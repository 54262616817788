import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IRequestOptions } from "src/app/interface/requestOption";

// export function applicationHttpClientCreator(http: HttpClient) {
//   return new ApplicationHttpClient(http);
// }

@Injectable({ providedIn: "root" })
export class GISHttpClient {
  private api = environment.apiBaseUrl + environment.apiroot;
  public accessToken;

  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient) {
    // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
    // for ex. this.httpClient.http.get(...)
  }

  setAccessToken(accessToken: any): void {
    this.accessToken = accessToken;
  }
  // tslint:disable-next-line:no-redundant-jsdoc
  /**
   * GET request
   * @param string endPoint it doesn't need / in front of the end point
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(endPoint, this.setTokenInHeader(options));
    //return this.http.get<T>(endPoint);
  }

  /**
   * POST request
   * @param string endPoint end point of the api
   * @param Object params body of the request.
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public post<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>((endPoint.includes("WfSServer") ? "" : this.api) + endPoint, params, this.setTokenInHeader(options));
  }

  /**
   * PATCH request
   * @param string endPoint end point of the api
   * @param Object params body of the request.
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public patch<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
    return this.http.patch<T>(endPoint.includes("WfSServer") ? "" : this.api + endPoint, params, this.setTokenInHeader(options));
  }

  /**
   * PUT request
   * @param string endPoint end point of the api
   * @param Object params body of the request.
   * @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public put<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(this.api + endPoint, params, this.setTokenInHeader(options));
  }

  /**
   * DELETE request
   *   @param string endPoint end point of the api
   *  @param IRequestOptions options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(this.api + endPoint, this.setTokenInHeader(options));
  }

  private setTokenInHeader(options: IRequestOptions) {
    options = options
      ? options
      : ({
          headers: new HttpHeaders()
        } as IRequestOptions);
    options.headers = options.headers.set("ProxyAuthorization", environment.apiKeyToken + this.accessToken);
    return options;
  }
}
