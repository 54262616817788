import { Component, OnInit, EventEmitter, Output, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CellRenderComponent } from "../CellRenderComponent/cell-render.component";
import { HelpCenterService } from "../../services/help-center.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { GridOptions } from "ag-grid-community";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { ViewEncapsulation } from "@angular/core";
import { AssetDetailService } from "src/app/services/assetDetail.service";
@Component({
  selector: "sms-solution-admin",
  templateUrl: "./solution-admin.component.html",
  styleUrls: ["./solution-admin.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SolutionAdminComponent implements OnInit {
  @ViewChild("queryForm") content;
  //Icon
  faPaperclip = faPaperclip;
  viewMode = true;
  frameworkComponents: any;
  subject: any;
  description: any;
  solution: any;
  searchClosedTab: any;
  searchOpenTab: any;
  gridApiOpenTab: any;
  gridApiClosedTab: any;
  rowDataOpen: any = [];
  rowDataClosed: any = [];
  faSearch = faSearch;
  currentUser: IUser;
  rowSelection = "single";
  tokenNo: any;
  url: any = "";
  imageSrc: any;
  showSolution: boolean;
  openTicketCount: any = "";
  modifiedBy: any;
  modifiedDate: any;
  noRowsTemplate: any;
  @Output() closeHelpEvent = new EventEmitter();

  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true
    }
  };
  columnDefs: any = [
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.tokenno"),
      field: "tokenNo",
      cellRenderer: "cellRendererComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.date"),
      field: "createdDate",
      cellRenderer: (data) => {
        return data.value ? this.datePipe.transform(data.value, "MM/dd/yyyy hh:mm a") : "";
      }
    },
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.username"),
      field: "createdBy"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.subject"),
      field: "subject"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.description"),
      field: "description"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tablesolutionadmin.attachment"),
      field: "url"
    }
  ];

  constructor(
    private modalService: NgbModal,
    private helpCenterService: HelpCenterService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    public objAssetDetailService: AssetDetailService
  ) {
    this.frameworkComponents = {
      cellRendererComponent: CellRenderComponent
    };
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.getAllTickets();
  }
  /**Close Help Center Popup */
  closeHelp() {
    this.closeHelpEvent.emit(false);
    // For ADA story - by Subba
    let mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
    if (mainPageTools.length > 0) {
      mainPageTools.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
    let bMapTools = document.querySelectorAll('[data-BMapTabbing="yes"]');
    if (bMapTools.length > 0) {
      bMapTools.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
  }
  /**Reset Field Value */
  reset() {
    this.solution = null;
  }
  /** Get All Ticket Details */
  getAllTickets() {
    try {
      this.helpCenterService.GetAllTickets().subscribe(
        (resultArray) => {
          const obj = JSON.parse(resultArray.toString());
          this.rowDataOpen = obj
            .sort((a, b) => {
              return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
            })
            .filter((x) => x.status.toString().toLowerCase() === "open");
          this.rowDataClosed = obj
            .sort((a, b) => {
              return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
            })
            .filter((x) => x.status.toString().toLowerCase() === "closed");
          const myRequest = obj.filter((x) => x.status.toString().toLowerCase() === "open");

          if (myRequest.length > 0) {
            this.openTicketCount = myRequest.length;
          } else {
            this.openTicketCount = "";
            //Jira- 731 no rows to show translate
            this.noRowsTemplate = this.translateService.instant("Helpcenter.solutionadmin.Norows");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  /**Updating Incident Detail*/
  onSubmit() {
    try {
      if (this.solution == null || this.solution === undefined || this.solution === "") {
        Swal.fire(this.translateService.instant("Helpcenter.alertsolutionadmin.entersolution"));
        return;
      }

      const obj = {
        solution: this.solution
      };
      this.helpCenterService.UpdateRequestByAdmin(obj, this.tokenNo).subscribe(
        (resultArray: any) => {
          const result = JSON.parse(resultArray);

          if (result.message === "Updated Successfully") {
            this.getAllTickets();
            Swal.fire(this.translateService.instant("Helpcenter.alertsolutionadmin.incidentupdate"));
            this.modalService.dismissAll();
          } else {
            Swal.fire(this.translateService.instant("Helpcenter.alertsolutionadmin.erroronsavingrecord"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  /**Load Incident Related Data to Fields */
  onRowEdit(tokenNo: string) {
    this.tokenNo = null;
    this.modifiedBy = null;
    this.modifiedDate = null;
    this.url = null;
    this.modalService.open(this.content, { centered: true });
    this.helpCenterService.GetTicketByTokenID(tokenNo).subscribe(
      (resultArray: any[]) => {
        if (resultArray.length > 0) {
          this.subject = resultArray[0].subject;
          this.description = resultArray[0].description;
          this.solution = resultArray[0].solution;
          this.url = resultArray[0].url;
          this.tokenNo = resultArray[0].tokenNo;
          this.modifiedBy = resultArray[0].modifiedBy;
          this.modifiedDate = this.datePipe.transform(resultArray[0].modifiedDate, "MM/dd/yyyy hh:mm a");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**Delete Incident baesd on Token Number */
  onRowDelete() {
    try {
      Swal.fire({
        title: "Delete Incident",
        text: "Are you sure want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue"
      }).then((result) => {
        if (result.value) {
          this.helpCenterService.DeleteTicketByID(this.tokenNo).subscribe(
            (resultArray: any) => {
              const result = JSON.parse(resultArray);

              if (result.message === "Deleted Successfully") {
                this.getAllTickets();
                Swal.fire(this.translateService.instant("Helpcenter.alertsolutionadmin.incidentdeleted"));
                this.modalService.dismissAll();
              } else {
                Swal.fire(this.translateService.instant("Helpcenter.alertsolutionadmin.errorondeleterecord"));
              }
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          if (this.viewMode) {
            this.reset();
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /**View Attachement */
  showAttachement(DocViewer) {
    try {
      this.modalService.open(DocViewer, { centered: true, size: "lg" });
      if (this.url != null) {
        this.imageGenerate(); //TSDRSAM-2045
      }
    } catch (error) {
      console.error(error);
    }
  }
  imageGenerate(data?) {
    try {
      this.imageSrc = "";
      let contentType = "image/png";
      //here docType = (this.url)filename with extension.For example - Filename.jpg
      this.objAssetDetailService.getImageAsBase64(this.tokenNo, this.currentUser.clientId, this.url.toLowerCase(), true).subscribe((base64Image: any) => {
        if (base64Image) this.imageSrc = `data:${contentType};base64, ${base64Image}`;
      });
    } catch (error) {
      console.error(error);
    }
  }
  onGridReadyOpenTab(params) {
    this.gridApiOpenTab = params.api;
  }
  onGridReadyClosedTab(params) {
    this.gridApiClosedTab = params.api;
  }
  /**Search Filter for Open Tickets */
  searchOpenTabFilter() {
    try {
      this.gridApiOpenTab.setQuickFilter(this.searchOpenTab);
    } catch (error) {
      console.error(error);
    }
  }
  /**Search Filter for Closed Tickets */
  searchCloseTabFilter() {
    try {
      this.gridApiClosedTab.setQuickFilter(this.searchClosedTab);
    } catch (error) {
      console.error(error);
    }
  }
  pendingTab() {
    this.viewMode = true;
  }
  completedTab() {
    this.viewMode = false;
  }
}
