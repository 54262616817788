<div class="bound">
  <div class="modal-content formu" cdkDragBoundary=".querybound" cdkDrag>
    <div cdkDragHandle>
      <app-heading-component [headingText]="'Helpcenter.user.title' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="closeHelp()"> </app-heading-component>
    </div>
    <div id="help_tab" class="modal-body p-0 help show">
      <mat-tab-group (selectedTabChange)="showSolution ? openTab() : closedTab()">
        <mat-tab>
          <ng-template mat-tab-label>
            <span matBadge="{{ openTicketCount }}" style="width: 50%" matBadgeOverlap="false">{{ "Helpcenter.user.opentab" | translate }}</span>
          </ng-template>
          <div class="tab-content">
            <div class="card">
              <div class="card-body">
                <div class="input-group-prepend">
                  <div class="flex-row-center">
                    <button (click)="openModel(queryForm)" style="width: auto" mat-button matPrefix mat-icon-button>
                      <mat-icon svgIcon="plus"></mat-icon>
                      Add
                    </button>

                    <mat-checkbox class="checkbox" (change)="showMyRequestOpen($event)" checked aria-label="Show Only My Requests">{{ "Helpcenter.user.showuserrequest" | translate }}</mat-checkbox>
                  </div>
                  <div style="width: 45%">
                    <mat-form-field style="width: 100%" appearance="outline">
                      <mat-label class="col-6">{{ "Helpcenter.user.search" | translate }}</mat-label>
                      <input matInput type="text" [(ngModel)]="searchOpenTab" (keyup)="searchOpenTabFilter()" placeholder="{{ 'Helpcenter.user.search' | translate }}" aria-label="Search" />
                      <button mat-button matPrefix mat-icon-button aria-label="search">
                        <mat-icon svgIcon="search"></mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <ag-grid-angular
                  style="width: 100%; height: 300px"
                  class="ag-theme-balham"
                  [rowData]="rowDataOpen"
                  [columnDefs]="columnDefs"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [frameworkComponents]="frameworkComponents"
                  [gridOptions]="gridOptions"
                  (gridReady)="onGridReadyOpenTab($event)"
                  [suppressMovableColumns]="true"
                  [rowSelection]="rowSelection"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab style="width: 50%" label="{{ 'Helpcenter.user.closedtab' | translate }}">
          <div class="tab-content">
            <div class="card">
              <div class="card-body">
                <div class="input-group-prepend">
                  <mat-checkbox (change)="showMyRequestClosed($event)" checked aria-label="Show Only My Requests">{{ "Helpcenter.user.showuserrequest" | translate }}</mat-checkbox>

                  <mat-form-field appearance="outline">
                    <mat-label>{{ "Helpcenter.user.search" | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchClosedTab" (keyup)="searchCloseTabFilter()" placeholder="{{ 'Helpcenter.user.search' | translate }}" aria-label="Search" />
                    <button mat-button matPrefix mat-icon-button aria-label="search">
                      <mat-icon svgIcon="search"></mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <ag-grid-angular
                  style="width: 100%; height: 300px"
                  class="ag-theme-balham"
                  [rowData]="rowDataClosed"
                  [columnDefs]="columnDefs"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [frameworkComponents]="frameworkComponents"
                  [suppressMovableColumns]="true"
                  (gridReady)="onGridReadyClosedTab($event)"
                  [rowSelection]="rowSelection"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<ng-template #queryForm let-modal>
  <div class="modal-content">
    <div>
      <app-heading-component
        [headingText]="viewMode === true ? ('Helpcenter.user.createnewticket' | translate) : ('Helpcenter.user.ticketinfo' | translate)"
        [variant]="'none'"
        [iconOne]="'cross'"
        [tooltipOne]="'Close'"
        (actionOne)="modal.dismiss()"
      >
      </app-heading-component>
    </div>
    <div class="modal-body formbg-d2" style="display: flex; flex-direction: column; margin: 31px">
      <div class="row" *ngIf="!viewMode">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.user.tokennumber" | translate }}</mat-label>
          <input matInput readonly [value]="tokenNo" />
        </mat-form-field>
      </div>
      <br *ngIf="!viewMode" />
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.user.subject" | translate }}</mat-label>
          <input #box matInput maxlength="60" [(ngModel)]="subject" [readonly]="!viewMode" />
          <span *ngIf="viewMode" style="color: red">{{ 60 - box.value.length + " characters remaining" }}</span>
        </mat-form-field>
      </div>
      <br />
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.user.description" | translate }}</mat-label>
          <textarea #box1 matInput rows="4" maxlength="300" [(ngModel)]="description" [readonly]="!viewMode"></textarea>
          <span *ngIf="viewMode" style="color: red">{{ 300 - box1.value.length + " characters remaining" }}</span>
        </mat-form-field>
      </div>
      <div class="row">
        <div *ngIf="!viewMode">
          <button style="width: auto" *ngIf="url" (click)="showAttachement(DocViewer)" mat-button matPrefix mat-icon-button>
            <mat-icon svgIcon="download"></mat-icon>
            {{ "Helpcenter.user.view" | translate }}
          </button>
        </div>
      </div>
      <br />
      <div class="row" *ngIf="!viewMode && showSolution">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.user.solution" | translate }}</mat-label>
          <textarea #box2 matInput rows="4" maxlength="300" [(ngModel)]="solution" [readonly]="!viewMode"></textarea>
          <span *ngIf="viewMode" style="color: red">{{ 300 - box2.value.length + " characters remaining" }}</span>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="viewMode">
        <button style="width: auto" (click)="imageUpload.click()" mat-button matPrefix mat-icon-button>
          <mat-icon svgIcon="share"></mat-icon>
          {{ "Helpcenter.user.upload" | translate }}
        </button>
        <input type="file" id="attachement" class="d-none" accept="image/jpeg" #imageUpload name="file" (change)="fileChangeEvent($event)" style="display: none" />
        {{ attachement }}
      </div>
    </div>

    <div class="modal-footer" *ngIf="viewMode">
      <button type="button" class="primary-button" (click)="onSubmit()">{{ "Helpcenter.user.save" | translate }}</button>
      <button type="button" class="inverse-button" (click)="reset()">{{ "Helpcenter.user.clear" | translate }}</button>
    </div>
    <div class="modal-footer" *ngIf="!viewMode && showSolution" style="display: flex">
      <div style="font-weight: bold; align-self: flex-start">{{ "Helpcenter.user.modifiedby" | translate }}:{{ modifiedBy }}</div>
      <div style="font-weight: bold; align-self: flex-end">{{ "Helpcenter.user.modifiedate" | translate }}:{{ modifiedDate }}</div>
    </div>
  </div>
</ng-template>
<ng-template #DocViewer let-modal let-d="dismiss">
  <app-heading-component [headingText]="'Helpcenter.user.photoviewer' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="d('Cross click')"></app-heading-component>
  <div class="modal-body sms-model-imageviewer">
    <img *ngIf="imageSrc !== ''" [src]="imageSrc" />
  </div>
</ng-template>
