import { Component, OnInit, Renderer2, HostListener } from "@angular/core";
import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { Papa } from "ngx-papaparse";
import * as shpjs from "../../../assets/shpjs/shp.js";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-sms-bulkinsert",
  templateUrl: "./bulkinsert.component.html",
  styleUrls: ["./bulkinsert.component.scss"]
})
export class BulkinsertComponent implements OnInit {
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["localName"] === "div" && (event.target["id"] === "csUploadDIv" || event.target["id"] === "shpUploadDIv")) {
      let fileElementObj = (<HTMLInputElement>event.target).children[1];
      (<HTMLInputElement>fileElementObj).click();
    }
  }
  //
  constructor(
    public esritools: EsriMapComponent,
    private papa: Papa,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private genericLayerService: GenericLayerService
  ) {
    this.AssetServiceList = this.esritools.assetService.layerData;
    this.currentUser = this.authenticationService.currentUserValue;
  }

  currentUser: IUser;
  Primarylangvalid;
  Secodarylangvalid;
  showlang = false;
  public primaryAssetClassSelected;
  public relatedAssetClassSelected;
  public AssetServiceList = [];
  public test;
  public SelectedAssetClass;
  public Region;
  public ShapefileFeatures = [];
  public AssectCodeSelected;
  public relatedAssectSelected;
  public featureLayer;
  AssetTableValue = [];
  relatedButton = false;
  showTableAssetId = false;
  showGridForRelatedAsset = true;

  PrimaryAssetClassDropDown = [
    {
      id: 0,
      layername: "Barrier",
      geomType: "LineString",
      layerType: "Inventory",
      AssetCode: "BAR"
    },
    {
      id: 1,
      layername: "CrashCushion",
      geomType: "Point",
      layerType: "Inventory",
      AssetCode: "CCU"
    },
    {
      id: 2,
      layername: "EndTreatment",
      geomType: "Point",
      layerType: "Inventory",
      AssetCode: "ETM"
    },
    {
      id: 3,
      layername: "Guardrail",
      geomType: "LineString",
      layerType: "Inventory",
      AssetCode: "GUR"
    },
    {
      id: 4,
      layername: "PavementMarking",
      geomType: "LineString",
      layerType: "Inventory",
      AssetCode: "PVM"
    },
    {
      id: 5,
      layername: "PavementMarking_Symbol",
      geomType: "Point",
      layerType: "Inventory",
      AssetCode: "PVS"
    },
    {
      id: 6,
      layername: "Support",
      geomType: "Point",
      layerType: "Inventory",
      AssetCode: "SUP"
    }
  ];

  RelatedAssetClassDropDown = [];

  ngOnInit() {
    this.RelatedAssetClassDropDown = [
      {
        id: 0,
        layername: "Light",
        AssetCode: "LGT",
        layerURL: this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId
      },
      {
        id: 1,
        layername: "Sign",
        AssetCode: "SIG",
        layerURL: this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId
      }
    ];
    this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
      console.log(res.primaryLanguageID);
      console.log(res.secondaryLanguageID);
    });

    if (this.Primarylangvalid === this.currentUser.languageID) {
      this.showlang = false;
    } else if (this.Secodarylangvalid === this.currentUser.languageID) {
      this.showlang = true;
    }
  }

  handleCSVFile(evt) {
    try {
      // const componentType = document.getElementById("CommitData");
      // const hasClassCT = componentType.classList.contains("i-active");

      // if (hasClassCT) {
      //   componentType.classList.remove("i-active");
      // }

      if (this.relatedAssetClassSelected === undefined || this.relatedAssetClassSelected === "") {
        this.toastr.error("Please select related Asset Class Dropdown");
        evt.target.value = "";
      } else {
        this.showTableAssetId = false;
        const fileName = evt.target.value.toLowerCase();

        if (fileName.indexOf(".csv") !== -1) {
          const files = evt.target.files; // FileList object
          const file = files[0];
          const reader = new FileReader();
          reader.readAsText(file);

          reader.onload = (event: any) => {
            const csv = event.target.result; // Content of CSV file
            this.calltheCSVfileToJSON(csv, evt);
            console.log(this.test);
          };
        } else {
          Swal.fire(this.translateService.instant("Userscreens.alertbulkinsert.selectcsv"));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  calltheCSVfileToJSON(csv, evt) {
    try {
      this.relatedAssectSelected = null;
      this.SelectedAssetClass = [];
      this.featureLayer = null;
      this.RelatedAssetClassDropDown.forEach((relDrpevt) => {
        if (relDrpevt.layername === this.relatedAssetClassSelected) {
          this.relatedAssectSelected = relDrpevt.AssetCode;
          this.featureLayer = new this.esritools.FeatureLayer(relDrpevt.layerURL);
        }
      });
      this.test = null;
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          if (typeof results.data[0].AssetCode === "undefined") {
            this.toastr.error("AssetCode field is not avaiable");
            evt.target.value = "";
          } else {
            if (this.relatedAssetClassSelected === "Sign") {
              if (typeof results.data[0].AssetSubType === "undefined") {
                this.toastr.error("AssetSubType field is not avaiable");
                evt.target.value = "";
              } else {
                this.test = results.data;
                this.relatedButton = true;
              }
            } else {
              this.test = results.data;
              this.relatedButton = true;
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleShapefile(evt) {
    try {
      // const componentType = document.getElementById("CommitData");
      // const hasClassCT = componentType.classList.contains("i-active");

      // if (hasClassCT) {
      //   componentType.classList.remove("i-active");
      // }

      if (this.primaryAssetClassSelected === undefined || this.primaryAssetClassSelected === "") {
        Swal.fire(this.translateService.instant("Userscreens.alertbulkinsert.selectassetdrpdwn"));
        evt.target.value = "";
      } else {
        const fileName = evt.target.value.toLowerCase();

        if (fileName.indexOf(".zip") !== -1) {
          const files = evt.target.files;
          const file = files[0];
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = (event: any) => {
            const Shp = event.target.result;
            this.calltheShapefileToJSON(Shp, evt);
          };
        } else {
          Swal.fire(this.translateService.instant("Userscreens.alertbulkinsert.selectzippedshapefile"));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  calltheShapefileToJSON(filepath, evt) {
    try {
      console.log(this.primaryAssetClassSelected);
      this.AssectCodeSelected = null;
      let GeomType;
      this.SelectedAssetClass = [];
      this.PrimaryAssetClassDropDown.forEach((pAssDrpevt) => {
        if (pAssDrpevt.layername === this.primaryAssetClassSelected) {
          this.AssectCodeSelected = pAssDrpevt.AssetCode;
          GeomType = pAssDrpevt.geomType;
        }
      });
      this.AssetServiceList.forEach((assSvsListevt) => {
        if (assSvsListevt.layerName === this.primaryAssetClassSelected) {
          this.SelectedAssetClass = assSvsListevt;
        }
      });
      shpjs(filepath).then((geojson) => {
        if (typeof geojson.features[0].properties.AssetCode === "undefined") {
          this.toastr.error("AssetCode field is not avaiable");
          evt.target.value = "";
        } else if (geojson.features[0].geometry.type !== GeomType) {
          this.toastr.error("Geometry Type is different selected AssetClass");
          evt.target.value = "";
        } else if (geojson.features[0].properties.AssetCode.substring(0, 3) !== this.AssectCodeSelected || geojson.features[0].properties.AssetCode === "") {
          this.toastr.error("AssetCode is not matching with the selected asset dropdown.");
          evt.target.value = "";
        } else {
          this.ShapefileFeatures = geojson.features;
          const regionLayer = this.esritools.contextLayersData.filter((obj) => obj.layerName.includes("Region"));
          const querytask = this.esritools.QueryTask(regionLayer[0].lyrObj.url);
          const query = this.esritools.Query();
          query.where = "1=1";

          if (geojson.features[0].geometry.type === "Point") {
            const pt = new this.esritools.Point({
              x: geojson.features[0].geometry.coordinates[0],
              y: geojson.features[0].geometry.coordinates[1],
              spatialReference: { wkid: 4326 }
            });
            const WebPt = this.esritools.webMercatorUtils.geographicToWebMercator(pt);
            query.geometry = WebPt;
            query.returnGeometry = false;
            query.outFields = ["*"];
            querytask.execute(
              query,
              (qryTaskEvt) => {
                console.log(qryTaskEvt);
                this.Region = qryTaskEvt.features[0].attributes;
                this.AddDataInService();
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (geojson.features[0].geometry.type === "LineString") {
            const polyline = new this.esritools.Polyline(geojson.features[0].geometry.coordinates);
            const WebPt = this.esritools.webMercatorUtils.geographicToWebMercator(polyline);
            query.geometry = WebPt;
            query.returnGeometry = false;
            query.outFields = ["*"];
            querytask.execute(
              query,
              (qryTaskEvt) => {
                console.log(qryTaskEvt);
                this.Region = qryTaskEvt.features[0].attributes;
                this.AddDataInService();
              },
              (err) => {
                console.log(err);
              }
            );
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  onChange(evt) {
    try {
      if (this.primaryAssetClassSelected === "Support") {
        this.showGridForRelatedAsset = true;
      } else {
        this.showGridForRelatedAsset = false;
      }

      this.showTableAssetId = false;
      this.AssetTableValue = [];
    } catch (error) {
      console.error(error);
    }
  }

  AddDataInService() {
    try {
      if (this.SelectedAssetClass) {
        this.ShapefileFeatures.forEach((evt) => {
          if (evt.geometry.type === "Point") {
            const pt = new this.esritools.Point({
              x: evt.geometry.coordinates[0],
              y: evt.geometry.coordinates[1],
              spatialReference: { wkid: 4326 }
            });
            const WebPt = this.esritools.webMercatorUtils.geographicToWebMercator(pt);
            const features = new this.esritools.Graphic(WebPt);
            const addAssetAttributes: any = [];

            addAssetAttributes.AssetCode = evt.properties.AssetCode;
            addAssetAttributes.Status = "Active";
            addAssetAttributes.Condition = "Good";
            addAssetAttributes.ModifiedBy = this.currentUser.username;
            addAssetAttributes.LastModifyDate = new Date().getTime();
            addAssetAttributes.Latitude = evt.geometry.coordinates[1];
            addAssetAttributes.Longitude = evt.geometry.coordinates[0];
            addAssetAttributes.Latitude2 = null;
            addAssetAttributes.Longitude2 = null;
            addAssetAttributes.Region1 = this.Region.RegionName1;
            addAssetAttributes.Region2 = this.Region.RegionName2;
            addAssetAttributes.Region3 = this.Region.RegionName3;
            addAssetAttributes.Region4 = this.Region.RegionName4;
            features.setAttributes(addAssetAttributes);
            this.AssetTableValue = [];
            this.SelectedAssetClass.lyrObj.applyEdits(
              [features],
              null,
              null,
              (newAsset: any) => {
                const updateFeature = new this.esritools.Graphic();
                const updateAssetAttr: any = [];
                updateAssetAttr.OBJECTID = newAsset[0].objectId;
                updateAssetAttr.AssetID = this.AssectCodeSelected + "_" + newAsset[0].objectId;
                updateFeature.setAttributes(updateAssetAttr);
                this.AssetTableValue.push(updateAssetAttr);
                this.SelectedAssetClass.lyrObj.applyEdits(
                  null,
                  [updateFeature],
                  null,
                  (updated) => {
                    console.log(updated);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (evt.geometry.type === "LineString") {
            const polyline = new this.esritools.Polyline(evt.geometry.coordinates);
            const WebPt = this.esritools.webMercatorUtils.geographicToWebMercator(polyline);
            const features = new this.esritools.Graphic(WebPt);
            const addAssetAttributes: any = [];

            addAssetAttributes.AssetCode = evt.properties.AssetCode;
            addAssetAttributes.Status = "Active";
            addAssetAttributes.Condition = "Good";
            addAssetAttributes.ModifiedBy = this.currentUser.username;
            addAssetAttributes.LastModifyDate = new Date().getTime();
            addAssetAttributes.Latitude = evt.geometry.coordinates[0][1];
            addAssetAttributes.Longitude = evt.geometry.coordinates[0][0];
            addAssetAttributes.Latitude2 = evt.geometry.coordinates[1][1];
            addAssetAttributes.Longitude2 = evt.geometry.coordinates[1][0];
            addAssetAttributes.Region1 = this.Region.RegionName1;
            addAssetAttributes.Region2 = this.Region.RegionName2;
            addAssetAttributes.Region3 = this.Region.RegionName3;
            addAssetAttributes.Region4 = this.Region.RegionName4;
            features.setAttributes(addAssetAttributes);
            this.AssetTableValue = [];
            this.SelectedAssetClass.lyrObj.applyEdits(
              [features],
              null,
              null,
              (newAsset) => {
                const updateAssetFeature = new this.esritools.Graphic();
                const updateAssetAttr: any = [];
                updateAssetAttr.OBJECTID = newAsset[0].objectId;
                updateAssetAttr.AssetID = this.AssectCodeSelected + "_" + newAsset[0].objectId;
                updateAssetFeature.setAttributes(updateAssetAttr);
                this.AssetTableValue.push(updateAssetAttr);
                this.SelectedAssetClass.lyrObj.applyEdits(
                  null,
                  [updateAssetFeature],
                  null,
                  (updatedAsset) => {
                    console.log(updatedAsset);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
        this.toastr.info("Added " + this.ShapefileFeatures.length + " features in the Service");

        if (this.ShapefileFeatures[0].geometry.type === "Point") {
          this.esritools.map.centerAndZoom(this.ShapefileFeatures[0].geometry.coordinates, 15);
        } else if (this.ShapefileFeatures[0].geometry.type === "LineString") {
          const polyline = new this.esritools.Polyline(this.ShapefileFeatures[0].geometry.coordinates);
          const WebPt = this.esritools.webMercatorUtils.geographicToWebMercator(polyline);
          this.esritools.map.setExtent(WebPt.getExtent());
        }

        this.showTableAssetId = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  AddrelatedDataInService(event) {
    try {
      if (this.relatedAssetClassSelected) {
        this.test.forEach((evt) => {
          const features = new this.esritools.Graphic();
          const addRelAssetAttr: any = [];
          addRelAssetAttr.AssetCode = evt.AssetCode;
          addRelAssetAttr.ParentID = evt.ParentID;
          addRelAssetAttr.Condition = "Good";
          addRelAssetAttr.ModifiedBy = this.currentUser.username;
          addRelAssetAttr.LastModifyDate = new Date().getTime();
          addRelAssetAttr.Status = "Active";
          features.setAttributes(addRelAssetAttr);
          this.featureLayer.applyEdits(
            [features],
            null,
            null,
            (newAsset: any) => {
              const updateFeature = new this.esritools.Graphic();
              const updateRelAssetAttr: any = [];
              updateRelAssetAttr.OBJECTID = newAsset[0].objectId;
              updateRelAssetAttr.AssetID = this.relatedAssectSelected + "_" + newAsset[0].objectId;
              updateFeature.setAttributes(updateRelAssetAttr);
              this.featureLayer.applyEdits(
                null,
                [updateFeature],
                null,
                (updatedAsset) => {
                  console.log(updatedAsset);
                },
                (err) => {
                  console.log(err);
                }
              );
            },
            (err) => {
              console.log(err);
            }
          );
        });
      }

      this.toastr.info("Added " + this.test.length + " related asset in the service.");
      this.relatedButton = false;
      // const className = "i-active";
      // const hasClass = event.target.classList.contains(className);

      // if (hasClass) {
      //   this.renderer.removeClass(event.target, className);
      // } else {
      //   this.renderer.addClass(event.target, className);
      // }
    } catch (error) {
      console.error(error);
    }
  }
}
