<div class="bound" [ngClass]="{ bound1: asstClickedES, bound: !asstClickedES }" #appMovableArea>
  <div
    class="modal-content formu"
    style="max-width: 500px; position: absolute; z-index: 1; height: auto"
    *ngIf="showPopup"
    ngResizable
    [bounds]="appMovableArea"
    [inBounds]="inBounds"
    [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
    ngDraggable
    [handle]="DemoHandle"
  >
    <div #DemoHandle>
      <app-heading-component [headingText]="'Crowdsource.main.title' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="modelClose()"> </app-heading-component>
    </div>
    <div class="card show" id="crowdsource-pop">
      <div style="margin: 8px 16px 0 16px">
        <h3 class="h4-heading" *ngIf="viewMode">{{ "Crowdsource.main.fillassetinfo" | translate }}</h3>
        <h4 class="h4-heading" *ngIf="!viewMode">{{ "Crowdsource.main.complaintid" | translate }}: {{ complaint }}</h4>
      </div>
      <div style="margin: 0 16px" class="formbg-d2">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Crowdsource.main.date" | translate }}</mat-label>
          <input [value]="dateofComplaint | date" readonly matInput />
        </mat-form-field>

        <div class="form-row-apart" *ngIf="!viewMode">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Crowdsource.main.latitude" | translate }}</mat-label>
            <input [(ngModel)]="latitude" disabled matInput />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Crowdsource.main.longitude" | translate }}</mat-label>
            <input [(ngModel)]="longitude" disabled matInput />
          </mat-form-field>

          <div style="text-align: center" *ngIf="showbutton && showlocation">
            <span aria-hidden="true" (click)="createNewTask()"><mat-icon svgIcon="select"></mat-icon></span>
          </div>
        </div>
      </div>
      <mat-form-field style="margin: 0px 16px" appearance="outline" *ngIf="!viewMode">
        <mat-label>{{ "Crowdsource.main.assetid" | translate }}</mat-label>
        <input [(ngModel)]="assetID" disabled matInput />
      </mat-form-field>
      <mat-form-field style="margin: 0px 16px" appearance="outline">
        <mat-label>{{ "Crowdsource.main.source" | translate }}</mat-label>
        <input [(ngModel)]="source" disabled matInput />
      </mat-form-field>
      <mat-form-field style="margin: 0px 16px" appearance="outline">
        <mat-label>{{ "Crowdsource.main.description" | translate }}</mat-label>
        <textarea matInput rows="3" maxlength="300" [(ngModel)]="description" [disabled]="!viewMode"></textarea>
      </mat-form-field>
      <mat-form-field style="margin: 0px 16px" appearance="outline">
        <mat-label>{{ "Crowdsource.main.reviewcomment" | translate }}</mat-label>
        <textarea matInput rows="3" maxlength="300" [(ngModel)]="reviewComment" [disabled]="!showbutton || viewMode"></textarea>
      </mat-form-field>
      <div class="card-footer formbg-d2" *ngIf="showbutton">
        <div style="text-align: right" *ngIf="viewMode">
          <button style="margin-right: 8px" matButton type="button" class="inverse-button" (click)="reset()">{{ "Crowdsource.main.clear" | translate }}</button>
          <button matButton type="button" class="primary-button" (click)="onSubmit()">{{ "Crowdsource.main.save" | translate }}</button>
        </div>
        <div style="text-align: right" *ngIf="!viewMode">
          <button style="margin-right: 8px" matButton type="button" class="inverse-button" (click)="updateComplaint(2)">{{ "Crowdsource.main.taskcreate" | translate }}</button>
          <button matButton type="button" class="primary-button" (click)="updateComplaint(1)">{{ "Crowdsource.main.discard" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="showSummaryView"
    style="height: 400px; max-width: 880px; border: none"
    class="modal-content formu"
    ngResizable
    [bounds]="appMovableArea"
    [inBounds]="inBounds"
    [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
    ngDraggable
    [handle]="DemoHandle"
  >
    <div #DemoHandle>
      <app-heading-component [headingText]="'Crowdsource.main.title' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="closeCrowdAsset()"> </app-heading-component>
    </div>

    <div style="margin-top: 16px; display: flex; justify-content: space-between; margin-left: 16px; margin-right: 18px">
      <button mat-button matPrefix mat-icon-button (click)="openModel()" aria-label="click to open manual entry form">
        <mat-icon svgIcon="plus"></mat-icon>
        Add
      </button>
      <mat-form-field appearance="outline">
        <mat-label [attr.aria-label]="'' + 'Crowdsource.main.search' | translate">{{ "Crowdsource.main.search" | translate }}</mat-label>
        <input matInput [(ngModel)]="search" (keyup)="searchFilter()" placeholder="{{ 'Helpcenter.user.search' | translate }}" aria-label="Search" />
        <button mat-button matPrefix mat-icon-button>
          <mat-icon svgIcon="search"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <ag-grid-angular
      style="width: 100%; height: 300px"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [pagination]="true"
      [paginationPageSize]="10"
      [frameworkComponents]="frameworkComponents"
      (gridReady)="onGridReady($event)"
      [suppressMovableColumns]="true"
      [rowSelection]="rowSelection"
    >
    </ag-grid-angular>
  </div>
</div>
