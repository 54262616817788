import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";

@Component({
  selector: "sms-PredefinedQuery",
  templateUrl: "./PredefinedQuery.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class PredefinedQueryComponent implements OnInit {
  @Output() data = new EventEmitter();
  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {}

  ngOnInit() {
    if (this.adminGlobal.HideAsset.length === 0) {
      this.MMMAdminService.getLayerRecords(this.adminGlobal.agencyDetails[0].webServiceUrl, this.adminGlobal.serviceLyrInfo.tableAliasId).subscribe(
        (res: any) => {
          res.features.forEach((data) => {
            if (data.attributes.GroupType === "Parent Table") {
              this.adminGlobal.HideAsset.push(data.attributes);

              if (data.attributes.IsAuthorized === "T") {
                this.adminGlobal.ShowAsset.push(data.attributes);
              }
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  showQueryData(event) {
    this.data.emit(event);
  }
}
