<nav class="navbar fixed-top navbar-expand-lg">
  <div class="navbar-brand">
    <a href="#" title="Logo of {{ 'Header.navbar.logotexttip' | translate }}">
      <!-- <img src="assets/images/threemlogo.png" class="app-logo"> -->
      <img src="assets/images/logo30px.svg" class="app-logo" title="Logo of Sign Management System" alt="Logo of Sign Management System" />
    </a>
    <!-- <label class="pl-2 color-primary logo-txt"> {{'Header.navbar.logotext'|translate}}</label>{{'Header.navbar.logotext'|translate}} -->
  </div>

  <button
    [ngClass]="{ collapsed1: isCollapsed === true }"
    class="navbar-toggler navbar-toggler-right"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="navbarSupportedContent"
    id="nav1"
  >
    <span> </span>
    <span> </span>
    <span> </span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav ml-auto">
      <li [ngClass]="{ 'i-active': activeTab === 'select' }" class="nav-container" *ngIf="currentUser.soultionTiers[0][0].asset_Selection">
        <a role="button" data-mainPageTabbing="yes" href="#" class="nav-new" (click)="optionClicked($event, 'select')" title="{{ 'Header.navitem.select' | translate }}">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-select" svgIcon="select" [ngClass]="{ 'i-active': activeTab === 'select' }"></mat-icon>
            <div class="xxsmall new-icon-text" [ngClass]="{ 'i-active': activeTab === 'select' }">
              {{ "Header.navitem.select" | translate }}
            </div>
          </div>
        </a>
      </li>
      <li id="querybuilder" [ngClass]="{ 'i-active': activeTab === 'querybuilder' }" class="nav-container">
        <a role="button" data-mainPageTabbing="yes" href="#" class="nav-new" (click)="optionClicked($event, 'querybuilder')" title="{{ 'Header.navitem.query' | translate }}">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-query" svgIcon="pencil"></mat-icon>
            <div class="xxsmall new-icon-text">{{ "Header.navitem.query" | translate }}</div>
          </div>
        </a>
      </li>
      <li [ngClass]="{ 'i-active': activeTab === 'add' }" class="nav-container" *ngIf="currentUser.rolePermissions.assetAdd && currentUser.selectedRegion && currentUser.selectedRegion.accessPrivilege === 'Data Editor'">
        <a role="button" data-mainPageTabbing="yes" href="#" class="nav-new" (click)="optionClicked($event, 'add')" title="{{ 'Header.navitem.add' | translate }}">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-add" svgIcon="add" [ngClass]="{ 'i-active': activeTab === 'add' }"></mat-icon>
            <div class="xxsmall new-icon-text" [ngClass]="{ 'i-active': activeTab === 'add' }">{{ "Header.navitem.add" | translate }}</div>
          </div>
        </a>
      </li>
      <li [ngClass]="{ 'i-active': activeTab === 'maintenanceViewer' }" class="nav-container">
        <a role="button" data-mainPageTabbing="yes" id="tasks-new" href="#" class="nav-new" (click)="optionClicked($event, 'maintenanceViewer')" title="{{ 'Header.navitem.mytasks' | translate }}">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-tasks" svgIcon="query" [ngClass]="{ 'i-active': activeTab === 'maintenanceViewer' }"></mat-icon>
            <div class="xxsmall new-icon-text" [ngClass]="{ 'i-active': activeTab === 'maintenanceViewer' }">{{ "Header.navitem.mytasks" | translate }}</div>
          </div>
        </a>
      </li>
      <li class="help-nav nav-container" *ngIf="currentUser.soultionTiers[0][0].help_Center">
        <div class="dropdown" id="helpdropdown">
          <a role="button" id="help-new" href="#" data-toggle="dropdown" aria-haspopup="true" class="nav-new" aria-expanded="false" title="{{ 'Header.navitem.help' | translate }}">
            <div class="nav-item-new">
              <mat-icon class="icon-new icon-new-help" svgIcon="question"></mat-icon>
              <div class="xxsmall new-icon-text">{{ "Header.navitem.help" | translate }}</div>
            </div>
          </a>
          <div class="dropdown-menu" aria-label="user-profile">
            <a role="button" data-mainPageTabbing="yes" id="help" class="dropdown-item" href="#" (click)="optionClicked($event, 'help')">
              <mat-icon class="icon-new icon-new-help material-icons-outlined">help_center</mat-icon>
              {{ "Header.helpnavitem.helpcentre" | translate }}</a
            >
            <a data-mainPageTabbing="yes" class="dropdown-item" [href]="ramhelp" target="_blank">
              <mat-icon variant="outlined" class="icon-new icon-new-help material-icons-outlined">help_outline</mat-icon>{{ "Header.helpnavitem.help" | translate }}</a
            >
            <a data-mainPageTabbing="yes" id="about" class="dropdown-item" href="#" (click)="optionClicked($event, 'about')">
              <mat-icon class="icon-new icon-new-help material-icons-outlined">info</mat-icon>{{ "Header.helpnavitem.about" | translate }}</a
            >
          </div>
        </div>
      </li>
      <li [ngClass]="{ 'i-active': activeTab === 'admin' }" class="nav-container" *ngIf="currentUser.rolePermissions.roleName === '3MAdmin'">
        <a
          href="#"
          class="nav-new"
          [ngClass]="{ 'i-active': admi, 'i-inactive': !admi }"
          id="admin"
          (click)="clickadmin(); optionClicked($event, 'admin')"
          data-toggle="modal"
          data-target="#nerModal"
          role="button"
          data-mainPageTabbing="yes"
          title="{{ 'Header.navitem.3madmin' | translate }}"
        >
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-3madmin" svgIcon="profile" [ngClass]="{ 'i-active': activeTab === 'admin' }"></mat-icon>
            <div class="xxsmall new-icon-text" [ngClass]="{ 'i-active': activeTab === 'admin' }">{{ "Header.navitem.3madmin" | translate }}</div>
          </div>
        </a>
      </li>
      <li [ngClass]="{ 'i-active': activeTab === 'settings' }" class="nav-container" *ngIf="currentUser.rolePermissions.adminAdvanced">
        <a
          id="select-new"
          href="#"
          class="nav-new"
          [ngClass]="{ 'i-active': setti, 'i-inactive': !setti }"
          data-toggle="modal"
          data-target="#settingModal"
          role="button"
          data-mainPageTabbing="yes"
          (click)="clicksetting(); optionClicked($event, 'settings')"
          title="{{ 'Header.navitem.settings' | translate }}"
        >
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-tasks" id="setting-icon" svgIcon="profile" [ngClass]="{ 'i-active': activeTab === 'settings' }"></mat-icon>
            <div class="xxsmall new-icon-text" id="setting-icon-text" [ngClass]="{ 'i-active': activeTab === 'settings' }">{{ "Header.navitem.settings" | translate }}</div>
            <span [ngClass]="{ 'notification-active': setti }" class="notification" *ngIf="currentUser.usesExternalDataProvider && notificationCount > 0">{{ notificationCount }}</span>
          </div>
        </a>
      </li>
      <li [ngClass]="{ 'i-active': activeTab === 'more' }" class="nav-container">
        <a role="button" data-mainPageTabbing="yes" id="more" href="#" class="nav-new" data-toggle="collapse" data-target="#demo" title="Click to view more items" (click)="optionClicked($event, 'more')">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-more" svgIcon="more" [ngClass]="{ 'i-active': activeTab === 'more' }"></mat-icon>
            <div class="xxsmall new-icon-text" [ngClass]="{ 'i-active': activeTab === 'more' }">{{ "Header.navitem.more" | translate }}</div>
          </div>
        </a>
      </li>
      <li class="divider-container">
        <div class="divider"></div>
      </li>
      <li class="nav-container">
        <a role="button" href="#" class="nav-new" (click)="logout()" title="Sign out">
          <div class="nav-item-new">
            <mat-icon class="icon-new icon-new-logout" svgIcon="exit-door" [ngClass]="{ 'i-active': activeTab === 'admin' }"></mat-icon>
            <div class="xxsmall new-icon-text">Sign out</div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</nav>

<!--Start of More menu-->
<div id="demo" class="collapse submenu" role="navigation">
  <ul class="navbar-nav">
    <li class="text-center" *ngIf="currentUser.soultionTiers[0][0].active_Region_Selection">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        data-forTab="yes"
        data-toggle="collapse"
        data-target="#demo"
        (click)="optionClicked($event, 'filter')"
        id="filter"
        title="{{ 'Header.navitem.regions' | translate }}"
      >
        <mat-icon class="icon-new" svgIcon="map"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navitem.regions" | translate }}</span>
      </a>
    </li>
    <li class="text-center">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        data-forTab="yes"
        data-toggle="collapse"
        data-target="#demo"
        (click)="optionClicked($event, 'layers')"
        id="layers"
        title="{{ 'Header.navitem.layers' | translate }}"
      >
        <mat-icon class="icon-new" svgIcon="layers"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navitem.layers" | translate }}</span>
      </a>
    </li>
    <li class="text-center">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        data-forTab="yes"
        data-toggle="collapse"
        data-target="#demo"
        (click)="optionClicked($event, 'search')"
        id="search"
        title="{{ 'Header.navitem.search' | translate }}"
      >
        <mat-icon class="icon-new" svgIcon="search"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navitem.search" | translate }}</span>
      </a>
    </li>
    <li class="text-center">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        id="redline"
        data-toggle="collapse"
        data-target="#demo"
        data-forTab="yes"
        (click)="optionClicked($event, 'redline')"
        title="{{ 'Header.navitem.redline' | translate }}"
      >
        <mat-icon class="icon-new" svgIcon="redline"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navitem.redline" | translate }}</span>
      </a>
    </li>
    <li class="text-center">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        id="measure"
        data-toggle="collapse"
        data-target="#demo"
        data-forTab="yes"
        (click)="optionClicked($event, 'measure')"
        title="{{ 'Header.navitem.measure' | translate }}"
      >
        <mat-icon class="icon-new icon-new-logout" svgIcon="measure"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navitem.measure" | translate }}</span>
      </a>
    </li>

    <li class="text-center" *ngIf="currentUser.soultionTiers[0][0].solution_State_Snapshot">
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        data-forTab="yes"
        data-toggle="collapse"
        data-target="#demo"
        (click)="capturescreen()"
        id="solutionSnapshot"
        title="{{ 'Header.navdrpdwn.screenshot' | translate }}"
      >
        <mat-icon class="icon-new icon-new-logout" svgIcon="screen-shot"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navdrpdwn.screenshot" | translate }}</span>
      </a>
    </li>
    <li
      *ngIf="
        (currentUser.rolePermissions.roleName == 'Admin' || currentUser.rolePermissions.roleName == 'DataCollector' || currentUser.rolePermissions.roleName == '3MAdmin') &&
        currentUser.soultionTiers[0][0].data_Collector_Settings
      "
      class="text-center submenu-dataEditor"
    >
      <a
        role="button"
        href="#"
        class="nav-link i-inactive"
        data-forTab="yes"
        data-toggle="collapse"
        data-target="#demo"
        (click)="optionClicked($event, 'datacollectorSettings')"
        id="datacollectorSettings"
        title="{{ 'Header.navdrpdwn.datacollectorSettings' | translate }}"
      >
        <mat-icon class="icon-new icon-new-logout" svgIcon="data-editor"></mat-icon>
        <br /><span class="xxsmall">{{ "Header.navdrpdwn.datacollectorSettings" | translate }}</span>
      </a>
    </li>
  </ul>
</div>
<!--End of More menu-->
<!-- POSSIBLE SOLUTION <div *ngIf="auth.currentUserValue.serviceLyrInfo" class="modal fade" id="settingModal" tabindex="-1" role="dialog" aria-label="settingModalLabel" data-backdrop="false" aria-hidden="true" #appMovableArea></div> -->
<div class="modal fade" id="settingModal" tabindex="-1" role="dialog" aria-label="settingModalLabel" data-backdrop="false" aria-hidden="true" #appMovableArea>
  <div class="modal-dialog administrator-details" #DemoHandle role="document">
    <div class="modal-content modal-xl settingmodal" cdkDrag cdkDragBoundary="#settingModal" ngResizable [rzHandles]="'e,w'">
      <app-heading-component cdkDragHandle [headingText]="'Settings.maindiv.heading' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="clicksetting()"> </app-heading-component>
      <section class="modal-body modalHeights show" id="administrationPopup">
        <mat-tab-group [selectedIndex]="0">
          <mat-tab *ngIf="currentUser.soultionTiers[0][0].solution_Administrator_Reports" label="{{ 'Settings.tabs.reports' | translate }}">
            <app-sms-reports [map]="map"></app-sms-reports>
          </mat-tab>
          <mat-tab *ngIf="currentUser.soultionTiers[0][0].crew_Management" label="{{ 'Settings.tabs.crewmanagement' | translate }}">
            <div style="min-height: 60vh; display: flex; align-items: center; justify-content: center">
              <button mat-button class="primary-button h7" data-dismiss="modal" (click)="optionClicked($event, 'crewmanagement'); clicksetting()">
                {{ "Crewmanagement.crewmanagement.btn" | translate }}
              </button>
            </div>
          </mat-tab>
          <mat-tab *ngIf="currentUser.soultionTiers[0][0].user_Administration" title="{{ 'Settings.tabs.useradmin' | translate }}" label="{{ 'Settings.tabs.useradmin' | translate }}">
            <ng-template matTabContent>
              <app-sms-useradmin></app-sms-useradmin>
            </ng-template>
          </mat-tab>
          <mat-tab label="{{ 'Settings.tabs.advance' | translate }}">
            <div class="card">
              <div class="card-body">
                <ng-template matTabContent>
                  <mat-tab-group>
                    <mat-tab *ngIf="currentUser.soultionTiers[0][0].crowdsourcing_Installed_Asset_State" title="{{ 'Settings.tabs.crowdSource' | translate }}" label="{{ 'Settings.tabs.crowdSource' | translate }}">
                      <ng-template matTabContent>
                        <div class="sms-model-height">
                          <div class="card">
                            <div class="card-body">
                              <button style="font-size: 14px" type="button" class="btn btn-primary" data-dismiss="modal" (click)="optionClicked($event, 'crowdSource')">
                                {{ "Settings.tabs.crowdSource" | translate }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="currentUser.soultionTiers[0][0].gpS_Track_Logs" title="{{ 'Settings.tabs.gpstrack' | translate }}" label="{{ 'Settings.tabs.gpstrack' | translate }}">
                      <ng-template matTabContent>
                        <app-sms-gpstrack
                          [TimeExtent]="TimeExtent"
                          [SimpleLineSymbol]="SimpleLineSymbol"
                          [SimpleRenderer]="SimpleRenderer"
                          [Color]="Color"
                          [TimeSlider]="TimeSlider"
                          [TimeInfo]="TimeInfo"
                          [map]="map"
                          [registry]="registry"
                          [Popup]="Popup"
                          [domConstruct]="domConstruct"
                        >
                        </app-sms-gpstrack>
                      </ng-template>
                    </mat-tab>
                    <mat-tab title="{{ 'Settings.tabs.assetattributes' | translate }}" label="{{ 'Settings.tabs.assetattributes' | translate }}">
                      <ng-template matTabContent><app-sms-assetattributes [Query]="Query" [Graphic]="Graphic" [FeatureLayer]="FeatureLayer"> </app-sms-assetattributes></ng-template>
                    </mat-tab>
                    <mat-tab title="{{ 'Settings.tabs.setdefaults' | translate }}" label="{{ 'Settings.tabs.setdefaults' | translate }}">
                      <ng-template matTabContent
                        ><app-sms-set-defaults [Graphic]="Graphic" [map]="map" [FeatureTable]="FeatureTable" [Query]="Query" [QueryTask]="QueryTask" [FeatureLayer]="FeatureLayer"> </app-sms-set-defaults
                      ></ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="currentUser.soultionTiers[0][0].bulk_Insert" title="{{ 'Settings.tabs.bulkinsert' | translate }}" label="{{ 'Settings.tabs.bulkinsert' | translate }}">
                      <ng-template matTabContent><app-sms-bulkinsert></app-sms-bulkinsert></ng-template>
                    </mat-tab>
                    <mat-tab
                      *ngIf="currentUser.soultionTiers[0][0].map_Export && !currentUser.usesExternalDataProvider"
                      title="{{ 'Settings.tabs.mapexport' | translate }}"
                      label="{{ 'Settings.tabs.mapexport' | translate }}"
                    >
                      <ng-template matTabContent><app-sms-map-export [map]="map" [Geoprocessor]="Geoprocessor"></app-sms-map-export></ng-template>
                    </mat-tab>
                  </mat-tab-group>
                </ng-template>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="currentUser.soultionTiers[0][0].user_Administration && currentUser.usesExternalDataProvider" label="Change Report">
            <app-change-report [FeatureLayer]="FeatureLayer" [Graphic]="Graphic"></app-change-report>
          </mat-tab>
        </mat-tab-group>
      </section>
    </div>
  </div>
</div>
<!-- <app-modal #modalRootAdmin>
  <ng-container class="app-modal-header">
    <h2 class="mb-1"><b>{{'Administrator.maindiv.header'|translate}}</b></h2>
  </ng-container>
  <ng-container class="app-modal-body" *ngIf="currentUser.rolePermissions.roleName === '3MAdmin'">
    <sms-administrator></sms-administrator>
  </ng-container>
</app-modal> -->
<div class="modal fade" id="nerModal" tabindex="-1" role="dialog" aria-labelledby="nerModalLabel" aria-hidden="true" data-backdrop="false" #appMovableArea1>
  <div class="modal-dialog" role="document" id="nerModalLabel">
    <div class="modal-content modal-xl adminset" ngResizable [bounds]="appMovableArea1" [inBounds]="inBounds" [rzHandles]="'e,w'" ngDraggable [handle]="DemoHandle1">
      <div class="modal-header" #DemoHandle1>
        <div class="modal-title">
          <h2 class="mb-1">
            <b>{{ "Administrator.maindiv.header" | translate }}</b>
          </h2>
        </div>
        <button type="button" class="close" data-dismiss="modal" (click)="clickadmin()"><span aria-hidden="true">&times;</span></button>
        <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"> -->
        <!-- <span aria-hidden="true">&times;</span>
     </button> -->
      </div>
      <div class="modal-body modalHeights show" id="administrationPopup">
        <ng-container class="app-modal-body" *ngIf="currentUser.rolePermissions.roleName === '3MAdmin'">
          <sms-administrator></sms-administrator>
        </ng-container>
      </div>
    </div>
  </div>
</div>
