import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";
import { QueryLayers } from "../services/queryLayers.service";
import { AssetDetailService } from "../services/assetDetail.service";
import { EsriMapComponent } from "../esri-map/esri-map.component";
import { ButtonRenderComponent } from "./button.component";
import { Globals } from "../select/globals";
import { environment } from "src/environments/environment";
import { GenericLayerService } from "../services/genericLayer.service";
import { CrewManagementService } from "../crewmanagement/crewmanagement.service";
import { GenericMapMethodsService } from "../services/generic-map-methods.service";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitersService } from "../services/eventEmiters.service";
import { UserService } from "src/app/services/user.service";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { GridOptions } from "ag-grid-community";

@AutoUnsubscribe()
@Component({
  selector: "sms-my-maintenance",
  templateUrl: "./my-maintenance.component.html",
  styleUrls: ["./my-maintenance.component.scss"],
  host: { class: "myMaintananceDiv" },
  encapsulation: ViewEncapsulation.None
})
export class MyMaintenanceComponent implements OnInit {
  /** Variables */
  @Input() Query: any;
  @Input() Graphic: any;
  @Input() QueryTask: any;
  @Input() map: any;
  @Input() tasksCategory: any;
  @Input() selectedData: any;
  @Input() FeatureLayer: any;
  @Input() all: any;
  @Input() Color: any;
  @Output() closeTable = new EventEmitter();
  @Input() PictureMarkerSymbol: any;
  @Input() JoinDataSource: any;
  @Output() showTask = new EventEmitter();
  @Output() rowDataObjVar = new EventEmitter();
  @Input() RelationshipQuery: any;
  // remove
  @Input() SimpleFillSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() Point: any;

  private myTasks: any[] = [];
  private currentUser: any;
  private currUser;
  private completedTasks: any[] = [];
  private openTasks: any[] = [];
  private relatedAsset: any[] = [];
  private checked: boolean;

  showtask = false;
  private selectedDataTasks: any[] = [];
  private loading = false;
  private highlightAllAssets = true;
  activityLyrDomines;
  activityToolKitList = [];
  activityToolKitListGroup = [];
  frameworkComponents: any;
  rowSelection = "";
  gridApi;
  gridColumnApi;
  showtools;
  showtoolkitbtn = true;
  checkboxChecked;
  completionDateFrom: string;
  // TSDRSAM-1067 By Manikantha
  taskGraphicsLyr;
  columnDefsOpen = [
    {
      headerName: this.translateService.instant("Mytasks.header.WorkOrder"),
      field: "WorkOrder"
    },
    { headerName: this.translateService.instant("Mytasks.header.AssetID"), field: "AssetID" },
    { headerName: this.translateService.instant("Mytasks.header.AssetCode"), field: "AssetCode" },
    { headerName: this.translateService.instant("Mytasks.header.AssetName"), field: "AssetName" },
    {
      headerName: this.translateService.instant("Mytasks.header.Activity"),
      field: "Activity",
      sort: "asc",
      cellRenderer: "cellRendererComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    { headerName: this.translateService.instant("Mytasks.header.Route"), field: "Route", cellRenderer: (params) => (params.value ? params.value : "-") },
    (this.rowSelection = "single")
  ];
  rowData = [];
  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true
    }
  };

  // Closed Tasks  Grid Related
  gridApiClosed;
  gridColumnApiClosed;
  columnDefsClosed = [
    {
      headerName: this.translateService.instant("Mytasks.header.WorkOrder"),
      field: "WorkOrder"
    },
    { headerName: this.translateService.instant("Mytasks.header.AssetID"), field: "AssetID" },
    { headerName: this.translateService.instant("Mytasks.header.AssetCode"), field: "AssetCode" },
    { headerName: this.translateService.instant("Mytasks.header.AssetName"), field: "AssetName" },
    {
      headerName: this.translateService.instant("Mytasks.header.Activity"),
      field: "Activity",
      cellRenderer: "cellRendererComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    { headerName: this.translateService.instant("Mytasks.header.Route"), field: "Route", cellRenderer: (params) => (params.value ? params.value : "-") },
    (this.rowSelection = "single")
  ];
  rowDataClosed = [];
  // Closed Tasks  Grid Related

  private gridApiOpt;
  private gridColumnApiOpt;
  faWindowClose = faWindowClose;
  faWrench = faWrench;
  columnDefsOpt = [
    {
      headerName: this.translateService.instant("Mytasks.header.Sequence"),
      field: "Task_Sequence",
      cellRenderer: (params) => (params.value ? params.value : "-")
    },

    {
      headerName: this.translateService.instant("Mytasks.header.WorkOrder"),
      field: "WorkOrder"
    },
    { headerName: this.translateService.instant("Mytasks.header.AssetID"), field: "AssetID" },
    { headerName: this.translateService.instant("Mytasks.header.AssetCode"), field: "AssetCode" },
    { headerName: this.translateService.instant("Mytasks.header.AssetName"), field: "AssetName" },
    {
      headerName: this.translateService.instant("Mytasks.header.Activity"),
      field: "Activity",
      //sort: "asc",
      cellRenderer: "cellRendererComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    { headerName: this.translateService.instant("Mytasks.header.Route"), field: "Route", cellRenderer: (params) => (params.value ? params.value : "-") },
    (this.rowSelection = "single")
  ];
  activeTabType = "Open";
  subsUserRoles: Subscription;
  subsTasksModified: Subscription;
  subsEmitOpenDetailedMaintenance: Subscription;
  subsEmitClosedDetailedMaintenance: Subscription;
  assetNameInputArray = [];
  getAllFeaturesSubscribe: Subscription;
  getFilteredFeaturesByFieldSubscribe: Subscription;
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["type"] === "checkbox" && event.target["id"] === "assigntome") {
      if ((<HTMLInputElement>event.target).checked) {
        (<HTMLInputElement>event.target).checked = false;
      } else {
        (<HTMLInputElement>event.target).checked = true;
      }
      this.assignedToChanged(event);
    }
  }
  //
  constructor(
    private authenticationService: AuthenticationService,
    private query: QueryLayers,
    private assetDetail: AssetDetailService,
    public esritools: EsriMapComponent,
    public objGlobals: Globals,
    private genericLayerService: GenericLayerService,
    private crewManagementService: CrewManagementService,
    private objAssetDetailService: AssetDetailService,
    public genericMapMethodsService: GenericMapMethodsService,
    private translateService: TranslateService,
    public eventEmitersService: EventEmitersService,
    private userService: UserService
  ) {
    this.assetNameInputArray = [];
    this.eventEmitersService.inventoryLayersData.forEach(
      function (layer) {
        if (layer.visible) {
          this.assetNameInputArray.push(layer.layerName);
          // RSAM JIRA ID 1101
          if (layer.layerName == "Support") {
            const signLyr = this.authenticationService.currentUserValue.layerLevelInfo.filter(
              function (layer) {
                if (parseInt(layer.gisLayerId, 10) === parseInt(this.authenticationService.currentUserValue.serviceLyrInfo.signId, 10)) {
                  return layer;
                }
              }.bind(this)
            )[0];
            const lightLyr = this.authenticationService.currentUserValue.layerLevelInfo.filter(
              function (layer) {
                if (parseInt(layer.gisLayerId, 10) === parseInt(this.authenticationService.currentUserValue.serviceLyrInfo.lightId, 10)) {
                  return layer;
                }
              }.bind(this)
            )[0];
            if (signLyr) {
              this.assetNameInputArray.push("Sign");
            }
            if (lightLyr) {
              this.assetNameInputArray.push("Light");
            }
          }
        }
      }.bind(this)
    );
    this.currentUser = this.authenticationService.currentUserValue;
    this.subsUserRoles = this.userService.getRoleslist().subscribe((evt) => {
      this.objGlobals.userRoles = evt;
    });
    this.frameworkComponents = { cellRendererComponent: ButtonRenderComponent };
    this.subsTasksModified = this.objAssetDetailService.tasksModified.subscribe((result) => {
      if (!this.selectedData || this.selectedData.length === 0) {
        //  this.getMyTasks();

        const objInputOpen = { AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
        objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        const objInputClosed = { AssetName: this.assetNameInputArray, Status: ["Closed"], CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(), AssetStatus: ["Active"] };
        objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        //TSDRSAM-1782
        if (this.checkboxChecked) {
          objInputOpen["Worker"] = [this.currUser.username];
          objInputClosed["Worker"] = [this.currUser.username];
        }
        // RSAN JIRA ID 941
        if (this.activeTabType == "Open") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
        }
        if (this.activeTabType == "Closed") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
        }
      } else {
        console.log("selected from map");
        this.getSelectedDataTasks(this.selectedData, this.checkboxChecked);
      }
    });
    this.subsEmitOpenDetailedMaintenance = this.eventEmitersService.emitOpenDetailedMaintenance.subscribe((opentasks) => {
      this.openTasks = opentasks.tasks;
      this.rowData = [];
      for (let i = 0; i < this.openTasks.length; i++) {
        this.rowData.push(this.openTasks[i]);
      }

      if (this.gridApi) {
        this.gridApi.setRowData(this.rowData);
        this.gridApi.sizeColumnsToFit();
      }
      if (this.gridApiOpt) {
        this.gridApiOpt.setRowData(this.rowData);
        this.gridApiOpt.sizeColumnsToFit();
      }
      if (!this.selectedData || this.selectedData.length === 0) {
        this.addGrapicsBasedOnTab("Open", this.openTasks);
      }
      // TSDRSAM-1067 By Manikantha
      this.groupByToolkitrecords(this.openTasks, this.activityToolKitList);
      this.loading = false;
    });
    this.subsEmitClosedDetailedMaintenance = this.eventEmitersService.emitClosedDetailedMaintenance.subscribe((closedtasks) => {
      this.completedTasks = closedtasks.tasks;
      this.rowDataClosed = [];
      console.log(closedtasks);
      for (let i = 0; i < this.completedTasks.length; i++) {
        this.rowDataClosed.push(this.completedTasks[i]);
      }

      if (this.gridApiClosed) {
        this.gridApiClosed.setRowData(this.rowDataClosed);
        this.gridApiClosed.sizeColumnsToFit();
      }
      // RSAN JIRA ID 941
      if (!this.selectedData || this.selectedData.length === 0) {
        this.addGrapicsBasedOnTab("Closed", this.completedTasks);
      }
      this.loading = false;
    });
  }

  ngOnInit(): void {
    // TSDRSAM-1067 By Manikantha
    if (this.map.getLayer("sms-tasks-layer")) {
      this.taskGraphicsLyr = this.map.getLayer("sms-tasks-layer");
    } else {
      this.taskGraphicsLyr = new this.genericMapMethodsService.GraphicsLayer({ id: "sms-tasks-layer" });
      this.map.addLayer(this.taskGraphicsLyr);
    }
    this.taskGraphicsLyr.on(
      "click",
      function (evt) {
        let AssetID;
        if (evt.graphic.attributes.ParentID) {
          AssetID = evt.graphic.attributes.ParentID;
        } else {
          AssetID = evt.graphic.attributes.AssetID;
        }
        // TSSDRSAM 1109
        this.gridApi.deselectAll();
        this.eventEmitersService.selectAssetforTasks(AssetID, evt);
      }.bind(this)
    );
    const ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    ftrLyrActivity.on(
      "Load",
      function (evt) {
        this.activityLyrDomines = evt.layer.types;
      }.bind(this)
    );
    this.loading = true;
    this.activityToolKitListGroup = [];
    console.log(this.Point);
    this.currUser = this.currentUser;

    this.getAllFeaturesSubscribe = this.genericLayerService.getAllFeatures(this.currentUser.serviceLyrInfo.activityToolkitId).subscribe(
      function (results: any) {
        console.log("toolkit list");
        console.log(results.features);
        this.activityToolKitList = results.features;
        this.showtools = false;
      }.bind(this)
    );

    if (!this.selectedData || this.selectedData.length === 0) {
      this.checkboxChecked = true;
      const objInputOpen = { AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
      objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
      // 16 hours = 57600000(16 * 60 * 60 *1000)
      const objInputClosed = { AssetName: this.assetNameInputArray, Status: ["Closed"], CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(), AssetStatus: ["Active"] };
      objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
      if (this.checkboxChecked) {
        objInputOpen["Worker"] = [this.currUser.username];
        objInputClosed["Worker"] = [this.currUser.username];
      }
      // RSAN JIRA ID 941
      if (this.activeTabType == "Open") {
        this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
      }
      if (this.activeTabType == "Closed") {
        this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
      }
    } else {
      console.log("selected from map");
      this.getSelectedDataTasks(this.selectedData, this.checkboxChecked);
    }
    //Jira Id 935
    this.objAssetDetailService.getAssetIDFormat(this.Query, this.QueryTask, this.FeatureLayer);
    //ADA fix
    //document.getElementById("closeTaskTable").focus();

    //  var alertZ = document.getElementById('ag-20-of-page');
    //  alertZ.innerText ="hello"
  }
  ngOnDestroy(): void {
    // TSDRSAM-814
    this.map.graphics.clear();
    // TSDRSAM-1067 By Manikantha
    if (this.taskGraphicsLyr) this.taskGraphicsLyr.clear();
  }
  showToolkit(): void {
    this.showtools = !this.showtools;
  }

  refreshGridData(): void {
    try {
      if (this.gridApi) {
        const rows = [];
        const closedRows = [];

        for (let i = 0; i < this.rowData.length; i++) {
          const row = this.gridApi.getDisplayedRowAtIndex(i);
          rows.push(row);
        }

        for (let i = 0; i < this.rowDataClosed.length; i++) {
          const row = this.gridApi.getDisplayedRowAtIndex(i);
          closedRows.push(row);
        }

        this.gridApi.redrawRows({ rowNodes: rows });
        this.gridApi.sizeColumnsToFit();
        this.gridApiOpt.redrawRows({ rowNodes: rows });
        this.gridApiOpt.sizeColumnsToFit();
        this.gridApiClosed.redrawRows({ rowNodes: closedRows });
        this.gridApiClosed.sizeColumnsToFit();
      }
    } catch (error) {
      console.error(error);
    }
  }
  onGridReadyOpen(evtOpen): void {
    try {
      this.gridApi = evtOpen.api;
      this.gridColumnApi = evtOpen.columnApi;
      this.gridApi.sizeColumnsToFit();
    } catch (error) {
      console.error(error);
    }
  }
  onGridReadyClosed(evtClosed): void {
    try {
      this.gridApiClosed = evtClosed.api;
      this.gridColumnApiClosed = evtClosed.columnApi;
      this.gridApiClosed.sizeColumnsToFit();
    } catch (error) {
      console.error(error);
    }
  }
  onGridReadyOpt(evtOpt): void {
    try {
      this.gridApiOpt = evtOpt.api;
      this.gridColumnApiOpt = evtOpt.columnApi;
      this.gridApiOpt.sizeColumnsToFit();
    } catch (error) {
      console.error(error);
    }
  }
  onRowEdit(task): void {
    try {
      this.showtask = false;
      this.openTaskEditorAg(task);
    } catch (error) {
      console.error(error);
    }
  }
  onSelectionChangedOpen(evt): void {
    const selectedRows = evt.api.getSelectedRows();
    this.selectRowDataAg(selectedRows);
  }
  onSelectionChangedOpt(evt): void {
    const selectedRows = evt.api.getSelectedRows();
    this.selectRowDataAg(selectedRows);
  }
  onSelectionChangedClosed(evt) {
    const selectedRows = evt.api.getSelectedRows();
    this.selectRowDataAg(selectedRows);
  }
  tabChanged(event) {
    let name = "";
    if (event.index == 2) {
      name = "closed";
    } else {
      name = "open";
    }
    try {
      // RSAN JIRA ID 941
      if (!this.selectedData || this.selectedData.length === 0) {
        const objInputOpen = { AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
        objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        const objInputClosed = { AssetName: this.assetNameInputArray, Status: ["Closed"], CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(), AssetStatus: ["Active"] };
        objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        if (this.checkboxChecked) {
          objInputOpen["Worker"] = [this.currUser.username];
          objInputClosed["Worker"] = [this.currUser.username];
        }
        if (name === "open") {
          this.activeTabType = "Open";
          this.showtoolkitbtn = true;
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
        } else {
          this.activeTabType = "Closed";
          this.showtoolkitbtn = false;
          this.showtools = false;

          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
        }
        // RSAN JIRA ID 941
      } else {
        console.log("selected from map");
        // TSDRSAM-1067 By Manikantha
        if (name === "open") {
          this.activeTabType = "Open";
          this.showtoolkitbtn = true;
        } else {
          this.activeTabType = "Closed";
          this.showtoolkitbtn = false;
          this.showtools = false;
        }
        this.getSelectedDataTasks(this.selectedData, this.checkboxChecked);
      }

      this.refreshGridData();
    } catch (error) {
      console.error(error);
    }
  }

  getOpenAndCompletedTasks(myTasks) {
    try {
      this.rowData = [];
      this.rowDataClosed = [];

      if (myTasks.length <= 0) {
        this.loading = false;
      } else {
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        this.completedTasks = myTasks.filter((task) => task.attributes.Status === "Closed" && task.attributes.CompletionDate > new Date(Date.now() - 1).getTime() - 57600000);
        console.log(this.completedTasks);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.completedTasks.length; i++) {
          this.rowDataClosed.push(this.completedTasks[i].attributes);
        }

        if (this.gridApiClosed) {
          this.gridApiClosed.setRowData(this.rowDataClosed);
          this.gridApiClosed.sizeColumnsToFit();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getSelectedDataTasks(data, assignedToMe) {
    try {
      const allObjectIDList = [];
      const assetIDsList = [];
      data.forEach((layer) => {
        const objectIDList = [];
        const otherAttributes = [];
        layer.Data.forEach((asset) => {
          if (asset.attributes.AssetID) {
            assetIDsList.push(asset.attributes.AssetID);
          }

          objectIDList.push(asset.attributes.OBJECTID);
          otherAttributes.push({
            ObjectID: asset.attributes.OBJECTID,
            AssetCode: asset.attributes.AssetCode,
            Route: asset.attributes.Route
          });
        });
        allObjectIDList.push({
          LayerName: layer.LayerName.layerName,
          layerObj: layer.LayerName.lyrObj,
          RelatedData: objectIDList,
          OtherAttributes: otherAttributes
        });
      });
      if (assignedToMe) {
        const objInputOpen = { AssetID: assetIDsList, Worker: [this.currUser.username], AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
        objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        const objInputClosed = {
          AssetID: assetIDsList,
          Worker: [this.currUser.username],
          AssetName: this.assetNameInputArray,
          Status: ["Closed"],
          CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(),
          AssetStatus: ["Active"]
        };
        objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // RSAN JIRA ID 941
        if (this.activeTabType == "Open") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
        }
        if (this.activeTabType == "Closed") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
        }
      } else {
        const objInputOpen = { AssetID: assetIDsList, AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
        objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        const objInputClosed = {
          AssetID: assetIDsList,
          AssetName: this.assetNameInputArray,
          Status: ["Closed"],
          CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(),
          AssetStatus: ["Active"]
        };
        objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];

        if (this.activeTabType == "Open") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
        }
        if (this.activeTabType == "Closed") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
        }
      }

      // this.querySpacialRelatedTasks(data, assignedToMe);
    } catch (error) {
      console.error(error);
    }
  }

  querySpacialRelatedTasks(data, assignedToMe) {
    try {
      let count = 0;
      const intTasks = [];
      data.forEach(
        function (layer) {
          let idcsv = "";
          layer.Data.forEach((feature) => {
            idcsv += "'" + feature.attributes.AssetID + "',";
          });
          idcsv = idcsv.substring(0, idcsv.length - 1);
          this.getRecordsByIdsSubscribe = this.genericLayerService.getRecordsByIds(this.currentUser.serviceLyrInfo.maintenanceId, idcsv).subscribe(
            function (results: any) {
              console.log(results);
              count++;

              if (results && results.features && results.features.length > 0) {
                // tslint:disable-next-line:prefer-for-of
                for (let ind = 0; ind < results.features.length; ind++) {
                  intTasks.push(results.features[ind]);
                }
              }

              if (count === data.length) {
                if (assignedToMe) {
                  this.myTasks = intTasks.filter(
                    function (task) {
                      if (task.attributes.Worker === this.currentUser.username) {
                        return task;
                      }
                    }.bind(this)
                  );
                } else {
                  this.myTasks = intTasks;
                }
              }
            }.bind(this)
          );
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  selectRowData(event) {
    try {
      /* STORE ASSET ID AND TASK STATUS */
      const assetID = event.attributes.AssetID;
      //const taskStatus = event.attributes.Status;

      /* GET LAYER OBJECT FOR CURRRENT TASK ASSET*/
      const prefix = assetID.slice(0, 3);
      //Jira-933
      const assetClass = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.LayerCode === prefix)[0].attributes.Name;
      const lyrObj = this.map.smsLayerdata.userLayers.filter((layer) => layer.layerName === assetClass[0].value)[0].lyrObj;

      /* CREATE QUERY TO FETCH ASSET DETAILS*/
      const dataQuery = new this.Query();
      const dataQueryTask = new this.QueryTask(lyrObj.url);
      dataQuery.where = "AssetID='" + assetID + "'";
      dataQuery.outFields = ["*"];
      dataQuery.returnGeometry = true;

      /* EXECUTE QUERY*/
      dataQueryTask.execute(dataQuery).then(
        function (result) {
          /*SET ORANFGE PICTURE MARKER*/
          const imageUrl = "assets/images/marker_orange.png";
          const width = 20;
          const height = 20;
          const graphic = result.features[0];
          // TSDRSAM-975 By Manikantha
          graphic.setSymbol(new this.PictureMarkerSymbol(imageUrl, width, height).setOffset(0, 5));
          this.taskGraphicsLyr.add(graphic);
          this.map.centerAndZoom(graphic.geometry, 15);
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  // TSDRSAM 1109
  selectRowDataAg(event) {
    try {
      let AssetID;
      if (event[0].ParentID) {
        AssetID = event[0].ParentID;
      } else {
        AssetID = event[0].AssetID;
      }
      if (event[0].GeomWKT) {
        const imageUrl = "assets/images/marker_orange.png";
        const width = 20;
        const height = 20;
        // TSDRSAM-975 By Manikantha
        const graphic = new this.Graphic(new this.Point(JSON.parse(event[0].GeomWKT)), new this.PictureMarkerSymbol(imageUrl, width, height).setOffset(0, 5));
        graphic.setSymbol(new this.PictureMarkerSymbol(imageUrl, width, height).setOffset(0, 5));
        graphic.setAttributes(event[0]);
        this.map.centerAndZoom(graphic.geometry, 15);
      }
      // TSDRSAM 1109
      if (!this.selectedData || this.selectedData.length === 0) {
        this.eventEmitersService.selectAssetforTasks(AssetID, event);
      } else {
        let selected;
        let index;
        let selectedLayer;

        for (let i = 0; i < this.eventEmitersService.selectedFeaturesOnMap.length; i++) {
          selected = this.eventEmitersService.selectedFeaturesOnMap[i].Data.find((d) => d.attributes.AssetID === AssetID);
          index = this.eventEmitersService.selectedFeaturesOnMap[i].Data.findIndex((d) => d.attributes.AssetID === AssetID);
          selectedLayer = i;

          if (selected) {
            break;
          }
        }
        this.eventEmitersService.setSelectedLayerIndexOnData(selectedLayer, index);
        this.eventEmitersService.setShowCard(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  /*Selected from Select Tool*/
  setThis() {
    return this;
  }
  async getRelatedTasks(data, allObjectIDList, allOtherAttributes, isMyTask) {
    try {
      this.rowData = [];
      this.rowDataClosed = [];
      allObjectIDList.forEach(async (layer) => {
        if (layer.LayerName === "Support") {
          const relatedQuery = new this.RelationshipQuery();
          relatedQuery.objectIds = layer.RelatedData;

          relatedQuery.outFields = ["*"];
          relatedQuery.relationshipId = 5; // need to cofigure dynamically
          const _this = this.setThis();
          await layer.layerObj.queryRelatedFeatures(relatedQuery, function (result) {
            console.log("Related Result", result);
            layer.RelatedData.forEach((object) => {
              if (result[object] !== undefined && isMyTask) {
                if (result[object].features[0].attributes.Worker === "TestUser") {
                  let marker = "";

                  if (result[object].features[0].attributes.Status === "Closed") {
                    marker = "assets/images/marker_green.png";
                  } else {
                    marker = "assets/images/marker_blue.png";
                  }

                  _this.setMarker(object, marker, layer.layerObj);

                  /*Fetch Asset Code and Route */
                  const selectedAttributes = layer.OtherAttributes.filter((attr) => attr.ObjectID === object);
                  result[object].features[0].attributes.assetCode = selectedAttributes[0].AssetCode;
                  result[object].features[0].attributes.Route = selectedAttributes[0].Route;
                  _this.selectedDataTasks.push(result[object].features[0]);
                } else {
                  let marker = "";

                  if (result[object].features[0].attributes.Status === "Closed") {
                    marker = "assets/images/marker_green.png";
                  } else {
                    marker = "assets/images/marker_blue.png";
                  }

                  _this.setMarker(object, marker, layer.layerObj);

                  /*Fetch Asset Code and Route */
                  const selectedAttributes = layer.OtherAttributes.filter((attr) => attr.ObjectID === object);
                  result[object].features[0].attributes.assetCode = selectedAttributes[0].AssetCode;
                  result[object].features[0].attributes.Route = selectedAttributes[0].Route;
                  _this.selectedDataTasks.push(result[object].features[0]);
                }
              }
            });
            _this.openTasks = _this.selectedDataTasks.filter((task) => task.attributes.Status !== "Closed");
            _this.completedTasks = _this.selectedDataTasks.filter((task) => task.attributes.Status === "Closed");

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.openTasks.length; i++) {
              this.rowData.push(this.openTasks[i].attributes);
            }

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.completedTasks.length; i++) {
              this.rowDataClosed.push(this.completedTasks[i].attributes);
            }

            if (this.gridApi) {
              this.gridApi.setRowData(this.rowData);
              this.gridApi.sizeColumnsToFit();
            }

            if (this.gridApiOpt) {
              this.gridApiOpt.setRowData(this.rowData);
              this.gridApiOpt.sizeColumnsToFit();
            }

            if (this.gridApiClosed) {
              this.gridApiClosed.setRowData(this.rowDataClosed);
              this.gridApiClosed.sizeColumnsToFit();
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  setMarker(object, marker, layerObj) {
    try {
      const query = new this.Query();
      const queryTask = new this.QueryTask(layerObj.url);
      query.where = "OBJECTID=" + object;
      query.returnGeometry = true;
      const width = 20;
      const height = 20;
      const _this = this.setThis();
      const pictureMarker = new _this.PictureMarkerSymbol(marker, width, height);
      // TSDRSAM-975 By Manikantha
      pictureMarker.setOffset(0, 5);
      queryTask.execute(query, function (result) {
        if (result) {
          console.log("Not Set Marker", result);
          result.features[0].setSymbol(pictureMarker);
          // TSDRSAM-1067 By Manikantha
          // _this.map.graphics.add(result.features[0]);
          _this.taskGraphicsLyr.add(result.features[0]);
        }

        console.log("Set Marker", result);
      });
    } catch (error) {
      console.error(error);
    }
  }
  openTaskEditor(tasks) {
    try {
      const assetId = tasks.attributes.WorkOrder;
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "WorkOrder", assetId).subscribe((results: any) => {
        this.objGlobals.selectedTask = results.features[0].attributes;
        this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService
          .getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", results.features[0].attributes.MaintID)
          .subscribe((results: any) => {
            this.objGlobals.userSelectedActivity = results.features[0].attributes.Activity;
            this.showtask = true;
          });
      });
    } catch (error) {
      console.error(error);
    }
  }
  openTaskEditorAg(tasks) {
    try {
      // const assetId = tasks.data.MaintID;
      this.objGlobals.selectedTask = tasks.data;
      this.objGlobals.userSelectedActivity = tasks.data.Activity;
      this.showtask = true;
    } catch (error) {
      console.error(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showTaksTab(event) {
    console.log("showing tasks");
    this.showtask = false;
  }
  closeSmartTable() {
    try {
      this.eventEmitersService.setShowGrid(false);
      this.closeTable.emit(true);
      // TSDRSAM-1067 By Manikantha
      if (this.taskGraphicsLyr) this.taskGraphicsLyr.clear();

      if (this.selectedData && this.selectedData.length > 0) {
        this.selectedData = [];
      }
      // For ADA story - by Subba
      let mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
      if (mainPageTools.length > 0) {
        mainPageTools.forEach((element) => {
          element.setAttribute("tabindex", "0");
        });
      }
      let bMapTools = document.querySelectorAll('[data-BMapTabbing="yes"]');
      if (bMapTools.length > 0) {
        bMapTools.forEach((element) => {
          element.setAttribute("tabindex", "0");
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  assignedToChanged(event) {
    try {
      console.log(event);

      if (event.checked) {
        this.checkboxChecked = true;
      } else {
        this.checkboxChecked = false;
      }

      if (!this.selectedData || this.selectedData.length === 0) {
        const objInputOpen = { AssetName: this.assetNameInputArray, Status: ["Open"], AssetStatus: ["Active"] };
        objInputOpen["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        // 16 hours = 57600000(16 * 60 * 60 *1000)
        const objInputClosed = { AssetName: this.assetNameInputArray, Status: ["Closed"], CompletionDateFrom: new Date(new Date(Date.now() - 1).getTime() - 57600000).toISOString(), AssetStatus: ["Active"] };
        objInputClosed["Region" + this.currentUser.selectedRegion.regionLevel] = [this.currentUser.selectedRegion.regionName.toString()];
        if (this.checkboxChecked) {
          objInputOpen["Worker"] = [this.currUser.username];
          objInputClosed["Worker"] = [this.currUser.username];
        }
        // RSAN JIRA ID 941
        if (this.activeTabType == "Open") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputOpen, "Open");
        }
        if (this.activeTabType == "Closed") {
          this.eventEmitersService.getUpdatedDetailedMaintenance(objInputClosed, "Closed");
        }
      } else {
        this.getSelectedDataTasks(this.selectedData, this.checkboxChecked);
      }

      this.refreshGridData();
    } catch (error) {
      console.error(error);
    }
  }

  groupByToolkitrecords(taskList, toolkitList) {
    try {
      const result = [];
      this.activityToolKitListGroup = [];
      taskList.reduce(
        function (res, value1, index) {
          // TSDRSAM-1067 By Manikantha
          if (value1.Status === "Open") {
            if (index === 0) {
              res = {};
            }

            const activityLyrDominesDES = this.activityLyrDomines;
            // TSDRSAM-1067 By Manikantha
            const layerName = value1.AssetName;
            const toolkitlistForSelTask = toolkitList.filter(
              function (result) {
                const assetclassid = activityLyrDominesDES.filter(
                  function (element) {
                    if (element.name === layerName) {
                      return element;
                    }
                  }.bind(this)
                )[0].id;
                // TSDRSAM-1067 By Manikantha
                if (result.attributes.Activity === value1.Activity && result.attributes.AssetClass === assetclassid) {
                  return result;
                }
              }.bind(this)
            );

            toolkitlistForSelTask.forEach(function (tool) {
              if (!res[tool.attributes.ToolName]) {
                res[tool.attributes.ToolName] = {
                  toolName: tool.attributes.ToolName,
                  count: 0
                };
                result.push(res[tool.attributes.ToolName]);
              }

              res[tool.attributes.ToolName].count += 1;
            });
          }

          return res;
        }.bind(this),
        {}
      );
      console.log(result);
      this.activityToolKitListGroup = result;
    } catch (error) {
      console.error(error);
    }
  }

  addGrapicsBasedOnTab(status: string, listOfTasks): void {
    try {
      // TSDRSAM-1067 By Manikantha
      if (this.taskGraphicsLyr) this.taskGraphicsLyr.clear();
      for (let i = 0; i < listOfTasks.length; i++) {
        if (listOfTasks[i].Status === status) {
          let imageUrl = "";
          if (listOfTasks[i].Status === "Closed") {
            imageUrl = "assets/images/marker_blue.png";
          } else {
            imageUrl = "assets/images/marker_orange.png";
          }
          const width = 20;
          const height = 23;
          let conspoint;
          if (listOfTasks[i].Shape.slice(0, listOfTasks[i].Shape.indexOf(" ")) == "POINT") {
            conspoint = new this.Point(
              parseFloat(listOfTasks[i].Shape.slice(listOfTasks[i].Shape.indexOf("(") + 1, listOfTasks[i].Shape.split(" ", 2).join(" ").length)),
              parseFloat(listOfTasks[i].Shape.slice(listOfTasks[i].Shape.split(" ", 2).join(" ").length, listOfTasks[i].Shape.indexOf(")"))),
              this.map.spatialReference
            );
          }
          // TSDRSAM-693
          else {
            conspoint = new this.Point(
              parseFloat(listOfTasks[i].Shape.slice(listOfTasks[i].Shape.indexOf("(") + 1, listOfTasks[i].Shape.split(" ", 2).join(" ").length)),
              parseFloat(listOfTasks[i].Shape.slice(listOfTasks[i].Shape.split(" ", 2).join(" ").length, listOfTasks[i].Shape.split(",", 1).join(" ").length)),
              this.map.spatialReference
            );
          }
          // TSDRSAM-975 By Manikantha
          const graphic = new this.Graphic(conspoint, new this.PictureMarkerSymbol(imageUrl, width, height).setOffset(0, 5));
          graphic.setAttributes(listOfTasks[i]);
          // TSDRSAM-975 By Manikantha
          graphic.setSymbol(new this.PictureMarkerSymbol(imageUrl, width, height).setOffset(0, 5));
          this.taskGraphicsLyr.add(graphic);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
