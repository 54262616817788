/* eslint-disable camelcase */
import { Component, OnInit, Input } from "@angular/core";
import { AssetDetailService } from "../../../services/assetDetail.service";
import { Globals } from "../../globals";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../../services/user.service";
import { EsriMapComponent } from "../../../esri-map/esri-map.component";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { QueryLayers } from "src/app/services/queryLayers.service";
import { TranslateService } from "@ngx-translate/core";

import { EventEmitersService } from "../../../services/eventEmiters.service";
import { ImageCompressService, ResizeOptions } from "@artiomsu/ng2-image-compress";
import { isNumeric } from "rxjs/internal-compatibility";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { getMimeType } from "../../../shared/utils";

@AutoUnsubscribe()
@Component({
  selector: "sms-Assessment",
  templateUrl: "./assessment.component.html",
  styleUrls: ["../../select.component.scss"]
})
export class AssessmentComponent implements OnInit {
  emitactiveAssetChangedBySelect: Subscription;
  emitactiveAssetFeatureSubscribe: Subscription;
  getFilteredFeaturesByFieldSubscribe: Subscription;
  getUserListByClientSubscribe: Subscription;
  getFilteredFeaturesByObjectIdFieldsubscribe: Subscription;
  constructor(
    private assetDetailService: AssetDetailService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    public objGlobals: Globals,
    private userService: UserService,
    public esritools: EsriMapComponent,
    private authenticationService: AuthenticationService,
    private genericLayerService: GenericLayerService,
    private queryLayers: QueryLayers,
    private translateService: TranslateService,
    public eventEmitersService: EventEmitersService,
    private imageCompressService: ImageCompressService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.currentUser = this.authenticationService.currentUserValue;
    this.emitactiveAssetChangedBySelect = this.eventEmitersService.emitactiveAssetChangedBySelect.subscribe(() => {
      this.onAssetChangeEvent();
    });
    this.emitactiveAssetFeatureSubscribe = this.eventEmitersService.emitactiveAssetFeature.subscribe((actveAsset: any) => {
      this.activeAsset = actveAsset;
      this.onAssetChangeEvent();
    });
  }

  ngOnDestroy() {
    this.emitactiveAssetChangedBySelect.unsubscribe();
  }

  @Input() relatedAsset: any;
  @Input() activeAsset: any;
  @Input() webMercatorUtils: any;

  public assessmentsData = [];
  public modalReference: NgbModalRef;
  public UserList;
  ConditionList = [];
  AssetTypeList = [];
  //  Added by Sowjanya for JIRA ID:-54 on 08/25/2021
  DefectTypeList = [];
  public defecttypeSelected;
  public assetTypeListSelected;
  public conditionSelected;
  public userListSelected;
  public CommentText;
  public currentUser: IUser;
  public objectid = 0;
  public photosData = [];
  public linearAssetValues = false;
  public hideSpacingFromEdit = false;
  public assessmentlyr;
  public Spacing;
  public default;
  public valuesIncrement = [];
  public defaultCondition = [];
  public LineComment = [];
  public DatatItemsFromDoc;
  public SelectedValueFromDoc;
  samples: any = [];
  imageSrc: any;
  PhotoDate;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  sourceTag;
  MeasureValue;
  photoFl;
  UploadDocsWithoutAssessment;
  maxIncrements = 9;
  polyLineMessage = "";
  public showDateByOrder = false;
  public showSequenceByOrder = false;
  public isDisabled = false;
  public showSourceByOrder = false;

  uploadfileName;
  public imgBase64: any;

  ngOnInit() {
    this.relatedAsset = this.objGlobals.relatedAssetData;
    this.activeAsset = this.eventEmitersService.activeAssetFeature;

    if (this.relatedAsset) {
      const maintenanceData = this.relatedAsset.filter((obj) => obj.Layer.includes("Assessment"));

      if (maintenanceData.length > 0 && maintenanceData[0].RelatedData && maintenanceData[0].RelatedData.length > 0) {
        //console.log("do nothing");
      }
    }

    let assetId;

    if (this.activeAsset[0].AssetID) {
      assetId = this.activeAsset[0].AssetID;
    } else {
      assetId = this.activeAsset[0] && this.activeAsset[0].signAttribute ? this.activeAsset[0].signAttribute.AssetID : null;
    }

    this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.assessmentId, "AssetID", assetId).subscribe((results: any) => {
      this.assessmentsData = results.features;
      //added by sowjanya for JIRA ID-54 on 10/26/2021
      this.sortOrder();
    });
    this.assessmentlyr = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.assessmentId, { outFields: ["*"] });
    this.photoFl = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);
    this.userList();
    // Bring the Domain Values for Assessment Type and Conditions
    this.assessmentlyr.on("load", () => {
      this.AssetTypeList = [];
      this.ConditionList = [];
      this.DefectTypeList = [];
      const AssessTypeDomain = this.assessmentlyr.getDomain("AssessType");
      AssessTypeDomain.codedValues.forEach((data) => {
        this.AssetTypeList.push(data.code);
      });
      const ConditionDomain = this.assessmentlyr.getDomain("Condition");
      ConditionDomain.codedValues.forEach((data) => {
        this.ConditionList.push(data.code);
      });
      //  Added by Sowjanya for JIRA ID:-54 on 08/25/2021
      const DefectTypeDomain = this.assessmentlyr.getDomain("DefectType");
      DefectTypeDomain.codedValues.forEach((data) => {
        this.DefectTypeList.push(data.name);
      });
    });
  }

  //added by sowjanya for JIRA ID-54 on 10/26/2021
  sortOrder() {
    try {
      this.assessmentsData.sort((a, b) => {
        const x = a.attributes.InspectionDate;
        const y = b.attributes.InspectionDate;
        return x > y ? -1 : x < y ? 1 : 0;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onAssetChange(actveAsset) {
    try {
      let assetId;
      this.assessmentsData = [];

      if (actveAsset[0].AssetID) {
        assetId = actveAsset[0].AssetID;
      } else {
        assetId = actveAsset[0].signAttribute.AssetID;
      }

      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.assessmentId, "AssetID", assetId).subscribe((results: any) => {
        this.assessmentsData = results.features;
      });
    } catch (error) {
      console.error(error);
    }
  }

  onAssetChangeEvent() {
    try {
      let assetId;
      this.assessmentsData = [];

      if (this.eventEmitersService.selectedChildIndexOnData && this.eventEmitersService.activeAssetChangedBySelect && this.eventEmitersService.activeAssetChangedBySelect.attributes) {
        assetId = this.eventEmitersService.activeAssetChangedBySelect.attributes.AssetID;
      } else {
        assetId = this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID;
      }

      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.assessmentId, "AssetID", assetId).subscribe((results: any) => {
        this.assessmentsData = results.features;
      });
    } catch (error) {
      console.error(error);
    }
  }
  createNew(content) {
    try {
      this.ClearForms();

      if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
        this.linearAssetValues = true;
        this.hideSpacingFromEdit = true;
      } else {
        this.linearAssetValues = false;
      }

      this.userListSelected = this.currentUser.username;
      this.modalReference = this.modalService.open(content, { centered: true });
    } catch (error) {
      console.error(error);
    }
  }

  close() {
    this.ClearForms();
    this.modalReference.close();
  }

  ClearForms() {
    try {
      this.assetTypeListSelected = "";
      this.conditionSelected = "";
      this.userListSelected = "";
      this.defecttypeSelected = ""; //  Added by Sowjanya for JIRA ID:-54 on 08/25/2021
      this.CommentText = "";
      this.objectid = 0;
      this.Spacing = 1;
      this.valuesIncrement = [];
      this.defaultCondition.forEach((data, key) => {
        this.defaultCondition[key] = [];
        this.LineComment[key] = [];
      });
      this.UploadDocsWithoutAssessment = [];
      this.isDisabled = false;
    } catch (error) {
      console.error(error);
    }
  }

  SaveAssessment() {
    try {
      if (this.assetTypeListSelected === undefined || this.assetTypeListSelected === "") {
        Swal.fire(this.translateService.instant("Select.alertassessment.selectassessmenttype"));
      } else if (this.conditionSelected === undefined || this.conditionSelected === "") {
        Swal.fire(this.translateService.instant("Select.alertassessment.selectcondition"));
      } else if (this.userListSelected === undefined || this.userListSelected === "") {
        Swal.fire(this.translateService.instant("Select.alertassessment.selectinspector"));
      } else if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline" && this.valuesIncrement.length === 0) {
        Swal.fire(this.translateService.instant("Select.alertassessment.selectspacing"));
      } else {
        this.isDisabled = true;
        const features = new this.esritools.Graphic();
        const assetAttr: any = [];
        assetAttr.AssetID = this.activeAsset[0].AssetID;
        assetAttr.AssessType = this.assetTypeListSelected;
        assetAttr.Condition = this.conditionSelected;
        assetAttr.Inspector = this.userListSelected;
        assetAttr.DefectType = this.defecttypeSelected; //  Added by Sowjanya for JIRA ID:-54 on 08/25/2021
        assetAttr.Comment = this.CommentText;
        assetAttr.ModifiedBy = this.currentUser.userId;
        const AssessmentPolyline = [];

        if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
          if (this.valuesIncrement.length > 0) {
            this.valuesIncrement.forEach((data, key) => {
              AssessmentPolyline.push({
                Measure: this.valuesIncrement[key],
                condition: this.defaultCondition[key] ? this.defaultCondition[key] : "",
                Comment: this.LineComment[key] ? this.LineComment[key] : ""
              });
            });
            assetAttr.Assessment = JSON.stringify(AssessmentPolyline);
          }
        }

        if (this.objectid !== 0) {
          assetAttr.OBJECTID = this.objectid;
          assetAttr.LastModifyDate = new Date(Date.now()).getTime();
          features.setAttributes(assetAttr);
          this.assessmentlyr.applyEdits(
            null,
            [features],
            null,
            (evt) => {
              this.loadFeatures(null);
              Swal.fire(this.translateService.instant("Select.alertassessment.savesuccessful"));
              this.modalReference.close();
              // Added by Sowjanya Jira Id:-24 on 11/02/2021
              if (this.assetTypeListSelected === "Night") {
                this.eventEmitersService.setEventAssessment(this.conditionSelected);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          assetAttr.InspectionDate = new Date(Date.now()).getTime();
          features.setAttributes(assetAttr);
          this.assessmentlyr.applyEdits(
            [features],
            null,
            null,
            (evt) => {
              const upfeatures = new this.esritools.Graphic();
              const upassetAttr: any = [];
              upassetAttr.OBJECTID = evt[0].objectId;
              upassetAttr.AssessmentID = evt[0].objectId;
              upfeatures.setAttributes(upassetAttr);
              this.assessmentlyr.applyEdits(null, [upfeatures], null);
              this.loadFeatures(null);
              this.updateDocsWithAssessment(evt[0].objectId);
              Swal.fire(this.translateService.instant("Select.alertassessment.savesuccessful"));
              this.modalReference.close();
              // Added by Sowjanya Jira Id:-24 on 11/02/2021
              if (this.assetTypeListSelected === "Night") {
                this.eventEmitersService.setEventAssessment(this.conditionSelected);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  editTask(val, content) {
    try {
      this.ClearForms();
      this.hideSpacingFromEdit = false;

      if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
        this.linearAssetValues = true;

        if (val.Assessment == null) {
          this.hideSpacingFromEdit = true;
        }
      } else {
        this.linearAssetValues = false;
      }

      this.assetTypeListSelected = val.AssessType;
      this.conditionSelected = val.Condition;
      this.userListSelected = val.Inspector;
      this.CommentText = val.Comment;
      this.defecttypeSelected = val.DefectType; //  Added by Sowjanya for JIRA ID:-54 on 08/25/2021
      this.objectid = val.OBJECTID;

      if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
        if (val.Assessment && val.Assessment.length > 0) {
          JSON.parse(val.Assessment).forEach((data, key) => {
            this.LineComment[key] = data.Comment;
            this.valuesIncrement[key] = data.Measure;
            this.defaultCondition[key] = data.condition;
          });
        }
      }

      this.modalReference = this.modalService.open(content, { centered: true });
      this.loadFeatures(null);
    } catch (error) {
      console.error(error);
    }
  }

  deleteTask(val) {
    try {
      const features = new this.esritools.Graphic();
      features.setAttributes(val.attributes);
      Swal.fire({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        //TSDRSAM-731
        title: this.translateService.instant("Select.assessment.deleteassessmentconfirmmsg"),
        cancelButtonText: this.translateService.instant("Select.alertassessment.cancelbtn"),
        confirmButtonText: this.translateService.instant("Select.alertassessment.delete")
      }).then((result) => {
        if (result.value) {
          this.queryLayers.deleteAsset(this.assessmentlyr, features).then(() => {
            this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService
              .getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.documentId, "AssessmentID", features.attributes.OBJECTID)
              .subscribe((results: any) => {
                if (results && results.features) {
                  results.features.forEach((data) => {
                    console.log(data);
                    const delphoto = new this.esritools.Graphic();
                    delphoto.setAttributes(data.attributes);
                    this.queryLayers.deleteAsset(this.photoFl, delphoto).then((results: any) => {
                      this.loadFeatures(null);
                    });
                  });
                }
              });

            this.loadFeatures(null);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  loadFeatures(evt) {
    try {
      let assetId;
      this.assessmentsData = [];

      if (this.activeAsset[0].AssetID) {
        assetId = this.activeAsset[0].AssetID;
      } else {
        assetId = this.activeAsset[0].signAttribute.AssetID;
      }

      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.assessmentId, "AssetID", assetId).subscribe((results: any) => {
        this.assessmentsData = results.features;
        //added by sowjanya for JIRA ID-54 on 10/26/2021
        this.sortOrder();
      });
      //added by sowjanya for JIRA ID-54 on 10/26/2021
      if (this.objectid !== 0) {
        this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.assessmentId, "AssetID", assetId).subscribe((results: any) => {
          this.assessmentsData = results.features;
          this.assessmentsData.sort((a, b) => {
            const x = a.attributes.LastModifyDate;
            const y = b.attributes.LastModifyDate;
            return x > y ? -1 : x < y ? 1 : 0;
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  userList() {
    try {
      this.getUserListByClientSubscribe = this.userService.getUserNamesByClient(this.currentUser.clientId).subscribe((evt: IUser[]) => {
        this.UserList = [];
        evt.forEach((data) => {
          this.UserList.push(data);
        });
        this.UserList.sort((a, b) => {
          const x = a.firstName.toLowerCase();
          const y = b.firstName.toLowerCase();

          if (x < y) {
            return -1;
          }

          if (x > y) {
            return 1;
          }

          return 0;
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  updateDocsWithAssessment(AssID) {
    try {
      if (this.UploadDocsWithoutAssessment.length > 0) {
        this.UploadDocsWithoutAssessment.forEach((data) => {
          const photoAsset = new this.esritools.Graphic();
          const assetAttr: any = [];
          assetAttr.AssessmentID = AssID;
          assetAttr.OBJECTID = data;
          photoAsset.setAttributes(assetAttr);
          this.queryLayers.updateAsset(this.photoFl, photoAsset).then((result) => {
            console.log(result);
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  setThis() {
    return this;
  }
  async uploadNew(files, i?, Conditionvalue?, elementIndex?) {
    try {
      // 25788 Start
      if (files.length > 0) {
        // TSDRSAM-510 Manikantha V
        const fileElement = environment.DocumentSupportFormats.includes(files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase());
        if (!fileElement) {
          Swal.fire(this.translateService.instant("Select.file.unsupportedformat"));
          // TSDRSAM-778
          if (elementIndex != undefined && isNumeric(elementIndex)) {
            const inputIncri: any = document.getElementById("imageInputFile" + elementIndex);
            inputIncri.value = null;
          } else {
            const input: any = document.querySelector("input[type=file]");
            input.value = null;
          }

          return;
        }
        const _this = this.setThis();
        // 9/14/20 jcc  why was this (wrong) file type hardcoded?
        // 7/26/2021 / Jcc Fixed hard coded values in assetDetails.ts replecated code frome there .
        // TSDRSAM-510 Manikantha V
        const doctyp = files[0].name.split(".")[files[0].name.split(".").length - 1].toUpperCase();
        // 25788 End
        const photoAsset = new this.esritools.Graphic();
        const assetAttr: any = [];
        assetAttr.AssetID = this.activeAsset[2].attributes.AssetID;
        assetAttr.AssessmentID = this.objectid;
        assetAttr.SourceTag = "Assessment" + (Conditionvalue ? "/" + Conditionvalue : "/" + this.conditionSelected);
        assetAttr.DocType = doctyp;
        assetAttr.DocID = this.objectid;
        assetAttr.DocDate = new Date(Date.now()).getTime();
        assetAttr.Filename = files[0].name;

        //092621 jcc add AssetName attribute
        assetAttr.AssetName = this.activeAsset[1].layerName;

        if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
          assetAttr.Asset_Segment_Measure = i;
        }

        photoAsset.setAttributes(assetAttr);

        this.photoFl.applyEdits(
          [photoAsset],
          null,
          null,
          (res) => {
            if (res[0].success) {
              _this.assetDetailService.UpdateDocId(_this.esritools.Graphic, res[0].objectId, _this.photoFl);
              this.uploadfileName = files[0].name;
              const options = new ResizeOptions();
              options.Resize_Max_Height = 500;
              options.Resize_Max_Width = 500;
              options.Resize_Quality = 100;
              options.Resize_Type = "image/jpg";

              //70
              const reader = new FileReader();
              let fileInBase64Data;
              reader.readAsDataURL(files[0]);
              reader.onload = () => {
                fileInBase64Data = reader.result;
              };

              // TSDRSAM-510 Manikantha V
              if (files[0].name.split(".")[files[0].name.split(".").length - 1].toUpperCase() == "PDF") {
                _this.assetDetailService.uploadImage(files[0], res[0].objectId, this.currentUser.clientId).subscribe(
                  (uploadres: any) => {
                    if (uploadres.message === "Photo Saved Successfully") {
                      if (this.objectid !== 0) {
                        Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                      } else {
                        this.UploadDocsWithoutAssessment.push(res[0].objectId);
                      }

                      photoAsset.attributes.OBJECTID = res[0].objectId;
                      // TSDRSAM-510 Manikantha V
                      this.samples.push({
                        label: photoAsset.attributes.Filename,
                        imgBase64: fileInBase64Data.split(",")[1],
                        url: `data:application/pdf;base64, ${fileInBase64Data.split(",")[1]}`,
                        attributes: photoAsset.attributes,
                        Date: photoAsset.attributes.DocDate,
                        sourceTag: photoAsset.attributes.SourceTag,
                        Measure: photoAsset.attributes.Asset_Segment_Measure
                      });

                      if (this.samples.length > 0) {
                        this.imageSrc = this.samples[0].url;
                        this.PhotoDate = this.samples[0].Date;
                        this.sourceTag = this.samples[0].sourceTag;
                        this.MeasureValue = this.samples[0].Measure;
                        this.imgBase64 = this.samples[0].imgBase64;
                      } else {
                        this.imageSrc = "";
                        this.PhotoDate = "";
                        this.sourceTag = "";
                        this.MeasureValue = "";
                        this.imgBase64 = "";
                      }

                      this.DescendingOrder("Date");
                    }
                  },
                  (err) => {
                    if (err.error.message === "File Format/Size is Not Correct") {
                      const featurese = [];
                      const features = new this.esritools.Graphic();
                      const ivdassetAttr: any = [];
                      ivdassetAttr.OBJECTID = res[0].objectId;
                      features.setAttributes(ivdassetAttr);
                      featurese.push(features);
                      this.photoFl
                        .applyEdits(null, null, featurese)
                        .then(
                          function () {
                            this.loadFeatures();
                            Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                          }.bind(this)
                        )
                        .catch((error) => {
                          console.log("===============================================");
                          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                          console.log("error = ", error);
                        });
                    } else {
                      console.log(err.error.message);
                    }
                  }
                );
              } else {
                _this.compressImage(files[0], options).then((newFileDataC: any) => {
                  _this.assetDetailService.uploadImage(newFileDataC, res[0].objectId, this.currentUser.clientId).subscribe(
                    (uploadres: any) => {
                      if (uploadres.message === "Photo Saved Successfully") {
                        if (this.objectid !== 0) {
                          Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                        } else {
                          this.UploadDocsWithoutAssessment.push(res[0].objectId);
                        }

                        photoAsset.attributes.OBJECTID = res[0].objectId;
                        // TSDRSAM-510 Manikantha V
                        this.samples.push({
                          label: photoAsset.attributes.Filename,
                          url: `data:image/png;base64, ${fileInBase64Data.split(",")[1]}`,
                          imgBase64: fileInBase64Data.split(",")[1],
                          attributes: photoAsset.attributes,
                          Date: photoAsset.attributes.DocDate,
                          sourceTag: photoAsset.attributes.SourceTag,
                          Measure: photoAsset.attributes.Asset_Segment_Measure
                        });

                        if (this.samples.length > 0) {
                          this.imageSrc = this.samples[0].url;
                          this.PhotoDate = this.samples[0].Date;
                          this.sourceTag = this.samples[0].sourceTag;
                          this.MeasureValue = this.samples[0].Measure;
                          this.imgBase64 = this.samples[0].imgBase64;
                        } else {
                          this.imageSrc = "";
                          this.PhotoDate = "";
                          this.sourceTag = "";
                          this.MeasureValue = "";
                          this.imgBase64 = "";
                        }

                        this.DescendingOrder("Date");
                      }
                    },
                    (err) => {
                      if (err.error.message === "File Format/Size is Not Correct") {
                        const featurese = [];
                        const features = new this.esritools.Graphic();
                        const ivdassetAttr: any = [];
                        ivdassetAttr.OBJECTID = res[0].objectId;
                        features.setAttributes(ivdassetAttr);
                        featurese.push(features);
                        this.photoFl
                          .applyEdits(null, null, featurese)
                          .then(
                            function () {
                              this.loadFeatures();
                              Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                            }.bind(this)
                          )
                          .catch((error) => {
                            console.log("===============================================");
                            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                            console.log("error = ", error);
                          });
                      } else {
                        console.log(err.error.message);
                      }
                    }
                  );
                });
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  getPhotoUrl(photoData) {
    return "Images/" + this.currentUser.clientId + "/" + photoData.attributes.OBJECTID + "." + photoData.attributes.Filename.split(".")[1];
  }

  viewDoc(content, att) {
    try {
      this.photosData = [];
      this.imgBase64 = "";
      this.imageSrc = "";
      this.samples = [];

      if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
        this.linearAssetValues = true;
        this.hideSpacingFromEdit = true;
      } else {
        this.linearAssetValues = false;
      }

      this.conditionSelected = att.Condition;
      this.objectid = att.OBJECTID;
      // TODO: do we need this ID for something?
      let assetId;

      if (this.activeAsset[0].AssetID) {
        assetId = this.activeAsset[0].AssetID;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        assetId = this.activeAsset[0].signAttribute.AssetID;
      }

      // this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.documentId,
      //   "AssetID", assetId).subscribe((results: any) => {
      //     results.features.forEach((data) => {
      //       this.photosData.push(data);
      //     });
      //     this.imageGenerate();
      //   });
      this.getFilteredFeaturesByObjectIdFieldsubscribe = this.genericLayerService
        .getFilteredFeaturesByObjectIdField(this.currentUser.serviceLyrInfo.documentId, "AssessmentID", this.objectid)
        .subscribe((results: any) => {
          results.features.forEach((data) => {
            this.photosData.push(data);
          });
          this.imageGenerate();
        });
      this.modalReference = this.modalService.open(content, { centered: true, windowClass: "asset-photo", size: "xl" });
    } catch (error) {
      console.error(error);
    }
  }

  CreateIncrements() {
    try {
      this.polyLineMessage = "";
      this.valuesIncrement = [];
      this.defaultCondition = [];
      let polylineLength = Math.round(this.activeAsset[2].attributes.Shape__Length);
      const minSpacing = Math.round(polylineLength / this.maxIncrements);
      if (this.default === undefined || this.default === "") {
        Swal.fire(this.translateService.instant("Select.alertassessment.provideconditionvalue"));
      } else if (this.Spacing === undefined || this.Spacing === "" || this.Spacing === 0) {
        Swal.fire(this.translateService.instant("Select.alertassessment.enterspacingvalue"));
      } else {
        this.valuesIncrement.push(0);
        this.defaultCondition.push(this.default);
        let idx = 0;
        for (let i = this.Spacing; i < polylineLength; i = i + this.Spacing) {
          if (idx + 1 == this.maxIncrements) {
            polylineLength = i;
            this.polyLineMessage = this.translateService.instant("Select.newassessment.incrementwarning");
            break;
          }
          this.valuesIncrement.push(i);
          this.defaultCondition.push(this.default);
          idx++;
        }

        this.valuesIncrement.push(polylineLength);
        this.defaultCondition.push(this.default);
      }
    } catch (error) {
      console.error(error);
    }
  }
  export() {
    //story 70
    if (this.imgBase64 !== undefined && this.imgBase64 !== "") {
      var selectedFile = { fileBase64: this.imageSrc };
      var mimeType = getMimeType(selectedFile.fileBase64);
      if (mimeType.toLowerCase() == "pdf") {
        var pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.imgBase64) + "'></iframe>");
      } else {
        var image = new Image();
        image.src = "data:image/" + mimeType + ";base64," + this.imgBase64;
        var w = window.open("_blank");
        w.document.write(image.outerHTML);
      }
    } else {
      Swal.fire(this.translateService.instant("Select.alertassessment.nodocument"));
    }
  }

  deleteImage() {
    try {
      this.samples.forEach((data, key) => {
        if (data.url === this.imageSrc) {
          const features = new this.esritools.Graphic();
          features.setAttributes(data.attributes);
          Swal.fire({
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            //TSDRSAM-731
            title: this.translateService.instant("Select.assessment.deleteassessmentconfirmmsg"),
            cancelButtonText: this.translateService.instant("Select.alertassessment.cancelbtn"),
            confirmButtonText: this.translateService.instant("Select.alertassessment.delete")
          }).then((result) => {
            if (result.value) {
              this.queryLayers.deleteAsset(this.photoFl, features).then(() => {
                this.samples.splice(key, 1);

                if (this.samples.length > 0) {
                  this.imageSrc = this.samples[0].url;
                  this.PhotoDate = this.samples[0].Date;
                  this.sourceTag = this.samples[0].sourceTag;
                  this.MeasureValue = this.samples[0].Measure;
                  this.imgBase64 = this.samples[0].imgBase64;
                } else {
                  this.imageSrc = "";
                  this.PhotoDate = "";
                  this.sourceTag = "";
                  this.MeasureValue = "";
                  this.imgBase64 = "";
                }
              });
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  imageGenerate(data?) {
    try {
      this.samples = [];
      const photoData = data ? data : this.photosData;
      let contentType;
      // TSDRSAM-510 Manikantha V
      if (photoData.length > 0) {
        photoData.forEach((photo) => {
          this.assetDetailService.getImageAsBase64(photo.attributes.OBJECTID, this.currentUser.clientId, photo.attributes.DocType.toLowerCase()).subscribe((base64Image: any) => {
            if (photo.attributes.DocType.toLowerCase() == "pdf") {
              contentType = "application/pdf";
            } else {
              contentType = "image/png";
            }
            this.samples.push({
              label: photo.attributes.Filename,
              imgBase64: base64Image,
              url: `data:${contentType};base64, ${base64Image}`,
              attributes: photo.attributes,
              Date: photo.attributes.DocDate,
              sourceTag: photo.attributes.SourceTag,
              Measure: photo.attributes.Asset_Segment_Measure
            });
            this.DescendingOrder("Date");
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  EventChange(event) {
    try {
      this.imageSrc = event.value;
      this.samples.forEach((data) => {
        if (data.url === event.value) {
          this.PhotoDate = data.Date;
          this.sourceTag = data.sourceTag;
          this.MeasureValue = data.Measure;
          this.imgBase64 = data.imgBase64;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  OrderImages(sortKey) {
    try {
      if (sortKey === "Date") {
        if (this.showDateByOrder) {
          this.showDateByOrder = !this.showDateByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showDateByOrder = !this.showDateByOrder;
          this.AscendingOrder(sortKey);
        }
      } else if (sortKey === "Measure") {
        if (this.showSequenceByOrder) {
          this.showSequenceByOrder = !this.showSequenceByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showSequenceByOrder = !this.showSequenceByOrder;
          this.AscendingOrder(sortKey);
        }
      } else if (sortKey === "sourceTag") {
        if (this.showSourceByOrder) {
          this.showSourceByOrder = !this.showSourceByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showSourceByOrder = !this.showSourceByOrder;
          this.AscendingOrder(sortKey);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  AscendingOrder(sortKey) {
    try {
      this.samples.sort((a, b) => {
        const x = a[sortKey];
        const y = b[sortKey];

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });

      if (this.samples.length > 0) {
        this.imageSrc = this.samples[0].url;
        this.PhotoDate = this.samples[0].Date;
        this.sourceTag = this.samples[0].sourceTag;
        this.MeasureValue = this.samples[0].Measure;
        this.imgBase64 = this.samples[0].imgBase64;
      } else {
        this.imageSrc = "";
        this.PhotoDate = "";
        this.sourceTag = "";
        this.MeasureValue = "";
        this.imgBase64 = "";
      }
    } catch (error) {
      console.error(error);
    }
  }
  DescendingOrder(sortKey) {
    try {
      this.samples.sort((a, b) => {
        const x = a[sortKey];
        const y = b[sortKey];

        if (x < y) {
          return 1;
        }

        if (x > y) {
          return -1;
        }

        return 0;
      });

      if (this.samples.length > 0) {
        this.imageSrc = this.samples[0].url;
        this.PhotoDate = this.samples[0].Date;
        this.sourceTag = this.samples[0].sourceTag;
        this.MeasureValue = this.samples[0].Measure;
        this.imgBase64 = this.samples[0].imgBase64;
      } else {
        this.imageSrc = "";
        this.PhotoDate = "";
        this.sourceTag = "";
        this.MeasureValue = "";
        this.imgBase64 = "";
      }
    } catch (error) {
      console.error(error);
    }
  }

  async compressImage(fileData, options) {
    const result = await this.compressImageAndUpload(fileData, options);
    return result;
  }
  compressImageAndUpload(fileData, options) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return new Promise((resolve, reject) => {
        const files: any = [fileData];
        const images = [];
        let newfileData: any;
        this.imageCompressService.compressImageArray(files, options).subscribe(
          (image) => {
            images.push(image);
          },
          (error) => {
            console.log("Error while converting");
          },
          () => {
            let processedImages = images;
            console.log();
            newfileData = this.dataURItoBlob(processedImages[0].compressedImage.imageDataUrl);
            resolve(newfileData);
            // if(newfileData.size < 3,096,632)
            // {

            // }

            // else{
            //   this.compressImage(newfileData);
            // }
          }
        );
      });
    } catch (error) {
      console.error(error);
    }
  }
  dataURItoBlob(dataURI) {
    try {
      const byteString = atob(dataURI.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // return new Blob([ab], { type: 'image/jpeg' });
      return new File([ia], this.uploadfileName, { type: "image/jpeg" });
    } catch (error) {
      console.error(error);
    }
  }
  findUserAccessByRoleRegionLayer() {
    try {
      // Role
      // if (condition) {

      // } else {

      // }
      // Region
      if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
        const layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.activeAsset[1].lyrObj.layerId) {
              return layer;
            }
          }.bind(this)
        )[0];

        // Layer
        if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
          return true;
        }

        return false;
      }

      return false;
    } catch (error) {
      console.error(error);
    }
  }
  assessmenttypeChange(event) {
    this.assetTypeListSelected = event;
  }
  conditionChange(event) {
    this.conditionSelected = event;
  }
  userChange(event) {
    this.userListSelected = event;
  }
  defectTypeChange(event) {
    this.defecttypeSelected = event;
  }
}
