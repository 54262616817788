import { Component, OnInit, Input, Renderer2, HostListener } from "@angular/core";
import { HeaderComponent } from "../../header/header.component";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import Swal from "sweetalert2";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { Globals } from "src/app/select/globals";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { EventEmitersService } from "src/app/services/eventEmiters.service";

@Component({
  selector: "app-sms-gpstrack",
  templateUrl: "./gpstrack.component.html",
  styleUrls: ["./gpstrack.component.scss"]
})
export class GPSTrackComponent implements OnInit {
  currentUser: any;
  faCalendar = faCalendar;
  @Input() map;
  @Input() TimeExtent;
  @Input() SimpleLineSymbol;
  @Input() SimpleRenderer;
  @Input() Color;
  @Input() TimeSlider;
  @Input() TimeInfo;
  @Input() registry;
  @Input() Popup;
  @Input() domConstruct;
  @Input() FeatureLayer: any;
  regions;
  ftrLyrTbl: any;
  ftrLyrActivity: any;
  ftrLyrActivityToolKit: any;
  layerDomines: any;
  activityLyrDomines: any;
  activityLyrFltDomines: any[];
  activity: any;
  result;

  //
  constructor(
    private genericService: GenericLayerService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private HeaderComponent: HeaderComponent,
    private genericLayerService: GenericLayerService,
    public esritools: EsriMapComponent,
    private translateService: TranslateService,
    public datepipe: DatePipe,
    public objGlobal: Globals,
    private eventEmitersService: EventEmitersService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  htmlStr: string;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public todayDate: Date = new Date();
  public userList = "";
  public selectedUser;
  public timeIntervalUnits;
  public timeInterval = 1;
  public thumbCount = 1;
  public timeSlider;
  clientUsersListByLayer;
  clientUsersList;
  userRoles;

  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate());
    //added by sowjanya JIRA ID-53 15-10-2021
    this.genericLayerService
      .getUserList(this.currentUser.clientId) //7/28/20 jcc -- to do fix getUserList service to include asset class setttings.  Only list workers with DE perms for current asset class.
      .subscribe(
        (results: any) => {
          this.result = results;
          this.clientUsersList = [];
          this.clientUsersListByLayer = [];
          this.result.forEach((element) => {
            //Jira Id-1499 fix

            if (element.roleId != 1004 && element.roleId != 2 && element.roleId != 1005) {
              this.clientUsersList.push(element);
            }
          });
        },
        (error) => {
          if (!this.clientUsersList) {
            this.clientUsersList = [this.currentUser];
          }
        }
      );
  }

  loadTimeSlider() {
    try {
      this.getTimeIntervalUnit();

      if (this.timeSlider) {
        try {
          this.timeSlider.destroy();
        } catch (e) {
          //eat error
        }
      } else {
        const component = this.registry.byId("timeSliderWidget");

        if (component) {
          component.destroy();
        }
      }

      const widgetDiv = this.renderer.createElement("div");
      this.renderer.setProperty(widgetDiv, "id", "timeSliderWidget");
      const container = document.getElementById("timeSliderDiv");
      container.style.display = "block";
      this.renderer.appendChild(container, widgetDiv);
      const featureLayerGPS = this.map.smsLayerdata.userLayers.filter((obj) => obj.layerName === "GPSTrackLog")[0].lyrObj;

      const timeExtent = new this.TimeExtent();
      timeExtent.startTime = this.startDate; // new Date("3/13/2019");
      timeExtent.endTime = this.endDate; // new Date("3/27/2019");
      const symbol = new this.SimpleLineSymbol().setColor(new this.Color([230, 104, 17, 9])).setWidth(3);
      const renderer = new this.SimpleRenderer(symbol);

      featureLayerGPS.setRenderer(renderer);
      featureLayerGPS.setTimeDefinition(timeExtent);
      this.map.addLayer(featureLayerGPS);
      //this.setEvents(featureLayerGPS);
      // featureLayerGPS.setDefinitionExpression("Inspector='" + this.selectedUser + "'");

      this.timeSlider = new this.TimeSlider({ style: "width: 100%;" }, "timeSliderWidget");
      this.timeSlider.setThumbCount(2);
      this.timeSlider.createTimeStopsByTimeInterval(timeExtent, this.timeInterval, this.timeIntervalUnits);
      // this.timeSlider.singleThumbAsTimeInstant(true);
      if (this.thumbCount > 1) {
        this.timeSlider.setThumbIndexes([0, this.thumbCount + 1]);
      }
      // timeSlider.on("time-extent-change", displayTimeInfo);
      this.timeSlider.startup();
      this.map.setTimeSlider(this.timeSlider);
      const labels = this.timeSlider.timeStops.map((timeStop, i) => {
        return this.getLabelForSlider(timeStop);
      });
      this.timeSlider.setLabels(labels);
      this.setTrackLogHeader();
      const popup = new this.Popup({}, this.domConstruct.create("div"));
      popup.setMap(this.map);
      this.map.infoWindow = popup;
      if (this.selectedUser == null || this.selectedUser == "") {
        featureLayerGPS.setDefinitionExpression("");
      } else {
        featureLayerGPS.setDefinitionExpression("Inspector='" + this.selectedUser + "'");
      }
      this.selectedUser = "";
      //Disable single click for asset selection
      this.eventEmitersService.setEnableSingleClick(false);
    } catch (error) {
      console.error(error);
      this.selectedUser = "";
    }
  }

  findTrackLogs() {
    try {
      if (this.startDate > this.endDate) {
        Swal.fire(this.translateService.instant("Esri-map.alert.dateentryvalidate"));
        this.HeaderComponent.clicksetting();
      }
      //added by sowjanya for JIRA ID-53 on 10/28/2021
      else if (this.startDate > new Date()) {
        Swal.fire(this.translateService.instant("Esri-map.alert.startdatevalidate"));
        this.HeaderComponent.clicksetting();
      } else {
        this.loadTimeSlider();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getLabelForSlider(date: Date) {
    try {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      return date.getDate() + "-" + months[date.getMonth()];
    } catch (error) {
      console.error(error);
    }
  }

  getTimeIntervalUnit() {
    try {
      this.timeInterval = 1;
      this.timeIntervalUnits = this.TimeInfo.UNIT_DAYS;
      const dayDiff = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24);
      this.timeInterval = dayDiff / 20 < 1 ? 1 : parseInt((dayDiff / 20).toFixed(0), 10);

      if (dayDiff >= 365) {
        this.timeIntervalUnits = this.TimeInfo.UNIT_MONTHS;
        this.timeInterval = 1;
      }

      this.thumbCount = parseInt((dayDiff / this.timeInterval).toFixed(0), 10);
    } catch (error) {
      console.error(error);
    }
  }

  setTrackLogHeader() {
    try {
      // this.loading = false;
      //added by sowjanya JIRA ID-53 15-10-2021
      if (this.selectedUser === undefined || this.selectedUser == null) {
        this.selectedUser = "";
      } else if (this.selectedUser.username != undefined) {
        this.selectedUser = this.selectedUser.username;
      }
      //
      const html =
        " <label>  User : " +
        this.selectedUser +
        "</label><label> Start Time : " +
        this.getLabelForSlider(this.startDate) +
        "-" +
        this.startDate.getFullYear() +
        "</label><label> End Time : " +
        this.getLabelForSlider(this.endDate) +
        "-" +
        this.endDate.getFullYear() +
        "</label>";
      document.getElementById("timeSliderHeaderLabels").innerHTML = html;
      this.objGlobal.gpstracklogData = {
        selectedUser: this.selectedUser,

        startDate: this.startDate,

        endDate: this.endDate
      };
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    this.selectedUser = "";
    this.startDate = new Date();
    this.endDate = new Date();
    // this.userList = null;
    // this.genericService.getUniqueValuesByField(this.currentUser.serviceLyrInfo.gpstracklogId, 'Inspector').subscribe(data => {
    //   this.userList = data['features'];
    // })
  }

  // setEvents(fl) {
  //   // fl.on('update-end', (evt) => {
  //   //     console.log('update-end',evt)
  //   // })
  //   // fl.on('update-start', (evt) => {
  //   //   console.log('update-start',evt)
  //   // })
  // }
}
