<div>
 <div class="map-tools">
  <div class="action-item-text xxsmall">Map Tools</div>
  <div class="action-item-container">
    <div class="action-item">
      <a href="#"title="{{'Map-tool.bottombox.zoomin'|translate}}" aria-label="Zoom In" data-mainPageTabbing="yes" (click)="zoomIn()">
        <mat-icon class="icon-new icon-new-select" svgIcon="zoom-in"></mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.zoomout'|translate}}" aria-label="Zoom Out" data-mainPageTabbing="yes" (click)="zoomOut()">
        <mat-icon class="icon-new icon-new-select" svgIcon="zoom-out"></mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.zoomtoextent'|translate}}" aria-label="Zoom to Extent" class="i-inactive" data-mainPageTabbing="yes" (click)="zoomExtent()">
        <mat-icon class="icon-new icon-new-select" svgIcon="zoom-in-2"></mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.zoomselected'|translate}}" aria-label="Zoom selected" data-mainPageTabbing="yes" class="i-inactive"  (click)="zoomSelected()">
        <mat-icon class="icon-new icon-new-select" svgIcon="zoom-out-2"></mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.overviewmap'|translate}}" aria-label="Map Overview" class="i-inactive" data-mainPageTabbing="yes" (click)=" mapInset(true, null)">
        <mat-icon class="icon-new icon-new-select" svgIcon="picture_in_picture_alt"></mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.triview'|translate}}" class="i-inactive" aria-label="Street View" data-mainPageTabbing="yes" (click)="triView($event)">
        <mat-icon class="icon-new icon-new-select" svgIcon="street-view"></mat-icon>
      </a>
    </div>
    <div class="action-item" *ngIf="currentUser.clientId === 509">
      <a href="#" title="View Lidar Data" aria-label="View Lidar Data" class="i-inactive" data-mainPageTabbing="yes" (click)="toggleViewLidar()">
        <mat-icon [ngStyle]="{'color': eventEmitterService.viewLidarData ? '#0D399A':'#323237'}" class="icon-new icon-new-select">radar</mat-icon>
      </a>
    </div>
    <div class="action-item">
      <a href="#" title="{{'Map-tool.bottombox.locate'|translate}}" class="i-inactive" aria-label="Location" data-mainPageTabbing="yes" (click)="initLocationButton()">
        <mat-icon class="icon-new icon-new-select" svgIcon="location"></mat-icon>
      </a>
    </div>
  </div>
</div>


  
</div>
