 <button class="stopgps" *ngIf="stoprecord" (click)="stoprecordin()">
   <fa-icon [icon]="faSquare" style="color:red" aria-hidden="true"></fa-icon>
    Stop GPS Recording
  </button>
 <div class="switchToggle">
    <input type="checkbox" class="custom-control-input" id="switch" title="Start/Stop Route logging" (click)="toggleRouteLogger()" [(ngModel)]="routeLogToggle">
    <label aria-labelledby="switch">Toggle</label>
</div> 

<!--<div class="switchToggle" >
  <input type="checkbox" class="custom-control-input" (change)="toggleRouteLogger($event)" [(ngModel)]="routeLogToggle" id="switch">
  <label for="switch">Toggle</label>
</div>-->

<div id="widgetDiv">
    <div id="routeLoglocateBtn"></div>
</div>

<ng-template #routeLogForm let-modal>
    <div class="modal-content">
      <div class="modal-header">

        <div class="modal-title">
          <h4><b>{{'Routelogs.main.title'|translate}}</b></h4>
          <h5>{{'Routelogs.main.desc'|translate}}</h5>
      </div>
     
          <!-- <h4 class="modal-title" id="exampleModalLongTitle">GPS Track Log</h4>
          <h6 class="modal-title" id="exampleModalLongTitle">Please enter a summary for the tracklog</h6> -->
  
          <button type="button" class="close" (click)="modal.dismiss();stopLogging()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      <div class="modal-body">
        <div class="row pt-2">
          <div class="col-1"></div>
          <div class="col-4">{{'Routelogs.main.inspector'|translate}}</div>
          <div class="col-6">
            <select name="gps-user-select" id="gps-user-select" class="form-control" [(ngModel)]="selectedUser">
                <ng-container *ngFor="let user of userList">
                      <option  value="{{user.username}}">{{user.username}}</option>
                </ng-container>                    
            </select>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row pt-2">
          <div class="col-1"></div>
          <div class="col-4">{{'Routelogs.main.assessment'|translate}}:</div>
          <div class="col-6">
            <select name="assessment" id="assessment" class="form-control" [(ngModel)]="assessment">
                <option value="Day">{{'Routelogs.main.assessmentday'|translate}}</option>            
                <option value="Night">{{'Routelogs.main.assessmentnight'|translate}}</option>            
            </select>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row pt-2">
          <div class="col-1"></div>
          <div class="col-4">{{'Routelogs.main.weathercondition'|translate}}:</div>
          <div class="col-6">
            <select name="gps-user-select" id="gps-user-select" class="form-control" [(ngModel)]="weather1" > 
                <option *ngFor="let w of weather" [value]="w.code" selected>{{w.name}}</option>
            </select>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col-4">{{'Routelogs.main.veichelmodel'|translate}}:</div>
            <div class="col-6">
                <input type="text" maxlength="60" [(ngModel)]="vmodel" class="form-control" />
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col-4">{{'Routelogs.main.veichelyear'|translate}}:</div>
            <div class="col-6">
              <select class="form-control" (change)="dropdownslected($event)">
                <option disabled>Select Year</option>
                <option *ngFor="let y of availableYears" [value]="y">
                  {{y}}
                </option>
              </select>
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row pt-2">   
            <div class="col-1"></div>
            <div class="col-4">{{'Routelogs.main.comments'|translate}}:</div>
            <div class="col-6">
              <textarea maxlength="300" rows="5" [(ngModel)]="comments" class="form-control"></textarea>
            </div>
            <div class="col-1"></div>
        </div>
      </div>
      <br>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="startRouteTracking()">{{'Routelogs.btn.starttrack'|translate}}</button>
      </div>
    </div>
  </ng-template>
  