import { Injectable } from "@angular/core";
import { TRANSLATIONS } from "../translations";

@Injectable()
export class TranslateService {
  private currentLanguage = "en";

  translate(str) {
    return TRANSLATIONS.filter((entrada) => entrada.en === str)[0][this.currentLanguage];
  }

  selectLanguage(language) {
    this.currentLanguage = language;
  }
}
