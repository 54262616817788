<div class="form-row h6">
    {{'Systemreports.retireyrassetclass.desc'|translate}}
</div>
<div class="card-footer text-right" style="background-color: white;">
    <button [attr.aria-label]="''+'Systemreports.btn.generatereport'|translate" (click)="exportPDF()"
        class="report-button h7" mat-button>
        <mat-icon variant="outlined" class="material-icons-outlined" class="report-icon" svgIcon="report"></mat-icon>
        {{'Systemreports.btn.generatereport'|translate}}
    </button>

</div>