<form [formGroup]="createEditUserForm" #formDirective="ngForm" class="edit-user-form" (ngSubmit)="onSubmit()">
  <div class="user-form-heading">User Information</div>
  <div class="form-row">
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <input matInput [formControl]="f.firstName" />
        <mat-label>{{ "Userscreens.useradmin.firstname" | translate }}</mat-label>
        <mat-error class="form-text-small" *ngIf="f.firstName.hasError('required')">
          {{ "Userscreens.alertuseradmin.enterfirstname" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <input matInput [formControl]="f.lastName" />
        <mat-label>{{ "Userscreens.useradmin.lastname" | translate }}</mat-label>
        <mat-error class="form-text-small" *ngIf="f.lastName.hasError('required')">
          {{ "Userscreens.alertuseradmin.enterlastname" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="user-form-heading">{{ "Userscreens.useradmin.appcredentials" | translate }}:</div>
  <div class="form-row">
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <input matInput [formControl]="f.username" />
        <mat-label>{{ "Userscreens.useradmin.username" | translate }}</mat-label>
        <mat-error class="form-text-small" *ngIf="f.username.hasError('required')">
          {{ "Userscreens.alertuseradmin.enterusername" | translate }}
        </mat-error>
        <mat-error class="form-text-small" *ngIf="!f.username.hasError('required')">
          {{ "Userscreens.alertuseradmin.usernamepswdlengthnotmatch" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col">
      <!-- // Added by Manikantha for JIRA ID:-57 -->
      <mat-form-field appearance="outline" [ngClass]="{ myBackgroundColor: locked }">
        <input type="password" matInput [formControl]="f.password" />

        <mat-label *ngIf="!locked">{{ "Userscreens.useradmin.password" | translate }}</mat-label>
        <mat-label *ngIf="locked">{{ "Userscreens.useradmin.typetoreset" | translate }}</mat-label>
        <mat-error class="form-text-small" *ngIf="!f.password.hasError('required')">
          {{ "Login.alertuseradmin.InvalidPassword" | ltranslate }}
        </mat-error>
        <mat-error class="form-text-small" *ngIf="f.password.hasError('required')">
          {{ "Userscreens.alertuseradmin.enterpswd" | translate }}
        </mat-error>
        <mat-hint class="hintColor" *ngIf="locked"><fa-icon [icon]="faLock" class="fa fa-lg pr-2" (click)="unlockUser()"></fa-icon>{{ "User.AccountLockedTextInfo" | translate }}</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="user-form-heading">{{ "Userscreens.useradmin.userrole" | translate }}:</div>
  <div class="form-row">
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select disableOptionCentering panelClass="styled-panel" [formControl]="f.userRole">
          <mat-option *ngFor="let userRole of RoleList" [value]="userRole.roleId">
            {{ userRole.roleName }}
          </mat-option>
        </mat-select>
        <mat-error class="form-text-small" *ngIf="f.userRole.hasError('required')">
          {{ "Userscreens.alertuseradmin.selectrole" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Userscreens.useradmin.activelabel" | translate }}</mat-label>
        <mat-select disableOptionCentering panelClass="styled-panel" [formControl]="f.userActiveSelected">
          <mat-option *ngFor="let uvf of userActive" [value]="uvf.id">
            {{ uvf.Value }}
          </mat-option>
        </mat-select>
        <mat-error class="form-text-small" *ngIf="f.userActiveSelected.hasError('required')"> Active is required. </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Userscreens.useradmin.visibilitylabel" | translate }}</mat-label>
        <mat-select disableOptionCentering panelClass="styled-panel" [formControl]="f.visiblity">
          <mat-option *ngFor="let uaf of userVisibility" [value]="uaf.id">
            {{ uaf.Value }}
          </mat-option>
        </mat-select>
        <mat-error class="form-text-small" *ngIf="f.visiblity.hasError('required')"> Visiblity is required. </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Userscreens.useradmin.languagelabel" | translate }}</mat-label>
        <mat-select disableOptionCentering panelClass="styled-panel" [formControl]="f.language">
          <mat-option *ngFor="let uaf of agencyLanguage" [value]="uaf.languageID">
            {{ uaf.languageName }}
          </mat-option>
        </mat-select>
        <mat-error class="form-text-small" *ngIf="f.language.hasError('required')">
          {{ "Userscreens.alertuseradmin.selectlanguage" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#newUserModal" (click)="close(formDirective)">{{ "Userscreens.useradmin.cancelbtn" | translate }}</button>
    <button type="submit" class="btn btn-primary">{{ "Userscreens.useradmin.saveuserbtn" | translate }}</button>
  </div>
</form>
