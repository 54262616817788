import { Component, OnInit, Input, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../services/user.service";
import { AuthenticationService } from "../services/authentication.service";
import { IUser } from "../interface/user";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "sms-route-logs",
  templateUrl: "./route-logs.component.html",
  styleUrls: ["./route-logs.component.scss"]
})
export class RouteLogsComponent implements OnInit {
  @ViewChild("routeLogForm") formContent;
  @Input() map;
  @Input() GraphicsLayer;
  @Input() Polyline;
  @Input() Point;
  @Input() SimpleLineSymbol;
  @Input() Color;
  @Input() Graphic;
  @Input() webMercatorUtils;
  @Input() mathUtils;
  @Input() PictureMarkerSymbol;
  ROUTE_LOGGER_COMPLETED = 1;
  ROUTE_LOGGER_INPROGRESS = 2;
  routeLoggerGlayer;
  routeLineSymbol;
  locMarkerSymbol;
  faSquare = faSquare;
  routeGraphics;
  locationGraphic;
  routeCoordinates = [];
  routeLogToggle = false;
  startTracking = false;
  watchId;
  // geoLocate
  thresholdAccuracy = 10;
  options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  currentUser: IUser;
  userList;
  selectedUser;
  assessment = "Day";
  weather;
  weather1 = "Clear";
  vmodel;
  vyear;
  comments;
  retryTimeout = 6;
  distanceInterval = 20;
  logStartTime;
  stoprecord = false;
  gpslayr;
  availableYears = [];

  constructor(
    public toast: ToastrService,
    private modalService: NgbModal,
    private uService: UserService,
    private auth: AuthenticationService,
    private translateService: TranslateService,
    private genericService: GenericLayerService
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.selectedUser = this.currentUser.username;
    this.thresholdAccuracy = environment.gpsAccuracyThreshold;
    this.options.timeout = environment.gpsTimeInterval;
    this.retryTimeout = environment.routeLogRetryTimeout;
    this.distanceInterval = environment.gpsDistanceInterval;
  }

  ngOnInit() {
    this.routeLoggerGlayer = new this.GraphicsLayer({ id: "route-logger-glayer" });
    this.routeLineSymbol = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([34, 189, 20]), 4);
    this.uService.getUserNamesByClient(this.currentUser.clientId).subscribe((data) => {
      this.userList = data;
    });
    this.map.addLayer(this.routeLoggerGlayer);
    this.locMarkerSymbol = new this.PictureMarkerSymbol("https://js.arcgis.com/3.31/esri/dijit/images/sdk_gps_location.png", 28, 28);
    this.checkSavedRoute();
    this.GetCodedValuesofGPSTrack();
    this.dropdownveichlyr();
  }

  GetCodedValuesofGPSTrack() {
    try {
      this.genericService.getServiceInfo(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.gpstracklogId).subscribe(
        (evt: any) => {
          evt.fields.forEach((data) => {
            if (data.domain !== null) {
              this.weather = data.domain.codedValues;
              // console.log(this.weather);
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  stoprecordin() {
    try {
      this.routeLogToggle = false;

      if (this.startTracking) {
        Swal.fire({
          title: this.translateService.instant("Routelogs.alert.title"),
          text: this.translateService.instant("Routelogs.alert.stopandsavetxt"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.translateService.instant("Routelogs.alert.stopandsavebtn")
        }).then((result) => {
          if (result.value) {
            this.stopLogging();
            this.stoprecord = false;
          }
        });
      } else {
        this.stopLogging();
        this.stoprecord = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  toggleRouteLogger() {
    try {
      if (this.routeLogToggle) {
        // this.stoprecord = true;
        if (this.startTracking) {
          Swal.fire({
            title: this.translateService.instant("Routelogs.alert.title"),
            text: this.translateService.instant("Routelogs.alert.stopandsavetxt"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.translateService.instant("Routelogs.alert.stopandsavebtn")
          }).then((result) => {
            if (result.value) {
              this.stopLogging();
              this.stoprecord = false;
            }
          });
        } else {
          this.stopLogging();
          this.stoprecord = false;
        }
      } else {
        Swal.fire({
          title: this.translateService.instant("Routelogs.alert.title"),
          text: this.translateService.instant("Routelogs.alert.enablegpsmode"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.translateService.instant("Routelogs.alert.enablegpsmodeacceptbtn"),
          cancelButtonText: this.translateService.instant("Routelogs.alert.enablegpsmodedeclinebtn")
        }).then((result) => {
          if (result.value) {
            this.startLogging();
            this.showForm();
            this.routeLogToggle = true;
          } else {
            this.routeLogToggle = !this.routeLogToggle;
            // this.routeLogToggle = true
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  showForm() {
    this.modalService.open(this.formContent, { centered: true });
  }

  startRouteTracking() {
    try {
      console.log(this.vyear);

      if (this.selectedUser == null || this.assessment == null || this.weather1 == null) {
        Swal.fire(this.translateService.instant("Routelogs.alert.formdesctxt"));
      } else {
        this.modalService.dismissAll();
        this.startTracking = true;
        this.logStartTime = Date.now();
        this.stoprecord = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  startLogging() {
    try {
      if (navigator.geolocation) {
        this.watchId = navigator.geolocation.watchPosition(this.locateCallback.bind(this), this.locationError.bind(this), this.options);
        // this.stoprecord = true;
      } else {
        Swal.fire(this.translateService.instant("Routelogs.alert.browsernotsupport"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  setThis() {
    return this;
  }
  locateCallback(e) {
    try {
      const _this = this.setThis();
      console.log(e);

      // Check position has been changed or not and accuracy
      if (e.coords && e.coords.accuracy < this.thresholdAccuracy) {
        const geom = this.transformGeometry(e.coords);
        this.showLocationOnMap(geom);

        if (this.checkDistanceThreshold(geom)) {
          this.capturePoint(geom);
        }
      } else if (this.routeCoordinates.length === 0) {
        // only show when location capture has not started
        this.log("Finding user location...");

        if (_this.retryTimeout > 0) {
          this.retryTimeout--;
        } else {
          Swal.fire(this.translateService.instant("Routelogs.alert.notabletofindlocation"));
          this.routeLogToggle = false;
          this.stopLogging();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  capturePoint(e) {
    if (this.startTracking) {
      this.routeCoordinates.push([e.x, e.y]);
    }

    if (this.routeCoordinates.length > 1) {
      this.showRouteOnMap();
    }
  }

  showRouteOnMap() {
    try {
      if (!this.routeGraphics) {
        const routeGeometry = new this.Polyline({
          spatialReference: this.map.spatialReference,
          paths: [this.routeCoordinates]
        });
        this.routeGraphics = new this.Graphic({ geometry: routeGeometry });
        this.routeGraphics.setSymbol(this.routeLineSymbol);
        this.routeLoggerGlayer.add(this.routeGraphics);
      } else {
        const routeGeometry = new this.Polyline({
          spatialReference: this.map.spatialReference,
          paths: [this.routeCoordinates]
        });
        this.routeGraphics.setGeometry(routeGeometry);
      }

      this.saveRouteToLocalStorage(this.ROUTE_LOGGER_INPROGRESS);
    } catch (error) {
      console.error(error);
    }
  }

  showLocationOnMap(geom) {
    try {
      if (!this.locationGraphic) {
        this.locationGraphic = new this.Graphic({ geometry: geom });
        this.locationGraphic.setSymbol(this.locMarkerSymbol);
        this.routeLoggerGlayer.add(this.locationGraphic);
      } else {
        this.locationGraphic.setGeometry(geom);
      }

      this.map.centerAndZoom(geom, 17);
      this.saveRouteToLocalStorage(this.ROUTE_LOGGER_INPROGRESS);
    } catch (error) {
      console.error(error);
    }
  }

  stopLogging() {
    try {
      this.clearWatch();
      this.startTracking = false;
      this.syncDataToLayer();
      // this.map.removeLayer(this.routeLoggerGlayer)
      this.routeLoggerGlayer.clear();
      this.routeGraphics = null;
      this.locationGraphic = null;
      this.routeCoordinates = [];
      this.retryTimeout = environment.routeLogRetryTimeout;
      this.modalService.dismissAll();
      this.stoprecord = false;
      this.routeLogToggle = false;
    } catch (error) {
      console.error(error);
    }
  }

  saveRouteToLocalStorage(s) {
    try {
      localStorage.setItem(
        "sms-route-logger",
        JSON.stringify({
          route: this.routeCoordinates,
          time: new Date().toISOString(),
          status: s,
          attrib: {
            Inspector: this.selectedUser,
            Method: this.assessment,
            WeatherCondition: this.weather1,
            VehicleModel: this.vmodel,
            VehicleYear: this.vyear,
            Comment: this.comments,
            StartTime: this.logStartTime,
            EndTime: Date.now()
          }
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  getRouteFromLocalStorage() {
    return localStorage.getItem("sms-route-logger") ? JSON.parse(localStorage.getItem("sms-route-logger")) : null;
  }

  clearLocalStorage() {
    localStorage.removeItem("sms-route-logger");
  }

  checkSavedRoute() {
    try {
      const rdata = this.getRouteFromLocalStorage();

      if (rdata) {
        if (rdata.status === this.ROUTE_LOGGER_INPROGRESS) {
          Swal.fire({
            title: this.translateService.instant("Routelogs.alert.title"),
            text: this.translateService.instant("Routelogs.alert.routelogpending"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.translateService.instant("Routelogs.alert.routelogpendingconfirmbtn")
          }).then((result) => {
            if (result.value) {
              this.routeCoordinates = rdata.route;
              this.startLogging();
              this.startRouteTracking();
              this.routeLogToggle = true;
              const attrib = rdata.attrib;
              (this.selectedUser = attrib.Inspector), (this.assessment = attrib.Method);
              (this.weather1 = attrib.WeatherCondition), (this.vmodel = attrib.VehicleModel);
              this.vyear = attrib.VehicleYear;
              this.comments = attrib.Comment;
              this.logStartTime = attrib.StartTime;
            } else {
              this.clearLocalStorage();
            }
          });
        } else if (rdata.status === this.ROUTE_LOGGER_COMPLETED) {
          Swal.fire({
            title: this.translateService.instant("Routelogs.alert.title"),
            text: this.translateService.instant("Routelogs.alert.unsavedroutedatatosave"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.translateService.instant("Routelogs.alert.unsavedroutedatatosavebtn")
          }).then((result) => {
            if (result.value) {
              this.syncDataToLayer();
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  locationError(error) {
    switch (error.code) {
      case 1:
        this.log("User permission denied");
        break;

      case 2:
        this.log("Current location not available");
        break;

      case 3:
        this.log("Request Timeout");
        break;

      default:
        this.log("unknown error fetching location");
        break;
    }
  }

  clearWatch() {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  checkDistanceThreshold(pt1) {
    return this.routeCoordinates.length === 0 ? true : this.mathUtils.getLength(pt1, new this.Point(this.routeCoordinates[this.routeCoordinates.length - 1], this.map.spatialReference)) >= this.distanceInterval;
  }

  log(msg) {
    try {
      this.toast ? this.toast.info(msg) : console.log(msg);
    } catch (error) {
      console.error(error);
    }
  }

  syncDataToLayer() {
    try {
      if (this.routeCoordinates.length > 0) {
        if (navigator.onLine) {
          this.saveFeature();
        } else {
          this.saveRouteToLocalStorage(this.ROUTE_LOGGER_COMPLETED);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  transformGeometry(g) {
    return this.webMercatorUtils.geographicToWebMercator(new this.Point(g.longitude, g.latitude));
  }

  saveFeature() {
    try {
      const data = this.getRouteFromLocalStorage();

      if (!data) {
        return;
      }

      const flGPS = this.map.smsLayerdata.userLayers.filter((obj) => obj.layerName === "GPSTrackLog")[0].lyrObj;
      const g = new this.Graphic(
        new this.Polyline({
          spatialReference: this.map.spatialReference,
          paths: [data.route]
        }),
        null,
        data.attrib
      );

      flGPS
        .applyEdits([g], null, null)
        .then(
          function (res) {
            this.clearLocalStorage();
            console.log(res);
          }.bind(this)
        )
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {
      alert(e);
    }
  }

  dropdownveichlyr() {
    try {
      const max = new Date();
      const min = max.getFullYear() - 60;

      for (let i = min; i <= max.getFullYear(); i++) {
        this.availableYears.push(i);
      }

      this.vyear = this.availableYears;
    } catch (error) {
      console.error(error);
    }
  }
  dropdownslected(event) {
    this.vyear = event.target.value;
    console.log(this.vyear);
  }
}
