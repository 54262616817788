import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { AssetAttributesService } from "../../services/assetAttibutes.service";
import { TranslateService } from "@ngx-translate/core";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { CardViewService } from "src/app/services/card-view.service";
// import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
// import {  ViewChild } from '@angular/core';
// import { DatatableComponent } from '@swimlane/ngx-datatable';
// import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: "app-sms-asset-defaults",
  templateUrl: "./asset-defaults.component.html",
  styleUrls: ["./asset-defaults.component.scss"],
  styles: [
    `
      :host::ng-deep .ng-select .ng-select- {
        background-color: #f3f5fd;
        background-clip: padding-box;
        border: 0.5px solid #e6ebfa;
        border-radius: 0.25rem;
      }
      :host::ng-deep .redBorder.ng-select .ng-select-container {
        border-color: red !important;
        background-color: #f3f5fd;
        background-clip: padding-box;
        border: 0.5px solid #e6ebfa;
        border-radius: 0.25rem;
      }
      :host::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
        background-color: #ffffff !important;
        top: 10px;
        text-align: left;
      }
    `
  ]
})
export class AssetDefaultsComponent implements OnInit {
  @Input() FeatureTable;
  @Input() FeatureLayer;
  @Input() Query;
  @Input() QueryTask;
  @Input() map;
  @Input() Graphic;
  public adFeatureTable;
  public adFeatureLayer;
  public defaultCondition = [];
  public add = [];
  public delete = [];
  public loading = true;
  public currentUser: IUser;
  public selectedLayer: any;
  public tableData: any;
  public Primarylangvalid;
  public Secodarylangvalid;
  public scndlyr = false;
  public assetCodeDefaults = [];
  selectedRowIndex = -1;
  OnClicked = [];
  codeValues = [];
  DomainValues = [];
  //<!-- TSDRSAM-856 By Manikantha -->
  public filterVal;
  public assetCodeDescriptionFltDrp;
  // public customerDiffer: KeyValueDiffer<any,any>;
  private assetCodeDefaultsold;
  constructor(
    private renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private assetAttributesService: AssetAttributesService,
    private cs: CardViewService,
    private translateService: TranslateService,
    private genericLayerService: GenericLayerService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.adFeatureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.assetCodeDefaultsId);
    this.getDomainValues();
  }

  getDomainValues() {
    try {
      this.assetAttributesService.getDomainValues().subscribe((res: any) => {
        this.DomainValues = res.domains.filter((Obj) => Obj.name.includes("_Material"));
        this.assetAttributesService.getTableAlias().subscribe((results: any) => {
          if (results.features.length > 0) {
            this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
              this.Primarylangvalid = res.primaryLanguageID;
              this.Secodarylangvalid = res.secondaryLanguageID;

              if (this.Primarylangvalid === this.currentUser.languageID) {
                this.scndlyr = false;
              } else if (this.Secodarylangvalid === this.currentUser.languageID) {
                this.scndlyr = true;
              }
            });
            this.tableData = [];
            const layer = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
            const layerValues = [];
            layer.forEach((data) => {
              layerValues.push(data.layerName);
            });
            results.features.forEach((data) => {
              layerValues.forEach((layername) => {
                if (data.attributes.Name === layername) {
                  this.tableData.push(data);
                }

                if (layername === "Support") {
                  if (data.attributes.GroupType === "Child Table") {
                    this.tableData.push(data);
                  }
                }
              });
            });

            const distinct = (value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            };

            this.tableData = this.tableData.filter(distinct);
            this.selectedLayer = this.tableData[0].attributes.OBJECTID;
            this.onChangelayer(null);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  save() {
    try {
      //<!-- TSDRSAM-856 By Manikantha -->
      const changes = this.getChanges(this.assetCodeDefaultsold, this.assetCodeDefaults);
      if (changes.length > 0) {
        const featuresSave = [];
        // this.assetCodeDefaults.forEach((value, key) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < changes.length; i++) {
          // if (key === changes[i]) {
          const featureSave = new this.Graphic();
          // const updateAssetAttr: any = [];
          // updateAssetAttr.OBJECTID = value.OBJECTID;
          // updateAssetAttr.Manufacturer = (document.getElementById("manufacturer_" + key) as HTMLInputElement).value;
          // updateAssetAttr.Material = this.defaultCondition[key];
          // updateAssetAttr.DimLength = parseFloat((document.getElementById("dimLength_" + key) as HTMLInputElement).value);
          // updateAssetAttr.DimWidth = parseFloat((document.getElementById("dimWidth_" + key) as HTMLInputElement).value);
          // updateAssetAttr.DimHeight = parseFloat((document.getElementById("dimHeight_" + key) as HTMLInputElement).value);
          // updateAssetAttr.MountHeight = parseFloat((document.getElementById("mountHeight_" + key) as HTMLInputElement).value);
          featureSave.setAttributes(changes[i]);
          featuresSave.push(featureSave);
          // }
        }
        // });
        this.adFeatureLayer.applyEdits(
          null,
          featuresSave,
          null,
          (res) => {
            Swal.fire(this.translateService.instant("Userscreens.alertassetdefaults.savededits"));
          },
          (err) => {
            Swal.fire(this.translateService.instant("Userscreens.alertassetdefaults.errortosave"));
          }
        );
      } else {
        Swal.fire(this.translateService.instant("Userscreens.alertassetdefaults.noeditstosave"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  cancel() {
    this.selectedRowIndex = -1;
    this.OnClicked = [];
  }
  onChangelayer(evt) {
    try {
      this.cancel();
      //<!-- TSDRSAM-856 By Manikantha -->
      this.filterVal = undefined;
      this.assetCodeDescriptionFltDrp = [];
      const data = this.tableData.filter((datarow) => {
        return datarow.attributes.OBJECTID === parseInt(this.selectedLayer, 10);
      })[0];
      //erring here
      const selectedDomainValues = this.DomainValues.filter((obj) => obj.name === data.attributes.LayerCode + "_Material");
      if (selectedDomainValues && selectedDomainValues.length >= 1) {
        this.codeValues = selectedDomainValues[0].codedValues;
      } else {
        this.codeValues = [];
      }
      // this.assetCodeDescriptionFltDrp  = this.fieldsInfo.filter(function(item) {
      //   if (item.fieldName === "AssetSubType") { return item; }})[0].codedValues[this.activeAsset[0].AssetSubType].domains.AssetCode.codedValues;

      this.assetCodeDefaults = [];
      const AssetCodeLayer = this.currentUser.webServiceURL + environment.mapServer + this.currentUser.serviceLyrInfo.assetCodeDefaultsId;
      const QueryParams = "AssetShortName='" + data.attributes.LayerCode + "'";
      this.cs.getFeatures(AssetCodeLayer, QueryParams, this.Query, this.QueryTask).then(
        function (val: any) {
          val.forEach((defaultValues) => {
            this.assetCodeDefaults.push(defaultValues.attributes);
          });
          this.assetCodeDefaults.forEach((codeValue, key) => {
            this.defaultCondition[key] = codeValue.Material;
          });
          this.assigncustomerDiffer();
        }.bind(this)
      );
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  assigncustomerDiffer() {
    this.assetCodeDefaultsold = JSON.parse(JSON.stringify(this.assetCodeDefaults)); //Object.assign({}, this.assetCodeDefaults);
    // if(this.differs){
    //   this.customerDiffer = this.differs.find(this.assetCodeDefaults).create();
    // }
  }
  TableOnClick(i) {
    try {
      this.selectedRowIndex = i;
      this.OnClicked.push(i);

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      this.OnClicked = this.OnClicked.filter(distinct);
    } catch (error) {
      console.error(error);
    }
  }
  //<!-- TSDRSAM-856 By Manikantha -->
  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.AssetCode.toLowerCase().includes(term) || item.DESCRIPTION.toLowerCase().includes(term);
  }
  //<!-- TSDRSAM-856 By Manikantha -->
  getAssetDefaultValues(evt) {
    const AssetCodeLayer = this.currentUser.webServiceURL + environment.mapServer + this.currentUser.serviceLyrInfo.assetCodeDefaultsId;
    const QueryParams = "AssetCode = '" + evt + "'";
    this.cs.getFeatures(AssetCodeLayer, QueryParams, this.Query, this.QueryTask).then((val: any) => {
      console.log(val[0].attributes);
      // const deafultCodeValues = val[0].attributes;
      // this.activeAsset[0].Manufacturer = deafultCodeValues.Manufacturer;this.activeAsset[0].Material = deafultCodeValues.Material;this.activeAsset[0].DimLength = deafultCodeValues.DimLength;
      // this.activeAsset[0].DimWidth = deafultCodeValues.DimWidth;this.activeAsset[0].DimHeight = deafultCodeValues.DimHeight;this.activeAsset[0].MountHeight = deafultCodeValues.MountHeight;
      // // Manikantha Start #24789
      // if (deafultCodeValues.AssetShortName === "SIG") {
      //   this.activeAsset[0].InkColor = deafultCodeValues.LegendColor1; this.activeAsset[0].SheetColor = deafultCodeValues.SheetColor1;this.activeAsset[0].SheetColor2 = deafultCodeValues.SheetColor2;
      //   this.activeAsset[0].SheetType = deafultCodeValues.SheetType1; this.activeAsset[0].SheetType2 = deafultCodeValues.SheetType2;
      // }
    });
  }
  getChanges(oldArray, newArray) {
    let i, item, j, len;
    if (JSON.stringify(oldArray) === JSON.stringify(newArray)) {
      return [];
    }
    const changes = [];
    for (i = j = 0, len = newArray.length; j < len; i = ++j) {
      item = newArray[i];
      if (JSON.stringify(item) !== JSON.stringify(oldArray[i])) {
        changes.push(item);
      }
    }
    return changes;
  }
}
