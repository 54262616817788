

<div id="cardview-container" style="display:flex; flex-direction: row;  height:100%">
  <!-- <div style="position: absolute;right: 16px;"> -->
    <!-- dynamic based on open windows -->
    <sms-assetDetail
      id="assestDetailDragDiv" 
      *ngIf="showAssetDtl==true" 
      style="height:100%"
      [AI]="AI"
      [map]="map"
      [Edit]="Edit"
      [Draw]="Draw"
      [Editor]="Editor"
      [Query]="Query"
      [QueryTask]="QueryTask"
      [Graphic]="Graphic"
      [FeatureLayer]="FeatureLayer"
      [signLayer]="FeatureLayer"
      [webMercatorUtils]="webMercatorUtils"
      [Polygon]="Polygon"
      [geometryEngine]="geometryEngine"
      [featureLayer]="featureLayer"
      [activeAsset]="activeAsset"
      [asset]="asset"
      [geometryJsonUtils]="geometryJsonUtils"
      [isAssetNew]="eventEmitersService.addNewParent"
      [isDeleteClicked]="isDeleteClicked"
      (closeAssetDetailCard)="closeAssetDetail()"
      (checkIfNewAsset)="getIfNewAsset()"
      (assetAttributes)="getAssetAttributes()"
      [Point]="Point"
      [relatedAsset]="relatedAsset"
      (assetUpdated)="loadSelectedParentFeatureInView()"
      [rowdataobj]="rowdataobj"
      [ismodelpopup]="false"
      [isVisualizationAsset]="activeVisualizationAsset"
      [selectedLayer]="selectedLayer"
      [currentLayerData]="currentLayerData"
      [activeParentAsset]="activeParentAsset"
      [newChildFeature]="newChildFeature"
      [newChildFClass]="newChildFClass"
      [isVisualizationAsset]="activeVisualizationAsset"
      [currentLayerData]="currentLayerData"
    >
    </sms-assetDetail>
  <!-- </div> -->
  <!-- <div style="position: absolute;right: 16px;"> -->
  <div id="cardview" style="display:flex; flex-direction:column;" class="cardview-content" cdkDrag cdkDragBoundary="#cardViewDiv" > 
    <div class="avPanel" id="assestDragDiv" style="overflow:hidden; border-radius: none;  height:100%">
      <app-heading-component
      cdkDragHandle
        [headingText]="assetViewerLabel"
        [variant]="'threeIcon'"
        [iconOne]="getRelocateAssetIcon()"
        tooltipOne="{{ 'Select.carddisplay.relocatebtn' | translate }}"
        [iconTwo]="getAssetAddIcon()"
        [tooltipTwo]="getAssetAddTooltip()"
        [iconTwoDropdown]="true"
        [iconThree]="getCloseIcon()"
        [tooltipThree]="'Close'"
        [tableAliases]="tableAliases"
        [signAddAccess]="signAddAccess"
        [lightAddAccess]="lightAddAccess"
        [iconFour]="getMinMaxIcon()"
        [tooltipFour]="isShown == false ? expand : minimize"
        reshapeTooltip="{{ 'Select.carddisplay.reshapebtn' | translate }}"
        (actionFour)="minimizeAssetViewer()"
        (actionThree)="closeAssetViewer()"
        (actionTwo)="(null)"
        (reshape)="reshape()"
        (dropdownAction)="relAssetSelect($event)"
        (actionOne)="relocateAsset()"
        [isCardView]="true"
      >
        <!-- TSDRSAM-1815 reshape icon was missing -->
        <!-- [iconFive]="getReshapeAssetIcon()"
        (actionFive)="reshape()" -->
      </app-heading-component>
      <!-- Header And close button and Select layer -->
      <div id="assestdiv" style="overflow:auto">
        <!-- Layer Selection -->
        <div *ngIf="rowdataobj" class="layer-selection">
          <div *ngIf="!activeVisualizationAsset" class="asset-card-sub-heading h6" style="visibility: hidden">
            {{ selectedLayer }}
          </div>
          <div class="layer-select">
            <mat-form-field class="small">
              <mat-select [(ngModel)]="selectedLayer">
                <mat-option (onSelectionChange)="layerChangedFromUi($event, i)" [value]="layer.LayerName.layerName" *ngFor="let layer of rowdataobj; let i = index">
                  {{ layer.LayerName.tableAlias }}
                </mat-option>
              </mat-select>

              <div class="dropdown-icons">
                <mat-icon matSuffix svgIcon="arrow-down"></mat-icon>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="layer-selection" *ngIf="!rowdataobj && selectedLayer && dropdownValues.length > 0">
          <div class="layer-select">
            <mat-form-field class="small">
              <mat-select [(ngModel)]="selectedLayer">
                <mat-option (onSelectionChange)="layerChangedFromUi($event, i)" [value]="opt.Layer" *ngFor="let opt of dropdownValues; let i = index" name="userlyrtype">
                  <span *ngIf="!scndlayer">{{ opt.layer_id.tableAlias }} </span><span *ngIf="scndlayer">{{ opt.layer_id.tableSL }} </span>
                </mat-option>
              </mat-select>
              <div class="dropdown-icons">
                <mat-icon matSuffix svgIcon="arrow-down"></mat-icon>
              </div>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div *ngIf="activeVisualizationAsset" class="avAssetCard avCurrentAsset">
            <div class="avAssetCardContent show hand-pointer" id="assetCard" tabindex="0" (click)="OpenVisualizationAssetDetail()">
              <div class="card-body avRow" style="padding-top: 16px; padding-bottom: 16px">
                <div class="asset-card-sub-heading h6">Visualization Layer Data</div>
              </div>
            </div>
            <div (click)="OpenVisualizationAssetDetail()" style="color: black"></div>
          </div>
        </div>

        <div class="avScrollArea scroll-avPanel">
          <!-- Parent Asset Card -->
          <div
            *ngIf="activeParentAsset && !eventEmitersService.toggleParent"
            [ngClass]="eventEmitersService.activeAssetFeature[0].AssetID === activeParentAsset[0]['AssetID'] ? 'avAssetCard avCurrentAsset' : 'avAssetCard'"
          >
            <div class="avAssetCardContent show hand-pointer asset-header" id="assetCard" (click)="OpenAssetDetail('Parent', null, null)">
              <div class="img-container">
                <img tabindex="0" class="text-center justify-content-center align-self-center" [src]="activeParentAsset[7]?.ImageBase64" (error)="onPicError($event)" alt="{{ activeParentAsset[0]['AssetCode'] }}" />
              </div>
              <div class="key-columns">
                <span class="h7" *ngIf="fieldsInfo.length > 0 && fieldsInfo[0].codedValues">{{ fieldsInfo[0].codedValues[activeParentAsset[0]["AssetCode"]] }}</span>
                <span class="h7" *ngIf="fieldsInfo.length > 0 && !fieldsInfo[0].codedValues">{{ activeParentAsset[0]["AssetCode"] }}</span>
                <span class="xxsmall">{{ activeParentAsset[0]["AssetID"] }}</span>
                <span *ngIf="activeParentAsset[5].task != null && activeParentAsset[5].task != 0" class="badge badge-pill badge-warning">{{ activeParentAsset[5]["task"] ? activeParentAsset[5]["task"] : 0 }}</span>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <!--jcc 9/22/20 switch to AssetID because it is possible for a related Sign and Support to have the same ObjectID, in which case both cards were shown as current. -->
          <div *ngIf="rowdataobj">
            <div
              *ngIf="activeParentAsset && !eventEmitersService.toggleParent"
              [ngClass]="eventEmitersService.activeAssetFeature[0].AssetID === activeParentAsset[0]['AssetID'] ? 'avAssetCard avCurrentAsset' : 'avAssetCard'"
            >
              <div class="avAssetCardContent show hand-pointer" id="assetCard" (click)="OpenAssetDetail('Parent', null, null)">
                <div class="card-body avRow xsmall">
                  <!-- Parent -- Support/Guardrail  -->
                  <!-- Added by Abhilash for JIRA ID:-34 on 08/12/2021 -->
                  <div *ngFor="let item of fieldsInfo; let rid = index">
                    <div *ngIf="item.IsCardviewColumnVisible === 1 && item.fieldName === 'DoNotReplace'">
                      <div class="avListRow">
                        <div class="avFormLabel">{{ item.label }}</div>
                        <div class="avFormValue" *ngIf="activeParentAsset[0][item.fieldName] == 1 && selectedDomain[1].code == 1 && selectedDomain[1].name != null" class="avJustifyRight avFormValue">
                          {{ selectedDomain[1].name }}
                        </div>
                        <div class="avFormValue" *ngIf="activeParentAsset[0][item.fieldName] == 0 && selectedDomain[0].code == 0 && selectedDomain[0].name != null" class="avJustifyRight avFormValue">
                          {{ selectedDomain[0].name }}
                        </div>
                      </div>
                      <div class="divider"></div>
                    </div>
                    <!-- Apply condition on class  ngif class Mani 11/19/2021-->
                    <div
                      *ngIf="
                        item.fieldName != 'DoNotReplace' &&
                        item.IsCardviewColumnVisible === 1 &&
                        item.fieldName !== 'InstallDate' &&
                        item.fieldName !== 'CollectDate' &&
                        item.fieldName !== 'LastModifyDate' &&
                        item.fieldName !== 'RetireDate'
                      "
                    >
                      <div class="avListRow">
                        <div>{{ item.label }}</div>
                        <div class="avFormValue">{{ activeParentAsset[0][item.fieldName] }}</div>
                      </div>
                      <div class="divider"></div>
                    </div>
                    <div *ngIf="item.IsCardviewColumnVisible === 1 && (item.fieldName === 'InstallDate' || item.fieldName === 'CollectDate' || item.fieldName === 'LastModifyDate' || item.fieldName === 'RetireDate')">
                      <!-- Apply condition on class  ngif class Mani 11/19/2021-->
                      <div class="avListRow">
                        <div>{{ item.label }}</div>
                        <div class="avFormValue">{{ activeParentAsset[0][item.fieldName] | date : "MM/dd/yyyy" }}</div>

                        <!--  8/14/20 jcc -->
                      </div>
                      <div class="divider"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- USE TABLE ALIAS -->
            <!-- Child Asset Card -->
            <div *ngIf="loading" class="spinner-border spinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="child-card" *ngIf="showChildCard">
              <div *ngFor="let relatedFeatures of childRecords; let rid = index">
                <div
                  *ngIf="
                    relatedFeatures.RelatedData &&
                    relatedFeatures.RelatedData.length > 0 &&
                    !relatedFeatures.Layer.includes('Maintenance') &&
                    !relatedFeatures.Layer.includes('Assessment') &&
                    !relatedFeatures.Layer.includes('Photo') &&
                    !relatedFeatures.Layer.includes('Documents')
                  "
                >
                  <div class="avSectionHeader" *ngIf="relatedFeatures.RelatedData && relatedFeatures.RelatedData.length > 0 && childLayerAccess(relatedFeatures)">
                    <div *ngIf="relatedFeatures.RelatedData && relatedFeatures.RelatedData.length > 0 && childLayerAccess(relatedFeatures)" class="avRow">
                      <div class="h6 child-heading">
                        {{ relatedFeatures.Layer | slice : selectedLayer.length + 2 : relatedFeatures.Layer.length }}
                        <div class="divider"></div>
                      </div>
                    </div>
                  </div>

                  <!-- Related Assets loop Single in Card Body -->
                  <div *ngIf="relatedFeatures.Layer == 'SupportToSign' && !eventEmitersService.toggleSign && childLayerAccess(this.currentUser.serviceLyrInfo.signId.toString())">
                    <div class="show hand-pointer" id="relAssetCard_{{ relatedFeatures.Layer }}">
                      <div *ngFor="let relatedFeature of relatedFeatures.RelatedData; let rid = index">
                        <div *ngIf="relatedFeature.attributes.Status != 'Inactive'">
                          <!-- 8/6/2020 jcc don't display inactive child assets-->
                          <!--jcc 9/22/20 switch to AssetID because it is possible for a related Sign and Support to have the same ObjectID, in which case both cards were shown as current. -->
                          <div
                            [ngClass]="eventEmitersService.activeAssetFeature[0].AssetID === relatedFeature['attributes']['AssetID'] ? 'avAssetCard avCurrentAsset' : 'avAssetCard'"
                            (click)="OpenAssetDetail('Child', relatedFeature, relatedFeatures)"
                          >
                            <div class="avRow asset-header avAssetCardContent">
                              <div class="img-container">
                                <img
                                  tabindex="0"
                                  class="text-center justify-content-center align-self-center"
                                  [src]="relatedFeature?.ImageBase64"
                                  (error)="setSignPic($event, relatedFeature)"
                                  alt="{{ relatedFeature['attributes']['AssetCode'] }}"
                                />
                              </div>
                              <div class="key-columns">
                                <span class="h7" *ngIf="fieldsInfo.length > 0 && fieldsInfo[0].codedValues">{{ relatedFeature["attributes"]["AssetCode"] }}</span>
                                <span class="xxsmall">{{ relatedFeature["attributes"]["AssetID"] }}</span>
                                <span *ngIf="relatedFeature.attributes.task != null && relatedFeature.attributes.task != 0" class="badge badge-pill badge-warning">{{
                                  relatedFeature["attributes"]["task"] ? relatedFeature["attributes"]["task"] : 0
                                }}</span>
                              </div>
                              <!-- Added by Sowjanya Jira Id:-32 on 10/11/2021 -->  
                            </div>
                           <div class="donot-replace-icon-div" *ngIf="relatedFeature.attributes.DoNotReplace == 1">
                                <img class="donot-replace-icon" src="assets/images/error_icon.png" />
                            </div>
                            <div class="divider"></div>
                            <div class="card-body avRow avAssetCardContent">
                              <!-- p-3 -->
                              <div class="w-100" *ngFor="let item of fieldsInfoSign; let fid = index">
                                <div *ngIf="item.IsCardviewColumnVisible === 1 && item.fieldName === 'DoNotReplace'">
                                  <div class="avListRow">
                                    <div class="avFormLabel">{{ item.label }}</div>

                                    <div *ngIf="relatedFeature['attributes'][item.fieldName] == 1 && selectedDomain[1].code == 1" class="avJustifyRight avFormValue">{{ selectedDomain[1].name }}</div>
                                    <div *ngIf="relatedFeature['attributes'][item.fieldName] == 0 && selectedDomain[0].code == 0" class="avJustifyRight avFormValue">{{ selectedDomain[0].name }}</div>

                                    <!-- <div class="avJustifyRight avFormValue">{{relatedFeature["attributes"][item.fieldName]}}</div> -->

                                    <!-- <div *ngIf="item.fieldName.codedValues[0]==item.fieldName.codedValues[0] ? 'No' : 'Yes'" class="avJustifyRight avFormValue">{{item.fieldName.codedValues[0]}}</div> -->
                                  </div>
                                </div>
                                <!-- Added by Abhilash for JIRA ID:-34 on 08/12/2021 -->
                                <!-- Sign Info -->
                                <div
                                  *ngIf="
                                    item.fieldName != 'DoNotReplace' &&
                                    item.IsCardviewColumnVisible === 1 &&
                                    item.fieldName !== 'InstallDate' &&
                                    item.fieldName !== 'CollectDate' &&
                                    item.fieldName !== 'LastModifyDate' &&
                                    item.fieldName !== 'RetireDate'
                                  "
                                >
                                  <div class="avListRow">
                                    <div class="avFormLabel">{{ item.label }}</div>
                                    <div class="avJustifyRight avFormValue">{{ relatedFeature["attributes"][item.fieldName] }}</div>
                                  </div>
                                  <div class="divider"></div>
                                </div>
                                <div
                                  *ngIf="
                                    item.IsCardviewColumnVisible === 1 && (item.fieldName === 'InstallDate' || item.fieldName === 'CollectDate' || item.fieldName === 'LastModifyDate' || item.fieldName === 'RetireDate')
                                  "
                                >
                                  <div class="avListRow" >
                                    <div class="avFormLabel">{{ item.label }}</div>
                                    <div class="avJustifyRight avFormValue">{{ relatedFeature["attributes"][item.fieldName] | date : "MM/dd/yyyy" }}</div>

                                    <!--  8/14/20 jcc -->
                                  </div>
                                  <div class="divider"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="relatedFeatures.Layer == 'SupportToLight' && !eventEmitersService.toggleLight && childLayerAccess(this.currentUser.serviceLyrInfo.lightId.toString())" class="xavAssetCard">
                  <div class="show hand-pointer" id="relAssetCard_{{ relatedFeatures.Layer }}">
                    <div *ngFor="let relatedFeature of relatedFeatures.RelatedData; let rid = index">
                      <div *ngIf="relatedFeature.attributes.Status != 'Inactive'">
                        <!-- 8/6/2020 jcc don't display inactive child assets-->
                        <!--jcc 9/22/20 switch to AssetID because it is possible for a related Sign and Support to have the same ObjectID, in which case both cards were shown as current. -->
                        <div
                          [ngClass]="eventEmitersService.activeAssetFeature[0].AssetID === relatedFeature['attributes']['AssetID'] ? 'avAssetCard avCurrentAsset' : 'avAssetCard'"
                          (click)="OpenAssetDetail('Child', relatedFeature, relatedFeatures)"
                        >
                          <div class="avRow asset-header avAssetCardContent">
                            <div class="img-container">
                              <img
                                tabindex="0"
                                class="text-center justify-content-center align-self-center"
                                [src]="relatedFeature?.ImageBase64"
                                (error)="setSignPic($event, relatedFeature)"
                                alt="{{ relatedFeature['attributes']['AssetCode'] }}"
                              />
                            </div>
                            <div class="key-columns">
                              <span class="h7">{{ relatedFeature["attributes"]["AssetCode"] }}</span>
                              <span class="xxsmall">{{ relatedFeature["attributes"]["AssetID"] }}</span>
                              <span *ngIf="relatedFeature.attributes.task != null && relatedFeature.attributes.task != 0" class="badge badge-pill badge-warning">{{
                                relatedFeature["attributes"]["task"] ? relatedFeature["attributes"]["task"] : 0
                              }}</span>
                            </div>
                            <!-- Added by Sowjanya Jira Id:-32 on 10/11/2021 -->
                            <!-- <div class="donot-replace-icon-div" *ngIf="relatedFeature.attributes.DoNotReplace == 1">
                                      <img class="donot-replace-icon" src="assets/images/error_icon.png" />
                                    </div> -->
                          </div>
                          <div class="donot-replace-icon-div" *ngIf="relatedFeature.attributes.DoNotReplace == 1">
                                    <img class="donot-replace-icon" src="assets/images/error_icon.png" />
                                  </div>
                          <!-- p-3 -->
                          <!-- Added by Abhilash for JIRA ID:-34 on 08/12/2021 -->
                          <!-- Light -->
                          <div class="divider"></div>
                          <div class="card-body avRow avAssetCardContent">
                            <div class="w-100" *ngFor="let item of fieldsInfoLight; let rid = index">
                              <div *ngIf="item.IsCardviewColumnVisible === 1 && item.fieldName === 'DoNotReplace'">
                                <div class="avListRow">
                                  <div class="avFormLabel">{{ item.label }}</div>
                                  <!-- <div class="avJustifyRight avFormValue">{{relatedFeature["attributes"][item.fieldName]}}</div> -->
                                  <!-- <div *ngIf="item.fieldName.codedValues[0]==item.fieldName.codedValues[0] ? 'No' : 'Yes'" class="avJustifyRight avFormValue">{{item.fieldName.codedValues[0]}}</div> -->
                                  <div *ngIf="relatedFeature['attributes'][item.fieldName] == 1 && selectedDomain[1].code == 1" class="avJustifyRight avFormValue">{{ selectedDomain[1].name }}</div>
                                  <div *ngIf="relatedFeature['attributes'][item.fieldName] == 0 && selectedDomain[0].code == 0" class="avJustifyRight avFormValue">{{ selectedDomain[0].name }}</div>
                                  <div class="divider"></div>
                                </div>
                              </div>

                              <div
                                *ngIf="
                                  item.fieldName != 'DoNotReplace' &&
                                  item.IsCardviewColumnVisible === 1 &&
                                  item.fieldName !== 'InstallDate' &&
                                  item.fieldName !== 'CollectDate' &&
                                  item.fieldName !== 'LastModifyDate' &&
                                  item.fieldName !== 'RetireDate'
                                "
                              >
                                <div class="avListRow">
                                  <div class="avFormLabel">{{ item.label }}</div>
                                  <div class="avJustifyRight avFormValue">{{ relatedFeature["attributes"][item.fieldName] }}</div>
                                </div>

                                <div class="divider"></div>
                          
                              </div>
                              <div
                                *ngIf="item.IsCardviewColumnVisible === 1 && (item.fieldName === 'InstallDate' || item.fieldName === 'CollectDate' || item.fieldName === 'LastModifyDate' || item.fieldName === 'RetireDate')"
                              >
                                <div class="avListRow">
                                  <div class="avFormLabel">{{ item.label }}</div>
                                  <div class="avJustifyRight avFormValue">{{ relatedFeature["attributes"][item.fieldName] | date : "MM/dd/yyyy" }}</div>
                                  <!--  8/14/20 jcc -->
                                </div>

                                <div class="divider"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End -->
              </div>
              <!-- End -->
            </div>
          </div>
          <!-- End Scroll Area -->
        </div>
      </div>

      <!-- Navigation Buttons Start -->
      <!-- </div> -->
    </div>
        <!-- Navigation Buttons Start -->
        <div class="avFooter" *ngIf="rowdataobj && !this.eventEmitersService.addNewParent && !this.eventEmitersService.addNewChild" style="z-index: 201; background: white; width: 95%;">
          <button mat-button title="Go to First" (click)="handlePagination(0)">
            <mat-icon svgIcon="ff-left"></mat-icon>
          </button>
          <button mat-button title="View Previous Asset" (click)="handlePagination(1)">
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <div class="text-center" class="small">{{ activeVisualizationAsset == true ? currentVisualizationDataIndex + 1 : eventEmitersService.selectedParentIndexOnData + 1 }} of {{ selectedCount }}</div>
          <button mat-button title="View Next Asset" (click)="handlePagination(2)">
            <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
          <button mat-button title="Go to Last" (click)="handlePagination(3)">
            <mat-icon svgIcon="ff-right"></mat-icon>
          </button>
        </div>
  </div>
</div>
