<div class="sms-model-height">
  <div class="card">
    <div class="card-header">
      <h3>
        {{ "Userscreens.gpstracklog.desctext1" | translate }} <br /><span class="badge badge-secondary">{{ "Userscreens.gpstracklog.desctext2" | translate }}</span>
      </h3>
    </div>
    <div id="form-container" class="card-body">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Userscreens.gpstracklog.inspector" | translate }}:</mat-label>
        <mat-select [(ngModel)]="selectedUser">
          <mat-option [value]="user" *ngFor="let user of clientUsersList">
            {{ user.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="dt" appearance="outline">
        <mat-label>{{ "Userscreens.gpstracklog.startdate" | translate }}:</mat-label>
        <input [max]="endDate" [(ngModel)]="startDate" matInput [matDatepicker]="picker1" id="gpsCalInput1" />
        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="dt" appearance="outline">
        <mat-label>{{ "Userscreens.gpstracklog.enddate" | translate }}:</mat-label>
        <input [max]="todayDate" [(ngModel)]="endDate" [min]="startDate" matInput [matDatepicker]="picker" id="gpsCalInput2" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="card-footer text-right">
      <button type="button" class="btn btn-secondary mr-3" (click)="clear()">{{ "Userscreens.gpstracklog.clear" | translate }}</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="findTrackLogs()">{{ "Userscreens.gpstracklog.findtracklogs" | translate }}</button>
    </div>
  </div>
</div>
