import { Component, OnInit, Input } from "@angular/core";
import { ILayer } from "src/app/interface/layer";
import Swal from "sweetalert2";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sms-map-export",
  templateUrl: "./map-export.component.html",
  styleUrls: ["./map-export.component.scss"]
})
export class MapExportComponent implements OnInit {
  constructor(private genericLayerService: GenericLayerService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  @Input() map;
  @Input() Geoprocessor;
  selectedRegion;
  selectedLayer;
  layerList;
  regionList;
  gpServiceUrl;
  private currentUser: any;
  RegionLabel;
  AssetClassLabel;
  ExportLabel;
  ExportHeading;
  ExportStatus;
  loading = false; // Loading Control //
  Primarylangvalid;
  Secodarylangvalid;
  public scndlayer = false;

  ngOnInit() {
    this.ExportHeading = "Userscreens.mapexport.exportheading";
    this.RegionLabel = "Userscreens.mapexport.regionlabel";
    this.AssetClassLabel = "Userscreens.mapexport.assetclasslabel";
    this.ExportLabel = "Userscreens.mapexport.exportlabel";
    this.regionList = [];
    this.layerList = [];
    this.layerList = this.map.smsLayerdata.userLayers.filter((obj: ILayer) => obj.layerType === "inventory");
    this.regionList = this.map.smsRegiondata;
    this.layerList.push({
      acessPrivilege: "Data Editor",
      layerId: 22,
      layerName: "Sign",
      layerType: "inventory",
      lyrObj: null,
      visible: true,
      tableAlias: "Sign",
      tableSL: "Sign"
    });
    this.layerList.push({
      acessPrivilege: "Data Editor",
      layerId: 23,
      layerName: "Light",
      layerType: "inventory",
      lyrObj: null,
      visible: true,
      tableAlias: "Light",
      tableSL: "Light"
    });
    this.gpServiceUrl = new this.Geoprocessor(environment.gpService);
    this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;

      if (this.Primarylangvalid === this.currentUser.languageID) {
        this.scndlayer = false;
      } else if (this.Secodarylangvalid === this.currentUser.languageID) {
        this.scndlayer = true;
      }
    });
  }

  export() {
    try {
      if (this.selectedRegion === undefined || this.selectedRegion === "") {
        Swal.fire(this.translateService.instant("Userscreens.alertmapexport.selectregion"));
      } else if (this.selectedLayer === undefined || this.selectedLayer === "") {
        Swal.fire(this.translateService.instant("Userscreens.alertmapexport.selectasset"));
      } else {
        const params = {
          // eslint need to fix
          // eslint-disable-next-line camelcase
          in_clientID: this.currentUser.clientId,
          // eslint-disable-next-line camelcase
          in_region: "Region" + this.selectedRegion.regionLevel + "='" + this.selectedRegion.regionName + "'",
          // eslint-disable-next-line camelcase
          in_layerName: this.selectedLayer.layerName
        };
        this.gpServiceUrl.submitJob(params, this.gpJobComplete.bind(this), this.gpJobStatus.bind(this), this.gpJobFailed.bind(this));
      }
    } catch (error) {
      console.error(error);
    }
  }

  onChange() {
    this.ExportStatus = "";
    this.loading = false;
  }

  gpJobComplete(jobinfo) {
    try {
      const URLData = environment.gpService + "/jobs/" + jobinfo.jobId + "/results/outputPath";
      this.genericLayerService.getServiceInfo(URLData).subscribe(
        (urlData: any) => {
          const OpenURL = urlData.value;
          window.open(OpenURL);
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  gpJobStatus(jobinfo) {
    try {
      switch (jobinfo.jobStatus) {
        case "esriJobSubmitted":
          this.ExportStatus = this.translateService.instant("Userscreens.alertmapexport.statussubmitted");
          this.loading = true;
          break;
        case "esriJobExecuting":
          this.ExportStatus = this.translateService.instant("Userscreens.alertmapexport.statusprocessing");
          this.loading = true;
          break;
        case "esriJobSucceeded":
          this.ExportStatus = this.translateService.instant("Userscreens.alertmapexport.chkdownloadfldr");
          this.loading = false;
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
  gpJobFailed(error) {
    Swal.fire(error);
  }
}
