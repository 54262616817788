<div class="card-body">
    <div class="form-row pb-5">
        <div class="col-3"></div>
        <div class="col-2">
            <span>{{'Systemreports.democratizeasset.selectregion'|translate}}:</span>
        </div>
        <div class="col-3">
            <select class="form-control" (change)="regionOnChange()" [(ngModel)]="selectedRegion"
            [attr.aria-label]="''+'Systemreports.democratizeasset.selectregion'|translate">
                <option *ngFor="let region of RegionsListByClient;" [ngValue]="region">
                    {{region.regionName}}
                    </option>
              </select>
        </div>
        <div class="col-4"></div>
    </div>
</div>
<div class="card-footer text-right">
    <button type="button" class="btn btn-primary" (click)="openReports()" [attr.aria-label]="''+'Systemreports.btn.generatereport'|translate">{{'Systemreports.btn.generatereport'|translate}}</button>
</div>
<div class="form-popup" id="myForm">
    <form class="form-container">
        <div class="animated fadeIn">
            <div class="col-12">
                <div class="row">
                    <div class="col-11"></div>
                    <div class="col-1 float-right">
                        <button type="button" (click)="close()" class="close" aria-label="Close">
                                <fa-icon [icon]="faTimes" aria-hidden="true"></fa-icon>
                        </button>
                        <button type="button" (click)="makePDF()" class="close" aria-label="Download as PDF">
                                <fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>
                            </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="card">
                            <div class="card-header">
                                <button class="btn btn-secondary Container" type="button" id="YearButton" (click)="YearClick()" [attr.aria-label]="''+'Systemreports.democratizeasset.yearbtn'|translate">{{'Systemreports.democratizeasset.yearbtn'|translate}}</button>
                                <button class="btn btn-secondary Container" type="button" id="QuarterButton" (click)="QuarterClick()" [attr.aria-label]="''+'Systemreports.democratizeasset.quaterbtn'|translate">{{'Systemreports.democratizeasset.quaterbtn'|translate}}</button>
                                <button class="btn btn-secondary" type="button" id="MonthButton" (click)="MonthClick()" [attr.aria-label]="''+'Systemreports.democratizeasset.monthbtn'|translate">{{'Systemreports.democratizeasset.monthbtn'|translate}}</button>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6 textColor" style="text-align: right;">{{'Systemreports.democratizeasset.regionlabel'|translate}}</div>
                                    <div class="col-6 textColor" style="text-align: left;">{{region}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-12 textColor" style="text-align: center;">
                                        <span>
                                            {{'Systemreports.democratizeasset.numroadasset'|translate}}
                                        </span>
                                    </div>
                                </div>
                                <div class=" row tablealiasFixHead">
                                    <table class="col-12">
                                        <thead>
                                            <tr>
                                                <th>{{'Systemreports.democratizeasset.assetclass'|translate}}</th>
                                                <th>{{'Systemreports.democratizeasset.totalnumasset'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let flayer of democratizeassets;let lid = index">
                                            <tr>
                                                <td>{{flayer.layer}}</td>
                                                <td>{{flayer.assetCount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">
                                    <div class="col-12 textColor" style="text-align: center;">
                                        <span>
                                            {{'Systemreports.democratizeasset.humanfactordamage'|translate}}:
                                        </span>
                                    </div>
                                </div>
                                <!-- Human Factor -->
                                <div class=" row tableFixHead">
                                    <table class="col-12">
                                        <thead>
                                            <tr>
                                                <th>{{'Systemreports.democratizeasset.causedby'|translate}}</th>
                                                <th>{{'Systemreports.democratizeasset.totalcases'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let flayer of humanfactor;let lid = index">
                                            <tr>
                                                <td>{{flayer.damageCause}}</td>
                                                <td>{{flayer.casesCount}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-12">
                                <div class="card text-white bg-gray">

                                    <div class="card-body">
                                        <div class="chart-wrapper" style="text-align: center;">
                                            <label class="textColor">{{barChartLabel}}</label>
                                            <canvas baseChart class="chart barCanvas" [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                                                [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"
                                                (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card text-white bg-gray">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-4" style="text-align: center;">
                                                <label class="textColor">{{'Systemreports.democratizeasset.assetcondition'|translate}}</label>
                                                <div class="chart-wrapper" *ngIf="PieChartAC">
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartDataAC" [labels]="pieChartLabelsAC" [chartType]="pieChartType"
                                                        [plugins]="barChartPlugins" (chartHover)="chartHovered($event)" [options]="pieChartOptionsAC"
                                                         (chartClick)="chartClicked($event)"></canvas>
                                                </div>
                                            </div>
                                            <div class="col-4" style="text-align: center;">
                                                <div class="chart-wrapper" *ngIf="PieChartRA">
                                                    <label class="textColor">{{'Systemreports.democratizeasset.reasonroadassetreplace'|translate}}:</label>
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartDataRA" [labels]="pieChartLabelsRA" [chartType]="pieChartType"
                                                        [plugins]="barChartPlugins" (chartHover)="chartHovered($event)" [options]="pieChartOptionsRA"
                                                         (chartClick)="chartClicked($event)"></canvas>
                                                </div>
                                            </div>
                                            <div class="col-4" style="text-align: center;">
                                                <div class="chart-wrapper" *ngIf="PieChartAD">
                                                    <label class="textColor">{{'Systemreports.democratizeasset.activityindamages'|translate}}</label>
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartDataAD" [labels]="pieChartLabelsAD" [chartType]="pieChartType"
                                                        [plugins]="barChartPlugins" (chartHover)="chartHovered($event)" [options]="pieChartOptionsAD"
                                                         (chartClick)="chartClicked($event)"></canvas>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 float-left">
                        <div class="row">
                            <div class="Container" *ngFor="let flayer of LayerName;let lid = index">
                                <button type="button" class="btn btn-secondary btn-sm btn-block responsive-width" id="{{flayer.Layer}}" (click)="AssetClassValue(flayer.Layer)" [attr.aria-label]="''+ flayer.alias">
                                    {{flayer.alias}} 
                                </button>
                            </div> &nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>