import { Injectable } from "@angular/core";
import { AssetDetailService } from "./assetDetail.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root"
})
export class CardViewService {
  public currentUser: any;
  constructor(public objAssetDetailService: AssetDetailService, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  getFeatures(layerURL, WhereCondition, Query, QueryTask) {
    return new Promise((resolve) => {
      const query = new Query();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.where = WhereCondition;
      const queryTask = new QueryTask(layerURL);
      queryTask.execute(query).then((result) => {
        if (result && result.features[0]) {
          resolve(result.features);
        }
      });
    });
  }
  // TSDRSAM-352 By Manikantha
  getFeaturesorderByLastModifyDate(layerURL, WhereCondition, Query, QueryTask) {
    return new Promise((resolve) => {
      const query = new Query();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.where = WhereCondition;
      query.orderByFields = ["LastModifyDate"];
      const queryTask = new QueryTask(layerURL);
      queryTask.execute(query).then((result) => {
        if (result && result.features[0]) {
          resolve(result.features);
        }
      });
    });
  }
  // populateChangedAssetDefaultValues(placedinRegion, asset) {
  //   return new Promise((resolve) => {
  //     const promise = this.objAssetDetailService.populateDefaultRegionValues(this.currentUser,
  //  placedinRegion, this.Query, this.QueryTask);
  //     promise.then((result) => {
  //       console.log(result);
  //       let regionData: any;
  //       regionData = result;
  //       let attrib = asset.attributes;
  //       if (placedinRegion.regionLevel == 4) {
  //         attrib["region1"] = regionData.features[0].attributes.RegionName1;
  //         attrib["region2"] = regionData.features[0].attributes.RegionName2;
  //         attrib["region3"] = regionData.features[0].attributes.RegionName3;
  //         attrib["region4"] = regionData.features[0].attributes.RegionName4;
  //       }
  //       if (placedinRegion.regionLevel == 3) {
  //         attrib['region1'] = regionData.features[0].attributes.RegionName1;
  //         attrib['region2'] = regionData.features[0].attributes.RegionName2;
  //         attrib['region3'] = regionData.features[0].attributes.RegionName3;
  //       }
  //       if (placedinRegion.regionLevel == 2) {
  //         attrib['region1'] = regionData.features[0].attributes.RegionName1;
  //         attrib['region2'] = regionData.features[0].attributes.RegionName2;
  //       }
  //       asset.setAttributes(attrib);
  //       resolve(asset);
  //     })
  //   })
  // }
}
