import { Input, Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sms-retro-admin",
  templateUrl: "./retro-admin.component.html",
  styleUrls: ["./retro-admin.component.scss"]
})
export class RetroAdminComponent implements OnInit {
  @Input() map: any;
  @Input() FeatureTable;
  @Input() FeatureLayer;
  @Input() Graphic;

  active = 1;
  ngOnInit(): void {
    this.active = 1;
  }
}
