import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IUser } from "../interface/user";
import { Observable, BehaviorSubject, forkJoin, from } from "rxjs";
import { UserService } from "./user.service";
import { SMSHttpClient } from "../helpers/http-client";
import Swal from "sweetalert2";
import { IRequestOptions } from "../interface/requestOption";
import { GISHttpClient } from "../helpers/gishttp-client";
import { LoginTranslateService } from "../shared/login-translation.service";
import { filter, take } from "rxjs/operators";
import { Client } from "../interface/client";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  public currentUser: Observable<IUser>;
  public dataEditorSettings: any;
  public currentUserSubject: BehaviorSubject<IUser>;
  public accessTokenObj: BehaviorSubject<any>;
  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };
  currentLang: string;
  minLength: any;
  maxLength: any;
  minLowercase: any;
  minUppercase: any;
  minSpec: any;
  minNumeric: any;
  passwordPattern: any;
  specialCharacters: any;

  constructor(private gishttp: GISHttpClient, private http: HttpClient, private smshttp: SMSHttpClient, private userService: UserService, public translate: LoginTranslateService) {
    this.accessTokenObj = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentUser_token")));
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem("currentUser")));
    this.currentUser = this.currentUserSubject.asObservable();

    if (this.accessTokenObj.value && this.checkTokenTimeOut(this.accessTokenObj.value.expires)) {
      if (this.accessTokenObj.value) {
        this.smshttp.setAccessToken(this.accessTokenObj.value.accessToken);
        this.gishttp.setAccessToken(this.accessTokenObj.value.accessToken);
        //send our token back to the service worker so it can modify requests. 
        this.updateServiceWorker(this.accessTokenObj.value.accessToken);
      }

      //get password pattern and rules heres so that when page refresh will get latest values
      this.userService.getClientListUser(this.currentUserValue.clientId).subscribe((res: any) => {
        this.setPasswordRules(res);
      });
      this.setRefreshTokenTimeout(this.accessTokenObj.value);
    } else {
      this.clearUserData();
    }

    localStorage.setItem("queryAttempts", "0");
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  public get accessToken(): any {
    return this.accessTokenObj.value;
  }

  login(Obj) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiBaseUrl + environment.apiroot + environment.authentication + "/SignIn", Obj, {}).subscribe(
        (res) => {
          if (res.accessToken) {
            this.setPasswordRules(res);
            this.smshttp.setAccessToken(res.accessToken);
            this.gishttp.setAccessToken(res.accessToken);
            this.accessTokenObj.next(res);
            this.updateServiceWorker(this.accessTokenObj.value.accessToken);
            this.setRefreshTokenTimeout(this.accessTokenObj.value);
            this.getUserInfo(res.userID, res.clientID)
              .then(() => {
                resolve(this.currentUser);
              })
              .catch((e) => {
                reject(e);
              });
            this.setDataEditorSettings(res.clientID);
          }
        },
        (err) => {
          if (err.error.message == "User.Password.Expired") {
            this.smshttp.setAccessToken(err.error.accessToken);
            this.setPasswordRules(err.error);
          }
          console.log(err);
          reject(err);
        }
      );
    });
  }

  PasswordChange(Clientid, Userid, data) {
    return new Promise((resolve, reject) => {
      this.smshttp.post<any>(environment.authChangePassword + "/" + Clientid + "/" + environment.getUser + "/" + Userid, data).subscribe(
        (res: any) => {
          if (res.message === "Password has been Changed Successfully") {
            resolve(res);
          } else {
            Swal.fire(res.message);
          }
        },
        (err) => {
          console.log(err.error.message);
          reject(err);
        }
      );
    });
  }

  getUserInfo(userId, clientID) {
    return new Promise((resolve, reject) => {
      forkJoin([this.userService.getUserInfo(clientID, userId), this.userService.getUserRolePermissionInfo(userId, clientID)]).subscribe(
        (res) => {
          if (res[0] && res[1]) {
            const user: IUser = res[0] as IUser;
            user.rolePermissions = res[1];
            this.currentUserSubject.next(user);
            //if cache cleared and new user on change password screen required to store regex
            localStorage.setItem("usernamePattern", user.usernamePattern);
            resolve(user);
          }
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  logout(clientId: number, userId: number): void {
    // remove user from local storage and set current user to null
    try {
      this.smshttp.put<any>(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId + "/logout?RefreshToken=" + this.accessTokenObj.value.refreshToken, {}).subscribe(_ => {
        this.clearUserData();
        location.reload();
      },
        (err) => {
          this.clearUserData()
          location.reload();
        });
    } catch (error) {
      console.error(error);
      location.reload();
    }
  }

  clearUserData() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUser_token");
    localStorage.removeItem("dataEditorSettings");
    localStorage.removeItem("workOrderDefaults");
    localStorage.removeItem("passwordPattern");
    localStorage.removeItem("usernamePattern");
    this.currentUserSubject.next(null);
    this.accessTokenObj.next(null);
    this.updateServiceWorker(null);
  }
  setRefreshTokenTimeout(res) {
    const timeDiff = this.getExpiredTime("hour", res.expires);

    if (timeDiff < 24) {
      // setting timeout only if time left is less than 24 hours
      setTimeout(() => {
        const responseType: IRequestOptions = {
          headers: new HttpHeaders({
            AccessToken: this.accessTokenObj.value.accessToken,
            RefreshToken: this.accessTokenObj.value.refreshToken
          })
        };
        this.smshttp.get(environment.authentication + "/Tokens/Refresh", responseType).subscribe((resToken: any) => {
            this.accessToken.accessToken = resToken.accessToken;
            this.accessToken.refreshToken = resToken.refreshToken;
            this.accessToken.expires = resToken.expires;
            this.saveCredsForRedirect();
            this.smshttp.setAccessToken(resToken.accessToken);
            this.gishttp.setAccessToken(resToken.accessToken);
            this.setRefreshTokenTimeout(resToken);
          },
          (err) => {
            this.logout(this.currentUserValue.clientId, this.currentUserValue.userId);
          }
        );
      }, this.getExpiredTime("ms", res.expires) - 300000);
    }
  }

  checkTokenTimeOut(expires: number) {
    return this.getExpiredTime("ms", expires) > 0;
  }

  getExpiredTime(unit: string, expires) {
    switch (unit) {
      case "hour":
        return (new Date(expires * 1000).getTime() - new Date().getTime()) / (1000 * 60 * 60);
        break;
      case "ms":
        return new Date(expires * 1000).getTime() - new Date().getTime();
        break;
    }
  }

  updateTOCStatus() {
    return this.smshttp.put(environment.getClientlist + "/" + this.currentUserValue.clientId + "/" + environment.getUserlist + "/" + this.currentUserValue.userId + "/ToC?DeviceType=web", {});
  }

  saveCredsForRedirect() {
    if (this.currentUserValue.defaultPasswordStatus && this.currentUserValue.webTOCStatus) {
      // Added for Proxy  enable

      console.log("////////////////////////////////////////////////");
      // tslint:disable-next-line:no-string-literal
      // this.currentUserValue["webServiceURL"] = "https://ramuatonline.com/proxy/proxy.ashx?" + this.currentUserValue["webServiceURL"];
      localStorage.setItem("currentUser", JSON.stringify(this.currentUserValue));
      localStorage.setItem("currentUser_token", JSON.stringify(this.accessToken));
    }
  }
  setDataEditorSettings(clientID) {
    this.userService.getClientListUser(clientID).subscribe((res: Client) => {
      console.log("..................Check Here ................");
      console.log(res);
      this.dataEditorSettings = { taskType: "New", hardDelete: res.hardDelete, IsOpenCreateEditTaskForm :res.isOpenCreateEditTaskForm };
      localStorage.setItem("dataEditorSettings", JSON.stringify(this.dataEditorSettings));
    });
  }

  getUsernamePattern() {
    if (localStorage.getItem("usernamePattern")) {
      return localStorage.getItem("usernamePattern");
    } else {
      this.getAuthPattern().subscribe((res: any) => {
        this.setAuthPattern(res.usernamePattern, res.passwordPattern);
        return res.UsernamePattern;
      });
    }
  }


  getAuthPattern() {
    return this.smshttp.get(environment.GetRegexPattern);
  }
  setAuthPattern(usernamePattern, passwordPattern) {
    localStorage.setItem("usernamePattern", usernamePattern);
  }
  updatePasswordFeedback() {
    this.currentLang = localStorage.getItem("currentUser_language");
    let originalMsg = this.translate.translations[this.currentLang]['Login.alertuseradmin.InvalidPassword'];
    const strFormat = (str, ...args) => args.reduce((s, v) => s.replace('%s', v), str);
    const updatedMsg = strFormat(originalMsg, this.minLength, this.maxLength, this.minUppercase, this.minLowercase, this.minNumeric, this.minSpec);
    //update messag here
    this.translate.translations[this.currentLang]['Login.alertuseradmin.InvalidPassword'] = updatedMsg + ' '+ this.specialCharacters;
  }
  setPasswordRules(res: Client) {
    this.minLength = res.pwMinLen;
    this.maxLength = res.pwMaxLen;
    this.minLowercase = res.pwMinLC;
    this.minUppercase = res.pwMinUC;
    this.minSpec = res.pwMinSpec;
    this.minNumeric = res.pwMinNum;
    this.passwordPattern = res.passwordPattern;
    this.specialCharacters = res.specialCharacters;
  }

  private updateServiceWorker(token: string): void {
    from(navigator.serviceWorker.getRegistration())
      .pipe(
        // Only push a token to the service worker after it's active
        filter((sw) => sw?.active != null),
        take(1)
      )
      .subscribe((sw) => {
        sw.active.postMessage(JSON.stringify({ proxytoken: token }));
      });
  }
}
