<div class="selection-container" style="margin-top:24px;">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" style="display:flex; width:100%; justify-content:center;">
        <mat-form-field class="form-element" style="margin-right:16px;">
            <mat-label>
                Select a Job
            </mat-label>
            <mat-select [formControl]="jobId" name="jobId">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let job of etlJobList$ | async" [value]="job.jobId">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                        <div>
                            {{job.layers}}
                        </div>
                        <div [ngStyle]="{'padding-right':job.reviewDate === null ? '17px' : '0' }">
                            {{job.requestTime | date:'shortDate'}}<span style="color:green; padding-left: 8px;"*ngIf="job.reviewDate != null">&#10003;</span>
                        </div>        
                    </div>
                </mat-option>
            </mat-select>
          </mat-form-field>
        <!-- Submit Button -->
        <div class="form-element" style="margin-right:16px">
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!formGroup.valid">View Report</button>
        </div>
    </form>
    <!-- Form ends -->

</div>
