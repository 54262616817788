import { Component } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  // See app.component.html
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    //MAIN NAVIGATION
    this.matIconRegistry.addSvgIcon("add", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/add_pin.svg`));
    this.matIconRegistry.addSvgIcon("query", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/add_query.svg`));
    this.matIconRegistry.addSvgIcon("download", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/download.svg`));
    this.matIconRegistry.addSvgIcon("share", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/share.svg`));
    this.matIconRegistry.addSvgIcon("erase", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/erase.svg`));
    this.matIconRegistry.addSvgIcon("exit-door", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/exit_door.svg`));
    this.matIconRegistry.addSvgIcon("location", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/location.svg`));
    this.matIconRegistry.addSvgIcon("map", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/map.svg`));
    this.matIconRegistry.addSvgIcon("more", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/more.svg`));
    this.matIconRegistry.addSvgIcon("pencil", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/pencil.svg`));
    this.matIconRegistry.addSvgIcon("profile", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/profile.svg`));
    this.matIconRegistry.addSvgIcon("question", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/question.svg`));
    this.matIconRegistry.addSvgIcon("select-by-extent", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/select_by_extent.svg`));
    this.matIconRegistry.addSvgIcon("select-by-polygon", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/select_by_polygon.svg`));
    this.matIconRegistry.addSvgIcon("select", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/select.svg`));
    this.matIconRegistry.addSvgIcon("street-view", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/street_view.svg`));
    this.matIconRegistry.addSvgIcon("table-view-bottom", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/table_view_bottom.svg`));
    this.matIconRegistry.addSvgIcon("table-view-bottom-2", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/table_view_bottom_2.svg`));
    this.matIconRegistry.addSvgIcon("table-view-right", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/table_view_right.svg`));
    this.matIconRegistry.addSvgIcon("zoom-in", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/zoom_in.svg`));
    this.matIconRegistry.addSvgIcon("zoom-in-2", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/zoom_in_2.svg`));
    this.matIconRegistry.addSvgIcon("zoom-out", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/zoom_out.svg`));
    this.matIconRegistry.addSvgIcon("zoom-out-2", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/zoom_out_2.svg`));
    this.matIconRegistry.addSvgIcon("data-editor", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/data_editor.svg`));
    this.matIconRegistry.addSvgIcon("redline", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/redline.svg`));
    this.matIconRegistry.addSvgIcon("measure", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/measure.svg`));
    this.matIconRegistry.addSvgIcon("screen-shot", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/screen_shot.svg`));
    this.matIconRegistry.addSvgIcon("layers", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/layers.svg`));
    this.matIconRegistry.addSvgIcon("search", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/search.svg`));
    
    this.matIconRegistry.addSvgIcon("picture_in_picture_alt", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/picture_in_picture_alt.svg`));
    this.matIconRegistry.addSvgIcon("location_searching", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/location_searching.svg`));
    this.matIconRegistry.addSvgIcon("home_pin", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/home_pin.svg`));
    this.matIconRegistry.addSvgIcon("find_in_page", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/main-nav/find_in_page.svg`));
    
    //SUB NAVIGATION
    this.matIconRegistry.addSvgIcon("arrow-left", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/arrow_left.svg`));
    this.matIconRegistry.addSvgIcon("arrow-right", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/arrow_right.svg`));
    this.matIconRegistry.addSvgIcon("ff-left", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/fast_forward_left.svg`));
    this.matIconRegistry.addSvgIcon("ff-right", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/fast_forward_right.svg`));
    this.matIconRegistry.addSvgIcon("arrow-down", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/arrow_down.svg`));
    this.matIconRegistry.addSvgIcon("arrow-up", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/arrow_up.svg`));
    this.matIconRegistry.addSvgIcon("cross", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/cross.svg`));
    this.matIconRegistry.addSvgIcon("add-query", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/add_query.svg`));
    this.matIconRegistry.addSvgIcon("plus", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/plus.svg`));
    this.matIconRegistry.addSvgIcon("arrow-up-down", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/arrow_up_and_down.svg`));
    this.matIconRegistry.addSvgIcon("edit", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/pencil.svg`));
    this.matIconRegistry.addSvgIcon("check", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/check.svg`));
    this.matIconRegistry.addSvgIcon("delete-nav", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/sub-nav/delete.svg`));
    //ACTIONS
    this.matIconRegistry.addSvgIcon("save", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/save.svg`));
    this.matIconRegistry.addSvgIcon("undo", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/undo.svg`));
    this.matIconRegistry.addSvgIcon("save-draft", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/save_draft.svg`));
    this.matIconRegistry.addSvgIcon("lock", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/lock.svg`));
    this.matIconRegistry.addSvgIcon("unlock", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/unlock.svg`));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/delete.svg`));
    this.matIconRegistry.addSvgIcon("report", this.domSanitizer.bypassSecurityTrustResourceUrl(`../${environment.iconroot}assets/icon/actions/report.svg`));
  }
  mapLoadedEvent(status: boolean) {
    console.log("The map loaded: " + status);
  }
}
