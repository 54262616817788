/* eslint-disable camelcase */
// eslint need to fix Modifier_Column
import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { environment } from "src/environments/environment";
@Component({
  selector: "sms-MMMBaseLifeModifier",
  templateUrl: "./BaseLifeModifier.component.html",
  styleUrls: ["../../administrator.component.scss"]
})
export class MMMBaseLifeModifier implements OnInit {
  AssetClassList = [];
  assetClassListSelected;
  BaseLifeFieldValue;
  BaseLifeField = [];
  SubmitButton = false;
  baseLifeFieldSelected;
  constructor(private adminService: MMMAdminService, public globalvariable: adminGlobal) {}

  ngOnInit() {
    const Arrayvalues = this.globalvariable.AssetClassList;
    this.AssetClassList = [];
    this.globalvariable.ExistBaseLife.forEach((data) => {
      const val = Arrayvalues.filter((obj) => {
        return obj.gislayername.toLowerCase() === data.AssetClass.toLowerCase();
      });
      this.AssetClassList.push(val[0]);
    });
  }

  onChange() {
    try {
      this.BaseLifeField = [];
      const val = this.globalvariable.ExistBaseLife.filter(
        function (obj) {
          return obj.AssetClass.toLowerCase() === this.assetClassListSelected.gislayername.toLowerCase();
        }.bind(this)
      );
      this.BaseLifeFieldValue = val[0].BLF_Column;
      this.adminService
        .getDomainValuesFields(environment.proxy + this.globalvariable.agencyDetails[0].webServiceUrl, this.assetClassListSelected.gisId)
        .subscribe(
          (res: any) => {
            res.fields.forEach((data) => {
              if (data.domain !== null) {
                if (this.BaseLifeFieldValue !== data.alias) {
                  this.BaseLifeField.push({
                    Modifier_Column: data.alias,
                    Modifier_Domain: data.domain.name,
                    CodedValues: data.domain.codedValues
                  });
                }
              }

            if (data.alias === this.BaseLifeFieldValue) {
              this.globalvariable.ExistBaseLifeModify.push({
                AssetClass: this.assetClassListSelected.gislayername,
                Modifier_Column: data.alias,
                Modifier_Domain: data.domain.name,
                CodedValues: data.domain.codedValues
              });
            }
          });
          this.globalvariable.ExistBaselifeModifier.forEach((data) => {
            this.BaseLifeField = this.BaseLifeField.filter((obj) => {
              return obj.Modifier_Column.toLowerCase() !== data.Modifier_Column.toLowerCase();
            });
          });
          this.globalvariable.NewBaselifeModifier.forEach((data) => {
            this.BaseLifeField = this.BaseLifeField.filter((obj) => {
              return obj.Modifier_Column.toLowerCase() !== data.Modifier_Column.toLowerCase();
            });
          });
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  BaseLifeModifier() {
    this.SubmitButton = true;
  }

  OnSubmit() {
    try {
      this.globalvariable.ExistBaselifeModifier.push({
        AssetClass: this.assetClassListSelected.gislayername,
        Modifier_Column: this.baseLifeFieldSelected.Modifier_Column,
        Modifier_Domain: this.baseLifeFieldSelected.Modifier_Domain
      });
      this.globalvariable.NewBaselifeModifier.push({
        AssetClass: this.assetClassListSelected.gislayername,
        Modifier_Column: this.baseLifeFieldSelected.Modifier_Column,
        Modifier_Domain: this.baseLifeFieldSelected.Modifier_Domain,
        CodedValues: this.baseLifeFieldSelected.CodedValues
      });
      this.SubmitButton = false;
      this.onChange();
      this.clear();
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    try {
      this.BaseLifeFieldValue = null;
      this.assetClassListSelected = undefined;
      this.baseLifeFieldSelected = undefined;
    } catch (error) {
      console.error(error);
    }
  }
}
