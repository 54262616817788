import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject, observable, timer } from 'rxjs';
import { filter, map, takeUntil, takeWhile } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html'
})
export class IdleModalComponent implements OnInit, OnDestroy {

  constructor(public modal: NgbActiveModal, public auth: AuthenticationService) { }

  private readonly destroy$ = new Subject();

  public secondsBeforelogout: number;

  timeRemaining$: Observable<number>

  ngOnInit(): void {
    this.timeRemaining$ = timer(0, 1000).pipe(
      map(secondsElapsed => this.secondsBeforelogout - secondsElapsed),
      takeWhile(secondsLeft => secondsLeft >= 0)
    );

    this.timeRemaining$.pipe(filter(sec => sec === 0), takeUntil(this.destroy$)).subscribe(() => {
      this.logout();
    })
  }

  logout() {
    const currentUser = this.auth.currentUserValue;
    this.auth.logout(currentUser.clientId, currentUser.userId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
