<div class="card mt-2">
    <div class="card-body admin-model-height formbg-d2">
        <sms-headAdmin></sms-headAdmin>
        <div *ngIf="ShowBase">
            <ul ngbNav #lifeNav="ngbNav" class="nav-pills" [(activeId)]="activeTab">
                <li style="max-width: 140px;" [ngbNavItem]="1" title="Base Life">
                    <a style="max-width: 140px;" ngbNavLink>Base Life</a>
                    <ng-template ngbNavContent>
                        <sms-MMMBaseLife></sms-MMMBaseLife>
                    </ng-template>
                </li>
                <li style="max-width: 140px;" [ngbNavItem]="2" title="Base Life Modifier">
                    <a style="max-width: 140px;" ngbNavLink>Base Life Modifier</a>
                    <ng-template ngbNavContent>
                        <sms-MMMBaseLifeModifier></sms-MMMBaseLifeModifier>
                    </ng-template>
                </li>
            </ul>
            
            <div [ngbNavOutlet]="lifeNav"></div>
        </div>
    </div>
</div>