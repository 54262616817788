export const LANG_EN_NAME = "us";

export const LANG_EN_TRANS = {
  "Login.logo.name": "Road Asset Manager",
  "Login.page.text1": "Safer, faster and lighter ways",
  "Login.page.text2": "to keep the world moving",
  "Login.loginbox.heading": "Login",
  "Login.loginbox.text": "Enter your credentials below to login to 3M Road Asset Manager",
  "Login.loginbox.userplaceholder": "Username",
  "Login.loginbox.passwordplaceholder": "Password",
  "Login.loginbox.button": "Login",
  "Login.loginvalidate.text1": "Your User Id and Password do not match",
  "Login.loginvalidate.text2": "Please enter valid credentials to Log In",
  "Login.loginvalidate.tryagain": "Try Again?",
  "Login.loginvalidate.forgotpassword": "Forgot password? Reset here.",
  "Login.loginpage.legaltext": "Legal",
  "Login.loginpage.privacytext": "Privacy",
  "Login.loginpage.rightstext": "All Rights Reserved",
  "Login.loginsuccess.welcome": "Welcome!",
  "Login.loginsuccess.msgtext": "You have logged in Successfully",
  "Login.loginsuccess.getstartedbtn": "GET STARTED",
  "Login.afterlogin.term&condition": "Terms & Conditions",
  "Login.afterlogin.acceptbtn": "Accept",
  "Login.newlogin.changepasstxt": "Change Password",
  "Login.newlogin.oldpasstxt": "Old Password",
  "Login.newlogin.oldpassdesc": "Please provide old password",
  "Login.newlogin.newpasstxt": "New Password",
  "Login.newlogin.newpassdesc": "Please provide New password",
  "Login.changepwdvalidate.newandold": "New password should be different from old password",
  "Login.changepwdvalidate.confrmpwd": "Confirm Password",
  "Login.changepwdvalidate.confrmpwddesc": "Please provide Confirm password",
  "Login.changepwdvalidate.confrmpwddesc2": "Confirm password should be same as new password",
  "Login.changepswd.changepswdbtn": "Change Password",
  "Login.alert.loginfail": "Login Failed..!!",
  "Login.alert.pswdchange": "Password Changed",
  "Login.alert.reloginconfirmation": "Press Ok to relogin",
  "User.Account.Inactive": "Your account is inactive. Please contact your agency's solution administrator.",
  "User.LockOut.Contact.SolutionAdmin": "Your account is locked. Please contact your agency's solution administrator to unlock it.",
  "User.Password.Expired": "Your password has expired, please create a new one.",
  "User.AttemptRemaining.1": "Invalid login attempt. ",
  "User.AttemptRemaining.2": " out of ",
  "User.AttemptRemaining.3": " attempts remaining.",
  "Login.alert.reloginmessage": "Click on Ok to relogin",
  "Login.alert.passwordsuccessmsg": "Password has been Changed Successfully",
  "Login.alertuseradmin.InvalidPassword": "Must have between %s and %s characters, including at least %s uppercase letter, %s lowercase letter, %s number and %s special character",
  "Login.changepswd.passUsernameSame":"Password cannot be same as User Id",
  "Login.changepswd.passNotValid":"Old password is not valid",
  "Login.changepswd.InvalidPasswordLast24":"New password cannot be a recently used password",
  "Login.loginbox.required":"Required",
  "Login.alert.username": "Please enter username",
  "Login.alert.password": "Please enter password"

};
