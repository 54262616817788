import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { environment } from "src/environments/environment";
@Component({
  selector: "sms-ExpectedLife",
  templateUrl: "./ExpectedLife.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class ExpectedLifeComponent implements OnInit {
  ShowBase = false;
  activeTab = 1;
  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {}

  ngOnInit() {
    this.activeTab = 1;
    if (this.adminGlobal.AssetClassList.length === 0) {
      this.CallLayers();
    } else {
      this.ShowBase = true;
    }
  }
  CallLayers() {
    try {
      if (this.adminGlobal.HideAsset.length === 0) {
        this.MMMAdminService.getLayerRecords(environment.proxy +
          this.adminGlobal.agencyDetails[0].webServiceUrl,
          this.adminGlobal.serviceLyrInfo.tableAliasId
        ).subscribe(
          (res: any) => {
            res.features.forEach((data) => {
              if (data.attributes.GroupType === "Parent Table") {
                this.adminGlobal.HideAsset.push(data.attributes);

                if (data.attributes.IsAuthorized === "T") {
                  this.adminGlobal.ShowAsset.push(data.attributes);
                }
              }
            });
          },
          (err) => {
            console.log(err);
          }
        );
      }

      this.MMMAdminService.getLayerRecords(environment.proxy +
        this.adminGlobal.agencyDetails[0].webServiceUrl,
        this.adminGlobal.serviceLyrInfo.tableAliasId
      ).subscribe(
        (res: any) => {
          res.features.forEach((data) => {
            if (data.attributes.GroupType === "Parent Table" || data.attributes.GroupType === "Child Table") {
              switch (data.attributes.Name) {
                case "Support":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.supportId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "CrashCushion":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.crashCushionId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "EndTreatment":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.endTreatmentId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "PavementMarking_Symbol":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.pavementMarkingSymbolId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "Barrier":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.barrierId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "Guardrail":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.guardrailId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "PavementMarking":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.pavementMarkingId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "AssetPolygon":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.assetPolygonId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "Light":
                  this.adminGlobal.AssetClassList.push({
                    gisId: this.adminGlobal.serviceLyrInfo.lightId,
                    gislayername: data.attributes.Name
                  });
                  break;
                case "Sign":
                  this.adminGlobal.AssetClassList.push({ gisId: this.adminGlobal.serviceLyrInfo.signId, gislayername: data.attributes.Name });
                  break;
              }
            }
          });
          this.ExistBaseLifeValues();
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  ExistBaseLifeValues() {
    try {
      this.MMMAdminService.getLayerRecords(environment.proxy +
        this.adminGlobal.agencyDetails[0].webServiceUrl,
        this.adminGlobal.serviceLyrInfo.baseLifeConfigId
      ).subscribe(
        (res: any) => {
          res.features.forEach((data) => {
            this.adminGlobal.ExistBaselifeConfig.push(data.attributes);
          });
          this.MMMAdminService.getLayerRecords(environment.proxy +
            this.adminGlobal.agencyDetails[0].webServiceUrl,
            this.adminGlobal.serviceLyrInfo.modifierConfigId
          ).subscribe(
            (mres: any) => {
              mres.features.forEach((data) => {
                this.adminGlobal.ExistBaselifeModifier.push(data.attributes);
              });
              this.ShowBase = true;
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
