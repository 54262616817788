<div *ngIf="showtask">
  <create-tasks
    (showTasks)="showTaksTab($event)"
    [Graphic]="Graphic"
    [delete]="deletetask"
    (taskDeleted)="taskDeleted($event)"
    [FeatureLayer]="FeatureLayer"
    [webMercatorUtils]="webMercatorUtils"
    [editCreateTaskForm]="editCreateTaskForm"
  >
  </create-tasks>
</div>

<div #table class="form-popup" id="crewForm">
  <div class="row">
    <div class="col-12">
      <form class="form-container">
        <div class="animated fadeIn">
          <div class="card">
            <app-heading-component aria-label="Close" [headingText]="'Crewmanagement.crew.header' | translate" [variant]="'none'" [iconOne]="'cross'" (actionOne)="close()" data-dismiss="modal" [tooltipOne]="'Close'">
            </app-heading-component>

            <div class="card-body">
              <mat-tab-group mat-stretch-tabs disablePagination="true" (selectedTabChange)="onTabChange($event)">
                <mat-tab [label]="'Crewmanagement.crew.assignmenttab' | translate" title="{{ 'Crewmanagement.crew.assignmenttab' | translate }}">
                  <app-sms-assignment [events]="eventsSubject.asObservable()" (crewTasksData)="loadFeaturesOnMap($event)"></app-sms-assignment>
                </mat-tab>
                <mat-tab [label]="'Crewmanagement.crew.schedulertab' | translate" title="{{ 'Crewmanagement.crew.schedulertab' | translate }}">
                  <app-sms-sheduler [events]="eventsSubject.asObservable()" (crewTasksData)="loadFeaturesOnMap($event)"></app-sms-sheduler>
                </mat-tab>
                <mat-tab [label]="'Crewmanagement.crew.optimizertab' | translate" title="{{ 'Crewmanagement.crew.optimizertab' | translate }}">
                  <app-sms-optimizer
                    [FeatureLayer]="FeatureLayer"
                    [Graphic]="Graphic"
                    [events]="eventsSubject.asObservable()"
                    (showTask)="showTaksTab($event)"
                    (crewTasksData)="loadFeaturesOnMap($event)"
                  ></app-sms-optimizer>
                </mat-tab>
              </mat-tab-group>
              <div class="d-flex justify-content-end align-items-center">
                <button *ngIf="showroutebtn" mat-button class="h7 primary-button crewButton" (click)="findRoute()">{{ "Crewmanagement.crew.routeoptimizationbtn" | translate }}</button>
                <button *ngIf="!showroutebtn" class="h7 primary-button crewButton" mat-button (click)="enableDraw()">{{ "Crewmanagement.crew.selecttasksbtn" | translate }}</button>

                <button *ngIf="showroutebtn && routecomplete" class="primary-button h7 crewButton" (click)="updateSequence()">{{ "Crewmanagement.crew.updatetasksbtn" | translate }}</button>
              </div>
              <div class="row">
                <div class="col-12">
                  <div #mapViewNode class="map-node mapHeight">
                    <div [hidden]="!viewmicroform" class="micro-form-popup p-3" id="frmCreateTask" style="display: block; position: absolute; height: 250px; z-index: 1000">
                      <form class="micro-form-container">
                        <div class="form-row">
                          <div class="form-group col">
                            <label for="wrkworker">{{ "Crewmanagement.crew.workerlabel" | translate }}:</label>
                            <!-- <input [(ngModel)]="worker" type="text" class="form-control" name="wrkworker"> -->
                            <select class="form-control" [(ngModel)]="worker" (ngModelChange)="workerChanged()" name="wrkworker">
                              <!-- <option  value="Clean">Clean</option>
                                                                    <option  value="Reinstall">Reinstall</option> -->
                              <option *ngFor="let opt of clientUsersList; let i = index" [value]="opt.username">{{ opt.username }}</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col">
                            <label for="wrkpriority">{{ "Crewmanagement.crew.prioritylabel" | translate }}:</label>
                            <select class="form-control" [(ngModel)]="priority" aria-label="select priority" name="wrkpriority">
                              <option value="0" selected>
                                {{ "Crewmanagement.crew.low" | translate }}
                              </option>
                              <option value="1">{{ "Crewmanagement.crew.medium" | translate }}</option>
                              <option value="2">{{ "Crewmanagement.crew.high" | translate }}</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col">
                            <label for="wrkshedule">{{ "Crewmanagement.crew.scheduledlabel" | translate }}</label>
                            <div class="form-group d-flex">
                              <mat-form-field appearance="outline">
                                <mat-label>Date Time</mat-label>
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  placeholder="Date Time"
                                  [min]="todayDate"
                                  [(ngModel)]="scheduled"
                                  class="form-control"
                                  (ngModelChange)="dateTimeChanged()"
                                  name="wrkshedule"
                                  autocomplete="off"
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>

                        <div class="form-row" style="float: right">
                          <button type="button" class="btn btn-secondary mr-2" (click)="closeMicroform()">{{ "Crewmanagement.crew.cancelbtn" | translate }}</button>
                          <button type="button" class="btn btn-primary" (click)="saveTask()">{{ "Crewmanagement.crew.savebtn" | translate }}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
