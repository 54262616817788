<div class="sms-model-height">
  <div class="card">
    <div class="card-body" style="display: flex; flex-direction: column">
      <div class="selection-container" style="display: flex; flex-direction: column; width: 320px; align-self: center">
        <mat-form-field>
          <mat-label>{{ RegionLabel | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedRegion" id="region-select" name="region-select" (ngModelChange)="showLayers()">
            <mat-option [value]="region" *ngFor="let region of regionList">
              {{ region.regionName }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ AssetClassLabel | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedLayer" id="layer-select" name="layer-select" (ngModelChange)="ShowActivities()">
            <mat-option [value]="lyr" *ngFor="let lyr of layerList">
              {{ "Userscreens.assetattributedefalt." + lyr.Layer | translate }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "Systemreports.headerassetdetail.activity" | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedActivity" id="activity-select" name="activity-select" (ngModelChange)="ShowReplacedOrRetiresAssets()">
            <mat-option [value]="activity.code" *ngFor="let activity of activityLyrFltDomines">
              {{ activity.code }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
      </div>
   
      <div class="form-row" *ngIf="rollbackData">
        <table tabindex="0" class="table table-hover table-bordered" aria-label="Table for Activity Rollback">
          <thead>
            <tr>
              <th scope="col" tabindex="0" [attr.aria-label]="'' + 'AssetId'">AssetId</th>
              <th scope="col" tabindex="0" [attr.aria-label]="'' + 'Retired Date'" *ngIf="selectedActivity =='Retire'">Retired Date</th>
              <th scope="col" tabindex="0" [attr.aria-label]="'' + 'Replaced Date'" *ngIf="selectedActivity =='Replace'">Replaced Date</th>
              <th scope="col" tabindex="0" [attr.aria-label]="'' + 'RollBack'">RollBack</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tblRollback of rollbackData; let k = index">
              <td tabindex="0">
                {{ tblRollback.assetID }}
              </td>
              <td tabindex="0">
                {{ tblRollback.retireDate | date : "medium" }}
              </td>
              <td align="center" [attr.aria-label]="'Rollback asset ' + tblRollback.assetID">
                <a tabindex="0" id="{{ 'rollBackLink_' + k }}" class="RemoveBtnColor" (click)="RollBackData(tblRollback)">RollBack</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
