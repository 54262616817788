<div class="modal-header">
    <h4 class="modal-title">Your Session is About to Expire!</h4>
</div>
<div class="modal-body">
    Your session is about to expire.
    <br>
    Logging out of RSAM in {{timeRemaining$ | async}} seconds.
</div>
<div class="modal-footer">
    <button type="button" class="btn primary-button" (click)="modal.close()">Stay Connected</button>
</div>