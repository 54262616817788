/* eslint-disable camelcase */
// camel case for layer id
import { Injectable } from "@angular/core";
import { GenericLayerService } from "./genericLayer.service";
import Swal from "sweetalert2";
import { AuthenticationService } from "../services/authentication.service";
import { environment } from "src/environments/environment";
import { QueryLayers } from "./queryLayers.service";
import { QueryBuilderConstants } from "../querybuilder/queryBuilderConstants";
import { SystemReportsStyle } from "../systemreports/system.reports.style";
import { TranslateService } from "@ngx-translate/core";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@Injectable()
export class QueryBuilder {
  public currentUser: any;
  public selectedDate: any;
  public map: any;
  public Query: any;
  public QueryTask: any;
  public RelationshipQuery: any;
  public inventoryLayersData: any;
  public FeatureLayer: any;
  public aliasData: any[] = [];
  public assetCode: any;
  constructor(
    private genericLayer: GenericLayerService,
    public authService: AuthenticationService,
    public queryLayers: QueryLayers,
    private constants: QueryBuilderConstants,
    private reportStyle: SystemReportsStyle,
    private translateService: TranslateService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }
  //  *****PREDEFIEND QUERIES */
  // Populate Predefined Queries */
  public getPredefinedQueries(userActionsQueryLayerObj, Query, QueryTask) {
    return new Promise((resolve) => {
      const query = new Query();
      const queryTask = new QueryTask(userActionsQueryLayerObj.url);
      query.where = "QueryTool = 'PQ-A'";
      query.outFields = ["QueryID", "Query", "QueryDescription"];
      queryTask.execute(query).then((result) => {
        resolve(result);
      });
    });
  }
  // Predefined Queries 10 and 11 */
  public executeMultiplePrimaryLayers(event, regionData, query, layerlist, Query, FeatureLayer, map, SimpleMarkerSymbol, SimpleLineSymbol, SimpleFillSymbol, Color, Graphic) {
    const selectbehaviorobj = FeatureLayer.SELECTION_NEW;
    const allResults = [];
    const queryString = query.queryString;
    const layers = queryString.split("?")[0];
    const conditions = queryString.split("?")[1];
    const query1 = layers.split(":")[0] + "?" + regionData + ";" + conditions.split(";")[0];
    const query2 = layers.split(":")[1] + "?" + regionData + ";" + conditions.split(";")[1];
    const query3 = layers.split(":")[2] + "?" + regionData + ";" + conditions.split(";")[2];
    const query1Obj = [];
    const query2Obj = [];
    const query3Obj = [];
    const layerQueryObj1 = layerlist.filter((layer) => layer.Layer === layers.split(":")[0]);
    const layerQueryObj2 = layerlist.filter((layer) => layer.Layer === layers.split(":")[1]);
    const layerQueryObj3 = layerlist.filter((layer) => layer.Layer === layers.split(":")[2]);
    const functionname = "test";
    return new Promise((resolve) => {
      this.genericLayer.getQueryResults(query1, functionname).subscribe((results: any) => {
        if (results && results.table && results.table.length > 0) {
          results.table.forEach((v) => {
            query1Obj.push(v.objectid);
          });
        }

        allResults.push({ layerName: layerQueryObj1, objectid: query1Obj });
      });
      this.genericLayer.getQueryResults(query2, functionname).subscribe((results: any) => {
        if (results && results.table && results.table.length > 0) {
          results.table.forEach((v) => {
            query2Obj.push(v.objectid);
          });
        }

        allResults.push({ layerName: layerQueryObj2, objectid: query2Obj });
      });

      this.genericLayer.getQueryResults(query3, functionname).subscribe((results: any) => {
        if (results && results.table && results.table.length > 0) {
          results.table.forEach((v) => {
            query3Obj.push(v.objectid);
          });
        }

        allResults.push({ layerName: layerQueryObj3, objectid: query3Obj });
        const querySelect = new Query();
        querySelect.returnGeometry = true;
        const allData = [];
        const lthis = this.setThis();

        if (query1Obj.length === 0 && query3Obj.length === 0 && query2Obj.length === 0) {
          Swal.fire("No results Found");
          return;
        }

        allResults.forEach((layer) => {
          if (layer.objectid.length > 0) {
            querySelect.objectIds = layer.objectid;
            layer.layerName[0].layer_id.lyrObj.selectFeatures(
              querySelect,
              selectbehaviorobj,
              (res: any) => {
                if (res.length === 0) {
                  return;
                }

                allData.push({
                  Data: res,
                  layerObj: layer.layerName[0].layer_id.lyrObj,
                  layerData: layer.layerName[0].layer_id
                });

                if (allData.length === allResults.length) {
                  lthis.showDataOnMapMultiple(allData, FeatureLayer, map, SimpleMarkerSymbol, SimpleLineSymbol, SimpleFillSymbol, Color, Graphic).then((result) => {
                    resolve(result);
                  });
                }
              },
              (error) => {
                console.log(error);
                return;
              }
            );
          }
        });
      });
    });
  }
  public showDataOnMapMultiple(allResults, FeatureLayer, map, SimpleMarkerSymbol, SimpleLineSymbol, SimpleFillSymbol, Color, Graphic) {
    return new Promise((resolve) => {
      const selectionGraphicsLyr = map.getLayer("sms-selection");
      selectionGraphicsLyr.clear();
      let symbol;
      const queryDataObj = [];
      allResults.forEach((result) => {
        if (result.layerObj.geometryType === "esriGeometryPoint") {
          symbol = new SimpleMarkerSymbol(SimpleMarkerSymbol.STYLE_CIRCLE, 12, new SimpleLineSymbol(SimpleLineSymbol.STYLE_NULL, new Color([247, 34, 101, 0.9]), 1), new Color([0, 255, 255, 9]));
        } else if (result.layerObj.geometryType === "esriGeometryPolyline") {
          symbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color([0, 255, 255, 9]), 3);
        } else if (result.layerObj.geometryType === "esriGeometryPolygon") {
          symbol = new SimpleFillSymbol(SimpleFillSymbol.STYLE_SOLID, new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color([247, 34, 101]), 2), new Color([247, 34, 101, 0.5]));
        }

        result.layerObj._graphicsVal.forEach((element) => {
          const graphicB = new Graphic({
            // graphic with point geometry
            geometry: element.geometry // set geometry here
          });
          graphicB.setSymbol(symbol);
          selectionGraphicsLyr.add(graphicB);
        });
        map.addLayer(selectionGraphicsLyr);
        queryDataObj.push({ LayerName: result.layerData, Data: result.Data });
      });
      resolve(queryDataObj);
    });
  }
  // END */

  // QUERYBUILDER */
  /*Save Load and Delete Functionality*/
  public saveQuery(fileName, FeatureLayer, queryString, Graphic, isCreateQuery, queryDescription) {
    return new Promise((resolve) => {
      const userActionsQueryLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new FeatureLayer(userActionsQueryLayer);
      const queryData = new Graphic();
      queryData.geometry = null;
      queryData.setAttributes({
        UserID: this.currentUser.userId,
        RegionLevel: this.currentUser.selectedRegion.regionLevel,
        QueryID: "123",
        RegionName: this.currentUser.selectedRegion.regionName,
        QueryName: fileName,
        Query: queryString,
        CreatedOn: new Date().getDate(),
        UpdatedOn: new Date().getDate(),
        QueryDescription: queryDescription,
        QueryTool: isCreateQuery
      });
      this.queryLayers.addAsset(userActionsQueryLayerObj, queryData).then((result) => {
        if (result) {
          const queryId = "QRY_" + result[0].objectId;
          queryData.setAttributes({
            OBJECTID: result[0].objectId,
            QueryID: queryId
          });
          this.queryLayers.updateAsset(userActionsQueryLayerObj, queryData).then((upresult: any) => {
            resolve(upresult);
          });
        }
      });
    });
  }
  public getSavedQueryWithoutUser(userActionsQueryLayerObj, Query, QueryTask, queryTool) {
    let where = "";

    if (queryTool === "PQ-A" || queryTool === "PQ") {
      where = "(QueryTool = 'PQ-A' or QueryTool = 'PQ')";
    } else {
      where = "QueryTool = '" + queryTool + "'";
    }

    return new Promise((resolve) => {
      const query = new Query();
      const queryTask = new QueryTask(userActionsQueryLayerObj.url);
      query.where = where;
      query.outFields = ["Query", "QueryName", "QueryDescription", "QueryTool"];
      queryTask.execute(query).then((result) => {
        resolve(result);
      });
    });
  }
  public getSavedQuery(userActionsQueryLayerObj, currentUser, Query, QueryTask, queryTool) {
    let where = "";

    if (queryTool === "PQ-A" || queryTool === "PQ") {
      where = "(QueryTool = 'PQ-A' or QueryTool = 'PQ')";
    } else {
      where = "QueryTool = '" + queryTool + "'";
    }

    return new Promise((resolve) => {
      const query = new Query();
      const queryTask = new QueryTask(userActionsQueryLayerObj.url);
      query.where = "UserId='" + currentUser.userId + "' and " + where;
      query.outFields = ["Query", "QueryName", "QueryDescription", "QueryTool"];
      queryTask.execute(query).then((result) => {
        resolve(result);
      });
    });
  }
  public deleteQuery(FeatureLayer, fileName, Query, QueryTask, queryTool) {
    const userActionsQueryLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.userQueryActionsId;
    const userActionsQueryLayerObj = new FeatureLayer(userActionsQueryLayer);
    let where = "";

    if (queryTool === "PQ-A" || queryTool === "PQ") {
      where = "(QueryTool = 'PQ-A' or QueryTool = 'PQ')";
    } else {
      where = "QueryTool = '" + queryTool + "'";
    }

    return new Promise(
      function (resolve) {
        const query = new Query();
        const queryTask = new QueryTask(userActionsQueryLayerObj.url);
        query.where = "QueryName='" + fileName + "' AND UserId='" + this.currentUser.userId + "' AND " + where;
        query.outFields = ["OBJECTID"];
        queryTask.execute(query).then((result) => {
          resolve(result);
        });
      }.bind(this)
    );
  }

  // Populate Dropdowns */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getWorkerData(workerField, Query, QueryTask, QueryLayer, currentuser) {
    return new Promise((resolve) => {
      console.log(10);
      this.genericLayer
        .getUserList(this.currentUser.clientId)
        // tslint:disable-next-line:only-arrow-functions
        .subscribe(
          function (result) {
            console.log(result);
            resolve(result);
          }.bind(this)
        );
    });
    // let regionData = "Region"+currentuser.selectedRegion.regionLevel+"='"+currentuser.selectedRegion.regionName;
    // return new Promise((resolve)=>{
    //     let query = new Query();
    //     let queryTask = new QueryTask(QueryLayer[0].layer_id.lyrObj.url);
    //     query.where = "1=1";
    //     query.outFields = [workerField];
    //     query.returnDistinctValues=true;
    //     query.returnGeometry=false;
    //     queryTask.execute(query,function(result){
    //         if(result){
    //             console.log(result);
    //             resolve(result);
    //         }
    //     })
    // })
  }
  public getDropDownValues() {
    const layers = [];
    const relatedTables: any[] = [];

    this.inventoryLayersData.forEach((lyr: any) => {
      if (lyr) {
        layers.push({ Layer: lyr.layerName, layer_id: lyr, tableAlias: lyr.tableAlias, tableAliasSL: lyr.tableSL });
        lyr.lyrObj.relationships.forEach((relation) => {
          const listValue = relation.name.split("To");
          const a = relatedTables.filter((relFil: any) => relFil.Value === listValue[1]);

          if (a.length === 0) {
            relatedTables.push({ Value: listValue[1], id: relation.relatedTableId });
          }
        });
      }
    });

    //add Activity -- related to Maintenance not the Asset Classes.
    relatedTables.push({ Value: "Activity", id: this.currentUser.serviceLyrInfo.activityId });

    relatedTables.forEach(
      function (relatedAsset) {
        // tslint:disable-next-line:no-shadowed-variable
        const baseurl = this.currentUser.webServiceURL + environment.featureServer;
        const lyrObj = { lyrObj: new this.FeatureLayer(baseurl + relatedAsset.id, { outFields: ["*"] }) };
        //const tablesl = { tableSL: new this.FeatureLayer(baseurl + this.currentUser.serviceLyrInfo.tableAliasId, { outFields: ["*"] }) };
        const aliasDataD: any = this.aliasData.filter((dt) => dt.attributes.Name === relatedAsset.Value)[0];

        // 20-01-2021 Manikantha Start 24791
        if (
          aliasDataD &&
          aliasDataD.attributes &&
          (this.currentUser.layerLevelInfo.filter((item) => {
            if (item.gisLayerId == relatedAsset.id) {
              return item;
            }
          }).length > 0 ||
            ["Maintenance", "Assessment", "Activity"].includes(relatedAsset.Value)) // 03-01-21 jcc - also include queryable non-asset tables
        ) {
          // 20-01-2021 Manikantha End 24791
          if (relatedAsset.Value == "Sign") {
            layers.unshift({
              Layer: relatedAsset.Value,
              layer_id: lyrObj, // 03-02-21 jcc - put Signs first in list
              tableAlias: aliasDataD.attributes.Alias,
              tableAliasSL: aliasDataD.attributes.Alias_SL
            });
          } else {
            layers.push({
              Layer: relatedAsset.Value,
              layer_id: lyrObj,
              tableAlias: aliasDataD.attributes.Alias,
              tableAliasSL: aliasDataD.attributes.Alias_SL
            });
          }
        }
      }.bind(this)
    );

    return layers;
  }
  public getActivityDomains(activityLayer) {
    let domains;
    const uniqueActivity = [];
    const uniqueActivityDomains = [];
    return new Promise((resolve) => {
      // tslint:disable-next-line:only-arrow-functions
      activityLayer.on(
        "Load",
        function (evt) {
          domains = evt.layer.types;
          domains.forEach((allDomains) => {
            allDomains.domains.Activity.codedValues.forEach((val) => {
              if (!uniqueActivity.includes(val.name)) {
                uniqueActivity.push(val.name);
                uniqueActivityDomains.push(val);
              }
            });
            resolve(uniqueActivityDomains);
          });
        }.bind(this)
      );
    });
  }
  public getsldata(Query, QueryTask, FeatureLayer) {
    return new Promise((resolve) => {
      const baseurl = this.currentUser.webServiceURL + environment.featureServer;
      const query = new Query();
      const queryTask = new QueryTask(new FeatureLayer(baseurl + this.currentUser.serviceLyrInfo.tableAliasId, { outFields: ["*"] }).url);
      query.where = "1=1";
      query.outFields = ["Alias", "Alias_SL", "Name"];
      query.returnDistinctValues = true;
      query.returnGeometry = false;
      queryTask.execute(query, (result) => {
        if (result) {
          console.log(result);
          resolve(result);
        }
      });
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getDropDownValuesLayer(layer, layerData, FeatureLayer) {
    const layers = [];
    let dropdownValues = [];
    const selectedLayer = layer == undefined ? this.inventoryLayersData[0] : this.inventoryLayersData.filter((lyr) => lyr.layerName === layer)[0];
    layers.push({
      Layer: selectedLayer.layerName,
      layer_id: selectedLayer.lyrObj,
      tableAlias: selectedLayer.tableAlias,
      tableAliasSL: selectedLayer.tableAliasSL
    });
    const baseurl = selectedLayer.lyrObj.url.substr(0, selectedLayer.lyrObj.url.lastIndexOf("/"));
    const lthis = this.setThis();
    selectedLayer.lyrObj.relationships.forEach(
      function (relation) {
        const lyrObj = { lyrObj: new lthis.FeatureLayer(baseurl + "/" + relation.relatedTableId, { outFields: ["*"] }) };
        const relatedAsset = relation.name.split("To");

        if (!relatedAsset.includes("Documents") && !relatedAsset.includes("Photo")) {
          const aliasDataf = this.aliasData.filter((dt) => dt.attributes.Name === relatedAsset[1])[0];
          layers.push({
            Layer: relatedAsset[1],
            layer_id: lyrObj,
            tableAlias: aliasDataf.attributes.Alias,
            tableAliasSL: aliasDataf.attributes.Alias_SL
          });
        }
      }.bind(this)
    );
    // Add activity layer
    const activityLayerUrl = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
    const activityLayer = new this.FeatureLayer(activityLayerUrl);
    const aliasData = this.aliasData.filter((dt) => dt.attributes.Name === "Activity")[0];
    layers.push({
      Layer: "Activity",
      layer_id: { lyrObj: activityLayer },
      tableAlias: aliasData.attributes.Alias,
      tableAliasSL: aliasData.attributes.Alias_SL
    });
    dropdownValues = layers;
    return dropdownValues;
  }
  public disableLayerDropdown(layerListRelated, layer) {
    const layerNames = [];
    layerListRelated.forEach((lyr) => {
      if (layer === "Sign" || layer === "Light") {
        if (lyr.Layer !== "Support") {
          layerNames.push(lyr.Layer);
        }
      } else if (layer === "Maintenance" || layer === "Assessment" || layer === "Activity") {
        if (lyr.Layer === "Maintenance" || lyr.Layer === "Assessment" || lyr.Layer === "Activity") {
          layerNames.push(lyr.Layer);
        }
      } else {
        layerNames.push(lyr.Layer);
      }
    });
    const op = document.getElementById("query_layers").getElementsByTagName("option");

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < op.length; i++) {
      op[i].disabled = false;

      if (!layerNames.includes(op[i].value)) {
        op[i].disabled = true;
      }
    }
  }
  setThis() {
    return this;
  }
  // Create Query String */
  public createQueryString(queryTableArr, currentUser) {
    //console.log(queryTableArr); //Venkat
    let layers = "";
    const region = "Region" + currentUser.selectedRegion.regionLevel + "='" + currentUser.selectedRegion.regionName + "'";
    let clause = "";
    const lthis = this.setThis();
    queryTableArr.forEach((query) => {
      // Layers String
      if (!layers.includes(query.layer)) {
        layers += query.layer + ":";
      }

      // Jointype
      if (query.jointype) {
        clause += query.jointype + " ";
      }

      // Field
      const prefix = lthis.constants.getQueryPrefix(query.layer);
      clause += prefix + query.field + " ";
      // Operator
      clause += query.operator + " ";

      // Value
      const type = parseInt(query.value, 10);

      if (query.field.includes("Date")) {
        // TSDRSAM-1634      '-' format not recognized as date on iOS
        const currentInputDate = new Date(query.value.replace(/-/g, "/"));

        if (currentInputDate instanceof Date) {
          // TSDRSAM-1634    dates stored in yyyy-mm-dd format.  need to match for LIKE query.
          const date = [String(currentInputDate.getFullYear()), String(currentInputDate.getMonth() + 1), String(currentInputDate.getDate())];
          date[1] = parseInt(date[1]) < 10 ? "0" + date[1] : date[1];
          date[2] = parseInt(date[2]) < 10 ? "0" + date[2] : date[2];

          if (query.operator === "LIKE") {
            clause += "'%" + date.join("-") + "%';";
          } else {
            clause += "'" + date.join("-") + "';";
          }
        } else {
          if (query.operator === "LIKE") {
            clause += "'%" + query.value + "%';";
          } else {
            clause += "'" + query.value + "';";
          }
        }
      } else if (query.field === "AssetCode") {
        let code;

        if (query.assetCode_type && query.assetCode_type.length > 0) {
          //jcc add length check to support LIKE query on AssetCode

          if (query.assetCode_type.filter((f) => f.code === query.value) && query.assetCode_type.filter((f) => f.code === query.value)[0] && query.assetCode_type.filter((f) => f.code === query.value)[0].code) {
            code = query.assetCode_type.filter((f) => f.code === query.value)[0].code;
          } else {
            code = query.value;
          }
        } else {
          code = query.value;
        }

        if (query.operator === "LIKE") {
          clause += "'%" + code + "%';";
        } else {
          clause += "'" + code + "';";
        }
      } else if (query.field === "AssetSubType") {
        let code;

        if (this.assetCode) {
          code = this.assetCode.filter((f) => f.code === parseInt(query.value))[0].code;
        } else {
          code = query.value;
        }

        if (query.operator === "LIKE") {
          clause += "'%" + code + "%';";
        } else {
          clause += "'" + code + "';";
        }
      } else if (isNaN(type)) {
        if (query.operator === "LIKE") {
          clause += "'%" + query.value + "%';";
        } else {
          clause += "'" + query.value + "';";
        }
      } else {
        if (query.operator === "LIKE") {
          clause += "'%" + query.value + "%';";
        } else {
          clause += " " + query.value + ";";
        }
      }
    });

    return layers.substring(0, layers.length - 1) + "?" + region + " AND;" + clause.substring(0, clause.length - 1);
  }
  // Load Query from Local System*/
  public loadTextAsQuery(event) {
    const queryStringLoaded = [];
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsText(file);
    return new Promise((resolve) => {
      fileReader.onload = () => {
        console.log(fileReader.result);

        if (fileReader.result !== undefined) {
          queryStringLoaded.push({ queryString: fileReader.result });
          resolve(queryStringLoaded);
        }
      };
    });
  }
  // Save Query to Local System*/
  public saveTextAsFile(data, filename) {
    if (!data) {
      console.error("Console.save: No data");
      return;
    }

    if (!filename) {
      filename = "console.json";
    }

    // tslint:disable-next-line:one-variable-per-declaration
    const blob = new Blob([data], { type: "text/plain" }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e = document.createEvent("MouseEvents"),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      a = document.createElement("a");
    const navigator: any = window.navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/plain", a.download, a.href].join(":");
      e.initEvent("click", true, false);
      a.dispatchEvent(e);
    }
  }
  // END */

  // REPORTS */
  getReportValues(queryDataObj) {
    let reportValues = [];
    const returnReportValues = [];

    if (queryDataObj.length === 1) {
      const layer = queryDataObj[0].LayerName.layerName;
      reportValues = this.getDropDownValuesLayer(layer, this.inventoryLayersData, this.FeatureLayer);
      reportValues.forEach((report) => {
        if (report.Layer !== "Activity") {
          returnReportValues.push(report.Layer + " Inventory Report");
        }
      });
    } else {
      queryDataObj.forEach((val) => {
        reportValues = [];
        const layer = val.LayerName.layerName;
        reportValues.push(this.getDropDownValuesLayer(layer, this.inventoryLayersData, this.FeatureLayer));
        reportValues[0].forEach((report) => {
          if (report.Layer !== "Activity") {
            // jcc 9/11/20 - include Maintenance and Assessment export report options for multi-asset queries too.  If don't check if already added, will be duplicated in dropdown.
            if (!returnReportValues.includes(report.Layer + " Inventory Report")) {
              returnReportValues.push(report.Layer + " Inventory Report");
            }
          }
        });
      });
    }

    console.log(reportValues);

    return returnReportValues;
  }
  createTableBody(data) {
    const tablebody = [];

    if (!data) {
      Swal.fire("Records not Available");
      return;
    }

    if (data && data.length <= 0) {
      Swal.fire("Records not Available");
      return;
    }

    const allHeaders = Object.keys(data[0]);
    const tableHeaders = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allHeaders.length; i++) {
      if (allHeaders[i].includes("_")) {
        const a = allHeaders[i].split("_");
        const temp = [];

        // tslint:disable-next-line:prefer-for-of
        for (let k = 0; k < a.length; k++) {
          temp.push(this.capitalize(a[k]));
        }
        tableHeaders.push({ text: this.translateService.instant("Querybuilder.reportheader." + allHeaders[i]), style: "tableHeader", alignment: "left" });
      } else {
        if (allHeaders[i] == "shapeLength") {
          allHeaders[i] = allHeaders[i].replace("shapeLength", "length");
        }
        tableHeaders.push({ text: this.capitalize(this.translateService.instant("Querybuilder.reportheader." + allHeaders[i])), style: "tableHeader", alignment: "left" });
      }
    }

    console.log("Table Headers are", tableHeaders);
    let tableContents = [];
    tablebody.push(tableHeaders);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      tableContents = [];

      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < allHeaders.length; j++) {
        if (allHeaders[j] === "asset_ID") {
          tableContents.push({ text: data[i].asset_ID, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "parent_ID") {
          tableContents.push({ text: data[i].parent_ID, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "asset_Type") {
          tableContents.push({ text: data[i].asset_Type, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "condition") {
          tableContents.push({ text: data[i].condition, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "material") {
          tableContents.push({ text: data[i].material, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "installer") {
          tableContents.push({ text: data[i].installer, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "install_Date") {
          tableContents.push({ text: data[i].install_Date, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "latitude") {
          tableContents.push({ text: data[i].latitude, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "longitude") {
          tableContents.push({ text: data[i].longitude, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "retire_Date") {
          tableContents.push({ text: data[i].retire_Date, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "position") {
          tableContents.push({ text: data[i].position, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "route_Type") {
          tableContents.push({ text: data[i].route_Type, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "work_Order") {
          tableContents.push({ text: data[i].work_Order, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "route") {
          tableContents.push({ text: data[i].route, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "assessment_Type") {
          tableContents.push({ text: data[i].assessment_Type, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "inspector") {
          tableContents.push({ text: data[i].inspector, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "inspection_Date") {
          tableContents.push({ text: data[i].inspection_Date, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "comments") {
          tableContents.push({ text: data[i].comments, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "mutcD_CODE") {
          tableContents.push({ text: data[i].mutcD_CODE, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "width") {
          tableContents.push({ text: data[i].width, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "height") {
          tableContents.push({ text: data[i].height, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "face_Direction") {
          tableContents.push({ text: data[i].face_Direction, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "mount_Height") {
          tableContents.push({ text: data[i].mount_Height, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "grid_Location") {
          tableContents.push({ text: data[i].grid_Location, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "replaces") {
          tableContents.push({ text: data[i].replaces, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "creation_Date") {
          tableContents.push({ text: data[i].creation_Date, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "creation_By") {
          tableContents.push({ text: data[i].creation_By, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "completion_Date") {
          tableContents.push({ text: data[i].completion_Date, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "completed_By") {
          tableContents.push({ text: data[i].completed_By, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "reason") {
          tableContents.push({ text: data[i].reason, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "status") {
          tableContents.push({ text: data[i].status, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "activity") {
          tableContents.push({ text: data[i].activity, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "woDesc") {
          tableContents.push({ text: data[i].woDesc, style: "tableContent", alignment: "left" });
        }

        if (allHeaders[j] === "woComments") {
          tableContents.push({ text: data[i].woComments, style: "tableContent", alignment: "left" });
        }

        // Added by Sowjanya Jira Id:-42 on 10/11/2021
        if (allHeaders[j] === "length") {
          tableContents.push({ text: data[i].shapeLength, style: "tableContent", alignment: "left" });
        }
      }

      tablebody.push(tableContents);
    }

    return tablebody;
  }
  createTableBodySQL(data) {
    const tablebody = [];

    if (!data) {
      Swal.fire("Records not Available");
      return;
    }

    if (data && data.length <= 0) {
      Swal.fire("Records not Available");
      return;
    }

    const allHeaders = Object.keys(data[0]);
    const tableHeaders = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allHeaders.length; i++) {
      if (allHeaders[i].includes("_")) {
        const a = allHeaders[i].split("_");
        const temp = [];

        // tslint:disable-next-line:prefer-for-of
        for (let k = 0; k < a.length; k++) {
          temp.push(this.capitalize(a[k]));
        }

        tableHeaders.push({ text: temp.join(" "), style: "tableHeader", alignment: "left" });
      } else {
        tableHeaders.push({ text: this.capitalize(allHeaders[i]), style: "tableHeader", alignment: "left" });
      }
    }

    console.log("Table Headers are", tableHeaders);
    let tableContents = [];
    tablebody.push(tableHeaders);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      tableContents = [];
      let cellData = "";

      // tslint:disable-next-line:forin
      for (const key in data[i]) {
        if (data[i][key] == null) {
          cellData = "";
        } else {
          cellData = data[i][key];
        }

        tableContents.push({ text: cellData, style: "tableContent", alignment: "left" });
        cellData = "";
      }

      tablebody.push(tableContents);
    }

    return tablebody;
  }
  capitalize = (s) => {
    if (typeof s !== "string") {
      return "";
    }

    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  reports(tablebody, reportFormat, selectedReportType, dataLength, pdfMake) {
    if (reportFormat === "Adobe PDF") {
      const pdfConig = this.pdfConfig(tablebody, selectedReportType, dataLength);
      pdfMake.createPdf(pdfConig).open();
    } else if (reportFormat === "Microsoft Excel") {
      let csv = "";

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < tablebody.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < tablebody[i].length; j++) {
          csv += tablebody[i][j].text + ",";
        }

        csv += "\n";
      }

      if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
      }

      const encodedUri = encodeURI(csv);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const filename = "ExportResultExcel" + ".csv";
      link.setAttribute("download", filename);
      link.click();
    }
  }
  pdfConfig(tablebody, selectedReport, totalCount) {
    const count = tablebody[0].length;
    const width = [];

    for (let i = 0; i < count; i++) {
      width.push("*");
    }

    const currentReportStyle = this.reportStyle.getReportStyle();
    const currentLayoutStyle = this.reportStyle.getReportLayout();
    const currentFooter = this.reportStyle.getReportFooter();
    // playground requires you to assign document definition to a variable called dd

    const dd = {
      pageSize: "A4", //TSDRSAM-1789
      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      footer: currentFooter,
      content: [
        { text: selectedReport + "\n\n", style: "header" },
        {
          table: {
            headerRows: 1,
            body: tablebody
          },
          layout: currentLayoutStyle,
          widths: width
        },
        { text: "\n Total Count : " + totalCount, style: "header" }
      ],
      styles: currentReportStyle
    };
    return dd;
  }

  createTableBodyForVisualizationLayers(data, isVisualizationData) {
    const tablebody = [];
    let max = -Infinity;
    let index = -1;

    if (!data) {
      Swal.fire("Records not Available");
      return;
    }

    if (data && data.length <= 0) {
      Swal.fire("Records not Available");
      return;
    }

    const tableHeaders = [];
    const allHeaders = Object.keys(data[0]);

    if (!isVisualizationData) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < allHeaders.length; i++) {
        if (allHeaders[i] == "shapeLength") {
          allHeaders[i] = allHeaders[i].replace("shapeLength", "length");
        }
        tableHeaders.push({ text: this.capitalize(allHeaders[i]), style: "tableHeader", alignment: "left" });
      }
    } else {
      //TSDRSAM-1789
      //length is different for different element of arrray
      //find index of array which has maximum number of column
      data.forEach(function (a, i) {
        if (a.length > max) {
          max = a.length;
          index = i;
        }
      });

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data[index].length; i++) {
        //only one loop
        tableHeaders.push({ text: this.capitalize(data[index][i].Label), style: "tableHeader", alignment: "left" });
      }
    }

    console.log("Table Headers are", tableHeaders);
    let tableContents = [];
    tablebody.push(tableHeaders);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      tableContents = Array(max).fill("");
      if (!isVisualizationData) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < allHeaders.length; j++) {
          //tableContents.push({ text: data[i][allHeaders[j]], style: "tableContent", alignment: "left" });
        }
      } else {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j] && data[i][j].Label == tablebody[0][j].text) {
            tableContents[j] = {};
            tableContents[j].text = data[i][j].Value;
            tableContents[j].style = "tableContent";
            tableContents[j].alignment = "left";
          } else {
            if (!tableContents[j].text) {
              tableContents[j] = {};
              tableContents[j].text = "NA";
              tableContents[j].style = "tableContent";
              tableContents[j].alignment = "left";
            }

            let tempIndex = tablebody[0].findIndex((img) => img.text === data[i][j].Label);
            tableContents[tempIndex] = {};
            tableContents[tempIndex].text = data[i][j].Value;
            tableContents[tempIndex].style = "tableContent";
            tableContents[tempIndex].alignment = "left";
          }
        }
      }

      tablebody.push(tableContents);
    }

    return tablebody;
  }

  updateMaxRecordMessage(maxRecordCount) {
    const originalMsg = this.translateService.instant("Selection.dataexceed.MaxRecords");
    const strFormat = (str, ...args) => args.reduce((s, v) => s.replace('%s', v), str);
    const updatedMsg = strFormat(originalMsg, maxRecordCount);
    Swal.fire(updatedMsg);
  }
  // END */
}
