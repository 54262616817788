<div class="card">
  <div class="card-body">
    <div class="row" style="margin-top: 10px;margin-bottom: 10px;">

      <div class="col-2">
        <div class="dropdown show">
          <app-dropdown aria-label="select region" [label]="'Organisation'" [dropdownList]="regions" [val]="selectedregion"
            [isRegion]="true" (select)="onRegionChange($event)"> </app-dropdown>
        </div>
      </div>
      <div class="col-2">
        <div class="dropdown show">
          <app-dropdown aria-label="select asset" [label]="'Type'" [dropdownList]="asset" [val]="selectedAssetClass"
            [isCrewManagement]="true" (select)="onAssetChange($event)"> </app-dropdown>
        </div>
      </div>
      <div class="col">
        <mat-tab-group mat-stretch-tabs disablePagination="true" (selectedTabChange)="filterdatabyDate($event)">
          <mat-tab [label]="'Crewmanagement.assignment.today' | translate">
          </mat-tab>
          <mat-tab [label]="'Crewmanagement.assignment.tomorrow' | translate">
          </mat-tab>
        </mat-tab-group>
      </div>
     
    </div>
    <div *ngIf="assignments">
      <table class="crewTable table tableFixHead tableWidth">
        <thead>
          <tr>
            <th>{{'Crewmanagement.optimizer.crewname'|translate}}</th>
            <th>{{'Crewmanagement.optimizer.numbertasks'|translate}}</th>
            <th class="disabled"></th>
            <th>7 am</th>
            <th>8 am</th>
            <th>9 am</th>
            <th>10 am</th>
            <th>11 am</th>
            <th>12 pm</th>
            <th>1 pm</th>
            <th>2 pm</th>
            <th>3 pm</th>
            <th>4 pm</th>
            <th>5 pm</th>
            <th>6 pm</th>
          </tr>
        </thead>
      </table>

      <div   *ngIf="assignments.length >0" class="tablescroll ">
        <table class="table ">
          <tr>
            <td style="width: 20.333%;">
              <table class="tableWidth tableFirst">
                <tbody>
                  <tr *ngFor="let assignment of assignments;let lid = index" id="row_{{lid}}" (click)="rowclicked(assignment,lid)"
                    class="crewTasktr">
                    <td>{{assignment.user}}</td>
                    <td>{{assignment.no_of_tasks}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style="width: 79.6667%;">
              <table class="example-boundary tableWidth tableSecond">
                <tbody>
                  <tr *ngFor="let assignment of assignments;let pind = index" class="crewTasktr">
                    <td *ngFor="let slot of timeslots;" id="cdktable">
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,slot);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == slot && arrdragPosition && arrdragPosition[pind]&& arrdragPosition[pind][clid]"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,slot).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div *ngIf="selectedRowindex == pind" class="roundbadges" >{{task.Task_Sequence || 0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <!-- <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,8);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 8"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,8).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>



                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,9);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 9"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,9).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                     
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,10);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 10"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,10).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence ||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,11);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 11"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,11).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                           
                            <div class="roundbadges" >{{task.Task_Sequence ||0}}</div>
                            {{task.OBJECTID}}
                           
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,12);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 12"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,12).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,13);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 13"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,13).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,14);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 14"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,14).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,15);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 15"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,15).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,16);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 16"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,16).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,17);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 17"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,17).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td>
                    <td>
                      <table><tr>
                        <td  *ngFor="let task of filterData(assignment.tasks,18);let clid = index,let lst = last">
                          <div  *ngIf= "task.hours == 18"  [ngClass]="{'badges':true,'low': task.Priority == 0,
                          'medium':task.Priority == 1, 
                          'high': task.Priority == 2 }" style="transform: translate3d(0px, 0px, 0px) !important;"
                            [style.width.%]="(task.EstimatedDuration*100*(filterData(assignment.tasks,18).length))/60" cdkDragBoundary=".example-boundary"
                            cdkDrag [cdkDragFreeDragPosition]="arrdragPosition[pind][clid]"  (dblclick)="openTask(task)" (cdkDragEnded)="dragEnd($event,pind,clid,task)">
                            <div class="roundbadges" >{{task.Task_Sequence||0}}</div>
                            {{task.OBJECTID}}
                          
                          </div>
                        </td>
                       </tr></table>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>

      </div>
    </div>
    <div class="col-12">
    </div>
  </div>
</div>