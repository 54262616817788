<div class="bound">
  <div class="redlineDiv card" cdkDragBoundary=".querybound" cdkDrag>
    <div class="card-header font-weight-bold" cdkDragHandle>
      {{ "Redline.mainbox.header" | translate }}
      <button type="button" class="close" aria-label="Close" (click)="togglepopup()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body formbg-d2">
      <form>
        <div class="form-row">
          <div class="form-group col-lg-5">
            <label id="rl_ddlDrawType" for="ddlDrawType">{{ "Redline.mainbox.drawtype" | translate }}:</label>
            <select aria-labelledby="rl_ddlDrawType" class="form-control" name="ddlDrawType" [(ngModel)]="drawToolType" (click)="endSketch()">
              <option value="polygon">{{ "Redline.drawtype.polygon" | translate }}</option>
              <option value="circle">{{ "Redline.drawtype.circle" | translate }}</option>
              <option value="ellipse">{{ "Redline.drawtype.ellipse" | translate }}</option>
              <option value="extent">{{ "Redline.drawtype.rectangle" | translate }}</option>
              <option value="freehandpolygon">{{ "Redline.drawtype.freepolygon" | translate }}</option>
              <option value="freehandpolyline">{{ "Redline.drawtype.freeline" | translate }}</option>
            </select>
          </div>
          <div class="form-group col-lg-5">
            <label id="rl_ddlLineWidthID" for="txtLineWidth">{{ "Redline.drawfnc.linewidth" | translate }}:</label>
            <input aria-labelledby="rl_ddlLineWidthID" type="number" [(ngModel)]="lineWidth" class="form-control" name="txtLineWidth" placeholder="Line Width" value="2" />
          </div>
          <div class="form-group col-lg-2">
            <label id="rl_ColorLabelID" for="colorPicker">{{ "Redline.drawfnc.color" | translate }}:</label>
            <!-- <a [style.background-color]="selectedColorhex"  class="color-box" (click)="showColorPicker = !showColorPicker">   -->
            <div class="divColorPicker" name="colorPicker" id="divColorPicker1">
              <div aria-labelledby="rl_ColorLabelID" id="widgetColorPicker"></div>
            </div>
            <!-- </a>                 -->
          </div>
        </div>
        <!-- <div class="form-row">
            <div class="form-group col">
                <label for="txtLineWidth">Line Width:</label>
                <input type="number" [(ngModel)]="lineWidth" class="form-control" name="txtLineWidth" placeholder="Line Width" value="4"/>
            </div>
        </div>            -->
      </form>
      <input type="file" accept=".3mr" #redlineUpload class="d-none" (change)="loadRedlines(redlineUpload.files)" aria-label="select file" />
    </div>
    <div class="card-footer">
      <button aria-label="Clear redline" type="submit" class="btn btn-primary mr-1" title="Clear redline" (click)="clear()">{{ "Redline.btnfnc.clear" | translate }}</button>
      <button aria-label="Save current redline to external file" type="submit" class="btn btn-primary mr-1" title="Save current redline to external file" (click)="saveRedlines()">
        {{ "Redline.btnfnc.save" | translate }}
      </button>
      <button aria-label="Load a previously saved redline file" type="submit" class="btn btn-primary" title="Load a previously saved redline file" (click)="redlineUpload.click()">
        {{ "Redline.btnfnc.load" | translate }}
      </button>
      <button aria-label="Toggle sketch mode on/off" type="submit" class="btn btn-primary float-right" title="Toggle sketch mode on/off" (click)="startSketch()">{{ "Redline.btnfnc.sketch" | translate }}</button>
    </div>
  </div>
</div>
