//Created by Abhilash for Jira-677
//Updated by Venkatesh for Jira-677
//Added for Visualization layers
import { Component, Input, OnInit } from "@angular/core";

// User and Data
import { Globals } from "../../select/globals";
import { IUser } from "../../interface/user";
import * as xml2js from "xml2js";

import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GenericLayerService } from "../../services/genericLayer.service";
import { TranslateService } from "@ngx-translate/core";
// Swal alerts
import Swal from "sweetalert2";
import { loadModules } from "esri-loader";
import { BASE64_PREFIX } from "src/app/shared/utils";

@Component({
  selector: "sms-visualizationDataLayer",
  templateUrl: "./visualizationDataLayer.component.html",
  styleUrls: ["../administrator.component.scss", "./visualizationDataLayer.component.scss"]
})
export class visualizationDataLayerComponent implements OnInit {
  currentUser: IUser;
  public wmsLayer;
  public WMSUrl;
  public selectedWMSLayer;
  public selectedWMSLayerName;
  public selectedWMSOrderLayer;
  public selectedWMSOrderLayerName;
  public WMSLayerName;
  public WMSLayerList;
  public referenceWMSLayerList = [];
  public fileInBase64Data: any;
  public removedLayerList = [];
  public WMSLayerModule;
  public layerLogo = "Add Logo";
  public existingVisualizationLyrData;
  public wmsUrlInputDisabled = false;

  constructor(public esritools: EsriMapComponent, private genericLayerService: GenericLayerService, private authService: AuthenticationService, private translateService: TranslateService) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    loadModules(["esri/layers/WMSLayer"]).then(([WMSLayer]) => {
      this.WMSLayerModule = WMSLayer;
    });
    this.loadExistingData();
  }

  loadExistingData() {
    this.wmsUrlInputDisabled = false;
    this.fileInBase64Data = null;
    this.layerLogo = "Add Logo";
    this.WMSUrl = "";
    this.WMSLayerName = "";
    this.WMSLayerList = [];
    this.removedLayerList = [];
    this.referenceWMSLayerList = [];
    this.genericLayerService.getVisualizationLayers().subscribe((layerList: any) => {
      layerList.forEach((layer) => {
        this.referenceWMSLayerList.push({
          wmsId: layer.wmsId,
          wmsUrl: layer.wmsUrl,
          layerName: layer.layerName,
          userName: layer.userName,
          password: layer.password,
          logo: layer.logo,
          layerOrder: layer.layerOrder,
          isDeleted: false,
          originalLayerName: layer.originalLayerName,
          originalLayerTitle: layer.originalLayerTitle,
          layerNotExist: false
        });
      });
      this.referenceWMSLayerList.sort(function (a, b) {
        return a.layerOrder - b.layerOrder;
      });
    });
    this.validateWMSSources();
  }

  validateWMSSources() {
    const invalidLayers = [];
    const promise = new Promise<void>((resolve, reject) => {
      let index = 0;
      this.referenceWMSLayerList.forEach(async (layer) => {
        index = index + 1;
        let currentLyrList = [];
        this.getWMSLayers(layer.wmsUrl, false)
          .then((result) => {
            currentLyrList = result;
            if (currentLyrList.filter((e) => e.layerName == layer.originalLayerName).length === 0) {
              invalidLayers.push(layer.layerName);
            }
            if (index == this.referenceWMSLayerList.length) {
              resolve();
            }
          })
          .catch((error) => {
            invalidLayers.push(layer.layerName);
            currentLyrList = [];
            if (index == this.referenceWMSLayerList.length) {
              resolve();
            }
          });
      });
    });

    promise.then(() => {
      invalidLayers.forEach((invalidLyr) => {
        this.referenceWMSLayerList.find((e) => e.layerName == invalidLyr.layerName).layerNotExist = true;
      });
    });
  }
  callWMSAsGet() {
    this.WMSLayerList = [];
    let valid = false;
    this.fileInBase64Data = null;
    this.layerLogo = "Add Logo";
    if (this.WMSUrl != null && this.WMSUrl.length > 0) {
      if (!this.WMSUrl.includes("?") && !this.WMSUrl.includes("=")) {
        valid = true;
      } else {
        Swal.fire(this.translateService.instant("VisualizationLayer.validUrl"));
      }
    }

    if (!valid) {
      return;
    }

    this.getWMSLayers(this.WMSUrl, true)
      .then((result) => {
        this.WMSLayerList = result;
      })
      .catch((error) => {
        this.WMSLayerList = [];
      });
  }

  async getWMSLayers(wmsUrl, singleWMS): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const layerList = [];
        if (singleWMS) {
          this.wmsUrlInputDisabled = true;
        }
        this.genericLayerService.getWMSLayers(wmsUrl + "?request=GetCapabilities&service=WMS").subscribe(
          (res: any) => {
            const lastIndex = wmsUrl.lastIndexOf("/");
            if (
              wmsUrl
                .substring(lastIndex + 1)
                .toLowerCase()
                .includes("wms")
            ) {
              const wfsUrl = wmsUrl.substring(0, lastIndex + 2) + "f" + wmsUrl.substring(lastIndex + 3); // wms => wfs
              console.log(wfsUrl);
              this.genericLayerService.getWMSLayers(wfsUrl + "?request=GetCapabilities&service=WFS").subscribe(
                (res: any) => {},
                (err) => {
                  if (singleWMS) {
                    Swal.fire(this.translateService.instant("VisualizationLayer.noQuery"));
                  }
                }
              );
            } else {
              Swal.fire(this.translateService.instant("VisualizationLayer.noQuery"));
            }

            const parser = new xml2js.Parser({ trim: true, explicitArray: true });
            if (res) {
              parser.parseString(res, (err, result) => {
                if (result && result.WMS_Capabilities && result.WMS_Capabilities.Capability && result.WMS_Capabilities.Capability.length > 0) {
                  result.WMS_Capabilities.Capability.forEach((element) => {
                    element.Layer.forEach((item) => {
                      item.Layer.forEach((layer) => {
                        if (layer.Layer && layer.Layer.length > 0) {
                          layer.Layer.forEach((sublayer) => {
                            layerList.push({ layerName: sublayer.Name[0], layerTitle: sublayer.Title[0] });
                          });
                        } else {
                          layerList.push({ layerName: layer.Name[0], layerTitle: layer.Title[0] });
                        }
                      });
                    });
                  });
                }
              });
              if (layerList.length == 0 && singleWMS) {
                Swal.fire(this.translateService.instant("VisualizationLayer.noLayers"));
              }
              resolve(layerList);
            } else {
              resolve(layerList);
              if (singleWMS) {
                Swal.fire(this.translateService.instant("VisualizationLayer.noDetails"));
              }
            }
          },
          (err) => {
            if (singleWMS) {
              Swal.fire(this.translateService.instant("VisualizationLayer.validUrl"));
            }
            reject(err);
          }
        );
      } catch (error) {
        if (singleWMS) {
          Swal.fire(this.translateService.instant("VisualizationLayer.errorWMS"));
        }
        this.wmsUrlInputDisabled = false;
        reject(error);
      }
    });
  }

  selectWMS(layer) {
    this.selectedWMSLayer = layer.originalLayerTitle ? layer.originalLayerTitle : layer.layerTitle;
    this.selectedWMSLayerName = layer.layerName;
    this.WMSLayerName = layer.layerTitle ? layer.layerTitle.substring(0, 30) : layer.layerName;
  }

  selectWMSLayerOrderList(layer) {
    this.selectedWMSOrderLayerName = layer.layerName;
    this.selectedWMSOrderLayer = layer;
  }

  uploadIcon(files) {
    this.fileInBase64Data = null;
    this.layerLogo = "Add Logo";
    if (files[0].size < 50000) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase() != "png") {
          Swal.fire(this.translateService.instant("VisualizationLayer.onlyPng"));
        } else {
          this.fileInBase64Data = reader.result;
          this.layerLogo = files[0].name;
        }
      };
    } else {
      //TSDRSAM-1651
      if(files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase() != "png"){
        Swal.fire(this.translateService.instant("VisualizationLayer.onlyPng"));
        return;
      }
      Swal.fire(this.translateService.instant("VisualizationLayer.iconSize"));
    }
  }

  addLayer() {
    if (this.WMSLayerName != null && this.WMSLayerName.length > 0) {
      if (this.referenceWMSLayerList.filter((e) => e.layerName.toLowerCase() === this.WMSLayerName.toLowerCase()).length === 0) {
        if (!this.fileInBase64Data) {
          Swal.fire(this.translateService.instant("VisualizationLayer.noLogo"));
        }
        //get max wmsId
        let maxWmsId =
          this.referenceWMSLayerList.length > 0
            ? Math.max.apply(
                Math,
                this.referenceWMSLayerList.map(function (e) {
                  return e.wmsId;
                })
              )
            : 0;
        const maxIdFromRemovedList =
          this.removedLayerList.length > 0
            ? Math.max.apply(
                Math,
                this.removedLayerList.map(function (e) {
                  return e.wmsId;
                })
              )
            : 0;

        if (maxWmsId < maxIdFromRemovedList) {
          maxWmsId = maxIdFromRemovedList;
        }

        this.referenceWMSLayerList.push({
          wmsId: maxWmsId + 1,
          wmsUrl: this.WMSUrl,
          layerName: this.WMSLayerName,
          userName: "",
          password: "",
          logo: this.fileInBase64Data ? this.fileInBase64Data.split(",")[1] : "",
          layerOrder: this.referenceWMSLayerList.length + 1,
          isDeleted: false,
          originalLayerName: this.selectedWMSLayerName,
          originalLayerTitle: this.selectedWMSLayer,
          layerNotExist: false
        });
        this.fileInBase64Data = null;
        this.layerLogo = "Add Logo";
        this.WMSLayerName = "";
      } else {
        Swal.fire(this.translateService.instant("VisualizationLayer.layerExists"));
      }
    } else {
      Swal.fire(this.translateService.instant("VisualizationLayer.nameRequired"));
    }
  }

  removeLayer() {
    this.WMSUrl = this.selectedWMSOrderLayer.wmsUrl;
    this.removedLayerList.push(this.referenceWMSLayerList.splice(this.referenceWMSLayerList.indexOf(this.selectedWMSOrderLayer), 1)[0]);
    if (this.selectedWMSOrderLayer.logo != "") {
      this.fileInBase64Data = BASE64_PREFIX + this.selectedWMSOrderLayer.logo;
      this.layerLogo = this.selectedWMSOrderLayer.layerName + ".png";
    } else {
      this.fileInBase64Data = null;
      this.layerLogo = "Add Logo";
    }
    this.selectWMS(this.selectedWMSOrderLayer);
    this.callWMSAsGet();
  }

  layerOrderUp() {
    const currentIndex = this.referenceWMSLayerList.indexOf(this.selectedWMSOrderLayer);
    for (let i = 0; i < this.referenceWMSLayerList.length; i++) {
      if (currentIndex != 0 && i == currentIndex) {
        this.referenceWMSLayerList[i].layerOrder = this.referenceWMSLayerList[i].layerOrder - 1;
        this.referenceWMSLayerList[i - 1].layerOrder = this.referenceWMSLayerList[i - 1].layerOrder + 1;
      }
    }
    this.referenceWMSLayerList.sort(function (a, b) {
      return a.layerOrder - b.layerOrder;
    });
  }

  layerOrderDown() {
    const currentIndex = this.referenceWMSLayerList.indexOf(this.selectedWMSOrderLayer);
    for (let i = 0; i < this.referenceWMSLayerList.length; i++) {
      if (currentIndex != this.referenceWMSLayerList.length - 1 && i == currentIndex) {
        this.referenceWMSLayerList[i].layerOrder = this.referenceWMSLayerList[i].layerOrder + 1;
        this.referenceWMSLayerList[i + 1].layerOrder = this.referenceWMSLayerList[i + 1].layerOrder - 1;
      }
    }
    this.referenceWMSLayerList.sort(function (a, b) {
      return a.layerOrder - b.layerOrder;
    });
  }

  addOrUpdateLayers() {
    try {
      const obj = [];
      this.referenceWMSLayerList.forEach((layer) => {
        obj.push({
          wmsId: layer.wmsId,
          wmsUrl: layer.wmsUrl,
          layerName: layer.layerName,
          userName: layer.userName,
          password: layer.password,
          logo: layer.logo,
          layerOrder: layer.layerOrder,
          isDeleted: false,
          originalLayerName: layer.originalLayerName,
          originalLayerTitle: layer.originalLayerTitle
        });
      });

      const finalRemovedLayers = this.removedLayerList.filter((e) => this.referenceWMSLayerList.filter((f) => f.layerName == e.layerName).length == 0);
      finalRemovedLayers.forEach((layer) => {
        obj.push({
          wmsId: layer.wmsId,
          wmsUrl: layer.wmsUrl,
          layerName: layer.layerName,
          userName: layer.userName,
          password: layer.password,
          logo: layer.logo,
          layerOrder: layer.layerOrder,
          isDeleted: true,
          originalLayerName: layer.originalLayerName,
          originalLayerTitle: layer.originalLayerTitle
        });
      });
      this.genericLayerService.addorUpdateVisualizationLayers(obj).subscribe((res: any) => {
        console.log(res);
        if (res.message == "Inserted Successfully") {
          this.removedLayerList = [];
          Swal.fire(this.translateService.instant("VisualizationLayer.updtSuccess"));
        }
      });
      this.existingVisualizationLyrData = this.esritools.visualizationLayersData;
      this.esritools.visualizationLayersData = [];

      obj.forEach((wmsLyr) => {
        const existingLayerInfo = this.existingVisualizationLyrData.filter((e) => e.layerName.toLowerCase() === wmsLyr.layerName.toLowerCase());
        if (!wmsLyr.isDeleted) {
          wmsLyr.legend = wmsLyr.wmsUrl + "?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=15&HEIGHT=15&LAYER=" + wmsLyr.originalLayerName;
          wmsLyr.visible = existingLayerInfo.length === 0 ? false : existingLayerInfo.visible;
          wmsLyr.imgLogo = BASE64_PREFIX + wmsLyr.logo;
          this.esritools.visualizationLayersData.push(wmsLyr);
          if (existingLayerInfo.length === 0) {
            //Add layer to map
            const currentLayer = new this.WMSLayerModule(wmsLyr.wmsUrl, {
              format: "png",
              visibleLayers: [wmsLyr.originalLayerName]
            });
            currentLayer.id = wmsLyr.layerName;
            this.esritools.map.addLayer(currentLayer);
            currentLayer.setVisibility(wmsLyr.visible);
          }
        } else if (existingLayerInfo.length !== 0) {
          //Remove layer from map
          if (this.esritools.map.getLayer(wmsLyr.layerName)) {
            this.esritools.map.removeLayer(this.esritools.map.getLayer(wmsLyr.layerName));
          }
        }
      });
      this.esritools.map.visualizationLayers = this.esritools.visualizationLayersData;
    } catch (error) {
      console.log(error);
      Swal.fire(this.translateService.instant("VisualizationLayer.errorLoading"));
    }
  }

  clearUrlData() {
    this.wmsUrlInputDisabled = false;
    this.fileInBase64Data = null;
    this.layerLogo = "Add Logo";
    this.WMSUrl = "";
    this.WMSLayerName = "";
    this.WMSLayerList = [];
  }
}
