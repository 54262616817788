import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SMSHttpClient } from "../helpers/http-client";
import { Observable } from "rxjs";
import { Client } from "../interface/client";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private httpClient: SMSHttpClient) {}

  getUserLayers(clientId, userId) {
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId + "/" + environment.getLayerAccess);
  }

  getUserInfo(clientId, userId) {
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId);
  }

  // Client List Services
  getClientList() {
    return this.httpClient.get(environment.getClientlist);
  }

  getRoleslist(): any {
    return this.httpClient.get(environment.getRoleslist);
  }

  getClientListUser(clientId: number) :Observable<Client> {
    return this.httpClient.get(environment.getClient + "/" + clientId);
  }

  // Start User Base Services
  getUserListByClient(ClientId) {
    return this.httpClient.get(environment.getClientlist + "/" + ClientId + "/" + environment.getUserlist);
  }

  getUserNamesByClient(ClientId) {
    return this.httpClient.get(environment.getClientlist + "/" + ClientId + "/" + environment.getUserlist);
  }

  postUserDataByClient(ClientId, obj) {
    return this.httpClient.post(environment.getSignup + "/" + ClientId, obj);
  }

  deleteUserDataByClient(clientId, UserId) {
    return this.httpClient.delete(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + UserId);
  }

  UpdateUserDataByClient(ClientId, UserId, obj) {
    return this.httpClient.post(environment.getUpdateUser + "/" + ClientId + "/" + environment.getUser + "/" + UserId, obj);
  }
  // End User Base Services

  // Start LayerList Base Services
  getUserLayerAccess(clientId, userId) {
    //console.log("getting layers");
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId + "/" + environment.getLayerAccess);
  }

  userAccountUnlock(userId) {
    // Added by Abhilash Jira Id:-57 on 10/11/2021
    return this.httpClient.post(environment.AccountUnlockPost + "?UserId=" + userId, null);
  }

  postUserLayerAccess(ClientId, UserId, obj) {
    return this.httpClient.post(environment.getClientlist + "/" + ClientId + "/" + environment.getUserlist + "/" + UserId + "/" + environment.getLayerAccess, obj);
  }

  RemoveUserLayerAccess(clientId, UserId, LayerId) {
    return this.httpClient.delete(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + UserId + "/" + environment.getLayerAccess + "/" + LayerId);
  }
  // End LayerList Base Services

  // Start Region Base Services
  getUserRegionAccess(clientId, userId) {
    console.log("getting regions");
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId + "/" + environment.getRegionAccess);
  }

  postUserRegionAccess(ClientId, UserId, obj) {
    return this.httpClient.post(environment.getClientlist + "/" + ClientId + "/" + environment.getUserlist + "/" + UserId + "/" + environment.getRegionAccess, obj);
  }

  RemoveUserRegionAccess(ClientId, UserId, RegionName, RegionLevel) {
    return this.httpClient.delete(
      environment.getClientlist + "/" + ClientId + "/" + environment.getUserlist + "/" + UserId + "/" + environment.getRegionAccess + "?RegionName=" + RegionName + "&RegionLevel=" + RegionLevel
    );
  }
  // End Region Base Services

  getUserRolePermissionInfo(userId, clientId) {
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist + "/" + userId + "/RolePermission");
  }

}
