import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { LoginTranslationService } from 'src/app/shared/login-translation.service';
import { MatSelectModule } from "@angular/material/select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedModule } from "src/app/shared/shared.module";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from "@angular/material/button";
import {MatIconModule} from '@angular/material/icon';
@NgModule({
  //TSDRSAM-1687
  declarations: [LoginComponent],
  imports: [CommonModule, BrowserModule, FormsModule, ReactiveFormsModule, MatSelectModule, FontAwesomeModule,SharedModule,MatCardModule
    ,MatFormFieldModule,MatInputModule,MatMenuModule,MatButtonModule,MatIconModule],

  
})
export class LoginModule {}
