import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
@Component({
  selector: "sms-help-center",
  templateUrl: "./help-center.component.html",
  styleUrls: ["./help-center.component.scss"]
})
export class HelpCenterComponent implements OnInit {
  currentUser: IUser;
  showAdmin = false;
  @Output() closeHelpEvent = new EventEmitter();
  constructor(private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;

    if (this.currentUser.roleId == 1) {
      this.showAdmin = true;
    }
  }
  ngOnInit(): void {
    // console.log("init help center component")
  }
  /**Close Help Center Popup */
  closeHelpBuilder() {
    this.closeHelpEvent.emit(false);
  }
}
