/* Added by Abhilash Jira Id:-40 on 9/28/2021 */
import { Component } from "@angular/core";
import { ITooltipParams } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AssetDetailService } from "src/app/services/assetDetail.service";
import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "tooltip-component",
  templateUrl: "./customTooltip.component.html",
  styles: [
    `
      :host {
        position: absolute;
        width: 100px;
        height: 100px;
        pointer-events: none;
        top: 10px;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        position: absolute;
        top: -65px;
        left: 20px;
        font-weight: bold;
        opacity: 1;
        filter: drop-shadow(0 0 0.75rem black);
      }
      .imageSize {
        width: auto;
        height: 100px;
        object-fit: cover;
      }
    `
  ]
})
export class CustomTooltip implements ITooltipAngularComp {
  public params: { color: string } & ITooltipParams;
  public data: any;
  public color: string;
  public currentUser: any;
  public imageSrc: any;
  constructor(private authService: AuthenticationService, public esritools: EsriMapComponent, public objAssetDetailService: AssetDetailService) {
    this.currentUser = this.authService.currentUserValue;
  }
  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;

    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.color = this.params.color || "rgba(255,255,255,0)";
    let featureList;
    const query = new this.esritools.Query();
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.where = "AssetID ='" + this.data.AssetID + "'";
    const queryTask = new this.esritools.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);
    queryTask.execute(query).then((result) => {
      if (result && result.features && result.features.length > 0) {
        featureList = result.features.sort(function (a, b) {
          return b.attributes.DocDate - a.attributes.DocDate;
        });
        const photo = featureList.filter((feature) =>
          ["jpg","JPG","jpeg","JPEG","png","PNG"].indexOf(feature.attributes.DocType) != -1
        )[0];
        this.objAssetDetailService.getImageAsBase64(photo.attributes.OBJECTID,this.currentUser.clientId,photo.attributes.DocType).subscribe(
          (base64Image: any) => {
            if(base64Image){
              this.imageSrc = "data:image/jpeg;base64," + base64Image;
            }else{
              this.imageSrc = 'No Image Found';
            }
          });
      }else{
        this.imageSrc = 'No Image Found';
      }
    });
  }
}

interface ITooltipAngularComp {
  /** The agInit(params) method is called on the tooltip component once.
        See below for details on the parameters. */
  agInit(params: ITooltipParams): void;
}
