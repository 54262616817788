import { Component, OnInit } from '@angular/core';
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sms-passwordConfig',
  templateUrl: './password-config.component.html',
  styleUrls: ['./password-config.component.scss']
})
export class PasswordConfigComponent implements OnInit {

  minLength;
  maxLength;
  minSpecialChar;
  minUpperCase;
  minLowerCase;
  minNumeric;
  daysBeforeChange;


  ngOnInit() {
    if (this.adminGlobal.agencyDetails) {
      this.minLength = this.adminGlobal.agencyDetails[0].pwMinLen;
      this.maxLength = this.adminGlobal.agencyDetails[0].pwMaxLen;
      this.minSpecialChar = this.adminGlobal.agencyDetails[0].pwMinSpec;
      this.minUpperCase = this.adminGlobal.agencyDetails[0].pwMinUC;
      this.minLowerCase = this.adminGlobal.agencyDetails[0].pwMinLC;
      this.minNumeric = this.adminGlobal.agencyDetails[0].pwMinNum;
      this.daysBeforeChange = this.adminGlobal.agencyDetails[0].pwRenewDays;
    }
  }
  constructor(private MMMAdminService: MMMAdminService,
    public adminGlobal: adminGlobal, private translateService: TranslateService) {
  }

  save() {
    if (this.minLength && this.maxLength && this.minSpecialChar && this.minUpperCase && this.minLowerCase && this.minNumeric && this.daysBeforeChange) {
    this.adminGlobal.agencyDetails[0].pwMinLen = this.minLength;
    this.adminGlobal.agencyDetails[0].pwMaxLen = this.maxLength;
    this.adminGlobal.agencyDetails[0].pwMinSpec = this.minSpecialChar;
    this.adminGlobal.agencyDetails[0].pwMinUC = this.minUpperCase;
    this.adminGlobal.agencyDetails[0].pwMinLC = this.minLowerCase;
    this.adminGlobal.agencyDetails[0].pwMinNum = this.minNumeric;
    this.adminGlobal.agencyDetails[0].pwRenewDays = this.daysBeforeChange;
    this.MMMAdminService.updateClientList(this.adminGlobal.agencyDetails[0], this.adminGlobal.AgencyId).subscribe(
      (res: any) => {
        if (res.message == "Updated Successfully") {
          Swal.fire(this.translateService.instant("PasswordConfig.configUpdated"));
        }
      },
      (err) => {
        console.log(err);
      })
  } else {
    Swal.fire("Please enter the input for all fields");
  }
  }

  checkValue(event) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }
}