import { Component, OnInit } from "@angular/core";
import { adminGlobal } from "src/app/administrator/adminGlobal";

@Component({
  selector: "sms-headAdmin",
  templateUrl: "./headAdmin.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class headAdminComponent implements OnInit {
  SecondaryAgencyLanguage;
  PrimaryAgencyLanguage;
  SolutionTier;
  agencyWebService;
  agencyName;
  agencySpatialDatabase;
  constructor(private adminGlobal: adminGlobal) {}
  ngOnInit() {
    const details = this.adminGlobal.agencyDetails[0];
    this.agencyName = details.clientName;
    this.agencyWebService = details.webServiceUrl;
    this.SolutionTier = details.clientType;
    this.agencySpatialDatabase = details.clientDb;
    this.adminGlobal.PALValues.forEach((dataVal) => {
      if (dataVal.languageID === details.primaryLanguageID) {
        this.PrimaryAgencyLanguage = dataVal.languageName;
      }
    });

    if (details.secondaryLanguageID !== null) {
      this.adminGlobal.PALValues.forEach((dataVal) => {
        if (dataVal.languageID === details.secondaryLanguageID) {
          this.SecondaryAgencyLanguage = dataVal.languageName;
        }
      });
    }
  }
}
