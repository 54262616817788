<div class="card">
  <div class="card-body admin-model-height">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white"> Agency Configuration Details</div>
          <div class="row bg-white">
            <div class="col-12 py-3 sumry_agency_Config">
              <div class="form-group row">
                <label for="agencyName" class="col-sm-5 col-form-label">Agency Name</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="agencyName" [(ngModel)]="agencyName"
                    value="">
                </div>
              </div>
              <div class="form-group row">
                <label for="SolutionTier" class="col-sm-5 col-form-label">Solution Tier</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="SolutionTier"
                    [(ngModel)]="SolutionTier" value="">
                </div>
              </div>
              <div class="form-group row">
                <label for="PrimaryAgencyLanguage" class="col-sm-5 col-form-label">Primary Agency Language</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="PrimaryAgencyLanguage"
                    [(ngModel)]="PrimaryAgencyLanguage" value="">
                </div>
              </div>
              <div class="form-group row">
                <label for="SecondaryAgencyLanguage" class="col-sm-5 col-form-label">Secondary Agency Language</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="SecondaryAgencyLanguage"
                    [(ngModel)]="SecondaryAgencyLanguage" value="">
                </div>
              </div>
              <div class="form-group row">
                <label for="agencyWebService" class="col-sm-5 col-form-label">Agency Web Service</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="agencyWebService"
                    [(ngModel)]="agencyWebService" value="">
                </div>
              </div>
              <div class="form-group row">
                <label for="agencySpatialDatabase" class="col-sm-5 col-form-label">Agency Spatial Database</label>
                <div class="col-sm-7">
                  <input type="text" readonly class="form-control-plaintext" id="agencySpatialDatabase"
                    [(ngModel)]="agencySpatialDatabase" value="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white ml-1">Assigned Asset Classes</div>
          <div class="row bg-white ml-1">
            <form class="col-12 py-3 ">
              <div class="form-group">
                <div *ngFor="let assetClass of AssetClass">
                  <div class="row"><b>{{ assetClass.Alias}}</b></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white">Expected Life</div>
          <div class="row bg-white">
            <div class="col-12 py-3 sumry_agency_Config">
              <div class="form-group">
                <div *ngFor="let baseLifeConfig of adminGlobal.NewBaselifeConfig">
                  <div class="row"><b>{{ baseLifeConfig.AssetClass}} / {{ baseLifeConfig.BLF_Column}}</b></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white ml-1">Expected Life Modifier</div>
          <div class="row bg-white ml-1">
            <form class="col-12 py-3 ">
              <div class="form-group">
                <div *ngFor="let baseLifeConfig of adminGlobal.NewBaselifeModifier">
                  <div class="row"><b>{{ baseLifeConfig.AssetClass}} / {{ baseLifeConfig.Modifier_Column}}</b></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white">Resource Requirement</div>
          <div class="row bg-white">
            <div class="col-12 py-3 sumry_agency_Config">
              <div class="form-group">
                <label>Resource FileName:</label>
                {{adminGlobal.ResourceRequirementFilename}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row h5 mb-0 p-3 bg-dark text-white ml-1">System Report</div>
          <div class="row bg-white ml-1">
            <form class="col-12 py-3 ">
              <div class="form-group">
                <div *ngFor="let systemReport of SystemReport">
                  <div class="row"><b>{{ systemReport.reportName}}</b></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-4 d-flex justify-content-end align-items-end">
  <div *ngIf="loading" class="spinner-border spinner" role="status">
  </div>
  <button type="button" class="btn btn-secondary" [disabled]="!showApplySettingButton ? 'disabled': null"
    (click)="applySettings()">Apply Settings</button>
</div>