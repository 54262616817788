<div class="query-box" id="queryDragDiv" cdkDragBoundary=".querybound" cdkDrag>
  <div class="querybound">
    <div class="modal-content small-modal" [ngClass]="{ 'qb-minimized': isQueryBuilderMinimized }" [ngResizable]="!isQueryBuilderMinimized" [rzHandles]="'n,e,s,w,se,sw,ne,nw'">
      <!-- Reverse logic that disables resizeability -->
      <!-- remove margin -50px -->
      <!-- Remove double y scrollbar -->

      <app-heading-component
        cdkDragHandle
        [headingText]="'Querybuilder.maindiv.header' | translate"
        [variant]="'none'"
        [tooltipOne]="isQueryBuilderMinimized == false ? minimizeTooltip : expand"
        [iconOne]="isQueryBuilderMinimized == false ? 'arrow-up' : 'arrow-down'"
        (actionOne)="minimizeQueryBuilder()"
        [tooltipTwo]="'Close'"
        [iconTwo]="'cross'"
        (actionTwo)="closeQueryBuilder()"
        [iconTwoDropdown]="false"
      >
      </app-heading-component>
      <div [ngStyle]="{ display: isQueryBuilderMinimized === true ? 'none' : 'initial' }" style="max-height: 80%">
        <mat-tab-group mat-stretch-tabs disablePagination="true" style="max-height: 100%">
          <!-- Query Builder -->
          <mat-tab [label]="'Querybuilder.maindiv.buildertab' | translate">
            <div class="q-builder">
              <!-- Query form -->
              <div class="query-builder-heading grey-divider-bottom h6">Add Query</div>
              <div class="dropdown-inputs">
                <div class="smaller-dropdown" style="width: 90px">
                  <app-dropdown
                    [label]="'Querybuilder.buildertab.jointype' | translate"
                    [dropdownList]="joinTypeDropdown"
                    [val]="jointype"
                    (select)="onJoinChange($event)"
                    [isJoinType]="true"
                    [disableJoinTypeDropdown]="disableJoinTypeDropdown"
                  >
                  </app-dropdown>
                </div>
                <div *ngIf="jointype == 'Distance'" style="height: 48px">
                  <mat-form-field appearance="outline">
                    <mat-label>Within (&#60;=)</mat-label>
                    <input matInput placeholder="Feet" type="number" min="1" id="inputStateDistance" class="form-control query-builder-input" [(ngModel)]="distanceValue" autocomplete="off" />
                  </mat-form-field>
                </div>
                <app-dropdown [label]="'Querybuilder.buildertab.layer' | translate" [val]="selectedlayr && (selectedlayr.id ?? null)" [dropdownList]="layerDropdown" (select)="onLayerChange($event)"> </app-dropdown>
                <app-dropdown [label]="'Querybuilder.buildertab.field' | translate" [dropdownList]="fieldDropdown" [val]="fields" (select)="onFieldChange($event)"> </app-dropdown>
                <app-dropdown [label]="'Querybuilder.buildertab.operator' | translate" [dropdownList]="operatorDropdown" (select)="operator = $event" [val]="operator"> </app-dropdown>
                <!-- No set values, render a text box -->

                <ng-template [ngIf]="codedValues != null && codedValues.length == 0">
                  <div id="1" style="width: 220px">
                    <ng-template [ngIf]="enableDateTimePicker">
                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Value</mat-label>

                        <input matInput placeholder="Value" [matDatepicker]="picker" autocomplete="off" [formControl]="codedValueControl" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-template>

                    <ng-template [ngIf]="!enableDateTimePicker">
                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Value</mat-label>
                        <input matInput placeholder="Value" autocomplete="off" [formControl]="codedValueControl" />
                      </mat-form-field>
                    </ng-template>
                  </div>
                </ng-template>
                <!-- Values are pre-determined, render a select box -->
                <ng-template [ngIf]="codedValues != null && codedValues.length > 0">
                  <div id="2" style="width: 220px">
                    <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>{{ "Querybuilder.buildertab.value" | translate }}</mat-label>
                      <input matInput [matAutocomplete]="auto" [formControl]="codedValueControl" />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let item of filteredOptions | async" [value]="item.code">
                          <span style="color: #000000"
                            ><strong>{{ item.code }}</strong></span
                          >
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <span style="color: #65656d"
                            ><small>{{ item.name }}</small></span
                          >
                        </mat-option>
                      </mat-autocomplete>
                      <div class="dropdown-icons">
                        <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                      </div>
                    </mat-form-field>
                  </div>
                </ng-template>
                <button mat-button style="margin-top: 0; margin-bottom: 18px" class="add-button h7" (click)="editItem()" *ngIf="isRowClicked == true; else elseBlock">
                  <mat-icon svgIcon="pencil"></mat-icon>
                  Save
                </button>
                <ng-template #elseBlock>
                  <button style="margin-top: 0; margin-bottom: 18px" mat-button class="add-button h7" (click)="addItem()">
                    <mat-icon svgIcon="plus"></mat-icon>
                    Add
                  </button>
                </ng-template>
              </div>
              <div class="form-content">
                <mat-checkbox class="regular" color="primary" [(ngModel)]="chkMapExtent">{{ "Querybuilder.buildertab.currentmapextent" | translate }}</mat-checkbox>
              </div>
              <!-- Selected Query Criteria -->
              <div *ngIf="querytableArr != null && querytableArr.length > 0" class="query-builder-heading h6">Listed Queries</div>
              <div *ngIf="querytableArr != null && querytableArr.length > 0" class="criteria-list section-spacing">
                <div class="criteria" *ngFor="let qt of querytableArr; let i = index" (click)="setClickedRow(i)" [class.active]="i == selectedRow">
                  <div style="min-width: 36px">{{ qt.jointype }}</div>
                  <div>{{ qt.layerDisplay }}</div>
                  <div>{{ qt.field }}</div>
                  <div>{{ qt.operator }}</div>
                  <div>{{ qt.value }}</div>
                  <div class="flex-0">
                    <button mat-button class="remove-button xsmall" (click)="deleteFieldValue(i)" aria-label="removequery">
                      <mat-icon svgIcon="cross"></mat-icon>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <!-- Query Management Section -->
              <div class="query-builder-heading h6 grey-divider-bottom">Query Management</div>
              <div class="form-content grey-divider-bottom section-spacing">
                <div class="form-element">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "Querybuilder.buildertab.selectquery" | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedLoadFile" (ngModelChange)="onFileNameChange($event, 'QB')">
                      <mat-option [value]="fileObj.fileName" *ngFor="let fileObj of savedQueriesList">
                        {{ fileObj.fileName }}
                      </mat-option>
                    </mat-select>

                    <div class="dropdown-icons">
                      <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                    </div>
                  </mat-form-field>
                </div>
                <div class="form-element">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "Querybuilder.buildertab.queryname" | translate }}</mat-label>
                    <input matInput [(ngModel)]="fileName" />
                  </mat-form-field>
                </div>
                <div *ngIf="currentUser.soultionTiers[0][0].query_Actions && clickedOnSave" class="form-element">
                  <mat-icon (click)="saveQuery('QB')" class="pointer" svgIcon="save"></mat-icon>
                </div>
                <div *ngIf="currentUser.soultionTiers[0][0].query_Actions" class="form-element">
                  <mat-icon (click)="deleteQuery('QB')" class="pointer" svgIcon="delete"></mat-icon>
                </div>
                <div *ngIf="currentUser.soultionTiers[0][0].share_Queries" class="form-element">
                  <input type="file" (change)="loadTextAsQuery($event)" style="display: none" #queryUpload aria-label="select file" />
                  <button mat-button class="remove-button xsmall" (click)="queryUpload.click()">
                    <mat-icon svgIcon="download"></mat-icon>
                    <span class="button-text">Import</span>
                  </button>
                </div>
                <div class="form-element">
                  <button mat-button class="remove-button xsmall" (click)="saveQueryAsFile()">
                    <mat-icon svgIcon="share"></mat-icon>
                    <span class="button-text">Export</span>
                  </button>
                </div>
                <div *ngIf="queryDataObj.length > 0 && !sqlViewOnMap && showCardViewForVisualizationLayer == false">
                  <button mat-button class="remove-button xsmall" title="{{ 'Select-tool.icon.cardviewer' | translate }}" (click)="openCardClicked()">
                    <mat-icon svgIcon="table-view-right"></mat-icon>
                  </button>
                </div>
                <div *ngIf="queryDataObj.length > 0 && !sqlViewOnMap && showCardViewForVisualizationLayer == true">
                  <button mat-button class="remove-button xsmall" title="{{ 'Select-tool.icon.cardviewer' | translate }}" (click)="openCardClicked()" data-forTab="yes" id="visualizationResultCard">
                    <mat-icon svgIcon="table-view-right"></mat-icon>
                  </button>
                </div>
                <div *ngIf="queryDataObj.length > 0 && !sqlViewOnMap">
                  <button mat-button class="remove-button xsmall" title="{{ 'Select-tool.icon.tableviewer' | translate }}" (click)="openGridClicked()">
                    <mat-icon svgIcon="table-view-bottom"></mat-icon>
                  </button>
                </div>
                <div *ngIf="queryDataObj.length > 0 && !sqlViewOnMap">
                  <button mat-button class="remove-button xsmall" title="{{ 'Select-tool.icon.maintenanceviewer' | translate }}" (click)="openMaintenanceClicked()">
                    <mat-icon svgIcon="table-view-bottom-2"></mat-icon>
                  </button>
                </div>
              </div>

              <div *ngFor="let assets of queryDataObj" style="padding-left: 16px; padding-bottom: 16px; font-size: 14px; margin-right: 8px">
                <span>{{ assets.LayerName.tableAlias }}:</span
                ><b
                  ><span>{{ assets.Data.length }}</span></b
                >
              </div>
            </div>
            <!-- Action buttons -->
            <div class="action-items">
              <button (click)="cleartable()" class="add-button h7" mat-button>{{ "Querybuilder.buildertab.clear" | translate }}</button>
              <button (click)="buildQuery()" class="add-button h7" mat-button>{{ "Querybuilder.buildertab.query" | translate }}</button>
              <button
                *ngIf="currentUser.soultionTiers[0][0].export_Query_Results && showCardViewForVisualizationLayer == false"
                [disabled]="queryDataObj.length <= 0 || querytableArr.length <= 0"
                class="add-button h7"
                data-target="#exampleModalCenter1"
                mat-button
                data-toggle="modal"
              >
                {{ "Querybuilder.buildertab.report" | translate }}
              </button>
              <button *ngIf="currentUser.soultionTiers[0][0].export_Query_Results && showCardViewForVisualizationLayer == true" class="add-button h7" data-target="#exampleModalCenter1" mat-button data-toggle="modal">
                {{ "Querybuilder.buildertab.report" | translate }}
              </button>
            </div>
          </mat-tab>
          <!-- Predefined Queries -->
          <mat-tab [label]="'Querybuilder.maindiv.predefinedtab' | translate">
            <mat-radio-group class="predefined-query-group">
              <mat-radio-button color="primary" class="example-radio-button" class="xsmall" (change)="selectPredefinedQuery($event, query)" *ngFor="let query of predefinedQuerisList" [value]="query">
                {{ query.queryDescription }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="action-items">
              <button type="button" mat-button class="h7 add-button execute-button short-button" (click)="buildQuery()">{{ "Querybuilder.predefinedquery.execute" | translate }}</button>
              <button type="button" mat-button class="h7 add-button short-button" (click)="clearPredefinedtable()">{{ "Querybuilder.predefinedquery.clear" | translate }}</button>
            </div>
          </mat-tab>
          <!-- Query Creator -->
          <mat-tab *ngIf="currentUser.soultionTiers[0][0].query_Creator" [label]="'Querybuilder.maindiv.querycreatortab' | translate">
            <div>
              <div class="container">
                <form>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label for="exampleFormControlTextarea1">{{ "Querybuilder.predefinedquery.queryentry" | translate }}</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" style="font-size: 14px" name="query_created" [(ngModel)]="createdQuery" (change)="onTextAreaChange($event)"></textarea>
                    </div>
                    <div *ngIf="sqlResultCount > 0" class="col-4 my-1 mr-sm-2" style="color: green; position: relative; float: right">
                      <label>{{ "Querybuilder.predefinedquery.totalresultavail" | translate }} : {{ sqlResultCount }}</label>
                    </div>
                    <div class="form-group col-12 mt-3 mb-3">
                      <div class="d-flex justify-content-start">
                        <select id="inputState" style="width: 150px" class="form-control mr-2 query-builder-input" name="allSavedFiles" [(ngModel)]="selectedLoadFile" (ngModelChange)="onFileNameChange($event, 'QC')">
                          <option [value]="''">{{ "Querybuilder.predefinedquery.selectquery" | translate }}</option>
                          <option *ngFor="let fileObj of sqlgetSavedQueries">
                            {{ fileObj.fileName }}
                          </option>
                        </select>
                        <input
                          type="text"
                          style="width: 150px"
                          class="form-control mr-2 query-builder-input"
                          placeholder="{{ 'Querybuilder.buildertab.queryname' | translate }}"
                          id="saveFile"
                          name="saveFileName"
                          [(ngModel)]="fileName"
                          aria-label="File Name"
                        />
                        <button type="button" class="btn btn-secondary mr-2 query-builder-input" (click)="vaildateQuery()" [disabled]="clickedValidate">
                          {{ "Querybuilder.predefinedquery.validate" | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary mr-2 query-builder-input"
                          (click)="runQuery()"
                          *ngIf="createdQuery != null && createdQuery != '' && queryValidatedFromServer == true"
                          [disabled]="clickedRun"
                        >
                          {{ "Querybuilder.predefinedquery.run" | translate }}
                        </button>
                        <button type="button" class="btn btn-secondary mr-2 query-builder-input" *ngIf="createdQuery != null && createdQuery != ''" (click)="clearQuery()">
                          {{ "Querybuilder.buildertab.clear" | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary mr-2 query-builder-input"
                          *ngIf="createdQuery != null && createdQuery != '' && queryValidatedFromServer == true && clickedOnSave"
                          (click)="saveQuery('QC')"
                        >
                          {{ "Querybuilder.predefinedquery.save" | translate }}
                        </button>
                        <button type="button" class="btn btn-primary mr-2 query-builder-input" *ngIf="createdQuery != null && createdQuery != ''" (click)="deleteQuery('QC')">
                          {{ "Querybuilder.predefinedquery.delete" | translate }}
                        </button>
                        <div class="spinner-border map-loader mr-4 mt-2" role="status" *ngIf="loadingQc">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group ml-2">
                      <div class="col-4 custom-control custom-checkbox my-1 mr-sm-2" style="display: inline">
                        <input type="checkbox" class="custom-control-input" id="exportResultsToCSV" name="excelView" [(ngModel)]="sqlViewOnExcel" />
                        <label class="custom-control-label" for="exportResultsToCSV">{{ "Querybuilder.predefinedquery.exportcsv" | translate }}</label>
                      </div>
                      <div class="col-4 custom-control custom-checkbox my-1 mr-sm-2" style="display: inline">
                        <input type="checkbox" class="custom-control-input" id="loadReturnedAssetsInSMS" name="mapView" [(ngModel)]="sqlViewOnMap" />
                        <label class="custom-control-label" for="loadReturnedAssetsInSMS">{{ "Querybuilder.predefinedquery.loadreturnedassets" | translate }}</label>
                      </div>
                      <div style="font-size: 19px">
                        <fa-icon [icon]="faInfoCircle" (click)="InfoMessage()"></fa-icon>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!-- Export Modal -->
    <div class="modal fade bd-example-modal-lg" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-label="exampleModalCenterTitle1" aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content export-modal">
          <app-heading-component aria-label="Close" [headingText]="'Export'" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" data-dismiss="modal"> </app-heading-component>
          <div *ngIf="'queryDataObj.length>0' || isVisualizationLyrSelected == true" class="modal-body">
            <div class="export-form">
              <!-- TSDRSAM-1805 report name was not displaying in the dropdown -->
              <app-dropdown [label]="'Querybuilder.exportreport.reportname' | translate" [isQueryReport]="true" [dropdownList]="reportValues" [val]="reportTypeES" (select)="onChangeReportType($event)"> </app-dropdown>
              <app-dropdown [label]="'Querybuilder.exportreport.format' | translate" [isQueryReportFormat]="true" [dropdownList]="reportFormat" [val]="reportFormatES" (select)="onChangeReportFormat($event)">
              </app-dropdown>
            </div>
          </div>
          <div class="action-items">
            <button mat-button class="add-button h7" (click)="exportReports()" *ngIf="queryDataObj.length > 0 || isVisualizationLyrSelected == true">
              <mat-icon svgIcon="share"></mat-icon>
              <span class="button-text">Export</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
