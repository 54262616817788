import { Component, OnInit } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { SystemReportsStyle } from "../system.reports.style";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-sheetingtype",
  templateUrl: "./sheetingType.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class SheetingTypeComponent implements OnInit {
  private currentUser: any;
  constructor(public systemReportsService: SystemReportsService, private authenticationService: AuthenticationService, private translateService: TranslateService, private reportStyle: SystemReportsStyle) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    console.log("Component init");
  }

  ExportSheetingTypeReport() {
    try {
      this.systemReportsService.getSheetCountReport().subscribe(
        (res) => {
          this.JSONToPDFConvertor(res, true);
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];

      if (res.tblSheetingType.length > 0) {
        JSONData = res.tblSheetingType;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertactivitybyreason.nodatafound"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const tableheader = [];
      const tablebody = [];
      let tablerow = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      // This condition will generate the Label/Header
      if (ShowLabel) {
        // This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          if (index === "sheetType") {
            tableheader.push({ text: this.translateService.instant("Systemreports.headersheetingtype.sheetingtype"), style: "tableHeader" });
          } else if (index === "quantity") {
            tableheader.push({ text: this.translateService.instant("Systemreports.headersheetingtype.quantity"), style: "tableHeader" });
          } else if (index === "percentage") {
            tableheader.push({ text: this.translateService.instant("Systemreports.headersheetingtype.percenttotal"), style: "tableHeader" });
          }
        }

        tablebody.push(tableheader);
      }

      // 1st loop is to extract each row
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrData.length; i++) {
        tablerow = [];

        // 2nd loop will extract each column and push in the table row
        for (const index in arrData[i]) {
          if (index === "sheetType") {
            switch (arrData[i][index]) {
              case "EG":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type1-enginner"),
                  style: "tableContent"
                });
                break;
              case "SE":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type2-superenginner"),
                  style: "tableContent"
                });
                break;
              case "HI":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type3-highintensity"),
                  style: "tableContent"
                });
                break;
              case "HIP":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type4-highintensityprismatic"),
                  style: "tableContent"
                });
                break;
              case "DGVIP":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type9-diamondvip"),
                  style: "tableContent"
                });
                break;
              case "DG3":
                tablerow.push({
                  text: this.translateService.instant("Systemreports.headersheetingtype.type11-diamonddg3"),
                  style: "tableContent"
                });
                break;
              case "Other":
                tablerow.push({ text: this.translateService.instant("Systemreports.headersheetingtype.other"), style: "tableContent" });
                break;
              default:
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
            }
          } else if (index === "percentage") {
            tablerow.push({ text: arrData[i][index] + "%", style: "tableContent" });
          } else {
            tablerow.push({ text: arrData[i][index], style: "tableContent" });
          }
        }

        // add a line break after each row
        tablebody.push(tablerow);
      }

      const totalValue = [
        {
          text: this.translateService.instant("Systemreports.headersheetingtype.totalactivesign"),
          style: "tableHeader"
        },
        {
          colSpan: 2,
          text: res.tblSheetingType1[0].total,
          style: "tableHeader"
        },
        ""
      ];
      tablebody.push(totalValue);
      const dd = {
        pageSize: "A4",
        pageOrientation: "portrait",
        fontSize: 18,
        footer: currentFooter,
        content: [
          {
            text: this.translateService.instant("Systemreports.headersheetingtype.countofsheetingtype"),
            style: "header"
          },
          {
            style: "tableExample",
            alignment: "justify",
            table: {
              widths: [200, 140, 140],
              heights: 20,
              headerRows: 1,
              body: tablebody
            },
            pageOrientation: "landscape",
            layout: currentLayoutStyle
          }
        ],
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
