<div *ngIf="showtask">
  <create-tasks [Graphic]="Graphic" (showTasks)="showTaksTab($event)" [FeatureLayer]="FeatureLayer"
    (taskDeleted)="loadFeatures()"  [asset]="selectedActiveAsset" [webMercatorUtils]="webMercatorUtils">
  </create-tasks>
</div>

<div class="" style="height:95%; padding-bottom:40px;">
  <div class="scroll-detail">
    <div  *ngIf="tasksData.length == 0" class="text-center no-data xxsmall">{{'Select.createtask.notaskmsg'|translate}}</div>

    <div *ngIf="tasksData.length >= 0">
      <div class="assessment-card" *ngFor="let task of tasksData">
        <div class="task-heading h7 flex-between">
          <div class="title">
            <mat-icon *ngIf="task.attributes.Status != 'Closed' else ElseBlock" aria-hidden="true" svgIcon="unlock"></mat-icon>
            <ng-template #ElseBlock>
               <mat-icon aria-hidden="true" svgIcon="lock"></mat-icon>
            </ng-template>
            {{(task.attributes.Activity) ? task.attributes.Activity : 'AssetViewerDetail.Task.NoneAvaialble'|translate}}
          </div>
          <div class="actions">
            <mat-icon style="text-align: center; margin-right:4px;" id="rollback" title="Activity Rollback"
              *ngIf="(this.RollBackObjectID === task.attributes.OBJECTID) && task.attributes.PendingChanges && currentUser.rolePermissions.assessmentEdit && currentUser.soultionTiers[0][0].activity_Rollback && findUserAccessByRoleRegionLayer()"
              (click)="rollBackAssets(task.attributes)"
              svgIcon="undo"
              aria-hidden="true">
            </mat-icon>
            <!-- TSDRSAM-1856 -->
            <mat-icon title="Edit" *ngIf="currentUser.rolePermissions.taskEditOpen && findUserAccessByRoleRegionLayer() && (task.attributes.Status == 'Closed') == false" (click)="task.attributes.Status != 'Closed' ? editTask(task.attributes) : false" svgIcon="edit"></mat-icon>
            <!-- TSDRSAM-1964 -->
            <mat-icon title="Delete" *ngIf="currentUser.rolePermissions.taskDeleteOpen && findUserAccessByRoleRegionLayer() && task.attributes.Activity != 'New' && task.attributes.Activity != 'DetailUpdate' && task.attributes.Status != 'Closed'"  (click)="task.attributes.Status != 'Closed' ? deleteTask(task.attributes) : false" svgIcon="delete-nav" ></mat-icon>
            <mat-icon [title]="task.attributes.Status != 'Closed' ? 'Complete' : 'Task Complete, no action required.'" *ngIf="currentUser.rolePermissions.taskCompleteOpen && findUserAccessByRoleRegionLayer() && (task.attributes.Status == 'Closed') == false" (click)="task.attributes.Status != 'Closed' ? completeTask(task.attributes) : false" svgIcon="check"></mat-icon>
          </div>
        </div>
        <div class="item-spacing xxsmall lighter-text">
          {{task.attributes.CompletionDate !=null ? (task.attributes.CompletionDate | date :'mediumDate') :((task.attributes.LastModifyDate !=null && task.attributes.CompletionDate ==null) ?  (task.attributes.LastModifyDate | date :'mediumDate') :(task.attributes.CreationDate | date :'mediumDate'))}}
        </div>
       
        <div *ngIf="task.attributes.Status == 'Closed'">
          <div class="xsmall item-spacing flex-between assessment-container" *ngFor="let field of closedfield">
            <div
              *ngIf="['CreationDate','CompletionDate','LastModifyDate','SheduledDate','StartTime','EndTime'].indexOf(field) === -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field] != null ? task.attributes[field] :'AssetViewerDetail.Task.NoneAvaialble'|translate}}</div>
            </div>
            <div *ngIf="['StartTime','EndTime'].indexOf(field) !== -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field]|date: 'HH:mm'}}</div>
            </div>        
            <div *ngIf="['CreationDate','CompletionDate','LastModifyDate','SheduledDate'].indexOf(field) !== -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field]|date: 'MM/dd/yyyy'}}</div>
            </div>
          </div>
        </div>
        <!-- Added by Sowjanya for JIRA ID:-66 on 08/19/2021 -->
        <div *ngIf="task.attributes.Status != 'Closed'">
          <div class="xsmall item-spacing flex-between assessment-container" *ngFor="let field of openfield">
            <div
              *ngIf="['CreationDate','CompletionDate','LastModifyDate','SheduledDate','StartTime','EndTime'].indexOf(field) === -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field] != null ? task.attributes[field] :'AssetViewerDetail.Task.NoneAvaialble'|translate}}</div>
            </div>
            <div *ngIf="['StartTime','EndTime'].indexOf(field) !== -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field]|date: 'HH:mm'}}</div>
            </div>
            <div *ngIf="['CreationDate','CompletionDate','LastModifyDate','SheduledDate'].indexOf(field) !== -1" class="light-text">{{field}}
              <div class="dark-text">{{task.attributes[field]|date: 'MM/dd/yyyy'}}</div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>

</div>
<div class="tasks-footer-controls" *ngIf="currentUser.rolePermissions.taskCreateGeneral && findUserAccessByRoleRegionLayer()">
  <button [ngClass]="{ 'btndisabled': activeAsset[0].DoNotReplace == 1}" [disabled]="(activeAsset[0].DoNotReplace == 1)? true : false" title="{{'Select.task.replacebtn'|translate}}" mat-button  class="h7 primary-button" (click)="replace()">{{'Select.task.replacebtn'|translate}}</button>
  <button title="{{'Select.task.retirebtn'|translate}}" mat-button class="primary-button h7" (click)="retire()">{{'Select.task.retirebtn'|translate}}</button>
  <button title="{{'Select.task.taskcreatebtn'|translate}}" mat-button class="primary-button h7"  (click)="createNew()">{{'Select.task.taskcreatebtn'|translate}}</button>
</div>