import { Component, OnInit, Input } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";

@Component({
  selector: "app-sms-set-defaults",
  templateUrl: "./set-defaults.component.html",
  styleUrls: ["./set-defaults.component.scss"]
})
export class SetDefaultsComponent implements OnInit {
  @Input() FeatureTable: any;
  @Input() FeatureLayer: any;
  activeTab = 1;
  @Input() map: any;
  @Input() Graphic;
  @Input() Query: any;
  @Input() QueryTask: any;
  public currentUser: IUser;
  constructor(private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.activeTab = 1;
  }
}
