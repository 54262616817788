import { Component } from "@angular/core";
@Component({
  selector: "sms-cell-renderer",
  template: `<a href="#" (click)="onClick($event)">{{ label }}</a>`
})
export class CellRenderComponent {
  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.value || null;
  }

  /**put anything into params u want pass into parents component */
  onClick($event) {
    try {
      const params = {
        event: $event,
        rowData: this.params.value
      };
      this.params.onClick(params.rowData);
    } catch (error) {
      console.error(error);
    }
  }
}
