<div class="selectTab col-12 bg-primary card" role="main" aria-labelledby="assetTable" id="assetTable">
  <app-heading-component
    [headingText]="'gridView.assetTableView' | translate"
    [variant]="'none'"
    (actionThree)="closeGrid()"
    [iconThree]="getCloseIcon()"
    [tooltipThree]="'Close'"
    [tooltipTwo]="'Add'"
    [tooltipOne]="'Download'"
    [iconTwo]="getAddIcon()"
    [iconTwoDropdown]="true"
    [isGridHeader]="true"
    [scndlyr]="this.scndlyr"
    [iconOne]="getDownloadIcon()"
    (actionOne)="exportExcel()"
    (DropdownListItem)="columnVisibilityCheck($event)"
    [columnsFieldsList]="columnsFieldsList"
    (selectedFormat)="onFormatChange($event)"
  >
  </app-heading-component>

  <div class="row">
    <div class="col-12">
      <div>
        <!-- TSDRSAM-1421 -->
        <mat-tab-group disablePagination="true" [(selectedIndex)]="activeTabId" (selectedTabChange)="tabChangedFromUI($event)">
          <mat-tab *ngFor="let item of menuItems; let i = index" [label]="scndlyr && item.Data.length > 0 ? item.LayerName.tableSL : item.LayerName.tableAlias">
            <div class="tab-content">
              <!-- Added by Abhilash Jira Id:-40 on 9/28/2021 -->
              <div class="tab-pane active" id="home" *ngIf="columnDefsselFeature.length > 0" role="tabpanel">
                <!-- <div id="attrGridMainContent" class="attrGridMainDiv"></div> -->
                <!-- // TSDRSAM-1090 By Manikantha V -->
                <ag-grid-angular
                  #selFeatureGrid
                  style="width: 100%;height:229px !important;"
                  class="ag-theme-balham mtTableHeight"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [frameworkComponents]="frameworkComponents"
                  [defaultColDef]="defaultColDef"
                  [rowData]="selFeaturerowData"
                  [columnDefs]="columnDefsselFeature"
                  (gridReady)="onGridReadyOpen($event)"
                  [rowStyle]="rowStyle"
                  [rowHeight]="32"
                  (selectionChanged)="onSelectionChangedOpen($event)"
                  [rowSelection]="rowSelection"
                  (cellClicked)="onCellClicked($event, content)"
                  (rowDataChanged)="rowDataChanged()"
                >
                </ag-grid-angular>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<!-- document viewer -->
<!-- Added by Abhilash Jira Id:-40 on 9/28/2021 -->
<ng-template #content let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ "Select.photoviewer.header" | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body sms-model-imageviewer">
    <a href="#" class="btn btn-secondary" id="Date" data-toggle="collapse" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" (click)="OrderImages('Date')">
      {{ "Select.docassessment.datefilter" | translate }}
      <i class="fa fa-lg" [ngClass]="{ 'fa-angle-up': showDateByOrder, 'fa-angle-down': !showDateByOrder }" aria-hidden="true"> </i>
    </a>
    <a href="#" class="btn btn-secondary" id="Sequence" *ngIf="linearAssetValues && currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('Measure')">
      {{ "Select.docassessment.sequencefilter" | translate }}
      <i class="fa fa-lg" [ngClass]="{ 'fa-angle-up': showSequenceByOrder, 'fa-angle-down': !showSequenceByOrder }" aria-hidden="true"> </i>
    </a>
    <a href="#" class="btn btn-secondary" id="Source" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('SourceTag')">
      {{ "Select.docassessment.sourcefilter" | translate }}
      <i class="fa fa-lg" [ngClass]="{ 'fa-angle-up': showSourceByOrder, 'fa-angle-down': !showSourceByOrder }" aria-hidden="true"> </i>
    </a>
    <form fxLayout="column" class="mb">
      <mat-button-toggle-group #group="matButtonToggleGroup" [value]="imageSrc" (change)="EventChange($event)">
        <mat-button-toggle *ngFor="let sample of samples; let i = index" color="accent" [value]="sample.url"> {{ sample.label }}</mat-button-toggle>
      </mat-button-toggle-group>
    </form>
    <!-- <app-image-viewer *ngIf="imgBase64 !== ''" [images]="[imgBase64]" [idContainer]="'idOnHTML'" [showOptions]="false"
      [showPDFOnlyOption]="false" [loadOnInit]="true">
    </app-image-viewer> -->
    <img *ngIf="imageSrc !== '' && !imageSrc.includes('application/pdf')" imageSrc [src]="imageSrc" />
    <ng-container *ngIf="imageSrc.includes('application/pdf')"><pdf-viewer [src]="imageSrc" [render-text]="true" [original-size]="false" style="width: 400px; height: 500px"></pdf-viewer></ng-container>
    <input type="file" accept=".jpg, .jpeg, .pdf" #assetPhotoUpload class="d-none" (change)="uploadNew(assetPhotoUpload.files)" aria-label="select file to upload" />
  </div>
  <div class="modal-footer">
    <div class="mr-auto photo-date">{{ "Select.docassessment.photodate" | translate }} : {{ PhotoDate | date : "medium" }}</div>
    <div class="mr-auto photo-date">{{ "Select.docassessment.sourceandcondition" | translate }} : {{ SourceTag }}</div>
    <div class="mr-auto photo-date" *ngIf="linearAssetValues">{{ "Select.docassessment.measureinterval" | translate }}: {{ MeasureValue }}</div>
    <div class="justify-content-end">
      <button aria-label="Upload New" *ngIf="currentUser.rolePermissions.documentAdd && findUserAccessByRoleRegionLayerVar" type="button" class="btn btn-secondary" (click)="assetPhotoUpload.click()">
        {{ "Select.photoviewer.uploadbtn" | translate }}
      </button>
      <button aria-label="Delete" *ngIf="currentUser.rolePermissions.documentRemove && findUserAccessByRoleRegionLayerVar" type="button" class="btn btn-secondary" (click)="deleteImage()">
        {{ "Select.photoviewer.deletebtn" | translate }}
      </button>
      <button aria-label="Export" type="button" class="btn btn-secondary" (click)="export()">{{ "Select.photoviewer.exportbtn" | translate }}</button>
    </div>
  </div>
</ng-template>
