import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { GenericLayerService } from "../services/genericLayer.service";
import { AuthenticationService } from "../services/authentication.service";
@Directive()
@Injectable()
export class CrewManagementService {
  @Output() public tasksModified: EventEmitter<any> = new EventEmitter();
  @Output() public regions: EventEmitter<any> = new EventEmitter();
  @Output() public layers: EventEmitter<any> = new EventEmitter();
  @Output() public records: EventEmitter<any> = new EventEmitter();
  public selectedRegion: any;
  public selectedLayer: any;
  public selectedDate: Date;
  public regionsList: any = [];
  public layersList: any = [];
  public totalrecords: any = [];
  Primarylangvalid: any;
  Secodarylangvalid: any;
  constructor(private genericLayerService: GenericLayerService, private authenticationService: AuthenticationService) {
    this.getRegions();
    this.genericLayerService.getClientList(this.authenticationService.currentUserValue.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
      this.getLayers();
    });
    this.getRecords();
  }
  getRegions() {
    this.genericLayerService.getAllFeatures(this.authenticationService.currentUserValue.serviceLyrInfo.regionId).subscribe((regionsRes: any) => {
      const regionsSet = new Set();
      regionsRes.features.forEach((result) => {
        if (result) {
          if (result.attributes.RegionName4 != null) {
            regionsSet.add(result.attributes.RegionName4);
          }

          if (result.attributes.RegionName3 != null) {
            regionsSet.add(result.attributes.RegionName3);
          }

          if (result.attributes.RegionName2 != null) {
            regionsSet.add(result.attributes.RegionName2);
          }
        }
      });
      this.regionsList = Array.from(regionsSet).sort();
      const it = this.regionsList.values();
      const firstregion = it.next();

      if (!this.selectedRegion) {
        this.selectedRegion = firstregion.value;
      }

      this.regions.emit(true);
    });
  }
  getLayers() {
    this.genericLayerService.getAllFeatures(this.authenticationService.currentUserValue.serviceLyrInfo.tableAliasId).subscribe((regionsRes: any) => {
      //  this.filterUniqLayersList(regionsRes);
      const assetClass = [];
      regionsRes.features.forEach((result) => {
        if (result) {
          if (result.attributes.GroupType === "Parent Table" || result.attributes.GroupType === "Child Table") {
            if (this.Primarylangvalid === this.authenticationService.currentUserValue.languageID) {
              console.log("primary");
              assetClass.push({ Name: result.attributes.Name, Alias: result.attributes.Alias });
            } else if (this.Secodarylangvalid === this.authenticationService.currentUserValue.languageID) {
              console.log("secondary");
              assetClass.push({ Name: result.attributes.Name, Alias: result.attributes.Alias_SL });
            } else {
              console.log("tertiary");
              assetClass.push({ Name: result.attributes.Name, Alias: result.attributes.Alias });
            }
          }
        }
      });
      this.layersList = assetClass.sort();

      if (!this.selectedLayer) {
        this.selectedLayer = this.layersList[0].Name;
      }

      this.layers.emit(true);
    });
  }
  getRecords() {
    const SheduledDate = new Date().toLocaleDateString();
    const tomorrow = new Date(SheduledDate);
    tomorrow.setDate(tomorrow.getDate() + 2);
    // const SheduledNextDate = tomorrow.toLocaleDateString();
    // const strCond =
    //   "SheduledDate >'" +
    //   SheduledDate +
    //   "' and SheduledDate <'" +
    //   SheduledNextDate +
    //   "'" +
    //   "and EstimatedDuration is not null and EstimatedDuration > 0 and  GeomWKT  is not null";
    const strCond1 = "EstimatedDuration is not null and EstimatedDuration > 0 and  GeomWKT  is not null";
    this.genericLayerService.getFeaturesByCond(this.authenticationService.currentUserValue.serviceLyrInfo.maintenanceId, strCond1).subscribe((results: any) => {
      this.totalrecords = results.features;
      this.records.emit(this.totalrecords);
    });
  }
}
