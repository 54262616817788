import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { adminGlobal } from "src/app/administrator/adminGlobal";

@Component({
  selector: "sms-administrator",
  templateUrl: "./administrator.component.html",
  styleUrls: ["./administrator.component.scss"]
})
export class AdministratorComponent implements OnInit {
  constructor(public adminGlobal: adminGlobal) {}
  @Output() data = new EventEmitter();
  active = 1;
  ngOnInit(): void {
    //console.log("init");
  }

  toggleNavbar(event): void {
    console.log(event.target.id);
  }
  showQueryData($event): void {
    this.data.emit($event);
  }
}
