import { environment as base } from "./environment.base";

export const environment = {
    ...base,
    production: true,

    apiBaseUrl: "https://rsamuat.3m.com/",
    baseHref: "/RSAMUIQA",
    apiroot: "RSAMAPIQA/",
    iconroot: "RSAMUIQA/",
    apiwebroot: "RSAMUIQA/",
    helproot: "RSAMHelpQA/",
    gpService: "https://rsamuat.3m.com/arcgis/rest/services/RSAMGeoProc/ExportByRegion/GPServer/ExportByRegion",
    proxy: "https://rsamuat.3m.com/gisproxy/proxy.ashx?",
    customReport:"https://rsamuat.3m.com/RSAMUIQA/",
    customerNameService:"https://rsamuat.3m.com/arcgis/rest/services/3M/CustomerSetup/GPServer"  
};
  