import { Component, OnInit, Input, HostListener } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsStyle } from "../system.reports.style";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-brushpickup",
  templateUrl: "./brushPickup.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class BrushPickupComponent implements OnInit {
  @Input() map;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public todayDate: Date = new Date();
  faCalendar = faCalendar;
  public currentUser: any;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if((['span','input']).includes(event.target['localName']) &&
       (['brushRepCalInput1','brushRepCalSpan1','brushRepCalInput2','brushRepCalSpan2']).includes(event.target['id'])){
      (<HTMLInputElement>event.target).click();
    }
    else if(event.target['localName'] === 'button'){
      (<HTMLInputElement>event.target).click();
    }
    console.log("From BrushPickup Keydown...");
  }
  //
  constructor(
    public systemReportsService: SystemReportsService,
    private authenticationService: AuthenticationService,
    private genericService: GenericLayerService,
    private translateService: TranslateService,
    private reportStyle: SystemReportsStyle
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate());
  }

  IsValidDate(inputDate: Date): boolean {
    try {
      if (Object.prototype.toString.call(inputDate) === "[object Date]") {
        if (isNaN(inputDate.getTime())) {
          return false;
        }

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
    }
  }

  openReports() {
    try {
      if (this.IsValidDate(this.startDate) && this.IsValidDate(this.endDate)) {
        if (this.startDate >= this.endDate) {
          Swal.fire(this.translateService.instant("Systemreports.alertemployeelog.fromtodatevalidate"));
          return;
        }

        const data = "?fromDate=" + this.startDate.toLocaleString().split(",")[0] + "&ToDate=" + this.endDate.toLocaleString().split(",")[0];
        this.systemReportsService.getBrushPickupData(data).subscribe(
          (res) => {
            this.JSONToPDFConvertor(res, true);
          },
          (err) => {
            Swal.fire("Please contact administrator. API is throwing an error");
          }
        );
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertbrushpickup.datevalidate"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];

      if (res.tblBrushPickupData.length > 0) {
        JSONData = res.tblBrushPickupData;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertretireyrassetclass.nodata"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const tableheader = [];
      const tablebody = [];
      let tablerow = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      // This condition will generate the Label/Header
      if (ShowLabel) {
        // This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          if (index !== "activities") {
            switch (index) {
              case "assetID":
                tableheader.push({ text: this.translateService.instant("Systemreports.headerbrushpickup.assetid"), style: "tableHeader" });
                break;
              case "route":
                tableheader.push({ text: this.translateService.instant("Systemreports.headerbrushpickup.route"), style: "tableHeader" });
                break;
              case "crossStr":
                tableheader.push({ text: this.translateService.instant("Systemreports.headerbrushpickup.crossstr"), style: "tableHeader" });
                break;
              case "crossStrDist":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerbrushpickup.crossstrdist"),
                  style: "tableHeader"
                });
                break;
              case "notes":
                tableheader.push({ text: this.translateService.instant("Systemreports.headerbrushpickup.notes"), style: "tableHeader" });
                break;
              default:
                tableheader.push({ text: index, style: "tableHeader" });
                break;
            }
          }
        }

        tablebody.push(tableheader);
      }

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrData.length; i++) {
        tablerow = [];

        // 2nd loop will extract each column and push in the table row
        for (const index in arrData[i]) {
          if (index !== "activities") {
            tablerow.push({ text: arrData[i][index], style: "tableContent" });
          }
        }

        // add a line break after each row
        tablebody.push(tablerow);
      }

      const dd = {
        pageSize: "A4",
        pageOrientation: "landscape",
        fontSize: 18,
        footer: currentFooter,
        content: [
          {
            text: this.translateService.instant("Systemreports.headerbrushpickup.reportheader"),
            style: "header"
          },
          {
            stack: [
              {
                text: [
                  {
                    text: this.translateService.instant("Systemreports.brushpickup.fromdate") + ":" + "\xa0",
                    bold: true
                  },
                  {
                    text: this.startDate.toLocaleString().split(",")[0]
                  },
                  {
                    text: "\xa0" + this.translateService.instant("Systemreports.brushpickup.todate") + ":" + "\xa0",
                    bold: true
                  },
                  {
                    text: this.endDate.toLocaleString().split(",")[0]
                  }
                ]
              }
            ],
            style: "subheader"
          },
          {
            style: "tableExample",
            alignment: "justify",
            table: {
              widths: [90, 110, 210, 90, 200],
              heights: 15,
              headerRows: 1,
              body: tablebody
            },
            pageOrientation: "landscape",
            layout: currentLayoutStyle
          }
        ],
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
