import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "app-heading-component",
  templateUrl: "./heading.component.html",
  styleUrls: ["./heading.component.scss"],
  viewProviders: [MatExpansionPanel]
})
//TODO : ADD SUPPORTED ICONS
export class HeadingComponent implements OnInit {
  //don't force extra functionality if heading is too different.
  //try not to make it so generic
  @Input() headingText: string;
  //not needed
  @Input() variant: string;

  @Input() iconOne: string;
  @Input() iconTwo: string;
  //specify which icon it is if it's always there (pass in a bool for close)
  @Input() iconThree: string;
  @Input() iconFour: string;

  @Input() tooltipOne : string;
  @Input() tooltipTwo : string;
  @Input() tooltipThree : string;
  @Input() tooltipFour : string;
  @Input() reshapeTooltip : string;
  //specific to dropdown
  @Input() tableAliases;
  @Input() signAddAccess: boolean;
  @Input() lightAddAccess: boolean;
  @Input() iconTwoDropdown = false;
  //icon actions
  @Output() actionOne = new EventEmitter();
  @Output() actionTwo = new EventEmitter();
  @Output() actionThree = new EventEmitter();
  @Output() actionFour = new EventEmitter();
  @Output() reshape = new EventEmitter();
  //???
  @Output() relAssetSelect = new EventEmitter();
  //dropdown action
  @Output() dropdownAction = new EventEmitter<string>();

  //TSDRSAM-1421
  @Output() DropdownListItem = new EventEmitter<{event: any,columnfield:any}>();
  @Input() columnsFieldsList;
  isAssetViewer = false;
  @Input() isGridHeader: boolean = false;
  @Input() scndlyr: boolean;
  selected = 'CSV';
  @Output() selectedFormat = new EventEmitter<string>();
  //TSDRSAM-1423
  @Input() checkboxChecked :boolean =false;
  @Input() checkboxlabel1: string;
  @Output() checkboxClick = new EventEmitter<boolean>();
  @Input() isTaskViewerHeader: boolean = false;
  @Input() isCardView: boolean = false;

  //TSDRSAM-1363
  @Input() isCreateEditTaskHeader: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
    console.log(this.iconOne);
  }

  dropdownSelect(type: string): void {
    this.dropdownAction.emit(type);
  }

  iconOneClick($event): void {
    this.actionOne.emit($event);
  }

  iconTwoClick(): void {
    this.actionTwo.emit();
  }

  iconThreeClick(): void {
    this.actionThree.emit();
  }

  iconFourClick():void{
    this.actionFour.emit();
  }

  columnVisibilityCheck(event: any,columnfield: any):void
  {
    this.DropdownListItem.emit({event,columnfield});
  }

  onChange(event)
  {
    this.selectedFormat.emit(event);
    this.selected = event; //TSDRSAM-1804
  }

  onCheckboxChange(event)
  {
    this.checkboxClick.emit(event);
  }
}
