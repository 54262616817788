import { Component, OnInit, Input, Output, EventEmitter, Renderer2, HostListener } from "@angular/core";
import { AssetDetailService } from "../../../services/assetDetail.service";
import { AssetAttributesService } from "../../../services/assetAttibutes.service";
import Swal from "sweetalert2";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { QueryLayers } from "../../../services/queryLayers.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { TranslateService } from "@ngx-translate/core";
import { CardViewService } from "src/app/services/card-view.service";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { EventEmitersService } from "../../../services/eventEmiters.service";
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from "@angular/core";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { faPlus, faMinus, faUndo, faArrowRight, faArrowLeft, faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { ImageCompressService, ResizeOptions } from "@artiomsu/ng2-image-compress";
import { BASE64_PREFIX, getMimeType } from "../../../shared/utils";
import { GenericMapMethodsService } from "src/app/services/generic-map-methods.service";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../globals";
@AutoUnsubscribe()
@Component({
  selector: "sms-assetDetail",
  templateUrl: "./assetDetail.component.html",
  styleUrls: ["../../select.component.scss"],
  styles: [
    `
      :host::ng-deep .ng-select .ng-select- {
        background-color: #f3f5fd;
        background-clip: padding-box;
        border: 0.5px solid #e6ebfa;
        border-radius: 0.25rem;
      }
      :host::ng-deep .redBorder.ng-select .ng-select-container {
        border-color: red !important;
        background-color: #f3f5fd;
        background-clip: padding-box;
        border: 0.5px solid #e6ebfa;
        border-radius: 0.25rem;
      }
      :host::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
        background-color: #ffffff !important;
        top: 10px;
        text-align: left;
      }
    `
  ]
})
export class AssetDetailComponent implements OnInit {
  //Icons
  faPlus = faPlus;
  faMinus = faMinus;
  faUndo = faUndo;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faArrowsAlt = faArrowsAlt;
  /** End */

  /** Variables */
  @Output() checkIfNewAsset = new EventEmitter();
  @Output() closeAssetDetailCard = new EventEmitter();
  @Output() closeCard = new EventEmitter();
  @Output() assetUpdated = new EventEmitter();
  @Output() assetAttributes = new EventEmitter();
  @Input() activeAsset: any;
  @Input() map: any;
  @Input() AI: any;
  @Input() Editor: any;
  @Input() Query: any;
  @Input() QueryTask: any;
  @Input() signLayer: any;
  @Input() featureLayer: any;
  @Input() asset: any;
  @Input() relatedAsset: any;
  @Input() webMercatorUtils: any;
  @Input() Edit;
  @Input() Draw: any;
  @Input() FeatureLayer;
  @Input() Graphic: any;
  @Input() isAssetNew: boolean;
  @Input() isDeleteClicked: boolean;
  @Input() geometryEngine: any;
  @Input() Polygon: any;
  @Input() geometryJsonUtils: any;
  @Input() ismodelpopup: boolean;
  @Input() Point: any;
  @Output() showTasks = new EventEmitter();
  @Input() selectedLayer: any;
  @Input() rowdataobj: any;
  @Input() activeParentAsset: any;
  @Input() newChildFClass: any;
  @Input() newChildFeature: any;
  //Added for visualization layers
  @Input() isVisualizationAsset: any;
  @Input() currentLayerData: any;
  //
  public Primarylangvalid;
  public Secodarylangvalid;
  public scndlyr = false;
  objSignLayer;
  isAssetSaved = false;
  activeAssetID: any;
  AttrInspect: any; // store asset values
  asstDtlTitle: any;
  asstDtlSubTitle: any;
  loading = false;
  drawTool: any;
  loadValues: any;
  showrelocate = false;
  result;

  expand = this.translateService.instant("Select.carddisplay.expand");
  minimize = this.translateService.instant("Select.carddisplay.minimize");
  layersfields;
  photosData = [];
  modalReference: NgbModalRef;
  currentUser: IUser;
  requiredFields: any = [];
  validations = -1;
  attributeChanged = false;
  public linearAssetValues = false;
  SelectedValueFromDoc;
  DatatItemsFromDoc;
  samples: any;
  imageSrc: any;
  ImageButtonTitle: any;
  PhotoDate;
  SourceTag;
  MeasureValue;
  assetPlacedinRegion: any;
  fieldname;
  activeLayerDomines;
  dataEditorSettings;
  showDateByOrder = false;
  showSequenceByOrder = false;
  showSourceByOrder = false;
  photoFl;
  ftrLyrTblMaintenance;
  ftrLyrActivity;
  assetCodeFrmAssetSubType;
  rollbackData = {};
  public oldAssetAttribute;
  assetTypeId;
  isShown = true; // hidden by default
  public temp = [];
  findUserAccessByRoleRegionLayerVar = false;
  faCalendar = faCalendar;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  selectedActiveAsset;
  showtask = false;

  public imgBase64: any;
  fieldsInfo = [];
  defaultDisabledFields = [];
  customerDiffer: KeyValueDiffer<string, any>;
  imageConfig = {
    btnClass: "default", // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: "#ccc", // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: "faPlus",
      zoomOut: "faMinus",
      rotateClockwise: "fa fa-repeat",
      rotateCounterClockwise: "faUndo",
      next: "faArrowRight",
      prev: "faArrowLeft",
      fullscreen: "faArrowsAlt"
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: true,
      prev: true
    }
  };

  emitCreateTaskOpened;
  isCreateTaskOpen: any;

  activeTabName = "panDetail";
  public editTool;
  emitactiveAssetFeature;
  uploadfileName;
  disableSavebtn = true;
  findUserAccessByRoleRegionLayerVal = false;
  getClientListSubscribe: Subscription;
  getAllFeaturesSubscribe: Subscription;
  emittaskRollbackSubscribe: Subscription;
  getAssetAttributesSubscribe: Subscription;
  deleteRelFeaturesByCondSubscribe: Subscription;
  getFilteredFeaturesByFieldSubscribe: Subscription;
  uploadImageSubscribe: Subscription;
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["localName"] === "div" && (event.target["id"] == "assetDetailsViewClose" || event.target["id"] == "assestViewerbtn1")) {
      (<HTMLInputElement>event.target).click();
    } else if (["span", "input"].includes(event.target["localName"]) && ["editorCalInput1", "editorCalSpan1", "editorCalInput2", "editorCalSpan2"].includes(event.target["id"])) {
      (<HTMLInputElement>event.target).click();
    } else if (event.target["localName"] === "button") {
      (<HTMLInputElement>event.target).click();
    }
    console.log("From AssetDetail Keydown...");
  }
  //
  constructor(
    public objAssetDetailService: AssetDetailService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private assetAttributesService: AssetAttributesService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    public toast: ToastrService,
    private authenticationService: AuthenticationService,
    private query: QueryLayers,
    private cs: CardViewService,
    private genericLayerService: GenericLayerService,
    public esri: EsriMapComponent,
    public eventEmitersService: EventEmitersService,
    private differs: KeyValueDiffers,
    private imageCompressService: ImageCompressService,
    private genericMapMethodService: GenericMapMethodsService,
    public objGlobals: Globals
  ) {
    this.dataEditorSettings = JSON.parse(localStorage.getItem("dataEditorSettings"));
    this.currentUser = this.authenticationService.currentUserValue;

    config.backdrop = "static";
    config.keyboard = false;
    this.getClientListSubscribe = this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
      if (this.Primarylangvalid === this.currentUser.languageID) {
        this.scndlyr = false;
      } else if (this.Secodarylangvalid === this.currentUser.languageID) {
        this.scndlyr = true;
      }
    });
    this.getAllFeaturesSubscribe = this.genericLayerService.getAllFeatures(this.currentUser.serviceLyrInfo.fieldsNonEditableByUserId).subscribe(
      function (nonEfields: any) {
        this.defaultDisabledFields = [];
        nonEfields.features.forEach((element) => {
          this.defaultDisabledFields.push(element.attributes.FieldName.toLowerCase());
        });
      }.bind(this)
    );
    this.emitactiveAssetFeature = this.eventEmitersService.emitactiveAssetFeature.subscribe((actveAsset: any) => {
      console.log("New active asset feature emmitted from event service");
      this.activeAsset = actveAsset;
      if (!this.scndlyr) {
        this.asstDtlTitle = this.activeAsset[1].tableAlias;
        this.asstDtlSubTitle = this.activeAsset[1].tableAlias.toLowerCase();
      } else if (this.scndlyr) {
        this.asstDtlTitle = this.activeAsset[1].tableSL;
        this.asstDtlSubTitle = this.activeAsset[1].tableSL.toLowerCase();
      }
      this.initAssetDetail();
    });
    this.emittaskRollbackSubscribe = this.eventEmitersService.emittaskRollback.subscribe((res) => {
      this.initAssetDetail();
      setTimeout(() => {
        this.disableSavebtn = true;
      }, 2000);
    });
    // Added by Sowjanya Jira Id:-24 on 11/02/2021
    this.eventEmitersService.eventassessmentnewAssessment.subscribe(
      function (res) {
        console.log(res);
        //this.featureLayer = this.activeAsset[1].lyrObj;
        this.activeAsset[2]["attributes"]["RetroAssessmentRating"] = res;
        this.activeAsset[2]["attributes"]["ReplaceYear"] = this.eventEmitersService.getCalculatedReplaceYear(this.activeAsset[1].layerName, this.activeAsset[2]);
        this.query.updateAsset(this.activeAsset[1].lyrObj, this.activeAsset[2]);
      }.bind(this)
    );
    //Added for visualization layers
    //TSDRSAM-1788
    this.eventEmitersService.emitShowVisualizationDetails.subscribe((res) => {
      if (res.isVisualizationAsset) {
        this.asstDtlTitle = res.selectedLayer;
        this.activeAsset = res.currentLayerData;
        return;
      }
    });

    this.emitCreateTaskOpened = this.eventEmitersService.emitTaskCreateFormOpened.subscribe((isCreateTaskOpen) => {
      this.isCreateTaskOpen = isCreateTaskOpen;
    });
  }

  ngAfterViewInit() {
    if (document.getElementById("idpanDetail")) document.getElementById("idpanDetail").addEventListener("click", this.selectedTab);
    if (document.getElementById("idpanTask")) document.getElementById("idpanTask").addEventListener("click", this.selectedTab1);
    if (document.getElementById("idpanAssessment")) document.getElementById("idpanAssessment").addEventListener("click", this.selectedTab2);
  }
  selectedTab() {
    this.activeTabName = "panDetail";
    return true;
  }
  selectedTab1() {
    this.activeTabName = "panTask";
    return true;
  }
  selectedTab2() {
    this.activeTabName = "panAssessment";
    return true;
  }
  ngOnDestroy() {
    this.emitactiveAssetFeature.unsubscribe();
  }
  ngOnInit() {
    //Added for visualization layers
    if (this.isVisualizationAsset) {
      this.asstDtlTitle = this.selectedLayer;
      this.activeAsset = this.currentLayerData;
      this.getAssetAttributesSubscribe = this.getAssetAttributesSubscribe = this.assetAttributesService.getAssetAttributes().subscribe((results) => {
        this.result = results;
        this.layersfields = [];
        this.result.features.forEach((element) => {
          this.layersfields.push(element.attributes);
          this.requiredFields.push(element.attributes.IsRequired === 0);
        });
      });
      return;
    }

    
    this.activeAsset = this.eventEmitersService.activeAssetFeature;
    if(this.eventEmitersService.activeAssetFeature){
      this.oldAssetAttribute = Object.assign({}, this.eventEmitersService.activeAssetFeature[0]);
    }
    if (!this.scndlyr && this.activeAsset) {
      this.asstDtlTitle = this.activeAsset[1].tableAlias;
      this.asstDtlSubTitle = this.activeAsset[1].tableAlias.toLowerCase();
    } else if (this.scndlyr && this.activeAsset) {
      this.asstDtlTitle = this.activeAsset[1].tableSL;
      this.asstDtlSubTitle = this.activeAsset[1].tableSL.toLowerCase();
    }
    if (this.currentUser.rolePermissions.roleName === "Readonly") {
      this.ImageButtonTitle = this.translateService.instant("Select.carddisplay.imageviewerbtnRead");
    } else {
      this.ImageButtonTitle = this.translateService.instant("Select.carddisplay.imageviewerbtn");
    }
    this.photoFl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);
    // Get Active Language for User
    this.getClientListSubscribe = this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      if (res.primaryLanguageID === this.currentUser.languageID) {
        this.scndlyr = false;
      } else if (res.secondaryLanguageID === this.currentUser.languageID) {
        this.scndlyr = true;
      }
    });
    // End
    this.getAssetAttributesSubscribe = this.getAssetAttributesSubscribe = this.assetAttributesService.getAssetAttributes().subscribe((results) => {
      this.result = results;
      this.layersfields = [];
      this.result.features.forEach((element) => {
        this.layersfields.push(element.attributes);
        this.requiredFields.push(element.attributes.IsRequired === 0);
      });
      this.initAssetDetail();
    });
    this.isDeleteClicked = true;
    this.disableSavebtn = true;
    if(this.activeAsset){
      this.customerDiffer = this.differs.find(this.activeAsset[0]).create();
    }
    //Jira Id 935
    this.objAssetDetailService.getAssetIDFormat(this.Query, this.QueryTask, this.FeatureLayer);
    console.log(this.fieldsInfo);
  }
  customerChanged(changes: KeyValueChanges<string, any>): void {
    console.log("customerChanged");
    const namearray = [];
    let changedItems = 0;
    changes.forEachChangedItem((item) => {
      if (!this.findFieldisDisable(item.key)) {
        changedItems++;
      }
      namearray.push(item.key);
    });

    if (!namearray.includes("GlobalID")) {
      if (changedItems >= 1) {
        this.disableSavebtn = false;
      } else {
        this.disableSavebtn = true;
      }
    }
  }

  ngDoCheck(): void {
    if (!this.isVisualizationAsset && this.activeAsset != null && this.activeAsset.length > 0) {
      //Added for visualization layers
      if (this.customerDiffer) {
        const changes = this.customerDiffer.diff(this.activeAsset[0]);
        if (changes) {
          this.customerChanged(changes);
        }
      }
    }
  }
  closeAssetDetail() {
    try {
      if (this.attributeChanged || this.eventEmitersService.addNewParent || this.eventEmitersService.addNewChild) {
        Swal.fire({
          title: this.translateService.instant("Select.alertcarddisplay.unsaveddata"),
          text: this.translateService.instant("Select.alertcarddisplay.continueedit"),
          showCancelButton: true,
          customClass: "confirmation-popup",
          cancelButtonText: this.translateService.instant("CreateAsset.button.Cancel"),
          confirmButtonText: this.translateService.instant("Select.alertcarddisplay.continue")
        }).then((result) => {
          if (result.value) {
            if (this.eventEmitersService.addNewParent) {
              this.isDeleteClicked = false;
              this.eventEmitersService.closeModelWindow();
              this.deleteAsset();
            }
            if (this.eventEmitersService.addNewChild) {
              this.isDeleteClicked = false;
              //child asset having prolem with close so below line commented
              //this.eventEmitersService.closeModelWindow();
              this.deleteAsset();
            }
          }
        });
      } else {
        //Investigattion needed why below code added
        // if (!this.disableSavebtn) {
        //   this.eventEmitersService.activeAssetFeature[0] = this.oldAssetAttribute;
        //   this.activeAsset = this.eventEmitersService.activeAssetFeature;
        // }
        if (this.closeAssetDetailCard.observers != null) {
          this.closeAssetDetailCard.emit(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  initAssetDetail() {
    try {
      this.activeAsset = this.eventEmitersService.activeAssetFeature;
      this.findUserAccessByRoleRegionLayer();
      this.assetCodeFrmAssetSubType = [];
      
      if (this.result) {
        let columns = [];
        if(this.activeAsset && this.activeAsset[1]){
          columns = this.result.features.filter(
            (feature) =>
              //Jira Id-933
              feature.attributes.TableName.toLowerCase() === this.activeAsset[1].tableName.toLowerCase()
          );
        }
        const fieldInfosdata = [];
        columns.forEach((element) => {
          if (element.attributes.DetailsVisible) {
            this.fieldname = element.attributes.Field;
            let Fieldtype;
            let CodedValues = [];
            let codedValuesIsAvailable = false;
            for (let ind = 0; ind < this.activeAsset[1].lyrObj._fields.length; ind++) {
              if (this.activeAsset[1].lyrObj._fields[ind].name === this.fieldname) {
                Fieldtype = this.activeAsset[1].lyrObj._fields[ind].type;

                if (this.activeAsset[1].lyrObj._fields[ind].domain) {
                  codedValuesIsAvailable = true;
                  CodedValues = this.activeAsset[1].lyrObj._fields[ind].domain.codedValues;
                }
              }
            }

            if (element.attributes.Field === "AssetSubType") {
              if (this.activeAsset[1].lyrObj.types[0] && this.activeAsset[1].lyrObj.types[0].templates[0].prototype.attributes.AssetSubType === 0) {
                fieldInfosdata.push({
                  fieldName: element.attributes.Field,
                  IsRequired: element.attributes.IsRequired,
                  labelSL: element.attributes.FieldAlias_SL,
                  label: element.attributes.FieldAlias,
                  type: "esriFieldTypeString",
                  // Added by Abhilash for JIRA ID:-38 on 08/18/2021
                  FieldOrder: element.attributes.FieldOrder,
                  codedValues: this.activeAsset[1].lyrObj.types
                });
              } else {
                //Below logic implemented to see empty dropdown if coded values are empty,additional asset changes
                if (codedValuesIsAvailable) {
                  fieldInfosdata.push({
                    fieldName: element.attributes.Field,
                    IsRequired: element.attributes.IsRequired,
                    labelSL: element.attributes.FieldAlias_SL,
                    label: element.attributes.FieldAlias,
                    type: "esriFieldTypeSmallInteger",
                    // Added by Abhilash for JIRA ID:-38 on 08/18/2021
                    FieldOrder: element.attributes.FieldOrder,
                    codedValues: this.activeAsset[1].lyrObj.types
                  });
                } else {
                  fieldInfosdata.push({
                    fieldName: element.attributes.Field,
                    IsRequired: element.attributes.IsRequired,
                    labelSL: element.attributes.FieldAlias_SL,
                    label: element.attributes.FieldAlias,
                    type: "esriFieldTypeSmallInteger",
                    // Added by Abhilash for JIRA ID:-38 on 08/18/2021
                    FieldOrder: element.attributes.FieldOrder
                    // codedValues: this.activeAsset[1].lyrObj.types,
                  });
                }
              }
              if (
                fieldInfosdata.filter(function (item) {
                  if (item.fieldName === "AssetCode") {
                    return item;
                  }
                }).length > 0
              ) {
                fieldInfosdata.filter(function (item) {
                  if (item.fieldName === "AssetCode") {
                    return item;
                  }
                })[0];
              }
              this.assetCodeFrmAssetSubType = [];
            }

            if (
              fieldInfosdata.filter(function (item) {
                if (item.fieldName === element.attributes.Field) {
                  return item;
                }
              }).length === 0
            ) {
              if (codedValuesIsAvailable) {
                fieldInfosdata.push({
                  fieldName: element.attributes.Field,
                  IsRequired: element.attributes.IsRequired,
                  labelSL: element.attributes.FieldAlias_SL,
                  label: element.attributes.FieldAlias,
                  type: Fieldtype,
                  FieldOrder: element.attributes.FieldOrder, // Added by Abhilash for JIRA ID:-38 on 08/18/2021
                  codedValues: CodedValues
                });
              } else {
                //Below logic implemented to see empty dropdown if coded values are empty
                fieldInfosdata.push({
                  fieldName: element.attributes.Field,
                  IsRequired: element.attributes.IsRequired,
                  labelSL: element.attributes.FieldAlias_SL,
                  label: element.attributes.FieldAlias,
                  type: Fieldtype,
                  FieldOrder: element.attributes.FieldOrder // Added by Abhilash for JIRA ID:-38 on 08/18/2021
                  // codedValues: CodedValues,
                });
              }
            }
          }
        });
        // Added by Abhilash for JIRA ID:-38 on 08/18/2021
        this.fieldsInfo = fieldInfosdata.sort(function (a, b) {
          return a.FieldOrder - b.FieldOrder;
        });
        if (
          this.fieldsInfo.filter(function (item) {
            if (item.fieldName === "AssetSubType") {
              return item;
            }
          })[0] &&
          this.fieldsInfo.filter(function (item) {
            if (item.fieldName === "AssetSubType") {
              return item;
            }
          })[0].codedValues &&
          this.fieldsInfo.filter(function (item) {
            if (item.fieldName === "AssetSubType") {
              return item;
            }
          })[0].codedValues[this.activeAsset[0].AssetSubType]
        ) {
          const new123 = this.fieldsInfo.filter(function (item) {
            if (item.fieldName === "AssetSubType") {
              return item;
            }
          })[0].codedValues[this.activeAsset[0].AssetSubType].domains.AssetCode.codedValues;
          console.log(new123);
          this.assetCodeFrmAssetSubType = new123;
        }
        this.disableSavebtn = true;
      }
    } catch (error) {
      console.error(error);
    }
  }
  saveAsset(): void {
    try {
      this.SaveExistData();
      Swal.fire({
        title: this.translateService.instant("CreateAsset.Save.Confirmation"),
        text: this.translateService.instant("CreateAsset.Save.Confirmation.continue"),
        showCancelButton: true,
        customClass: "confirmation-popup",
        cancelButtonText: this.translateService.instant("CreateAsset.button.Cancel"),
        confirmButtonText: this.translateService.instant("Select.alertcarddisplay.continue")
      }).then((result) => {
        if (result.value) {
          this.validations = 0;
          this.disableSavebtn = true;
          if (this.activeAsset[2].asset) {
            this.asset = this.activeAsset[2].asset;
          } else {
            this.asset = this.activeAsset[2];
          }
          const attr = this.activeAsset[0];

          for (let ind = 0; ind < this.fieldsInfo.length; ind++) {
            if (this.fieldsInfo[ind].IsRequired === 1 && (attr[this.fieldsInfo[ind].fieldName] == null || attr[this.fieldsInfo[ind].fieldName] === "")) {
              this.validations = this.validations + 1;
              this.temp.push(this.fieldsInfo[ind].fieldName);
            } else if (
              this.activeAsset[1].lyrObj._fields[ind].type === "esriFieldTypeInteger" ||
              this.activeAsset[1].lyrObj._fields[ind].type === "esriFieldTypeSmallInteger" ||
              this.activeAsset[1].lyrObj._fields[ind].type === "esriFieldTypeOID" ||
              this.activeAsset[1].lyrObj._fields[ind].type === "esriFieldTypeDouble"
            ) {
              attr[this.activeAsset[1].lyrObj._fields[ind].name] = Number(attr[this.activeAsset[1].lyrObj._fields[ind].name]);
            }
          }
          if (this.validations > 0) {
            Swal.fire(this.translateService.instant("Select.alertcarddisplay.inputreqfields") + ":" + this.temp[0]);
            this.temp = [];
          } else {
            this.validations = -1;
            this.featureLayer = this.activeAsset[1].lyrObj;
            this.asset.attributes = attr;
            this.asset.symbol = null; // Fix for removing graphics
            for (let ind = 0; ind < this.fieldsInfo.length; ind++) {
              if (this.fieldsInfo[ind].type === "esriFieldTypeDate") {
                if (this.asset.attributes[this.fieldsInfo[ind].fieldName] !== 0 && this.asset.attributes[this.fieldsInfo[ind].fieldName] !== null) {
                  this.asset.attributes[this.fieldsInfo[ind].fieldName] = new Date(this.asset.attributes[this.fieldsInfo[ind].fieldName]).getTime();
                } else {
                  this.asset.attributes[this.fieldsInfo[ind].fieldName] = null;
                }
                if (this.fieldsInfo[ind].fieldName === "LastModifyDate") {
                  this.asset.attributes[this.fieldsInfo[ind].fieldName] = new Date().getTime();
                }
              }
            }
            this.featureLayer.add(this.asset);

            this.asset["attributes"]["ReplaceYear"] = this.eventEmitersService.getCalculatedReplaceYear(this.featureLayer.name, this.asset);
            this.query.updateAsset(this.featureLayer, this.asset).then(
              function () {
                //Manikantha V M
                if (this.featureLayer.name === "Sign" || this.featureLayer.name === "Light") {
                  // Mani JIRA ID 872
                  // if(this.featureLayer.name === "Sign"){ this.objSubTypeChanged();}
                  this.objSubTypeChanged();
                  this.objAssetDetailService.parentAssetAttributeForm = "Child";
                  this.objAssetDetailService.childAssetId = this.asset.attributes.OBJECTID;
                } else {
                  this.parentAssetAttributeForm = "Parent";
                  this.objAssetDetailService.childAssetId = null;
                }

                if (this.eventEmitersService.addNewParent || this.eventEmitersService.addNewChild) {
                  if (this.eventEmitersService.addNewParent) {
                    //Mani RSAM 333
                    this.saveTask(this.rowdataobj[0].Data);
                  }
                  if (this.eventEmitersService.addNewChild) {
                    //Mani RSAM 333
                    this.saveTask(this.newChildFeature, this.newChildFClass);
                  }
                  this.eventEmitersService.addNewParent = false;
                  this.eventEmitersService.addNewChild = false;
                } else {
                  this.createDetailsUpdateTask(this.activeAsset);
                }
                this.assetAttributes.emit(attr);
                this.disableSavebtn = true;
                this.featureLayer.refresh();
                // Added by Sowjanya Jira Id:-46 on 10/11/2021
                Swal.fire({
                  type: this.translateService.instant("CreateAsset.alerttype.type"),
                  title: this.translateService.instant("CreateAsset.alertheader.Save"),
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000
                });
              }.bind(this),
              function (error) {
                console.log(error);
              }.bind(this)
            );
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  changeTime(evt, fieldName) {
    try {
      console.log(evt);
      this.activeAsset[0][fieldName] = new Date(evt.value).getTime();
    } catch (error) {
      console.error(error);
    }
  }
  deleteAsset() {
    try {
      console.log("deleteAsset Method");
      this.esri.showAdd = false;
      if (!this.asset) {
        if (this.activeAsset[2].asset) {
          this.asset = this.activeAsset[2].asset;
        } else {
          this.asset = this.activeAsset[2];
        }
      }
      this.featureLayer = this.activeAsset[1].lyrObj;
      if (this.isDeleteClicked) {
        Swal.fire({
          title: this.translateService.instant("Select.alertcarddisplay.deleteasset"),
          showCancelButton: true,
          customClass: "confirmation-popup",
          confirmButtonText: this.translateService.instant("Select.photoviewer.deletebtn")
        }).then((result) => {
          if (result.value) {
            if (this.dataEditorSettings.hardDelete) {
              const featurese = [];
              const features = new this.Graphic();
              if (this.asset.attributes) {
                features.setAttributes(this.asset.attributes);
              } else if (this.asset[0].attributes) {
                features.setAttributes(this.asset[0].attributes);
              } else {
                features.setAttributes(this.asset[0]);
              }
              featurese.push(features);

              this.featureLayer
                .applyEdits(null, null, featurese)
                .then(
                  function (newrow) {
                    console.log(newrow);
                    if (this.closeAssetDetailCard.observers != null) {
                      this.closeAssetDetailCard.emit(true);
                    }
                    if (this.featureLayer._name == "Sign" || this.featureLayer._name == "Light") {
                      this.eventEmitersService.setchildDeleted(false);
                    } else {
                      if (this.map.getLayer("sms-selection")) {
                        this.map.getLayer("sms-selection").remove(
                          this.map.getLayer("sms-selection").graphics.filter((item) => {
                            if (item.attributes.AssetID == this.eventEmitersService.activeAssetFeature[0].AssetID) return item;
                          })[0]
                        );

                        this.map.getLayer("sms-selection").refresh();
                      }
                      this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.splice(this.eventEmitersService.selectedParentIndexOnData, 1);
                      this.eventEmitersService.setShowCard(false);

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length > 0) {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap, this.eventEmitersService.selectedLayerIndexOnData, 0);
                      } else {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap);
                      }

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length) {
                        this.eventEmitersService.setShowCard(true);
                      }
                      this.map.getLayer("sms-selection").refresh();
                    }

                    if (this.closeCard.observers != null) {
                      this.closeCard.emit("true");
                    }
                    this.featureLayer.refresh();
                    this.map.graphics.clear();
                    this.map.getLayer("sms-layers").refresh();
                  }.bind(this)
                )
                .catch(function (error) {
                  console.log("===============================================");
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                  console.log("error = ", error);
                });
            } else {
              const featurese = [];
              const features = new this.Graphic();
              if (this.asset.attributes) {
                this.asset.attributes.Status = "Inactive";
                features.setAttributes(this.asset.attributes);
              } else if (this.asset[0].attributes) {
                this.asset[0].attributes.Status = "Inactive";
                features.setAttributes(this.asset[0].attributes);
              }
              featurese.push(features);
              this.featureLayer
                .applyEdits(null, featurese, null)
                .then(
                  function (newrow) {
                    console.log(newrow);
                    if (this.closeAssetDetailCard.observers != null) {
                      this.closeAssetDetailCard.emit(true);
                    }
                    this.eventEmitersService.setShowCard(false);
                    this.featureLayer.refresh();
                    this.map.graphics.clear();
                    this.map.getLayer("sms-layers").refresh();
                  }.bind(this)
                )
                .catch(function (error) {
                  console.log("===============================================");
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                  console.log("error = ", error);
                });
            }
          }
        });
      } else {
        const featurese = [];
        const features = new this.Graphic();
        if (this.asset.attributes) {
          features.setAttributes(this.asset.attributes);
        } else if (this.asset[0].attributes) {
          features.setAttributes(this.asset[0].attributes);
        }
        featurese.push(features);
        this.featureLayer
          .applyEdits(null, null, featurese)
          .then(
            function (newrow) {
              console.log(newrow);
              if (this.closeAssetDetailCard.observers != null) {
                this.closeAssetDetailCard.emit(true);
              }
              if (this.eventEmitersService.addNewParent) {
                this.eventEmitersService.setShowCard(false);
                this.eventEmitersService.addNewParent = false;
                this.eventEmitersService.closeModelWindow();
              } else if (this.eventEmitersService.addNewChild) {
                this.eventEmitersService.setchildDeleted(false);
                this.eventEmitersService.addNewChild = false;
                this.eventEmitersService.closeModelWindow();
              }
              this.map.graphics.clear();
              // this line of code is not working
              // this.closeCard.emit("true");

              if (this.closeCard.observers != null) {
                this.closeCard.emit("true");
              }
              this.refreshAfterEdit();
              this.featureLayer.refresh();
              // this.deleteRelFeaturesByCondSubscribe = this.genericLayerService.deleteRelFeaturesByCond(22,'SUP_12333').subscribe((result)=>console.log(result));
              this.map.getLayer("sms-layers").refresh();
            }.bind(this)
          )
          .catch(function (error) {
            console.log(JSON.stringify(error));
            console.log("===============================================");
            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
  reshape() {
    try {
      console.log("reshape Method");
      const editToolbar = new this.Edit(this.map);
      const tool = this.Edit.EDIT_VERTICES; // Tool for Editing Vertices
      let graphic = this.activeAsset[2];
      this.map.graphics.add(graphic);
      const currentAssetLocation = editToolbar.getCurrentState();
      currentAssetLocation.graphic = graphic;
      const featureLayerTemp = this.activeAsset[1].lyrObj;
      if (featureLayerTemp.geometryType === "esriGeometryPoint") {
        Swal.fire(this.translateService.instant("Select.alertcarddisplay.cannotbereshaped"));
      } else {
        editToolbar.activate(tool, graphic);
        // const geometryCrosses = false;

        const clickMap = this.map.on(
          "click",
          function () {
            if (this.objAssetDetailService.crossesPolygon(graphic.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
              Swal.fire(this.translateService.instant("Select.newasset.differentregion"));
              clickMap.remove();
              editToolbar.refresh();
              editToolbar.deactivate();
              return;
            }
            if (this.objAssetDetailService.isOutsideRegion(graphic.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
              this.populateRegionGeometry().then(
                function (results) {
                  let isAssetinAnyRegion = false;
                  for (let i = 0; i < results.length; i++) {
                    if (this.objAssetDetailService.isOutsideRegion(graphic.geometry, this.geometryEngine, results[i])) {
                      isAssetinAnyRegion = false;
                    } else {
                      isAssetinAnyRegion = true;
                      this.assetPlacedinRegion = results[i];
                      break;
                    }
                  }
                  if (isAssetinAnyRegion) {
                    Swal.fire({
                      title: this.translateService.instant("Select.alertcarddisplay.reshapeasset"),
                      text: this.translateService.instant("Select.alertcarddisplay.confirmreshapeasset"),
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
                    }).then(
                      function (result) {
                        if (result.value) {
                          /*Update Lat Long*/
                          this.loading = true;
                          graphic = this.updateLatLong(graphic);
                          /*Update Region*/
                          this.populateChangedAssetDefaultValues(this.assetPlacedinRegion, graphic).then(
                            function (results) {
                              this.query.updateAsset(featureLayerTemp, graphic).then(
                                function () {
                                  clickMap.remove();
                                  editToolbar.deactivate();
                                  editToolbar.refresh();
                                  this.refreshAfterEdit();
                                }.bind(this)
                              );
                            }.bind(this)
                          );
                        } else {
                          editToolbar.refresh();
                          editToolbar.deactivate();
                          clickMap.remove();
                          this.refreshAfterEdit();
                        }
                      }.bind(this)
                    );
                  } else {
                    Swal.fire(this.translateService.instant("Select.newasset.regionnotregistered"));
                  }
                }.bind(this)
              );
            } else {
              Swal.fire({
                title: this.translateService.instant("Select.alertcarddisplay.reshapeasset"),
                text: this.translateService.instant("Select.alertcarddisplay.confirmreshapeasset"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
              }).then(
                function (result) {
                  if (result.value) {
                    /*Update Lat Long*/
                    this.loading = true;
                    graphic = this.updateLatLong(graphic);
                    this.query.updateAsset(featureLayerTemp, graphic).then(
                      function () {
                        clickMap.remove();
                        editToolbar.deactivate();
                        editToolbar.refresh();
                        this.refreshAfterEdit();
                      }.bind(this)
                    );
                  } else {
                    editToolbar.refresh();
                    editToolbar.deactivate();
                    clickMap.remove();
                    this.refreshAfterEdit();
                  }
                }.bind(this)
              );
            }
          }.bind(this)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  refreshAfterEdit() {
    try {
      this.loading = false;
      this.map.getLayer("sms-layers").refresh();
      this.map.getLayer("sms-selection").clear();

      this.map.enableMapNavigation();
      this.initAssetDetail();
    } catch (error) {
      console.error(error);
    }
  }
  updateLatLong(graphic) {
    try {
      // const wm = this.webMercatorUtils.webMercatorToGeographic(graphic.geometry);
      graphic.attributes.Latitude = graphic.geometry.paths[0][0][0];
      graphic.attributes.Longitude = graphic.geometry.paths[0][0][1];
      graphic.attributes.Latitude2 = graphic.geometry.paths[0][graphic.geometry.paths[0].length - 1][0];
      graphic.attributes.Longitude2 = graphic.geometry.paths[0][graphic.geometry.paths[0].length - 1][1];
      return graphic;
    } catch (error) {
      console.error(error);
    }
  }
  relocateAsset() {
    try {
      console.log("relocateAsset Method");
      const selectedAsset = this.activeAsset[2];
      const featureLayerTemp = this.activeAsset[1].lyrObj;
      const geometryType = featureLayerTemp.geometryType;
      if (geometryType === "esriGeometryPoint") {
        this.relocatePoint(selectedAsset, featureLayerTemp);
      }
      if (geometryType === "esriGeometryPolyline" || geometryType === "esriGeometryPolygon") {
        this.relocatePolyline(selectedAsset);
      }
    } catch (error) {
      console.error(error);
    }
  }
  relocatePoint(selectedAsset, featureLayerTemp) {
    try {
      console.log("relocatePoint Method");
      // Get a feature to Relocate (Point)
      if (selectedAsset) {
        this.drawTool = this.Draw(this.map);
        this.drawTool.activate("point");
        let isAssetinAnyRegion = false;
        const clickMap = this.drawTool.on(
          "draw-end",
          function (evt) {
            this.populateRegionGeometry().then((results) => {
              console.log(results);
              for (let i = 0; i < results.length; i++) {
                if (this.objAssetDetailService.isOutsideRegion(evt.geometry, this.geometryEngine, results[i])) {
                  isAssetinAnyRegion = false;
                } else {
                  isAssetinAnyRegion = true;
                  this.assetPlacedinRegion = results[i];
                  break;
                }
              }
              if (isAssetinAnyRegion) {
                this.loading = true;
                // Populate Region Data for Asset
                let destination = new this.Graphic();
                const finalAsset = [];
                destination = selectedAsset;
                destination.geometry = evt.geometry;
                Swal.fire({
                  title: this.translateService.instant("Select.alertcarddisplay.relocateasset"),
                  text: this.translateService.instant("Select.alertcarddisplay.confirmrelocateasset"),
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
                }).then((result) => {
                  if (result.value) {
                    this.populateChangedAssetDefaultValues(this.assetPlacedinRegion, destination, this.Query, this.QueryTask).then(
                      function (result) {
                        finalAsset.push(result);
                        featureLayerTemp.applyEdits(null, [result], null).then(
                          function (res) {
                            this.refreshAfterEdit();
                            this.drawTool.deactivate();
                            this.loading = false;
                          }.bind(this),
                          function (err) {
                            console.log(err);
                          }
                        );
                      }.bind(this)
                    );
                  }
                });
                clickMap.remove();
                console.log("Found Region");
              } else {
                Swal.fire(this.translateService.instant("Select.newasset.regionnotregistered"));
                return;
              }
            });
          }.bind(this)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  relocatePolyline(selectedAsset) {
    try {
      this.editTool = new this.Edit(this.map);
      let feature = new this.Graphic();
      feature = selectedAsset;
      this.map.graphics.add(feature);
      this.map.disableMapNavigation();
      this.editTool.activate(this.Edit.MOVE, feature);
    } catch (error) {
      console.error(error);
    }
  }
  populateChangedAssetDefaultValues(placedinRegion, asset) {
    return new Promise((resolve) => {
      const promise = this.objAssetDetailService.populateDefaultRegionValues(this.currentUser, placedinRegion, this.Query, this.QueryTask);
      promise.then((result) => {
        console.log(result);
        let regionData: any;
        // eslint-disable-next-line prefer-const
        regionData = result;
        const attrib = asset.attributes;
        if (placedinRegion.regionLevel === 4) {
          attrib.region1 = regionData.features[0].attributes.RegionName1;
          attrib.region2 = regionData.features[0].attributes.RegionName2;
          attrib.region3 = regionData.features[0].attributes.RegionName3;
          attrib.region4 = regionData.features[0].attributes.RegionName4;
        }
        if (placedinRegion.regionLevel === 3) {
          attrib.region1 = regionData.features[0].attributes.RegionName1;
          attrib.region2 = regionData.features[0].attributes.RegionName2;
          attrib.region3 = regionData.features[0].attributes.RegionName3;
        }
        if (placedinRegion.regionLevel === 2) {
          attrib.region1 = regionData.features[0].attributes.RegionName1;
          attrib.region2 = regionData.features[0].attributes.RegionName2;
        }
        asset.setAttributes(attrib);
        resolve(asset);
      });
    });
  }
  revert() {
    this.AttrInspect.refresh();
  }
  viewImage(content) {
    try {
      this.photosData = [];
      this.imgBase64 = "";
      this.imageSrc = "";
      this.samples = [];
      if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
        this.linearAssetValues = true;
      } else {
        this.linearAssetValues = false;
      }
      let assetId;
      if (this.activeAsset[0].AssetID) {
        assetId = this.activeAsset[0].AssetID;
      } else {
        assetId = this.activeAsset[0].signAttribute.AssetID;
      }
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.documentId, "AssetID", assetId).subscribe((results: any) => {
        results.features.forEach((data) => {
          this.photosData.push(data);
        });
        this.imageGenerate();
      });
      this.modalReference = this.modalService.open(content, {
        centered: true,
        windowClass: "asset-photo",
        size: "xl"
      });
    } catch (error) {
      console.error(error);
    }
  }
  setThis() {
    return this;
  }
  uploadNew(files) {
    try {
      // TSDRSAM-510 Manikantha V
      if (files.length > 0) {
        const fileElement = environment.DocumentSupportFormats.includes(files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase());
        if (!fileElement) {
          Swal.fire(this.translateService.instant("Select.file.unsupportedformat"));
          const input: any = document.querySelector("input[type=file]");
          input.value = null;
          return;
        }
        const _this = this.setThis();
        // TSDRSAM-510 Manikantha V
        const doctyp = files[0].name.split(".")[files[0].name.split(".").length - 1].toUpperCase();

        const photoAsset = new this.Graphic();
        const attr: any = [];
        attr.AssetID = this.activeAsset[2].attributes.AssetID;
        attr.SourceTag = "General";
        attr.DocType = doctyp;
        attr.DocID = this.activeAsset[2].attributes.OBJECTID;
        attr.Filename = files[0].name;
        attr.DocDate = new Date(Date.now()).getTime();
        attr.ModifiedBy = this.currentUser.userId;

        //092621 jcc TSDRSAM-740
        attr.AssetName = this.activeAsset[1].layerName;

        photoAsset.setAttributes(attr);

        const photoFl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);

        photoFl.applyEdits(
          [photoAsset],
          null,
          null,
          (res) => {
            if (res[0].success) {
              _this.objAssetDetailService.UpdateDocId(_this.Graphic, res[0].objectId, photoFl);
              this.uploadfileName = files[0].name;
              const options = new ResizeOptions();
              // eslint-disable-next-line camelcase
              options.Resize_Max_Height = 1280;
              // eslint-disable-next-line camelcase
              options.Resize_Max_Width = 1280;
              // eslint-disable-next-line camelcase
              options.Resize_Quality = 80;
              // eslint-disable-next-line camelcase
              options.Resize_Type = "image/jpg";

              //70
              const reader = new FileReader();
              let fileInBase64Data;
              reader.readAsDataURL(files[0]);
              reader.onload = () => {
                fileInBase64Data = reader.result;
              };

              // TSDRSAM-510 Manikantha V
              if (files[0].name.split(".")[files[0].name.split(".").length - 1].toUpperCase() == "PDF") {
                _this.uploadImageSubscribe = _this.objAssetDetailService.uploadImage(files[0], res[0].objectId, this.currentUser.clientId).subscribe(
                  (uploadres: any) => {
                    if (uploadres.message === "Photo Saved Successfully") {
                      Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                      photoAsset.attributes.OBJECTID = res[0].objectId;
                      // TSDRSAM-510 Manikantha V
                      this.samples.push({
                        label: photoAsset.attributes.Filename,
                        imgBase64: fileInBase64Data.split(",")[1],
                        url: `data:application/pdf;base64, ${fileInBase64Data.split(",")[1]}`,
                        attributes: photoAsset.attributes,
                        Date: photoAsset.attributes.DocDate,
                        SourceTag: photoAsset.attributes.SourceTag,
                        Measure: photoAsset.attributes.Asset_Segment_Measure
                      });
                      if (this.samples.length > 0) {
                        this.imageSrc = this.samples[0].url;
                        this.PhotoDate = this.samples[0].Date;
                        this.SourceTag = this.samples[0].sourceTag;
                        this.MeasureValue = this.samples[0].Measure;
                        this.imgBase64 = this.samples[0].imgBase64;
                      } else {
                        this.imageSrc = "";
                        this.PhotoDate = "";
                        this.SourceTag = "";
                        this.MeasureValue = "";
                        this.imgBase64 = "";
                      }
                      this.DescendingOrder("Date");
                    }
                  },
                  (err) => {
                    if (err.error.message === "File Format/Size is Not Correct") {
                      const featurese = [];
                      const features = new this.Graphic();
                      const assetAttr: any = [];
                      assetAttr.OBJECTID = res[0].objectId;
                      features.setAttributes(assetAttr);
                      featurese.push(features);
                      photoFl
                        .applyEdits(null, null, featurese)
                        .then(
                          function () {
                            Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                          }.bind(this)
                        )
                        .catch(function (error) {
                          console.log("===============================================");
                          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                          console.log("error = ", error);
                        });
                    } else {
                      console.log(err.error.message);
                    }
                  }
                );
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              else {
                const newFileDataC = _this.compressImage(files[0], options).then((newFileDataC: any) => {
                  _this.uploadImageSubscribe = _this.objAssetDetailService.uploadImage(newFileDataC, res[0].objectId, this.currentUser.clientId).subscribe(
                    (uploadres: any) => {
                      if (uploadres.message === "Photo Saved Successfully") {
                        Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                        photoAsset.attributes.OBJECTID = res[0].objectId;
                        // TSDRSAM-510 Manikantha V
                        this.samples.push({
                          label: photoAsset.attributes.Filename,
                          imgBase64: fileInBase64Data.split(",")[1],
                          url: `data:image/png;base64, ${fileInBase64Data.split(",")[1]}`,
                          attributes: photoAsset.attributes,
                          Date: photoAsset.attributes.DocDate,
                          SourceTag: photoAsset.attributes.SourceTag,
                          Measure: photoAsset.attributes.Asset_Segment_Measure
                        });
                        if (this.samples.length > 0) {
                          this.imageSrc = this.samples[0].url;
                          this.PhotoDate = this.samples[0].Date;
                          this.SourceTag = this.samples[0].sourceTag;
                          this.MeasureValue = this.samples[0].Measure;
                          this.imgBase64 = this.samples[0].imgBase64;
                        } else {
                          this.imageSrc = "";
                          this.PhotoDate = "";
                          this.SourceTag = "";
                          this.MeasureValue = "";
                          this.imgBase64 = "";
                        }
                        this.DescendingOrder("Date");
                      }
                    },
                    (err) => {
                      if (err.error.message === "File Format/Size is Not Correct") {
                        const featurese = [];
                        const features = new this.Graphic();
                        const assetAttr: any = [];
                        assetAttr.OBJECTID = res[0].objectId;
                        features.setAttributes(assetAttr);
                        featurese.push(features);
                        photoFl
                          .applyEdits(null, null, featurese)
                          .then(
                            function () {
                              Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                            }.bind(this)
                          )
                          .catch(function (error) {
                            console.log("===============================================");
                            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                            console.log("error = ", error);
                          });
                      } else {
                        console.log(err.error.message);
                      }
                    }
                  );
                });
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  cancel() {
    this.modalReference.close();
  }
  getPhotoUrl(photoData) {
    return "Images/" + this.currentUser.clientId + "/" + photoData.attributes.OBJECTID + "." + photoData.attributes.Filename.split(".")[1];
  }
  export() {
    //70
    if (this.imgBase64 !== undefined && this.imgBase64 !== "") {
      var selectedFile = { fileBase64: this.imageSrc };
      var mimeType = getMimeType(selectedFile.fileBase64);
      if (mimeType.toLowerCase() == "pdf") {
        var pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.imgBase64) + "'></iframe>");
      } else {
        var image = new Image();
        image.src = "data:image/" + mimeType + ";base64," + this.imgBase64;
        var w = window.open("_blank");
        w.document.write(image.outerHTML);
      }
    } else {
      Swal.fire(this.translateService.instant("Select.alertassessment.nodocument"));
    }
  }
  deleteImage() {
    try {
      Swal.fire({
        title: environment.deleteMessage,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant("Select.alertassessment.delete")
      }).then((result) => {
        if (result.value) {
          this.samples.forEach((data, key) => {
            if (data.url === this.imageSrc) {
              const featurese = [];
              const features = new this.Graphic();
              features.setAttributes(data.attributes);
              featurese.push(features);
              this.photoFl
                .applyEdits(null, null, featurese)
                .then(
                  function () {
                    Swal.fire(this.translateService.instant("Select.alertcarddisplay.deletedsucceffully"));
                    this.samples.splice(key, 1);
                    if (this.samples.length > 0) {
                      this.imageSrc = this.samples[0].url;
                      this.PhotoDate = this.samples[0].Date;
                      this.SourceTag = this.samples[0].SourceTag;
                      this.MeasureValue = this.samples[0].Measure;
                      this.imgBase64 = this.samples[0].imgBase64;
                    } else {
                      this.imageSrc = "";
                      this.PhotoDate = "";
                      this.SourceTag = "";
                      this.MeasureValue = "";
                      this.imgBase64 = "";
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  console.log("===============================================");
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                  console.log("error = ", error);
                });
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  imageGenerate(data?) {
    try {
      this.samples = [];
      const photoData = data ? data : this.photosData;
      let contentType;
      // TSDRSAM-510 Manikantha V
      if (photoData.length > 0) {
        photoData.forEach((photo) => {
          this.objAssetDetailService.getImageAsBase64(photo.attributes.OBJECTID, this.currentUser.clientId, photo.attributes.DocType.toLowerCase()).subscribe((base64Image: any) => {
            if (photo.attributes.DocType.toLowerCase() == "pdf") {
              contentType = "application/pdf";
            } else {
              contentType = "image/png";
            }

            this.samples.push({
              label: photo.attributes.Filename,
              imgBase64: base64Image,
              url: `data:${contentType};base64, ${base64Image}`,
              attributes: photo.attributes,
              Date: photo.attributes.DocDate,
              SourceTag: photo.attributes.SourceTag,
              Measure: photo.attributes.Asset_Segment_Measure
            });
            this.DescendingOrder("Date");
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  EventChange(event) {
    this.imageSrc = event.value;
    this.samples.forEach((imageData) => {
      if (imageData.url === event.value) {
        this.PhotoDate = imageData.Date;
        this.SourceTag = imageData.SourceTag;
        this.MeasureValue = imageData.Measure;
        this.imgBase64 = imageData.imgBase64;
      }
    });
  }
  populateRegionGeometry() {
    return new Promise((resolve) => {
      const rdata = [];
      const tempRegion = this.map.smsRegiondata;
      const fl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.regionId);
      tempRegion.forEach((region) => {
        const regionObj = region;
        const query = new this.Query();
        query.where = "RegionName" + regionObj.regionLevel + "='" + regionObj.regionName + "'";
        fl.selectFeatures(
          query,
          null,
          (evt) => {
            if (evt.length > 0) {
              const poly = new this.Polygon(evt[0].geometry);
              // this.map.setExtent(poly.getExtent(), true);
              regionObj.Extent = poly.getExtent();
              regionObj.geom = poly;
              rdata.push(regionObj);
            }
            if (rdata.length === this.map.smsRegiondata.length) {
              resolve(rdata);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      });
    });
  }
  OrderImages(sortKey) {
    try {
      if (sortKey === "Date") {
        if (this.showDateByOrder) {
          this.showDateByOrder = !this.showDateByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showDateByOrder = !this.showDateByOrder;
          this.AscendingOrder(sortKey);
        }
      } else if (sortKey === "Measure") {
        if (this.showSequenceByOrder) {
          this.showSequenceByOrder = !this.showSequenceByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showSequenceByOrder = !this.showSequenceByOrder;
          this.AscendingOrder(sortKey);
        }
      } else if (sortKey === "SourceTag") {
        if (this.showSourceByOrder) {
          this.showSourceByOrder = !this.showSourceByOrder;
          this.DescendingOrder(sortKey);
        } else {
          this.showSourceByOrder = !this.showSourceByOrder;
          this.AscendingOrder(sortKey);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  AscendingOrder(sortKey) {
    try {
      this.samples.sort((a, b) => {
        const x = a[sortKey];
        const y = b[sortKey];
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      if (this.samples.length > 0) {
        this.imageSrc = this.samples[0].url;
        this.PhotoDate = this.samples[0].Date;
        this.SourceTag = this.samples[0].SourceTag;
        this.MeasureValue = this.samples[0].Measure;
        this.imgBase64 = this.samples[0].imgBase64;
      } else {
        this.imageSrc = "";
        this.PhotoDate = "";
        this.SourceTag = "";
        this.MeasureValue = "";
        this.imgBase64 = "";
      }
    } catch (error) {
      console.error(error);
    }
  }
  DescendingOrder(sortKey) {
    try {
      this.samples.sort((a, b) => {
        const x = a[sortKey];
        const y = b[sortKey];
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });
      if (this.samples.length > 0) {
        this.imageSrc = this.samples[0].url;
        this.PhotoDate = this.samples[0].Date;
        this.SourceTag = this.samples[0].SourceTag;
        this.MeasureValue = this.samples[0].Measure;
        this.imgBase64 = this.samples[0].imgBase64;
      } else {
        this.imageSrc = "";
        this.PhotoDate = "";
        this.SourceTag = "";
        this.MeasureValue = "";
        this.imgBase64 = "";
      }
    } catch (error) {
      console.error(error);
    }
  }
  createDetailsUpdateTask(asset) {
    try {
      this.assetTypeId = asset[1].layerId;
      this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
      this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
      const featurese = [];
      const features = new this.Graphic();

      this.asset = asset;
      const assetId = asset[0].AssetID;
      const geomString = asset[3].geometry.toJson();
      if (geomString.paths) {
        geomString.x = geomString.paths[0][0][0];
        geomString.y = geomString.paths[0][0][1];
        delete geomString.paths;
      }
      // let region;
      const region = asset[4].region;
      const status = "Closed";
      let assetDetailsDiff = this.generateAssetChangeDetails(this.rollbackData, this.asset[0]);
      features.setAttributes({
        Status: status,
        CreatedBy: this.currentUser.username,
        CompletedBy: this.currentUser.username,
        CompletionDate: new Date(Date.now()).getTime(),
        CreationDate: new Date(Date.now()).getTime(),
        Region: region,
        AssetID: assetId,
        AssetName: asset[1].layerName,
        WorkOrderDesc: "",
        Comment: "",
        GeomWKT: JSON.stringify(geomString),
        PendingChanges: JSON.stringify(assetDetailsDiff)
      });
      featurese.push(features);

      this.ftrLyrTblMaintenance
        .applyEdits(featurese, null, null)
        .then(
          function (newrow) {
            // updateing MaintID for newly inserted Maintanance record
            const featureseAssetID = [];
            const featuresA = new this.Graphic();
            featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
            featureseAssetID.push(featuresA);
            this.ftrLyrTblMaintenance
              .applyEdits(null, featureseAssetID, null)
              .then(function (newrow) {
                console.log(newrow);
              })
              .catch(function (error) {
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              });

            const actfeaturese = [];
            const actfeatures = new this.Graphic();
            actfeatures.setAttributes({
              MaintID: "MNT_" + newrow[0].objectId,
              Asset: this.assetTypeId,
              Activity: "DetailUpdate",
              Description: " ",
              ModifiedBy: this.currentUser.username
            });
            actfeaturese.push(actfeatures);
            this.ftrLyrActivity.applyEdits(actfeaturese, null, null);
            // this.ftrLyrActivity .applyEdits(actfeaturese, null, null) .then(function() { });
            this.objAssetDetailService.tasksModified.emit(true);
            this.eventEmitersService.emitTaskCreated.emit(true);
            this.assetUpdated.emit(true);
          }.bind(this)
        )
        .catch(function (error) {
          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        });
    } catch (error) {
      console.error(error);
    }
  }
  objSubTypeChanged() {
    try {
      const new123 = this.fieldsInfo.filter(function (item) {
        if (item.fieldName === "AssetSubType") {
          return item;
        }
      })[0].codedValues[this.activeAsset[0].AssetSubType].domains.AssetCode.codedValues;
      this.assetCodeFrmAssetSubType = new123;
      // TSDRSAM-315
      if (
        this.assetCodeFrmAssetSubType.filter(
          function (item) {
            if (item.code == this.activeAsset[0].AssetCode) {
              return item;
            }
          }.bind(this)
        ).length == 0
      ) {
        this.activeAsset[0].AssetCode = this.assetCodeFrmAssetSubType[0].code;
      }
    } catch (error) {
      console.error(error);
    }
  }
  SaveExistData() {
    try {
      this.cs.getFeatures(this.activeAsset[1].lyrObj.url, "ObjectID = " + this.activeAsset[0].OBJECTID, this.Query, this.QueryTask).then((val) => {
        const result = val[0];
        console.log(result);
        this.rollbackData = {};
        this.rollbackData = result.attributes;
      });
    } catch (error) {
      console.error(error);
    }
  }
  findFieldisDisable(fieldName) {
    try {
      if (this.currentUser.rolePermissions.assetEditAttributes && this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
        const layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.activeAsset[1].lyrObj.layerId) {
              return layer;
            }
          }.bind(this)
        )[0];
        if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
          if (this.defaultDisabledFields.includes(fieldName.toLowerCase())) {
            return true;
          }
          //  else { return false; }
          return false;
        }
        return true;
        //  else {  return true; }
      }
      //  else { return true;}
      return true;
    } catch (error) {
      console.error(error);
    }
  }
 
  doNotReplaceDisable(fieldName) {
    if (fieldName == "DoNotReplace" && this.objAssetDetailService.activetasksData.length > 0) {
      let isreplaceTaskOpen = this.objAssetDetailService.isReplaceTaskOpen(this.objAssetDetailService.activetasksData);
      if (isreplaceTaskOpen) {
        return true;
      }
      return false
    }
    return false;
  }
  
  getAssetDefaultValues(evt) {
    try {
      const AssetCodeLayer = this.currentUser.webServiceURL + environment.mapServer + this.currentUser.serviceLyrInfo.assetCodeDefaultsId;
      const QueryParams = "AssetCode = '" + evt + "'";
      this.cs.getFeatures(AssetCodeLayer, QueryParams, this.Query, this.QueryTask).then((val: any) => {
        const deafultCodeValues = val[0].attributes;
        this.activeAsset[0].Manufacturer = deafultCodeValues.Manufacturer;
        this.activeAsset[0].Material = deafultCodeValues.Material;
        this.activeAsset[0].DimLength = deafultCodeValues.DimLength;
        this.activeAsset[0].DimWidth = deafultCodeValues.DimWidth;
        this.activeAsset[0].DimHeight = deafultCodeValues.DimHeight;
        this.activeAsset[0].MountHeight = deafultCodeValues.MountHeight;
        // Manikantha Start #24789
        if (deafultCodeValues.AssetShortName === "SIG") {
          this.activeAsset[0].InkColor = deafultCodeValues.LegendColor1;
          this.activeAsset[0].SheetColor = deafultCodeValues.SheetColor1;
          this.activeAsset[0].SheetColor2 = deafultCodeValues.SheetColor2;
          this.activeAsset[0].SheetType = deafultCodeValues.SheetType1;
          this.activeAsset[0].SheetType2 = deafultCodeValues.SheetType2;
          //TSDRSAM-2045 : get asset icon on change of asset code dropdown
          this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", evt, false).subscribe(
            (base64Image: any) => {
              if (base64Image) {
                this.activeAsset[2].ImageBase64 = BASE64_PREFIX + base64Image;
              }
            },
            (error) => {
              if (error.status) {
                console.log("No object found", error);
              }
            }
          );
        } else {
          this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", evt, true).subscribe(
            (base64Image: any) => {
              if (base64Image && this.activeAsset.length >= 7) {
                let ImageBase64 = {};
                ImageBase64 = BASE64_PREFIX + base64Image;
                this.activeAsset.splice(7, 0, { "ImageBase64": ImageBase64 });
              }
            },
            (error) => {
              if (error.status) {
                console.log("No object found", error);
              }
            }
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  closeCardViewForRetire() {
    if (this.closeAssetDetailCard.observers != null) {
      this.closeAssetDetailCard.emit(true);
    }

    this.eventEmitersService.setShowCard(false);
  }
  // Manikantha V 23/09/2020
  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().includes(term) || item.code.toLowerCase().includes(term);
  }
  //

  async compressImage(fileData, options) {
    const result = await this.compressImageAndUpload(fileData, options);
    return result;
  }
  compressImageAndUpload(fileData, options) {
    try {
      return new Promise((resolve) => {
        const files: any = [fileData];
        const images = [];
        let newfileData: any;
        this.imageCompressService.compressImageArray(files, options).subscribe(
          (image) => {
            images.push(image);
          },
          (error) => {
            console.log("Error while converting");
          },
          () => {
            let processedImages = images;
            console.log();
            newfileData = this.dataURItoBlob(processedImages[0].compressedImage.imageDataUrl);
            resolve(newfileData);
            // if(newfileData.size < 3,096,632)
            // {

            // }

            // else{
            //   this.compressImage(newfileData);
            // }
          }
        );
      });
    } catch (error) {
      console.error(error);
    }
  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // return new Blob([ab], { type: 'image/jpeg' });
    return new File([ia], this.uploadfileName, { type: "image/jpeg" });
  }

  findUserAccessByRoleRegionLayer() {
    // Region
    if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
      const layerinfo = this.currentUser.layerLevelInfo.filter(
        function (layer) {
          if (this.activeAsset&& this.activeAsset[1] && parseInt(layer.gisLayerId, 10) === this.activeAsset[1].lyrObj.layerId) {
            return layer;
          }
        }.bind(this)
      )[0];
      // Layer
      if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
        this.findUserAccessByRoleRegionLayerVar = true;
      } else {
        this.findUserAccessByRoleRegionLayerVar = false;
      }
    } else {
      this.findUserAccessByRoleRegionLayerVar = false;
    }
  }
  //Mani RSAM 333
  saveTask(asset, featureClassName?) {
    try {
      this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
      this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
      if (this.selectedLayer) {
        this.ftrLyrActivity.on(
          "Load",
          function (evt) {
            this.getLayerId(evt.layer.types, this.selectedLayer);
          }.bind(this)
        );
      } else {
        this.ftrLyrActivity.on(
          "Load",
          function (evt) {
            this.getLayerId(evt.layer.types, this.selectRelAsset);
          }.bind(this)
        );
      }
      const featurese = [];
      const features = new this.Graphic();
      // this.asset = asset;
      let assetId;
      if (asset.attributes && asset.attributes.AssetID) {
        assetId = asset.attributes.AssetID;
      } else {
        //Jira Id-935
        const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === this.selectedLayer)[0].attributes.LayerCode;
        assetId = prefix + "_" + asset[0].attributes.OBJECTID;
      }

      let geomString;

      if (asset[0] && asset[0].geometry) {
        geomString = asset[0].geometry.toJson();
      } else {
        geomString = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].geometry.toJson();
      }
      if (geomString.paths) {
        geomString.x = geomString.paths[0][0][0];
        geomString.y = geomString.paths[0][0][1];
        delete geomString.paths;
      }
      let region;
      if (asset[0] && asset[0].attributes) {
        if (asset[0].attributes.Region4) {
          region = asset[0].attributes.Region4;
        } else if (asset[0].attributes.Region3) {
          region = asset[0].attributes.Region3;
        } else if (asset[0].attributes.Region2) {
          region = asset[0].attributes.Region2;
        } else if (asset[0].attributes.Region1) {
          region = asset[0].attributes.Region1;
        } else if (asset[4].attributes.region) {
          region = asset[4].attributes.region;
        }
      } else {
        region = null;
      }

      if (!region) {
        // console.log(this.activeParentAsset);
        if (this.activeParentAsset[0]) {
          if (this.activeParentAsset[0].Region4) {
            region = this.activeParentAsset[0].Region4;
          } else if (this.activeParentAsset[0].Region3) {
            region = this.activeParentAsset[0].Region3;
          } else if (this.activeParentAsset[0].Region2) {
            region = this.activeParentAsset[0].Region2;
          } else if (this.activeParentAsset[0].Region1) {
            region = this.activeParentAsset[0].Region1;
          } else if (this.activeParentAsset[0].region) {
            region = this.activeParentAsset[0].region;
          }
        }
      }
      let status;
      if (this.dataEditorSettings.taskType === "New") {
        status = "Open";
      } else {
        status = "Closed";
      }
      let assetName;
      if (featureClassName) {
        assetName = featureClassName;
      } else {
        assetName = this.selectedLayer;
      }
      features.setAttributes({
        Status: status,
        CreatedBy: this.currentUser.displayName,
        CreationDate: new Date(Date.now()).getTime(),
        Region: region,
        AssetID: assetId,
        AssetName: assetName,
        WorkOrderDesc: "",
        Comment: "",
        GeomWKT: JSON.stringify(geomString)
      });
      featurese.push(features);

      this.ftrLyrTblMaintenance
        .applyEdits(featurese, null, null)
        .then(
          function (newrow) {
            // updateing MaintID for newly inserted Maintanance record
            const featureseAssetID = [];
            const featuresA = new this.Graphic();
            featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
            featureseAssetID.push(featuresA);
            this.ftrLyrTblMaintenance
              .applyEdits(null, featureseAssetID, null)
              .then(function (newrow) {
                console.log(newrow);
                //opent task window here
                if (this.dataEditorSettings.IsOpenCreateEditTaskForm) {
                  const assetId = featuresA.attributes.MaintID;
                  this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "MaintID", assetId).subscribe((results: any) => {
                    this.objGlobals.selectedTask = results.features[0].attributes;
                    this.objGlobals.userSelectedActivity = "New";
                    this.selectedActiveAsset = null;
                    this.objGlobals.regions = this.objAssetDetailService.getRegions(this.activeAsset);
                    this.showtask = true;
                    this.objGlobals.activeAsset = this.activeAsset;
                    this.eventEmitersService.createTaskOpened(true);
                  });
                }
              }.bind(this))
              .catch((error) => {
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              });
            // End
            const actfeaturese = [];
            const actfeatures = new this.Graphic();
            actfeatures.setAttributes({
              MaintID: "MNT_" + newrow[0].objectId,
              Asset: this.assetTypeId,
              Activity: this.dataEditorSettings.taskType,
              Description: " ",
              ModifiedBy: this.currentUser.username
            });
            actfeaturese.push(actfeatures);
            this.ftrLyrActivity.applyEdits(actfeaturese, null, null).then(
              function (newrow) {
                this.eventEmitersService.closeModelWindow();
                console.log(newrow);
              }.bind(this)
            );
            if (this.wrkStatus === "Closed" && (this.activity === "Replace" || this.activity === "Retire")) {
              features.attributes.OBJECTID = newrow[0].objectId;
              this.task.completeTask(features.attributes);
            }
            // this.loadSelectedParentFeatureInView();
          }.bind(this)
        )
        .catch(function (error) {
          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        });
    } catch (error) {
      console.error(error);
    }
  }
  getInputType(property, type) {
    if (
      property == "esriFieldTypeOID" ||
      property == "esriFieldTypeGlobalID" ||
      (type == "esriFieldTypeString" && property == "AssetSubType") ||
      (type == "esriFieldTypeString" && property != "AssetSubType") ||
      property == "AssetCode"
    ) {
      return "text";
    } else if (property != "DoNotReplace" && (type == "esriFieldTypeInteger" || type == "esriFieldTypeDouble" || type == "esriFieldTypeSmallInteger")) {
      return "number";
    } else if (type == "esriFieldTypeDate") {
      return "date";
    }
    return "text";
  }
  getDate(input, type) {
    if (type == "esriFieldTypeDate") {
      // convert Epoch time to timestamp format
      const newDate = new Date(input).getTime();
      return newDate;
    }
    return input;
  }
  getLayerId(data, layerName) {
    this.assetTypeId = data.filter(
      function (hero) {
        return hero.name === layerName;
      }.bind(this)
    )[0].id;
  }
  onPicError(event) {
    event.target.src = "assets/images/nopic.png";
  }
  // JIRA ID :- 61
  minimizeAssetDetail() {
    try {
      this.isShown = !this.isShown;
      const panel = document.querySelector("#avDetailPanelDiv");
      const avDetailPanelDiv = document.querySelector("#assestDetailDragDiv");
      if (!this.isShown) {
        avDetailPanelDiv.setAttribute("style", "height: 57px;");
        panel.setAttribute("style", "display:none;")
      } else {
        avDetailPanelDiv.setAttribute("style", "height: 100%;");
        
        panel.setAttribute("style", "display:flex;")
      }
    } catch (error) {
      console.error(error);
    }
  }

  //TSDRSAM-1426
  generateAssetChangeDetails(obj1, obj2) {
    let deltaResult = { before: {}, after: {} };

    Object.keys(obj1).forEach(function (key) {
      if (obj1[key] !== obj2[key]) {
        deltaResult.before[key] = obj1[key];
        deltaResult.after[key] = obj2[key];
      }
    });

    return deltaResult;
  }

  //get lidar assets will query the feature layer service to return lidar runs.
  //we then toggle the lidar iframe with the data we receive about the runs.
  loadLidarData(){
    //[2] represents a parent asset's geometry, [3] is for child assets that have their parents geometry
    const activeAssetGeometry = this.activeAsset[2].geometry ?? this.activeAsset[3].geometry;
    const buffer = this.genericMapMethodService.getBuffer(activeAssetGeometry, 100, "meters");
    if (buffer.rings && buffer.rings[0]) {
      const extent = buffer.getExtent();
      this.query.getLidarAssets(extent.xmax, extent.xmin, extent.ymax, extent.ymin).subscribe(res => {
        // only toggle lidar iframe if run was found near asset
        if(res != null && res.features != null && res.features[0] != null){
          //closest run - grabbing the first now, calc distance in future.
          const closestRun = res.features[0].attributes;
          //Custom 1 = Database; Custom 2 = Project; Custom 3 = Run;
          return this.eventEmitersService.toggleLidarIframe(activeAssetGeometry.y, activeAssetGeometry.x, closestRun.Custom1,  closestRun.Custom2,  closestRun.Custom3);
        }else{
          this.toast.info("No lidar data available near the selected asset.")
        }
      });
    }
  }
  showTaksTab(event) {
    this.showtask = false;
  }
}
