import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GISHttpClient } from "../helpers/gishttp-client";
import { BehaviorSubject, ReplaySubject, Observable } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class QueryLayers {
  public FeatureLayer;
  map: any;
  featureLayer: any;
  relationshipIds: any;
  resultAssetRelations: string[] = [];
  allFeatureLayers: any[] = [];
  RelationshipQuery: any;
  results: any;
  Draw: any;
  SimpleMarkerSymbol: any;
  Color: any;
  Graphic: any;
  SimpleLineSymbol: any;
  Point: any;
  webMercatorUtils: any;
  currentUser;
  baseGISUrl = environment.proxy;
  public lidarDataList$: BehaviorSubject<any>;


  constructor(private httpClient: GISHttpClient, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAssetsDetails(colName, value, objectID, layer) {
    const promise = new Promise((resolve) => {
      this.results = [];
      const layerName = layer.lyrObj.name;
      this.featureLayer = layer.lyrObj;

      if (this.featureLayer.url != null) {
        this.relationshipIds = this.getRelationshipIds(layer.lyrObj);

        if (this.relationshipIds) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.relationshipIds.length; i++) {
            const relatedQuery = new this.RelationshipQuery();
            // relatedQuery.definitionExpression = colName+"="+value;
            relatedQuery.objectIds = [objectID];
            relatedQuery.outFields = ["*"];
            relatedQuery.relationshipId = this.relationshipIds[i].id;
            this.getRelatedAssetInformation(relatedQuery, objectID, this.relationshipIds[i], colName).then((result: any) => {
              console.log("check here for related data");
              console.log(result);

              if (result) {
                if (result.length > 0 && this.FeatureLayer) {
                  const lyr = new this.FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.relationshipIds[i].relatedTableId);

                  for (let ii = 0; ii < result.length; ii++) {
                    result[ii]._layer = lyr;
                  }
                }

                if (this.relationshipIds[i].name.includes("Sign") || this.relationshipIds[i].name.includes("Light")) {
                  this.results.push({
                    Layer: this.relationshipIds[i].name,
                    RelatedData: result.filter((attr) => attr.attributes.Status !== "Inactive"),
                    // eslint-disable-next-line camelcase
                    layer_id: this.relationshipIds[i].relatedTableId
                  });
                } else {
                  this.results.push({
                    Layer: this.relationshipIds[i].name,
                    RelatedData: result,
                    // eslint-disable-next-line camelcase
                    layer_id: this.relationshipIds[i].relatedTableId
                  });
                }

                if (this.results.length === this.relationshipIds.length) {
                  const finalresult = {
                    Layer: layerName,
                    Fid: objectID,
                    RelatedAssets: this.results
                  };
                  // console.log(finalresult);
                  resolve(finalresult);
                }
              }
            });
          }
        }
      }
    });
    return promise;
  }
  getRelatedAssetInfo(layer, relatedQuery, objectID) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const promise = new Promise((resolve) => {
      layer.queryRelatedFeatures(relatedQuery, (relatedRecords) => {
        this.resultAssetRelations = [];

        if (relatedRecords[objectID] !== undefined) {
          relatedRecords[objectID].features.forEach((relatedAsset, key) => {
            this.resultAssetRelations.push(relatedAsset);

            if (key === relatedRecords[objectID].features.length - 1) {
              resolve(this.resultAssetRelations);
            }

            if (relatedRecords[objectID].features.length === 0) {
              resolve(this.resultAssetRelations);
            }
          });
        }

        if (relatedRecords[objectID] === undefined) {
          resolve([]);
        }
      });
    });
  }
  getRelationshipIds(featureLayer) {
    return featureLayer.relationships;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRelatedAssetInformation(relatedQuery, objectID, val, colName) {
    const promise = new Promise((resolve) => {
      this.featureLayer.queryRelatedFeatures(relatedQuery, (relatedRecords) => {
        this.resultAssetRelations = [];

        if (relatedRecords[objectID] !== undefined) {
          relatedRecords[objectID].features.forEach((relatedAsset, key) => {
            this.resultAssetRelations.push(relatedAsset);

            if (key === relatedRecords[objectID].features.length - 1) {
              resolve(this.resultAssetRelations);
            }

            if (relatedRecords[objectID].features.length === 0) {
              resolve(this.resultAssetRelations);
            }
          });
        }

        if (relatedRecords[objectID] === undefined) {
          resolve([]);
        }
      });
    });
    return promise;
  }
  getAllLayersInformation(url) {
    if (!url.includes(environment.proxy)) {
      url = environment.proxy + url;
    }

    return this.httpClient.get(url + "MapServer?f=pjson");
  }
  addAsset(layer, asset) {
    const promise = new Promise((resolve) => {
      layer.applyEdits([asset], null, null).then((results) => {
        resolve(results);
      });
    });
    return promise;
  }
  deleteAsset(layer, asset) {
    const promise = new Promise((resolve) => {
      layer.applyEdits(null, null, [asset]).then((results) => {
        resolve(results);
      });
    });
    return promise;
  }
  updateAsset(layer, asset) {
    let promise;

    // 11/20/2020 Manikantha Start
    if (layer) {
      promise = new Promise((resolve) => {
        layer.applyEdits(null, [asset], null).then((results) => {
          resolve(results);
        });
      });
    }

    // 11/20/2020 Manikantha End
    return promise;
  }
  retireAsset(layer, asset) {
    const attr = asset.attributes;
    attr.Status = "Inactive";
    attr.RetireDate = new Date(Date.now()).getTime();
    // 11/20/2020 Manikantha Start
    //asset.setAttributes(attr);
    asset.attributes = attr;
    // 11/20/2020 Manikantha Start
    this.updateAsset(layer, asset).then((result) => {
      console.log(result);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  replaceAsset(layer, asset, relatedFeatures, layerName) {
    // • Set Status as Inactive for asset classes
    const attr = asset.attributes;
    attr.Status = "Inactive";
    // 11/20/2020 Manikantha Start
    //asset.setAttributes(attr);
    asset.attributes = attr;
    // 11/20/2020 Manikantha Start
    layer.lyrObj = layer;
    // layer.lyrObj.name = 'Support';
    this.updateAsset(layer, asset).then((result) => {
      // Set Related Asset Status as Closed
      console.log(result);
    });
  }
  linkRelatedAssets(asset, relatedFeatures) {
    return new Promise(
      function (resolve) {
        const assetId = asset.attributes.AssetID;
        const data = relatedFeatures.filter(
          (a) =>
            (a.Layer.includes("Sign") || a.Layer.includes("Light") || a.Layer.includes("Documents")) &&
            // 11/20/2020 Manikantha Start
            a.RelatedData &&
            // 11/20/2020 Manikantha End
            a.RelatedData.length > 0
        );
        data.forEach(
          function (layer) {
            // eslint-disable-next-line camelcase
            const layer_id = layer.layer_id;
            layer.RelatedData.forEach(
              function (rltdAsset) {
                // rltdAsset.attributes.Status = 'Inactive'
                rltdAsset.attributes.ParentID = assetId;
                // const lyr = new this.featureLayer(
                //   this.currentUser.webServiceURL + "FeatureServer/" + layer_id
                // );
                // 11/20/2020 Manikantha Start
                // eslint-disable-next-line camelcase
                rltdAsset._layer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + layer_id);
                // 11/20/2020 Manikantha End
                this.updateAsset(rltdAsset._layer, rltdAsset);
                resolve([]);
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }

  rollBacklinkRelatedAssets(asset, relatedFeatures) {
    return new Promise(
      function (resolve) {
        const assetId = asset;
        const data = relatedFeatures.filter((a) => (a.Layer.includes("Sign") || a.Layer.includes("Light") || a.Layer.includes("Documents")) && a.RelatedData.length > 0);
        data.forEach(
          function (layer) {
            //const layer_id = layer.layer_id;
            layer.RelatedData.forEach(
              function (rltdAsset) {
                // rltdAsset.attributes.Status = 'Inactive'
                rltdAsset.attributes.ParentID = assetId;
                // const lyr = new this.featureLayer(
                //   this.currentUser.webServiceURL + "FeatureServer/" + layer_id
                // );
                this.updateAsset(rltdAsset._layer, rltdAsset);
                resolve([]);
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }

  //get lidar assets will by default query the test region
  getLidarAssets(xmax:number, xmin: number, ymax:number, ymin: number):Observable<any>{
    return this.httpClient.get(`${environment.proxy}${this.currentUser.webServiceURL}/FeatureServer/17/query?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&inSR=10211&outSR=102100&geometry='{{'xmax':${xmax}, 'ymin':${ymin}, 'xmin':${xmin}, 'ymax':${ymax}, 'spatialReference':{{'wkid':102100}}}}'`);
  }
  // replaceRelatedAsset(layer,asset){
  //     let attr = asset.attributes;
  //     attr['Status'] = 'Inactive';
  //     asset.setAttributes(attr)
  //     this.updateAsset(layer, asset);
  // }
}
