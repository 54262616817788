// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //Local
  apiBaseUrl: "https://localhost",
  baseHref: "/",
  apiroot: ":44354/",
  apiwebroot: "RSAMUI/",
  helproot: "RAMHelp/",
  gpService: "https://rsamuat.3m.com/arcgis/rest/services/Clients/ExportByRegion/GPServer/ExportByRegion",
  proxy: "https://rsamuat.3m.com/gisproxy/proxy.ashx?",
  // End Local

  // Prod  (uncomment this section for production server)
  //apiBaseUrl: "https://rsam.3m.com/",
  //apiroot: "RSAMAPI/",
  //apiwebroot: "RSAMUI/",
  //helproot: "RSAMHelp/",
  //gpService: "https://rsam.3m.com/arcgis/rest/services/Clients/ExportByRegion/GPServer/ExportByRegion",
  //proxy: "https://rsam.3m.com/proxy/proxy.ashx?",
  // end Prod

  // UAT  (uncomment this section for UAT server)
  // apiBaseUrl: "https://rsamuat.3m.com/",
  // apiroot: "RAMAPIUAT/",
  // apiwebroot: "RAMUIUAT/",
  // helproot: "RAMHelp/",
  // gpService: "https://rsamuat.3m.com/arcgis/rest/services/Clients/ExportByRegion/GPServer/ExportByRegion",
  // proxy: "https://rsamuat.3m.com/proxy/proxy.ashx?",
  // end UAT

  // QA  (uncomment this section for QA region)
  // apiBaseUrl: "https://rsamuat.3m.com/",
  // apiroot: "RSAMAPIQA/",
  // apiwebroot: "RSAMUIQA/",
  // helproot: "RSAMHelpQA/",
  // gpService: "https://rsamuat.3m.com/arcgis/rest/services/Clients/ExportByRegion/GPServer/ExportByRegion",
  // proxy: "https://rsamuat.3m.com/proxy/proxy.ashx?",
  // end QA

  // SIT
  // apiBaseUrl: "http://35.154.107.211/",
  // apiroot: "SMSWebApi/",
  // apiwebroot: "SMSWebApp/",

  authChangePasswordWithUsername: "/Authentication/PasswordChange/User/",

  // Development Environment
  // apiroot: "SMSService_Dev/",
  // apiwebroot: "SMSWebAppDev/",
  apiKeyToken: "Bearer ",
  // apiroot: 'SMSServiceWeb/'
  // API URLs ----------------------------------------------
  // getLayersByClientUser: 'Clients/21/Users/8/Layers',
  // API URLs ends -----------------------------------------
  SetTimeOut: 1800,
  SuperAdminId: 1004,
  getClientlist: "Clients",
  getClient: "Client",
  getUserlist: "Users",
  getUsernamesByClient: "UsersLimited",
  getUser: "User",
  getRoleslist: "Roles",
  getSolutionTiers: "SolutionTiers",
  getLanguages: "Languages",
  getUpdateUser: "Authentication/UpdateUser/Client",
  getSignup: "Authentication/SignUp",
  queryBuilder: "QueryBuilder",
  // getRegionsList: 'Regions',
  getRegionAccess: "UsersRegionAccess",
  getLayerAccess: "UsersLayerAccess",
  getImage: "Images",
  GetImageAsBase64: "GetImages", // Added by Abhilash Jira Id:-40 on 10/11/2021
  authentication: "Authentication",
  authChangePassword: "Authentication/ChangePassword/Client",
  AccountUnlockPost: "Authentication/AccountUnlockPost", // Added by Abhilash Jira Id:-57 on 10/11/2021
  GetETLJobSummary: "GetETLJobSummary",
  GetETLJobDetails: "GetETLJobDetails",
  MarkJobAsReviewed: "MarkJobAsReviewed",
  democratizeReport: "Democratize/",
  activityTotalReport: "ActivityTotalReport",
  sheetCountReport: "SheetCountReport",
  activityByReasonReport: "ActivityByReasonReport",
  assetRetireReport: "AssetRetireReport",
  featureServer: "FeatureServer/",
  getEmployeeActionLog: "GetEmployeeActionLog",
  getBrushPickupData: "GetBrushPickupData",
  //added by sowjanya JIRA ID-51,52 on 27/09/2021
  getSummaryReport: "GetSummaryReport",
  //added by sowjanya JIRA ID:-48 on 27/09/2021
  getCustomReportARTESP: "GetCustomReportARTESP",
  getProjectReplaceAssetDetails: "GetProjectReplaceAssetDetails",
  specificAssetReport: "SpecificAssetReport",
  ExpectedLife: "SetExpectedLifeByConfigurationChange/Users/",
  expectedLifeNewAsset: "ExpectedLife/AssetClass/",
  getTOC: "TOC",
  getReport: "Reports",
  helpTicket: "Help/Ticket",
  helpTicketByTokenID: "Help/Ticket/",
  updateRequestByAdmin: "Help/Ticket/SolutionAdmin/",
  crowdComplaint: "CrowdSource/CrowdComplaints",
  mapServer: "MapServer/",
  getOpenTasks: "Task/GetOpenTask",
  getMaintananceTasks: "Task/GetDetailedMaintenance",
  weatherApiURL: "https://api.openweathermap.org/data/2.5/forecast?lat=<lat>&lon=<lon>&units=metric&appid=f319cca64be63b1204b24432750b4e43",
  queryAllPostFix: "/query?where=1=1&resultType=none&outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=false&f=json",
  queryAllPostFixGeomFalse: "/query?where=1=1&resultType=none&outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=false&returnGeometry=false&f=json",
  queryAllPostFixGeomTrue: "/query?where=1=1&resultType=none&outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=false&returnGeometry=true&f=json",
  queryDistinctValuesPostFix:
    "/query?where=1=1&resultType=none&returnGeodetic=false&outFields=<fieldNames>&returnGeometry=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=true&cacheHint=false&returnZ=false&returnM=false&returnExceededLimitFeatures=true&sqlFormat=none&f=json",

  routeLogRetryTimeout: 6,
  gpsTimeInterval: 7000, // ms
  gpsDistanceInterval: 10, // meters
  gpsAccuracyThreshold: 20, // meters

  weatherThreshold: {
    rain: {
      light: 1,
      moderate: 4,
      heavy: 6
    },
    wind: {
      light: 10,
      moderate: 25,
      heavy: 25
    },
    humidity: {
      light: 45,
      moderate: 70,
      heavy: 80
    },
    temp: {
      light: 10,
      moderate: 25,
      heavy: 40
    }
  },
  deleteMessage: "Are you sure want to delete?",
  DocumentSupportFormats: ["pdf", "jpg", "jpeg"],
  DocumentSupportFormatsAccept: "image/jpeg,application/pdf",
  newCustomer: "/CustomerSetup", //Added by Sowjanya for TSDRSAM-71
  //Added for Visualization layers
  addorUpdateVisualizationLayers: "VendorService/AddorUpdateVendorLayers",
  getVisualizationLayers: "VendorService/GetVendorLayers",
  GetAssetIconAsBase64: "GetAssetIcon", //Jira Id : 70
  GetRegexPattern: "GetAuthRegexPatterns",
  getRollbackData: "GetRollbackAssets"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
