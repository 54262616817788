import { Component, OnInit, HostListener } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsStyle } from "../system.reports.style";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-activitybyreason",
  templateUrl: "./activityByReason.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class ActivityByReasonComponent implements OnInit {
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public todayDate: Date = new Date();
  public currentUser: any;
  private tableAliases: any;
  faCalendar = faCalendar;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if((['span','input']).includes(event.target['localName']) &&
       (['abReRepCalInput1','abReRepCalSpan1','abReRepCalInput2','abReRepCalSpan2']).includes(event.target['id'])){
      (<HTMLInputElement>event.target).click();
    }
    //console.log("From Activity By Reason Keydown...");
  }
  //
  constructor(
    public systemReportsService: SystemReportsService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private reportStyle: SystemReportsStyle,private assetAttributesService: AssetAttributesService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate());
  }

  exportPDF(): void {
    try {
      if (this.startDate >= this.endDate) {
        Swal.fire(this.translateService.instant("Systemreports.alertactivitybyreason.dateentryvalidate"));
      } else {
        const data = "?FromDate=" + this.startDate.toLocaleString().split(",")[0] + "&ToDate=" + this.endDate.toLocaleString().split(",")[0];
        this.systemReportsService.getActivityByReasonReport(data).subscribe(
          (res) => {
            this.assetAttributesService.getTableAlias().subscribe((tblresults: any) => {
              this.tableAliases = tblresults.features;
              this.JSONToPDFConvertor(res, true);
            });
          },
          (err) => {
            Swal.fire("Please contact administrator. API is throwing an error");
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];

      if (res.tblActivityByReason.length > 0) {
        JSONData = res.tblActivityByReason;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertactivitybyreason.nodatafound"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const val = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const arrData = [];
      const tableheader = [];
      const tablebody = [];
      let tablerow = [];
      let tableRowGroupHeader = [];
      let total = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      const colHeaderGroupHeaderMapping: Record<string, string> = {
        clean: "t2",
        planning: "t9",
        replace: "t12",
        align: "t1",
        retire: "t15",
        hardware: "t5",
        inspect: "t6",
        collect: "t3",
        detailUpdate: "t4",
        new: "t7",
        relocate: "t10",
        repair: "t11",
        replaceBase: "t13",
        reshape: "t14",
        straighten: "t16",
        other: "t17",
        reinstall: "t18",
        total: "t19"
      };

      val.forEach((data) => {
        if (data.assetName !== null) {
          arrData.push(data);
        }
      });

      // This condition will generate the Label/Header
      if (ShowLabel) {
        // This loop will extract the label from 1st index of on array

        for (const index in arrData[0]) {
          if (index !== "assetName") {
            switch (index) {
              case "reason":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.activitytype"),
                  style: "tableHeader",
                  noWrap:true
                });
                break;
              case "clean":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.clean"),
                  style: "tableHeader"
                });
                break;
              case "planning":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.planning"),
                  style: "tableHeader"
                });
                break;
              case "replace":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.replace"),
                  style: "tableHeader"
                });
                break;
              case "align":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.align"),
                  style: "tableHeader"
                });
                break;
              case "retire":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.retire"),
                  style: "tableHeader"
                });
                break;
              case "hardware":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.hardware"),
                  style: "tableHeader"
                });
                break;
              case "inspect":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.inspect"),
                  style: "tableHeader"
                });
                break;
              case "collect":
                tableheader.push({ text: "Collect", style: "tableHeader" });
                break;
              case "detailUpdate":
                tableheader.push({ text: "Detail Update", style: "tableHeader" });
                break;
              case "new":
                tableheader.push({ text: "New", style: "tableHeader" });
                break;
              case "relocate":
                tableheader.push({ text: "Relocate", style: "tableHeader" });
                break;
              case "repair":
                tableheader.push({ text: "Repair", style: "tableHeader" });
                break;
              case "replaceBase":
                tableheader.push({ text: "Replace Base", style: "tableHeader" });
                break;
              case "reshape":
                tableheader.push({ text: "Reshape", style: "tableHeader" });
                break;
              case "straighten":
                tableheader.push({ text: "Straighten", style: "tableHeader" });
                break;
              case "other":
                tableheader.push({ text: "Other", style: "tableHeader" });
                break;
              case "reinstall":
                tableheader.push({ text: "Reinstall", style: "tableHeader" });
                break;
              case "total":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headeractivitybyreason.total"),
                  style: "tableHeader"
                });
                break;
            }
          }
        }

        tablebody.push(tableheader);
      }

      let assetClass = [];
      arrData.forEach((data) => {
        if (data.assetName !== "") {
          assetClass.push(data.assetName);
        }
      });

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      assetClass = assetClass.filter(distinct);

      // 1st loop is to extract each row
      for (let j = 0; j < assetClass.length; j++) {
        total = [];
        tableRowGroupHeader = [];
        const arr = arrData.filter((obj) => obj.assetName === assetClass[j]);
        const tableAlias = this.tableAliases.map(a => a['attributes']).filter((lyr) => lyr.Name === assetClass[j])[0].Alias;

        if (j === 0) {
          tableRowGroupHeader.push({
            colSpan: tableheader.length,
            text: tableAlias,
            style: "rowGroupHeader"
          });
        } else {
          tableRowGroupHeader.push({
            colSpan: tableheader.length,
            text: tableAlias,
            style: "rowGroupHeader",
            pageBreak: "before"
          });
        }

        //add blanks one less than total header columns
        for (let headerIndex = 1; headerIndex < tableheader.length; headerIndex++) {
          tableRowGroupHeader.push("");
        }

        tablebody.push(tableRowGroupHeader);

        const rowGroupTotal = {
          t1: 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t7: 0,
          t9: 0,
          t10: 0,
          t11: 0,
          t12: 0,
          t13: 0,
          t14: 0,
          t15: 0,
          t16: 0,
          t17: 0,
          t18: 0,
          t19: 0
        };

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          tablerow = [];
          //let rowGroupColName: string;

          // 2nd loop will extract each column and push in the table row
          for (const index in arr[i]) {
            if (index !== "assetName") {
              //TODO: Below block can be used in future to make this report truly dynamic
              // if(index !== "assetName" && index!== "reason"){
              //   rowGroupColName = index + '_total';
              //   rowGroupTotal[rowGroupColName] = rowGroupTotal[rowGroupColName] + arr[i][index];
              // }
              switch (index) {
                case "reason":
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "align":
                  rowGroupTotal.t1 = rowGroupTotal.t1 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "clean":
                  rowGroupTotal.t2 = rowGroupTotal.t2 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "collect":
                  rowGroupTotal.t3 = rowGroupTotal.t3 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "detailUpdate":
                  rowGroupTotal.t4 = rowGroupTotal.t4 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "hardware":
                  rowGroupTotal.t5 = rowGroupTotal.t5 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "inspect":
                  rowGroupTotal.t6 = rowGroupTotal.t6 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "new":
                  rowGroupTotal.t7 = rowGroupTotal.t7 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "planning":
                  rowGroupTotal.t9 = rowGroupTotal.t9 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "relocate":
                  rowGroupTotal.t10 = rowGroupTotal.t10 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "repair":
                  rowGroupTotal.t11 = rowGroupTotal.t11 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "replace":
                  rowGroupTotal.t12 = rowGroupTotal.t12 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "replaceBase":
                  rowGroupTotal.t13 = rowGroupTotal.t13 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "reshape":
                  rowGroupTotal.t14 = rowGroupTotal.t14 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "retire":
                  rowGroupTotal.t15 = rowGroupTotal.t15 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "straighten":
                  rowGroupTotal.t16 = rowGroupTotal.t16 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "other":
                  rowGroupTotal.t17 = rowGroupTotal.t17 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "reinstall":
                  rowGroupTotal.t18 = rowGroupTotal.t18 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
                case "total":
                  rowGroupTotal.t19 = rowGroupTotal.t19 + arr[i][index];
                  tablerow.push({ text: arr[i][index], style: "tableContent" });
                  break;
              }
            }
          }

          // add a line break after each row
          tablebody.push(tablerow);
        }

        total.push({
          text: this.translateService.instant("Systemreports.headeractivitybyreason.total"),
          style: "tableHeader"
        });

        //TODO: Below block can be used in future to make this report truly dynamic. Have to also make width array dynamic
        // for (const index in arrData[0]) {
        //   if(index !== "assetName" && index!== "reason"){
        //     total.push({ text: rowGroupTotal[index + '_total'], style: "tableHeader" },)
        //   }
        // }

        for (const index in arrData[0]) {
          const colTotalKey = colHeaderGroupHeaderMapping[index];

          if (colTotalKey !== undefined) {
            total.push({ text: rowGroupTotal[colTotalKey.toString()], style: "tableHeader" });
          }
        }

        tablebody.push(total);
      }

      const dd = {
        pageSize: "A4",
        pageOrientation: "landscape",
        footer: currentFooter,
        content: [
          {
            text: this.translateService.instant("Systemreports.headeractivitybyreason.headingtxt"),
            style: "header"
          },
          {
            stack: [
              {
                text: [
                  {
                    text: this.translateService.instant("Systemreports.headeractivitybyreason.fromdate") + "\xa0",
                    bold: true
                  },
                  {
                    text: this.startDate.toLocaleString().split(",")[0]
                  },
                  {
                    text: this.translateService.instant("Systemreports.headeractivitybyreason.todate") + "\xa0",
                    bold: true
                  },
                  {
                    text: this.endDate.toLocaleString().split(",")[0]
                  }
                ]
              }
            ],
            style: "subheader"
          },
          {
            style: "tableExample",
            alignment: "justify",
            table: {
              heights: 20,
              headerRows: 1,
              body: tablebody
            },
            pageOrientation: "landscape",
            layout: currentLayoutStyle
          }
        ],
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
