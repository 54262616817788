import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MMMAdminService } from 'src/app/administrator/administrator.reports.service';
import { ETLJob } from '../change-report.model';


@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {

  etlJobList$ : Observable<ETLJob[]>;
  formGroup: UntypedFormGroup;
  reviewedFormGroup: UntypedFormGroup;
  
  @Output() jobIdSelected = new EventEmitter<number>();
  constructor(private formBuilder: UntypedFormBuilder, private adminService : MMMAdminService) { }

  ngOnInit() {
    this.etlJobList$ = this.adminService.getJobDetails();
    this.createForm();
  }

  //create initial form
  createForm() {
    this.formGroup = this.formBuilder.group({
      'jobId': [null, [Validators.required]]
    });
  }

  //get jobid of form.
  get jobId() {
    return this.formGroup.get('jobId') as UntypedFormControl
  }

  //Submission methods
  onSubmit() {
    this.jobIdSelected.emit(this.jobId.value);
  }
}
