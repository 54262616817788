<div aria-label="taskTableHeader" role="tabpanel">

    <app-heading-component 
    [headingText]="'My-maintenance.taskTableView' | translate" 
    [variant]="'none'" 
    [tooltipTwo]="'Edit'"
    [tooltipThree]="'Close'"
    (actionThree)="closeSmartTable()"
    [iconThree]="'cross'"
    [iconTwo]="'pencil'"
    [iconTwoDropdown]="false"
    (actionTwo)="showToolkit()"
    [checkboxlabel1]="'My-maintenance.input.assigntome' | translate " 
    [checkboxChecked]="checkboxChecked"
    (checkboxClick)="assignedToChanged($event)"
    [isTaskViewerHeader]="true"
    > </app-heading-component>
    
  <div class="row" role="table">
    <div class="col-12">
      <div style="background-color: Gray 10">
        <div style="display: flex; flex-direction: row">
          <div class="myTask" style="overflow: hidden; flex-grow: 1">
            
            <mat-tab-group (selectedTabChange)="tabChanged($event)">
              <mat-tab label="{{'My-maintenance.tab.open' | translate }}" > 
                <div class="tab-content">
                <ag-grid-angular
                  #agOpenGrid
                  style="width: 100%;height:229px !important;"
                  class="ag-theme-balham mtTableHeight"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [gridOptions]="gridOptions"
                  [rowSelection]="rowSelection"
                  [frameworkComponents]="frameworkComponents"
                  (gridReady)="onGridReadyOpen($event)"
                  (selectionChanged)="onSelectionChangedOpen($event)"
                  [rowData]="rowData"
                  [columnDefs]="columnDefsOpen">
              
                </ag-grid-angular>
              </div>
                    </mat-tab>
               
              <mat-tab label="{{'My-maintenance.tab.optimized' | translate }}">
                <div class="tab-content">
                <ag-grid-angular
                #agOptGrid
                style="width: 100%;height:229px !important;"
                class="ag-theme-balham mtTableHeight"
                [pagination]="true"
                [paginationPageSize]="10"
                [gridOptions]="gridOptions"
                [rowSelection]="rowSelection"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReadyOpt($event)"
                (selectionChanged)="onSelectionChangedOpt($event)"
                [rowData]="rowData"
                [columnDefs]="columnDefsOpt"
              >
              </ag-grid-angular></div>
              </mat-tab>
              <mat-tab label="{{'My-maintenance.tab.completed' | translate}}">
                <ag-grid-angular
                  #agClosedGrid
                  style="width: 100%;height:229px !important;"
                  class="ag-theme-balham mtTableHeight"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [gridOptions]="gridOptions"
                  [rowSelection]="rowSelection"
                  [frameworkComponents]="frameworkComponents"
                  (gridReady)="onGridReadyClosed($event)"
                  (selectionChanged)="onSelectionChangedClosed($event)"
                  [rowData]="rowDataClosed"
                  [columnDefs]="columnDefsClosed"
                >
                </ag-grid-angular>
                 
              </mat-tab>

             
            </mat-tab-group>
           
          </div>

          <div *ngIf="activityToolKitListGroup.length && showtools" style="background-color: #fff; padding: 2rem; width: 20%">
            <div class="card">
              <div class="card-header">
                {{ "My-maintenance.tab.resourcelist" | translate }}
              </div>
              <div class="card-body" style="max-height: 250px; overflow-y: auto">
                <ul class="list-group">
                  <li data-forTab="yes"
                    *ngFor="let tool of activityToolKitListGroup; let i = index"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {{ tool.toolName }}
                    <h4>
                      <span class="badge badge-pill"> {{ tool.count }}</span>
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showtask" role="navigation" aria-label="Show Task">
  <create-tasks
    (showTasks)="showTaksTab($event)"
    [Graphic]="Graphic"
    [FeatureLayer]="FeatureLayer"
  >
  </create-tasks>
</div>
