import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sms-baselife",
  templateUrl: "./baseLife.component.html",
  styleUrls: ["../retro-admin.component.scss"]
})
export class BaseLifeClassComponent implements OnInit {
  @Input() map: any;
  @Input() FeatureTable;
  @Input() FeatureLayer;
  @Input() Graphic;
  private currentUser: IUser;
  public LayerList: any[];
  public RetroAdmin: any[];
  layerListSelected;
  retroAdminSelected;
  public RetroBaseLifeTable: any[];
  public BaseLifeTable = false;
  selectedRowIndex = -1;
  domainValue;
  private adFeatureTable;
  private adFeatureLayer;
  private updates = [];
  private add = [];
  private delete = [];
  private loading = true;
  private selectedLayer: any;
  private tableData: any;
  private DomainValues: any = [];
  public domainValuesCode = [];
  inputsm;
  OnClicked = [];

  constructor(private renderer: Renderer2, private authenticationService: AuthenticationService, private assetAttributesService: AssetAttributesService, private translateService: TranslateService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.assetAttributesService.getELifeBaseLifeConfig(this.currentUser.serviceLyrInfo.baseLifeConfigId).subscribe((results: any) => {
      if (results.features.length > 0) {
        this.init(results);
      }
    });
    this.assetAttributesService.getDomainValues().subscribe((res: any) => {
      this.DomainValues = res.domains;
    });
    this.adFeatureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.baseLifeValuesId);
  }

  init(res) {
    const result = res.features;
    this.LayerList = [];
    result.forEach((data) => {
      this.LayerList.push(data.attributes);
    });
  }

  onChangeLayerList() {
    try {
      this.RetroAdmin = [];
      this.clear();
      this.assetAttributesService.getELSheetingType(this.currentUser.serviceLyrInfo.baseLifeConfigId, "AssetClass", this.layerListSelected.AssetClass).subscribe((res: any) => {
        if (res.features.length > 0) {
          res.features.forEach((data) => {
            this.RetroAdmin.push(data.attributes);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  onChangeAdminList() {
    try {
      this.RetroBaseLifeTable = [];
      const values = this.DomainValues.filter((obj) => obj.name.includes(this.retroAdminSelected.BLF_Domain));
      this.domainValuesCode = values[0].codedValues;
      this.assetAttributesService.getTableValues(this.currentUser.serviceLyrInfo.baseLifeValuesId, "BLF_Domain", this.retroAdminSelected.BLF_Domain).subscribe((res: any) => {
        if (res.features.length > 0) {
          this.BaseLifeTable = true;
          res.features.forEach((data) => {
            this.RetroBaseLifeTable.push(data.attributes);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  TableOnClick(i) {
    try {
      this.selectedRowIndex = i;
      this.OnClicked.push(i);

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      this.OnClicked = this.OnClicked.filter(distinct);
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    this.BaseLifeTable = false;
    this.selectedRowIndex = -1;
    this.domainValuesCode = [];
    this.OnClicked = [];
  }

  SaveEdits() {
    try {
      Swal.fire({
        title: "Are you sure want to save data?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.SaveEditsData();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  SaveEditsData() {
    try {
      const saveData = [];
      this.RetroBaseLifeTable.forEach((data, key) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.OnClicked.length; i++) {
          if (key === this.OnClicked[i]) {
            saveData.push([
              { OBJECTID: data.OBJECTID },
              { AssetClass: data.AssetClass },
              // eslint need to fix
              // eslint-disable-next-line camelcase
              { BLF_Domain: data.BLF_Domain },
              // eslint-disable-next-line camelcase
              { BLF_Domain_Code: data.BLF_Domain_Code },
              { oldValue: data.BaseLife },
              {
                newValue: parseFloat((document.getElementById("inputsm_" + this.OnClicked[i]) as HTMLInputElement).value)
              }
            ]);
          }
        }
      });
      const APIDatasSave = [];
      const featuresSave = [];
      saveData.forEach((data) => {
        const featureSave = new this.Graphic();
        const updateAssetAttr: any = [];
        updateAssetAttr.OBJECTID = data[0].OBJECTID;
        updateAssetAttr.BaseLife = data[5].newValue;
        updateAssetAttr.UpdatedBy = this.currentUser.username;
        updateAssetAttr.UpdatedOn = new Date(Date.now()).getTime();
        featureSave.setAttributes(updateAssetAttr);
        featuresSave.push(featureSave);
        const APIDataSave = {
          assetClass: "string",
          blFDomain: "string",
          blFDomainCode: "string",
          modifierDomain: "string",
          modifierDomainCode: "string",
          oldValue: 0,
          newValue: 0
        };
        APIDataSave.assetClass = data[1].AssetClass;
        APIDataSave.blFDomain = data[2].BLF_Domain;
        APIDataSave.blFDomainCode = data[3].BLF_Domain_Code;
        APIDataSave.newValue = data[5].newValue;
        APIDataSave.oldValue = data[4].oldValue;
        APIDatasSave.push(APIDataSave);
      });
      this.adFeatureLayer.applyEdits(
        null,
        featuresSave,
        null,
        (res) => {
          this.assetAttributesService.postExpectedLifeValues(this.currentUser.userId, APIDatasSave).subscribe(
            (evt) => {
              Swal.fire({
                title: this.translateService.instant("Userscreens.alertbaselife.changesdone"),
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok"
              }).then((result) => {
                this.clear();
                this.layerListSelected = [];
                this.retroAdminSelected = [];
              });
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
