import { Injectable } from "@angular/core";
@Injectable()
export class QueryBuilderConstants {
  public userFields = ["Worker", "Installer", "Inspector", "ModifiedBy", "CreatedBy"];
  public getOperators() {
    const oprtrs = [
      //{ id: "", name: "Select Operator" },
      { id: "=", name: "Equals" },
      { id: "!=", name: "Not Equal To" },
      { id: ">", name: "Greater Than" },
      { id: ">=", name: "GT/Equal To" },
      { id: "<", name: "Less Than" },
      { id: "<=", name: "LT/Equal To" },
      { id: "LIKE", name: "Contains" }
    ];
    return oprtrs;
  }
  public getJoinType() {
    const jointype = [{ name: "AND" }, { name: "OR" }];
    return jointype;
  }
  public getSelectBehaviorValues() {
    const selectBehaviors = ["New Selection", "Add to Selection", "Remove from Selection"];
    return selectBehaviors;
  }
  public getReportFormat() {
    const reportFormat = [
      { name: "Adobe PDF", id: 1 },
      { name: "Microsoft Excel", id: 2 }
    ];
    return reportFormat;
  }
  getQueryPrefix(layer) {
    let fieldLabel = "";

    switch (layer) {
      case "Sign":
        fieldLabel = "L1.";
        break;
      case "Light":
        fieldLabel = "L2.";
        break;
      case "Maintenance":
        fieldLabel = "Mnt.";
        break;
      case "Assessment":
        fieldLabel = "Asm.";
        break;
      case "Activity":
        fieldLabel = "Act.";
        break;
      default:
        fieldLabel = "L.";
    }

    return fieldLabel;
  }
  getQueryTables(prefix, layer) {
    let fieldLabel = "";

    switch (prefix) {
      case "L1":
        fieldLabel = "Sign";
        break;
      case "L2":
        fieldLabel = "Light";
        break;
      case "Mnt":
        fieldLabel = "Maintenance";
        break;
      case "Asm":
        fieldLabel = "Assessment";
        break;
      case "Act":
        fieldLabel = "Activity";
        break;
      default:
        fieldLabel = layer;
    }

    return fieldLabel;
  }
  getFunctionName(event) {
    if (event === "Support Inventory Report") {
      return "Support";
    } else if (event === "Barrier Inventory Report") {
      return "Barrier";
    } else if (event === "PavementMarking Inventory Report") {
      return "PavementMarking";
    } else if (event === "Guardrail Inventory Report") {
      return "Guardrail";
    } else if (event === "CrashCushion Inventory Report") {
      return "CrashCushion";
    } else if (event === "EndTreatment Inventory Report") {
      return "EndTreatment";
    } else if (event === "Assessment Inventory Report") {
      return "Assessment";
    } else if (event === "Light Inventory Report") {
      return "Light";
    } else if (event === "Sign Inventory Report") {
      return "Sign";
    } else if (event === "Maintenance Inventory Report") {
      return "Maintenance";
    } else if (event === "PavementMarking_Symbol Inventory Report") {
      return "PavementMarking_Symbol";
    } //9/11/20 jcc this option was missing
  }
}
