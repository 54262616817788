<!-- Updated by Sowjanya for TSDRSAM-71-->
<div class="card">
  <div class="card-body admin-model-height formbg-d2">
      <div class="text-dark">
          <div class="form-row mt-3">
              <div class="col-2">
                  <label class="text-green"> Select Agency</label>
              </div>
              <div class="col-3">
                  <select class="form-control" [(ngModel)]="AgencyListSelected" (change)="onChange()">
        <option [ngValue]="undefined" disabled selected> Please select exist Agency... </option>
        <option *ngFor="let agencyList of AgencyList" [ngValue]="agencyList">
          {{ agencyList.clientName }}
        </option>
      </select>
              </div>
          </div>
          <div class="form-row my-3">
              <div class="col-2">
                  <label class="text-green"> Customer Name</label>
              </div>
              <div class="col-3">
                  <input class="form-control" type="text" placeholder="Enter customer name…" [(ngModel)]="customerName" autocomplete="off" aria-label="Customer Name">
              </div>
          </div>
          <div class="container">
              <div class="form-control-customer" *ngIf="!AgencyListSelected">
                  <div class="form-row my-3">
                      <div class="col-2">
                          <label>File Upload</label></div>
                      <div class="col-3">
                          <input type="file" accept=".zip" id="file" (change)="handleFileInputs($event.target.files)" aria-label="File Upload"></div>

                  </div>
                  <div class="row">
                      <div class="radio-inline-col-12" style="margin-left: 180px;">
                          <div class="form-check form-check-inline">
                              <input type="radio" class="form-check-input"  name="template" value="New" [(ngModel)]="template" aria-labelledby="default">
                              <label class="form-check-label" for="defaultTemplate" id="default">Default Template</label>
                          </div>
                          <div class="form-check form-check-inline ms-3">
                              <input type="radio" class="form-check-input"  name="template" value="Existing" [(ngModel)]="template" aria-labelledby="custom">
                              <label class="form-check-label" for="customTemplate" id="custom">Custom Template</label>
                          </div>
                      </div>
                  </div>

                  <div class="d-flex justify-content-center align-items-center" style="margin-right: 640px;margin-top: 20px;">
                      <button type="button" class="btn btn-primary" [disabled]="temp==true" (click)="Upload()">Generate</button>
                      <!-- <button type="button" class="btn btn-primary" (click)="Upload()">Generate</button> -->
                  </div>
              </div>


          </div>
      </div>
      <div class="form-control-agencyList" *ngIf="AgencyListSelected || temp==true">
          <!-- <div class="form-control-agencyList"> -->
          <!-- <div class="form-control-agencyList"> -->
          <div class="form-row my-3">
              <div class="form-group col-sm-3">
                  <label class="text-green">Solution Tier</label>
                  <select class="form-control" (change)="ChangeSolutionTier()" [(ngModel)]="SolutionTier" [attr.disabled]="SolutionTierChange ? 'disabled' : null">
                <option [ngValue]="undefined" disabled selected> Please select Solution Tier... </option>
                <option *ngFor="let solutionTierValue of SolutionTierValue" [ngValue]="solutionTierValue">
                  {{ solutionTierValue }}
                </option>
              </select>
              </div>
              <div class="form-group col-sm-3">
                  <label class="text-green">Primary Agency Language</label>
                  <select class="form-control" [(ngModel)]="PrimaryAgencyLanguage">
        <option [ngValue]="undefined" disabled selected> Please select Primary Agency Language... </option>
        <option *ngFor="let PALValue of adminGlobal.PALValues" [ngValue]="PALValue">
          {{PALValue.languageName}}
        </option>
      </select>
              </div>
              <div class="form-group col-sm-3">
                  <label class="text-green" for="inputState">Secondary Agency Language</label>
                  <select class="form-control" [(ngModel)]="SecondaryAgencyLanguage" [disabled]="SecondaryAgencyLanguageDisable">
        <option [ngValue]="undefined" disabled selected> Please select Secondary Agency Language... </option>
        <option *ngFor="let PALValue of adminGlobal.PALValues" [ngValue]="PALValue">
          {{PALValue.languageName}}
        </option>
      </select>
              </div>
          </div>
          <div class="form-row ">

              <div class="form-group col-sm-3">
                  <label class="text-green">Agency Web Service</label>
                  <input id="agencyWebService" class="form-control" [(ngModel)]="serviceURL" placeholder="Enter Agency Web Service …" autocomplete="off" aria-label="Agency Web Service"/>
              </div>
              <div class="form-group col-sm-3">
                  <label class="text-green">Agency Spatial Database</label>
                  <input id="agencySpatialDatabase" class="form-control" [(ngModel)]="agencySpatialDatabase" placeholder="Enter Agency Spatial Database…" autocomplete="off" aria-label="Agency Spatial Database"/>
              </div>
              <div class="col-sm-6 d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-secondary mr-2" (click)="clear()">Clear</button>
                  <button type="button" class="btn btn-secondary mr-2" (click)="add()">Add Row</button>
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-backdrop="false" data-target="#exampleModalCenter" (click)="uploadAgencyLanguage()">New Agency Language</button>
                  <div *ngIf="openlang">
                      <sms-LangConfig (closeform)="close($event)"></sms-LangConfig>
                  </div>
              </div>

          </div>
          <div class="container-fluid" *ngIf="temp==true && !AgencyListSelected" style="border:1px solid #1b0c0c;">
              <!-- <div class="container-fluid" *ngIf="!AgencyListSelected" style="border:1px solid #4e1515;"> -->
              <b>3MAdmin User:</b> <br /><br />
              <div class="form-row">
                  <div class="form-group col-sm-3">
                      <label for="firstname">FirstName:</label>
                      <input [(ngModel)]=firstName type="text" id="adminGlobal.AdminData['firstName']" name="adminGlobal.AdminData['firstName']">
                  </div>
                  <div class="form-group col-sm-3">
                      <label for="lastname">LastName:</label>
                      <input [(ngModel)]="lastName" type="text" id="adminGlobal.AdminData.['lastName']" name="adminGlobal.AdminData.['lastName']">
                  </div>
              </div>
              <br/>
              <div class="form-row">
                  <div class="form-group col-sm-3">
                      <label for="username">Username:</label>
                      <input [(ngModel)]="username" type="text" id="username" name="username">
                  </div>
                  <div class="form-group col-sm-3">
                      <label for="password">Password:</label>
                      <input [(ngModel)]="password" type="password" id="password" name="password">
                  </div>
              </div>
          </div>
          <!--Start Agency Details Table -->
          <div class="mt-3">
              <table class="table">
                  <thead>
                      <tr>
                          <th width="15%">Agency Name</th>
                          <th width="15%">Solution Tier</th>
                          <th width="35%">Agency Web Service</th>
                          <th width="10%">Agency Spatial Database</th>
                          <th width="10%">Primary Agency Language</th>
                          <th width="15%">Secondary Agency Language</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let agencyDetail of agencyDetails; index as i">
                          <td>{{ agencyDetail.clientName }} </td>
                          <td>{{ agencyDetail.clientType }}</td>
                          <td>{{ agencyDetail.webServiceUrl }} </td>
                          <td>{{ agencyDetail.clientDb }}</td>
                          <td>
                              <div *ngFor="let PALValue of adminGlobal.PALValues;">
                                  <div *ngIf="PALValue.languageID === agencyDetail.primaryLanguageID">
                                      {{ PALValue.languageName }}
                                  </div>
                              </div>
                          </td>
                          <td>
                              <div *ngFor="let PALValue of adminGlobal.PALValues;">
                                  <div *ngIf="agencyDetail.secondaryLanguageID !== null">
                                      <div *ngIf="PALValue.languageID === agencyDetail.secondaryLanguageID">
                                          {{ PALValue.languageName }}
                                      </div>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>