import { Component, ViewChild, TemplateRef, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { GalleryItem } from "@ngx-gallery/core";

@Component({
  selector: "app-sms-documentviewer",
  templateUrl: "./documentViewer.component.html",
  styleUrls: ["../../select.component.scss"]
})
export class DocumentViewerComponent implements OnInit {
  /** Variables */

  @ViewChild("itemTemplate") itemTemplate: TemplateRef<any>;
  @Input() photosData: any;
  @Input() currentUser: any;
  @Output() SelectedImageIndex = new EventEmitter();
  @Output() PhotoDataItems = new EventEmitter();

  imagesdata: any;
  items: GalleryItem[];
  imageIndex: any;

  currIndex: any;
  samples: any;
  imageSrc: any;

  /** End */

  ngOnInit(): void {
    this.imageGenerate();
  }

  EventChange(event) {
    this.imageSrc = event.value;
    this.SelectedImageIndex.emit(this.imageSrc);
  }
  imageGenerate(data?) {
    try {
      this.samples = [];
      const photoData = data ? data : this.photosData;
      // TSDRSAM-510 Manikantha V
      if (photoData.length > 0) {
        photoData.forEach((photo) => {
          this.samples.push({
            label: photo.attributes.Filename,
            url: "http://cpcinpudv005250/SMSWebApp/Images/" + this.currentUser.clientId + "/" + photo.attributes.OBJECTID + "." + photo.attributes.Filename.split(".")[photo.attributes.Filename.split(".").length - 1],
            OID: photo.attributes
          });
        });
      }

      this.imageSrc = this.samples[0].url;
      this.SelectedImageIndex.emit(this.imageSrc);
      this.PhotoDataItems.emit(this.samples);
    } catch (error) {
      console.error(error);
    }
  }
}
