// import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { GenericLayerService } from "../services/genericLayer.service";
import { environment } from "src/environments/environment";
import { IUser } from "../interface/user";
import { SMSHttpClient } from "../helpers/http-client";
import { map } from "rxjs/operators";
import { IRequestOptions } from "src/app/interface/requestOption";

export class TranslationLoaderService {
  public currentUserSubject: BehaviorSubject<IUser>;
  public langid;
  public accessTokenObj;

  public options: IRequestOptions;
  public languageContent: any[];
  constructor(private http: SMSHttpClient, private gen: GenericLayerService) {
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem("currentUser")));
    this.langid = this.currentUserSubject.value.languageID;
    this.accessTokenObj = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentUser_token")));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTranslation(lang: string): Observable<any> {
    this.setTokenInHeader(this.options);
    return this.http.get(environment.apiBaseUrl + environment.apiroot + environment.getLanguages + "/" + this.langid, this.options).pipe(
      map((response: any) => {
        // console.log(response);
        return response.languageContent;
      })
    );
  }
  private setTokenInHeader(options: IRequestOptions) {
    options = options
      ? options
      : ({
          headers: new HttpHeaders()
        } as IRequestOptions);
    options.headers = options.headers.set("Authorization", environment.apiKeyToken + this.accessTokenObj.value.accessToken);
    this.options = options;
    return options;
  }
}
