import { Component, OnInit, Input,HostListener } from "@angular/core";
import { AuthenticationService } from "../services/authentication.service";
import { IUser } from "../interface/user";
import { EventEmitersService } from "../services/eventEmiters.service";
import { GenericMapMethodsService } from "../services/generic-map-methods.service";

@Component({
  selector: "sms-map-tools",
  templateUrl: "./map-tools.component.html",
  styleUrls: ["./map-tools.component.scss"]
})
export class MapToolsComponent implements OnInit {
  @Input() map: any;
  @Input() extent: any;
  @Input() OverviewMap: any;
  @Input() drawTool: any;
  @Input() webMercatorUtils: any;
  @Input() LocateButton: any;
  @Input() layerData: any;
  @Input() VectorTileLayer: any;
  @Input() region: any;
  @Input() QueryTask: any;
  @Input() Query: any;
  @Input() Draw: any;
  @Input() PictureMarkerSymbol: any;
  // private drawTool:any = this.DrawTool;
  private overviewMapDijit: any;
  public clickmap: any;
  private geoLocateValue = false;
  private geoLocate: any;
  private regionData: any;
  private clickTriView = false;
  currentUser: IUser;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if(event.target['localName'] === 'div'){
      let divName = (<HTMLInputElement>event.target).getAttribute("name");
      if( (['ZoomIn','ZoomOut','ZoomExtent','ZoomSelected','OverViewMap','TriView','Locate']).includes(divName)){
        (<HTMLInputElement>event.target).click();
      }
    }
  }
  //
  constructor(private authenticationService: AuthenticationService, 
    public eventEmitterService: EventEmitersService,
    private genericMapMethodsService : GenericMapMethodsService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.genericMapMethodsService.Draw = this.Draw;
  }
  ngOnInit(): void {
    console.log("Map Tools initialized");
  }

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 1);
  }
  zoomOut() {
    this.map.setZoom(this.map.getZoom() - 1);
  }
  enablePan() {
    this.map.enablePan();
  }

  refresh() {
    this.map.getLayer("sms-layers").refresh();
  }
  zoomSelected() {
    try {
      // const graphic = [];
      // let extent;
      let selected;
      let newExtent;
      // totalExtent-new this.extent();

      this.layerData
        .filter((obj) => obj.layerType.includes("inventory"))
        .forEach((data) => {
          selected = data.lyrObj.getSelectedFeatures();

          if (selected.length > 0) {
            let thisExtent: any;

            if (selected[0].geometry.type === "polyline" || selected[0].geometry.type === "polygon") {
              const graphic = selected[0];

              if (newExtent === undefined) {
                newExtent = graphic.geometry.getExtent();
              } else {
                thisExtent = graphic.geometry.getExtent();
                newExtent = newExtent.union(thisExtent);
              }

              for (let i = 1; i < selected.length; i++) {
                const graphic = selected[i];
                thisExtent = graphic.geometry.getExtent();

                // making a union of extent or previous feature and current feature.
                newExtent = newExtent.union(thisExtent);
              }
            }

            if (selected[0].geometry.type === "point") {
              // for xmax and xmin etc.
              const xmin = selected[0].geometry.x;
              const xmax = selected[0].geometry.x;
              const ymax = selected[0].geometry.y;
              const ymin = selected[0].geometry.y;

              if (newExtent === undefined) {
                newExtent = new this.extent(xmin, ymin, xmax, ymax, selected[0].geometry.spatialReference);
              } else {
                thisExtent = new this.extent(xmin, ymin, xmax, ymax, selected[0].geometry.spatialReference);
                newExtent = newExtent.union(thisExtent);
              }

              for (let i = 1; i < selected.length; i++) {
                //const gra = selected[i];
                const xmini = selected[i].geometry.x;
                const xmaxi = selected[i].geometry.x;
                const ymaxi = selected[i].geometry.y;
                const ymini = selected[i].geometry.y;

                thisExtent = new this.extent(xmini, ymini, xmaxi, ymaxi, selected[0].geometry.spatialReference);
                // making a union of extent or previous feature and current feature.
                newExtent = newExtent.union(thisExtent);
              }
            }
          }
        });
      if (newExtent.xmax == newExtent.xmin || newExtent.ymax == newExtent.ymin) {
        if (newExtent.xmax == newExtent.xmin) {
          newExtent.xmin = newExtent.xmin - 500;
          newExtent.xmax = newExtent.xmax + 500;
        }
        if (newExtent.ymax == newExtent.ymin) {
          newExtent.ymin = newExtent.ymin - 500;
          newExtent.ymax = newExtent.ymax + 500;
        }
        this.map.setExtent(newExtent, true);
      } else {
        this.map.setExtent(newExtent, true);
      }
      // this.map.setExtent(newExtent, true);
    } catch (error) {
      console.error(error);
    }
  }
  zoomExtent() {
    try {
      // Query IRegion
      if (this.currentUser.selectedRegion) {
        this.map.setExtent(this.currentUser.selectedRegion.Extent, true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  mapInset(insetMapVisible = true, selectedBasemap) {
    try {
      if (!this.overviewMapDijit) {
        const options: any = {
          map: this.map,
          attachTo: "bottom-right",
          visible: insetMapVisible,
          width: 200,
          height: 100
        };

        if (selectedBasemap) {
          if (typeof selectedBasemap.layers[0].type !== "undefined") {
            options.baseLayer = new this.VectorTileLayer(selectedBasemap.layers[0].styleUrl);
          }
        }

        this.overviewMapDijit = new this.OverviewMap(options);
        this.overviewMapDijit.startup();
      } else {
        if (this.overviewMapDijit.visible) {
          this.overviewMapDijit.hide();
        } else {
          this.overviewMapDijit.show();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  switchMapInsetOnBaseMapChange(basemap) {
    try {
      if (this.overviewMapDijit) {
        const insetMapVisible = this.overviewMapDijit.visible;
        this.overviewMapDijit.destroy();
        this.overviewMapDijit = null;
        this.mapInset(insetMapVisible, basemap);
      }
    } catch (error) {
      console.error(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  triView(event) {
    try {
      this.clickTriView = !this.clickTriView;

      if (this.clickTriView) {
        this.drawTool = new this.Draw(this.map);
        this.drawTool.activate("point");
        this.clickmap = this.drawTool.on("draw-end", (event) => {
          this.openTriView(event);
        });
      } else {
        this.clickmap.remove();
        this.drawTool.deactivate();
      }
    } catch (error) {
      console.error(error);
    }
  }
  openTriView(event) {
    try {
      const wm = this.webMercatorUtils.webMercatorToGeographic(event.geometry);
      //2/24/21 jcc - mashedworld is not secure.  replace with link to google streetview
      //const url = location.protocol + "//data.mashedworld.com/dualmaps/map.htm?x=" + wm.x + "&y=" + wm.y;
      const url = location.protocol + "//www.google.com/maps/@?api=1&map_action=pano&viewpoint=" + wm.y + ", " + wm.x;
      window.open(url, "_blank");
      this.clickTriView = false;
      this.clickmap.remove();
      this.drawTool.deactivate();
    } catch (error) {
      console.error(error);
    }
  }
  initLocationButton() {
    try {
      // const _this = this;
      if (!this.geoLocateValue) {
        this.geoLocate = new this.LocateButton(
          {
            map: this.map,
            highlightLocation: true,
            symbol: new this.PictureMarkerSymbol({
              angle: 0,
              xoffset: 0,
              yoffset: 0,
              type: "esriPMS",
              url: "https://js.arcgis.com/3.30/esri/dijit/images/esriGreenPin16x26.png",
              contentType: "image/png",
              width: 16,
              height: 24
            })
          },
          "locateBtn"
        );
        // geoLocate.startup();
        this.geoLocateValue = this.geoLocate.visible;
        this.geoLocate.locate();
      } else {
        this.geoLocate.clear();
        this.geoLocateValue = !this.geoLocateValue;
      }
    } catch (error) {
      console.error(error);
    }
  }
  query() {
    try {
      const rQuery = new this.Query();
      rQuery.objectIds = [1];
      rQuery.outFields = ["*"];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.layerData[7].lyrObj.queryFeatures(rQuery, (relatedRecords) => {
        // console.log(relatedRecords);
      });
    } catch (error) {
      console.error(error);
    }
  }
  toggleViewLidar(){
    if(this.eventEmitterService.viewLidarData === false){
      this.genericMapMethodsService.esriBundle.toolbars.draw.addPoint = "Click point to view lidar data";
      this.Draw = this.Draw(this.map);
      this.Draw.activate("point");
    }else{
      this.Draw.deactivate();
    }
    this.eventEmitterService.viewLidarData = !this.eventEmitterService.viewLidarData;
  }
}
