<div class="card">
  <div class="card-body">
    <div class="container">
      <div class="selections" style="display: flex; justify-content: space-evenly">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Userscreens.baselife.assetclass" | translate }}</mat-label>
          <mat-select [(ngModel)]="layerListSelected" (ngModelChange)="onChangeLayerList()">
            <mat-option [value]="layerList" *ngFor="let layerList of LayerList">
              {{ "Userscreens.assetattributedefalt." + layerList.AssetClass | translate }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Userscreens.baselife.baselifefld" | translate }}</mat-label>
          <mat-select [(ngModel)]="retroAdminSelected" (ngModelChange)="onChangeAdminList()">
            <mat-option [value]="retroAdmin" *ngFor="let retroAdmin of RetroAdmin">
              {{ retroAdmin.BLF_Domain }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
      </div>
      <div class="dataEntry" [style.display]="BaseLifeTable ? 'block' : 'none'">
        <div class="dataEntry-header h7" style="display: flex; justify-content: space-around; margin-bottom: 16px">
          <div style="width: 145px">{{ "Userscreens.baselife.sheetingtype" | translate }}</div>
          <div style="width: 145px">
            {{ "Userscreens.baselife.baselife" | translate }}
          </div>
        </div>
        <div *ngFor="let retroBaseLifeTable of RetroBaseLifeTable; let i = index" (click)="TableOnClick(i)">
          <div *ngFor="let domainValues of domainValuesCode" class="values xsmall">
            <div *ngIf="domainValues.code === retroBaseLifeTable.BLF_Domain_Code" style="display: flex; justify-content: space-around; padding: 8px 0px; align-items: center; width: 100%; max-height: 35px">
              <div style="width: 145px">
                {{ domainValues.name }}
              </div>
              <div style="width: 145px">
                <mat-form-field class="example-full-width">
                  <input type="number" id="inputsm_{{ i }}" matInput value="{{ retroBaseLifeTable.BaseLife }}" aria-label=" Base Life" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <div class="row justify-content-end">
      <!-- <div class="col">
                <label>
                    {{'Userscreens.baselife.footertext'|translate}}
                </label>
            </div> -->

      <div class="col-auto">
        <button type="button" class="btn btn-secondary float-right" (click)="SaveEdits()">{{ "Userscreens.baselife.saveedits" | translate }}</button>
      </div>
    </div>
  </div>
</div>
