<div class="sms-model-height">
  <div class="card">
    <div class="card-header">
      {{ ExportHeading | translate }}
    </div>
    <div class="card-body">
      <mat-form-field>
        <mat-label>{{ RegionLabel | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedRegion" (ngModelChange)="onChange()">
          <mat-option [value]="region" *ngFor="let region of regionList">
            {{ region.regionName }}
          </mat-option>
        </mat-select>

        <div class="dropdown-icons">
          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ AssetClassLabel | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedLayer">
          <mat-option [value]="lyr" *ngFor="let lyr of layerList">
            {{ !scndlayer ? lyr.tableAlias : lyr.tableSL }}
          </mat-option>
        </mat-select>

        <div class="dropdown-icons">
          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
        </div>
      </mat-form-field>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-center align-items-center pt-2">
        <div *ngIf="loading" class="spinner-border spinner" role="status"></div>
        <span>{{ ExportStatus }}</span>
      </div>
      <button type="button" aria-label="Export" class="btn btn-primary mr-3 align-right" style="float: right; top: -15px" (click)="export()" data-ok="Yes">{{ ExportLabel | translate }}</button>
    </div>
  </div>
</div>
