<div class="card">
  <div class="card-body">
<div class="row" style="margin-top: 10px;margin-bottom: 10px;">

  <div class="col-2">
    <div class="dropdown show">
      <app-dropdown aria-label="select region" [label]="'Organisation'" [dropdownList]="regions"
       [val]="selectedregion" [isRegion]="true" (select)="onRegionChange($event)"> </app-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="dropdown show">
      <app-dropdown aria-label="select asset" [label]="'Type'" [dropdownList]="asset" [val]="selectedAssetClass"
        [isCrewManagement]="true" (select)="onAssetChange($event)"> </app-dropdown>
    </div>
  </div>
  <div class="col">
    <mat-tab-group mat-stretch-tabs disablePagination="true" (selectedTabChange)="filterdatabyDate($event)">
      <mat-tab [label]="'Crewmanagement.assignment.today' | translate">
      </mat-tab>
      <mat-tab [label]="'Crewmanagement.assignment.tomorrow' | translate">
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div>
<table class="table">
  <thead>
    <tr>
      <th style="width: 40%; text-align: left;">{{'Crewmanagement.assignment.cremembername'|translate}}</th>
      <th  style="width: 30%;" class="text-center">{{'Crewmanagement.assignment.tasknumber'|translate}}</th>
      <th  style="width: 30%;" class="text-center">{{'Crewmanagement.assignment.totalesitimatedduration'|translate}}</th>
    </tr>
  </thead>
</table>
<div class="tablescroll">
<table *ngIf="assignments" class="table">
  <tbody *ngIf="assignments.length >0" >
    <tr *ngFor="let assignment of assignments;let lid = index" (click) ="rowclicked(assignment,lid)" [class.active]="lid == selectedRow">

      <td  style="width: 40%; text-align: left;">{{assignment.user}}</td>
      <td  style="width: 30%;" class="text-center">{{assignment.noOfTasks}}</td>
      <td  style="width: 30%;" class="text-center">{{assignment.duration|hoursMinute}}</td>
    </tr>
    
  </tbody>
</table>
</div>
</div>
</div>
</div>