import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import Swal from "sweetalert2";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
//Added by Sowjanya for TSDRSAM-71
import { environment } from "src/environments/environment";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { GenericLayerService } from "../../services/genericLayer.service";
import { TranslateService } from "@ngx-translate/core";
import { Button, error } from "protractor";
import { Globals } from "src/app/select/globals";
import { UserService } from "src/app/services/user.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
//
@Component({
  selector: "sms-agencyConfig",
  templateUrl: "./agency.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class AgencyConfigComponent implements OnInit {
  public currentUser: IUser;
  public agencyDetails;
  public agencyName;
  public SolutionTierValue = [];
  public SolutionTier;
  public PrimaryAgencyLanguage;
  public SecondaryAgencyLanguage;
  public agencyWebService;
  public agencySpatialDatabase;
  public AgencyList = [];
  public AgencyListSelected;
  public SecondaryAgencyLanguageDisable = true;
  public SolutionTierChange = false;
  openlang = false;
  selectedRowIndex = -1;
  //Added by Sowjanya for TSDRSAM-71
  public customerURL;
  public customerName = "";
  public template = "";
  ExportStatus;
  fileToUpload: File = null;
  fileext = false;
  loading = false;
  filecontent;
  uploads: string;
  Geoprocessor;
  serviceURL;
  temp = false;
  jobId;
  public firstName = "";
  public lastName = "";
  public username = "";
  public password = "";
  submitted = false;
  userdata;
  adminData: {};
  //
  constructor(
    private MMMAdminService: MMMAdminService,
    public adminGlobal: adminGlobal,
    private authenticationService: AuthenticationService,
    //Added by Sowjanya for TSDRSAM-71
    private translateService: TranslateService,
    private userService: UserService,
    private genericService: GenericLayerService,
    private esriMapComponent: EsriMapComponent //
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.CallSoltionTier();
    this.MMMAdminService.getAPILanguage().subscribe(
      (res: any[]) => {
        this.adminGlobal.PALValues = [];
        this.adminGlobal.PALValues = res;
      },
      (err) => {
        console.log(err);
      }
    );
    this.MMMAdminService.getClientList().subscribe(
      (res: any[]) => {
        this.AgencyList = [];
        res.forEach((data) => {
          if (data.clientId === this.currentUser.clientId) {
            this.AgencyList.push(data);
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );

    if (this.adminGlobal.agencyDetails !== undefined && this.adminGlobal.agencyDetails.length > 0) {
      const details = this.adminGlobal.agencyDetails[0];
      this.agencyDetails = this.adminGlobal.agencyDetails;
      this.customerName = details.clientName; //Updated by Sowjanya for TSDRSAM-71
      this.serviceURL = details.webServiceUrl; //Updated by Sowjanya for TSDRSAM-71
      this.SolutionTier = details.clientType;
      this.agencySpatialDatabase = details.clientDb;
      //Added by Sowjanya for TSDRSAM-71
      if ((this.AgencyList.length == 0 && this.agencyDetails) || this.AgencyListSelected) {
        this.AgencyList.push(this.adminGlobal.agencyDetails[0]);
        this.AgencyListSelected = this.adminGlobal.agencyDetails;
      }
      if ((!this.AgencyListSelected && this.adminData) || this.adminGlobal.AdminData) {
        this.temp = true;
        this.firstName = this.adminGlobal.AdminData["firstName"];
        this.lastName = this.adminGlobal.AdminData["lastName"];
        this.username = this.adminGlobal.AdminData["username"];
        this.password = this.adminGlobal.AdminData["password"];
      }
      //
      this.adminGlobal.PALValues.forEach((dataVal) => {
        if (dataVal.languageID === details.primaryLanguageID) {
          this.PrimaryAgencyLanguage = dataVal;
        }
      });

      if (details.secondaryLanguageID !== null) {
        this.adminGlobal.PALValues.forEach((dataVal) => {
          if (dataVal.languageID === details.secondaryLanguageID) {
            this.SecondaryAgencyLanguage = dataVal;
          }
        });
      }

      this.ChangeSolutionTier();

      if (details.clientType === "PROFESSIONAL") {
        this.SecondaryAgencyLanguageDisable = false;
        this.SolutionTierChange = true;
      } else {
        this.SecondaryAgencyLanguageDisable = true;
        this.SolutionTierChange = false;
      }
    }
  }
  CallSoltionTier() {
    try {
      this.MMMAdminService.getSolutionsTiers().subscribe(
        (res: any[]) => {
          this.SolutionTierValue = [];
          res.forEach((data) => {
            this.SolutionTierValue.push(data);
          });
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  onChange() {
    try {
      this.clear();
      this.agencyDetails = this.AgencyListSelected; //Updated by Sowjanya for TSDRSAM-71
      const details = this.AgencyListSelected;
      this.adminGlobal.AgencyId = details.clientId;
      this.customerName = details.clientName; //Updated by Sowjanya for TSDRSAM-71
      this.serviceURL = details.webServiceUrl; //Updated by Sowjanya for TSDRSAM-71
      this.SolutionTier = details.clientType;
      this.agencySpatialDatabase = details.clientDb;
      this.adminGlobal.PALValues.forEach((dataVal) => {
        if (dataVal.languageID === details.primaryLanguage) {
          this.PrimaryAgencyLanguage = dataVal;
        }
      });

      if (details.secondaryLanguage !== null) {
        this.adminGlobal.PALValues.forEach((dataVal) => {
          if (dataVal.languageID === details.secondaryLanguage) {
            this.SecondaryAgencyLanguage = dataVal;
          }
        });
      }

      if (this.AgencyListSelected.clientType === "PROFESSIONAL") {
        this.SecondaryAgencyLanguageDisable = false;
      } else {
        this.SecondaryAgencyLanguageDisable = true;
      }

      const data = {
        clientName: this.AgencyListSelected.clientName,
        clientDb: this.AgencyListSelected.clientDb,
        webServiceUrl: this.AgencyListSelected.webServiceUrl,
        clientType: this.AgencyListSelected.clientType,
        primaryLanguageID: this.AgencyListSelected.primaryLanguage,
        secondaryLanguageID: this.AgencyListSelected.secondaryLanguage,
        pwMinLen: this.AgencyListSelected.pwMinLen, 
        pwMaxLen:this.AgencyListSelected.pwMaxLen,
        pwMinSpec:this.AgencyListSelected.pwMinSpec,
        pwMinUC:this.AgencyListSelected.pwMinUC,
        pwMinLC:this.AgencyListSelected.pwMinLC,
        pwMinNum:this.AgencyListSelected.pwMinNum,
        pwRenewDays:this.AgencyListSelected.pwRenewDays,
      };
      this.ChangeSolutionTier();
      this.adminGlobal.agencyDetails = [];
      this.agencyDetails = [];

      //TSDRSAM-1177
      this.GetLayerInfo(environment.proxy + this.AgencyListSelected.webServiceUrl, data);
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    try {
      this.adminGlobal.agencyDetails = [];
      this.agencyDetails = [];
      this.agencyName = undefined;
      this.SolutionTier = undefined;
      this.PrimaryAgencyLanguage = undefined;
      this.agencyWebService = undefined;
      this.agencySpatialDatabase = undefined;
      this.SecondaryAgencyLanguage = undefined;
      this.adminGlobal.AgencyId = 0;
      this.SecondaryAgencyLanguageDisable = true;
      this.adminGlobal.AssetClassList = [];
      this.adminGlobal.HideAsset = [];
      this.adminGlobal.HideSystemReport = [];
      this.adminGlobal.ShowAsset = [];
      this.adminGlobal.ShowSystemReport = [];
      this.adminGlobal.serviceLyrInfo = [];
      this.adminGlobal.ExistBaselifeConfig = [];
      this.adminGlobal.ExistBaselifeModifier = [];
      this.adminGlobal.NewBaselifeConfig = [];
      this.adminGlobal.NewBaselifeModifier = [];
      this.adminGlobal.ExistBaseLife = [];
      this.adminGlobal.ExistBaseLifeModify = [];
      this.adminGlobal.ResourceRequirement = [];
      this.adminGlobal.ResourceSaveValues = [];
      this.adminGlobal.ResourceRequirementFilename = null;
      this.SolutionTierChange = false;
      //Added by Sowjanya for TSDRSAM-71
      this.customerName = "";
      this.serviceURL = "";
      this.template = "";
      this.temp = false;
    } catch (error) {
      console.error(error);
    }
  }

  add() {
    try {
      //Updated by Sowjanya for TSDRSAM-71
      if (!this.AgencyListSelected || this.AgencyListSelected === undefined) {
        if (this.firstName === "" || this.firstName === undefined) {
          Swal.fire(this.translateService.instant("Agency.alert.firstname"));
        } else if (this.lastName === "" || this.lastName === undefined) {
          Swal.fire(this.translateService.instant("Agency.alert.lastname"));
        } else if (this.username === "" || this.username === undefined) {
          Swal.fire(this.translateService.instant("Agency.alert.username"));
        } else if (!this.username.endsWith("_3MA")) {
          Swal.fire(this.translateService.instant("Agency.alert.usernameninvalid"));
        } else if (this.password === "" || this.password === undefined) {
          Swal.fire(this.translateService.instant("Agency.alert.password"));
        } else if (this.password.length < 8 || this.password.length >= 15) {
          Swal.fire(this.translateService.instant("Agency.alert.pwslengthinvalid"));
        } else if (!(/[A-Z]/.test(this.password) && /[a-z]/.test(this.password) && /[0-9]/.test(this.password))) {
          Swal.fire(this.translateService.instant("Agency.alert.pwdpattern"));
        } else if (this.username === this.password) {
          Swal.fire(this.translateService.instant("Agency.alert.pwdnotbeuser"));
        }
      }
      if (this.customerName === undefined || this.customerName === "") {
        Swal.fire(this.translateService.instant("Agency.alert.cstmrnameinvalid"));
        //Swal.fire(this.translateService.instant("Esri-map.alert.noregionmsg"));
      } else if (/\s/g.test(this.customerName)) {
        Swal.fire(this.translateService.instant("Agency.alert.cstmrnamevalidator"));
      } else if (this.SolutionTier === undefined || this.SolutionTier === "") {
        Swal.fire("Please select Solution Tier");
      } else if (this.PrimaryAgencyLanguage === undefined || this.PrimaryAgencyLanguage === "") {
        Swal.fire("Please select Primary Agency Language");
      } else if (this.agencySpatialDatabase === undefined || this.agencySpatialDatabase === null) {
        Swal.fire("Please enter Spatial Database Name");
      } else if (this.SecondaryAgencyLanguage !== undefined && this.SecondaryAgencyLanguage !== null) {
        if (this.SecondaryAgencyLanguage.languageID === this.PrimaryAgencyLanguage.languageID) {
          Swal.fire("Same Language is selected in secondary Language");
        } else {
          this.SaveData();
        }
      } else {
        this.SaveData();
      }
    } catch (error) {
      console.error(error);
    }
  }

  SaveData() {
    try {
      this.ChangeSolutionTier();
      this.adminGlobal.agencyDetails = [];
      this.agencyDetails = [];
      const data = {
        clientName: "string",
        clientDb: "string",
        webServiceUrl: "string",
        clientType: "string",
        primaryLanguageID: 0,
        secondaryLanguageID: 0
      };
      // Mandatory
      data.clientName = this.customerName; //Updated by Sowjanya for TSDRSAM-71
      data.clientType = this.SolutionTier;
      data.primaryLanguageID = this.PrimaryAgencyLanguage.languageID;
      data.clientDb = this.agencySpatialDatabase;
      // Optional
      data.webServiceUrl = this.serviceURL; //Update by Sowjanya for TSDRSAM-71
      data.secondaryLanguageID = this.SecondaryAgencyLanguage ? this.SecondaryAgencyLanguage.languageID : 0;
      this.GetLayerInfo(data.webServiceUrl, data);
    } catch (error) {
      console.error(error);
    }
  }
  ChangeSolutionTier() {
    try {
      if (this.SolutionTier === "PROFESSIONAL") {
        this.SecondaryAgencyLanguageDisable = false;
        this.SolutionTierChange = true;
      } else if (this.SolutionTier === "PREMIUM") {
        this.SecondaryAgencyLanguageDisable = true;
        this.SolutionTierChange = false;
        this.SolutionTierValue = this.SolutionTierValue.filter(function (obj) {
          return obj.toLowerCase() !== "basic";
        });
      } else {
        this.SolutionTierChange = false;
        this.SecondaryAgencyLanguageDisable = true;
        this.CallSoltionTier();
      }
    } catch (error) {
      console.error(error);
    }
  }

  uploadAgencyLanguage() {
    this.openlang = true;
  }

  close(evt) {
    //console.log("closing")
  }

  GetLayerInfo(URL, data) {
    try {
      this.MMMAdminService.getAllLayersInformation(URL).subscribe(
        (res: any) => {
          if (res.error === undefined) {
            const serviceLyrInfo: any = {};
            res.layers.forEach((lyr) => {
              switch (lyr.name) {
                case "Support":
                  serviceLyrInfo.supportId = lyr.id;
                  break;
                case "CrashCushion":
                  serviceLyrInfo.crashCushionId = lyr.id;
                  break;
                case "EndTreatment":
                  serviceLyrInfo.endTreatmentId = lyr.id;
                  break;
                case "PavementMarking_Symbol":
                  serviceLyrInfo.pavementMarkingSymbolId = lyr.id;
                  break;
                case "Barrier":
                  serviceLyrInfo.barrierId = lyr.id;
                  break;
                case "Guardrail":
                  serviceLyrInfo.guardrailId = lyr.id;
                  break;
                case "PavementMarking":
                  serviceLyrInfo.pavementMarkingId = lyr.id;
                  break;
                case "AssetPolygon":
                  serviceLyrInfo.assetPolygonId = lyr.id;
                  break;
              }
            });

            console.log(res.tables);
            res.tables.forEach((lyr) => {
              switch (lyr.name) {
                case "TableAliases":
                  serviceLyrInfo.tableAliasId = lyr.id;
                  break;
                case "FieldAliases":
                  serviceLyrInfo.fieldAliasId = lyr.id;
                  break;
                case "InventoryDeletes":
                  serviceLyrInfo.inventoryDeleteId = lyr.id;
                  break;
                case "Maintenance":
                  serviceLyrInfo.maintenanceId = lyr.id;
                  break;
                case "Assessment":
                  serviceLyrInfo.assessmentId = lyr.id;
                  break;
                case "Activity":
                  serviceLyrInfo.activityId = lyr.id;
                  break;
                case "Photo":
                  serviceLyrInfo.photoId = lyr.id;
                  break;
                case "Light":
                  serviceLyrInfo.lightId = lyr.id;
                  break;
                case "AssetCodeDefaults":
                  serviceLyrInfo.assetCodeDefaultsId = lyr.id;
                  break;
                case "Sign":
                  serviceLyrInfo.signId = lyr.id;
                  break;
                case "Documents":
                  serviceLyrInfo.documentId = lyr.id;
                  break;
                case "UserQueryActions":
                  serviceLyrInfo.userQueryActionsId = lyr.id;
                  break;
                case "ELife_BaseLife_Config":
                  serviceLyrInfo.baseLifeConfigId = lyr.id;
                  break;
                case "ELife_BaseLife_Values":
                  serviceLyrInfo.baseLifeValuesId = lyr.id;
                  break;
                case "ELife_Modifier_Config":
                  serviceLyrInfo.modifierConfigId = lyr.id;
                  break;
                case "ELife_Modifier_Values":
                  serviceLyrInfo.modifierValueId = lyr.id;
                  break;
                case "ActivityDuration":
                  serviceLyrInfo.activityDurationId = lyr.id;
                  break;
                case "ActivityToolkit":
                  serviceLyrInfo.activityToolkitId = lyr.id;
                  break;
                // Added by Sowjanya for JIRA ID:-27  JIRA ID:-29 on 08/17/2021

                case "MaintenanceConfiguration":
                  serviceLyrInfo.maintenanceConfigId = lyr.id;
                  break;
              }
            });
            console.log(serviceLyrInfo);
            this.adminGlobal.serviceLyrInfo = serviceLyrInfo;
            this.adminGlobal.agencyDetails.push(data);
            this.agencyDetails.push(data);
            this.adminLogin(); //Added by Sowjanya for TSDRSAM-71
          } else {
            Swal.fire(res.error.message);
            return;
          }
        },
        (err) => {
          Swal.fire("Rest services is not properly set. Please see error message:" + err.message);
          return;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  //Added by Sowjanya for TSDRSAM-71
  handleFileInputs(files: FileList) {
    this.fileToUpload = files.item(0);
    if (files[0]) {
      if (!this.validateFile(files[0].name)) {
        Swal.fire(this.translateService.instant("Agency.alert.fileformat"));
        (<HTMLInputElement>document.getElementById("file")).value = null;
        this.fileToUpload = null;
        this.fileext = false;
      } else {
        this.fileext = true;
        //Validate file size (max. 20 MB)
        if (files[0].size > 20000000) {
          //3/2/22 jcc bytes not kb 20000) {
          (<HTMLInputElement>document.getElementById("file")).value = null;
          this.fileToUpload = null;
          Swal.fire(this.translateService.instant("Agency.alert.fileSize"));
        }
      }
    }
    if (this.fileToUpload != null) {
      const fileReader = new FileReader();
      fileReader.readAsText(this.fileToUpload, "UTF-8");

      fileReader.onload = () => {
        this.filecontent = fileReader.result;
      };
    }
  }
  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf(".") + 1);

    if (ext.toLowerCase() === "zip") {
      return true;
    }

    return false;
  }
  Upload() {
    debugger;
    if (this.customerName === undefined || this.customerName === "") {
      Swal.fire(this.translateService.instant("Agency.alert.cstmrnameinvalid"));
    } else if (/\s/g.test(this.customerName)) {
      Swal.fire(this.translateService.instant("Agency.alert.cstmrnamevalidator"));
    } else if (this.fileToUpload === null) {
      Swal.fire(this.translateService.instant("Agency.alert.fileupload"));
    } else if (this.template == undefined || this.template == "") {
      Swal.fire(this.translateService.instant("Agency.alert.clienttype"));
    } else {
      this.MMMAdminService.fileUpload(this.fileToUpload).subscribe(
        (res) => {
          debugger;
          this.temp = true;
          this.uploads = JSON.parse(res.toString());
          this.uploads["item"]["itemID"];
          this.CustomerSetup();
        },
        (err) => {
          debugger;
          console.log(err);
          Swal.fire("File uploading is failed " + err);
          this.temp = false;
        }
      );
    }
  }

  adminLogin() {
    this.adminGlobal.AdminData = {
      username: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      password: this.password,
      roleId: 1004,
      active: 1,
      visible: 1,
      languageID: 1
    };
    this.adminData = this.adminGlobal.AdminData;
  }
  CustomerSetup() {
    debugger;
    this.customerURL = new this.esriMapComponent.Geoprocessor(environment.customerNameService + environment.newCustomer);
    const param = {
      dbName: this.customerName,
      zipFileItemId: this.uploads["item"]["itemID"],
      clientType: this.template
    };
    this.customerURL.submitJob(param, this.gpJobComplete.bind(this), this.gpJobStatus.bind(this), this.gpJobFailed.bind(this));
  }

  gpJobComplete(jobinfo) {
    if (jobinfo.jobStatus != "esriJobFailed") {
      const URLData = environment.customerNameService + environment.newCustomer + "/jobs/" + jobinfo.jobId + "/results/serviceUrl";
      console.log(URLData);
      this.genericService.getServiceInfo(URLData).subscribe(
        (urlData: any) => {
          this.serviceURL = urlData.value;
          if (this.serviceURL) {
            this.serviceURL = this.serviceURL.substring(0, this.serviceURL.lastIndexOf("MapServer"));
            this.adminGlobal.NewClientGisUrl = this.serviceURL;
          }
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
          this.temp = false;
        }
      );
    } else {
      this.temp = false;
    }
  }

  gpJobStatus(jobinfo) {
    switch (jobinfo.jobStatus) {
      case "esriJobSubmitted":
        this.ExportStatus = this.translateService.instant("Agency.alert.statussubmitted");
        this.loading = true;
        if (jobinfo.messages.length == 0) {
          Swal.fire({
            text: jobinfo.jobStatus,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 5000
          });
        }
        break;
      case "esriJobExecuting":
        this.ExportStatus = this.translateService.instant("Agency.alert.statusprocessing");
        this.loading = true;
        if (jobinfo.messages.length > 0) {
          for (let i = 1; i < jobinfo.messages.length; i++) {
            let messagestring = "";
            if (jobinfo.messages[i].type == "esriJobMessageTypeInformative") {
              messagestring += messagestring + "Info:" + jobinfo.messages[i].description + "\n";
              Swal.fire({
                text: messagestring,
                showCancelButton: false,
                showConfirmButton: false,
                timer: 3000
              });
            }
          }
        }
        break;
      case "esriJobSucceeded":
        this.ExportStatus = this.translateService.instant("Agency.alert.statussuccess");
        this.loading = false;
        if (jobinfo.jobStatus == "esriJobSucceeded") {
          Swal.fire({
            text: jobinfo.jobStatus,
            //jobinfo.messages[0].description
            // text:jobinfo.jobStatus,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000
          });
        }
        break;
    }
    if (jobinfo.jobStatus === "esriJobFailed") {
      if (jobinfo.messages.length > 0) {
        let messagestring = "";
        for (let i = 1; i < jobinfo.messages.length; i++) {
          console.log(jobinfo.messages[i].description);
          if (jobinfo.messages[i].type == "esriJobMessageTypeError") {
            messagestring += messagestring + "Info:" + jobinfo.messages[i].description + "\n";
            Swal.fire({
              text: messagestring,
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000
            });
          }
        }
      }
    }
  }
  gpJobFailed(error) {
    Swal.fire(error.message);
    this.temp = false;
  }
}
