<div>
  <div style="padding-bottom:60px;" class="scroll-detail">
    <div class="text-center no-data" *ngIf="assessmentsData.length == 0">{{ "Select.assessment.noassessmentmsg" | translate }}</div>
    <div class="assessment-card" *ngFor="let assessment of assessmentsData">
      <div style="padding-bottom: 12px" class="flex-between">
        <div class="h7">{{ assessment.attributes.Condition }}</div>
        <div class="small">
          <a href="#" (click)="viewDoc(DocViewer, assessment.attributes)">{{ "Select.assessment.viewdocs" | translate }}</a>
        </div>
      </div>
      <div>
        <span *ngIf="assessment.attributes.LastModifyDate == null; else elseBlock">
          {{ assessment.attributes.InspectionDate | date : "medium" }}
        </span>
        <ng-template #elseBlock>{{ assessment.attributes.LastModifyDate | date : "medium" }}</ng-template>
      </div>
      <div class="xsmall item-spacing assessment-container flex-between">
        <div class="light-text">{{ "Select.newassessment.inspector" | translate }}</div>
        <div class="dark-text">{{ assessment.attributes.Inspector ? assessment.attributes.Inspector : ("AssetViewerDetail.Assessment.NoneAvaialble" | translate) }}</div>
      </div>
      <div class="xsmall item-spacing assessment-container flex-between">
        <div class="light-text">{{ "Select.newassessment.assessmenttype" | translate }}</div>
        <div class="dark-text">{{ assessment.attributes.AssessType ? assessment.attributes.AssessType : ("AssetViewerDetail.Assessment.NoneAvaialble" | translate) }}</div>
      </div>
      <div class="xsmall item-spacing assessment-container flex-between">
        <div class="light-text">{{ "Select.newassessment.AssessmentDefectTypes" | translate }}</div>
        <div class="dark-text">{{ assessment.attributes.DefectType ? assessment.attributes.DefectType : "none available" }}</div>
      </div>
      <!-- TSDRSAM-1819 -->
      <div class="xsmall item-spacing assessment-container flex-between">
        <div class="light-text">{{ "Select.newassessment.comments" | translate }}</div>
        <div class="dark-text">{{ assessment.attributes.Comment ? assessment.attributes.Comment : "none available" }}</div>
      </div>
      <div class="assessment-button item-spacing space-around">
        <button
          title="{{ 'Select.assessment.edit' | translate }}"
          mat-button
          *ngIf="currentUser.rolePermissions.assessmentEdit && findUserAccessByRoleRegionLayer()"
          class="primary-button h7"
          (click)="editTask(assessment.attributes, content)"
        >
          {{ "Select.assessment.edit" | translate }}
        </button>
        <button
          title="{{ 'Select.assessment.delete' | translate }}"
          mat-button
          *ngIf="currentUser.rolePermissions.assessmentDelete && findUserAccessByRoleRegionLayer()"
          class="inverse-button h7"
          (click)="deleteTask(assessment)"
        >
          {{ "Select.assessment.delete" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="text-center assessment-button detailsAllTab-footerHeight assessment-footer-component" *ngIf="currentUser.rolePermissions.assessmentCreate && findUserAccessByRoleRegionLayer()">
    <button title="{{ 'Select.assessment.newassessmentbtn' | translate }}" mat-button class="primary-button h7" (click)="createNew(content)">{{ "Select.assessment.newassessmentbtn" | translate }}</button>
  </div>
</div>

<ng-template #content let-modal>
  <app-heading-component [headingText]="'Select.newassessment.header' | translate" [variant]="'none'" [iconThree]="'cross'" (actionThree)="modal.dismiss('Cross click')" [tooltipThree]="'Close'"> </app-heading-component>
  <div class="modal-body sms-model-height-Assessment formbg-d2">
    <div class="form-row">
      <div class="form-group col-6">
        <app-dropdown
          [label]="'Select.newassessment.assessmenttype' | translate"
          [dropdownList]="AssetTypeList"
          [isAssessment]="true"
          [isRequired]="true"
          [val]="assetTypeListSelected"
          (select)="assessmenttypeChange($event)"
        >
        </app-dropdown>
      </div>
      <div class="form-group col-6">
        <app-dropdown [label]="'Select.newassessment.condition' | translate" [dropdownList]="ConditionList" [isAssessment]="true" [isRequired]="true" [val]="conditionSelected" (select)="conditionChange($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <app-dropdown [label]="'Select.newassessment.inspector' | translate" [dropdownList]="UserList" [isWorkerUsername]="true" [isRequired]="true" [val]="userListSelected" (select)="userChange($event)"> </app-dropdown>
      </div>
      <div class="form-group col-6">
        <app-dropdown [label]="'Select.newassessment.AssessmentDefectTypes' | translate" [dropdownList]="DefectTypeList" [isAssessment]="true" [val]="defecttypeSelected" (select)="defectTypeChange($event)">
        </app-dropdown>
      </div>
    </div>
    <!-- Added by Sowjanya for JIRA ID:-54 on 08/25/2021 -->
    <div class="form-row">
      <div class="form-group col">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{ "Select.newassessment.comments" | translate }}</mat-label>
          <textarea matInput [(ngModel)]="CommentText" type="text" rows="3" name="taskdesc"></textarea>
          <mat-error>Error</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row width100 p-3" [style.display]="linearAssetValues ? 'inline-block' : 'none'">
      <hr />
      <div [style.display]="hideSpacingFromEdit ? 'inline-block' : 'none'">
        <div class="form-row align-self-baseline mb-4">
          <div class="form-group col-sm-3">
            <label for="Spacing">{{ "Select.newassessment.spacing" | translate }}</label>
            <input type="number" pattern="^[0-9]" min="1" class="form-control" [(ngModel)]="Spacing" id="Spacing" />
          </div>
          <div class="form-group col-sm-4">
            <label for="taskname">{{ "Select.newassessment.default" | translate }}</label>
            <select class="form-control" [(ngModel)]="default">
              <option *ngFor="let defaultList of ConditionList" [value]="defaultList">
                {{ defaultList }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex align-items-end justify-content-end">
            <button title="{{ 'Select.newassessment.createincrement' | translate }}" mat-button class="primary-button h7" (click)="CreateIncrements()">
              {{ "Select.newassessment.createincrement" | translate }}
            </button>
          </div>
          <div class="form-group" style="display: flex; color: #e43a40" *ngIf="polyLineMessage != ''">{{ polyLineMessage }}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2">
          <label for="taskname">{{ "Select.incrementassessment.measure" | translate }}</label>
        </div>
        <div class="form-group col-sm-3">
          <label for="taskname">{{ "Select.incrementassessment.condition" | translate }}</label>
        </div>
        <div class="form-group col-sm-3">
          <label for="Comment">{{ "Select.incrementassessment.comment" | translate }}</label>
        </div>
        <div class="form-group col-sm-3">
          <label for="Photo">{{ "Select.incrementassessment.photo" | translate }}</label>
        </div>
      </div>
      <div class="form-row" *ngFor="let valueIncrement of valuesIncrement; let i = index">
        <div class="form-group col-sm-2 pl-4">
          <div>{{ valueIncrement }}</div>
        </div>
        <div class="form-group col-sm-3">
          <select class="form-control" id="defaultCondition + i" [(ngModel)]="defaultCondition[i]">
            <option *ngFor="let conditionList of ConditionList" [value]="conditionList">
              {{ conditionList | translate }}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-3">
          <input [(ngModel)]="LineComment[i]" type="text" name="LineComment" maxlength="20" id="LineComment + i" />
        </div>
        <div class="form-group col-sm-3 d-flex align-items-end justify-content-end pb-2">
          <!-- TSDRSAM-778  -->
          <input
            type="file"
            accept=".jpg, .jpeg, .pdf"
            #assetDocUploadIncrement
            class="d-none"
            [id]="'imageInputFile' + i"
            (change)="uploadNew(assetDocUploadIncrement.files, valueIncrement, defaultCondition[i], i)"
            aria-label="select file to upload"
          />
          <button type="button" class="btn btn-secondary" (click)="assetDocUploadIncrement.click()">...</button>
        </div>
      </div>
    </div>
  </div>
  <div class="divider-assessment"></div>
  <div class="modal-footer modal-footer-assessment">
    <button title="{{ 'Select.newassessment.cancel' | translate }}" mat-stroked-button class="h7 inverse-button-assessment" (click)="close()">{{ "Select.newassessment.cancel" | translate }}</button>
    <div>
      <input type="file" accept=".jpg, .jpeg, .pdf" #assetDocUpload class="d-none" (change)="uploadNew(assetDocUpload.files)" />

      <button title="{{ 'Select.newassessment.docuploadbtn' | translate }}" mat-button class="h7 primary-button" [style.display]="linearAssetValues ? 'none' : 'inline-block'" (click)="assetDocUpload.click()">
        {{ "Select.newassessment.docuploadbtn" | translate }}
      </button>
    </div>
    <button title="{{ 'Select.newassessment.save' | translate }}" mat-button class="h7 primary-button" (click)="SaveAssessment()" [disabled]="isDisabled">{{ "Select.newassessment.save" | translate }}</button>
  </div>
</ng-template>

<ng-template #DocViewer let-modal let-d="dismiss">
  <app-heading-component [headingText]="'Select.assessment.docviewer' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="d('Cross click')"> </app-heading-component>

  <div class="modal-body sms-model-imageviewer">
    <div class="sort-container">
      <button class="small pv-btn" id="Date" data-toggle="collapse" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" (click)="OrderImages('Date')">
        {{ "Select.docassessment.datefilter" | translate }}
        <ng-container>
          <mat-icon title="Sort Descending" *ngIf="!showDateByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
          <ng-template #ElseBlock>
            <mat-icon title="Sort Ascending" aria-hidden="true" svgIcon="arrow-down"></mat-icon>
          </ng-template>
        </ng-container>
      </button>
      <button class="small pv-btn" id="Sequence" *ngIf="linearAssetValues && currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('Measure')">
        {{ "Select.docassessment.sequencefilter" | translate }}
        <ng-container>
          <mat-icon title="Sort Descending" *ngIf="!showSequenceByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
          <ng-template #ElseBlock>
            <mat-icon title="Sort Ascending" svgIcon="arrow-down"></mat-icon>
          </ng-template>
        </ng-container>
      </button>
      <button class="small pv-btn" id="Source" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('SourceTag')">
        {{ "Select.docassessment.sourcefilter" | translate }}
        <ng-container>
          <mat-icon title="Sort Descending" *ngIf="!showSourceByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
          <ng-template #ElseBlock>
            <mat-icon title="Sort Ascending" aria-hidden="true" svgIcon="arrow-down"></mat-icon>
          </ng-template>
        </ng-container>
      </button>
    </div>
    <form fxLayout="column" class="mb">
      <mat-button-toggle-group class="button-group" #group="matButtonToggleGroup" [value]="imageSrc" (change)="EventChange($event)">
        <mat-button-toggle *ngFor="let sample of samples; let i = index" color="primary" [value]="sample.url"> {{ sample.label }}</mat-button-toggle>
      </mat-button-toggle-group>
    </form>
    <img *ngIf="imageSrc !== '' && !imageSrc.includes('application/pdf')" imageSrc [src]="imageSrc" />
    <ng-container *ngIf="imageSrc.includes('application/pdf')"><pdf-viewer [src]="imageSrc" [render-text]="true" [original-size]="false" style="width: 400px; height: 500px"></pdf-viewer></ng-container>
    <input type="file" accept=".jpg, .jpeg, .pdf" #assetPhotoUpload class="d-none" (change)="uploadNew(assetPhotoUpload.files)" aria-label="select file" />
  </div>
  <div class="photo-data">
    <div class="xsmall photo-date">{{ "Select.docassessment.photodate" | translate }} : {{ PhotoDate | date : "medium" }}</div>
    <div class="xsmall photo-date">{{ "Select.docassessment.sourceandcondition" | translate }} : {{ sourceTag }}</div>
    <div class="xsmall photo-date" *ngIf="linearAssetValues">{{ "Select.docassessment.measureinterval" | translate }}: {{ MeasureValue }}</div>
  </div>
  <div class="modal-footer">
    <div class="justify-content-end">
      <button
        title="{{ 'Select.photoviewer.uploadbtn' | translate }}"
        mat-button
        *ngIf="currentUser.rolePermissions.documentAdd && findUserAccessByRoleRegionLayer()"
        type="button"
        class="primary-button btn-sm"
        (click)="assetPhotoUpload.click()"
      >
        {{ "Select.photoviewer.uploadbtn" | translate }}
      </button>
      <button title="{{ 'Select.photoviewer.exportbtn' | translate }}" mat-button type="button" class="primary-button btn-sm" (click)="export()">{{ "Select.photoviewer.exportbtn" | translate }}</button>
      <button
        title="{{ 'Select.photoviewer.deletebtn' | translate }}"
        mat-button
        *ngIf="currentUser.rolePermissions.documentRemove && findUserAccessByRoleRegionLayer()"
        type="button"
        class="inverse-button btn-sm"
        (click)="deleteImage()"
      >
        {{ "Select.photoviewer.deletebtn" | translate }}
      </button>
    </div>
  </div>
</ng-template>
