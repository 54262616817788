<div>
  <div class="h5 p-3 mb-2 bg-dark text-white">Create map queries</div>
  <div class="h5 p-3 mb-2 bg-light text-dark">
    <div class="row">
      <div class="form-group col-md-2">
        <label for="join_type">Join Type</label>
        <select id="join_type" class="form-control" (ngModelChange)="onJoinChange($event)" [(ngModel)]="jointype" [disabled]="querytableArr.length == 0" aria-label="Join Type">
          <option *ngFor="let j of jointypes" [value]="j.name">
            {{ j.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="query_layers">Layer:</label>
        <select id="query_layers" class="form-control" (ngModelChange)="onLayerChange($event)" [(ngModel)]="selectedlayr">
          <option [value]="''">Select Layer</option>
          <option *ngFor="let l of layerList; let i = index" [value]="l.Layer" id="layerOption" selected>{{ l.tableAlias }}</option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="inputField">Field:</label>
        <select id="inputField" class="form-control" (ngModelChange)="onFieldChange($event)" [(ngModel)]="fields">
          <option [value]="''">Select Field</option>
          <option *ngFor="let f of field; let i = index" [value]="f.Field">
            {{ f.FieldAlias }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-2">
        <label for="inputOperator">Operator:</label>
        <select id="inputOperator" class="form-control" (ngModelChange)="onOperatorChange($event)" [(ngModel)]="operator" autocomplete="off">
          <option *ngFor="let o of oprtrs" [value]="o.id">
            {{ o.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-2" *ngIf="!enableDateTimePicker">
        <label for="inputValue">Value:</label>
        <input id="inputValue" class="form-control" list="values" (ngModelChange)="onValueChange($event)" [(ngModel)]="codedvalue" autocomplete="off" />
        <datalist id="values">
          <option [value]="''">Select Value</option>
          <option *ngFor="let v of codedValues" [value]="v.name">{{ v.name }}</option>
        </datalist>
      </div>
      <div class="form-group col" *ngIf="enableDateTimePicker">
        <label for="wrkshedule">Scheduled:</label>
        <div class="form-group d-flex">
          <mat-form-field appearance="outline">
            <mat-label>Date Time</mat-label>

            <input matInput [(ngModel)]="codedvalue" placeholder="Value" [matDatepicker]="picker" [max]="todayDate" autocomplete="off" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group col-sm-2">
        <div class="mt-4 pt-1">
          <fa-icon [icon]="faPlusSquare" (click)="addItem()"></fa-icon>
          <fa-icon [icon]="faPencilSquare" class="h2 icon" (click)="editItem()" *ngIf="isRowClicked == true"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-striped table-hover mb-0 text-center">
    <thead>
      <tr>
        <th scope="col" style="width: 11%">Join Type</th>
        <th scope="col" style="width: 25%">Layers</th>
        <th scope="col" style="width: 14%">Field</th>
        <th scope="col" style="width: 10%">Operator</th>
        <th scope="col" style="width: 30%">Value</th>
        <th scope="col" style="width: 10%">Remove</th>
      </tr>
    </thead>
  </table>
  <div class="tableqb">
    <table class="table table-striped table-hover text-center">
      <tbody>
        <tr *ngFor="let qt of querytableArr; let i = index" (click)="setClickedRow(i)">
          <td style="width: 11%">{{ qt.jointype }}</td>
          <td style="width: 25%">{{ qt.layer }}</td>
          <td style="width: 14%">{{ qt.field }}</td>
          <td style="width: 10%">{{ qt.operator }}</td>
          <td style="width: 30%">{{ qt.value }}</td>
          <td style="width: 10%">
            <div (click)="deleteFieldValue(i)">
              <fa-icon [icon]="faMinusSquare" class="text-danger h2 icon m-0"></fa-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
  </div>
  <div class="form-group col-12">
    <label for="exampleFormControlTextarea1">Query Description</label>
    <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" style="font-size: 14px" name="query_created" [(ngModel)]="queryDescription" aria-label="Query Description"></textarea>
  </div>
  <div class="form-group col-2" style="display: inline-flex">
    <input id="queryAssigned" type="checkbox" style="width: 10%" class="form-control checkbox" name="query_assigned" [(ngModel)]="queryAssigned" (change)="assignToUser($event)" aria-label="query assign to user" />
    <label for="queryAssigned">Assign</label>
  </div>
  <div class="h5 p-3 mb-2 text-dark bg-light">
    <form>
      <div class="form-row">
        <div class="form-group col-2">
          <select id="inputState1" class="form-control" name="allSavedFiles" [(ngModel)]="selectedLoadFile" (ngModelChange)="onFileNameChange($event, 'PQ-A')">
            <option [value]="''">Select Query</option>
            <option *ngFor="let fileObj of savedQueriesList">
              {{ fileObj.fileName }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <input type="text" class="form-control" placeholder="Query Name…" id="saveFile" name="saveFileName" aria-label="File Name" [(ngModel)]="fileName" />
        </div>
        <!-- Save Query -->
        <div class="form-group mr-1" style="font-size: 25px !important" title="Save Query">
          <div class="input-group-prepend" (click)="saveQuery('PQ')">
            <fa-icon [icon]="faSave"></fa-icon>
          </div>
        </div>
        <div class="form-group m-0" style="font-size: 25px !important" title="Delete Query">
          <div class="input-group-prepend" (click)="deleteQuery('PQ-A')">
            <fa-icon [icon]="trash" class="text-danger"></fa-icon>
          </div>
        </div>
        <div class="col d-flex flex-row-reverse bd-highlight align-items-start">
          <button type="button" class="btn btn-outline-dark mr-1 h6" (click)="buildQuery()">Query</button>
          <button type="button" class="btn btn-outline-dark mr-2" (click)="cleartable()">Clear</button>
          <div class="spinner-border map-loader mr-4 mt-2" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <div *ngIf="showRecordCount && !multipleResults" class="result">
            <span>Records Found : {{ objectIds.length }}</span>
          </div>
          <div *ngIf="showRecordCount && multipleResults">
            <span>Tables : {{ multipleResultCounts.length }}</span>
            <div *ngFor="let tables of multipleResultCounts">{{ tables.layerName }} : {{ tables.dataLength }}</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
