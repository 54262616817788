import { Component, OnInit, Input,HostListener } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsStyle } from "../system.reports.style";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-employeelog",
  templateUrl: "./employeeLog.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class EmployeeLogComponent implements OnInit {
  @Input() map;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public todayDate: Date = new Date();
  faCalendar = faCalendar;
  public currentUser: any;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if((['span','input']).includes(event.target['localName']) &&
       (['empLogRepCalInput1','empLogRepCalSpan1','empLogRepCalInput2','empLogRepCalSpan2']).includes(event.target['id'])){
      (<HTMLInputElement>event.target).click();
    }
    //console.log("From Employee Action Log Keydown...");
  }
  //
  constructor(
    public systemReportsService: SystemReportsService,
    private authenticationService: AuthenticationService,
    private genericService: GenericLayerService,
    private translateService: TranslateService,
    private reportStyle: SystemReportsStyle
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate());
  }

  openReports() {
    try {
      if (this.startDate >= this.endDate) {
        Swal.fire(this.translateService.instant("Systemreports.alertemployeelog.fromtodatevalidate"));
      } else {
        const data = "?FromDate=" + this.startDate.toLocaleString().split(",")[0] + "&ToDate=" + this.endDate.toLocaleString().split(",")[0];
        this.systemReportsService.getEmployeeActionLog(data).subscribe(
          (res) => {
            this.JSONToPDFConvertor(res);
          },
          (err) => {
            Swal.fire("Please contact administrator. API is throwing an error");
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  JSONToPDFConvertor(res) {
    try {
      let JSONData = [];

      if (res.tblemployeelog.length > 0) {
        JSONData = res.tblemployeelog;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertemployeelog.nodatafound"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const arrData1 = typeof res.tblemployeelog1 !== "object" ? JSON.parse(res.tblemployeelog1) : res.tblemployeelog1;
      const arrData2 = typeof res.tblemployeelog2 !== "object" ? JSON.parse(res.tblemployeelog2) : res.tblemployeelog2;
      let tablerow = [];
      const content = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      content.push(
        {
          text: this.translateService.instant("Systemreports.headeremployeelog.employeeactionlog"),
          style: "header"
        },
        {
          stack: [
            {
              text: [
                {
                  text: this.translateService.instant("Systemreports.headeremployeelog.from") + "\xa0",
                  bold: true
                },
                {
                  text: this.startDate.toLocaleString().split(",")[0]
                },
                {
                  text: this.translateService.instant("Systemreports.headeremployeelog.to") + "\xa0",
                  bold: true
                },
                {
                  text: this.endDate.toLocaleString().split(",")[0]
                }
              ]
            }
          ],
          style: "subheader"
        }
      );
      let userName = [];
      arrData.forEach((data) => {
        if (data.createdBy !== "") {
          userName.push(data.createdBy);
        }
      });

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      userName = userName.filter(distinct);
      userName.forEach((data, key) => {
        const AssetID = [];
        let table1 = [];
        let table2 = [];
        const tableHeaderSec1 = [];
        const tablebodySec1 = [];
        const tableHeaderSec2 = [];
        const tablebodySec2 = [];
        const tableHeaderSec3 = [];
        const tablebodySec3 = [];
        const tableHeaderSec4 = [];
        const tablebodySec4 = [];
        const arr = arrData.filter((obj) => obj.createdBy === data);
        arr.forEach((arrdata: any) => {
          AssetID.push(arrdata.assetID);
        });
        AssetID.forEach((arrdata: any) => {
          table1 = arrData1.filter((obj) => obj.assetID === arrdata);
        });
        AssetID.forEach((arrdata: any) => {
          table2 = arrData2.filter((obj) => obj.assetID === arrdata);
        });

        if (key === 0) {
          content.push({
            columns: [
              {
                text: this.translateService.instant("Systemreports.headeremployeelog.username") + data,
                style: "rowGroupHeader"
              }
            ]
          });
        } else {
          content.push(
            {
              text: "",
              pageBreak: "before"
            },
            {
              columns: [
                {
                  text: this.translateService.instant("Systemreports.headeremployeelog.username") + data,
                  style: "rowGroupHeader"
                }
              ]
            }
          );
        }

        // Section 1
        tableHeaderSec1.push(
          { text: this.translateService.instant("Systemreports.headeremployeelog.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.activitytype"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.createddate"), style: "tableHeader" },
          { text: "Region", style: "tableHeader" }
        );
        tablebodySec1.push(tableHeaderSec1);

        const Sec1 = ["assetID", "activityType", "creationDate", "region"];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          tablerow = [];
          // 2nd loop will extract each column and push in the table row
          Sec1.forEach((index) => {
            if (index === "creationDate" && arr[i][index] !== null) {
              tablerow.push({ text: arr[i][index].split("T")[0], style: "tableContent" });
            } else {
              tablerow.push({ text: arr[i][index], style: "tableContent" });
            }
          });
          // add a line break after each row
          tablebodySec1.push(tablerow);
        }

        // Section 2
        tableHeaderSec2.push(
          { text: this.translateService.instant("Systemreports.headeremployeelog.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.activitytype"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.completeddate"), style: "tableHeader" },
          { text: "Region", style: "tableHeader" }
        );
        tablebodySec2.push(tableHeaderSec2);

        const Sec2 = ["assetID", "activityType", "completedDate", "region"];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          tablerow = [];
          // 2nd loop will extract each column and push in the table row
          Sec2.forEach((index) => {
            if (index === "completedDate" && arr[i][index] !== null) {
              tablerow.push({ text: arr[i][index].split("T")[0], style: "tableContent" });
            } else {
              tablerow.push({ text: arr[i][index], style: "tableContent" });
            }
          });
          // add a line break after each row
          tablebodySec2.push(tablerow);
        }

        // Section 3
        tableHeaderSec3.push(
          { text: this.translateService.instant("Systemreports.headeremployeelog.assetid"), style: "tableHeader" },
          {
            text: this.translateService.instant("Systemreports.headeremployeelog.assessmentcondition"),
            style: "tableHeader"
          },
          { text: this.translateService.instant("Systemreports.headeremployeelog.completedbydate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.type"), style: "tableHeader" }
        );
        tablebodySec3.push(tableHeaderSec3);

        const Sec3 = ["assetID", "assessType", "assessCompletionDate", "assessType"];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < table1.length; i++) {
          tablerow = [];
          // 2nd loop will extract each column and push in the table row
          Sec3.forEach((index) => {
            if (index === "assessCompletionDate" && table1[i][index] !== null) {
              tablerow.push({ text: table1[i][index].split("T")[0], style: "tableContent" });
            } else {
              tablerow.push({ text: table1[i][index], style: "tableContent" });
            }
          });
          // add a line break after each row
          tablebodySec3.push(tablerow);
        }

        // Section 4
        tableHeaderSec4.push(
          { text: this.translateService.instant("Systemreports.headeremployeelog.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.documentid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeremployeelog.uploaddate"), style: "tableHeader" }
        );
        tablebodySec4.push(tableHeaderSec4);

        const Sec4 = ["assetID", "docId", "uploadedDate"];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < table2.length; i++) {
          tablerow = [];
          // 2nd loop will extract each column and push in the table row
          Sec4.forEach((index) => {
            if (index === "uploadedDate" && table2[i][index] !== null) {
              tablerow.push({ text: table2[i][index].split("T")[0], style: "tableContent" });
            } else {
              tablerow.push({ text: table2[i][index], style: "tableContent" });
            }
          });
          // add a line break after each row
          tablebodySec4.push(tablerow);
        }

        content.push(
          {
            style: "tableExample",
            table: {
              widths: [120, 120, 120, 120],
              headerRows: 1,
              body: tablebodySec1
            },
            layout: currentLayoutStyle
          },
          {
            style: "tableExample",
            table: {
              widths: [120, 120, 120, 120],
              headerRows: 1,
              body: tablebodySec2
            },
            layout: currentLayoutStyle
          },
          {
            style: "tableExample",
            table: {
              widths: [120, 120, 120, 120],
              body: tablebodySec3
            },
            layout: currentLayoutStyle
          },
          {
            style: "tableExample",
            table: {
              widths: [160, 160, 160],
              headerRows: 1,
              body: tablebodySec4
            },
            layout: currentLayoutStyle
          }
        );
      });
      const dd = {
        pageSize: "A4",
        pageOrientation: "portrait",
        fontSize: 18,
        footer: currentFooter,
        content: content,
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
