import { Injectable } from "@angular/core";
@Injectable()
export class Globals {
  public relatedAssetData: any;
  public IsAssetViewerClosed: any;
  public IsGridViewClosed: any;
  public IsMntViewClosed: any;
  public featureTableObj: any = null;
  public selectedTask: any;
  public showTaskWindow = false;
  public userSelectedActivity: any;
  public relatedAsset: any[] = [];
  public taskGeometry: any;
  public latitude: any;
  public longitude: any;
  public complaintID: any;
  public assetID: any;
  public activeAsset: any;
  public content: any;
  public hideSetting: any;
  public hideAdmin: any;
  public settingStyle: any;
  public adminstyle: any;
  public closeCrowdSource: any;
  public userRoles: any;
  public regions: any; //7/28/20 jcc to pass all regions into task in order to correclty generate workers list
  public toleranceDistance = 15; // in meters added for JIRA ID:-35
  // Added by Madhuri Gupta for JIRA ID:-26 on 08/03/2021
  public assetCardDisplayMaxCol = 5;
  public taskCardDisplayMax = 5;
  // added by sowjanya JIRA ID-53 15-10-2021
  public gpstracklogData = {};
}
