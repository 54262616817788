<div class="sms-model-height">
  <div class="card" style="border: none;">
    <div class="card-header report-tab" >
      <div class="form-row">
        <div class="form-group col-6">
          <h3><span>{{'Systemreports.header.text2'|translate}}</span></h3>
        </div>
        <div class="form-group col-6">
         
          <mat-form-field appearance="outline">
            <mat-label>{{ "Systemreports.header.selectreport" | translate }}</mat-label>
            <mat-select (selectionChange)="reportOnChange($event.value)">
              <mat-option [value]="reportList.reportName" *ngFor="let reportList of reportLists">
                {{ reportList.reportName|translate }}
              </mat-option>
            </mat-select>

            <div class="dropdown-icons">
              <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
            </div>
          </mat-form-field>
        </div>   
      </div> 
      </div>
    <div class="card-body">

      <div *ngIf="ActivityTool">
        <app-sms-activitytotals></app-sms-activitytotals>
      </div>
      <div *ngIf="sheetingType">
        <app-sms-sheetingtype></app-sms-sheetingtype>
      </div>
      <div *ngIf="ActivityByReason">
        <app-sms-activitybyreason></app-sms-activitybyreason>
      </div>
      <div *ngIf="RetiredAssets">
        <app-sms-retiredassets></app-sms-retiredassets>
      </div>
      <div *ngIf="AssetDetail">
        <app-sms-assetdetail [map]="map"></app-sms-assetdetail>
      </div>
      <div *ngIf="employeeLog">
        <app-sms-employeelog [map]="map"></app-sms-employeelog>
      </div>
      <div *ngIf="democratizeAssetInformation">
        <app-sms-democratizeassetinformation [map]="map"></app-sms-democratizeassetinformation>
      </div>
      <div *ngIf="replaceYearAssetClass">
        <app-sms-replaceyearasset></app-sms-replaceyearasset>
      </div>
      <div *ngIf="brushPickup">
        <app-sms-brushpickup [map]="map"></app-sms-brushpickup>
      </div>
      <!-- added by sowjanya JIRA ID:-48 on 27/09/2021 -->
      <div *ngIf="customReportARTESP">
        <app-customreports-artesp></app-customreports-artesp>
      </div>
      <!-- added by sowjanya JIRA ID-51 on 27/09/2021 -->
      <div *ngIf="maintActivitySummary">
        <app-maintactivitysummary></app-maintactivitysummary>
      </div>
      <!-- added by sowjanya JIRA ID-52 on 27/09/2021 -->
      <div *ngIf="currentConditionSummary">
        <app-current-condition-summary></app-current-condition-summary>
      </div>
    </div>
  </div>
</div>
