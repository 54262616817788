import { Component, OnInit } from "@angular/core";
import { DualListComponent } from "angular-dual-listbox";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";

@Component({
  selector: "sms-systemReport",
  templateUrl: "./systemReport.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class systemReportComponent implements OnInit {
  tab = 1;
  keepSorted = true;
  key: string;
  display: string;
  filter = false;
  source: any[];
  confirmed: any[];
  userAdd = "";
  disabled = false;

  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;
  TitlePage: any = "System Reports";
  SourceSideValue: any = "(Available)";
  DestinationSideValue: any = "(Assigned to Customer)";

  private sourceStations: any[];

  private confirmedStations: any[];
  private stations: any = [];
  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {}

  ngOnInit() {
    this.doReset();
  }

  private useStations() {
    this.key = "reportID";
    this.display = "reportName";
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }
  doReset() {
    if (this.adminGlobal.HideSystemReport.length > 0) {
      this.sourceStations = JSON.parse(JSON.stringify(this.adminGlobal.HideSystemReport));
      this.confirmedStations = new Array<any>();
      this.confirmedStations = this.adminGlobal.ShowSystemReport;
      this.useStations();
    } else {
      this.MMMAdminService.getSystemReport().subscribe(
        (res: any) => {
          if (this.adminGlobal.agencyDetails[0].clientType !== "PROFESSIONAL") {
            res = res.filter(function (obj) {
              return obj.reportName.toLowerCase() !== "Democratize Asset Information".toLowerCase();
            });
            this.adminGlobal.HideSystemReport = res;
            this.stations = res;
          } else {
            this.adminGlobal.HideSystemReport = res;
            this.stations = res;
          }

          this.sourceStations = JSON.parse(JSON.stringify(this.stations));
          this.callStations();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  callStations() {
    if (this.adminGlobal.AgencyId === 0) {
      this.confirmedStations = new Array<any>();
      this.adminGlobal.ShowSystemReport = this.confirmedStations;
      this.useStations();
    } else {
      this.MMMAdminService.getSystemReportByclientId(this.adminGlobal.AgencyId).subscribe(
        (res: any) => {
          this.confirmedStations = new Array<any>();
          this.confirmedStations = res;
          this.adminGlobal.ShowSystemReport = this.confirmedStations;
          this.useStations();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  filterBtn() {
    return this.filter ? "Hide Filter" : "Show Filter";
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return this.disabled ? "Enable" : "Disabled";
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }
  OnChange(event) {
    this.adminGlobal.ShowSystemReport = event;
  }
}
