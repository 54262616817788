<div *ngIf="">

</div>
<div *ngIf="jobDetails != null && jobDetails.tblETLJobDetails != null">
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <div>
              <h1>Newly Added Assets</h1>
            </div>
            <div>
              <h1 class="item-count">({{jobDetails.tblETLJobDetails[0].resultCount}})</h1>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
        <table mat-table [dataSource]="jobDetails.tblETLJobDetails1" *ngIf="jobDetails.tblETLJobDetails1.length > 0" class="mat-elevation-z8 change-report">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
                <!-- Symbol Column -->

          <!-- Position Column -->
          <ng-container matColumnDef="AssetId">
            <th mat-header-cell *matHeaderCellDef> Asset Id </th>
            <td mat-cell *matCellDef="let element"> {{element.assetId}} </td>
          </ng-container>
        
          <!-- Name Column -->
          <ng-container matColumnDef="AssetCode">
            <th mat-header-cell *matHeaderCellDef> Asset Code </th>
            <td mat-cell *matCellDef="let element"> {{element.assetCode}} </td>
          </ng-container>
        
          <!-- Weight Column -->
          <ng-container matColumnDef="Geolocation">
            <th mat-header-cell *matHeaderCellDef> Geolocation </th>
            <td mat-cell *matCellDef="let element"> {{element.geolocation}} </td>
          </ng-container>

          <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator (page)="pageChanged($event, 1)" [length]="jobDetails.tblETLJobDetails[0].resultCount" [pageSize]="50" aria-label="Select page of Change Report"></mat-paginator>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <h1>Changes to Existing Assets - Updated</h1><h1 class="item-count"> ({{jobDetails.tblETLJobDetails[1].resultCount}}) </h1>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table *ngIf="jobDetails != null && jobDetails.tblETLJobDetails2 != null && jobDetails.tblETLJobDetails2.length > 0" mat-table [dataSource]="jobDetails.tblETLJobDetails2" class="mat-elevation-z8 change-report">
  
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
              <!-- Symbol Column -->

        <!-- Position Column -->
        <ng-container matColumnDef="AssetId">
          <th mat-header-cell *matHeaderCellDef> Asset Id </th>
          <td mat-cell *matCellDef="let element"> {{element.assetId}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="AttributeName">
          <th mat-header-cell *matHeaderCellDef> Attribute Name </th>
          <td mat-cell *matCellDef="let element"> {{element.attributeName}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="ExistingValue">
          <th mat-header-cell *matHeaderCellDef> Existing Value </th>
          <td mat-cell *matCellDef="let element"> {{element.existingValue}} </td>
        </ng-container>
      
        <ng-container matColumnDef="NewValue">
          <th mat-header-cell *matHeaderCellDef> New Value </th>
          <td mat-cell *matCellDef="let element"> {{element.newValue}} </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-button (click)="createTaskForReviewRecord(element, 'updated')">Create Task</button> </td>
        </ng-container>

      
        <tr mat-header-row class="table-header" *matHeaderRowDef="updatedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: updatedColumns;"></tr>
      </table>

      <mat-paginator [length]="jobDetails.tblETLJobDetails[1].resultCount" (page)="pageChanged($event, 2)" [pageSize]="50" aria-label="Select page of Change Report"></mat-paginator>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <div>
              <h1>Existing Assets not available in the new data set - Review</h1> 
            </div>
            <div>
              <h1 class="item-count">({{jobDetails.tblETLJobDetails[2].resultCount}})</h1>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table *ngIf="jobDetails != null && jobDetails.tblETLJobDetails != null && jobDetails.tblETLJobDetails3.length > 0" mat-table [dataSource]="jobDetails.tblETLJobDetails3" class="mat-elevation-z8 change-report">
    
        <ng-container matColumnDef="AssetId">
          <th mat-header-cell *matHeaderCellDef> Asset Id </th>
          <td mat-cell *matCellDef="let element"> {{element.assetId}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="AssetCode">
          <th mat-header-cell *matHeaderCellDef> Asset Code </th>
          <td mat-cell *matCellDef="let element"> {{element.assetCode}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="Geolocation">
          <th mat-header-cell *matHeaderCellDef> Geolocation </th>
          <td mat-cell *matCellDef="let element"> {{element.geolocation}} </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-button (click)="createTaskForReviewRecord(element, 'removed')">Create Task</button> </td>
        </ng-container>
        
          <tr mat-header-row class="table-header" *matHeaderRowDef="reviewColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: reviewColumns;"></tr>
        </table>

        <mat-paginator [length]="jobDetails.tblETLJobDetails[2].resultCount" (page)="pageChanged($event, 3)" [pageSize]="50" aria-label="Select page of Change Report"></mat-paginator>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <div>
              <h1>Changes to Existing Assets - Review (user updated changes)</h1>
            </div> 
            <div>
              <h1 class="item-count">({{jobDetails.tblETLJobDetails[3].resultCount}})</h1>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table *ngIf="jobDetails != null && jobDetails.tblETLJobDetails4 != null && jobDetails.tblETLJobDetails4.length > 0" mat-table [dataSource]="jobDetails.tblETLJobDetails4" class="mat-elevation-z8 change-report">
      
        <ng-container matColumnDef="AssetId">
          <th mat-header-cell *matHeaderCellDef> Asset Id </th>
          <td mat-cell *matCellDef="let element"> {{element.assetId}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="AttributeName">
          <th mat-header-cell *matHeaderCellDef> Attribute Name </th>
          <td mat-cell *matCellDef="let element"> {{element.attributeName}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="ExistingValue">
          <th mat-header-cell *matHeaderCellDef> Attribute value (RSAM) </th>
          <td mat-cell *matCellDef="let element"> {{element.existingValue}} </td>
        </ng-container>
      
        <ng-container matColumnDef="NewValue">
          <th mat-header-cell *matHeaderCellDef> New Value (data set) </th>
          <td mat-cell *matCellDef="let element"> {{element.newValue}} </td>
        </ng-container>  
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-button (click)="createTaskForReviewRecord(element, 'updated')">Create Task</button> </td>
        </ng-container>
          <tr mat-header-row class="table-header" *matHeaderRowDef="updatedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: updatedColumns;"></tr>
        </table>

        <mat-paginator [length]="jobDetails.tblETLJobDetails[3].resultCount" (page)="pageChanged($event, 4)" [pageSize]="50" aria-label="Select page of Change Report"></mat-paginator>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <div>
              <h1>Exception Records </h1>
            </div>  
            <div>
              <h1 class="item-count">({{jobDetails.tblETLJobDetails[4].resultCount}})</h1>
            </div> 
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table *ngIf="jobDetails != null && jobDetails.tblETLJobDetails5 != null && jobDetails.tblETLJobDetails5.length > 0" mat-table [dataSource]="jobDetails.tblETLJobDetails5" class="mat-elevation-z8 change-report">
        
        <ng-container matColumnDef="LayerName">
          <th mat-header-cell *matHeaderCellDef> Layer Name </th>
          <td mat-cell *matCellDef="let element"> {{element.layerName}} </td>
        </ng-container>
        <ng-container matColumnDef="AssetNumber">
          <th mat-header-cell *matHeaderCellDef> Asset Number </th>
          <td mat-cell *matCellDef="let element"> {{element.assetNumber}} </td>
        </ng-container>
      
        <ng-container matColumnDef="Geolocation">
          <th mat-header-cell *matHeaderCellDef> Geolocation </th>
          <td mat-cell *matCellDef="let element"> {{element.geolocation}} </td>
        </ng-container>
        
        <ng-container matColumnDef="PossibleMatch">
          <th mat-header-cell *matHeaderCellDef> Possible Match</th>
          <td mat-cell *matCellDef="let element"> {{element.possibleMatch}} </td>
        </ng-container>
      
      
  
        
          <tr mat-header-row class="table-header" *matHeaderRowDef="exceptionColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: exceptionColumns;"></tr>
        </table>

        <mat-paginator [length]="jobDetails.tblETLJobDetails[4].resultCount" (page)="pageChanged($event, 5)" [pageSize]="50" aria-label="Select page of Change Report"></mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
  <form class="review-form" *ngIf="reviewedFormGroup" [formGroup]="reviewedFormGroup" (ngSubmit)="onSubmitReviewedFormGroup()" style="width:100%;margin-top:24px;">
    <h2 style="margin-bottom:16px; font-size:1.5rem; font-weight:bold;">Mark Report as Reviewed</h2>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <mat-form-field appearance="fill" style="width:35%">
              <mat-label>Review Comments</mat-label>
              <textarea [readonly]="selectedJob.reviewDate != null" [formControl]="reviewComments" matInput></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="selectedJob.reviewedBy != null && selectedJob.reviewedBy != ''">
        <mat-label>
          Reviewed By
        </mat-label>
        <input disabled matInput type="text" readonly [value]="selectedJob.reviewedBy"/>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="selectedJob.reviewedBy != null && selectedJob.reviewedBy != ''">
        <mat-label>
          Review Date
        </mat-label>
        <input disabled matInput type="text" readonly [value]="selectedJob.reviewDate"/>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!reviewedFormGroup.valid || (selectedJob.reviewedBy != null && selectedJob.reviewedBy != '')">Mark as Reviewed</button>
    </div>
</form>  
</div>
