<div class="card">
    <div class="card-body formbg-d2">
        <div class="text-dark">
            <div class="form-row pb-5">
                <div class="col-3"></div>
                <div class="col-2">
                    <span>Asset Class:</span>
                </div>
                <div class="col-3">
                    <select name="region-select" id="region-select" class="form-control" (change)="onChange()"
                        [(ngModel)]="assetClassListSelected">
                        <option [ngValue]="undefined" disabled selected> select Asset Class... </option>
                        <ng-container *ngFor="let assetClassList of AssetClassList">
                            <option [ngValue]="assetClassList">{{assetClassList.gislayername}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-4"></div>
            </div>
            <div class="form-row pb-5">
                <div class="col-3"></div>
                <div class="col-2">
                    <span>Base life Field:</span>
                </div>
                <div class="col-3">
                    <select name="layer-select" id="layer-select" class="form-control" (change)="BaseLifeChange()"
                        [(ngModel)]="baseLifeFieldSelected">
                        <option [ngValue]="undefined" disabled selected> select BaseLife Field... </option>
                        <ng-container *ngFor="let baseLifeField of BaseLifeField">
                            <option [ngValue]="baseLifeField">{{baseLifeField.BLF_Column}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-4"></div>
            </div>
            <div class="mt-4 d-flex justify-content-end align-items-end">
                <button type="button" class="btn btn-secondary" *ngIf="SubmitButton"
                    (click)="OnSubmit()">Submit</button>
            </div>
            <div class='row'>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Asset Class</th>
                            <th scope="col">BLF_Column</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let retroBaseLifeTable of globalvariable.ExistBaseLife">
                            <td> {{retroBaseLifeTable.AssetClass}} </td>
                            <td> {{retroBaseLifeTable.BLF_Column}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>