<div class="card-body">
  <div class="card-body">
    <div class="form-row pb-5">
      <div class="col-3"></div>
      <div class="col-2">
        <span id="actSumReportLabelID">{{ "Systemreports.summaryofmaint.regionname" | translate }}:</span>
      </div>
      <div class="col-3">
        <select aria-labelledby="actSumReportLabelID" class="form-control" (ngModelChange)="regionListSelected($event)" [(ngModel)]="regionSelected">
          <option *ngFor="let region of regionsListByClient" [ngValue]="region">
            {{ region.regionName }}
          </option>
        </select>
      </div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="card-body">
    <div class="form-row pb-5">
      <div class="col-3"></div>
      <div class="col-2">
        <label id="actSumAssetClsLabelID" for="region">{{ "Userscreens.useradmin.assetclasslabel" | translate }}:</label>
      </div>
      <div class="col-3">
        <select aria-labelledby="actSumAssetClsLabelID" class="form-control" (change)="onAssetClassChange()" [(ngModel)]="selectedLayer">
          <option *ngFor="let layer of layerList" [ngValue]="layer">
            {{ layer.gisLayerName }}
          </option>
        </select>
      </div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="card-body">
    <div class="form-row pb-5">
      <div class="col-3"></div>
      <div class="col-2">
        <label id="actSumAssetDetLabelID" for="region">{{ "Systemreports.headerassetdetail.assetcode" | translate }}:</label>
      </div>
      <div class="col-3">
        <ng-multiselect-dropdown
          [data]="dropdownList"
          [settings]="dropdownSettings"
          aria-labelledby="actSumAssetDetLabelID"
          (onSelect)="onItemSelect($event)"
          [(ngModel)]="selectedItems"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect)="onItemDeSelect($event)"
          (onDeSelectAll)="onDeSelectAll($event)"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="form-row pb-5">
      <div class="col-3"></div>
      <div class="col-2">
        <span id="actSumFdateLabelID">{{ "Systemreports.activitybyreason.fromdate" | translate }}:</span>
      </div>
      <div class="col-7">
        <div class="form-group d-flex">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Systemreports.activitybyreason.fromdate" | translate }}</mat-label>
            <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [max]="endDate" [(ngModel)]="startDate" autocomplete="off" />

            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="form-row pb-5">
      <div class="col-3"></div>
      <div class="col-2">
        <span id="actSumTdateLabelID">{{ "Systemreports.activitybyreason.todate" | translate }}:</span>
      </div>
      <div class="col-7">
        <div class="form-group d-flex">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Systemreports.activitybyreason.todate" | translate }}</mat-label>
            <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [min]="startDate" [max]="todayDate" [(ngModel)]="endDate" autocomplete="off" />

            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="card-footer text-right">
    <div>
      <select aria-label="Choose File Type" class="select-control" [(ngModel)]="format">
        <option value="CSV">CSV</option>
        <option value="PDF">PDF</option></select
      >&nbsp; &nbsp;
      <button type="button" class="btn btn-primary" [attr.aria-label]="'' + 'Systemreports.btn.generatereport' | translate" (click)="exportFormat()">{{ "Systemreports.btn.generatereport" | translate }}</button>
    </div>
  </div>
</div>
