<div class="heading-container">
  <div class="h4-heading heading-left">
    {{ headingText }}
  </div>
  <div class="heading-right">
    <div class="right-container">
      <div *ngIf="isTaskViewerHeader == true" style="display: flex; align-items: center">
        <mat-checkbox color="primary" [(ngModel)]="checkboxChecked" labelPosition="before" (change)="onCheckboxChange($event)" style="margin: 8px; height: 24px">{{ checkboxlabel1 }}</mat-checkbox>
        <mat-icon [svgIcon]="iconTwo" (click)="iconTwoClick()" [matTooltip]="tooltipTwo"></mat-icon>
        <mat-icon [svgIcon]="iconThree" (click)="iconThreeClick()" [matTooltip]="tooltipThree"></mat-icon>
      </div>
      <div *ngIf="isGridHeader == true" class="right-container">
        <button mat-button [matMenuTriggerFor]="menu" class="export-type-btn small">{{ selected }} <mat-icon variant="outlined" class="material-icons-outlined" matSuffix svgIcon="arrow-up-down"></mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="small" (click)="onChange('CSV')">CSV</button>
          <button mat-menu-item class="small" (click)="onChange('PDF')">PDF</button>
        </mat-menu>
      </div>
      <div *ngIf="iconOne && isCardView == false" class="icon-heading" (click)="iconOneClick($event)" data-dismiss="modal">
        <span class="icon-heading-container"><mat-icon variant="outlined" class="material-icons-outlined" [svgIcon]="iconOne" [matTooltip]="tooltipOne" [matTooltip]="tooltipOne"></mat-icon></span>
      </div>
      <div id="relocate" *ngIf="iconOne && isCardView == true && iconOne == 'add-query'" class="icon-heading" (click)="iconOneClick($event)">
        <span class="icon-heading-container"><mat-icon variant="outlined" class="material-icons-outlined" [svgIcon]="iconOne" [matTooltip]="tooltipOne" [matTooltip]="tooltipOne"></mat-icon></span>
      </div>
      <div *ngIf="iconTwo && iconTwoDropdown == true && isCreateEditTaskHeader == false && iconTwo == 'plus'" class="icon-heading" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="icon-heading-container"><mat-icon variant="outlined" class="material-icons-outlined" [svgIcon]="iconTwo" [matTooltip]="tooltipTwo"></mat-icon></span>
      </div>
      <div id="draw" style="display: flex" *ngIf="iconTwo && isCreateEditTaskHeader == false && iconTwo == 'draw'" class="icon-heading" (click)="reshape.emit()">
        <span class="icon-heading-container"
          ><mat-icon variant="outlined" class="material-icons-outlined" style="fill: #323237; font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 48" [matTooltip]="reshapeTooltip">{{
            iconTwo
          }}</mat-icon></span
        >
      </div>
      <div *ngIf="iconTwoDropdown == false && iconTwo && isCreateEditTaskHeader == false && isTaskViewerHeader === false" (click)="iconTwoClick()" class="icon-heading">
        <span class="icon-heading-container"><mat-icon variant="outlined" class="material-icons-outlined" [svgIcon]="iconTwo" [matTooltip]="tooltipTwo"></mat-icon></span>
      </div>
      <div class="work-order" *ngIf="iconTwoDropdown == false && iconTwo && isCreateEditTaskHeader == true">
        <div>Manage Work Order</div>
        <mat-icon (click)="iconTwoClick()" variant="outlined" title="Maintenance Defaults Form" class="material-icons-outlined icon-heading" style="vertical-align: super" [matTooltip]="tooltipTwo">{{
          iconTwo
        }}</mat-icon>
      </div>
      <div class="dropdown-menu" aria-label="btnGroupDrop1" *ngIf="isGridHeader == false">
        <a *ngIf="signAddAccess" class="dropdown-item" href="#" (click)="dropdownSelect('Sign')">{{ tableAliases.Sign }}</a>
        <a *ngIf="lightAddAccess" class="dropdown-item" href="#" (click)="dropdownSelect('Light')">{{ tableAliases.Light }}</a>
      </div>
      <div *ngIf="iconFour" class="icon-heading" (click)="iconFourClick()">
        <span class="icon-heading-container"><mat-icon variant="outlined" class="material-icons-outlined" [svgIcon]="iconFour" [matTooltip]="tooltipFour"></mat-icon></span>
      </div>
      <div *ngIf="iconThree && isTaskViewerHeader == false" class="icon-heading" (click)="iconThreeClick()">
        <span class="icon-heading-container"
          ><mat-icon variant="outlined" class="material-icons-outlined" [style.vertical-align]="isCreateEditTaskHeader ? 'super' : 'initial'" [svgIcon]="iconThree" [matTooltip]="tooltipThree"></mat-icon
        ></span>
      </div>

      <div class="dropdown-menu columnlist-showHide-container" *ngIf="isGridHeader == true && !this.scndlyr" aria-label="dropdownMenuButton">
        <a class="dropdown-item" href="#" *ngFor="let columnField of columnsFieldsList; let x = index">
          <div *ngIf="columnField.attributes.FieldAlias">
            <input
              type="checkbox"
              class="columnlist-checkbox-label"
              id="{{ 'fieldChk_' + x }}"
              (click)="columnVisibilityCheck($event, columnField)"
              [checked]="columnField.attributes.GridVisible == 1 ? true : false"
              aria-label="FieldAlias"
            />
            {{ columnField.attributes.FieldAlias }}
          </div>
        </a>
      </div>
      <div class="dropdown-menu columnlist-showHide-container" *ngIf="isGridHeader == true && this.scndlyr" aria-label="dropdownMenuButton">
        <a class="dropdown-item" href="#" *ngFor="let columnField of columnsFieldsList">
          <div *ngIf="columnField.attributes.FieldAlias_SL">
            <input type="checkbox" class="columnlist-checkbox-label" (click)="columnVisibilityCheck($event, columnField)" [checked]="columnField.attributes.GridVisible == 1 ? true : false" />
            {{ columnField.attributes.FieldAlias_SL }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
