import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { CardViewService } from "src/app/services/card-view.service";
import { WeatherService } from "src/app/services/weatherForcast.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

import { AssetDetailService } from "../../../services/assetDetail.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { EventEmitersService } from "../../../services/eventEmiters.service";
import { GenericLayerService } from "../../../services/genericLayer.service";
import { QueryLayers } from "../../../services/queryLayers.service";
import { Globals } from "../../globals";
import { DatePipe } from "@angular/common";
import { faLock, faUnlock, faUndo } from "@fortawesome/free-solid-svg-icons";

@AutoUnsubscribe()
@Component({
  selector: "sms-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["../../select.component.scss"]
})
export class TasksComponent implements OnInit {
  // #region Properties (42)

  // for sending create task ;
  RollBackObjectID;
  RollbackEvent: any = [];
  activity: any;
  currentUser: any;
  detailForecastData;
  loading = false;
  maintanancelyr;
  modalReference: NgbModalRef;
  oldAssectAssId;
  oldAssectId;
  rollback;
  selectedActiveAsset;
  showEditTextBox = false;
  showTable = false;
  showtask = false;
  taskComments: any;
  taskDate = "";
  taskDesc: any;
  taskName: any;
  tasksData = [];
  // public max= new Date(new Date().setDate(new Date().getDate() + 5));
  public woID: any;
  public worker: any;

  faLock = faLock;
  faUnlock = faUnlock;
  faUndo = faUndo;
  @Input() public FeatureLayer: any;
  @Input() public Graphic: any;
  @Input() public Point: any;
  @Input() public Query: any;
  @Input() public QueryTask: any;
  @Input() public activeAsset: any;
  @Input() public asset: any;
  @Output() public closeCard: any = new EventEmitter();
  public emitactiveAssetChanged: Subscription;
  // events
  public emitactiveAssetChangedBySelect;
  public emitactiveAssetFeatureSubscribe: Subscription;
  public emitreplaceTaskCreatedSubscribe: Subscription;
  public emitreshapeTaskCreatedSubscribe: Subscription;
  @Input() public geometryJsonUtils: any;
  public getFilteredFeaturesByFieldSubscribe: Subscription;
  public getFilteredFeaturesByListOfValuesSubscribe: Subscription;
  @Input() public map: any;
  @Input() public relatedAsset: any;
  public todayDate: any = new Date();
  @Input() public webMercatorUtils: any;
  wrkStatus: string;
  ftrLyrTbl: any;
  activityLayer: string;
  @Output() showTasks = new EventEmitter();
  // #endregion Properties (42)

  // #region Constructors (1)

  constructor(
    public objAssetDetailService: AssetDetailService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private weatherService: WeatherService,
    public objGlobals: Globals,
    private authenticationService: AuthenticationService,
    private genericLayerService: GenericLayerService,
    private query: QueryLayers,
    private translateService: TranslateService,
    public esriMap: EsriMapComponent,
    private cs: CardViewService,
    public eventEmitersService: EventEmitersService
  ) {
    config.backdrop = "static";
    config.keyboard = false;

    this.currentUser = this.authenticationService.currentUserValue;
    this.emitreshapeTaskCreatedSubscribe = this.eventEmitersService.emitreshapeTaskCreated.subscribe((actveAsset) => {
      this.activeAsset = actveAsset;
      this.tasksData = [];
      this.loadFeatures();
    });
    this.emitactiveAssetFeatureSubscribe = this.emitactiveAssetChanged = this.eventEmitersService.emitactiveAssetFeature.subscribe((actveAsset: any) => {
      console.log("Calling from 1");
      // if(this.activeAsset[0].AssetID != actveAsset[0].AssetID){
      this.activeAsset = actveAsset;
      this.tasksData = [];
      this.loadFeatures();
      // }
    });
    this.emitreplaceTaskCreatedSubscribe = this.eventEmitersService.emitreplaceTaskCreated.subscribe((actveAsset) => {
      this.activeAsset = actveAsset;
      this.tasksData = [];
      this.loadFeatures();
    });
  }

  // #endregion Constructors (1)

  // #region Public Methods (30)

  public DeletePendingChanges() {
    const features = new this.Graphic();
    const att: any = [];
    att.OBJECTID = this.RollbackEvent.OBJECTID;
    features.setAttributes(att);
    this.maintanancelyr
      .applyEdits(null, null, [features])
      .then(
        function () {
          this.loading = false;
          //  this.map.getLayer("sms-layers").refresh();
          //  this.map.getLayer("sms-selection").clear();
          //  this.map.graphics.clear();
          this.RollbackEvent = [];
          console.log("Calling from 6");
          this.loadFeatures();
          Swal.fire(this.translateService.instant("Task.alert.rollbacksucess"));
        }.bind(this)
      )
      .catch((error) => {
        console.log("===============================================");
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        console.log("error = ", error);
      });
  }

  public RollBack(event) {
    try {
      console.log(event);

      if (event.PendingChanges !== null && event.Activity !== "New") {
        this.loading = true;
        this.RollbackEvent = event;
        const features = new this.Graphic();
        const att = JSON.parse(event.PendingChanges);
        this.oldAssectId = att.AssetID;
        if (att.Shape) {
          features.setGeometry(this.geometryJsonUtils.fromJson(att.Shape));
          delete att.Shape;
        }
        features.setAttributes(att);
        this.activeAsset[1].lyrObj
          .applyEdits(null, [features], null)
          .then(
            function () {
              this.DeletePendingChanges();
            }.bind(this)
          )
          .catch((error) => {
            console.log("===============================================");
            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      } else if (event.PendingChanges !== null && event.Activity === "New") {
        const rollbackValues: any = [];
        rollbackValues.attributes = JSON.parse(event.PendingChanges);
        this.oldAssectId = rollbackValues.attributes.AssetID;
        if (rollbackValues.attributes.AssetID !== this.asset[0].AssetID) {
          this.RollbackEvent = event;
          const features = new this.Graphic();
          const att: any = [];
          att.OBJECTID = rollbackValues.attributes.OBJECTID;
          att.Status = "Active";
          // att.RetireDate = null;
          console.log("check here");
          console.log(att);
          // att.RetireDate = null;
          features.setAttributes(att);
          this.query.updateAsset(this.asset[2]._layer, features).then((result) => {
            const delfeatures = new this.Graphic();
            const delatt: any = [];
            delatt.OBJECTID = this.asset[0].OBJECTID;
            this.deleteRelocateTaskAftRollback();
            delfeatures.setAttributes(delatt);
            this.query.deleteAsset(this.asset[2]._layer, delfeatures).then(() => {
              this.loading = false;
              this.closeCard.emit(true);
              this.map.getLayer("sms-layers").refresh();
              this.map.getLayer("sms-selection").clear();
              this.map.graphics.clear();
              this.query.rollBacklinkRelatedAssets(this.oldAssectId, this.objGlobals.relatedAssetData);
              if (this.activeAsset[0].AssetID) {
                this.asset[0].AssetID = this.oldAssectId;
              } else {
                this.asset[0].signAttribute.AssetID = this.oldAssectId;
              }
              console.log("Calling from 5");
              this.loadFeatures();
              Swal.fire(this.translateService.instant("Task.alert.rollbacksucess"));
            });
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  public SaveExistData() {
    try {
      this.cs.getFeatures(this.activeAsset[1].lyrObj.url, "ObjectID = " + this.activeAsset[0].OBJECTID, this.Query, this.QueryTask).then((val) => {
        const result = val[0];
        console.log(result);
        this.rollback = {};
        this.rollback = result.attributes;
        this.replace();
      });
    } catch (error) {
      console.error(error);
    }
  }

  public ShowRollBack(task) {
    try {
      if (task && task[0] && task[0].attributes) {
        this.RollBackObjectID = task[0].attributes.OBJECTID;
        console.log(this.RollBackObjectID);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public close() {
    // this.ClearForms();
    this.modalReference.close();
  }

  public closeCardView() {
    this.closeCard.emit(true);
  }

  public completeTask(attributes) {
    try {
      this.wrkStatus = "Closed";
      this.activity = attributes.Activity;
      const featurese = [];
      const features = new this.Graphic();
      attributes.Status = "Closed";
      attributes.CompletionDate = new Date(Date.now()).getTime();
      attributes.CompletedBy = this.currentUser.username;
      features.setAttributes(attributes);
      featurese.push(features);
      this.ftrLyrTbl
        .applyEdits(null, featurese, null)
        .then(
          function () {
            // Update Status of Asset if Retired or Replaced
            this.objAssetDetailService.Query = this.esriMap.Query;
            this.objAssetDetailService.QueryTask = this.esriMap.QueryTask;

            if (this.activity === "Retire") {
              //this.eventEmitersService.setShowCard(false);
            }
            this.esriMap.map.getLayer("sms-layers").refresh();

            if (this.wrkStatus === "Closed" && this.activity === "New") {
              const lyrObj = this.activeAsset[1].lyrObj;
              this.genericLayerService.getFilteredFeaturesByField(lyrObj.layerId, "AssetID", this.activeAsset[0].AssetID).subscribe(
                function (resultObj) {
                const features = new this.Graphic();
                const att: any = [];
                att.OBJECTID = resultObj.features[0].attributes.OBJECTID;
                att.InstallDate = new Date(Date.now()).getTime();
                features.setAttributes(att);
                this.query.updateAsset(lyrObj, features).then((result) => {
                  this.eventEmitersService.emitTaskCompleted.emit(true);
                });
              }.bind(this));
            }
            
            if (this.wrkStatus === "Closed" && this.activity === "Replace") {
              // Update Status of Asset if Retired or Replaced
              this.objAssetDetailService.Query = this.esriMap.Query;
              this.objAssetDetailService.QueryTask = this.esriMap.QueryTask;
              const activityLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
              this.objAssetDetailService.ReplaceTask(this.activeAsset, this.ftrLyrTbl, activityLayer, this.FeatureLayer, this.Graphic, this.currentUser, features.attributes);
              this.esriMap.map.getLayer("sms-layers").refresh();
            }
            if (this.wrkStatus === "Closed" && this.activity === "Retire") {
              this.objAssetDetailService.Query = this.esriMap.Query;
              this.objAssetDetailService.QueryTask = this.esriMap.QueryTask;
              const activityLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
              this.objAssetDetailService.RetireAsset(this.activeAsset, this.ftrLyrTbl, this.currentUser, this.FeatureLayer);


              if (this.eventEmitersService.activeAssetFeature[1].layerName == "Sign" || this.eventEmitersService.activeAssetFeature[1].layerName == "Light") {
                setTimeout(function () {
                  this.eventEmitersService.setchildDeleted(false);
                }.bind(this), 5000)
              } else {
                if (this.esriMap.map.getLayer("sms-selection")) {
                  this.esriMap.map.getLayer("sms-selection").remove(
                    this.esriMap.map.getLayer("sms-selection").graphics.filter((item) => {
                      if (item.attributes.AssetID == this.eventEmitersService.activeAssetFeature[0].AssetID) return item;
                    })[0]
                  );

                  this.esriMap.map.getLayer("sms-selection").refresh();
                  this.esriMap.map.graphics.clear();
                }
                if (this.eventEmitersService.selectedFeaturesOnMap && this.eventEmitersService.selectedFeaturesOnMap.length) {
                  this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.splice(this.eventEmitersService.selectedParentIndexOnData, 1);
                  this.eventEmitersService.setShowCard(false);

                  if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length > 0) {
                    this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap, this.eventEmitersService.selectedLayerIndexOnData, 0);
                  } else {
                    this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap);
                  }

                  if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length) {
                    this.eventEmitersService.setShowCard(true);
                  }
                }

                this.esriMap.map.getLayer("sms-selection").refresh();
              }
              this.esriMap.map.graphics.clear();
              this.esriMap.map.getLayer("sms-layers").refresh();
              this.objAssetDetailService.tasksModified.emit(true);
            }
            if (this.activity !== "Retire" && this.activity !== "Replace") {
              this.objAssetDetailService.tasksModified.emit(true);
            }

            this.eventEmitersService.emitTaskAdded(true);
            this.objAssetDetailService.tasksModified.emit(true);
            this.eventEmitersService.emitTaskCreated.emit(true);

          }.bind(this),
          this.showTasks.emit(false)
        )
        // tslint:disable-next-line:only-arrow-functions
        .catch(function (error) {
          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
          console.log("error = ", error);
        });

    } catch (error) {
      console.error(error);
    }
  }

  public createNew() {
    try {
      const element = document.getElementById("assestDetailDragDiv");
      element.setAttribute("style", "transform: initial;");
      this.objGlobals.selectedTask = null;
      this.objGlobals.userSelectedActivity = "General";
      this.selectedActiveAsset = this.activeAsset;
      this.objGlobals.regions = this.getRegions(this.asset); //7/28/20 jcc
      this.showtask = true;
      this.eventEmitersService.createTaskOpened(true);
  } catch (error) {
    console.error(error);
  }
  }

  public deleteRelocateTaskAftRollback() {
    try {
      let oldAssetTasks;
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", this.oldAssectId).subscribe((results: any) => {
        oldAssetTasks = results.features;
        // tslint:disable-next-line:prefer-const
        const AssetIdsList = [];
        oldAssetTasks.forEach((layer) => {
          AssetIdsList.push("'" + layer.attributes.MaintID + "'");
        });
        this.getFilteredFeaturesByListOfValuesSubscribe = this.genericLayerService
          .getFilteredFeaturesByListOfValues(this.currentUser.serviceLyrInfo.activityId, "MaintID", AssetIdsList.toString())
          .subscribe((results: any) => {
            if (results.features && results.features[0] && results.features[0].attributes) {
              oldAssetTasks.forEach((task) => {
                if (
                  results.features.filter((acy) => {
                    if (acy.attributes.MaintID === task.attributes.MaintID) {
                      return acy;
                    }
                  }).length > 0
                ) {
                  task.attributes.Activity = results.features.filter((acy) => {
                    if (acy.attributes.MaintID === task.attributes.MaintID) {
                      return acy;
                    }
                  })[0].attributes.Activity;
                  console.log("valid activity");
                }
              });
            }
            const featuresList = [];
            oldAssetTasks.forEach(
              function (task) {
                const feature = new this.Graphic();
                const att: any = [];
                att.OBJECTID = task.attributes.OBJECTID;
                feature.setAttributes(att);
                if (task.attributes.Activity === "Replace" && task.attributes.Status === "Closed") {
                  featuresList.push(feature);
                }
              }.bind(this)
            );
            console.log("deleteing " + featuresList);
            this.maintanancelyr.applyEdits(null, null, featuresList).then(
              function () {
                this.loading = false;
                this.map.getLayer("sms-layers").refresh();
              }.bind(this)
            );
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  public deleteTask(attributes) {
    try {
      Swal.fire({
        title: this.translateService.instant("Select.createtask.deletetask"),
        text: this.translateService.instant("Select.createtask.deletetaskconfirmmsg"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant("Select.createtask.continuebtn")
      }).then((result) => {
        if (result.value) {
          const featurese = [];
          const features = new this.Graphic();
          attributes.Status = "Closed";
          features.setAttributes(attributes);
          featurese.push(features);
          this.maintanancelyr
            .applyEdits(null, null, featurese)
            .then(
              function (newrow) {
                console.log("Calling from 2");
                this.eventEmitersService.settaskCompleted(true);
                this.loadFeatures();
                console.log(newrow);
              }.bind(this)
            )
            .catch((error) => {
              console.log("===============================================");
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  public editTask(task) {
    try {
      const assetId = task.MaintID;
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", assetId).subscribe((results: any) => {
        //  this.activity = results.features[0].attributes.Activity;
        task.Activity = results.features[0].attributes.Activity;
        this.objGlobals.selectedTask = task;
        const jsn = JSON.parse(task.GeomWKT);
        this.objGlobals.taskGeometry = this.esriMap.Point(jsn.x, jsn.y, jsn.spatialReference);
        this.objGlobals.userSelectedActivity = results.features[0].attributes.Activity;
        this.selectedActiveAsset = null;
        // 25772
        // this.selectedActiveAsset = this.activeAsset;
        this.objGlobals.regions = this.getRegions(this.asset); //7/28/20 jcc
        this.showtask = true;
        this.objGlobals.activeAsset = this.activeAsset;//TSDRSAM-2641
        this.eventEmitersService.createTaskOpened(true);
      });
    } catch (error) {
      console.error(error);
    }
  }

  public findUserAccessByRoleRegionLayer() {
    try {
      // Role
      // if (condition) {
      // } else {
      // }
      // Region
      if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
        const layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.activeAsset[1].lyrObj.layerId) {
              return layer;
            }
          }.bind(this)
        )[0];
        // Layer
        if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
          return true;
        }
        // else {  return false; }
        return false;
      }
      // else { return false;}
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  //  Added by Sowjanya for JIRA ID:-66 on 08/19/2021
  closedfield = [];
  getClosedFields() {
    this.closedfield = [];

    this.genericLayerService.getMaintainanceConfigInfo().subscribe((maintinfo: any) => {
      if (maintinfo.features && maintinfo.features[0] && maintinfo.features[0].attributes) {
        maintinfo.features.forEach((element) => {
          if (element.attributes.ForStatusClosed === 1) {
            this.closedfield.push(element.attributes.Field);
          }
        });
      }
    });
  }

  //  Added by Sowjanya for JIRA ID:-66 on 08/19/2021
  openfield = [];
  getOpenFields() {
    try {
      this.openfield = [];

      this.genericLayerService.getMaintainanceConfigInfo().subscribe((maintinfo: any) => {
        if (maintinfo.features && maintinfo.features[0] && maintinfo.features[0].attributes) {
          maintinfo.features.forEach((element) => {
            if (element.attributes.ForStatusOpen === 1) {
              this.openfield.push(element.attributes.Field);
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  public getActivity(MaintID) {
    try {
      const assetId = MaintID.toString();
      console.log(assetId);
      this.getFilteredFeaturesByListOfValuesSubscribe = this.genericLayerService.getFilteredFeaturesByListOfValues(this.currentUser.serviceLyrInfo.activityId, "MaintID", assetId).subscribe((results: any) => {
        if (results.features && results.features[0] && results.features[0].attributes) {
          this.tasksData.forEach((task) => {
            if (
              results.features.filter((acy) => {
                if (acy.attributes.MaintID === task.attributes.MaintID) {
                  return acy;
                }
              }).length > 0
            ) {
              task.attributes.Activity = results.features.filter((acy) => {
                if (acy.attributes.MaintID === task.attributes.MaintID) {
                  return acy;
                }
              })[0].attributes.Activity;
              console.log("valid activity");
            }
          });
          this.tasksData.sort((a, b) => {
            const x = a.attributes.CreationDate;
            const y = b.attributes.CreationDate;
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
          
          this.objAssetDetailService.activetasksData = this.tasksData;
          this.ShowRollBack(this.tasksData);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  public getColorClass(recommendation) {
    try {
      switch (recommendation) {
        case 1:
          return "w-r-green";
        case 2:
          return "w-r-yellow";
        case 3:
          return "w-r-red";
      }
    } catch (error) {
      console.error(error);
    }
  }

  public getRecommendationColorClass(temp, wind, humidity, rain) {
    try {
      if (temp === 3 || wind === 3 || humidity === 3 || rain === 3) {
        return "r-red";
      } else if (temp === 2 || wind === 2 || humidity === 2 || rain === 2) {
        return "r-yellow";
      }
      return "r-green";
    } catch (error) {
      console.error(error);
    }
  }

  public getRecommendationColorClassForIcon(temp, wind, humidity, rain) {
    try {
      if (temp === 3 || wind === 3 || humidity === 3 || rain === 3) {
        return "w-icon-red";
      } else if (temp === 2 || wind === 2 || humidity === 2 || rain === 2) {
        return "w-icon-yellow";
      }
      return "w-icon-green";
    } catch (error) {
      console.error(error);
    }
  }

  public getRegions(asset) {
    // jcc 7/28/20 - helper function.  Find, and return, the element in the asset array that holds regions data.
    try {
      let regions;
      asset.forEach((el) => {
        if (el.regions) {
          regions = el.regions;
        }
      });
      return regions;
    } catch (error) {
      console.error(error);
    }
  }
  setThis() {
    return this;
  }
  public getWeatherInformation() {
    try {
      if (this.taskDate) {
        this.loading = true;
        const _this = this.setThis();
        const geom = this.webMercatorUtils.webMercatorToGeographic(this.activeAsset[3].geometry);
        let lat: any;
        let lng: any;
        if (geom.type === "point") {
          lat = geom.y;
          lng = geom.x;
        } else if (geom.type === "polyline") {
          lng = geom.paths[0][0][0];
          lat = geom.paths[0][0][1];
        }
        this.weatherService.getWeatherForcastfordate(this.taskDate, lat, lng).then((result) => {
          if (result) {
            _this.setWeatherInfo(result);
          } else {
            console.log("no weather information available");
          }
          this.loading = false;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  public loadFeatures() {
    try {
      // let assetId;
      this.tasksData = [];
      // if (this.activeAsset[0].AssetID) {
      //   assetId = this.asset[0].AssetID;
      // } else {
      //   assetId = this.asset[0].signAttribute.AssetID;
      // }
      console.log("this.eventEmitersService.assitId");
      console.log(this.eventEmitersService.assitId);
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService
        .getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", this.eventEmitersService.activeAssetFeature[0].AssetID)
        .subscribe((results: any) => {
          this.tasksData = results.features;
          // if (this.asset[5]) {
          //   this.asset[5].task = this.tasksData.length;
          // } else if (this.asset.tasks) {
          //   this.asset[0].task = this.tasksData.length;
          // }
          // tslint:disable-next-line:prefer-const
          const AssetIdsList = [];
          this.tasksData.forEach((layer) => {
            AssetIdsList.push("'" + layer.attributes.MaintID + "'");
          });
          console.log(AssetIdsList);
          this.getActivity(AssetIdsList);
        });
    } catch (error) {
      console.error(error);
    }
  }

  public ngOnDestroy() {
    if (this.emitactiveAssetChangedBySelect) {
      this.emitactiveAssetChangedBySelect.unsubscribe();
    }
  }

  //  Added by Sowjanya for JIRA ID:-66 on 08/23/2021
  pipe = new DatePipe("en-US");

  public ngOnInit() {
    this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService
      .getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", this.eventEmitersService.activeAssetFeature[0].AssetID)
      .subscribe((results: any) => {
        this.tasksData = results.features;
        const AssetIdsList = [];
        this.tasksData.forEach((layer) => {
          AssetIdsList.push("'" + layer.attributes.MaintID + "'");
        });
        this.getActivity(AssetIdsList);
      });
    this.maintanancelyr = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.activityLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    //  Added by Sowjanya for JIRA ID:-66 on 08/19/2021
    this.getClosedFields();
    this.getOpenFields();
  }

  public onAssetChange(actveAsset) {
    try {
      console.log("asset change evt");
      let assetId;
      this.tasksData = [];
      if (actveAsset[0].AssetID) {
        assetId = actveAsset[0].AssetID;
      } else {
        assetId = actveAsset[0].signAttribute.AssetID;
      }
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", assetId).subscribe((results: any) => {
        this.tasksData = results.features;
        // tslint:disable-next-line:prefer-const
        const AssetIdsList = [];
        this.tasksData.forEach((layer) => {
          AssetIdsList.push("'" + layer.attributes.MaintID + "'");
        });
        console.log(AssetIdsList);
        this.getActivity(AssetIdsList);
        actveAsset.tasks = results.features.length;
      });
    } catch (error) {
      console.error(error);
    }
  }

  public refreshGeomAndGhaphics(modifiedAsset, AssetID) {
    try {
      if (
        modifiedAsset &&
        modifiedAsset.geometry &&
        this.map.getLayer("sms-selection").graphics.filter((item) => {
          if (item.attributes.AssetID == AssetID) return item;
        }).length > 0
      ) {
        if (this.map.getLayer("sms-selection")) {
          const wm = this.webMercatorUtils.webMercatorToGeographic(modifiedAsset.geometry);
          if (wm.paths) {
            this.map.getLayer("sms-selection").graphics.filter((item) => {
              if (item.attributes.AssetID == AssetID) return item;
            })[0].geometry = new this.Point(wm.paths[0][0]);
          } else {
            this.map.getLayer("sms-selection").graphics.filter((item) => {
              if (item.attributes.AssetID == AssetID) return item;
            })[0].geometry = modifiedAsset.geometry;
          }
        }
      }
      this.map.graphics.clear();
      if (this.map.getLayer("sms-layers")) {
        this.map.getLayer("sms-layers").refresh();
      }
      if (this.map.getLayer("sms-selection")) {
        this.map.getLayer("sms-selection").refresh();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public replace() {
    try {
      const element = document.getElementById("assestDetailDragDiv");
      element.setAttribute("style", "transform: initial;");
    this.objGlobals.selectedTask = null;
    this.objGlobals.userSelectedActivity = "Replace";
    this.selectedActiveAsset = this.activeAsset;
    this.objGlobals.relatedAsset = this.relatedAsset;
    this.query.featureLayer = this.FeatureLayer;
    this.objGlobals.regions = this.getRegions(this.asset); //7/28/20 jcc
    this.showtask = true;
    this.eventEmitersService.createTaskOpened(true);
    this.objGlobals.activeAsset = this.activeAsset;
  } catch (error) {
    console.error(error);
  }
  }

  public retire() {
    try {
      const element = document.getElementById("assestDetailDragDiv");
      element.setAttribute("style", "transform: initial;");
    this.objGlobals.selectedTask = null;
    this.selectedActiveAsset = this.activeAsset;
    this.objGlobals.userSelectedActivity = "Retire";
    this.objGlobals.regions = this.getRegions(this.asset); //7/28/20 jcc
    this.showtask = true;
    this.eventEmitersService.createTaskOpened(true);
    this.objGlobals.activeAsset = this.activeAsset;
  } catch (error) {
    console.error(error);
  }
  }

  public rollBackAssets(taskData) {
    try {
    if (taskData.PendingChanges !== null) {
      if (taskData.Activity === "New") {
        // Rollback related assets ;
        const rollbackValues = JSON.parse(taskData.PendingChanges);
        const oldAsset = new this.Graphic();
        const att: any = [];
        if (rollbackValues.AssetID !== taskData.AssetID) {
          att.OBJECTID = rollbackValues.OBJECTID;
          att.Status = "Active";
          att.RetireDate = null;
          oldAsset.setAttributes(att);
        }
        const newAsset = new this.Graphic();
        const newatt: any = [];
        newatt.OBJECTID = this.activeAsset[0].OBJECTID;
        newAsset.setAttributes(newatt);
        this.oldAssectId = rollbackValues.AssetID;
        this.query.rollBacklinkRelatedAssets(this.oldAssectId, this.objGlobals.relatedAssetData).then(
          // updateing previous asset as active  ;
          this.activeAsset[2]._layer
            .applyEdits(null, [oldAsset], null)
            .then(() => {
              console.log("activated previous asset");
              this.deleteRelocateTaskAftRollback();
              this.activeAsset[2]._layer
                .applyEdits(null, null, [newAsset])
                .then(() => {
                  console.log("activated previous asset");
                  this.closeCard.emit(true);
                  this.map.getLayer("sms-layers").refresh();
                  this.map.getLayer("sms-selection").clear();
                  this.map.graphics.clear();
                  if (this.activeAsset[1].layerName == "Sign" || this.activeAsset[1].layerName == "Light") {
                    this.eventEmitersService.emitTaskAdded(true);
                  }
                  this.eventEmitersService.setEmitTaskRollback(true);
                  console.log(this.activeAsset);
                  console.log("emmited 1");
                  Swal.fire(this.translateService.instant("Task.alert.rollbacksucess"));
                })
                .catch((error) => {
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                  console.log("error = ", error);
                });
            })
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            })
        );
      } else {
        this.loading = true;
        this.RollbackEvent = taskData;
        const features = new this.Graphic();
        const att = JSON.parse(taskData.PendingChanges);  
        let assetRollbackAttributes;  
        if(att){
          if(att.before){
            assetRollbackAttributes = att.before;
          }
          else{
            assetRollbackAttributes = att; //needed for backward compatilibity for PendingChanges prior to TSDRSAM-1426 changes
          }          
        }
        this.genericLayerService
              .getRecordsByIds(
                this.activeAsset[1].lyrObj.layerId,
                "'" + this.activeAsset[0].AssetID + "'"                
              )
              .subscribe((results: any) => {
                if (
                  results.features &&
                  results.features[0] &&
                  results.features[0].attributes
                ){
                  features.setAttributes(results.features[0].attributes);
                }

                this.oldAssectId = features.attributes.AssetID;
                if (assetRollbackAttributes.hasOwnProperty('Shape')) {
                  features.setGeometry(this.geometryJsonUtils.fromJson(assetRollbackAttributes['Shape']));
                  delete assetRollbackAttributes['Shape'];
                }

                Object.keys(assetRollbackAttributes).forEach(function(key) {
                    if(features.attributes.hasOwnProperty(key)){
                      features.attributes[key] = assetRollbackAttributes[key];
                    }
                });

                this.activeAsset[1].lyrObj
                  .applyEdits(null, [features], null)
                  .then(
                    function() {
                      // Added for Jira Id 672 
                      // JIRA ID 962
                      //if(Object.keys(JSON.parse(taskData.PendingChanges)).length > 10){
                        this.eventEmitersService.activeAssetFeature[0] = features.attributes;
                    // }
                      this.DeletePendingChanges();
                      //TSDRSAM-1099 By Manikantha 
                    
                      this.refreshGeomAndGhaphics(features, taskData.AssetID);
                    
                      console.log(this.activeAsset);
                      console.log("emmited 2");
                      if(this.RollbackEvent.Activity == 'Relocate' && (this.RollbackEvent.AssetName =='Sign' || this.RollbackEvent.AssetName =='Light')){
                          this.eventEmitersService.selectParentBeforeRelocate(assetRollbackAttributes);
                      }  
                      else
                      {
                        if (this.activeAsset[1].layerName == "Sign" || this.activeAsset[1].layerName == "Light") {
                          this.eventEmitersService.emitTaskAdded(true);
                        }
                        this.eventEmitersService.setEmitTaskRollback(true);
                      }
                    }.bind(this)
                  )
                  .catch((error) => {
                    console.log("===============================================");
                    console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                    console.log("error = ", error);
                  });
              });        
      }
    }
  } catch (error) {
    console.error(error);
  }
  }

  public saveTask(attributes) {
    try {
      const featurese = [];
      attributes.saveComments = null;
      const features = new this.Graphic();
      attributes.Comment = this.taskComments;
      features.setAttributes(attributes);
      featurese.push(features);
      console.log(featurese);

      console.log(attributes);
      this.maintanancelyr
        .applyEdits(null, featurese, null)
        .then(() => {
          // this.loadFeatures(); console.log(newrow)
        })
        .catch((error) => {
          console.log("===============================================");
          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
          console.log("error = ", error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  public setWeatherInfo(forecastData) {
    try {
      this.detailForecastData = forecastData.dayForecast;
      if (forecastData.timeForecast) {
        const icondiv = document.getElementById("weatherIconDiv");
        icondiv.setAttribute("class", "");
        icondiv.classList.add("w-icon");
        icondiv.classList.add(
          this.getRecommendationColorClassForIcon(forecastData.timeForecast.tempcolor, forecastData.timeForecast.windcolor, forecastData.timeForecast.humiditycolor, forecastData.timeForecast.raincolor)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  public showDetailTable() {
    this.showTable = !this.showTable;
  }

  public showTaksTab(evt) {
    this.showtask = evt;
    this.eventEmitersService.createTaskOpened(evt);
    console.log("Calling from 4");
    this.loadFeatures();
  }

  // #endregion Public Methods (30)
}
