import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import Swal from "sweetalert2";
import { WorkBook, WorkSheet, read, utils } from "xlsx";
type AOA = any[][];
@Component({
  selector: "sms-ResourceRequrie",
  templateUrl: "./ResourceRequire.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class ResourceRequireComponent implements OnInit {
  @ViewChild("InputFile") InputFile: ElementRef;
  data: AOA = [[], []];
  values: AOA = [[], []];
  ResourceSaveButton = false;
  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {}
  ngOnInit() {
    this.MMMAdminService.getLayerRecords(this.adminGlobal.agencyDetails[0].webServiceUrl, this.adminGlobal.serviceLyrInfo.activityToolkitId).subscribe(
      (evt: any) => {
        evt.features.forEach((data) => {
          this.adminGlobal.ResourceRequirement.push(data.attributes);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  Resoucefiles(evt) {
    try {
      this.adminGlobal.ResourceRequirementFilename = evt.target.files[0].name;
      /* wire up file reader */
      const target: DataTransfer = evt.target as DataTransfer;

      if (target.files.length !== 1) {
        throw new Error("Cannot use multiple files");
      }

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: WorkBook = read(bstr, { type: "binary" });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = utils.sheet_to_json(ws, { header: 1 }) as AOA;
        this.data.forEach((evt, key) => {
          if (key !== 0) {
            if (evt.length !== 3) {
              this.ErrorFile();
            } else {
              if (typeof evt[0] !== "number") {
                this.ErrorFile();
              } else {
                this.values = this.data;
                this.ResourceSaveButton = true;
              }
            }
          }
        });
      };

      reader.readAsBinaryString(target.files[0]);
    } catch (error) {
      console.error(error);
    }
  }

  save() {
    try {
      this.ResourceSaveButton = false;
      this.adminGlobal.ResourceSaveValues = [];
      this.values.forEach((evt, key) => {
        if (key !== 0) {
          this.adminGlobal.ResourceSaveValues.push({
            AssetClass: evt[0],
            Activity: evt[1],
            ToolName: evt[2]
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  ErrorFile() {
    Swal.fire("Invalid format ");
    this.InputFile.nativeElement.value = null;
    this.ResourceSaveButton = false;
  }
}
