import { Component, EventEmitter, Input, OnInit, Output,HostListener } from "@angular/core";
import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import Swal from "sweetalert2";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { SystemReportsService } from "../system.reports.service";
import { SystemReportsStyle } from "../system.reports.style";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";
import { QueryBuilder } from "../../services/queryBuilderService.service";
import { ILayer } from "src/app/interface/layer";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface ClientList {
  clientID: number;
  clientName: string;
  primaryLanguageID: any;
  secondaryLanguageID: any;
}

@Component({
  selector: "app-maintactivitysummary",
  templateUrl: "./maintactivitysummary.component.html",
  styleUrls: ["./maintactivitysummary.component.scss"]
})
export class MaintactivitysummaryComponent implements OnInit {
  //Icons
  faCalendar = faCalendar;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public todayDate: Date = new Date();
  modalReference: NgbModalRef;
  public Primarylangvalid;
  public Secodarylangvalid;
  public currentUser: IUser;
  public inventoryLayersData: ILayer[] = [] as ILayer[];
  public selectGraphicslayer: any;
  layerList = [];
  ClientList: ClientList[] = [];
  regionsListByClient = [];
  private codedValues = [];
  JSONData: any = [];
  selectedLayer;
  LayersFromServices = [];
  textValue;
  public aliasData: any[] = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  layr = [];
  lyrfld = [];
  format = "CSV";
  regionSelected;
  columnDelimiter;
  lineDelimiter;
  spaceDelimiter;
  private coderesult;

  currentLayerName
  //TSDRSAM-1539
  lyrObj;
  lyrObjTypes;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if((['span','input']).includes(event.target['localName']) &&
       (['actSumRepCalInput1','actSumRepCalSpan1','actSumRepCalInput2','actSumRepCalSpan2']).includes(event.target['id'])){
      (<HTMLInputElement>event.target).click();
    }
    //console.log("From Maintenance Summary Report Keydown...");
  }
  //

  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userService: UserService,
    private genericService: GenericLayerService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private assetAttributesService: AssetAttributesService,
    public esritools: EsriMapComponent,
    private genericLayerService: GenericLayerService,
    public systemReportsService: SystemReportsService,
    private reportStyle: SystemReportsStyle,
    private qb: QueryBuilder
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.currentUser = this.authenticationService.currentUserValue;
  }
  @Input() FeatureLayer: any;
  @Input() RelationshipQuery: any;
  @Output() queryBuilder = new EventEmitter();
  //@Input() map: any;

  ngOnInit() {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate());
    this.regionsListByClient = [];
    this.regionsListByClient=Object.create(this.esritools.map.smsRegiondata); //JSON.parse(JSON.stringify(this.esritools.map.smsRegiondata))
    //Jira Id-51
    if(this.regionsListByClient.length > 1){
      this.regionsListByClient.push({regionName : 'All'});
    }

    this.userService.getClientListUser(this.currentUser.clientId).subscribe(
      (evt: any) => {
        this.ClientList = [];
        this.ClientList.push(evt);
        this.textValue = evt.clientName;
        this.genericService.getAllFeatures(this.currentUser.serviceLyrInfo.tableAliasId).subscribe(
          (res: any) => {
            this.CallLayers(res.features);
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (error) => {
        Swal.fire(error.error);
      }
    );
    this.genericService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
    });
    this.selectGraphicslayer = this.esritools.map.smsLayerdata.graphicLayers[0];
    this.qb.getsldata(this.esritools.Query, this.esritools.QueryTask, this.esritools.FeatureLayer).then(
      function (result) {
        this.aliasData = result.features;
        this.initQBServiceVariables();
      }.bind(this)
    );
    //for assetcoded values dropdown
    this.dropdownList = this.codedValues;
    this.selectedItems = this.dropdownList;
    console.log(this.dropdownList);
    this.dropdownSettings = {
      singleSelection: false,
      idField: "code",
      textField: "name",
      selectAllText: this.translateService.instant("Systemreports.summaryofmaint.selectall"),
      unSelectAllText: this.translateService.instant("Systemreports.summaryofmaint.deselectall"),
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.selectedLayer = { gisId: 21, gisLayerName: "Sign" };
    this.onAssetClassChange();
  }
  //for assetcoded values dropdown
  onItemSelect(item: any[]) {
    // no function body
  }
  onSelectAll(items: any) {
    // no function body
  }
  onItemDeSelect(item: any[]) {
    // no function body
  }
  onDeSelectAll(items: any) {
    // no function body
  }

  exportPDF(): void {
    try {
      this.coderesult = "";
      this.columnDelimiter = ",";
      this.lineDelimiter = "\n";
      this.spaceDelimiter = "\t";

      if (this.regionSelected === undefined) {
        Swal.fire(this.translateService.instant("Systemreports.alertsummaryofmaint.regionnotselected"));
      }

      let ctr = 0;
      if (this.selectedItems.length === this.dropdownList.length) {
        this.coderesult = "All";
      } else {
        this.selectedItems.forEach((element) => {
          if (element) {
            this.coderesult = this.coderesult + "'" + element.code + "'";
            ctr++;
            if (ctr < this.selectedItems.length) {
              this.coderesult = this.coderesult + this.columnDelimiter;
            }
          }
        });
      }

      if (this.startDate > new Date()){
        Swal.fire(this.translateService.instant("Systemreports.alertsummaryofmaint.startdatevalidate"));
      }
      if (this.coderesult.length > 0) {
        //Jira Id-51
        let regionQuery;
        if (this.regionSelected.regionName == "All") {
          this.esritools.map.smsRegiondata.forEach((region) => {

            if (regionQuery == "" || !regionQuery) {
              regionQuery = " Region" + region.regionLevel.toLocaleString() + " = '" + region.regionName + "'";
            }
            else {
              regionQuery = regionQuery + " OR Region" + region.regionLevel.toLocaleString() + " = '" + region.regionName + "'";
            }
          });
          regionQuery = "(" + regionQuery + ")";
        }
        else {
          regionQuery = " Region" + this.regionSelected.regionLevel.toLocaleString() + " = '" + this.regionSelected.regionName + "'";
        }

        const Obj1 =
        {

          ReportName: "Maintenance Activity Summary",
          RegionQuery: regionQuery,

          AssetClass: this.currentLayerName,

          AssetCodes: this.coderesult,
          FromDate: this.startDate.toLocaleString(),
          ToDate: this.endDate.toLocaleString()
        };

        this.systemReportsService.GetSummaryReport(Obj1).subscribe(
          (res) => {
            this.JSONToPDFConvertor(res, true);
          },
          (err) => {
            Swal.fire("Please contact administrator. API is throwing an error");
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];
      let JSONData1 = [];

      if (res.tblSummaryReport.length > 0 && res.tblSummaryReport1.length > 0) {
        JSONData = res.tblSummaryReport;
        JSONData1 = res.tblSummaryReport1;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const val = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData; //tblSummaryReport
      const val2 = typeof JSONData1 !== "object" ? JSON.parse(JSONData1) : JSONData1; //tblSummaryReport1
      let tablerow = [];
      let tablerow1 = [];
      const tableheader = [];
      const tableheader1 = [];
      const tablebody = [];
      const tablebody1 = [];
      let arrData = [];
      let arrData1 = [];
      let csv;
      let result = "";
      let result1 = "";

      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      if (res.tblSummaryReport.length > 0 && res.tblSummaryReport1.length > 0 && this.format === "CSV") {
        if (res.tblSummaryReport) {
          // let ctr = 0
          const keys = Object.keys(res.tblSummaryReport[0]);
          //console.log(Object);
          result += keys.join(this.columnDelimiter + " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); //TSDRSAM-1539
          result += this.lineDelimiter;
          const selfeatures = res.tblSummaryReport;
          selfeatures.forEach((element) => {
            if (element) {
              keys.forEach(
                function (key, i) {
                  result += element[key];
                  if (this.columnDelimiter) {
                    result += this.columnDelimiter;
                  }
                }.bind(this)
              );
              // ctr++;
              result += this.lineDelimiter;
            }
          });
        }

        if (res.tblSummaryReport1) {
          // let ctr = 0
          const keys = Object.keys(res.tblSummaryReport1[0])
          result1 += keys.join(this.columnDelimiter + " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); //TSDRSAM-1539
          result1 += this.lineDelimiter;
          const selfeatures = res.tblSummaryReport1;
          selfeatures.forEach((element) => {
            if (element) {
              keys.forEach(
                function (key) {
                  result1 += element[key];
                  if (this.columnDelimiter) {
                    result1 += this.columnDelimiter;
                  }
                }.bind(this)
              );
              // ctr++;
              result1 += this.lineDelimiter;
            }
          });
        }
        result = this.translateService.instant("Systemreports.headersummaryofmaint.maintainanceactivitysummaryreport") + this.lineDelimiter  + this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofmaint.regionname") + (":") + this.columnDelimiter + this.regionSelected.regionName + this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofmaint.assetclass") + (":") + this.columnDelimiter + this.selectedLayer.gisLayerName + this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofmaint.assetcodes") + (":") + this.columnDelimiter + this.coderesult + this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofmaint.fromdate") + (":") + this.startDate.toLocaleString().split(",")[0] + this.columnDelimiter + this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofmaint.todate") + (":") + this.endDate.toLocaleString().split(",")[0] + this.columnDelimiter + this.lineDelimiter +
          result + this.lineDelimiter + this.lineDelimiter + this.lineDelimiter + result1;


        csv = result;
        if (!csv.match(/^data:text\/csv/i)) {
          csv = "data:text/csv;charset=utf-8," + csv;
        }
        const encodedUri = encodeURI(csv);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const filename = this.translateService.instant("Systemreports.headersummaryofmaint.maintainanceactivitysummaryreport") + " of " + this.selectedLayer.gisLayerName + ".csv";
        link.setAttribute("download", filename);
        link.click();
      }

      if (ShowLabel) {
        tableheader.push(
          { text: this.translateService.instant("Systemreports.headersummaryofmaint.activity"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headersummaryofmaint.quantity"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headersummaryofmaint.percentageoftotal"), style: "tableHeader" }
        );
        tablebody.push(tableheader);

        arrData = [];
        val.forEach((element) => {
          if (element) arrData.push(element);
        });
        const distinct = (value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        };

        arrData = arrData.filter(distinct);

        const IndexVal = ["activity", "quantity", "% of Total"];
        // let t1 = 0;
        // const t2 = 0;

        for (let i = 0; i < arrData.length; i++) {
          tablerow = [];
          IndexVal.forEach((index) => {
            switch (index) {
              case "activity":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
              case "quantity":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
              case "% of Total":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
            }
          });
          tablebody.push(tablerow);
          console.log(tablerow);
        }
      }
      //table 2
      tableheader1.push(
        { text: this.translateService.instant("Systemreports.headersummaryofmaint.reason"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headersummaryofmaint.quantity"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headersummaryofmaint.percentageoftotal"), style: "tableHeader" }
      );
      tablebody1.push(tableheader1);

      arrData1 = [];
      val2.forEach((element) => {
        if (element) arrData1.push(element);
      });
      const distinct1 = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      arrData1 = arrData1.filter(distinct1);

      const IndexVal1 = ["reason", "quantity", "% of Total"];

      // let t3 = 0;
      // let t4 = 0;
      for (let i = 0; i < arrData1.length; i++) {
        tablerow1 = [];

        IndexVal1.forEach((index) => {
          switch (index) {
            case "reason":
              tablerow1.push({ text: arrData1[i][index], style: "tableContent" });
              break;
            case "quantity":
              tablerow1.push({ text: arrData1[i][index], style: "tableContent" });
              break;
            case "% of Total":
              tablerow1.push({ text: arrData1[i][index], style: "tableContent" });
              break;
          }
        });

        tablebody1.push(tablerow1);
      }
      if (res.tblSummaryReport.length && res.tblSummaryReport1.length && this.format === "PDF") {
        const dd = {
          pageSize: "A4",
          pageOrientation: "portrait",
          footer: currentFooter,
          content: [
            {
              text: this.translateService.instant("Systemreports.headersummaryofmaint.maintainanceactivitysummary"),
              style: "header"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: this.translateService.instant("Systemreports.summaryofmaint.regionname") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.regionSelected.regionName
                    },
                    {
                      text: this.lineDelimiter
                    },
                    {
                      text: this.translateService.instant("Systemreports.summaryofmaint.assetclass") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.selectedLayer.gisLayerName
                    },
                    {
                      text: this.lineDelimiter
                    },
                    {
                      text: this.translateService.instant("Systemreports.summaryofmaint.assetcodes") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.coderesult
                    },

                    {
                      text: this.lineDelimiter
                    },
                    {
                      text: this.translateService.instant("Systemreports.summaryofmaint.fromdate") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.startDate.toLocaleString().split(",")[0]
                    },
                    {
                      text: "\t"
                    },
                    {
                      text: this.translateService.instant("Systemreports.summaryofmaint.todate") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.endDate.toLocaleString().split(",")[0]
                    }
                  ]
                }
              ],
              style: "subheader"
            },
            {
              style: "tableExample",
              alignment: "justify",
              table: {
                widths: [85, 85, 85],
                heights: 20,
                headerRows: 1,
                body: tablebody
              },
              pageOrientation: "landscape",
              layout: currentLayoutStyle
            },
            {
              style: "tableExample",
              alignment: "justify",
              table: {
                widths: [85, 85, 85],
                heights: 20,
                headerRows: 1,
                body: tablebody1
              },
              pageOrientation: "landscape",
              layout: currentLayoutStyle
            }
          ],
          styles: currentReportStyle
        };
        console.log(dd);
        pdfMake.createPdf(dd).open();
      }
    } catch (error) {
      console.error(error);
    }
  }
  exportFormat() {
    this.exportPDF();
  }

  layers = [];
  CallLayers(res) {
    try {
      this.genericService.getLayerInforBasedOnService().subscribe(
        (results: any) => {
          results.layers.forEach((data: any) => {
            if (data.name === "Inventory") {
              data.subLayerIds.forEach((val: any) => {
                this.layers.push({ gisId: val, gisLayerName: results.layers[val].name });
              });
            }
          });
          results.tables.forEach((data) => {
            if (data.name === "Sign") {
              this.layers.push({ gisId: data.id, gisLayerName: data.name });
            } else if (data.name === "Light") {
              this.layers.push({ gisId: data.id, gisLayerName: data.name });
            }
          });
          this.layers.forEach((val: any) => {
            res.forEach((data: any) => {
              if (data.attributes.Name === val.gisLayerName) {
                if (data.attributes.IsAuthorized === "T") {
                  if (this.Primarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias });
                  } else if (this.Secodarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias_SL });
                  }
                }
              }
            });
          });
          this.callLayersByClient();
        },
        (error) => {
          Swal.fire(error.error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  res = [];
  callLayersByClient() {
    try {
      this.layerList = this.LayersFromServices.sort((a, b) => b.gisId - a.gisId);
      this.selectedLayer = this.layerList[0];
      console.log(this.layerList);
    } catch (error) {
      console.error(error);
    }
  }
  initQBServiceVariables() {
    try {
      this.qb.map = this.esritools.map;
      this.qb.Query = this.esritools.Query;
      (this.qb.QueryTask = this.esritools.QueryTask), (this.qb.RelationshipQuery = this.RelationshipQuery);
      (this.qb.inventoryLayersData = this.inventoryLayersData), (this.qb.FeatureLayer = this.FeatureLayer);
      this.qb.aliasData = this.aliasData;
    } catch (error) {
      console.error(error);
    }
  }

  onAssetClassChange() {
    try {
      this.codedValues = [];
      this.dropdownList = [];
      this.selectedItems = [];


      //Jira Id-51/1539 refresh issue
    if(this.currentUser)
    {
       this.lyrObj = new this.esritools.FeatureLayer(
        this.currentUser.webServiceURL +
        environment.featureServer +
        this.selectedLayer.gisId,
        { outFields: ["*"] }
      );
      this.lyrObj.on( "Load",
      function (evt) {
        console.log(evt);
        this.currentLayerName = this.lyrObj._name;
      }.bind(this));

    }


      const fields = "AssetCode";
      const query = new this.esritools.Query();
      query.where = "1=1";
      query.outFields = [fields];
      query.returnGeometry = false;
      query.returnDistinctValues = true;
      const queryTask = new this.esritools.QueryTask(this.lyrObj.url);

      queryTask.execute(query).then((res) => {
         if (this.lyrObj._fields) {
        this.lyrfld = this.lyrObj._fields.filter((fld) => fld.name === fields);
        }

        if (this.lyrfld && this.lyrfld[0] && this.lyrfld[0].domain) {
          console.log(this.lyrfld[0].domain);
          this.lyrfld[0].domain.codedValues.forEach((codeValue) => {
            this.codedValues.push(codeValue);
          });
        }
        else {
          if (fields === "AssetCode" && this.lyrObj.types) {
            this.lyrObj.types.forEach((val) => {
              val.domains.AssetCode.codedValues.forEach((code) => {
                const matched = res.features.filter((f) => f.attributes[fields] === code.code);
                if (matched.length === 1) {
                  this.codedValues.push({ name: code.name, code: code.code });
                }
              });
            });
            console.log(this.codedValues);
          }
        }
        this.dropdownList = this.codedValues;
        this.selectedItems = this.dropdownList;
      });
    } catch (error) {
      console.error(error);
    }
  }
  regionListSelected(event) {
    this.regionSelected = event;

     //Fix for 51,1539
     this.onAssetClassChange();

  }
}
