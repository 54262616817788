import { Injectable, Output, EventEmitter } from "@angular/core";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { Observable } from "rxjs";
import { isNumeric } from "rxjs/internal-compatibility";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root"
})
export class GenericMapMethodsService {
  public childLayerObj;

  public map;
  public Draw;
  private Measure;
  public Query;
  public FeatureLayer;
  public GraphicsLayer;
  private ArcGISDynamicMapServiceLayer;
  private BasemapGallery;
  private LocateButton;
  public Extent;
  private OverviewMap;
  private SearchWidget;
  private InfoTemplate;
  private Color;
  private SimpleLineSymbol;
  private SimpleMarkerSymbol;
  private SimpleFillSymbol;
  private TextSymbol;
  private Font;
  private Legend;
  public Graphic;
  private Circle;
  private Units;
  public Point;
  private Locator;
  private ColorPicker;
  private webMercatorUtils;
  private PictureMarkerSymbol;
  private PopupTemplate;
  private Popup;
  private domConstruct;
  private registry;
  private VectorTileLayer;
  private ScreenPoint;
  private PopupExtended;
  private RelationshipQuery;
  private AttributeInspector;
  private FeatureTable;
  private Edit;
  private scaleUtils;
  private request;
  private QueryTask;
  private Polyline;
  private Polygon;
  public all;
  private SimpleRenderer;
  private TimeInfo;
  private TimeSlider;
  private TimeExtent;
  private Geoprocessor;
  private geometryEngine;
  private mathUtils;
  private event;
  private geometryJsonUtils;
  public graphicsUtils;
  // Added By Madhuri JIRA ID :- 62
  public queryBuilderZoomExtent = false;
  public esriBundle;
  // userdefined data ;
  selectedOpentasksJSON;
  selectionGraphicsLyr;
  selectedFeaturesOnMap;
  @Output() public emitSelectedGraphicsOnMap: EventEmitter<any> = new EventEmitter();
  clickEventOnGraphics;
  public relocateChild = false;
  public relocateChildAsset;
  public currentUser;
  private ftrLyrTblMaintenance;
  private ftrLyrActivity;
  private selectedLayer;
  private rollbackData;
  //TSDRSAM-975By Manikantha
  public linearAssetSelectionColor;
  public linearAssetSelectionWidth = 4;
  public linearAssetRelocateWidth = 6;
  public linearAssetEditColor;

  // Added by Manikantha for JIRA ID:-34
  ftrLyrSign;
  signTypes;
  ftrLyrLight;
  lightTypes; //1546

  constructor(private genericService: GenericLayerService, private translateService: TranslateService) {
    this.childLayerObj = [];
  }
  setEsriModules(
    Map: any,
    Draw: any,
    Measurement: any,
    Query: any,
    FeatureLayer: any,
    GraphicsLayer: any,
    ArcGISDynamicMapServiceLayer: any,
    BasemapGallery: any,
    LocateButton: any,
    Extent: any,
    OverviewMap: any,
    Search: any,
    InfoTemplate: any,
    Color: any,
    SimpleLineSymbol: any,
    SimpleMarkerSymbol: any,
    SimpleFillSymbol: any,
    TextSymbol: any,
    Font: any,
    Legend: any,
    Graphic: any,
    Circle: any,
    Units: any,
    Point: any,
    Locator: any,
    ColorPicker: any,
    webMercatorUtils: any,
    PictureMarkerSymbol: any,
    PopupTemplate: any,
    Popup: any,
    domConstruct: any,
    registry: any,
    VectorTileLayer: any,
    ScreenPoint: any,
    PopupExtended: any,
    RelationshipQuery: any,
    AttributeInspector: any,
    FeatureTable: any,
    Edit: any,
    scaleUtils: any,
    request: any,
    QueryTask: any,
    Polyline: any,
    Polygon: any,
    all: any,
    SimpleRenderer: any,
    TimeInfo: any,
    TimeSlider: any,
    TimeExtent: any,
    Geoprocessor: any,
    geometryEngine: any,
    mathUtils: any,
    event: any,
    geometryJsonUtils,
    graphicsUtils: any,
    esriBundle: any
  ): void {
    this.map = Map;
    this.Draw = Draw;
    this.Measure = Measurement;
    this.Query = Query;
    this.FeatureLayer = FeatureLayer;
    this.GraphicsLayer = GraphicsLayer;
    this.ArcGISDynamicMapServiceLayer = ArcGISDynamicMapServiceLayer;
    this.BasemapGallery = BasemapGallery;
    this.LocateButton = LocateButton; // Locate
    this.Extent = Extent; // setExtent
    this.OverviewMap = OverviewMap; // InsetMap
    this.SearchWidget = Search; // Search Widget
    this.InfoTemplate = InfoTemplate; // Info template
    this.Color = Color;
    this.SimpleLineSymbol = SimpleLineSymbol;
    this.SimpleMarkerSymbol = SimpleMarkerSymbol;
    this.SimpleFillSymbol = SimpleFillSymbol;
    this.TextSymbol = TextSymbol;
    this.Font = Font;
    this.Legend = Legend;
    this.Graphic = Graphic;
    this.Circle = Circle;
    this.Units = Units;
    this.Point = Point;
    this.Locator = Locator;
    this.ColorPicker = ColorPicker;
    this.webMercatorUtils = webMercatorUtils;
    this.PictureMarkerSymbol = PictureMarkerSymbol;
    this.PopupTemplate = PopupTemplate;
    this.Popup = Popup;
    this.domConstruct = domConstruct;
    this.registry = registry;
    this.VectorTileLayer = VectorTileLayer;
    this.ScreenPoint = ScreenPoint;
    this.PopupExtended = PopupExtended;
    this.RelationshipQuery = RelationshipQuery;
    this.AttributeInspector = AttributeInspector;
    this.FeatureTable = FeatureTable;
    this.Edit = Edit;
    this.scaleUtils = scaleUtils;
    this.request = request;
    this.QueryTask = QueryTask;
    this.Polyline = Polyline;
    this.Polygon = Polygon;
    this.all = all;
    this.SimpleRenderer = SimpleRenderer;
    this.TimeInfo = TimeInfo;
    this.TimeSlider = TimeSlider;
    this.TimeExtent = TimeExtent;
    this.Geoprocessor = Geoprocessor;
    this.geometryEngine = geometryEngine;
    this.mathUtils = mathUtils;
    this.event = event;
    this.geometryJsonUtils = geometryJsonUtils;
    this.graphicsUtils = graphicsUtils;
    this.esriBundle = esriBundle;
    // this.assessmentlyr = new this.FeatureLayer(
    //   this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.assessmentId,
    //   { outFields: ["*"] }
    // );
    this.linearAssetSelectionColor = new this.Color([0, 245, 255, 1]);
    this.linearAssetEditColor = new this.Color([46, 46, 253, 255]);

    // Added by Manikantha for JIRA ID:-34
    if (this.currentUser && this.currentUser.serviceLyrInfo && this.currentUser.serviceLyrInfo.signId) {
      this.ftrLyrSign = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId, { outFields: ["*"] });
      this.ftrLyrSign.on(
        "Load",
        function (evt) {
          console.log(evt);
          if (this.ftrLyrSign.types) {
            this.signTypes = this.ftrLyrSign.types;
          }
        }.bind(this)
      );
    }
    //JIRA ID:-34-1546
    if (this.currentUser && this.currentUser.serviceLyrInfo && this.currentUser.serviceLyrInfo.lightId) {
      this.ftrLyrLight = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId, { outFields: ["*"] });
      this.ftrLyrLight.on(
        "Load",
        function (evt) {
          console.log(evt);
          if (this.ftrLyrLight.types) {
            this.lightTypes = this.ftrLyrLight.types;
          }
        }.bind(this)
      );
    }
  }
  isVisualizationLayer(layerObject) {
    if (layerObject.lyrObj != null && layerObject.lyrObj.url.includes(environment.apiBaseUrl)) {
      return false;
    }
    return true;
  }
  getOpenTasks(res): void {
    if (this.map.getLayer("sms-selection")) {
      this.selectionGraphicsLyr = this.map.getLayer("sms-selection");
    } else {
      this.selectionGraphicsLyr = new this.GraphicsLayer({ id: "sms-selection" });
      this.map.addLayer(this.selectionGraphicsLyr);
    }
    this.enableMapGraphicClick(res);
    this.selectedOpentasksJSON = {};
    this.selectionGraphicsLyr.clear();
    const object = {};
    if (res[0].Data) {
      for (let ild = 0; ild < res.length; ild++) {
        const selectedAssetslist = Array.prototype.map
          .call(res[ild].Data, function (item) {
            return item.attributes.AssetID;
          })
          .join(",");
        const layerName = res[ild].LayerName.layerName;
        object[layerName] = selectedAssetslist.split(",");
      }
    } else if (res[0].attributes) {
      const selectedAssetslist = Array.prototype.map
        .call(res, function (item) {
          return item.attributes.AssetID;
        })
        .join(",");
      const layerName = res[0].layerObj.name.toString();

      object[layerName] = selectedAssetslist.split(",");
    }
    this.genericService.getOpentasks(object).subscribe((opentasksRes: any) => {
      this.selectedOpentasksJSON = JSON.parse(opentasksRes);

      if (res[0].Data) {
        for (let ild = 0; ild < res.length; ild++) {
          // validate metadata of Layer Obj on res loop
          if (res[ild].Data[0].geometry) {
            //Added for visualization layers
            res[ild].Data.forEach((element) => {
              // check to make sure asset is from visualization layer before placing pin, otherwise we get duplicate pins (double bubble)
              if (this.isVisualizationLayer(res[ild].LayerName)) {
                const imgPicSym = new this.PictureMarkerSymbol("assets/images/marker_blue.png", 20, 20);
                let graphic = new this.Graphic(element.geometry, imgPicSym);
                graphic.attributes = [];
                graphic.attributes.isActiveAsset = false;
                this.selectionGraphicsLyr.add(graphic);
              }
            });
          }
          res[ild].Data.forEach((element) => {
            //let tasks
            let geometry;

            if (element.attributes.AssetID && this.selectedOpentasksJSON.OpenTaskByAsset[0][element._layer.name]?.length > 0) {
              element.attributes.opentasksobj = this.selectedOpentasksJSON.OpenTaskByAsset[0][element._layer.name].filter((item) => item.AssetId == element.attributes.AssetID);
              if (element.attributes.opentasksobj[0] != undefined) {
                //935
                element.attributes.tasks = element.attributes.opentasksobj[0].OpenTaskCount;
              }
              let imageURL;
              const width = 20;
              const height = 20;

              if (element._layer.geometryType !== "esriGeometryPoint") {
                this.highlightLinearAssets(element);
                const wm = this.webMercatorUtils.webMercatorToGeographic(element.geometry);
                //console.log(this.getPointAlongLine(element.geometry,50,0))
                if (wm.paths && wm.paths[0] && wm.paths[0][0]) {
                  geometry = this.getPolylineMidPoint(element.geometry);
                } else {
                  geometry = null;
                }
              }

              if (element._layer.geometryType === "esriGeometryPoint") {
                geometry = element.geometry;
              }
              //935
              if (element.attributes.opentasksobj[0] != undefined) {
                if (element.attributes.opentasksobj[0].OpenTasks) {
                  imageURL = "assets/images/marker_orange.png";
                } else {
                  imageURL = "assets/images/marker_blue.png";
                }
              }

              const picSym = new this.PictureMarkerSymbol(imageURL, width, height);
              picSym.setOffset(0, 5);
              const graphicB = new this.Graphic({
                // graphic with point geometry
                attributes: element.attributes,
                geometry // set geometry here
              });
              graphicB.setSymbol(picSym);
              this.selectionGraphicsLyr.add(graphicB);
            }
          });
          if (ild == res.length - 1 && this.queryBuilderZoomExtent) {
            this.afterQuerySetZoomLevel();
          }
        }
      } else if (res[0].attributes) {
        res.forEach((element) => {
          let geometry;

          if (element.attributes.AssetID && this.selectedOpentasksJSON.OpenTaskByAsset[0][element._layer.name].length > 0) {
            element.attributes.opentasksobj = this.selectedOpentasksJSON.OpenTaskByAsset[0][element._layer.name].filter((item) => item.AssetId == element.attributes.AssetID);
            element.attributes.tasks = element.attributes.opentasksobj[0].OpenTaskCount;
            let imageURL;
            const width = 20;
            const height = 20;

            if (element._layer.geometryType !== "esriGeometryPoint") {
              this.highlightLinearAssets(element);
              const wm = this.webMercatorUtils.webMercatorToGeographic(element.geometry);

              if (wm.paths && wm.paths[0] && wm.paths[0][0]) geometry = new this.Point(wm.paths[0][0]);
              else {
                geometry = null;
              }
            }
            //933
            if (element._layer.geometryType === "esriGeometryPoint") {
              geometry = element.geometry;
            }
            if (element.attributes.opentasksobj[0] != undefined) {
              if (element.attributes.opentasksobj[0].OpenTasks) {
                imageURL = "assets/images/marker_orange.png";
              } else {
                imageURL = "assets/images/marker_blue.png";
              }
            }

            const picSym = new this.PictureMarkerSymbol(imageURL, width, height);
            picSym.setOffset(0, 5);
            const graphicB = new this.Graphic({
              // graphic with point geometry
              attributes: element.attributes,
              geometry // set geometry here
            });
            graphicB.setSymbol(picSym);
            this.selectionGraphicsLyr.add(graphicB);
          }
        });
        if (this.queryBuilderZoomExtent) {
          this.afterQuerySetZoomLevel();
        }
      }
    });
  }

  selectActiveAssetOnMap(asset, activeAssetFeature): void {
    if (asset) {
      const id = asset.attributes.OBJECTID;

      // setup a query by specifying objectIds
      const query = {
        objectIds: [parseInt(id)],
        outFields: ["*"],
        returnGeometry: true
      };
      let Layer = asset._layer;
      if (!Layer) {
        //skip for visualization layers
        return;
      }
      if (!Layer.queryFeatures && activeAssetFeature && activeAssetFeature[1] && activeAssetFeature[1].lyrObj) {
        Layer = activeAssetFeature[1].lyrObj;
      }

      if (!asset.attributes.opentasksobj) {
        const object = {};
        object[asset._layer._name] = [asset.attributes.AssetID];
        this.genericService.getOpentasks(object).subscribe(
          function (opentasksRes: any) {
            console.log(JSON.parse(opentasksRes));
            this.selectedOpentasksJSON = JSON.parse(opentasksRes);
            if (this.selectedOpentasksJSON.OpenTaskByAsset[0][asset._layer.name]) {
              //1.6 additional asset fix added by Harshal k
              asset.attributes.opentasksobj = this.selectedOpentasksJSON.OpenTaskByAsset[0][asset._layer.name].filter((item) => item.AssetId == asset.attributes.AssetID);
              asset.attributes.tasks = asset.attributes.opentasksobj[0].OpenTaskCount;
            }
            if (Layer.queryFeatures) {
              let image_url = "assets/images/marker_green.png";
              if (asset.attributes.opentasksobj && asset.attributes.opentasksobj[0].OpenTasks) {
                image_url = "assets/images/OpenSelectedMarker.png";
              } else {
                image_url = "assets/images/marker_green.png";
              }
              const width = 22;
              const height = 36;
              const picSym = new this.PictureMarkerSymbol(image_url, width, height);
              picSym.setOffset(0, 5);
              let geometry;
              Layer.queryFeatures(query)
                .then((results) => {
                  const graphics = results.features;

                  this.map.graphics.clear();
                  if (Layer.geometryType !== "esriGeometryPoint") {
                    // const wm = this.webMercatorUtils.webMercatorToGeographic(graphics[0].geometry);
                    let lineSymbol;
                    if (asset._layer.name == "Barrier" || activeAssetFeature[1].layerName == "Barrier") {
                      lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor, type: "simplelinesymbol" });
                      const graphic = new this.Graphic(asset.geometry, lineSymbol);
                      graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
                      graphic.attributes = asset.attributes;
                      graphic.attributes.isActiveAsset = true; //Added for visualization layers
                      this.map.graphics.add(graphic);
                    } else if (asset._layer.name == "PavementMarking" || activeAssetFeature[1].layerName == "Barrier") {
                      lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor, type: "simplelinesymbol" });
                      const lineSymbolShortDot = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_DASH, this.linearAssetEditColor, this.linearAssetSelectionWidth);
                      lineSymbolShortDot.setWidth(this.linearAssetSelectionWidth);
                      const newGraphic = new this.Graphic(asset.geometry, lineSymbolShortDot);
                      newGraphic.attributes = asset.attributes;
                      newGraphic.graphictype = "dotline";
                      newGraphic.attributes.isActiveAsset = true; //Added for visualization layers
                      this.map.graphics.add(newGraphic);
                      //this.map.graphics.refresh();
                    } else if (asset._layer.name == "Guardrail" || activeAssetFeature[1].layerName == "Barrier") {
                      lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor });
                      const graphic = new this.Graphic(asset.geometry, lineSymbol);
                      graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
                      graphic.attributes = asset.attributes;
                      graphic.attributes.isActiveAsset = true; //Added for visualization layers
                      this.map.graphics.add(graphic);
                    }
                    // geometry = new this.Point(wm.paths[0][0]);
                    geometry = this.getPolylineMidPoint(graphics[0].geometry);
                  } else if (Layer.geometryType === "esriGeometryPoint") {
                    geometry = graphics[0].geometry;
                    const graphic = new this.Graphic(geometry, picSym);
                    graphic.attributes = asset.attributes;
                    graphic.attributes.isActiveAsset = true; //Added for visualization layers
                    this.map.graphics.add(graphic);
                  }
                  const graphic = new this.Graphic(geometry, picSym);
                  graphic.attributes = asset.attributes;
                  this.map.graphics.add(graphic);
                })
                .catch(console.error());
            }
          }.bind(this)
        );
      } else {
        if (Layer.queryFeatures) {
          let image_url = "assets/images/marker_green.png";
          if (asset.attributes.opentasksobj && asset.attributes.opentasksobj[0].OpenTasks) {
            image_url = "assets/images/OpenSelectedMarker.png";
          } else {
            image_url = "assets/images/marker_green.png";
          }
          const width = 22;
          const height = 36;
          const picSym = new this.PictureMarkerSymbol(image_url, width, height);
          picSym.setOffset(0, 5);
          let geometry;
          Layer.queryFeatures(query)
            .then((results) => {
              const graphics = results.features;

              this.map.graphics.clear();
              if (Layer.geometryType !== "esriGeometryPoint") {
                // const wm = this.webMercatorUtils.webMercatorToGeographic(graphics[0].geometry);
                let lineSymbol;
                if (asset._layer.name == "Barrier" || activeAssetFeature[1].layerName == "Barrier") {
                  lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor, type: "simplelinesymbol" });
                  const graphic = new this.Graphic(asset.geometry, lineSymbol);
                  graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
                  graphic.attributes = asset.attributes;
                  this.map.graphics.add(graphic);
                } else if (asset._layer.name == "PavementMarking" || activeAssetFeature[1].layerName == "Barrier") {
                  lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor, type: "simplelinesymbol" });
                  const lineSymbolShortDot = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_DASH, this.linearAssetEditColor, this.linearAssetSelectionWidth);
                  lineSymbolShortDot.setWidth(this.linearAssetSelectionWidth);
                  const newGraphic = new this.Graphic(asset.geometry, lineSymbolShortDot);
                  newGraphic.attributes = asset.attributes;
                  newGraphic.graphictype = "dotline";
                  this.map.graphics.add(newGraphic);
                  //this.map.graphics.refresh();
                } else if (asset._layer.name == "Guardrail" || activeAssetFeature[1].layerName == "Barrier") {
                  lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetEditColor });
                  const graphic = new this.Graphic(asset.geometry, lineSymbol);
                  graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
                  graphic.attributes = asset.attributes;
                  graphic.attributes.isActiveAsset = true;
                  this.map.graphics.add(graphic);
                }
                // geometry = new this.Point(wm.paths[0][0]);
                geometry = this.getPolylineMidPoint(graphics[0].geometry);
              }
              if (Layer.geometryType === "esriGeometryPoint") {
                geometry = graphics[0].geometry;
              }
              const graphic = new this.Graphic(geometry, picSym);
              graphic.attributes = asset.attributes;
              this.map.graphics.add(graphic);
            })
            .catch(console.error());
        }
      }
    }
    //  const row = event.rows[0];
  }
  enableMapGraphicClick(selectedFeaturesOnMap): void {
    this.selectedFeaturesOnMap = selectedFeaturesOnMap;
    if (this.clickEventOnGraphics) {
      this.clickEventOnGraphics.remove();
    }
    this.clickEventOnGraphics = this.selectionGraphicsLyr.on(
      "click",
      function (evt) {
        let selected;
        let index;
        let selectedLayer;

        for (let i = 0; i < this.selectedFeaturesOnMap.length; i++) {
          selected = this.selectedFeaturesOnMap[i].Data.find((d) => d.attributes.AssetID === evt.graphic.attributes.AssetID);
          index = this.selectedFeaturesOnMap[i].Data.findIndex((d) => d.attributes.AssetID === evt.graphic.attributes.AssetID);
          selectedLayer = i;

          if (selected) {
            break;
          }
        }
        // TSDRSAM-1099 Manikantha V
        if (this.relocateChild) {
          if (this.selectedFeaturesOnMap[selectedLayer].LayerName.layerName == "Support") {
            this.moveToNewParent(evt.graphic.attributes.AssetID, selectedLayer, index);
          } else {
            Swal.fire(this.translateService.instant("RelocateChild.selectSupport"));
            this.relocateChild = false;
          }
        } else {
          this.emitSelectedGraphicsOnMap.emit([selectedLayer, index]);
        }
      }.bind(this)
    );
  }

  // Added by Madhuri Gupta for JIRA ID:-62 on 08/03/2021
  afterQuerySetZoomLevel(): void {
    let extGraphics;
    let extGraphics1;
    let newExtent;
    if (this.selectionGraphicsLyr.graphics.length > 0) {
      const graphicarr = this.selectionGraphicsLyr.graphics.filter((ele) => {
        // Map Coordinate System WKID
        return ele.geometry.spatialReference.wkid == 4326;
      });
      const graphicarr1 = this.selectionGraphicsLyr.graphics.filter((ele) => {
        // Map Coordinate System WKID
        return ele.geometry.spatialReference.wkid == 102100;
      });
      if (graphicarr.length > 0) {
        extGraphics = this.webMercatorUtils.geographicToWebMercator(this.graphicsUtils.graphicsExtent(graphicarr));
      } else {
        extGraphics1 = this.graphicsUtils.graphicsExtent(graphicarr1);
      }
      if (extGraphics) {
        newExtent = extGraphics;
        if (extGraphics1) newExtent.union(extGraphics1);
      } else {
        newExtent = extGraphics1;
      }
      // For handleing point features
      if (newExtent != null) {
        if (newExtent.xmax == newExtent.xmin || newExtent.ymax == newExtent.ymin) {
          if (newExtent.xmax == newExtent.xmin) {
            newExtent.xmin = newExtent.xmin - 500;
            newExtent.xmax = newExtent.xmax + 500;
          }
          if (newExtent.ymax == newExtent.ymin) {
            newExtent.ymin = newExtent.ymin - 500;
            newExtent.ymax = newExtent.ymax + 500;
          }
          this.map.setExtent(newExtent, true);
        } else {
          this.map.setExtent(newExtent, true);
        }
      }
    }
  }

  getDetailedMaintenance(object) {
    const getMaintananceTasksObs = new Observable((observer) => {
      console.log("Observable starts");

      this.genericService.getMaintananceTasks(object).subscribe((opentasksRes: any) => {
        observer.next(JSON.parse(opentasksRes));
      });
    });
    return getMaintananceTasksObs;
  }
  // TSDRSAM-975 By Manikantha
  highlightLinearAssets(asset: any): void {
    try {
      let lineSymbol;
      if (asset._layer.name == "Barrier") {
        lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetSelectionColor, type: "simplelinesymbol" });
        const graphic = new this.Graphic(asset.geometry, lineSymbol);
        graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
        graphic.attributes = asset.attributes;
        this.selectionGraphicsLyr.add(graphic);
      } else if (asset._layer.name == "PavementMarking") {
        lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetSelectionColor, type: "simplelinesymbol" });
        const lineSymbolShortDot = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_DASH, this.linearAssetSelectionColor, this.linearAssetSelectionWidth);
        lineSymbolShortDot.setWidth(this.linearAssetSelectionWidth);
        const newGraphic = new this.Graphic(asset.geometry, lineSymbolShortDot);
        newGraphic.graphictype = "dotline";
        newGraphic.attributes = asset.attributes;
        this.selectionGraphicsLyr.add(newGraphic);
        //this.map.graphics.refresh();
      } else if (asset._layer.name == "Guardrail") {
        lineSymbol = new this.SimpleLineSymbol({ color: this.linearAssetSelectionColor });
        const graphic = new this.Graphic(asset.geometry, lineSymbol);
        graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.linearAssetSelectionWidth));
        graphic.attributes = asset.attributes;
        this.selectionGraphicsLyr.add(graphic);
      }
    } catch (error) {
      console.log(error);
    }

    // const lineSymbol = new this.SimpleLineSymbol({ color: new this.Color([0, 0, 255, 1]), type: "simplelinesymbol" });
  }
  // TSDRSAM-975 By Manikantha
  getPolylineMidPoint(polyline) {
    const path = polyline.paths[0];
    let length = 0;
    for (let i = 0; i < path.length - 1; i++) {
      const startPoint = polyline.getPoint(0, i);
      const nextPoint = polyline.getPoint(0, i + 1);
      //const d = this.geometryEngine.distance(startPoint, nextPoint, "feet");
      length += this.geometryEngine.distance(startPoint, nextPoint, "feet");
    }
    //const length = this.geometryEngine.geodesicLength(polyline, "feet"); // esri.geometry.geometryEngine
    const middleLength = length / 2;
    let currentDistance = 0;
    let beforeIndex = 0;
    let dt = 0;
    let dis = 0;
    let startPoint = polyline.getPoint(0, 0);
    for (let i = 1; i <= path.length - 1; i++) {
      const nextPoint = polyline.getPoint(0, i);
      const d = this.geometryEngine.distance(startPoint, nextPoint, "feet");
      if (currentDistance + d < middleLength) {
        currentDistance += d;
        startPoint = nextPoint;
      } else {
        dt = d + currentDistance - middleLength;
        dis = d;
        beforeIndex = i;
        break;
      }
    }
    const t = 1 - dt / dis;
    //startPoint = polyline.getPoint(0, beforeIndex);
    const endPoint = polyline.getPoint(0, beforeIndex);
    let x, y;
    if (isNumeric(t)) {
      x = (1 - t) * startPoint.x + t * endPoint.x; // (startPoint.x + endPoint.x) / 2
      y = (1 - t) * startPoint.y + t * endPoint.y; //(startPoint.y + endPoint.y) / 2;
    } else {
      x = (startPoint.x + endPoint.x) / 2;
      y = (startPoint.y + endPoint.y) / 2;
    }
    // const x = ((1 - t) * startPoint.x + t * endPoint.x);// (startPoint.x + endPoint.x) / 2
    // const y = ((1 - t) * startPoint.y + t * endPoint.y);//(startPoint.y + endPoint.y) / 2;
    // const midPoint =  (xt, yt) = (((1 - t) * x0 + t * x1), ((1 - t) * y0 + t * y1))
    const midPoint = new this.Point({ x, y, spatialReference: { wkid: polyline.spatialReference.wkid } }); // esri.geometry.Point
    return midPoint;
  }
  //  distanceBetweenPoints(x1, y1, x2, y2) {
  //   return Math.sqrt(Math.pow(x2 - x1, 2) + (Math.pow(y2 - y1, 2)));
  //  }

  //   getPointAlongLine(polyline, distance, pathIndex) {
  //   if (!pathIndex)
  //    pathIndex = 0;

  //   if (!distance)
  //    distance = 0;

  //   if ((pathIndex >= 0) && (pathIndex < polyline.paths.length)) {
  //    var path = polyline.paths[pathIndex];
  //    var x1, x2, x3, y1, y2, y3;
  //    var travelledDistance = 0;
  //    var pathDistance;
  //    var distanceDiff;
  //    var angle;

  //    if (distance === 0)
  //     return polyline.getPoint(pathIndex, 0);
  //    else if (distance > 0) {
  //     for (var i = 1; i < path.length; i++) {
  //      x1 = path[i-1][0];
  //      y1 = path[i-1][1];
  //      x2 = path[i][0];
  //      y2 = path[i][1];
  //  console.log(Math.sqrt(Math.pow(x2 - x1, 2) + (Math.pow(y2 - y1, 2))))
  //      pathDistance = this.distanceBetweenPoints(x1, y1, x2, y2);
  //      travelledDistance += pathDistance;

  //      if (travelledDistance === distance)
  //       return polyline.getPoint(pathIndex, i);
  //      else if (travelledDistance > distance) {
  //       distanceDiff = pathDistance - (travelledDistance - distance);

  //       angle = Math.atan2(y2-y1, x2-x1);

  //       x3 = distanceDiff * Math.cos(angle);
  //       y3 = distanceDiff * Math.sin(angle);

  //       return new this.Point(x1 + x3, y1 + y3, polyline.spatialReference);
  //      }
  //     }
  //    }
  //   }

  //   return null;
  //  }
  // TSDRSAM-1099 Manikantha V
  moveToNewParent(parentId, selectedLayer, index): void {
    //Swal.fire("abc");
    console.log("MOVING TO NEW PARENT");
    this.rollbackData = JSON.stringify(this.relocateChildAsset.attributes);
    Swal.fire({
      title: this.translateService.instant("RelocateChild.tittle"),
      text: this.translateService.instant("RelocateChild.text") + parentId + "?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.translateService.instant("RelocateChild.save"),
      cancelButtonText: this.translateService.instant("RelocateChild.cancel")
    }).then((result) => {
      if (result.value) {
        this.relocateChild = false;
        console.log("yes");
        const asset = new this.Graphic();
        asset.setAttributes(this.relocateChildAsset.attributes);
        asset.attributes.ParentID = parentId;

        const featureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.relocateChildAsset._layer.layerId, {
          outFields: ["*"]
        });
        featureLayer.applyEdits(null, [asset], null).then((editsResult) => {
          this.createRelocateTask(this.relocateChildAsset, selectedLayer, index);
          // this.emitSelectedGraphicsOnMap.emit([selectedLayer, index])
          console.log(editsResult);
        });
      } else {
        this.relocateChild = false;
      }
    });
  }
  // TSDRSAM-1099 Manikantha V
  createRelocateTask(asset, selectedLayer, index) {
    //   this.selectedLayer = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.layerName;
    this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    const featurese = [];
    const features = new this.Graphic();

    // this.asset = asset;
    //  const prefix = this.objAssetDetailService.getAssetIDFormat(this.selectedLayer);
    const assetId = asset.attributes.AssetID;
    // const geomString = asset.geometry.toJson();
    // if (geomString.paths) {
    //   geomString.x = geomString.paths[0][0][0];
    //   geomString.y = geomString.paths[0][0][1];
    //   delete geomString.paths;
    // }
    let region;
    if (asset.attributes.Region4) {
      region = asset.attributes.Region4;
    } else if (asset.attributes.Region3) {
      region = asset.attributes.Region3;
    } else if (asset.attributes.Region2) {
      region = asset.attributes.Region2;
    } else if (asset.attributes.region) {
      region = asset.attributes.region;
    }
    const status = "Closed";
    const priorParentId = JSON.parse(this.rollbackData).ParentID;
    // TSDRSAM-1114 By Manikantha V
    features.setAttributes({
      Status: status,
      CreatedBy: this.currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      CompletedBy: this.currentUser.username,
      CompletionDate: new Date(Date.now()).getTime(),
      Region: region,
      Reason: "Other",
      AssetID: assetId,
      AssetName: asset._layer._name,
      WorkOrderDesc: "",
      Comment: "Relocated from " + priorParentId,

      PendingChanges: JSON.stringify(this.generatePendingChangesForChildRelocate(priorParentId, asset.attributes.ParentID))
    });
    // GeomWKT: JSON.stringify(geomString),
    featurese.push(features);
    this.ftrLyrTblMaintenance
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          const featureseAssetID = [];
          const featuresA = new this.Graphic();
          featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
          featureseAssetID.push(featuresA);
          this.ftrLyrTblMaintenance
            .applyEdits(null, featureseAssetID, null)
            .then(function () {
              console.log("sucess");
            })
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            });
          // End
          const actfeaturese = [];
          const actfeatures = new this.Graphic();
          actfeatures.setAttributes({
            MaintID: "MNT_" + newrow[0].objectId,
            Asset: this.assetTypeId,
            Activity: "Relocate",
            Description: " ",
            ModifiedBy: this.currentUser.username
          });
          actfeaturese.push(actfeatures);
          this.ftrLyrActivity.applyEdits(actfeaturese, null, null).then(
            function () {
              // this.eventEmitersService.setreplaceTaskCreated(true);
              this.emitSelectedGraphicsOnMap.emit([selectedLayer, index]);
            }.bind(this)
          );
          // this.objAssetDetailService.assetChanged.emit(this.activeParentAsset);
        }.bind(this)
      )
      .catch((error) => {
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
      });
  }

  public getBuffer(point, distance, units) {
    return this.geometryEngine.geodesicBuffer(point, distance, units);
  }

  public getGMLPointGeometry(coordinates) {
    let pointCoordinates = coordinates.split(" ")[0];
    let x = parseFloat(pointCoordinates.split(",")[0]);
    let y = parseFloat(pointCoordinates.split(",")[1]);
    let geometry = new this.Point({
      x: x,
      y: y,
      spatialReference: { wkid: 3857 }
    });
    return geometry;
  }

  public setActiveVisualizationAsset(geometry) {
    const activeImageUrl = "assets/images/marker_green.png";
    //TODO: evaluate if this is the appropriate size or if it should match Managed Layer selected pin size.
    const activeImgWdth = 30;
    const activeImgHeight = 34.5;
    const activeImgPicSym = new this.PictureMarkerSymbol(activeImageUrl, activeImgWdth, activeImgHeight);
    let graphic = new this.Graphic(geometry, activeImgPicSym);
    graphic.attributes = [];
    graphic.attributes.isActiveAsset = true;

    this.map.graphics.add(graphic);
  }

  //TSDRSAM-1426
  generatePendingChangesForChildRelocate(priorParentId, toBeParentId) {
    let deltaResult = { before: { ParentID: {} }, after: { ParentID: {} } };

    deltaResult.before.ParentID = priorParentId;
    deltaResult.after.ParentID = toBeParentId;

    return deltaResult;
  }

  convertXYToLatLng(x: number, y: number): number{
    return this.webMercatorUtils.xyToLngLat(x, y);
  }

  disableDrawTool(): void{
    this.Draw.deactivate();
  }
}
