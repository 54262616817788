/* eslint-disable camelcase */
// BLF_Column camel case

import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { environment } from "src/environments/environment";
@Component({
  selector: "sms-MMMBaseLife",
  templateUrl: "./BaseLife.component.html",
  styleUrls: ["../../administrator.component.scss"]
})
export class MMMBaseLife implements OnInit {
  AssetClassList = [];
  assetClassListSelected;
  baseLifeFieldSelected;
  BaseLifeField = [];
  SubmitButton = false;
  constructor(private adminService: MMMAdminService, public globalvariable: adminGlobal) {}

  ngOnInit() {
    this.initLoad();
  }
  initLoad() {
    try {
      this.AssetClassList = [];
      this.assetClassListSelected = undefined;
      this.baseLifeFieldSelected = undefined;
      const Arrayvalues = this.globalvariable.AssetClassList;
      this.globalvariable.ShowAsset.forEach((data) => {
        const val = Arrayvalues.filter((obj) => {
          return obj.gislayername.toLowerCase() === data.Name.toLowerCase();
        });

        if (data.Name.toLowerCase() === "support") {
          this.AssetClassList.push({ gisId: this.globalvariable.serviceLyrInfo.signId, gislayername: "Sign" });
          this.AssetClassList.push({ gisId: this.globalvariable.serviceLyrInfo.lightId, gislayername: "Light" });
        }

        if (val.length !== 0) {
          this.AssetClassList.push(val[0]);
        }
      });
      this.globalvariable.ExistBaseLife = this.globalvariable.ExistBaselifeConfig;
      this.globalvariable.ExistBaselifeConfig.forEach((data) => {
        this.AssetClassList = this.AssetClassList.filter((obj) => {
          return obj.gislayername.toLowerCase() !== data.AssetClass.toLowerCase();
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  onChange() {
    try {
      this.BaseLifeField = [];
      this.adminService
        .getDomainValuesFields(environment.proxy +this.globalvariable.agencyDetails[0].webServiceUrl, this.assetClassListSelected.gisId)
        .subscribe(
          (res: any) => {
            res.fields.forEach((data) => {
              if (data.domain !== null) {
                this.BaseLifeField.push({
                  BLF_Column: data.alias,
                  BLF_Domain: data.domain.name,
                  CodedValues: data.domain.codedValues
                });
              }
            });
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (error) {
      console.error(error);
    }
  }

  BaseLifeChange() {
    this.SubmitButton = true;
  }

  OnSubmit() {
    try {
      this.globalvariable.ExistBaseLife.push({
        AssetClass: this.assetClassListSelected.gislayername,
        BLF_Column: this.baseLifeFieldSelected.BLF_Column,
        BLF_Domain: this.baseLifeFieldSelected.BLF_Domain
      });
      this.globalvariable.NewBaselifeConfig.push({
        AssetClass: this.assetClassListSelected.gislayername,
        BLF_Column: this.baseLifeFieldSelected.BLF_Column,
        BLF_Domain: this.baseLifeFieldSelected.BLF_Domain,
        CodedValues: this.baseLifeFieldSelected.CodedValues
      });
      this.SubmitButton = false;
      this.initLoad();
    } catch (error) {
      console.error(error);
    }
  }
}
