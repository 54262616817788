import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import Swal from "sweetalert2";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { SystemReportsService } from "../system.reports.service";
import { SystemReportsStyle } from "../system.reports.style";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";
import { QueryBuilder } from "../../services/queryBuilderService.service";
import { ILayer } from "src/app/interface/layer";
import { IDropdownSettings } from "ng-multiselect-dropdown";
// import { Autowired } from "ag-grid-community";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface ClientList {
  clientID: number;
  clientName: string;
  primaryLanguageID: any;
  secondaryLanguageID: any;
}

// interface RegionList {
//   userId: number;
//   regionId: number;
//   regionName: string;
//   arcGISRegionId: number;
// }

// interface ILayerUser {
//   gisLayerId: string;
//   accessPrivilege: string;
// }
@Component({
  selector: "app-current-condition-summary",
  templateUrl: "./current-condition-summary.component.html",
  styleUrls: ["./current-condition-summary.component.scss"]
})
export class CurrentConditionSummaryComponent implements OnInit {
  private generDate = new Date();
  modalReference: NgbModalRef;
  private Primarylangvalid;
  private Secodarylangvalid;
  private currentUser: IUser;
  private inventoryLayersData: ILayer[] = [] as ILayer[];
  private selectGraphicslayer: any;
  layerList = [];
  ClientList: ClientList[] = [];
  regionsListByClient = [];
  LayerListByUser = [];
  private codedValues = [];
  codevalues = [];
  JSONData: any = [];
  selectedLayer;
  LayersFromServices = [];
  textValue;
  public aliasData: any[] = [];
  selectedItems = [];
  selectedItemsReg = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  layr = [];
  lyrfld = [];
  private coderesult;
  regionSelected;
  selectedList = [];
  columnDelimiter;
  lineDelimiter;
  spaceDelimiter;
  format = "CSV";
  currentLayerName;
  //TSDRSAM-1539
  lyrObj;
  lyrObjTypes;

  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userService: UserService,
    private genericService: GenericLayerService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private assetAttributesService: AssetAttributesService,
    public esritools: EsriMapComponent,
    private genericLayerService: GenericLayerService,
    public systemReportsService: SystemReportsService,
    private reportStyle: SystemReportsStyle,
    private qb: QueryBuilder
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.currentUser = this.authenticationService.currentUserValue;
  }

  @Input() FeatureLayer: any;
  @Input() RelationshipQuery: any;
  @Output() queryBuilder = new EventEmitter();
  //@Input() map: any;

  ngOnInit() {
    this.generDate.setDate(this.generDate.getDate());
    this.regionsListByClient = [];
    this.regionsListByClient = Object.create(this.esritools.map.smsRegiondata);
    //Jira Id-52
    if (this.regionsListByClient.length > 1) {
      this.regionsListByClient.push({ regionName: "All" });
    }

    this.userService.getClientListUser(this.currentUser.clientId).subscribe(
      (evt: any) => {
        this.ClientList = [];
        this.ClientList.push(evt);
        this.textValue = evt.clientName;
        this.genericService.getAllFeatures(this.currentUser.serviceLyrInfo.tableAliasId).subscribe(
          (res: any) => {
            this.CallLayers(res.features);
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (error) => {
        Swal.fire(error.error);
      }
    );
    this.genericService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
    });
    this.selectGraphicslayer = this.esritools.map.smsLayerdata.graphicLayers[0];
    this.qb.getsldata(this.esritools.Query, this.esritools.QueryTask, this.esritools.FeatureLayer).then(
      function (result) {
        this.aliasData = result.features;
        this.initQBServiceVariables();
      }.bind(this)
    );
    this.dropdownList = this.codedValues;
    this.selectedItems = this.dropdownList;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "code",
      textField: "name",
      selectAllText: this.translateService.instant("Systemreports.summaryofcondition.selectall"),
      unSelectAllText: this.translateService.instant("Systemreports.summaryofcondition.deselectall"),
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.selectedLayer = { gisId: 21, gisLayerName: "Sign" };
    this.onAssetClassChange();
  }
  //for asset codedvalues
  onItemSelect(item: any[]) {
    // console
  }
  onSelectAll(items: any) {
    // console
  }
  onItemDeSelect(item: any[]) {
    // console
  }
  onDeSelectAll(items: any) {
    // console
  }

  exportPDF(): void {
    this.coderesult = [];
    this.columnDelimiter = ",";
    this.lineDelimiter = "\n";
    this.spaceDelimiter = "\t";

    if (this.regionSelected === undefined) {
      Swal.fire(this.translateService.instant("Systemreports.alertsummaryofcondition.regionnotselected"));
    }
    // const values = Object.values(this.selectedItems)
    let ctr = 0;
    const selCodes = this.selectedItems;

    if (this.selectedItems.length === this.dropdownList.length) {
      this.coderesult = "All";
    } else {
      selCodes.forEach((element) => {
        if (element) {
          this.coderesult = this.coderesult + "'" + element.code + "'";
          ctr++;
          if (ctr < this.selectedItems.length) {
            this.coderesult = this.coderesult + this.columnDelimiter;
          }
        }
      });
    }

    if (this.coderesult.length > 0) {
      //Jira Id-52
      let regionQuery;

      if (this.regionSelected.regionName == "All") {
        this.esritools.map.smsRegiondata.forEach((region) => {
          if (regionQuery == "" || !regionQuery) {
            regionQuery = " Region" + region.regionLevel.toLocaleString() + " = '" + region.regionName + "'";
          } else {
            regionQuery = regionQuery + " OR Region" + region.regionLevel.toLocaleString() + " = '" + region.regionName + "'";
          }
        });
        regionQuery = "(" + regionQuery + ")";
      } else {
        regionQuery = "Region" + this.regionSelected.regionLevel.toLocaleString() + " = '" + this.regionSelected.regionName + "'";
      }

      const Obj1 = {
        ReportName: "Current Condition Summary",
        RegionQuery: regionQuery,
        AssetClass: this.currentLayerName,

        AssetCodes: this.coderesult
      };

      this.systemReportsService.GetSummaryReport(Obj1).subscribe(
        (res) => {
          this.JSONToPDFConvertor(res, true);
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    }
  }
  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];
      let csv;
      let ctr = 0;
      if (res.tblSummaryReport.length > 0) {
        JSONData = res.tblSummaryReport;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const val = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData; //tblSummaryReport
      let tablerow = [];
      const tableheader = [];
      const tablebody = [];
      let arrData = [];
      // const total = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();

      if (res.tblSummaryReport.length > 0 && this.format === "CSV") {
        const keys = Object.keys(res.tblSummaryReport[0]);
        let result = "";
        let result1 = "";
        result += keys.join(this.columnDelimiter + " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()); //TSDRSAM-1539
        result += this.lineDelimiter;

        const selfeatures = res.tblSummaryReport;
        selfeatures.forEach((element) => {
          if (element) {
            keys.forEach(
              function (key) {
                result += element[key];
                if (this.columnDelimiter) {
                  result += this.columnDelimiter;
                }
              }.bind(this)
            );
            ctr++;
            result += this.lineDelimiter;
          }
        });

        result1 =
          this.translateService.instant("Systemreports.headersummaryofcondition.generdate") +
          " " +
          this.generDate.toLocaleString().split(",")[0] +
          this.lineDelimiter +
          this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofcondition.regionname") +
          ":" +
          this.regionSelected.regionName +
          this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofcondition.assetclass") +
          ":" +
          this.selectedLayer.gisLayerName +
          this.lineDelimiter +
          this.translateService.instant("Systemreports.summaryofcondition.assetcodes") +
          ":" +
          this.coderesult +
          this.lineDelimiter +
          this.lineDelimiter +
          this.lineDelimiter +
          this.lineDelimiter +
          result;
        if (ctr === res.tblSummaryReport.length) {
          csv = result1;
          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }
          const encodedUri = encodeURI(csv);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          const filename = this.translateService.instant("Systemreports.headersummaryofcondition.currentconditionsummaryreport") + " of " + this.selectedLayer.gisLayerName + ".csv";
          link.setAttribute("download", filename);
          link.click();
        }
      }

      if (ShowLabel) {
        tableheader.push(
          { text: this.translateService.instant("Systemreports.headersummaryofcondition.condition"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headersummaryofcondition.numberofassets"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headersummaryofcondition.percentageoftotal"), style: "tableHeader" }
        );
        tablebody.push(tableheader);

        arrData = [];
        val.forEach((element) => {
          if (element) arrData.push(element);
        });
        const distinct = (value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        };

        arrData = arrData.filter(distinct);

        const IndexVal = ["condition", "number of Assets", "% of Total"];
        // let t1 = 0;
        // let t2 = 0;

        for (let i = 0; i < arrData.length; i++) {
          tablerow = [];
          IndexVal.forEach((index) => {
            switch (index) {
              case "condition":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
              case "number of Assets":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
              case "% of Total":
                tablerow.push({ text: arrData[i][index], style: "tableContent" });
                break;
            }
          });
          tablebody.push(tablerow);
          console.log(tablerow);
        }
      }
      if (res.tblSummaryReport.length && this.format === "PDF") {
        const dd = {
          pageSize: "A4",
          pageOrientation: "portrait",
          footer: currentFooter,
          content: [
            {
              text: this.translateService.instant("Systemreports.headersummaryofcondition.generdate") + "\xa0" + this.generDate.toLocaleString().split(",")[0],
              style: "header"
            },
            {
              stack: [
                {
                  text: [
                    {
                      text: this.translateService.instant("Systemreports.summaryofcondition.regionname") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.regionSelected.regionName
                    },

                    {
                      text: this.lineDelimiter
                    },

                    {
                      text: this.translateService.instant("Systemreports.summaryofcondition.assetclass") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.selectedLayer.gisLayerName
                    },

                    {
                      text: this.lineDelimiter
                    },
                    {
                      text: this.translateService.instant("Systemreports.summaryofcondition.assetcodes") + ":" + "\xa0",
                      bold: true
                    },
                    {
                      text: this.coderesult
                    }
                  ]
                }
              ],
              style: "subheader"
            },

            {
              style: "tableExample",
              table: {
                body: tablebody
              },

              pageOrientation: "landscape",
              layout: currentLayoutStyle
            }
          ],
          styles: currentReportStyle
        };
        console.log(dd);
        pdfMake.createPdf(dd).open();
      }
    } catch (error) {
      console.error(error);
    }
  }
  exportFormat() {
    try {
      this.exportPDF();
      // this.exportPDF(this.format);
    } catch (error) {
      console.error(error);
    }
  }

  layers = [];
  CallLayers(res) {
    try {
      this.genericService.getLayerInforBasedOnService().subscribe(
        (results: any) => {
          results.layers.forEach((data: any) => {
            if (data.name === "Inventory") {
              data.subLayerIds.forEach((val: any) => {
                this.layers.push({ gisId: val, gisLayerName: results.layers[val].name });
              });
            }
          });

          results.tables.forEach((data) => {
            if (data.name === "Sign") {
              this.layers.push({ gisId: data.id, gisLayerName: data.name });
            } else if (data.name === "Light") {
              this.layers.push({ gisId: data.id, gisLayerName: data.name });
            }
          });
          this.layers.forEach((val: any) => {
            res.forEach((data: any) => {
              if (data.attributes.Name === val.gisLayerName) {
                if (data.attributes.IsAuthorized === "T") {
                  if (this.Primarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias });
                  } else if (this.Secodarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias_SL });
                  }
                }
              }
            });
          });
          this.callLayersByClient();
        },
        (error) => {
          Swal.fire(error.error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  callLayersByClient() {
    try {
      this.layerList = this.LayersFromServices.sort((a, b) => b.gisId - a.gisId);
      this.selectedLayer = this.layerList[0];
    } catch (error) {
      console.error(error);
    }
  }
  initQBServiceVariables() {
    try {
      this.qb.map = this.esritools.map;
      this.qb.Query = this.esritools.Query;
      (this.qb.QueryTask = this.esritools.QueryTask), (this.qb.RelationshipQuery = this.RelationshipQuery);
      (this.qb.inventoryLayersData = this.inventoryLayersData), (this.qb.FeatureLayer = this.FeatureLayer);
      this.qb.aliasData = this.aliasData;
    } catch (error) {
      console.error(error);
    }
  }

  onAssetClassChange() {
    try {
      this.codedValues = [];
      this.dropdownList = [];
      this.selectedItems = [];

      //Jira Id-52/1539 refresh issue
      if (this.currentUser) {
        this.lyrObj = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.selectedLayer.gisId, { outFields: ["*"] });
        this.lyrObj.on(
          "Load",
          function (evt) {
            console.log(evt);
            this.currentLayerName = this.lyrObj._name;
          }.bind(this)
        );
      }

      const fields = "AssetCode";
      const query = new this.esritools.Query();
      query.where = "1=1";
      query.outFields = [fields];
      query.returnGeometry = false;
      query.returnDistinctValues = true;
      const queryTask = new this.esritools.QueryTask(this.lyrObj.url);
      queryTask.execute(query).then((res) => {
        if (this.lyrObj._fields) {
          this.lyrfld = this.lyrObj._fields.filter((fld) => fld.name === fields);
        }

        if (this.lyrfld && this.lyrfld[0] && this.lyrfld[0].domain) {
          console.log(this.lyrfld[0].domain);
          this.lyrfld[0].domain.codedValues.forEach((codeValue) => {
            this.codedValues.push(codeValue);
          });
        } else {
          if (fields === "AssetCode" && this.lyrObj.types) {
            this.lyrObj.types.forEach((val) => {
              val.domains.AssetCode.codedValues.forEach((code) => {
                const matched = res.features.filter((f) => f.attributes[fields] === code.code);
                if (matched.length === 1) {
                  this.codedValues.push({ name: code.name, code: code.code });
                }
              });
            });
            console.log(this.codedValues);
          }
        }
        this.dropdownList = this.codedValues;
        this.selectedItems = this.dropdownList;
      });
    } catch (error) {
      console.error(error);
    }
  }
  regionListSelected(event) {
    this.regionSelected = event.value;
    //Fix for 52,1539
    this.onAssetClassChange();
  }
}
