/* eslint-disable camelcase */
// Disable camelcase for BLF_Column
import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import Swal from "sweetalert2";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
//Added by Sowjanya for TSDRSAM-71
import { UserService } from "src/app/services/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { IUser } from "src/app/interface/user";
import { forkJoin } from "rxjs";
//
@Component({
  selector: "sms-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["../administrator.component.scss", "./summary.component.scss"]
})
export class summaryComponent implements OnInit {
  agencyName;
  agencyWebService;
  SolutionTier;
  agencySpatialDatabase;
  PrimaryAgencyLanguage;
  SecondaryAgencyLanguage;
  AssetClass = [];
  SystemReport = [];
  tableAlias;
  BaseLifeConfig;
  BaseLifeConfigValues;
  BaseLifeModify;
  BaseLifeModifyValues;
  AcitivtyDuration;
  showApplySettingButton = false;
  loading = false; // Loading Control //
  //Added by Sowjanya for TSDRSAM-71
  GlobalClientId = null;
  GlobalUserId = null;
  currentUser: IUser;
  LayerListByUser: any[];
  LayersFromServices = [];
  constructor(
    private MMMAdminService: MMMAdminService,
    public adminGlobal: adminGlobal,
    public esriMapTool: EsriMapComponent,
    private userService: UserService,
    public esritools: EsriMapComponent,
    private authenticationService: AuthenticationService,
    private genericService: GenericLayerService
  ) {}
  //
  ngOnInit() {
    if (this.adminGlobal.agencyDetails === undefined || this.adminGlobal.agencyDetails.length < 1) {
      Swal.fire("Please add or select Agency in the Agency Configuration Screen");
    } else {
      this.showApplySettingButton = true;
      const details = this.adminGlobal.agencyDetails[0];
      this.agencyName = details.clientName;
      this.agencyWebService = details.webServiceUrl;
      this.SolutionTier = details.clientType;
      this.agencySpatialDatabase = details.clientDb;
      this.adminGlobal.PALValues.forEach((dataVal) => {
        //Fix to display languages on summary screen id to languageID
        if (dataVal.languageID === details.primaryLanguageID) {
          this.PrimaryAgencyLanguage = dataVal.languageName;
        }
      });

      if (details.secondaryLanguageID !== null) {
        this.adminGlobal.PALValues.forEach((dataVal) => {
          if (dataVal.languageID === details.secondaryLanguageID) {
            this.SecondaryAgencyLanguage = dataVal.languageName;
          }
        });
      }

      this.AssetClass = this.adminGlobal.ShowAsset;
      this.SystemReport = this.adminGlobal.ShowSystemReport;
      this.tableAlias = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.tableAliasId);
      this.BaseLifeConfig = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.baseLifeConfigId);
      this.BaseLifeConfigValues = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.baseLifeValuesId);
      this.BaseLifeModify = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.modifierConfigId);
      this.BaseLifeModifyValues = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.modifierValueId);
      this.AcitivtyDuration = this.esriMapTool.FeatureLayer(details.webServiceUrl + environment.featureServer + this.adminGlobal.serviceLyrInfo.activityToolkitId);
    }
  }

  applySettings() {
    try {
      if (this.adminGlobal.AgencyId === 0) {
        this.CreateClient();
      } else {
        this.UpdateClient();
      }
    } catch (error) {
      console.error(error);
    }
  }
  SaveDatainResourceRequirement() {
    try {
      if (this.adminGlobal.ResourceRequirement.length === 0) {
        const featuresSet = [];
        this.adminGlobal.ResourceSaveValues.forEach((data) => {
          const features = new this.esriMapTool.Graphic();
          const assetAttr: any = [];
          assetAttr.AssetClass = data.AssetClass;
          assetAttr.Activity = data.Activity;
          assetAttr.ToolName = data.ToolName;
          features.setAttributes(assetAttr);
          featuresSet.push(features);
        });
        this.CallResourceFileUpdate(featuresSet);
      } else {
        const featuresSet = [];
        this.adminGlobal.ResourceRequirement.forEach((data) => {
          const features = new this.esriMapTool.Graphic();
          features.setAttributes(data);
          featuresSet.push(features);
        });
        this.AcitivtyDuration.applyEdits(
          null,
          null,
          featuresSet,
          (evt) => {
            const featuresSet = [];
            this.adminGlobal.ResourceSaveValues.forEach((data) => {
              const features = new this.esriMapTool.Graphic();
              const assetAttr: any = [];
              assetAttr.AssetClass = data.AssetClass;
              assetAttr.Activity = data.Activity;
              assetAttr.ToolName = data.ToolName;
              features.setAttributes(assetAttr);
              featuresSet.push(features);
            });
            this.CallResourceFileUpdate(featuresSet);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  CallResourceFileUpdate(featuresSetCreate) {
    this.AcitivtyDuration.applyEdits(
      featuresSetCreate,
      null,
      null,
      (evt) => {
        this.ShowSWalMessage();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  CreateClient() {
    this.showApplySettingButton = false;
    this.loading = true;
    //Updated by Sowjanya for TSDRSAM-71
    let regionLayerId = 0;
    const mapServiceL = [];
    this.showApplySettingButton = false;
    this.loading = true;
    this.genericService.getLayerInforBasedOnServiceUrl(this.adminGlobal.NewClientGisUrl).subscribe((mapServiceResult: any) => {
      mapServiceResult.layers.forEach((data: any) => {
        if (data.name === "Context") {
          data.subLayerIds.forEach((val: any) => {
            if (mapServiceResult.layers[val].name == "Regions") {
              regionLayerId = val;
            }
          });
        }
        if (data.name === "Inventory") {
          data.subLayerIds.forEach((val: any) => {
            mapServiceL.push({ gisId: val, gisLayerName: mapServiceResult.layers[val].name });
          });
        }
      });
      mapServiceResult.tables.forEach((data) => {
        if (data.name === "Sign") {
          mapServiceL.push({ gisId: data.id, gisLayerName: data.name });
        } else if (data.name === "Light") {
          mapServiceL.push({ gisId: data.id, gisLayerName: data.name });
        }
      });

      this.MMMAdminService.postClientList(this.adminGlobal.agencyDetails[0]).subscribe(
        (res: any) => {
          this.adminGlobal.AgencyId = parseInt(res.message, 10);
          this.userService.postUserDataByClient(this.adminGlobal.AgencyId, this.adminGlobal.AdminData).subscribe((res: any) => {
            console.log("result from postUserDataByClient : " + res.message);
            const lyrObj = new this.esritools.FeatureLayer(
              this.adminGlobal.NewClientGisUrl +
                // this.currentUser.webServiceURL +
                environment.featureServer +
                "/" +
                regionLayerId
            );
            const fields = "RegionName1";
            const query = new this.esritools.Query();
            query.where = "1=1";
            query.outFields = [fields];
            query.returnGeometry = false;
            query.returnDistinctValues = true;
            const queryTask = new this.esritools.QueryTask(lyrObj.url);

            queryTask.execute(query).then((res) => {
              const data = {
                regionName: "string",
                regionLevel: 1,
                accessPrivilege: "string"
              };
              data.regionName = res.features[0].attributes.RegionName1;
              data.regionLevel = 1;
              data.accessPrivilege = "Data Editor";
              this.genericService.getUserList(this.adminGlobal.AgencyId).subscribe((results: any) => {
                console.log("from getUserList : " + results);
                this.adminGlobal.SuperAdminUserId = results[0].userId;
                this.userService.postUserRegionAccess(this.adminGlobal.AgencyId, this.adminGlobal.SuperAdminUserId, data).subscribe((evt: any) => {
                  console.log(evt.message + "meassage from region access");
                });
                this.adminGlobal.ShowAsset.forEach((element) => {
                  const dataC = {
                    userId: 0,
                    gisLayerId: "string",
                    accessPrivilege: "string"
                  };
                  const gisLId = mapServiceL.filter((servicelayers) => {
                    if (servicelayers.gisLayerName === element.Name) return servicelayers.gisId;
                  });
                  dataC.userId = this.adminGlobal.SuperAdminUserId;
                  dataC.gisLayerId = gisLId[0].gisId;
                  dataC.accessPrivilege = "Data Editor";
                  this.userService.postUserLayerAccess(this.adminGlobal.AgencyId, this.adminGlobal.SuperAdminUserId, dataC).subscribe(
                    (evt: any) => {
                      console.log(evt.message + ":" + dataC.gisLayerId);
                    },
                    (error: any) => {
                      console.log(error.error.message);
                    }
                  );
                });
              });
            });
          });

          if (this.adminGlobal.ShowAsset.length > 0) {
            this.UpdateAssetClass();
          } else {
            if (this.adminGlobal.NewBaselifeConfig.length > 0) {
              this.SaveDataOfBaseLifeConfig();
            } else {
              if (this.adminGlobal.NewBaselifeModifier.length > 0) {
                this.SaveDataOfBaseLifeModifer();
              } else {
                if (this.adminGlobal.ShowSystemReport.length > 0) {
                  this.UpdateReport(this.adminGlobal.AgencyId);
                } else {
                  if (this.adminGlobal.ResourceSaveValues.length > 0) {
                    this.SaveDatainResourceRequirement();
                  } else {
                    this.ShowSWalMessage();
                  }
                }
              }
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    });
    //
  }

  UpdateClient() {
    this.showApplySettingButton = false;
    this.loading = true;
    //Added by Sowjanya for TSDRSAM-71
    this.genericService.getLayerInforBasedOnService().subscribe((results: any) => {
      const mapServicelayers = [];
      results.layers.forEach((data: any) => {
        if (data.name === "Inventory") {
          data.subLayerIds.forEach((val: any) => {
            mapServicelayers.push({ gisId: val, gisLayerName: results.layers[val].name });
          });
        }
      });
      results.tables.forEach((data) => {
        if (data.name === "Sign") {
          mapServicelayers.push({ gisId: data.id, gisLayerName: data.name });
        } else if (data.name === "Light") {
          mapServicelayers.push({ gisId: data.id, gisLayerName: data.name });
        }
      });

        this.MMMAdminService.updateClientList(this.adminGlobal.agencyDetails[0],this.adminGlobal.AgencyId).subscribe(
          (res: any) => {
            let deleteRequest=[];

            //fixed 3M admin data save quick fix 
            //TODO: this.currentUser should not be null\undefined
            if(this.currentUser !=undefined)
            {
            this.currentUser.layerLevelInfo.forEach(lyrInfo => {
              deleteRequest.push(this.userService.RemoveUserLayerAccess(this.currentUser.clientId,this.currentUser.userId,lyrInfo.gisLayerId))
            });
          }           
            
            forkJoin(
              deleteRequest
              ).subscribe(
                (results:any) => {
                  results.forEach((result)=>{
                  console.log(result.message)
                  });
                  this.adminGlobal.ShowAsset.forEach(element => {
                    const dataL = {
                      userId: 0,
                      gisLayerId: "string",
                      accessPrivilege: "string"
                    };
                    dataL.userId = this.currentUser.userId;
                    const gisLId=mapServicelayers.filter((servicelayers)=>{if(servicelayers.gisLayerName===element.Name) return servicelayers.gisId});
                    dataL.gisLayerId = gisLId[0].gisId;
                    dataL.accessPrivilege = "Data Editor";
                    this.userService.postUserLayerAccess(this.currentUser.clientId,this.currentUser.userId, dataL).subscribe(
                      (evt: any) => {
                       console.log(evt.message+":"+ dataL.gisLayerId);
                      },
                      (error: any) => {
                        console.log(error.error.message);
                      }
                    );
                  });
                });
                //
            if (this.adminGlobal.ShowAsset.length > 0) {
              this.UpdateAssetClass();
            } else {
              if (this.adminGlobal.NewBaselifeConfig.length > 0) {
                this.SaveDataOfBaseLifeConfig();
              } else {
                if (this.adminGlobal.NewBaselifeModifier.length > 0) {
                  this.SaveDataOfBaseLifeModifer();
                } else {
                  if (this.adminGlobal.ShowSystemReport.length > 0) {
                    this.UpdateReport(this.adminGlobal.AgencyId);
                  } else {
                    if (this.adminGlobal.ResourceSaveValues.length > 0) {
                      this.SaveDatainResourceRequirement();
                    } else {
                      this.ShowSWalMessage();
                    }
                  }
                }
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
  }

  closepopup() {
    this.showApplySettingButton = false;
    this.adminGlobal.agencyDetails = [];
    this.agencyName = null;
    this.SolutionTier = null;
    this.PrimaryAgencyLanguage = null;
    this.agencyWebService = null;
    this.agencySpatialDatabase = null;
    this.SecondaryAgencyLanguage = null;
    this.adminGlobal.AgencyId = 0;
    this.adminGlobal.AssetClassList = [];
    this.adminGlobal.HideAsset = [];
    this.adminGlobal.HideSystemReport = [];
    this.adminGlobal.PALValues = [];
    this.adminGlobal.ShowAsset = [];
    this.adminGlobal.ShowSystemReport = [];
    this.adminGlobal.serviceLyrInfo = [];
    this.adminGlobal.ExistBaselifeConfig = [];
    this.adminGlobal.ExistBaselifeModifier = [];
    this.adminGlobal.NewBaselifeConfig = [];
    this.adminGlobal.NewBaselifeModifier = [];
    this.adminGlobal.ExistBaseLife = [];
    this.adminGlobal.ExistBaseLifeModify = [];
    this.adminGlobal.ResourceRequirement = [];
    this.adminGlobal.ResourceSaveValues = [];
    this.adminGlobal.ResourceRequirementFilename = null;
  }

  UpdateAssetClass() {
    this.showApplySettingButton = false;
    const result3 = _(this.adminGlobal.HideAsset).differenceBy(this.adminGlobal.ShowAsset, "OBJECTID", "Name").map(_.partial(_.pick, _, "OBJECTID", "Name")).value();
    const featuresSet = [];
    result3.forEach((data) => {
      const features = new this.esriMapTool.Graphic();
      const assetAttr: any = [];
      assetAttr.OBJECTID = data.OBJECTID;
      assetAttr.IsAuthorized = "F";
      features.setAttributes(assetAttr);
      featuresSet.push(features);
    });
    this.AssetClass.forEach((data) => {
      const features = new this.esriMapTool.Graphic();
      const assetAttr: any = [];
      assetAttr.OBJECTID = data.OBJECTID;
      assetAttr.IsAuthorized = "T";
      features.setAttributes(assetAttr);
      featuresSet.push(features);
    });
    this.tableAlias.applyEdits(
      null,
      featuresSet,
      null,
      (evt) => {
        if (this.adminGlobal.NewBaselifeConfig.length > 0) {
          this.SaveDataOfBaseLifeConfig();
        } else {
          if (this.adminGlobal.NewBaselifeModifier.length > 0) {
            this.SaveDataOfBaseLifeModifer();
          } else {
            if (this.adminGlobal.ShowSystemReport.length > 0) {
              this.UpdateReport(this.adminGlobal.AgencyId);
            } else {
              if (this.adminGlobal.ResourceSaveValues.length > 0) {
                this.SaveDatainResourceRequirement();
              } else {
                this.ShowSWalMessage();
              }
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  ShowSWalMessage() {
    try {
      this.loading = false;
      let Message;

      if (this.adminGlobal.AgencyId === 0) {
        Message = "Successfully created " + this.adminGlobal.agencyDetails[0].clientName + " agency.";
      } else {
        Message = "Successfully updated " + this.adminGlobal.agencyDetails[0].clientName + " agency.";
      }

      Swal.fire({
        title: Message
      }).then((result) => {
        if (result.value) {
          this.closepopup();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  UpdateReport(clientID) {
    try {
      this.showApplySettingButton = false;
      let data;
      this.adminGlobal.ShowSystemReport.forEach((element, key) => {
        if (key === 0) {
          data = "?reportConfigurations=" + element.reportID;
        } else {
          data = data + "&reportConfigurations=" + element.reportID;
        }
      });
      this.MMMAdminService.postSystemReportByclientId(clientID, data).subscribe(
        (res) => {
          if (this.adminGlobal.ResourceSaveValues.length > 0) {
            this.SaveDatainResourceRequirement();
          } else {
            this.ShowSWalMessage();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  SaveDataOfBaseLifeConfig() {
    const featuresSet = [];
    this.adminGlobal.NewBaselifeConfig.forEach((data) => {
      const features = new this.esriMapTool.Graphic();
      const assetAttr: any = [];
      assetAttr.AssetClass = data.AssetClass;
      assetAttr.BLF_Column = data.BLF_Column;
      assetAttr.BLF_Domain = data.BLF_Domain;
      assetAttr.CreatedBy = "3MAdmin";
      assetAttr.CreatedOn = new Date(Date.now()).getTime();
      features.setAttributes(assetAttr);
      featuresSet.push(features);
    });
    this.BaseLifeConfig.applyEdits(
      featuresSet,
      null,
      null,
      (evt) => {
        const featuresSetVal = [];
        this.adminGlobal.NewBaselifeConfig.forEach((data) => {
          data.CodedValues.forEach((newConfig) => {
            const features = new this.esriMapTool.Graphic();
            const assetAttr: any = [];
            assetAttr.AssetClass = data.AssetClass;
            assetAttr.BLF_Domain = data.BLF_Domain;
            assetAttr.BLF_Domain_Code = newConfig.code;
            assetAttr.BLF_Column = data.BLF_Column;
            assetAttr.CreatedBy = "3MAdmin";
            assetAttr.BaseLife = 0;
            assetAttr.CreatedOn = new Date(Date.now()).getTime();
            features.setAttributes(assetAttr);
            featuresSetVal.push(features);
          });
        });
        this.BaseLifeConfigValues.applyEdits(
          featuresSetVal,
          null,
          null,
          () => {
            if (this.adminGlobal.NewBaselifeModifier.length > 0) {
              this.SaveDataOfBaseLifeModifer();
            } else {
              if (this.adminGlobal.ShowSystemReport.length > 0) {
                this.UpdateReport(this.adminGlobal.AgencyId);
              } else {
                if (this.adminGlobal.ResourceSaveValues.length > 0) {
                  this.SaveDatainResourceRequirement();
                } else {
                  this.ShowSWalMessage();
                }
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  SaveDataOfBaseLifeModifer() {
    const featuresSet = [];
    this.adminGlobal.NewBaselifeModifier.forEach((data) => {
      const features = new this.esriMapTool.Graphic();
      const assetAttr: any = [];
      assetAttr.AssetClass = data.AssetClass;
      assetAttr.Modifier_Column = data.Modifier_Column;
      assetAttr.Modifier_Domain = data.Modifier_Domain;
      assetAttr.CreatedBy = "3MAdmin";
      assetAttr.CreatedOn = new Date(Date.now()).getTime();
      features.setAttributes(assetAttr);
      featuresSet.push(features);
    });
    this.BaseLifeModify.applyEdits(
      featuresSet,
      null,
      null,
      (evt) => {
        const featuresSetVal = [];
        this.adminGlobal.NewBaselifeModifier.forEach((data) => {
          this.adminGlobal.ExistBaseLifeModify.forEach((val) => {
            data.CodedValues.forEach((newModifier) => {
              val.CodedValues.forEach((existModifier) => {
                const features = new this.esriMapTool.Graphic();
                const assetAttr: any = [];
                assetAttr.AssetClass = data.AssetClass;
                assetAttr.Modifier_Domain = data.Modifier_Domain;
                assetAttr.Modifier_Domain_Code = newModifier.code;
                assetAttr.BLF_Column = val.Modifier_Column;
                assetAttr.CreatedBy = "3MAdmin";
                assetAttr.BLF_Domain_Code = existModifier.code;
                assetAttr.Adjust_Years = "0.0000";
                assetAttr.CreatedOn = new Date(Date.now()).getTime();
                features.setAttributes(assetAttr);

                // 4-12-2020 Manikantha Start
                if (
                  featuresSetVal.filter((item) => {
                    if (
                      item.attributes.Modifier_Domain === assetAttr.Modifier_Domain &&
                      item.attributes.Modifier_Domain_Code === assetAttr.Modifier_Domain_Code &&
                      item.attributes.BLF_Column === assetAttr.BLF_Column &&
                      item.attributes.BLF_Domain_Code === assetAttr.BLF_Domain_Code
                    ) {
                      return item;
                    }
                  }).length == 0
                ) {
                  featuresSetVal.push(features);
                }
                // 4-12-2020 Manikantha End
              });
            });
          });
        });
        this.BaseLifeModifyValues.applyEdits(
          featuresSetVal,
          null,
          null,
          () => {
            if (this.adminGlobal.ShowSystemReport.length > 0) {
              this.UpdateReport(this.adminGlobal.AgencyId);
            } else {
              if (this.adminGlobal.ResourceSaveValues.length > 0) {
                this.SaveDatainResourceRequirement();
              } else {
                this.ShowSWalMessage();
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
