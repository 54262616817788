import { InjectionToken } from "@angular/core";

// import translations
import { LANG_EN_NAME, LANG_EN_TRANS } from "./en";
import { LANG_ES_NAME, LANG_ES_TRANS } from "./es";
import { LANG_DE_NAME, LANG_DE_TRANS } from "./de";
import { LANG_HI_NAME, LANG_HI_TRANS } from "./hi";
import { LANG_PT_NAME, LANG_PT_TRANS } from "./pt";

// translation token
export const TRANSLATIONS = new InjectionToken("translations");

// all translations
export const dictionary = {
  [LANG_EN_NAME]: LANG_EN_TRANS,
  [LANG_ES_NAME]: LANG_ES_TRANS,
  [LANG_DE_NAME]: LANG_DE_TRANS,
  [LANG_PT_NAME]: LANG_PT_TRANS,
  [LANG_HI_NAME]: LANG_HI_TRANS
};

// providers
export const TRANSLATION_PROVIDERS = [{ provide: TRANSLATIONS, useValue: dictionary }];
