<!-- width 100% single click not work added by mdhuri -->
<div style="width: 100%; height: 100%; position: absolute; top: 70px; z-index: 9999; background: white" *ngIf="eventEmitersService.showLidar">
  <app-heading-component
    aria-label="Close"
    headingText="Lidar Data"
    [variant]="'none'"
    (actionOne)="eventEmitersService.toggleLidarIframe(null, null, null, null, null)"
    [iconOne]="'cross'"
    [tooltipOne]="'Close'"
    data-dismiss="modal"
  ></app-heading-component>
  <!-- Change iframe height to make sure we are not cutting off bottom of ifram urls -->
  <iframe style="width: 100%; height: 75%" [src]="eventEmitersService.lidarUrl" frameborder="0"></iframe>
</div>
<!-- TSDRSAM -1113  height 6rem -->
<div
  class="row"
  style="position: absolute; bottom: 0px; left: 0px; z-index: 1; margin-left:0px; margin-right:0px;"
  [style.width]="showTask || showgrid || showSelectedTasksData ? '100%' : '25%'"
  role="navigation"
  aria-label="My Maintenance"
  id="MaintenanceDiv"
>
  <div id="maptool" class="map-tools-div" role="navigation" aria-label="map tools" data-html2canvas-ignore="true" *ngIf="currentUser.soultionTiers[0][0].toolbar" style="bottom: 0px; left: 0px; z-index: 1">
    <sms-map-tools
      [map]="map"
      [extent]="Extent"
      [OverviewMap]="OverviewMap"
      [drawTool]="drawTool"
      [webMercatorUtils]="webMercatorUtils"
      [LocateButton]="LocateButton"
      [layerData]="layerData.userLayers"
      [VectorTileLayer]="VectorTileLayer"
      [region]="region"
      [Query]="Query"
      [QueryTask]="QueryTask"
      [Draw]="Draw"
      [PictureMarkerSymbol]="PictureMarkerSymbol"
    ></sms-map-tools>
  </div>
  <div *ngIf="rowdataobj" class="myMaintananceDiv">
    <sms-select-features *ngIf="showgrid" [map]="map" [FeatureTable]="FeatureTable" (selectedRow)="(null)"></sms-select-features>
  </div>
  <sms-my-maintenance
    *ngIf="showTask"
    [Query]="Query"
    [QueryTask]="QueryTask"
    [map]="map"
    [Graphic]="Graphic"
    [tasksCategory]="category"
    [FeatureLayer]="FeatureLayer"
    [all]="all"
    (closeTable)="closeTable($event)"
    [all]="all"
    [map]="map"
    [Point]="Point"
    (showTask)="showTaskData($event)"
    [SimpleMarkerSymbol]="SimpleMarkerSymbol"
    [SimpleFillSymbol]="SimpleFillSymbol"
    [SimpleLineSymbol]="SimpleLineSymbol"
    [Color]="Color"
    [PictureMarkerSymbol]="PictureMarkerSymbol"
    (rowDataObjVar)="displayTaskinViewer($event)"
    [RelationshipQuery]="RelationshipQuery"
  >
  </sms-my-maintenance>
  <sms-my-maintenance
    *ngIf="showSelectedTasksData"
    [Query]="Query"
    [QueryTask]="QueryTask"
    [map]="map"
    [Graphic]="Graphic"
    [tasksCategory]="category"
    [selectedData]="rowdataobj"
    [FeatureLayer]="FeatureLayer"
    [all]="all"
    (closeTable)="closeTable($event)"
    [all]="all"
    [map]="map"
    [Point]="Point"
    (showTask)="showTaskData($event)"
    [SimpleMarkerSymbol]="SimpleMarkerSymbol"
    [SimpleFillSymbol]="SimpleFillSymbol"
    [SimpleLineSymbol]="SimpleLineSymbol"
    [Color]="Color"
    [PictureMarkerSymbol]="PictureMarkerSymbol"
    (rowDataObjVar)="displayTaskinViewer($event)"
    [RelationshipQuery]="RelationshipQuery"
  >
  </sms-my-maintenance>
</div>
<!-- w-50 -->

<div class="container-fluid h-100">
  <div class="row h-5" role="navigation" aria-label="header" id="header">
    <div class="col-12">
      <sms-header
        (headerOptionEvent)="invokePopup($event)"
        [Graphic]="Graphic"
        [Query]="Query"
        [FeatureLayer]="FeatureLayer"
        [FeatureTable]="FeatureTable"
        [TimeExtent]="TimeExtent"
        [SimpleLineSymbol]="SimpleLineSymbol"
        [SimpleRenderer]="SimpleRenderer"
        [Color]="Color"
        [TimeSlider]="TimeSlider"
        [TimeInfo]="TimeInfo"
        [QueryTask]="QueryTask"
        [map]="map"
        [registry]="registry"
        [Geoprocessor]="Geoprocessor"
        [Popup]="Popup"
        [domConstruct]="domConstruct"
        (showCard)="showCardView($event)"
      >
      </sms-header>
    </div>
  </div>
  <div class="row h-95">
    <div class="col-12" style="padding: 0px">
      <!-- Here's the DIV that we inject the map into -->
      <div #mapViewNode class="map-node" role="main" aria-label="mapView">
        <div class="searchDiv ml-3 p-3" [style.display]="showSearch ? 'block' : 'none'" data-html2canvas-ignore="true">
          <sms-search-tool
            [map]="map"
            [searchWidget]="searchWidget"
            [InfoTemplate]="InfoTemplate"
            [Circle]="Circle"
            [Units]="Units"
            [SimpleFillSymbol]="SimpleFillSymbol"
            [SimpleLineSymbol]="SimpleLineSymbol"
            [Color]="Color"
            [Graphic]="Graphic"
            [Query]="Query"
            [Point]="Point"
            [PictureMarkerSymbol]="PictureMarkerSymbol"
            [Locator]="Locator"
            [FeatureLayer]="FeatureLayer"
            [SimpleMarkerSymbol]="SimpleMarkerSymbol"
            [PopupTemplate]="PopupTemplate"
            [Popup]="Popup"
            [domConstruct]="domConstruct"
            [webMercatorUtils]="webMercatorUtils"
            [layerData]="layerData"
            [ScreenPoint]="ScreenPoint"
            [GraphicsLayer]="GraphicsLayer"
            [PopupExtended]="PopupExtended"
            [RelationshipQuery]="RelationshipQuery"
            [all]="all"
            [currentUser]="currentUser"
            [drawTool]="drawTool"
            [QueryTask]="QueryTask"
            (showmaintenanceViewer)="showTaskData($event)"
            (category)="tasksSelected($event)"
            (showCard)="showCardView($event)"
            (showGrid)="showGridData($event)"
            (data)="emittedData($event)"
            (index)="setRowIndex($event)"
            (layerIndex)="setSelLayerIndex($event)"
          ></sms-search-tool>
        </div>
        <!-- Select Tools Container   -->
        <div class="selectDiv" *ngIf="showSelect" data-html2canvas-ignore="true">
          <sms-select-tool
            [Point]="Point"
            [map]="map"
            [SimpleFillSymbol]="SimpleFillSymbol"
            [SimpleLineSymbol]="SimpleLineSymbol"
            [Query]="Query"
            [SimpleMarkerSymbol]="SimpleMarkerSymbol"
            [drawTool]="drawTool"
            [Color]="Color"
            [Graphic]="Graphic"
            [all]="all"
            [QueryTask]="QueryTask"
            [webMercatorUtils]="webMercatorUtils"
            [PictureMarkerSymbol]="PictureMarkerSymbol"
            [GraphicsLayer]="GraphicsLayer"
            [FeatureLayer]="FeatureLayer"
            (showmaintenanceViewer)="showTaskViewTable($event)"
            (isOpenCard)="OpenCardView($event)"
            (showCard)="showCardView($event)"
            (showGrid)="showGridData($event)"
            (data)="emittedData($event)"
            (category)="tasksSelected($event)"
            (index)="setRowIndex($event)"
            (layerIndex)="setSelLayerIndex($event)"
          >
          </sms-select-tool>
        </div>
        <div class="measurebound">
          <div
            id="measureId"
            class="measureDiv card"
            [style.display]="showMeasure ? 'block' : 'none'"
            [style.transform]="!showMeasure ? 'none' : ''"
            data-html2canvas-ignore="true"
            style="position: absolute"
            cdkDrag
            cdkDragBoundary=".measurebound"
          >
            <app-heading-component
              aria-label="Close"
              [headingText]="'Esri-map.measurementdiv.measurement' | translate"
              [variant]="'none'"
              [iconOne]="'cross'"
              cdkDragHandle
              (actionOne)="close($event, 'measure')"
              [tooltipOne]="'Close'"
              data-dismiss="modal"
            >
            </app-heading-component>

            <div class="card-body">
              <div id="measureWidgetDiv"></div>
            </div>
          </div>
        </div>
        <div class="basemapDiv" [style.display]="showBaseMapGallery ? 'block' : 'none'">
          <!-- TSDRSAM-1806 -->
          <app-heading-component [headingText]="'Map Types'" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="toggleBasemapGallery()"> </app-heading-component>
          <div class="basemap-list">
            <div id="basemapGalleryDiv"></div>
          </div>
        </div>

        <div class="layersDiv p-3" [style.display]="showLayers ? 'block' : 'none'" data-toggle="collapse">
          <app-heading-component
            [headingText]="'Esri-map.layerdiv.layer' | translate"
            [variant]="'none'"
            [iconOne]="showLayerList ? 'arrow-up' : 'arrow-down'"
            [tooltipOne]="showLayerList ? 'Minimize' : 'Expand'"
            (actionOne)="showLayerList = !showLayerList"
          >
          </app-heading-component>
          <div [style.display]="showLayerList ? 'block' : 'none'" class="layerContentDiv">
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" [ngClass]="{ active: layerTabActive }" id="layers-tab" data-toggle="tab" href="#layers" role="tab" aria-controls="layers" aria-selected="true" (click)="switchTabs('layers')"
                  >Managed</a
                >
                <!-- //Updated for Visualization layers-->
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngClass]="{ active: !layerTabActive && !visualizationLyrTabActive }"
                  id="legend-tab"
                  data-toggle="tab"
                  href="#legend"
                  role="tab"
                  aria-controls="legend"
                  aria-selected="false"
                  (click)="switchTabs('legend')"
                  >{{ "Esri-map.layerdiv.legends" | translate }}</a
                >
              </li>
              <!--//Added for Visualization layers-->
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngClass]="{ active: visualizationLyrTabActive }"
                  id="legend-tab"
                  data-toggle="tab"
                  href="#legend"
                  role="tab"
                  aria-controls="layers"
                  aria-selected="false"
                  (click)="switchTabs('visualizationlayers')"
                  >Reference</a
                >
              </li>
            </ul>
            <div class="tab-content layers-tab-content">
              <div class="tab-pane fade" [ngClass]="{ 'show active': layerTabActive }" id="layersmain" role="tabpanel" aria-labelledby="layers-tab">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let lyr of inventoryLayersData" [hidden]="false">
                    <div *ngIf="!scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableAlias ? lyr.tableAlias : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                    <div *ngIf="scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableSL ? lyr.tableSL : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                  </li>
                  <!-- <div class="border-top border-divider"></div> -->
                  <li class="list-group-item" *ngFor="let lyr of tracksLayersData">
                    <div *ngIf="!scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableAlias ? lyr.tableAlias : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                    <div *ngIf="scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableSL ? lyr.tableSL : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                  </li>
                  <!-- <div class="border-top border-divider"></div> -->
                  <li class="list-group-item" *ngFor="let lyr of contextLayersData">
                    <div *ngIf="!scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableAlias ? lyr.tableAlias : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                    <div *ngIf="scndlyr" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleMapLayers($event, lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        {{ lyr.tableSL ? lyr.tableSL : lyr.layerName }}
                      </mat-checkbox>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade legend-tab" [ngClass]="{ 'show active': !layerTabActive && !visualizationLyrTabActive }" id="legend" role="tabpanel" aria-labelledby="legend-tab">
                <div id="divLegendWidget"></div>
              </div>
              <!--//Added for Visualization layers-->
              <!-- Visualization Layers -->
              <div class="tab-pane fade" [ngClass]="{ 'show active': visualizationLyrTabActive }" id="layersRef" role="tabpanel" aria-labelledby="layers-tab">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let lyr of visualizationLayersData" [hidden]="false">
                    <div style="display: flex; align-items: center" class="custom-control custom-checkbox lyr-checkbox" (click)="toggleVisualizationLayers(lyr)">
                      <mat-checkbox [checked]="lyr.visible">
                        <img tabindex="0" *ngIf="lyr.logo.length > 0" src="{{ lyr.imgLogo }}" width="15px" height="15px" style="margin-top: -7px" alt="" />
                        <img tabindex="0" *ngIf="lyr.logo.length == 0" src="assets/images/nopic.png" width="15px" height="15px" style="margin-top: -7px" alt="No image" />
                        {{ lyr.tableAlias ? lyr.tableAlias : lyr.layerName }}
                      </mat-checkbox>

                      <img alt="{{ lyr.legend }}" tabindex="0" *ngIf="lyr.legend.length > 0" src="{{ lyr.legend }}" style="margin-left: 5px" width="15px" height="15px" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filterDiv p-3" [style.display]="showFilter ? 'block' : 'none'" data-toggle="collapse">
          <app-heading-component
            [headingText]="'Esri-map.regiondiv.regions' | translate"
            [variant]="'none'"
            [iconOne]="showFilterList ? 'arrow-up' : 'arrow-down'"
            [tooltipOne]="showFilterList ? 'Minimize' : 'Expand'"
            (actionOne)="showFilterList = !showFilterList"
          >
          </app-heading-component>
          <div [style.display]="showFilterList ? 'block' : 'none'" class="filterContentDiv">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let reg of regionData; let i = index" [hidden]="false">
                <div class="custom-control custom-checkbox">
                  <input type="radio" class="custom-control-input" name="region-select" id="reg-chk-{{ reg.regionName }}" [checked]="i == 0 ? true : false" (change)="changeRegion(event, reg)" />
                  <label class="custom-control-label" for="reg-chk-{{ reg.regionName }}">{{ reg.regionName }}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="showEsriWidgets" data-html2canvas-ignore="true">
          <sms-redline
            [map]="map"
            [drawTool]="drawTool"
            [ColorPicker]="ColorPicker"
            *ngIf="showRedlineDraw"
            [SimpleLineSymbol]="SimpleLineSymbol"
            [SimpleFillSymbol]="SimpleFillSymbol"
            [SimpleMarkerSymbol]="SimpleMarkerSymbol"
            [Color]="Color"
            [Graphic]="Graphic"
            [GraphicsLayer]="GraphicsLayer"
            (closeEvent)="close($event, 'redline')"
          >
          </sms-redline>
        </div>

        <!--All Map-Tools Navigations-->

        <div class="query-builder-div" *ngIf="closeAdministrator">
          <sms-administrator (closeQueryBuilderEvent)="closeQueryBuilder($event)" (data)="showQueryData($event)"> </sms-administrator>
        </div>

        <div class="querybound">
          <div class="query-builder-div" [ngClass]="{ minimized: isQueryBuilderMinimized == true }" id="queryBuilderDiv" *ngIf="closeQueryBuilderVar" data-html2canvas-ignore="true">
            <sms-querybuilder
              (closeQueryBuilderEvent)="closeQueryBuilder($event)"
              (queryBuilder)="saveQBTableData($event)"
              [Query]="Query"
              [QueryTask]="QueryTask"
              (data)="showQueryData($event)"
              [SimpleLineSymbol]="SimpleLineSymbol"
              [SimpleFillSymbol]="SimpleFillSymbol"
              [SimpleMarkerSymbol]="SimpleMarkerSymbol"
              [Color]="Color"
              [Graphic]="Graphic"
              [GraphicsLayer]="GraphicsLayer"
              [map]="map"
              [all]="all"
              [drawTool]="drawTool"
              [FeatureLayer]="FeatureLayer"
              [queryBuilderTable]="queryBuilderArray"
              [RelationshipQuery]="RelationshipQuery"
              [PictureMarkerSymbol]="PictureMarkerSymbol"
              [webMercatorUtils]="webMercatorUtils"
              [Point]="Point"
              (indexCV)="setRowIndex($event)"
              (layerIndex)="setSelLayerIndex($event)"
              (isFromQB)="closeFromQB($event)"
              (data)="emittedData($event)"
              (indexdata)="setRowIndex($event)"
              (layerIndex)="setSelLayerIndex($event)"
              (minimize)="toggleMinimizeQb($event)"
            >
            </sms-querybuilder>
          </div>
        </div>
        <!-- JIRA ID:-61 -->
        <!-- TSDRSAM-1544 div kept in same bound for query builder and cardview -->
        <div class="position-absolute card-view-div card" *ngIf="showEsriWidgets" id="cardViewDiv"  [ngClass]="eventEmitersService.numOfOpenPanels == 0  ?  'full-card-view'  : eventEmitersService.numOfOpenPanels == 1 ? 'half-card-view' : eventEmitersService.numOfOpenPanels == 2 ?  'small-card-view' : 'full-card-view'" >
          <div class="row" style="height:100%">
            <div class="col-12" style="height:100%">
              <sms-select-cardview-features
                style="display:block; height:100%"
                *ngIf="showcard"
                [map]="map"
                [RelationshipQuery]="RelationshipQuery"
                [FeatureLayer]="FeatureLayer"
                [Query]="Query"
                [QueryTask]="QueryTask"
                [AI]="AttributeInspector"
                [Editor]="Editor"
                (selectedRow)="(null)"
                [Point]="Point"
                [rowdataobj]="rowdataobj"
                [layerData]="inventoryLayersData"
                [Draw]="Draw"
                [webMercatorUtils]="webMercatorUtils"
                [Graphic]="Graphic"
                [SimpleMarkerSymbol]="SimpleMarkerSymbol"
                [SimpleLineSymbol]="SimpleLineSymbol"
                [Color]="Color"
                [Edit]="Edit"
                [geometryEngine]="geometryEngine"
                [Polygon]="Polygon"
                [geometryJsonUtils]="geometryJsonUtils"
                [selectedIndex]="index"
                [selLayerindex]="layerIndex"
                [isFromQB]="isFromQB"
              >
              </sms-select-cardview-features>
            </div>
          </div>
        </div>
        <div class="help-div" *ngIf="closeHelpVar">
          <sms-help-center (closeHelpEvent)="closeHelpBuilder($event)"></sms-help-center>
        </div>
        <div class="data-div" *ngIf="DataEditor">
          <div class="modal-content" style="height:unset;" cdkDrag cdkDragBoundary=".data-div">
            <div cdkDragHandle>
              <app-heading-component [headingText]="'Datacollect.main.title' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="closeDataCollector()"> </app-heading-component>
            </div>
            <div id="help_tab" class="modal-body p-0 help show">
              <div class="container">
                <div style="margin-bottom: 16px; margin-top: 16px" class="h7">{{ "Datacollect.main.desc" | translate }}</div>

                <div class="card mb-3">
                  <ul class="list-group list-group-flush" style="padding: 0">
                    <li class="list-group-item formbg-d2">
                      <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" [checked]="dataEditorSettings.taskType == 'New'" (click)="choose($event['target'])" id="New" name="optradio" />
                        <label class="custom-control-label" for="New">{{ "Datacollect.main.normaloption" | translate }}</label>
                      </div>
                    </li>
                    <li class="list-group-item formbg-d2">
                      <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="Collect" name="optradio" [checked]="dataEditorSettings.taskType == 'Collect'" (click)="choose($event['target'])" />
                        <label class="custom-control-label" for="Collect">{{ "Datacollect.main.collectoption" | translate }}</label>
                      </div>
                    </li>
                    <li class="list-group-item formbg-d2">
                      <div class="custom-control custom-checkbox my-1 mr-sm-2">
                        <input [disabled]="currentUser.rolePermissions.roleName == 'Admin' ? false : true" [(ngModel)]="dataEditorSettings.hardDelete" type="checkbox" value="" class="custom-control-input" id="chkbox1" />
                        <label class="custom-control-label" for="chkbox1">{{ "Datacollect.main.permanentdelecteallow" | translate }}</label>
                      </div>
                    </li>
                    <li class="list-group-item formbg-d2">
                      <div class="custom-control custom-checkbox my-1 mr-sm-2">
                        <input [disabled]="currentUser.rolePermissions.roleName == 'Admin' ? false : true" [(ngModel)]="dataEditorSettings.IsOpenCreateEditTaskForm" type="checkbox" value="" class="custom-control-input" id="chkboxCreateEditTask" />
                        <label class="custom-control-label" for="chkboxCreateEditTask">{{ "Datacollect.main.openCreateEditTaskForm" | translate }}</label>
                      </div>
                    </li>
                  </ul>
                  <div class="card-footer bg-transparent text-right">
                    <button matButton (click)="saveDataEditor()" type="button" class="btn btn-primary">
                      {{ "Datacollect.main.saveworkordersetting" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="crew-management-div" *ngIf="crewmanagement">
          <app-sms-crewmanagement (closeCrewManagementEvent)="closeCrewManagement($event)"></app-sms-crewmanagement>
        </div>
        <div class="crowd-SourcedInfo-div" *ngIf="crowdSourceVar">
          <sms-crowd-sourced-info (createTask)="createTaskforAsset($event)" (crowdsource)="crowdsource()" (closeCrowdSourceEvent)="closeCrowdSourceBuilder($event)"></sms-crowd-sourced-info>
        </div>
        <!-- Basemap -->
        <div class="basemapBtnsDiv p-2" data-html2canvas-ignore="true" *ngIf="BaseMapproLicense">
          <table>
            <tr>
              <td>
                <a href="#" class="basemapMore" title="More Basemaps" (click)="toggleBasemapGallery()" *ngIf="proLicense">
                  <mat-icon id="basemaps-more" svgIcon="arrow-left"></mat-icon>
                </a>
              </td>
              <td>
                <div id="BasemapToggleDiv" [ngClass]="{ 'basemap-btns-more': BaseMapproLicense, 'basemap-btns': !BaseMapproLicense }"></div>
              </td>
            </tr>
          </table>
        </div>
        <!-- Basemap ends-->
        <!-- GPS Route Logs -->
        <div class="routeLogBtnDiv p-2" *ngIf="showEsriWidgets && currentUser.soultionTiers[0][0].route_Logs" data-html2canvas-ignore="true">
          <sms-route-logs
            [map]="map"
            [GraphicsLayer]="GraphicsLayer"
            [Polyline]="Polyline"
            [Point]="Point"
            [SimpleLineSymbol]="SimpleLineSymbol"
            [Color]="Color"
            [Graphic]="Graphic"
            [webMercatorUtils]="webMercatorUtils"
            [PictureMarkerSymbol]="PictureMarkerSymbol"
            [mathUtils]="mathUtils"
          >
          </sms-route-logs>
        </div>
        <!-- GPS Route Logs Ends-->
        <!-- Inset Map-->
        <div class="insetMap">
          <div id="overviewMapWidget"></div>
        </div>
        <!-- time slider-->
        <div id="timeSliderDiv" class="smstimeslider">
          <div id="timeSliderHeader" class="row">
            <div class="col-2"></div>
            <div id="timeSliderHeaderLabels" class="timeSliderLabels col-6">
              <!-- <label> User : Prashant</label>
    <label> Start Time : 20-Aug-2019</label>
    <label> End Time : 20-Nov-2019</label>  -->
            </div>
            <!-- added by sowjanya JIRA ID-53 15-10-2021  -->
            <div class="col-2"></div>
            <div class="col-1">
              <button class="custbtn btn-sm" style="background-color: transparent" (click)="ExportShapefile()" aria-label="ExportShapefile">
                <span class="iconassets medium" style="transform: rotate(180deg)"></span>
              </button>
            </div>
            <div class="col-1">
              <button class="custbtn btn-sm" style="background-color: transparent" (click)="closeTimeSlider()" aria-label="icon-close">
                <span class="iconassets1 medium"></span>
              </button>
            </div>
          </div>
        </div>
        <!-- time slider ends-->
        <div class="text-center" role="status">
          <div class="spinner-border map-loader" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-border map-loader mr-4 mt-2 spinnew" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
