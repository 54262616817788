import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../../services/authentication.service";
import { GenericLayerService } from "../../../../services/genericLayer.service";
import { Subscription, Observable } from "rxjs";
import { IUser } from "src/app/interface/user";
import { CrewManagementService } from "../../../crewmanagement.service";

@Component({
  selector: "app-sms-sheduler",
  templateUrl: "./sheduler.component.html",
  styleUrls: ["./sheduler.component.scss"]
})
export class ShedulerComponent implements OnInit {
  @Output() crewTasksData = new EventEmitter();
  @Input() events: Observable<void>;
  sheduler;

  private eventsSubscription: Subscription;
  assignmentTdata;
  assignments;
  totalassignments;
  regions: any = [];
  asset: any = [];
  dateToday;
  selectedregion;
  selectedAssetClass;
  selectedRow;
  selectedDate;
  Primarylangvalid;
  Secodarylangvalid;
  private currentUser: IUser;

  constructor(private authenticationService: AuthenticationService, private genericLayerService: GenericLayerService, private crewManagementService: CrewManagementService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.currentUser = this.authenticationService.currentUserValue;
    this.selectedregion = this.crewManagementService.selectedRegion;
    this.selectedAssetClass = this.crewManagementService.selectedLayer;
    this.asset = this.crewManagementService.layersList;
    this.regions = this.crewManagementService.regionsList;
    this.crewManagementService.regions.subscribe((result) => {
      console.log(this.crewManagementService.regionsList);
      this.selectedregion = this.crewManagementService.selectedRegion;
      this.regions = this.crewManagementService.regionsList;
    });
    this.crewManagementService.layers.subscribe((result) => {
      console.log(this.crewManagementService.layersList);
      this.selectedAssetClass = this.crewManagementService.selectedLayer;
      this.asset = this.crewManagementService.layersList;
    });
    this.totalassignments = this.crewManagementService.totalrecords;
    console.log(this.totalassignments);
    this.crewManagementService.records.subscribe((result) => {
      const date = new Date();
      this.dateToday = date.toLocaleDateString();
      this.selectedDate = this.dateToday;
      this.totalassignments = result;
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    });
  }

  ngOnInit() {
    this.crewManagementService.getRecords();
    const date = new Date();
    const tomorrow = new Date(this.dateToday);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.dateToday = date.toLocaleDateString();
    this.selectedDate = this.dateToday;

    if (this.totalassignments.length > 0) {
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    }
  }

  groupByrecords(resultArray, date, selectedregion, selectedAssetClass) {
    try {
      const result = [];

      const tomorrow = new Date(this.dateToday);
      tomorrow.setDate(tomorrow.getDate() + 1);

      // tslint:disable-next-line:only-arrow-functions
      resultArray.reduce(function (res, value) {
        // new Date(value.attributes.SheduledDate).toLocaleDateString() == date &&

        if (value.attributes.Region != null && value.attributes.Status === "Open" && value.attributes.AssetName != null) {
          if (value.attributes.Region === selectedregion && value.attributes.AssetName === selectedAssetClass) {
            if (value.attributes.SheduledDate == null || value.attributes.SheduledDate === 0) {
              value.attributes.Scheduler = "Unscheduled";
            }

            if (new Date(value.attributes.SheduledDate).toLocaleDateString() === date) {
              value.attributes.Scheduler = "Today";
            }

            if (new Date(value.attributes.SheduledDate).toLocaleDateString() === tomorrow.toLocaleDateString()) {
              value.attributes.Scheduler = "Tomorrow";
            }

            if (value.attributes.SheduledDate != null && new Date(value.attributes.SheduledDate).toLocaleDateString() < date) {
              value.attributes.Scheduler = "Past Due";
            }

            if (new Date(value.attributes.SheduledDate).toLocaleDateString() > tomorrow.toLocaleDateString()) {
              value.attributes.Scheduler = "Later";
            }

            if (!res[value.attributes.Scheduler]) {
              res[value.attributes.Scheduler] = { schedule: value.attributes.Scheduler, noOfTasks: 0, duration: 0, tasks: [] };
              result.push(res[value.attributes.Scheduler]);
            }

            res[value.attributes.Scheduler].noOfTasks += 1;
            res[value.attributes.Scheduler].duration += value.attributes.EstimatedDuration;
            res[value.attributes.Scheduler].tasks.push(value.attributes);
          }
        }

        return res;
      }, {});

      console.log(result);

      if (!result.find((o) => o.schedule === "Unscheduled")) {
        result.push({ schedule: "Unscheduled", noOfTasks: 0, duration: 0 });
      }

      if (!result.find((o) => o.schedule === "Today")) {
        result.push({ schedule: "Today", noOfTasks: 0, duration: 0 });
      }

      if (!result.find((o) => o.schedule === "Tomorrow")) {
        result.push({ schedule: "Tomorrow", noOfTasks: 0, duration: 0 });
      }

      if (!result.find((o) => o.schedule === "Past Due")) {
        result.push({ schedule: "Past Due", noOfTasks: 0, duration: 0 });
      }

      if (!result.find((o) => o.schedule === "Later")) {
        result.push({ schedule: "Later", noOfTasks: 0, duration: 0 });
      }

      this.assignments = result;
    } catch (error) {
      console.error(error);
    }
  }
  filterdatabyDate(date) {
    try {
      if (date === "today") {
        this.selectedDate = this.dateToday;
        this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass);
      } else {
        const tomorrow = new Date(this.dateToday);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.selectedDate = tomorrow;
        this.groupByrecords(this.totalassignments, tomorrow, this.selectedregion, this.selectedAssetClass);
      }
    } catch (error) {
      console.error(error);
    }
  }
  onRegionChange(evt) {
    try {
      console.log(evt);
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedRegion = this.selectedregion;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass);
    } catch (error) {
      console.error(error);
    }
  }
  onAssetChange(event) {
    try {
      console.log(event);
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedLayer = this.selectedAssetClass;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass);
    } catch (error) {
      console.error(error);
    }
  }

  rowclicked(data, ind) {
    try {
      this.crewManagementService.tasksModified.emit(true);
      this.selectedRow = ind;
      data.region = this.selectedregion;
      data.assetclass = this.selectedAssetClass;
      this.crewTasksData.emit(data);
    } catch (error) {
      console.error(error);
    }
  }
}
