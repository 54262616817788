<div class="card">
  <div class="card-body">
    <div class="modal-body" style="display: flex; flex-direction: column; max-width: 320px">
      <mat-form-field>
        <mat-label>{{ "Userscreens.taskduration.selectlayer" | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedLayer" (ngModelChange)="onChangelayer()">
          <mat-option [value]="layer.name" *ngFor="let layer of this.layerdominedata">
            {{ "Userscreens.assetattributedefalt." + layer.name | translate }}
          </mat-option>
        </mat-select>

        <div class="dropdown-icons">
          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field *ngIf="selectedLayerObj">
        <mat-label>
          {{ "Userscreens.taskduration.activitytype" | translate }}
        </mat-label>
        <mat-select [(ngModel)]="selectedLayerAct" (ngModelChange)="onChangelayerAct()">
          <mat-option [value]="activity.code" *ngFor="let activity of selectedLayerObj.domains.Activity.codedValues">
            {{ activity.name }}
          </mat-option>
        </mat-select>

        <div class="dropdown-icons">
          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Hours</mat-label>
        <input matInput [(ngModel)]="hours" type="number" min="0" max="12" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Minutes</mat-label>
        <input matInput [(ngModel)]="minites" type="number" min="0" max="59" />
      </mat-form-field>
    </div>
    <div class="modal-footer">
      <!-- <button class="btn btn-secondary mr-3" type="button" (click)="cancel()">Cancel Edits</button> -->
      <button class="btn btn-primary" aria-label="save edits" type="button" (click)="save()">{{ "Userscreens.taskduration.saveedits" | translate }}</button>
    </div>
  </div>
</div>
