import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import Swal from "sweetalert2";
import { ETLJob, ETLJobDetailRequest, JobReviewedRequest } from "../change-report.model";
import { Globals } from "src/app/select/globals";
import { EventEmitersService } from "src/app/services/eventEmiters.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"]
})
export class ViewComponent implements OnInit {
  //component accepts a JobID input
  @Input() jobId: number;
  @Input() FeatureLayer: any;
  @Input() Graphic: any;

  //public variables for use in the component and template
  jobDetails: any;
  selectedJob: ETLJob;
  reviewedFormGroup: UntypedFormGroup;
  ftrLyrTbl;
  ftrLyrActivity;
  currentUser;
  jobSummarySubscription: Subscription;
  //columns for different tables
  displayedColumns: string[] = ["AssetId", "AssetCode", "Geolocation"];

  reviewColumns: string[] = ["AssetId", "AssetCode", "Geolocation", "Action"];
  updatedColumns: string[] = ["AssetId", "AttributeName", "ExistingValue", "NewValue", "Action"];
  exceptionColumns: string[] = ["LayerName", "AssetNumber", "Geolocation", "PossibleMatch"];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private adminService: MMMAdminService,
    private auth: AuthenticationService,
    public objGlobals: Globals,
    public eventEmitersService: EventEmitersService,
    private translateService: TranslateService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    //extract to a method so it only is called once.
    this.makeETLJobDetailsRequest();
    this.ftrLyrTbl = new this.FeatureLayer(
      this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId,
      { outFields: ["*"] }
    );
    this.ftrLyrActivity = new this.FeatureLayer(
      this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId,
      { outFields: ["*"] }
    );
    this.jobSummarySubscription = this.adminService.getJobDetails().subscribe((data) => {
      this.selectedJob = data.find((job) => job.jobId == this.jobId);
    });
  }

  //when the job id is changed we need to fetch the job details again
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobId.currentValue != null) {
      this.makeETLJobDetailsRequest();
      //get job details and unsubscribe after first emit.
      this.adminService
        .getJobDetails()
        .pipe(take(1))
        .subscribe((data) => {
          this.selectedJob = data.find((job) => job.jobId == this.jobId);
          if (this.reviewComments != null) {
            this.reviewComments.patchValue(this.selectedJob.reviewComments);
            //get review date as well
          }
        });

      //is this redundant?
      //setup form to use current job id and review comments if applicable
      this.reviewedFormGroup = this.formBuilder.group({
        jobId: [this.jobId, [Validators.required]],
        reviewComments: [this.selectedJob.reviewComments]
      });
    }
  }

  //unsubscribe from job summary subscription
  ngOnDestroy(): void {
    this.jobSummarySubscription.unsubscribe();
  }

  //on page change, make a request to get the job details, unsubscribe after first emit (take(1))
  pageChanged(event, dataSetId): void {
    const request: ETLJobDetailRequest = {
      jobId: this.jobId,
      pageNumber: event.pageIndex + 1,
      pageSize: 50,
      specificallyNeededResultSetId: dataSetId
    };

    this.adminService
      .getETLJobDetails(request)
      .pipe(take(1))
      .subscribe((jobDetailResponse) => {
        //todo : make an enum to make this more clear and readable
        if (dataSetId == 1) {
          this.jobDetails.tblETLJobDetails1 = jobDetailResponse.tblETLJobDetails1;
        } else if (dataSetId == 2) {
          this.jobDetails.tblETLJobDetails2 = jobDetailResponse.tblETLJobDetails1;
        } else if (dataSetId == 3) {
          this.jobDetails.tblETLJobDetails3 = jobDetailResponse.tblETLJobDetails1;
        } else if (dataSetId == 4) {
          this.jobDetails.tblETLJobDetails4 = jobDetailResponse.tblETLJobDetails1;
        } else if (dataSetId == 5) {
          this.jobDetails.tblETLJobDetails5 = jobDetailResponse.tblETLJobDetails1;
        }
      });
  }

  //handles submission of reviewed job to the service
  onSubmitReviewedFormGroup(): void {
    const reviewedRequest: JobReviewedRequest = {
      jobId: this.jobId,
      reviewComments: this.reviewComments.value,
      reviewedBy: this.currentUser.username
    };

    //kill this subscription.
    this.adminService.markJobAsReviewed(reviewedRequest).subscribe();
  }

  makeETLJobDetailsRequest(): void {
    const request: ETLJobDetailRequest = {
      jobId: this.jobId,
      pageNumber: 1,
      pageSize: 50,
      specificallyNeededResultSetId: null
    };
    //make request to service with job id
    this.adminService
      .getETLJobDetails(request)
      .pipe(take(1))
      .subscribe((jobDetailResponse) => {
        this.jobDetails = jobDetailResponse;
      });
  }
  //shorthand for reviewComments on the form group
  get reviewComments(): UntypedFormControl {
    if (this.reviewedFormGroup != null) {
      return this.reviewedFormGroup.get("reviewComments") as UntypedFormControl;
    }
    return null;
  }

  //Create task -
  createTaskForReviewRecord(changeRecord: any, reason: string): boolean {
    const featurese = [];
    const features = new this.Graphic();

    features.setAttributes({
      WorkOrderType: "1",
      Status: "Open",
      // todo: Get current user.
      CreatedBy: this.currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      Priority: 0,
      //probably wont work, need to get current region somehow.
      Region: "SantaClaraCo",
      // todo: Get current user
      Worker: this.currentUser.username,
      Reason: "Other",
      Activity: "Inspect",
      EstimatedDuration: 420,
      SheduledDate: null,
      AssetID: changeRecord.assetId,
      AssetName: this.getAssetNameById(changeRecord.assetId),
      WorkOrderDesc: `Inspect the asset to determine if it was ${reason}.`,
      Comment: `This task was generated from the Change Report.`,
      GeomWKT: `{"x":${changeRecord.geolocation.split(",")[1].trim()}, "y":${changeRecord.geolocation
        .split(",")[0]
        .trim()},"spatialReference":{"wkid":4326,"latestWkid":4326}}`
    });
    featurese.push(features);

    this.ftrLyrTbl
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          // updateing MaintID for newly inserted Maintanance record
          const featureseAssetID = [];
          const featuresA = new this.Graphic();
          featuresA.setAttributes({
            OBJECTID: newrow[0].objectId,
            MaintID: "MNT_" + newrow[0].objectId
          });
          const maintenanceRelId = "MNT_" + newrow[0].objectId;
          featureseAssetID.push(featuresA);
          this.ftrLyrTbl
            .applyEdits(null, featureseAssetID, null)
            .then(
              function () {
                const actfeaturese = [];
                const actfeatures = new this.Graphic();

                actfeatures.setAttributes({
                  // updateing MaintID for newly inserted Maintanance record
                  MaintID: maintenanceRelId,
                  // ?
                  Asset: 0,
                  Activity: "Inspect",
                  //todo : dynamically generate
                  Description: "Inspect the asset to determine if it was removed",
                  // todo : get current user
                  ModifiedBy: "atla_sa1"
                });
                actfeaturese.push(actfeatures);
                this.ftrLyrActivity
                  .applyEdits(actfeaturese, null, null)
                  .then(
                    function () {
                      this.eventEmitersService.emitTaskCreated.emit(true);
                      this.showCreateMesage();
                    }.bind(this)
                  )
                  .catch(
                    function (error) {
                      console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                      console.log("error = ", error);
                      this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
                    }.bind(this)
                  );
              }.bind(this)
            )
            .catch(
              function (error) {
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                console.log("error = ", error);
                this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
              }.bind(this)
            );
          // End
        }.bind(this)
      )
      .catch((error) => {
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        console.log("error = ", error);
      });

    return true;
  }
  getAssetNameById(assetId: string): string {
    if (assetId.includes("SIG")) {
      return "Sign";
    } else if (assetId.includes("LGT")) {
      return "Light";
    } else if (assetId.includes("SUP")) {
      return "Support";
    } else if (assetId.includes("PVM")) {
      return "PavementMarking";
    } else if (assetId.includes("PVS")) {
      return "PavementMarking_Symbol";
    }
    return "Unknown";
  }
  showCreateMesage(): void {
    try {
      Swal.fire(this.translateService.instant("Select.createtask.taskcreate"));
    } catch (error) {
      console.error(error);
    }
  }
}
