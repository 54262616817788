import { Injectable, Inject } from "@angular/core";
import { TRANSLATIONS } from "src/app/shared/translation/translation"; // import our opaque token
import { AuthenticationService } from "src/app/services/authentication.service";

@Injectable()
export class LoginTranslateService {
  public currentLang: string;

  public get currentLangGet() {
    return this.currentLang;
  }

  // inject our translations
  constructor(@Inject(TRANSLATIONS) public translations: any, private authenticationService: AuthenticationService,) {}

  public use(lang: string): void {
    // set current language
    this.currentLang = lang;
  }

  private translate(key: string): string {
    // private perform translation
    const translation = key;

    if (!this.currentLang) {
      this.currentLang = localStorage.getItem("currentUser_language");//TSDRSAM-1927
    }
    if (this.translations[this.currentLang] && this.translations[this.currentLang][key]) {
      return this.translations[this.currentLang][key];
    }

    return translation;
  }

  public instant(key: string) {
    // call translation
    return this.translate(key);
  }
}
