<!--<h2>Login</h2>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="username">Username</label>
        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Login
        </button>
    </div>
</form>-->
<div class="scroll">
<div id="loginWraper">
  <div class="container login_page">
    <div class="loginPage">
     
  
      <div class="mt-5" role="banner">
        <div class="row">
          <div class="col-md-12 text-center">
            <div><img src="assets/images/logo60px.svg" alt="Logo Of Sign Management System" class="loginLogo"></div>
            <!-- <h6 class="subtitle">{{'Login.logo.name'|ltranslate}}</h6>  -->
          </div>
         
        </div>
      </div>
  
      
        <div class="row" role="main">
           <div class="col-lg-4 offset-lg-4">
            <div class="card border mb-3 rounded shadow shadow-sm">
              <div class="card-body">
                <!-- JIRA ID:-47 & JIRA ID:-56 -->
                <div *ngIf="loginattemptErrMsg" class="alert alert-danger" role="alert" id="errloginattempt" aria-label="invalid login attempt">
                  {{loginattemptErrMsg}}
                </div>
                <!-- <h2 class="card-title mb-4" *ngIf="!loginattemptErrMsg">{{'Login.loginbox.heading'|ltranslate}}</h2> -->
  
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                 
                  <mat-form-field #username appearance="outline" floatLabel="always" style="width: 100%;">
                   
                    <mat-label style="background: white;"  *ngIf="username._control.value.length >0">{{'Login.loginbox.userplaceholder'|ltranslate}}</mat-label>
                    <mat-label style="background: white;"  *ngIf="username._control.value.length === 0">{{'Login.loginbox.required'|ltranslate}}</mat-label>
                    
                    <input  class="text-align-left"  id="txtuser" matInput formControlName="username" type="text"
                      placeholder="{{'Login.loginbox.userplaceholder'|ltranslate}}" aria-label="Username" />
                    <mat-error *ngIf="f.username.invalid && submitted||f.username.touched" class="form-text-small">
                      <div *ngIf="f.username.errors" aria-invalid="true" aria-errormessage="Please enter username"
                        class="text-align-left">
                        {{'Login.alert.username'|ltranslate}}
                      </div>
                    </mat-error>
                  </mat-form-field>
                  
               
                  <mat-form-field #password appearance="outline" floatLabel="always" style="width: 100%;">
                    <mat-label style="background: white;" *ngIf="password._control.value.length >0">{{'Login.loginbox.passwordplaceholder'|ltranslate}}</mat-label>
                    <mat-label style="background: white;" *ngIf="password._control.value.length === 0">{{'Login.loginbox.required'|ltranslate}}</mat-label>
                    <input class="text-align-left" id="txtpassword" matInput formControlName="password" type="password"
                      placeholder="{{'Login.loginbox.passwordplaceholder'|ltranslate}}" (keyup)="keyPress($event)"
                      aria-label="Password" />
                    <mat-error *ngIf="f.password.invalid && submitted||f.password.touched" class="form-text-small">
                      <div *ngIf="f.password.errors" aria-invalid="true" aria-errormessage="Please enter password"
                        class="text-align-left">
                        {{'Login.alert.password'|ltranslate}}
                      </div>
                    </mat-error>
                    <p class="Capsalert" *ngIf="capslockOn">Capslock is On</p>
                  </mat-form-field>
               
                  <div style="display: flex; justify-content: space-between;" >
                    <button aria-label="Please use Up/Down arrow keys for veiwing language list" mat-button [matMenuTriggerFor]="menu"
                      class="export-type-btn small" type="button" >
                      <span class="flag-icon flag-icon-{{codelang}}"></span> {{selectedLang}} <mat-icon
                        [ngStyle]="{'vertical-align': 'baseline'}" matSuffix svgIcon="arrow-up-down"></mat-icon></button>
                    <mat-menu tabindex="0" #menu="matMenu" aria-label="Please use TAB for navigating through list items" id="languagemenu">
                      <button aria-label="English Language Button" mat-menu-item class="small"
                        (click)="valueClick('English');selectLang('us')"><span class="flag-icon flag-icon-us"></span> English <mat-icon
                          *ngIf="selectedLang=='English'" svgIcon="check"></mat-icon></button>
                      <button aria-label="Español Language Button" mat-menu-item class="small"
                        (click)="valueClick('Español');selectLang('es')"><span class="flag-icon flag-icon-es"></span> Español <mat-icon
                          *ngIf="selectedLang=='Español'" svgIcon="check"></mat-icon></button>
                      <button aria-label="Deutsche Language Button" mat-menu-item class="small"
                        (click)="valueClick('Deutsche');selectLang('de')"><span class="flag-icon flag-icon-de"></span> Deutsche <mat-icon
                          *ngIf="selectedLang=='Deutsche'" svgIcon="check"></mat-icon></button>
                      <button aria-label="Português Language Button" mat-menu-item class="small"
                        (click)="valueClick('Português');selectLang('pt')"><span class="flag-icon flag-icon-pt"></span> Português<mat-icon
                          *ngIf="selectedLang=='Português'" svgIcon="check"></mat-icon></button>
                      <button aria-label="Hindi Language Button" mat-menu-item class="small"
                        (click)="valueClick('Hindi');selectLang('in')"><span class="flag-icon flag-icon-in"></span> Hindi<mat-icon
                          *ngIf="selectedLang=='Hindi'" svgIcon="check"></mat-icon></button>
                    </mat-menu>
                    <button [disabled]="loginForm.invalid" [attr.aria-label]="loginArialabel" type="submit"
                      class="h7 primary-button float-right" mat-button id="btnLogin">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 mb-1"></span>
                      {{'Login.loginbox.button'|ltranslate}}
                    </button>
                  </div>
                </form>
              </div>
  
              <!-- Overlay -->
              <div class="card-img-overlay card rounded shadow shadow-sm text-white  bg-dark d-none" style="position: absolute;width: 100%;opacity: 0.85;background-color: #000 !important; ">
                <div class="align-self-center text-center">
                  <div class="mt-4">
                      <fa-icon [icon]="faTimesCircle" style='font-size:77px;color:#fff'></fa-icon>
                  </div>
                  <p class="mt-5" style="line-height: 1.7;">{{'Login.loginvalidate.text1'|ltranslate}}.<br> {{'Login.loginvalidate.text2'|ltranslate}}</p><br><br>
  
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a class="nav-link text-white" href="#">{{'Login.loginvalidate.tryagain'|ltranslate}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link text-white" href="#">{{'Login.loginvalidate.forgotpassword'|ltranslate}}</a>
                    </li>
                  </ul>
                </div>
                <!--/card-block-->
              </div>
            </div>
          </div>
        </div>
  
  
        <footer class="col-sm-12 mx-auto text-center">
          <ul class="footer px-0">
            <li><a href="https://www.3m.com/3M/en_US/company-us/legal-information/">{{'Login.loginpage.legaltext'|ltranslate}}</a></li>
            <li><a href="https://www.3m.com/3M/en_US/company-us/privacy-policy/">{{'Login.loginpage.privacytext'|ltranslate}}</a></li>
            <li><a href="https://www.3m.com/3M/en_US/company-us/dmca-copyright-policy/">DMCA</a></li>
            <li class="pr-0">© 3M 2023 {{'Login.loginpage.rightstext'|ltranslate}}</li>
          </ul>
        </footer>
      </div>
  
  
    </div>
  </div>
  </div>
  
  <!-- Modal login successful-->
  <div class="modal fade " id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="width: 300px; height: 700px;" role="document">
      <div class="modal-content align-self-center text-center">
        <div class="mt-4 mb-4">
          <fa-icon [icon]="faCheckCircle" class="colorLoginIcon" style="font-size: 70px;"></fa-icon>
        </div>
        <div class="border border-secondary"></div>
        <div class="modal-body">
          <div class="h4 mb-2"> {{'Login.loginsuccess.welcome'|ltranslate}} </div>
          <div class="h6">{{'Login.loginsuccess.msgtext'|ltranslate}}!</div>
          <button type="button" class="btn btn-success btn-lg loginBtnColor mt-5 mb-4 " data-dismiss="modal">{{'Login.loginsuccess.getstartedbtn'|ltranslate}}</button>
        </div>
      </div>
    </div>
  </div>
  <ng-template class='h-100' #tocmodalpopup let-c="close" let-d="dismiss">
    <div class="modal-header">
      <div class="modal-title">
        <h2><b>{{'Login.afterlogin.term&condition'|ltranslate}}</b></h2>
      </div>
    </div>
    <div class="modal-body h-100 w-100">
      <div id="divtoc">
  
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" data-dismiss="modal" (click)="acceptTOC()">{{'Login.afterlogin.acceptbtn'|ltranslate}}</button>
    </div>
  </ng-template>
  
  <ng-template class='h-100' #ChangePasswordForm let-c="close" let-d="dismiss">
    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
      <div class="modal-header">
        <div class="modal-title">
          <h2><b>{{'Login.newlogin.changepasstxt'|ltranslate}}</b></h2>
        </div>
        <button type="button" class="close clsbtn" (click)="myFunction(c)" data-dismiss="modal">&times;</button>
      </div>
    <div class="modal-body h-100 w-100">
        <div class="form-group">
          <label>{{'Login.newlogin.oldpasstxt'|ltranslate}}</label>
          <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control"
            [ngClass]="{ 'is-invalid': cpsubmitted && ChangePWD.oldPwd.errors }" />
          <div *ngIf="cpsubmitted && ChangePWD.oldPwd.errors" class="invalid-feedback">
            <div *ngIf="ChangePWD.oldPwd.errors.required">{{'Login.newlogin.oldpassdesc'|ltranslate}}</div>
          </div>
        </div>
        <div class="form-group">
          <label>{{'Login.newlogin.newpasstxt'|ltranslate}}</label>
          <input formControlName="newPwd" id="newPwd" type="password" class="form-control" [ngClass]="{ 'is-invalid':  ChangePWD.newPwd.touched && (!ChangePWD.newPwd.hasError('required'))  && (ChangePWD.newPwd.errors || (changePasswordForm.errors && (changePasswordForm.errors.oldPassSame ||changePasswordForm.errors.passUsernameSame)))  }"/>

          <div class="invalid-feedback">
            <div *ngIf="ChangePWD.newPwd.touched && ChangePWD.newPwd.errors && !ChangePWD.newPwd.hasError('required')">
              {{'Login.alertuseradmin.InvalidPassword'|ltranslate}}</div>
            <div *ngIf="ChangePWD.newPwd.touched && changePasswordForm.errors && changePasswordForm.errors.oldPassSame">
              {{'Login.changepwdvalidate.newandold'|ltranslate}}</div>
            <div *ngIf="changePasswordForm.errors && changePasswordForm.errors.passUsernameSame">
              {{'Login.changepswd.passUsernameSame'|ltranslate}}</div>
          
          </div>
        </div>

        <div class="form-group">
          <label>{{'Login.changepwdvalidate.confrmpwd'|ltranslate}}</label>
          <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control"
            [ngClass]="{ 'is-invalid': ChangePWD.confirmPwd.touched && (ChangePWD.confirmPwd.errors || (changePasswordForm.errors && changePasswordForm.errors.confirmPassSame)) }" />
          <div class="invalid-feedback">
            <div *ngIf="ChangePWD.confirmPwd.errors && ChangePWD.confirmPwd.errors.required">
              {{'Login.changepwdvalidate.confrmpwddesc'|ltranslate}}</div>
            <div *ngIf="changePasswordForm.errors && changePasswordForm.errors.confirmPassSame">
              {{'Login.changepwdvalidate.confrmpwddesc2'|ltranslate}}</div>
          </div>
        </div>
      
    </div>
    <div class="modal-footer">
      <button [disabled]="changePasswordForm.invalid" type="button" class="btn btn-success"
        type="submit">{{'Login.changepswd.changepswdbtn'|ltranslate}}</button>
    </div>
  </form>
  </ng-template>
  