import * as deepEqual from "deep-equal";
/** Imports */
import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewEncapsulation } from "@angular/core";
import { AssetAttributesService } from "../services/assetAttibutes.service";
import { AssetDetailService } from "../services/assetDetail.service";
import { AuthenticationService } from "../services/authentication.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { ILayer } from "../interface/layer";
import { IUser } from "../interface/user";
import { QueryBuilder } from "../services/queryBuilderService.service";
import { QueryBuilderConstants } from "./queryBuilderConstants";
import { QueryLayers } from "../services/queryLayers.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
// Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
import { Globals } from "../select/globals";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

import { EventEmitersService } from "../services/eventEmiters.service";
import { GenericMapMethodsService } from "../services/generic-map-methods.service";
import { faCheckCircle, faDownload, faUpload, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faSave, faPlusSquare, faMinusSquare, faEdit as faPenSquare, faTrashAlt, faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";

//Added for visualization layers
import * as xml2js from "xml2js";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { DropdownListItem, Layer } from "../interface/DropDownListItem";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
/** END */
export interface Querytable {
  jointype: string;
  layer: string;
  layerDisplay: string;
  field: "";
  operator: string;
  value: string;
  assetCodeType: any[];
}
@Component({
  selector: "sms-querybuilder",
  templateUrl: "./querybuilder.component.html",
  styleUrls: ["./querybuilder.component.scss"],
  styles: [
    `
      :host::ng-deep .ng-select .ng-select-container {
        top: -4px;
        left: -8px;
        border: 0px solid #ccc;
        min-height: 10px;
        height: 26px !important;
      }
      :host::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items {
        background-color: #ffffff !important;
        top: -1px;
        text-align: left;
      }
    `
  ]
})
export class QuerybuilderComponent implements OnInit {
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["type"] === "checkbox" && event.target["id"] === "customCheck1") {
      if ((<HTMLInputElement>event.target).checked) {
        (<HTMLInputElement>event.target).checked = false;
      } else {
        (<HTMLInputElement>event.target).checked = true;
      }
    }
    if (event.target["localName"] === "div" && event.target["id"] === "importFileDiv") {
      let fileElementObj = (<HTMLInputElement>event.target).children[0];
      (<HTMLInputElement>fileElementObj).click();
    }
  }
  //
  constructor(
    // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
    public objGlobals: Globals,
    public qb: QueryBuilder,
    public objAssetDetailService: AssetDetailService,
    public query: QueryLayers,
    public genericService: GenericLayerService,
    public assetAttributesService: AssetAttributesService,
    public auth: AuthenticationService,
    public translateService: TranslateService,
    public eventEmitersService: EventEmitersService,
    public constants: QueryBuilderConstants,
    public genericMapMethodsService: GenericMapMethodsService,
    public esriTools: EsriMapComponent // Added for visualization layers
  ) {
    this.currentUser = this.auth.currentUserValue;
    this.isFromQB.emit(true);
  }
  event: any;
  eventChecked: any;
  // JIRA ID:-61
  isShown = true; // hidden by default
  codedValueControl = new UntypedFormControl();
  codedValues: any[] = [];
  filteredOptions: Observable<DropdownListItem[]>;
  @Input() Query: any;
  @Input() FeatureLayer: any;
  @Input() drawTool: any;
  @Input() all: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() Color: any;
  @Input() SimpleFillSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() Graphic: any;
  @Input() GraphicsLayer: any;
  @Output() data = new EventEmitter();
  @Output() closeQueryBuilderEvent = new EventEmitter();
  @Output() selectedOptions = new EventEmitter();
  @Input() map: any;
  @Input() QueryTask: any;
  @Input() queryBuilderTable: any;
  @Output() queryBuilder = new EventEmitter();
  @Input() RelationshipQuery: any;
  @Input() PictureMarkerSymbol: any;
  @Input() webMercatorUtils;
  @Input() Point;
  @Input() graphicsUtils;
  @Output() indexdata = new EventEmitter();
  @Output() minimize = new EventEmitter();
  @Output() layerIndex = new EventEmitter();
  public querytableArr: any[] = [];
  public querytable: Querytable = {} as Querytable;
  public layerList: any[] = [];
  public inventoryLayersData: ILayer[] = [] as ILayer[];
  public selectGraphicslayer: any;
  public isRowClicked = false;
  public queryTableRow: any;
  public loading = false;
  public field: any[] = [];
  public fields;
  public lyrfld: any[] = [];
  public filteredCodedValues: any[] = [];
  public centered = true;
  public reportFormatDropdown: DropdownListItem[] = [];
  public reportValuesDropdown: DropdownListItem[] = [];
  public joinTypeDropdown: DropdownListItem[] = [];
  public layerDropdown: Layer[] = [];
  public fieldDropdown: DropdownListItem[] = [];
  public operatorDropdown: DropdownListItem[] = [];
  public valueDropdown: DropdownListItem[] = [];
  public distanceDropdown: DropdownListItem = { id: "", name: "", code: "" };

  expand = this.translateService.instant("Select.carddisplay.expand");
  minimizeTooltip = this.translateService.instant("Select.carddisplay.minimize");
  public layr;
  public yrl;
  public operator: string;
  public querydomain = {};
  public codedvalue: any;
  public oprtrs: any[] = [];
  public oprtr: any[] = [];
  public jointypes: any[] = [];
  public jointype: string;
  public LayersFromServices = [];
  public result: any;
  public layersfields: any;
  public whereExp: any;
  public selectionGraphicsLyr: any;
  public selectedlayr;
  public fieldArray: any[] = [];
  public newAttribute: any = {};
  public chkMapExtent = false;
  public loadingQc = false;
  public removeselect = false;
  public closeQueryBuilderDialog = false;
  public selectedRow: any;
  public currentUser: IUser;
  public queryDataObj = [];
  public selectbehaviorobj;
  public ftrLayer;
  public selectBehaveValues: any = [];
  public enableDateTimePicker = false;
  public predefinedQuerisList: any[] = [];
  public fileName: string;
  public reportValues: any[] = [];
  public reportFormat: any[] = [];
  public isResultPresent = false;
  public pdfConig: any;
  public reportFormatES: any;
  public savedQueriesList: any[] = [];
  public savedQueriesListDropdown: DropdownListItem[] = [];
  public selectedLoadFile: string;
  public selectedPredefinedQuery: string;
  public selectedObjIdsByMapExtent = [];
  public reportTypeES: string;
  public objectIds: any[] = [];
  public showExportPopup = false;
  public tableBody: any;
  public selectedReportType: any;
  public todayDate = new Date();
  public dataLength: any;
  public predefinedSelected = false;
  public multiplePrimaryLayerQuery: any;
  public index: any;
  public firstReport = false;
  public prevSelected: any;
  public createdQuery: any = null;
  public aliasData: any[] = [];
  public clickedValidate = false;
  public clickedRun = false;
  public sqlgetSavedQueries = [];
  public sqlViewOnMap = false;
  public sqlViewOnExcel = false;
  public Primarylangvalid;
  inBounds = true;
  asstClicked = true;
  public Secodarylangvalid;
  public scndlyr = false;
  public isQueryBuilderMinimized = false;
  faPlusSquare = faPlusSquare;
  faPencilSquare = faPenSquare;
  faMinusSquare = faMinusSquare;
  faTrash = faTrashAlt;
  faCalendar = faCalendar;
  faSave = faSave;
  faUpload = faUpload;
  faCheckCircle = faCheckCircle;
  faDownload = faDownload;
  faInfoCircle = faInfoCircle;
  // Fix for QB cardviewer
  @Output() indexCV = new EventEmitter();
  // @Output() layerIndex = new EventEmitter();

  /**Query Creator */
  public sqlResultCount: any;
  public queryValidatedFromServer: any;
  public countWhere = 0;
  @Output() isFromQB = new EventEmitter();

  /**Save from query table to database */
  public clickedOnSave = true;
  public count;
  public selectedQueryLayersList = [];

  /**
   * New CR Code
   */

  people: any[] = [];
  peopleLoading = false;
  selectedOpentasksJSON;
  //Added for Visualization layers
  visualizationLayersData;
  visualizationFieldsData = [];
  isVisualizationLyrSelected = false;
  selectedVisualizationlyr;
  distanceValue = "";
  isAssetLyrSelected = false;
  queryResultsData;
  public showCardViewForVisualizationLayer = false;
  filterVisualizationData = false;
  querytableUpdated: any[] = [];
  disableJoinTypeDropdown = true;
  minimizeQueryBuilder() {
    this.isQueryBuilderMinimized = !this.isQueryBuilderMinimized;
    this.minimize.emit(this.isQueryBuilderMinimized);
  }
  ngOnInit() {
    this.distanceDropdown.id = "Distance";
    this.distanceDropdown.name = "Distance";
    this.distanceDropdown.code = "Distance";
    this.selectedQueryLayersList = [];
    //Added for visulization layers
    this.visualizationLayersData = this.map.visualizationLayers;
    //
    /** Language function */
    this.genericService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;

      if (this.Primarylangvalid === this.currentUser.languageID) {
        this.scndlyr = false;
      } else if (this.Secodarylangvalid === this.currentUser.languageID) {
        this.scndlyr = true;
      }
    });
    this.filteredOptions = this.codedValueControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    /**Load Predefined Queries */
    this.predefinedQuerisList = this.getPredefinedQueries();
    /**Save Functionality for QueryBuilder and QueryCreator */
    this.clickedOnSave = true;
    this.savedQueriesList = this.getSavedQueries("QB");
    this.savedQueriesListDropdown = this.savedQueriesList.map((query) => {
      const dropdownItem: DropdownListItem = {} as DropdownListItem;
      dropdownItem.name = query.fileName;
      dropdownItem.id = query.fileName;
      return dropdownItem;
    });
    this.sqlgetSavedQueries = this.getSavedQueries("QC");

    /**Default Behaviour is NEW SELECTION */
    this.selectbehaviorobj = this.FeatureLayer.SELECTION_NEW;

    /**Retain Table Values when QB is closed */
    this.querytableArr = this.queryBuilderTable;

    /** by default first layer */
    this.selectGraphicslayer = this.map.smsLayerdata.graphicLayers[0];
    this.qb.getsldata(this.Query, this.QueryTask, this.FeatureLayer).then(
      function (result) {
        this.aliasData = result.features;
        this.inventoryLayersData = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
        /**Initialize Service Variables */
        this.initQBServiceVariables();
        /**Get layers , operators , jointype and values from QBService : Neha 1/10*/
        this.operatorDropdown = this.constants.getOperators();
        console.log(this.operatorDropdown);
        this.joinTypeDropdown = this.constants.getJoinType().map((join) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = join.name;
          dropdownItem.id = join.name;
          return dropdownItem;
        });
        this.setLayerListDropdown();
        this.oprtrs = this.constants.getOperators();
        this.jointypes = this.constants.getJoinType().map((type) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = type.name;
          dropdownItem.id = type.name;
          return dropdownItem;
        });
        this.jointypes = this.constants.getJoinType();
        this.selectBehaveValues = this.constants.getSelectBehaviorValues();
        this.reportFormat = this.constants.getReportFormat();
        this.reportFormatDropdown = this.reportFormat.map((val) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = val.name;
          dropdownItem.id = val.id;
          return dropdownItem;
        });
        this.reportFormatES = this.reportFormat[0].name;
        this.defaultDropdownValues();
      }.bind(this)
    );
    /**Get Fields for layers */
    this.assetAttributesService.getAssetAttributes().subscribe((result: any) => {
      this.layersfields = [];
      result.features.forEach((element) => {
        this.layersfields.push(element.attributes);
      });
      //Jira Id-931
      if (this.selectedlayr != null) {
        //TSDRSAM-1792
        this.field = this.layersfields.filter((res) => res.TableName.toLowerCase() === this.selectedlayr.id.toLowerCase() && res.QueryVisible === 1);
        this.fieldDropdown = this.field.map((field) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = this.scndlyr == false ? field.FieldAlias : field.FieldAlias_SL;
          dropdownItem.id = field.Field;
          return dropdownItem;
        });
      }
      if (this.field && this.field.length > 0) {
        this.fields = this.field[0].Field;
      }
    });

    this.people = [
      {
        name: "Karyn Wright",
        gender: "female"
      },
      {
        name: "Rochelle Estes",
        disabled: true,
        gender: "female"
      },
      {
        name: "Mendoza Ruiz",
        gender: "male"
      }
    ];
  }
  defaultDropdownValues() {
    try {
      this.selectedlayr = this.layerDropdown[0]; //Updated for visualization layers
      this.layr = this.layerList.filter((astCls) => astCls.Layer === this.selectedlayr.id); //Updated for visualization layers
      this.operator = "=";
    } catch (error) {
      console.error(error);
    }
  }
  initQBServiceVariables() {
    try {
      this.qb.map = this.map;
      this.qb.Query = this.Query;
      (this.qb.QueryTask = this.QueryTask), (this.qb.RelationshipQuery = this.RelationshipQuery);
      (this.qb.inventoryLayersData = this.inventoryLayersData), (this.qb.FeatureLayer = this.FeatureLayer);
      this.qb.aliasData = this.aliasData;
    } catch (error) {
      console.error(error);
    }
  }
  closeQueryBuilder() {
    try {
      /**Close QUery Builder and retain the avlue of  non cleared data  */
      this.queryBuilder.emit(this.querytableArr);
      this.closeQueryBuilderEvent.emit(false);
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      if (this.queryDataObj.length === 0) {
        this.eventEmitersService.setEnableSingleClick(true);
        this.genericMapMethodsService.queryBuilderZoomExtent = false;
      }
      // For ADA story - by Subba
      let mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
      if (mainPageTools.length > 0) {
        mainPageTools.forEach((element) => {
          if (element.localName === "a") {
            element.setAttribute("tabindex", "0");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**Query Builder On Change Events */
  onJoinChange(event) {
    this.jointype = event;
    //Added for visualization layers
    if (this.jointype == "Distance") {
      if (this.isVisualizationLyrSelected) {
        //Allow only asset layers
        for (let i = 0; i < this.layerDropdown.length; i++) {
          const layerData = this.layerList[i];
          this.layerDropdown[i].disabled = true;
          if (!layerData.isVisualizationLayer) {
            this.layerDropdown[i].disabled = false;
          }
        }
      } else {
        //Enable only visualization layers
        for (let i = 0; i < this.layerDropdown.length; i++) {
          const layerData = this.layerList[i];
          this.layerDropdown[i].disabled = false;
          if (layerData.isVisualizationLayer) {
            this.layerDropdown[i].disabled = true;
          }
        }
      }
    } else {
      for (let i = 0; i < this.layerDropdown.length; i++) {
        const layerData = this.layerList[i];
        this.layerDropdown[i].disabled = false;
        if (layerData.disabled) {
          this.layerDropdown[i].disabled = true;
        }
      }
    }
  }
  onLayerChange(event) {
    try {
      const layer = this.layerDropdown.find((lyr) => lyr.id == event); //TSDRSAM-1792
      //Added for visualization layers
      if (layer.isVisualizationLayer) {
        this.selectedlayr = layer;
        this.getWMSFieldInfo(event).then((res) => {
          return [];
        });
      } else {
        //Added for visualization layers
        this.selectedVisualizationlyr = "";
        this.isVisualizationLyrSelected = false;
        this.isAssetLyrSelected = true;
        this.selectedlayr = layer; /**Set Selected Layer */

        if (this.selectedlayr.name) {
          /**Reset Fields and Values if layers is changed */
          this.fields = "";
          this.codedValues = [];
          // this.codedvalue = "";
          this.codedvalue = null;
          this.layr = this.layerList.filter((astCls) => astCls.Layer === this.selectedlayr.id);
          const query = this.Query();
          query.where = "1=1";
          const queryTask = new this.QueryTask(this.layr[0].layer_id.lyrObj.url);
          queryTask.execute(query).then((count) => {
            this.count = count.features.length;

            if (this.count !== 0) {
              //Jira Id-931,//TSDRSAM-1792
              this.field = this.layersfields.filter((res) => res.TableName.toLowerCase() === this.selectedlayr.id.toLowerCase() && res.QueryVisible === 1);
              this.fieldDropdown = this.field.map((field) => {
                const dropdownItem: DropdownListItem = {} as DropdownListItem;
                dropdownItem.name = this.scndlyr == false ? field.FieldAlias : field.FieldAlias_SL;
                dropdownItem.id = field.Field;
                return dropdownItem;
              });
              return this.field;
            }

            Swal.fire("No records found");
            this.emptySearchFields();
          });
          return [];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  onFieldChange(event) {
    try {
      this.fields = event;
      //Added for visualization layers
      if (this.isVisualizationLyrSelected) {
        this.enableDateTimePicker = false;
        if (this.fields.includes("Date")) {
          this.enableDateTimePicker = true;
        }
      }

      this.peopleLoading = true;
      this.operator = "=";
      this.lyrfld = this.layr[0].layer_id.lyrObj._fields.filter((fld) => fld.alias === this.fields);
      this.codedValues = [];
      this.filteredCodedValues = [];
      this.codedvalue = null;
      this.codedValueControl.setValue(null);
      this.enableDateTimePicker = false;
      const query = new this.Query();
      query.where = "1=1";
      query.outFields = [this.fields];
      query.returnGeometry = false;
      query.returnDistinctValues = true;
      const queryTask = new this.QueryTask(this.layr[0].layer_id.lyrObj.url);
      queryTask.execute(query).then((res) => {
        if (this.constants.userFields.includes(this.fields)) {
          const codedValuesES = [];
          let workers;
          this.qb.getWorkerData(this.fields, this.Query, this.QueryTask, this.layr, this.currentUser).then((result) => {
            workers = result;
            workers.forEach((worker) => {
              codedValuesES.push({ name: worker.displayName, code: worker.displayName, value: worker.userId });

              if (codedValuesES.length === workers.length) {
                this.codedValues = codedValuesES.filter((cv) => cv.code != null);
                this.filteredCodedValues = this.codedValues;
                console.log(this.codedValues);
              }
            });
          });
        }

        if (this.fields.includes("Date")) {
          this.enableDateTimePicker = true;
        } // UC  – 20134:

        if (this.fields === "Activity") {
          // for all primary layers if only activity is selected
          const baseurl = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
          const activityLayer = new this.FeatureLayer(baseurl);
          const promise = this.qb.getActivityDomains(activityLayer);
          const activityDomainsList = [];
          promise.then((results) => {
            activityDomainsList.push(results);
            this.codedValues = activityDomainsList[0];
            this.filteredCodedValues = this.codedValues;
            console.log(this.codedValues);
          });
        }

        if (this.lyrfld[0] && this.lyrfld[0].domain) {
          // 13-01-2021 Manikantha Start 24787
          this.lyrfld[0].domain.codedValues.forEach((codeValue) => {
            this.codedValues.push({ name: codeValue.name, id: codeValue.name, code: codeValue.code });
            this.filteredCodedValues.push({ name: codeValue.name, id: codeValue.name, code: codeValue.code });
          });
          // 13-01-2021 Manikantha End 24787
        } else {
          if (this.fields === "AssetSubType") {
            this.layr[0].layer_id.lyrObj.types.forEach((val) => {
              const matched = res.features.filter((f) => f.attributes[this.fields] === val.id);

              if (matched.length > 0) {
                this.codedValues.push({ name: val.name,id: val.id, code: val.id });
                this.filteredCodedValues.push({ name: val.name,id: val.id, code: val.id });
              }
            });
            console.log(this.codedValues);
          }

          if (this.fields === "AssetCode") {
            this.layr[0].layer_id.lyrObj.types.forEach((val) => {
              val.domains.AssetCode.codedValues.forEach((code) => {
                const matched = res.features.filter((f) => f.attributes[this.fields] === code.code);

                if (matched.length === 1) {
                  this.codedValues.push({ name: code.name, id: code.name, code: code.code });
                  this.filteredCodedValues.push({ name: code.name, id: code.name, code: code.code });
                }
              });
            });
            console.log(this.codedValues);
          }
        }

        console.log(this.codedValues);
        this.peopleLoading = false;
        return this.codedValues;
      });
      return this.codedValues;
    } catch (error) {
      console.error(error);
    }
  }

  // Manikantha V 23/09/2020

  customSearchFn(event) {
    console.log(event);
    this.filteredCodedValues = this.codedValues.filter((item) => item.name.toLowerCase().includes(event.toLowerCase()));
  }
  //
  onValueChange(event) {
    try {
      if (event instanceof Date) {
        const month = event.getMonth();
        this.codedvalue = event.getFullYear() + "-" + (month + 1) + "-" + event.getDate();
        this.qb.selectedDate = event;
      } else {
        this.codedvalue = event;
      }
    } catch (error) {
      console.error(error);
    }
  }
  onOperatorChange(event) {
    this.operator = event;
  }
  onFileNameChange(event, queryTool) {
    this.selectedLoadFile = event;
    this.populateQueryTable(this.selectedLoadFile, queryTool);
  }
  selectPredefinedQuery(event, query) {
    try {
      this.eventChecked = event;
      this.multiplePrimaryLayerQuery = query;

      if (query.queryId !== "QRY_11" && query.queryId !== "QRY_10") {
        let queryString = query.queryString;
        const regionData = "Region" + this.currentUser.selectedRegion.regionLevel + "='" + this.currentUser.selectedRegion.regionName + "' AND";
        const splitRes = queryString.split(";");
        splitRes[0] += regionData;
        let newQueryString = [];
        newQueryString = splitRes.join(";");
        queryString = newQueryString;
        newQueryString = [];
        newQueryString.push(query);
        this.parseQuery(newQueryString);
      }
    } catch (error) {
      console.error(error);
    }
  }
  getSavedQueries(queryTool) {
    try {
      const userActionsQueryLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new this.FeatureLayer(userActionsQueryLayer);
      const promise = this.qb.getSavedQuery(userActionsQueryLayerObj, this.currentUser, this.Query, this.QueryTask, queryTool); // Populating Default Values in New Asset
      //const _this = this;
      const savedQueryNames = [];
      const savedQueryList = [];
      promise.then(function (result) {
        if (result) {
          savedQueryNames.push(result);
          savedQueryNames[0].features.forEach((savedQuery) => {
            if (savedQuery.attributes.QueryName != null) {
              savedQueryList.push({
                fileName: savedQuery.attributes.QueryName,
                queryString: savedQuery.attributes.Query
              });
            }
          });
        }
      });
      this.savedQueriesListDropdown = savedQueryList.map((query) => {
        const dropdownItem: DropdownListItem = {} as DropdownListItem;
        dropdownItem.name = query.fileName;
        dropdownItem.id = query.fileName;
        return dropdownItem;
      });
      return savedQueryList;
    } catch (error) {
      console.error(error);
    }
  }
  getPredefinedQueries() {
    try {
      const userActionsQueryLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new this.FeatureLayer(userActionsQueryLayer);
      const savedQueryNames = [];
      const savedQueryList = [];
      const promise = this.qb.getPredefinedQueries(userActionsQueryLayerObj, this.Query, this.QueryTask); // Populating Default Values in New Asset
      promise.then(function (result) {
        if (result) {
          savedQueryNames.push(result);
          savedQueryNames[0].features.forEach((savedQuery) => {
            savedQueryList.push({
              queryId: savedQuery.attributes.QueryID,
              queryString: savedQuery.attributes.Query,
              queryDescription: savedQuery.attributes.QueryDescription
            });
          });
        }
      });
      this.savedQueriesListDropdown = savedQueryList.map((query) => {
        const dropdownItem: DropdownListItem = {} as DropdownListItem;
        dropdownItem.name = query.fileName;
        dropdownItem.id = query.fileName;
        return dropdownItem;
      });
      return savedQueryList;
    } catch (error) {
      console.error(error);
    }
  }
  addItem() {
    try {
      //Validate if distance join type is already used
      if (this.jointype == "Distance" && this.querytableArr && this.querytableArr.filter((e) => e.jointype && e.jointype.includes("WITHIN")).length > 0) {
        Swal.fire(this.translateService.instant("Querybuilder.OnlyOneDistanceJoin"));
        return;
      }
      this.querytable = {} as Querytable;
      this.querytable.field = this.fields;
      this.querytable.layerDisplay = this.selectedlayr.name;
      this.querytable.layer = this.isVisualizationLyrSelected ? this.selectedVisualizationlyr : this.selectedlayr.id;
      this.querytable.operator = this.operator;
      if (this.jointype) {
        //TSDRSAM-1864
        this.querytable.jointype = this.querytableArr.length >= 1 ? this.jointype : undefined;
        if (this.jointype == "Distance") {
          if (this.distanceValue) {
            this.querytable.jointype = "WITHIN   " + this.distanceValue + "ft";
          } else {
            Swal.fire(this.translateService.instant("Querybuilder.DistanceRequired"));
            return;
          }
        }
      } else {
        this.querytable.jointype = undefined;
      }

      if (this.codedValueControl.value instanceof Date) {
        const formattedDate = this.codedValueControl.value.getMonth() + 1 + "-" + this.codedValueControl.value.getDate() + "-" + this.codedValueControl.value.getFullYear();
        this.querytable.value = formattedDate;
      } else {
        if (!this.isVisualizationLyrSelected && this.fields === "AssetCode") {
          this.querytable.assetCodeType = this.codedValues;
        }

        if (!this.isVisualizationLyrSelected && this.fields === "AssetSubType") {
          this.qb.assetCode = this.codedValues;
        }

        this.querytable.value = this.codedValueControl.value;
      }

      if (this.checkDuplicateQueryCriteria()) {
        return;
      }
      this.disableJoinTypeDropdown = false;
      //Added for visualization layers
      if (this.isVisualizationLyrSelected && this.selectedlayr.isVisualizationLayer) {
        this.querytableArr.push(Object.create(this.querytable));
        //Disable layer dropdown for other layers
        for (let i = 0; i < this.layerDropdown.length; i++) {
          const layerData = this.layerList[i];
          this.layerDropdown[i].disabled = false;
          if (!layerData.isVisualizationLayer || (layerData.isVisualizationLayer && layerData.Layer != this.selectedVisualizationlyr)) {
            this.layerDropdown[i].disabled = true;
          }
        }
        this.jointype = this.joinTypeDropdown[0].name; //TSDRSAM-1864
      } else {
        /**Create a new row to Insert into table */
        this.queryDataObj = [];
        /**Add selected data into table row */
        this.selectedQueryLayersList.push(this.selectedlayr.id); //TSDRSAM-1792

        if (this.querytableArr.length > 0 && !this.querytable.jointype) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.addjointype"));
          return;
        }
        if (this.querytable.value == null && this.codedValueControl.value != null) {
          this.querytable.value = this.codedValueControl.value;
        }
        // 27-01-2021 Manikantha Start 24820
        if (this.querytable.field && this.querytable.layer && this.querytable.operator && this.querytable.value != undefined && this.querytable.value != null && this.querytable.value !== "") {
          // 27-01-2021 Manikantha End 24820
          // Refresh DropdownList to Fetch related Tables : Neha 1/10
          if (this.querytable.layer !== "Activity" && this.querytable.layer !== "Assessment" && this.querytable.layer !== "Maintenance" && this.querytable.layer !== "Light" && this.querytable.layer !== "Sign") {
            const layerListRelated = this.qb.getDropDownValuesLayer(this.querytable.layer, this.inventoryLayersData, this.FeatureLayer);
            this.disableLayerDropdown(layerListRelated, ""); // Updated for Visualization layers
          }

          // End
          this.querytableArr.push(Object.create(this.querytable));

          /** Dont show Primary layers when Sign or Light is selectethis.querytable = <Querytable>{};d first */
          if (this.querytableArr.length === 1 && (this.querytableArr[0].layer === "Sign" || this.querytableArr[0].layer === "Light")) {
            const layerListRelated = this.qb.getDropDownValuesLayer("Support", this.inventoryLayersData, this.FeatureLayer);
            this.disableLayerDropdown(layerListRelated, this.querytableArr[0].layer); // Updated for Visualization layers
          }

          /** Dont show Primary layers or Asset Layers when Assessment, Maintenance or Activity Selected first */
          if (this.querytableArr.length === 1 && (this.querytableArr[0].layer === "Maintenance" || this.querytableArr[0].layer === "Assessment" || this.querytableArr[0].layer === "Activity")) {
            const layerListRelated = this.qb.getDropDownValuesLayer("Support", this.inventoryLayersData, this.FeatureLayer); // 8/20/20 jcc same code as previous if
            this.qb.disableLayerDropdown(layerListRelated, this.querytableArr[0].layer);
          }

          this.jointype = this.joinTypeDropdown[0].name; // 8/18/20 jcc set default Join Type after adding first criteria
        } else {
          // Default Layers List : Neha 1/10
          this.setLayerListDropdown();
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.enterallvalues"));
          this.disableJoinTypeDropdown = true;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkDuplicateQueryCriteria() {
    //TSDRSAM-1789
    //validation on duplicate query
    if (this.querytableArr.length === 1) {
      const joinTemp = this.querytable.jointype;
      this.querytable.jointype = undefined;
      const sameQueryValidation = this.querytableArr.filter((fil) => deepEqual(this.querytable, fil.__proto__));

      if (sameQueryValidation.length >= 1) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotbesame"));
        return true;
      }

      this.querytable.jointype = joinTemp;
    } else {
      let sameQueryValidation = this.querytableArr.filter((fil) => deepEqual(this.querytable, fil.__proto__));

      if (sameQueryValidation.length >= 1) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotbesame"));
        return true;
      }

      const joinTemp = this.querytable.jointype;
      this.querytable.jointype = undefined;
      sameQueryValidation = this.querytableArr.filter((fil) => deepEqual(this.querytable, fil.__proto__));

      if (sameQueryValidation.length >= 1) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotbesame"));
        return true;
      }

      this.querytable.jointype = joinTemp;
    }
    //end
  }

  emptySearchFields() {
    this.jointype = "";
    this.selectedlayr = {}; // Added for visualization layers
    this.codedvalue = null;
    this.codedValueControl.setValue(null);
    this.operator = "";
    this.fields = "";
    this.isVisualizationLyrSelected = false; // Added for visualization layers
    this.disableJoinTypeDropdown = true;
  }
  deleteFieldValue(i) {
    try {
      if (this.querytableArr.length > 1 && i === 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.cannotdeleteprimaryquery"));
        return;
      }

      this.querytableArr.splice(i, 1);
      this.queryDataObj = [];

      if (this.querytableArr.length === 0) {
        /**Default values in Search Fields */
        this.emptySearchFields();
        /**Join type should be null */
        this.querytable.jointype = null;
        /**Saved Query Filename text box empty */
        this.fileName = "";
        /**Reload all Saved Queries*/
        this.savedQueriesList = this.getSavedQueries("QB");
        this.savedQueriesListDropdown = this.savedQueriesList.map((query) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = query.fileName;
          dropdownItem.id = query.fileName;
          return dropdownItem;
        });
        this.sqlgetSavedQueries = this.getSavedQueries("QC");
        /*Empty Fields and Values*/
        this.field = [];
        this.codedValues = [];
        this.filteredCodedValues = this.codedValues;
        // Cannot edit Hide Button
        this.isRowClicked = false;
        // Clear Prev Selected for Query Behavior
        this.prevSelected = [];
        /**Reload Layers */

        this.setLayerListDropdown();
        // todo: selected layer
        this.selectedlayr = this.layerDropdown[0];
        //Jira Id-931,//TSDRSAM-1792
        this.field = this.layersfields.filter((res) => res.TableName.toLowerCase() === this.selectedlayr.id.toLowerCase() && res.QueryVisible === 1);
        this.fieldDropdown = this.field.map((field) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = this.scndlyr == false ? field.FieldAlias : field.FieldAlias_SL;
          dropdownItem.id = field.Field;
          return dropdownItem;
        });
        this.fields = this.field[0].Field;
        this.onFieldChange(this.fields);
      } else if (this.querytableArr.length >= 1) {
        this.disableLayerDropdown(this.querytable.layer, ""); // Updated for Visualization layers
      } else {
        this.setLayerListDropdown();
      }
    } catch (error) {
      console.error(error);
    }
  }
  cleartable() {
    try {
      this.setLayerListDropdown();
      this.distanceValue = "";
      this.emptySearchFields();
      this.querytableArr = [];
      this.selectionGraphicsLyr = this.map.getLayer("sms-selection");
      this.selectionGraphicsLyr.clear();
      this.map.graphics.clear();
      this.queryDataObj = [];
      this.data.emit([]);
      //Manikantha V 2/8/2021
      this.eventEmitersService.setSelectedFeaturesOnMap([]);
      console.log(1);
      this.eventEmitersService.setShowCard(false);
      this.eventEmitersService.setShowGrid(false);
      //Added for Visualization layers
      this.showCardViewForVisualizationLayer = false;
      console.log("Grid View Close  " + 1);
      this.fileName = "";
      this.selectedLoadFile = "";
      // this.predefinedQuerisList = this.getPredefinedQueries();
      this.isRowClicked = false;
      this.prevSelected = [];
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }
  clearPredefinedtable() {
    try {
      this.selectedQueryLayersList = [];
      this.selectionGraphicsLyr = this.map.getLayer("sms-selection");
      this.selectionGraphicsLyr.clear();
      this.map.graphics.clear();
      this.queryDataObj = [];
      if (this.joinTypeDropdown.length == 3) {
        this.joinTypeDropdown.splice(2, 1); //Added for visualization layers ... remove Distance join type
      }
      this.data.emit([]);
      //Manikantha V 2/8/2021
      this.eventEmitersService.setSelectedFeaturesOnMap([]);
      console.log(2);
      this.eventEmitersService.setShowCard(false);
      this.eventEmitersService.setShowGrid(false);
      console.log("Grid View Close  " + 2);
      this.isRowClicked = false;
      this.prevSelected = [];
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }
  setClickedRow(index) {
    try {
      this.index = index;
      // Set Clicked Row
      this.isRowClicked = true;
      // Set all Drop down Values
      this.field = this.onLayerChange(this.querytableArr[index].layer);
      if (this.field != null) {
        this.fieldDropdown = this.field.map((field) => {
          const dropdownItem: DropdownListItem = {} as DropdownListItem;
          dropdownItem.name = this.scndlyr == false ? field.FieldAlias : field.FieldAlias_SL;
          dropdownItem.id = field.Field;
          return dropdownItem;
        });
      }
      this.codedValues = this.onFieldChange(this.querytableArr[index].field);
      this.filteredCodedValues = this.codedValues;
      console.log(this.codedValues);

      this.selectedlayr = {
        name: this.querytableArr[index].layer,
        id: this.querytableArr[index].layer,
        Layer: this.querytableArr[index].layer
      } as Layer;
      this.fields = this.querytableArr[index].field;

      if (this.enableDateTimePicker) {
        this.codedvalue = new Date(this.querytableArr[index].value);
        this.codedValueControl.setValue(new Date(this.querytableArr[index].value));
      } else {
        this.codedvalue = this.querytableArr[index].value;
        this.codedValueControl.setValue(this.querytableArr[index].value);
      }

      this.operator = this.querytableArr[index].operator;
      this.jointype = this.querytableArr[index].jointype;
    } catch (error) {
      console.error(error);
    }
  }
  selectbehave(event) {
    try {
      switch (event.target.value) {
        case "New Selection":
          this.selectbehaviorobj = this.FeatureLayer.SELECTION_NEW;
          break;
        case "Add to Selection":
          this.selectbehaviorobj = this.FeatureLayer.SELECTION_ADD;
          break;
        case "Remove from Selection":
          this.selectbehaviorobj = this.FeatureLayer.SELECTION_SUBTRACT;
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
  buildQuery() {
    try {
      this.filterVisualizationData = false;
      //Added for visualization layers
      if (this.isVisualizationLyrSelected) {
        let isSpatialSearch = false;
        if (this.queryDataObj && this.queryDataObj.length > 0) {
          //filter the results of existing query result
          isSpatialSearch = true;
        }

        let allSelectedData = [];

        let filterCriteria = "";
        let orOperatorQueries = "";
        let andOperatorQueries = "";
        let noOperatorQueries = "";

        let isAssetQuery = true;
        this.querytableArr.forEach((query) => {
          if (isSpatialSearch) {
            if (query.jointype && query.jointype.includes("WITHIN")) {
              isSpatialSearch = false;
              isAssetQuery = false;
            }
          } else {
            isAssetQuery = false;
          }

          if (!isAssetQuery) {
            let fieldName = "<ogc:PropertyName>" + query.field + "</ogc:PropertyName>";
            let fieldValue = "<ogc:Literal>" + query.value + "</ogc:Literal>";
            let finalQuery;
            //different joint types
            if (query.operator == "=") {
              finalQuery = "<ogc:PropertyIsEqualTo>" + fieldName + fieldValue + "</ogc:PropertyIsEqualTo>";
            } else if (query.operator == "!=") {
              finalQuery = "<ogc:PropertyIsNotEqualTo>" + fieldName + fieldValue + "</ogc:PropertyIsNotEqualTo>";
            } else if (query.operator == "<") {
              finalQuery = "<ogc:PropertyIsLessThan>" + fieldName + fieldValue + "</ogc:PropertyIsLessThan>";
            } else if (query.operator == ">") {
              finalQuery = "<ogc:PropertyIsGreaterThan>" + fieldName + fieldValue + "</ogc:PropertyIsGreaterThan>";
            } else if (query.operator == "<=") {
              finalQuery = "<ogc:PropertyIsLessThanOrEqualTo>" + fieldName + fieldValue + "</ogc:PropertyIsLessThanOrEqualTo>";
            } else if (query.operator == ">=") {
              finalQuery = "<ogc:PropertyIsGreaterThanOrEqualTo>" + fieldName + fieldValue + "</ogc:PropertyIsGreaterThanOrEqualTo>";
            } else if (query.operator == "LIKE") {
              fieldValue = "<ogc:Literal>" + "%" + query.value + "%" + "</ogc:Literal>";
              finalQuery = "<ogc:PropertyIsLike wildcard='%' singleChar='.' escape='!'>" + fieldName + fieldValue + "</ogc:PropertyIsLike>";
            } //TSDRSAM-1864

            //different operators
            if (query.jointype == "AND") {
              andOperatorQueries = andOperatorQueries + finalQuery;
            } else if (query.jointype == "OR") {
              orOperatorQueries = orOperatorQueries + finalQuery;
            } else {
              noOperatorQueries = finalQuery;
            }
          }
        });
        //append all queries
        filterCriteria = noOperatorQueries;
        if (andOperatorQueries.length > 0) {
          filterCriteria = "<ogc:And>" + filterCriteria + andOperatorQueries + "</ogc:And>";
        }

        if (orOperatorQueries.length > 0) {
          filterCriteria = "<ogc:Or>" + filterCriteria + orOperatorQueries + "</ogc:Or>";
        }
        if (this.visualizationLayersData) {
          let lyrArray = this.visualizationLayersData.filter((e) => e.layerName == this.selectedVisualizationlyr);
          if (lyrArray && lyrArray[0] && lyrArray.length > 0) {
            let lyr = lyrArray[0];
            //create xml body
            let xmlBody =
              "<wfs:GetFeature\n" +
              ' service="WFS" \n' +
              ' version="1.0.0" \n' +
              '  xmlns:wfs="http://www.opengis.net/wfs"\n' +
              '  xmlns:ogc="http://www.opengis.net/ogc"\n' +
              '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
              '  xsi:schemaLocation="http://www.opengis.net/wfs\n' +
              '  http://schemas.opengis.net/wfs/1.0.0/WFS-basic.xsd">\n' +
              ' <wfs:Query srsName="EPSG:3857" typeName="' +
              lyr.originalLayerTitle +
              '"> \n' +
              " <ogc:Filter> \n" +
              filterCriteria +
              " \n" +
              "  </ogc:Filter> \n" +
              " </wfs:Query>\n" +
              " </wfs:GetFeature>";
            let lastIndex = lyr.wmsUrl.lastIndexOf("/");
            if (
              lyr.wmsUrl
                .substring(lastIndex + 1)
                .toLowerCase()
                .includes("wms")
            ) {
              let wfsUrl = lyr.wmsUrl.substring(0, lastIndex + 2) + "f" + lyr.wmsUrl.substring(lastIndex + 3); // wms => wfs
              lyr.originalLayerTitle = lyr.originalLayerTitle;
              let getFeatures = wfsUrl; // + "?service=WFS&REQUEST=GetFeature&VERSION=1.0.0&typeName=" + lyr.originalLayerTitle + "&Filter=" + filterValue;
              allSelectedData.push(this.eventEmitersService.getSelectedWFSData(getFeatures, lyr.originalLayerTitle, true, xmlBody));
            }
          }
        }
        let distanceUnits = "feet";
        let updatedQueryObj = [];
        if (allSelectedData.length > 0) {
          this.all(allSelectedData).then(
            function (visualizationSelectedData) {
              if (this.queryDataObj && this.queryDataObj.length > 0) {
                //filter the results of existing query result
                this.queryDataObj.forEach((queryData) => {
                  let selectionData = [];
                  let uniqueIds = [];
                  queryData.Data.forEach((data) => {
                    visualizationSelectedData.forEach((layerData) => {
                      layerData.forEach((dataArray) => {
                        dataArray.Data.forEach((element) => {
                          if (uniqueIds.indexOf(data.attributes.OBJECTID) === -1) {
                            let distance = this.esriTools.geometryEngine.distance(element.geometry, data.geometry, distanceUnits);
                            if (distance && distance <= this.distanceValue) {
                              uniqueIds.push(data.attributes.OBJECTID);
                              selectionData.push(data);
                            }
                          }
                        });
                      });
                    });
                  });
                  updatedQueryObj.push({ LayerName: queryData.LayerName, Data: selectionData });
                });
                if (updatedQueryObj && updatedQueryObj.length > 0 && updatedQueryObj[0].Data && updatedQueryObj[0].Data.length > 0) {
                  this.queryDataObj = updatedQueryObj;
                  this.data.emit(this.queryDataObj);
                  this.eventEmitersService.setSelectedFeaturesOnMap(this.queryDataObj);
                  this.genericMapMethodsService.queryBuilderZoomExtent = true;
                  this.eventEmitersService.setShowCard(true);
                } else {
                  this.queryDataObj = [];
                  this.data.emit(this.queryDataObj);
                  this.eventEmitersService.setSelectedFeaturesOnMap(this.queryDataObj);
                  this.genericMapMethodsService.queryBuilderZoomExtent = true;
                  this.eventEmitersService.setShowCard(false);
                  this.eventEmitersService.setShowGrid(false);
                  Swal.fire("No matching records");
                }
              } else {
                let selectedData = [];
                this.eventEmitersService.getSelectedVisualizationData(visualizationSelectedData).forEach((data) => {
                  selectedData.push(data);
                });
                this.queryResultsData = selectedData;
                this.rowdataobj = selectedData;
                this.data.emit(this.rowdataobj);

                if (this.rowdataobj.length > 0) {
                  this.eventEmitersService.setSelectedFeaturesOnMap(this.rowdataobj);
                  if (visualizationSelectedData.length > 0) {
                    //Select Visualization assets
                    this.eventEmitersService.emitWFSData(visualizationSelectedData);
                    this.showCardViewForVisualizationLayer = true;
                    //Added for visualization layers
                    this.reportValues = [];
                    this.reportValues.push(this.selectedVisualizationlyr + " Reference Data");
                    this.reportTypeES = this.reportValues[0];
                  }
                  this.eventEmitersService.setSelectedFeaturesOnMap(this.queryDataObj);
                  this.genericMapMethodsService.queryBuilderZoomExtent = true;
                  this.eventEmitersService.setShowCard(true);
                  this.eventEmitersService.setShowGrid(false);
                  //Add distance join type
                  if (this.joinTypeDropdown.length == 2) {
                    this.joinTypeDropdown.push(this.distanceDropdown); //Added for visualization layers ... add Distance join type
                  }
                } else {
                  this.eventEmitersService.setShowCard(false);
                  this.eventEmitersService.setShowGrid(false);
                  Swal.fire("No matching records");
                }
              }
            }.bind(this)
          );
        }
      } else {
        this.querytableUpdated = this.querytableArr;
        let visualizationLayers = this.layerList.filter((e) => e.isVisualizationLayer == true).map((e) => e.Layer);
        if (this.querytableArr.filter((e) => visualizationLayers.includes(e.layer)).length > 0 && this.querytableArr.filter((e) => !visualizationLayers.includes(e.layer)).length > 0) {
          //filter visualization layer results
          this.filterVisualizationData = true;
          //filter query table inputs for asset data
          let isAssetQuery = false;
          this.querytableArr.forEach((query) => {
            if (query.jointype && query.jointype.includes("WITHIN")) {
              isAssetQuery = true;
            }
            if (isAssetQuery) {
              query.jointype = "";
              this.querytableUpdated.push(query);
            }
          });
        }

        // 7/27/20 jcc -- called from View
        this.asstClicked = false;
        this.sqlViewOnMap = false;

        if (this.multiplePrimaryLayerQuery && this.multiplePrimaryLayerQuery.queryId && (this.multiplePrimaryLayerQuery.queryId === "QRY_10" || this.multiplePrimaryLayerQuery.queryId === "QRY_11")) {
          this.buildQueryMultiple();
          return;
        }

        // Set Service Variables
        this.selectionGraphicsLyr = this.map.getLayer("sms-selection");
        this.selectionGraphicsLyr.clear();
        if (this.joinTypeDropdown.length == 3) {
          this.joinTypeDropdown.splice(2, 1); //Added for visualization layers ... remove Distance join type
        }
        // Set Resutts Object to empty : Refresh results object for new Query
        this.queryDataObj = [];
        // Hide Card View if already opened
        //Manikantha V 2/8/2021
        this.eventEmitersService.setSelectedFeaturesOnMap(this.queryDataObj);
        // Added by Madhuri Gupta for JIRA ID:-62 on 08/03/2021
        this.genericMapMethodsService.queryBuilderZoomExtent = true;
        console.log(3);

        if (this.selectedQueryLayersList.includes("Sign")) {
          this.eventEmitersService.toggleCardView("Sign");
        } else if (this.selectedQueryLayersList.includes("Light")) {
          this.eventEmitersService.toggleCardView("Light");
        } else {
          this.eventEmitersService.toggleCardView("Oters");
        }

        this.eventEmitersService.setShowCard(true);
        this.eventEmitersService.setShowGrid(false);
        //Added for Visualization layers
        this.showCardViewForVisualizationLayer = false;
        console.log("Grid View Close  " + 3);
        this.data.emit(this.queryDataObj);
        this.objectIds = [];
        if (this.querytableArr.length === 0) {
          this.addItem();
        } else if (this.querytableArr.length === 1 && this.querytableArr[0].jointype != null) {
          this.jointype = null;
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotstartwithjointype"));
          return;
        }
        if (this.querytableArr[0].jointype != null) {
          this.jointype = null;
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotstartwithjointype"));
        }

        const queryString = this.qb.createQueryString(this.querytableUpdated, this.currentUser);
        const functionname = "test";
        console.log(`Query string for QB ${queryString}`);
        this.genericService.getQueryResults(queryString, functionname).subscribe((results: any) => {
          if (results.table && results.table[0] && results.table[0].layerName) {
            this.processQueryResultsMultipleLayers(results);
          } else {
            this.processQueryResults(results);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  processQueryResults(results: any) {
    try {
      if (results && results.table && results.table.length > 0) {
        results.table.forEach((v) => {
          this.objectIds.push(v.objectid);
        });
      }

      if (this.objectIds.length === 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.norecordsfound"));
        return;
      }

      if (!this.layr) {
        // Fix -> open and Query table after closing QB
        this.layr = this.layerList.filter((astCls) => astCls.Layer === this.querytableArr[0].layer);
      } else if (this.querytableArr[0].layer === "Assessment" || this.querytableArr[0].layer === "Maintenance" || this.querytableArr[0].layer === "Activity") {
        this.buildQueryMultiple();
        return;
      } else if (this.querytableArr[0].layer === "Sign" || this.querytableArr[0].layer === "Light") {
        this.layr = this.layerList.filter((astCls) => astCls.Layer === "Support");
      } else {
        this.layr = this.layerList.filter((astCls) => astCls.Layer === this.querytableArr[0].layer);
      }

      const layerObj = this.layr[0].layer_id.lyrObj;

      /*Select Features on Map*/
      this.loading = true;
      const query = new this.Query();
      query.objectIds = this.objectIds;
      this.map.graphics.clear();

      /** Check map extent */
      if (this.chkMapExtent) {
        query.geometry = this.map.extent;
      }

      layerObj.selectFeatures(
        query,
        this.selectbehaviorobj,
        function (res) {
          let filtered;

          if (this.selectbehaviorobj === 5) {
            filtered = this.prevSelected.filter((r) => !this.objectIds.includes(r.attributes.OBJECTID));
          } else if (this.selectbehaviorobj === 4) {
            let checkAlready;

            if (this.prevSelected && this.prevSelected.length > 0) {
              checkAlready = this.prevSelected.filter((p) => !this.objectIds.includes(p.attributes.OBJECTID));
              filtered = this.prevSelected.concat(checkAlready);
            } else {
              filtered = res;
            }
          } else {
            filtered = res;
          }

          this.loading = false;

          if (filtered.length === 0) {
            Swal.fire(this.translateService.instant("Querybuilder.alertquery.noresultfound"));
            this.isResultPresent = false;
            return;
          }

          this.isResultPresent = true;
          this.prevSelected = filtered;
          this.selectedObjIdsByMapExtent = [];

          if (this.chkMapExtent) {
            filtered.forEach((asset) => {
              this.selectedObjIdsByMapExtent.push(asset.attributes.OBJECTID);
            });
          }

          this.showDataOnMap(layerObj, this.layr[0].layer_id, filtered);
        }.bind(this),
        function (error) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.invalidquery"));
          this.loading = false;
          return;
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  processQueryResultsMultipleLayers(results: any) {
    this.buildQueryMultiple();
  }

  setThis() {
    return this;
  }

  buildQueryMultiple() {
    try {
      if (this.multiplePrimaryLayerQuery) {
        const regionData = "Region" + this.currentUser.selectedRegion.regionLevel + "='" + this.currentUser.selectedRegion.regionName + "' AND";

        if (this.multiplePrimaryLayerQuery.queryId === "QRY_11" || this.multiplePrimaryLayerQuery.queryId === "QRY_10") {
          this.qb
            .executeMultiplePrimaryLayers(
              event,
              regionData,
              this.multiplePrimaryLayerQuery,
              this.layerList,
              this.Query,
              this.FeatureLayer,
              this.map,
              this.SimpleMarkerSymbol,
              this.SimpleLineSymbol,
              this.SimpleFillSymbol,
              this.Color,
              this.Graphic
            )
            .then((result) => {
              //Added for visualization layers
              if (!this.filterVisualizationData || !this.queryResultsData || this.queryResultsData.length == 0) {
                this.data.emit(result);
                //Manikantha V 2/8/2021
                this.eventEmitersService.setSelectedFeaturesOnMap(result);
                // Added by Madhuri Gupta for JIRA ID:-62 on 08/03/2021
                this.genericMapMethodsService.queryBuilderZoomExtent = true;
                console.log(4);

                if (this.selectedQueryLayersList.includes("Sign")) {
                  this.eventEmitersService.toggleCardView("Sign");
                } else if (this.selectedQueryLayersList.includes("Light")) {
                  this.eventEmitersService.toggleCardView("Light");
                } else {
                  this.eventEmitersService.toggleCardView("Oters");
                }

                this.eventEmitersService.setShowCard(true);
                this.eventEmitersService.setShowGrid(false);
                //Added for Visualization layers
                this.showCardViewForVisualizationLayer = false;
                console.log("Grid View Close  " + 4);
              }
            });
          return;
        }
      }

      // Set Resutts Object to empty : Refresh results object for new Query
      this.queryDataObj = [];
      if (this.joinTypeDropdown.length == 3) {
        this.joinTypeDropdown.splice(2, 1); //Added for visualization layers ... remove Distance join type
      }
      if (this.querytableArr.length === 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.addquerytoexecute"));
        return;
      } else if (this.querytableArr.length === 1 && this.querytableArr[0].jointype != null) {
        this.querytableArr[0].jointype = "";
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.querycannotstartwithjointype"));
      } else {
        // Get QueryString to fetch Data
        const queryString = this.qb.createQueryString(this.querytableUpdated, this.currentUser);
        const functionname = "test";

        const allResults = [];

        this.genericService.getQueryResults(queryString, functionname).subscribe(
          function (results: any) {
            const query = new this.Query();
            const allLayersObj = [];
            results.table.forEach((layer) => {
              const currentLayer = results.table.filter((lyr) => lyr.layerName === layer.layerName);

              if (layer.layerName === "Sign" || layer.layerName === "Light" || layer.layerName === "Support") {
                this.layr = this.layerList.filter((astCls) => astCls.Layer === "Support");
              } else {
                this.layr = this.layerList.filter((astCls) => astCls.Layer === layer.layerName);
              }

              const objects = currentLayer[0].objectid.split(",").map(function (item) {
                return parseInt(item, 10);
              });

              if (layer.layerName === "Sign" || layer.layerName === "Light" || layer.layerName === "Support") {
                const lyrSupportData = allLayersObj.filter((lyr) => lyr.LayerObj === this.layr[0].layer_id);

                if (lyrSupportData.length > 0) {
                  const obj = lyrSupportData[0].ObjectID.concat(objects); // 7/27/20 jcc the objects list returned for Signs and Lights by getQueryResults are the parent (Support) record's ObjectIds.
                  allLayersObj.find((lyr) => lyr.LayerObj === this.layr[0].layer_id).ObjectID = obj;
                } else {
                  if (this.layr[0].layer_id.visible) {
                    // 7/27/20 jcc only keep layers that are currently visible
                    allLayersObj.push({
                      LayerObj: this.layr[0].layer_id,
                      ObjectID: objects
                    });
                  }
                }
              } else {
                if (this.layr[0]) {
                  if (this.layr[0].layer_id.visible) {
                    // 7/27/20 jcc only keep layers that are currently visible
                    allLayersObj.push({
                      LayerObj: this.layr[0].layer_id,
                      ObjectID: objects
                    });
                  }
                }
              }
            });
            this.loading = true;
            this.map.graphics.clear();

            /** Check map extent */
            if (this.chkMapExtent) {
              query.geometry = this.map.extent;
            }

            const _this = this.setThis();
            let count = allLayersObj.length;
            allLayersObj.forEach(
              function (layer) {
                query.objectIds = layer.ObjectID;
                layer.LayerObj.lyrObj.selectFeatures(
                  query,
                  this.selectbehaviorobj,
                  function (res) {
                    this.loading = false;

                    if (res.length === 0) {
                      count -= 1;
                      this.isResultPresent = false;
                      return;
                    }

                    _this.isResultPresent = true;
                    allResults.push({
                      Data: res,
                      layerObj: layer.LayerObj.lyrObj,
                      layerData: layer.LayerObj,
                      LayerName: layer.LayerObj
                    });

                    if (allResults.length === count) {
                      this.eventEmitersService.setSelectedFeaturesOnMap(allResults);
                      // Added by Madhuri Gupta for JIRA ID:-62 on 08/03/2021
                      this.genericMapMethodsService.queryBuilderZoomExtent = true;
                      this.showDataOnMapMultiple(layer.LayerObj.lyrObj, _this.layr[0].layer_id, allResults);
                    }
                  }.bind(this),
                  function (error) {
                    Swal.fire(this.translateService.instant("Querybuilder.alertquery.invalidquery"));
                    this.loading = false;
                    return;
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  editItem() {
    try {
      this.querytable = {} as Querytable;
      this.querytable.layer = this.selectedlayr.name;
      this.querytable.field = this.fields;

      if (this.enableDateTimePicker) {
        const formattedDate = this.codedValueControl.value.getMonth() + 1 + "-" + this.codedValueControl.value.getDate() + "-" + this.codedValueControl.value.getFullYear();
        this.querytable.value = formattedDate;
      } else {
        if (this.fields === "AssetCode") {
          this.qb.assetCode = this.codedValueControl.value;
        }

        this.querytable.value = this.codedValueControl.value;
      }

      this.querytable.operator = this.operator;

      if (this.querytable.layer && this.querytable.field && this.querytable.operator && this.querytable.value) {
        if (this.index === 0) {
          if (this.querytable.jointype) {
            this.jointype = null;
            Swal.fire(this.translateService.instant("Querybuilder.alertquery.noeditjointypeprimaryquery"));
            return;
          }

          this.querytable.jointype = undefined;
        } else {
          this.querytable.jointype = this.jointype;
        }
        this.querytable.layerDisplay = this.aliasData.find((alias) => alias.attributes.Name === this.querytable.layer).attributes.Alias;
        this.querytableArr[this.index] = this.querytable;
        this.isRowClicked = false;
        this.queryDataObj = [];
        if (this.joinTypeDropdown.length == 3) {
          this.joinTypeDropdown.splice(2, 1); //Added for visualization layers ... remove Distance join type
        }
      } else {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.entervalues"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  showDataOnMapMultiple(ftrLayer, lyrData, allResults) {
    try {
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(false);
      this.queryDataObj = allResults;
      this.selectionGraphicsLyr.clear();
      if (this.joinTypeDropdown.length == 2) {
        this.joinTypeDropdown.push(this.distanceDropdown); //Added for visualization layers ... add Distance join type
      }
      this.data.emit(this.queryDataObj);
      //Manikantha V 2/8/2021
      if (this.selectedQueryLayersList.includes("Sign")) {
        this.eventEmitersService.toggleCardView("Sign");
      } else if (this.selectedQueryLayersList.includes("Light")) {
        this.eventEmitersService.toggleCardView("Light");
      } else {
        this.eventEmitersService.toggleCardView("Oters");
      }

      //jcc 8/20/21 add back in define query export reports
      this.reportValues = this.getReportType();
      this.reportTypeES = this.reportValues[0];

      this.eventEmitersService.setShowCard(true);
      this.eventEmitersService.setShowGrid(false);
      //Added for Visualization layers
      this.showCardViewForVisualizationLayer = false;
      console.log("Grid View Close  " + 7);
      this.loadingQc = false;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  getTasksCount(assetID) {
    try {
      // 7/28/20 jcc  - expanded function to retrieve assets that are related to child assets with open tasks.
      return new Promise(
        function (resolve) {
          const query = new this.Query();
          const queryTaskMaintenance = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId);
          const queryTaskSign = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId);
          query.outFields = ["AssetID"];

          // get asset open tasks
          query.where = "AssetID='" + assetID + "' AND Status='Open'";
          queryTaskMaintenance.execute(query).then((result) => {
            if (result.features.length > 0) {
              resolve(result.features.length); // primary asset has open maintenance.  stop here since actual count of tasks does not seem necessary.
            } else {
              // get sign asset children of selected assets
              const childAssetIDList = [];
              query.where = "ParentID='" + assetID + "' AND Status <> 'Inactive'";
              queryTaskSign.execute(query).then((result) => {
                if (result.features.length > 0) {
                  result.features.forEach((element) => {
                    childAssetIDList.push("'" + element.attributes.AssetID + "'");
                  });

                  // get children's open maintenance
                  query.where = "AssetID IN (" + childAssetIDList.toString() + ") and Status = 'Open'";
                  queryTaskMaintenance.execute(query).then((result) => {
                    resolve(result.features.length); // count (zero or more) of child assets with open maintenance
                  });
                } else {
                  resolve(0); // neither primary or related assets have an open task
                }
              });
            }
          });
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  showDataOnMap(ftrLayer, lyrData, res) {
    try {
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(false);
      this.selectionGraphicsLyr = this.map.getLayer("sms-selection");
      this.selectionGraphicsLyr.clear();
      this.queryDataObj = [];
      this.queryDataObj.push({ LayerName: lyrData, Data: res });
      if (this.joinTypeDropdown.length == 2) {
        this.joinTypeDropdown.push(this.distanceDropdown); //Added for visualization layers ... add Distance join type
      }
      if (!this.sqlViewOnMap) {
        this.reportValues = this.getReportType();
        this.reportTypeES = this.reportValues[0];
      }
      this.isFromQB.emit(true);
      this.data.emit(this.queryDataObj);
      if (this.queryDataObj[0].Data.length == this.map.smsLayerdata.dynamicMapLayer.maxRecordCount) {
        this.qb.updateMaxRecordMessage(this.map.smsLayerdata.dynamicMapLayer.maxRecordCount);
      }
      //Manikantha V 2/8/2021
      this.eventEmitersService.setSelectedFeaturesOnMap(this.queryDataObj);
      // Added by Madhuri Gupta for JIRA ID:-62 on 08/03/2021
      this.genericMapMethodsService.queryBuilderZoomExtent = true;
      console.log(8);

      if (this.selectedQueryLayersList.includes("Sign")) {
        this.eventEmitersService.toggleCardView("Sign");
      } else if (this.selectedQueryLayersList.includes("Light")) {
        this.eventEmitersService.toggleCardView("Light");
      } else {
        this.eventEmitersService.toggleCardView("Oters");
      }

      this.eventEmitersService.setShowCard(true);
      this.eventEmitersService.setShowGrid(false);
      //Added for Visualization layers
      this.showCardViewForVisualizationLayer = false;
      console.log("Grid View Close  " + 10);
      this.loadingQc = false;
    } catch (error) {
      console.error(error);
    }
  }
  saveQuery(queryTool) {
    try {
      if (this.fileName == null || this.fileName === "") {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.enternameb4saving"));
        this.clickedOnSave = true;
        return;
      }

      /*Validate if file Name already present*/
      let validateFileName;
      let queryString;

      if (queryTool === "QB") {
        this.clickedOnSave = false;
        validateFileName = this.savedQueriesList.filter((savedQuery) => savedQuery.fileName === this.fileName);

        if (this.querytableArr.length === 0) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.enterqueryb4saving"));
          this.clickedOnSave = true;
          return;
        }

        queryString = this.qb.createQueryString(this.querytableArr, this.currentUser);
      } else if (queryTool === "QC") {
        this.clickedOnSave = false;
        this.vaildateQuery();

        if (this.sqlResultCount > 0) {
          validateFileName = this.sqlgetSavedQueries.filter((savedQuery) => savedQuery.fileName === this.fileName);
          queryString = this.createdQuery;
        }
      }

      if (validateFileName.length > 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.noduplicatequery"));
        this.clickedOnSave = true;
        return;
      }

      this.qb.saveQuery(this.fileName, this.FeatureLayer, queryString, this.Graphic, queryTool, null).then(
        function (result) {
          this.savedQueriesList = this.getSavedQueries("QB");

          if (this.sqlResultCount > 0) {
            this.sqlgetSavedQueries = this.getSavedQueries("QC");
          }

          Swal.fire(this.translateService.instant("Querybuilder.alertquery.querysavetodatabase"));
          this.clickedOnSave = true;
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  /** Delete Query From the Database */
  deleteQuery(queryTool) {
    try {
      if (this.fileName == null || this.fileName === "") {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.enternametodelete"));
        return;
      }

      let checkFilePresent;

      if (queryTool === "QB") {
        checkFilePresent = this.savedQueriesList.filter((list) => list.fileName === this.fileName);
      } else if (queryTool === "QC") {
        checkFilePresent = this.sqlgetSavedQueries.filter((list) => list.fileName === this.fileName);
      }

      if (checkFilePresent.length <= 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.querynamenotavailable"));
        return;
      }

      const userActionsQueryLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new this.FeatureLayer(userActionsQueryLayer);
      const promise = this.qb.deleteQuery(this.FeatureLayer, this.fileName, this.Query, this.QueryTask, queryTool);
      let deleteRecord;
      const _this = this.setThis();
      promise.then(function (result) {
        if (result) {
          deleteRecord = result;

          if (deleteRecord.length === 0) {
            Swal.fire(this.translateService.instant("Querybuilder.alertquery.noquerynameavailable"));
            return;
          }

          _this.query.deleteAsset(userActionsQueryLayerObj, deleteRecord.features[0]).then((result) => {
            if (result) {
              Swal.fire(_this.translateService.instant("Querybuilder.alertquery.successfullydeleted"));
              _this.savedQueriesList = _this.getSavedQueries("QB");
              _this.sqlgetSavedQueries = _this.getSavedQueries("QC");
              _this.querytableArr = [];
              _this.fileName = "";
              _this.createdQuery = "";
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /** Load from Database into Map */
  populateQueryTable(selectedFile, queryTool) {
    try {
      if (queryTool === "QB") {
        this.querytableArr = [];
        this.queryDataObj = [];
        if (this.joinTypeDropdown.length == 3) {
          this.joinTypeDropdown.splice(2, 1); //Added for visualization layers ... add Distance join type
        }
        const queryStringSelected = this.savedQueriesList.filter((savedQuery) => savedQuery.fileName === this.selectedLoadFile);
        this.parseQuery(queryStringSelected);
        // Get Layer Name
        let layer = queryStringSelected[0].queryString.split("?")[0].split(":")[0];
        let primaryLayer;

        if (layer === "Sign" || layer === "Light" || layer === "Maintenance" || layer === "Assessment" || layer === "Activity") {
          primaryLayer = layer;
          layer = "Support";
        }

        const layerListRelated = this.qb.getDropDownValuesLayer(layer, this.inventoryLayersData, this.FeatureLayer);
        this.disableLayerDropdown(layerListRelated, primaryLayer); // Updated for Visualization layers
      } else if (queryTool === "QC") {
        const queryStringSelected = this.sqlgetSavedQueries.filter((savedQuery) => savedQuery.fileName === selectedFile);

        if (queryStringSelected.length === 0) {
          Swal.fire("Incorrect Query Saved");
        } else {
          this.createdQuery = queryStringSelected[0].queryString;
          this.fileName = queryStringSelected[0].fileName;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  /** Save Query Table to Text File */
  saveQueryAsFile() {
    try {
      if (this.querytableArr.length <= 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.addqueryb4savingtofile"));
      } else {
        const data = this.qb.createQueryString(this.querytableArr, this.currentUser);
        this.qb.saveTextAsFile(data, "queryToFile.txt");
      }
    } catch (error) {
      console.error(error);
    }
  }
  /** Load Query from text file to querytable */
  loadTextAsQuery(event) {
    try {
      const _this = this.setThis();
      this.qb.loadTextAsQuery(event).then((result) => {
        _this.parseQuery(result);
        // Get Layer Name
        let layer = result[0].queryString.split("?")[0].split(":")[0];
        let primaryLayer;

        if (layer === "Sign" || layer === "Light" || layer === "Maintenance" || layer === "Assessment" || layer === "Activity") {
          primaryLayer = layer;
          layer = "Support";
        }

        const layerListRelated = _this.qb.getDropDownValuesLayer(layer, this.inventoryLayersData, this.FeatureLayer);
        this.disableLayerDropdown(layerListRelated, primaryLayer); // Updated for Visualization layers
      });
    } catch (error) {
      console.error(error);
    }
  }
  parseQuery(queryStringSelected) {
    try {
      this.querytableArr = [];

      try {
        const queryString = queryStringSelected[0].queryString;
        this.fileName = queryStringSelected[0].fileName;
        let queries = [];
        queries = queryString.split("?");
        const layers = queries[0].split(":");
        const clauses = queries[1].split(";");
        //const count_tables = 0;

        this.disableJoinTypeDropdown = false;
        for (let i = 1; i < clauses.length; i++) {
          this.querytable = {} as Querytable;
          const conditionSplit = clauses[i].trim().split(" ");

          if (conditionSplit.length === 3) {
            const tablePrefix = conditionSplit[0].split(".")[0];
            this.querytable.layer = this.constants.getQueryTables(tablePrefix, layers[0]);
            const fieldName = conditionSplit[0].split(".");
            this.field = this.onFieldChange(fieldName[1]);
            this.querytable.field = fieldName[1];
            const match = this.aliasData.find((alias) => alias.attributes.Name === this.querytable.layer);
            this.querytable.layerDisplay = this.aliasData.find((alias) => alias.attributes.Name === this.querytable.layer).attributes.Alias;
            this.querytable.operator = conditionSplit[1];
            this.querytable.value = conditionSplit[2].replace(/'/g, "");
          } else {
            const tablePrefix = conditionSplit[1].split(".")[0];
            this.querytable.layer = this.constants.getQueryTables(tablePrefix, layers[0]);
            const match = this.aliasData.find((alias) => alias.attributes.Name === this.querytable.layer);
            this.querytable.layerDisplay = this.aliasData.find((alias) => alias.attributes.Name === this.querytable.layer).attributes.Alias;
            //jcc 10/1/20 -- account for space(s) in Value, which cause conditionSplit.length>3.
            //              Original code assumed this necessarily meant a statement with a Join -- error if first statement in query.
            //              Original code truncated the Value at first space when loading saved queries.
            if (conditionSplit[0] == "AND" || conditionSplit[0] == "OR") {
              this.querytable.jointype = conditionSplit[0];
            } else {
              // placeholder so remaining code works as expected
              conditionSplit.unshift("");
            }

            if (conditionSplit.length > 4) {
              //jcc 10/1/20 -- re-concat any space seperated components of Value
              for (let j = 4; j < conditionSplit.length; j++) {
                conditionSplit[3] += " " + conditionSplit[j];
              }
            }

            const fieldName = conditionSplit[1].split(".");
            this.field = this.onFieldChange(fieldName[1]);
            this.querytable.field = fieldName[1];
            this.querytable.operator = conditionSplit[2];
            this.querytable.value = conditionSplit[3].replace(/'/g, "");
          }

          this.querytableArr.push(Object.create(this.querytable));
        }
      } catch (err) {
        if (err) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.invalidformat"));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**Reports Functions */
  getReportType() {
    return this.qb.getReportValues(this.queryDataObj);
  }
  onChangeReportType(event) {
    try {
      this.reportTypeES = event;
      if (this.isVisualizationLyrSelected) {
        let data = [];
        let tableBody;
        if (!this.reportTypeES.includes("Reference Data") && this.queryDataObj && this.queryDataObj.length > 0) {
          //Asset layers
          this.queryDataObj[0].Data.forEach((row) => {
            data.push(row.attributes);
          });
          tableBody = this.qb.createTableBodyForVisualizationLayers(data, false);
        } else if (this.reportTypeES.includes("Reference Data") && this.queryResultsData) {
          //Visualization layers
          this.queryResultsData[0].Data.forEach((row) => {
            data.push(row.attributes);
          });
          tableBody = this.qb.createTableBodyForVisualizationLayers(data, true);
        }

        let dataLength = data.length;
        this.qb.reports(tableBody, this.reportFormatES, this.reportTypeES, dataLength, pdfMake);
      } else {
        this.selectedReportType = event;
        let functionName = "Report:";
        const data = this.qb.createQueryString(this.querytableArr, this.currentUser);
        functionName += this.constants.getFunctionName(event) ?? this.selectedlayr.Layer;
        const _this = this.setThis();
        let reportData = "";

        if (this.chkMapExtent) {
          reportData = data.split("?")[0] + "?" + "OBJECTID in (" + this.selectedObjIdsByMapExtent.join(",") + ")";
        } else {
          reportData = data;
        }

        this.genericService.getQueryResults(reportData, functionName).subscribe((results: any) => {
          if (results) {
            _this.tableBody = this.qb.createTableBody(results.table);
            _this.dataLength = results.table.length;

            if (this.firstReport) {
              this.qb.reports(this.tableBody, this.reportFormatES, this.selectedReportType, this.dataLength, pdfMake);
            }

            this.firstReport = false;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  exportReports() {
    try {
      this.tableBody = undefined;

      if (this.tableBody === undefined) {
        this.firstReport = true;
        this.onChangeReportType(this.reportTypeES);
      }
    } catch (error) {
      console.error(error);
    }
  }
  vaildateQuery() {
    try {
      let layer: any;

      if (this.createdQuery === "" || this.createdQuery == null) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.enterqueryb4validate"));
        return;
      }

      if (this.createdQuery.includes("--")) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.commentnorallowed"));
        return;
      }

      try {
        layer = this.createdQuery.toLocaleLowerCase().split("from")[1].split(" ")[1];

        if (this.createdQuery.includes("*") && ["Maintenance", "Assessment", "Activity"].includes(layer)) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.addassetidincolumn"));
          return;
        }
      } catch (err) {
        Swal.fire("Invalid Query");
      }

      if (layer.toLocaleLowerCase() === "sign" || layer.toLocaleLowerCase() === "light") {
        layer = "support";
      }

      const isAllowed = this.layerList.filter((lyr) => lyr.Layer.toLowerCase() === layer.toLocaleLowerCase());

      if (isAllowed.length <= 0) {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.layernotaccessible"));
        return;
      }

      const functionName = "QueryCreatorValidate";
      const params = this.createdQuery;
      this.genericService.getQueryResults(params, functionName).subscribe(
        (results: any) => {
          if (results) {
            if (results.table[0].error) {
              Swal.fire(results.table[0].error);
              this.queryValidatedFromServer = false;
            } else {
              this.sqlResultCount = results.table[0].count;

              if (this.sqlResultCount === 0) {
                Swal.fire("No records Found");
                return;
              }

              this.queryValidatedFromServer = true;
            }
          } else {
            Swal.fire(this.translateService.instant("Querybuilder.alertquery.errorinquery"));
            return;
          }

          if (this.queryValidatedFromServer) {
            this.clickedValidate = true;
            setTimeout(() => {
              this.clickedValidate = false;
              this.queryValidatedFromServer = false;
            }, 15000);
          }
        },
        (err) => {
          Swal.fire(err.error.error.message);
          return;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  clearQuery() {
    this.createdQuery = "";
    this.sqlResultCount = 0;
    this.fileName = "";
    this.sqlgetSavedQueries = this.getSavedQueries("QC");
    this.showCardViewForVisualizationLayer = false;
  }

  runQuery() {
    try {
      let functionName = "QueryCreator";
      this.sqlResultCount = 0;

      if (this.sqlViewOnMap || this.sqlViewOnExcel) {
        if (this.sqlViewOnExcel === true) {
          functionName += "Export";
        }

        if (this.sqlViewOnMap === true) {
          functionName += "Load";
        }

        this.clickedRun = true;
        setTimeout(() => {
          this.clickedRun = false;
        }, 15000);
        let queryAttempt = Number(localStorage.getItem("queryAttempts"));

        if (queryAttempt === 50) {
          Swal.fire(this.translateService.instant("Querybuilder.alertquery.exceednumberqueries"));
          return;
        }

        this.loadingQc = true;
        queryAttempt += 1;
        localStorage.setItem("queryAttempts", queryAttempt.toString());
        this.createdQuery = this.createdQuery.toLocaleLowerCase();
        const mapObj = {
          assetid: "assetID",
          parentid: "parentID"
        };
        this.createdQuery = this.createdQuery.replace(/assetid|parentid/gi, function (matched) {
          return mapObj[matched];
        });
        const params = this.createdQuery;
        const _this = this.setThis();
        this.genericService.getQueryResults(params, functionName).subscribe(
          (results: any) => {
            this.countWhere = 0;

            if (results && results.table.length) {
              console.log(results);

              if (this.sqlViewOnExcel) {
                const tablebody = _this.qb.createTableBodySQL(results.table);
                this.qb.reports(tablebody, "Microsoft Excel", "", this.sqlResultCount, pdfMake);
                this.loadingQc = false;
              }

              if (this.sqlViewOnMap) {
                let layer = this.createdQuery.toLocaleLowerCase().split("from")[1].split(" ")[1];
                const origLayer = layer;

                if (layer.toLocaleLowerCase() === "sign" || layer.toLocaleLowerCase() === "light") {
                  layer = "support";
                }

                if (layer.toLocaleLowerCase() === "maintenance" || layer.toLocaleLowerCase() === "assessment" || layer.toLocaleLowerCase() === "activity") {
                  const allResults = [];
                  let filteredResults = [];
                  // Iteration thrugh all primary and related layers
                  const reqdLayers = this.layerList.filter((l) => l.Layer.toLocaleLowerCase() !== "maintenance" && l.Layer.toLocaleLowerCase() !== "assessment" && l.Layer.toLocaleLowerCase() !== "activity");
                  if (reqdLayers.filter((lyr) => lyr.Layer === "Sign").length > 0) {
                    //length -= 2;
                  }

                  reqdLayers.forEach((lyr) => {
                    results.table.filter((res) => {
                      if (res.assetID != null) {
                        if (res.assetID.slice(0, 3) === this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === lyr.Layer)[0].attributes.LayerCode) {
                          filteredResults.push(res);
                        }
                      }
                    });
                    const query = new this.Query();
                    let assetID = "";
                    filteredResults.forEach((object) => {
                      if (filteredResults.length > 0) {
                        if (object.parentid) {
                          assetID += "'" + object.parentid + "'.";
                        } else {
                          assetID += "'" + object.assetID + "',";
                        }
                      }
                    });

                    if (assetID === "") {
                      // Swal.fire(this.translateService.instant('Querybuilder.alertquery.addassetidincolumn'));
                      // this.loadingQc=false;
                      // return;
                    } else {
                      query.where = "AssetID in (" + assetID.substring(0, assetID.length - 1) + ")";

                      if (lyr.Layer.toLocaleLowerCase() !== "sign" && lyr.Layer.toLocaleLowerCase() !== "light") {
                        this.countWhere++;
                      }
                    }

                    let layerObj;
                    layerObj = this.layr = this.layerList.filter((astCls) => astCls.Layer.toLowerCase() === lyr.Layer.toLocaleLowerCase());

                    if (lyr.Layer.toLocaleLowerCase() === "sign" || lyr.Layer.toLocaleLowerCase() === "light") {
                      layerObj = this.layr = this.layerList.filter((astCls) => astCls.Layer.toLowerCase() === "support");
                    }

                    if (query.where) {
                      layerObj[0].layer_id.lyrObj.selectFeatures(
                        query,
                        layerObj[0].layer_id.lyrObj.NEW_SELECTION,
                        function (result) {
                          if (result && result.length > 0) {
                            const existingsupportLayer = allResults.filter((res) => res.layerObj === layerObj[0].layer_id.lyrObj);

                            if (existingsupportLayer.length > 0) {
                              const data = existingsupportLayer[0].Data.concat(result);
                              allResults.find((lyr) => lyr.layerObj === layerObj[0].layer_id.lyrObj).Data = data;
                            } else {
                              allResults.push({
                                Data: result,
                                layerObj: layerObj[0].layer_id.lyrObj,
                                layerData: layerObj[0].layer_id
                              });
                            }

                            if (allResults.length === this.countWhere) {
                              this.showDataOnMapMultiple(layerObj[0].layer_id.lyrObj, _this.layr[0].layer_id, allResults);
                              this.loadingQc = false;
                            }
                          }
                        }.bind(this)
                      );
                    }

                    filteredResults = [];
                  });
                  this.loadingQc = false;
                  return;
                }

                const layerObj = (this.layr = this.layerList.filter((astCls) => astCls.Layer.toLowerCase() === layer.toLocaleLowerCase()));
                console.log(layerObj);
                const query = new this.Query();
                query.objectIds = [];
                //const oid = [];
                let assetID = "";
                results.table.forEach(
                  function (object) {
                    if (object.objectid && origLayer.toLocaleLowerCase() !== "sign" && origLayer.toLocaleLowerCase() !== "light") {
                      query.objectIds.push(object.objectid);
                    } else {
                      if (origLayer.toLocaleLowerCase() === "sign" || origLayer.toLocaleLowerCase() === "light") {
                        if (object.parentID) {
                          assetID += "'" + object.parentID + "',";
                        } else if (object.parentid) {
                          assetID += "'" + object.parentid + "',";
                        }
                      } else {
                        if (object.assetID) {
                          assetID += "'" + object.assetID + "',";
                        } else if (object.assetid) {
                          assetID += "'" + object.assetid + "',";
                        }
                      }
                    }
                  }.bind(this)
                );

                if (query.objectIds.length === 0) {
                  query.where = "AssetID in (" + assetID.substring(0, assetID.length - 1) + ")";
                }

                layerObj[0].layer_id.lyrObj.selectFeatures(query, layerObj[0].layer_id.lyrObj.NEW_SELECTION, function (result) {
                  if (result && result.length > 0) {
                    _this.showDataOnMap(layerObj[0].layer_id.lyrObj, _this.layr[0].layer_id, result);
                    _this.loadingQc = false;
                  } else {
                    Swal.fire("Data not present in current region");
                  }

                  _this.loadingQc = false;
                });
                console.log(results);
              }
            } else {
              Swal.fire(this.translateService.instant("Querybuilder.alertquery.invalidquery"));
            }
          },
          (err) => {
            if (err) {
              Swal.fire(this.translateService.instant("Querybuilder.alertquery.queryincorrectrecheck"));
            }
          }
        );
      } else {
        Swal.fire(this.translateService.instant("Querybuilder.alertquery.selectoptionfrresults"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  onTextAreaChange(event) {
    console.log("Text Area Change", event);
    this.queryValidatedFromServer = false;
  }
  InfoMessage() {
    let text = '<div class="text-left py-4"><b>Validate :</b> Considers all the regions available</div>';
    text += '<div class="text-left"><b>Run (' + this.translateService.instant("Querybuilder.predefinedquery.loadreturnedassets") + "):</b>  Results will be shown on map as per selected region</div>";
    text += '<div class="text-left pt-3"><b>Run (Export results to CSV):</b>  Results will be for all regions</div>';

    Swal.fire({
      type: "info",
      title: "",
      html: '<div class="text-center" style="font-size: 15px;">' + text + "</div>",
      width: "465px"
    });
  }
  openCardClicked() {
    //if condition added for Visualization layer changes
    // if(this.isVisualizationLyrSelected){
    //   this.showCardViewForVisualizationLayer = true;
    //   this.eventEmitersService.setShowVisualizationAssetDetails();
    // }
    // else
    // {
    this.showCardViewForVisualizationLayer = true;
    this.eventEmitersService.setShowCard();
    this.eventEmitersService.addNewParent = false;
    this.eventEmitersService.addNewChild = false;
    //}
  }
  openGridClicked() {
    this.eventEmitersService.setShowGrid();
    console.log("Grid View Open/Close  " + 11);
  }
  openMaintenanceClicked() {
    this.eventEmitersService.setMaintenanceView();
  }
  // JIRA ID:-61
  //Added for visualization layers
  loadVisualizationLayersData(layerName) {
    let fieldsData;
    return new Promise(
      function (resolve) {
        const allFieldsData = [];
        const lyr = this.visualizationLayersData.filter((e) => e.layerName == layerName)[0];
        const lastIndex = lyr.wmsUrl.lastIndexOf("/");
        if (
          lyr.wmsUrl
            .substring(lastIndex + 1)
            .toLowerCase()
            .includes("wms")
        ) {
          const wfsUrl = lyr.wmsUrl.substring(0, lastIndex + 2) + "f" + lyr.wmsUrl.substring(lastIndex + 3); // wms => wfs
          //lyr.originalLayerTitle = lyr.originalLayerTitle;
          const getFeatures = wfsUrl + "?service=WFS&REQUEST=DescribeFeatureType&VERSION=1.0.0&typeName=" + lyr.originalLayerTitle;
          allFieldsData.push(this.getSelectedWFSFieldData(getFeatures, lyr.originalLayerTitle));
        }
        if (allFieldsData.length > 0) {
          this.all(allFieldsData).then(
            function (fieldsInfo) {
              fieldsInfo.forEach((data) => {
                if (data.length > 0) {
                  fieldsData = data;
                }
              });
              resolve(fieldsData);
            }.bind(this)
          );
        }
      }.bind(this)
    );
  }

  getSelectedWFSFieldData(getFeaturesUrl, layerTitle) {
    try {
      return new Promise(
        function (resolve) {
          this.genericService.getWMSLayers(getFeaturesUrl).subscribe(
            (res: any) => {
              let parser = new xml2js.Parser({ trim: true, explicitArray: true });
              if (res) {
                parser.parseString(res, (err, result) => {
                  let fieldData = [];
                  fieldData.push({ class: layerTitle });
                  if (result && result.hasOwnProperty("xsd:schema")) {
                    let complexType = result["xsd:schema"]["xsd:complexType"];
                    if (complexType && complexType[0]) {
                      let complexContent = complexType[0]["xsd:complexContent"];
                      if (complexContent && complexContent[0]) {
                        let extension = complexContent[0]["xsd:extension"];
                        if (extension && extension[0]) {
                          let sequence = extension[0]["xsd:sequence"];
                          if (sequence && sequence[0]) {
                            let elements = sequence[0]["xsd:element"];
                            elements.forEach((element) => {
                              fieldData.push({ name: element["$"].name, type: element["$"].type ? element["$"].type.split(":")[1] : "string" });
                            });
                          }
                        }
                      }
                    }
                  }
                  resolve(fieldData);
                });
              } else {
                resolve([]);
              }
            },
            (err) => {
              console.log(err);
              resolve([]);
            }
          );
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  disableLayerDropdown(layerListRelated, layer) {
    const layerNames = [];
    if (typeof layerListRelated != "string") {
      layerListRelated.forEach((lyr) => {
        if (layer === "Sign" || layer === "Light") {
          if (lyr.Layer !== "Support") {
            layerNames.push(lyr.Layer);
          }
        } else if (layer === "Maintenance" || layer === "Assessment" || layer === "Activity") {
          if (lyr.Layer === "Maintenance" || lyr.Layer === "Assessment" || lyr.Layer === "Activity") {
            layerNames.push(lyr.Layer);
          }
        } else {
          layerNames.push(lyr.Layer);
        }
      });
    }

    for (let i = 0; i < this.layerDropdown.length; i++) {
      const layerData = this.layerList[i];
      this.layerDropdown[i].disabled = false;
      if ((!layerData.isVisualizationLayer && !layerNames.includes(layerData.Layer)) || layerData.isVisualizationLayer) {
        this.layerDropdown[i].disabled = true;
        this.layerList[i].disabled = true;
      } else if (layerData.isVisualizationLayer) {
        //disable all visualization layers
        this.layerDropdown[i].disabled = true;
        this.layerList[i].disabled = true;
      }
    }
  }

  private async getWMSFieldInfo(event: string) {
    this.visualizationLayersData = this.map.visualizationLayers;
    let fieldsData;
    if (this.visualizationFieldsData.filter((e) => e[0].class && e[0].class == event).length == 0) {
      fieldsData = await this.loadVisualizationLayersData(event);
      if (fieldsData && fieldsData.length > 0) {
        this.visualizationFieldsData.push(fieldsData);
      }
    }

    /**Reset Fields and Values if layers is changed */
    this.fields = "";
    this.codedValues = [];
    this.codedvalue = null;
    this.field = [];
    this.isVisualizationLyrSelected = true;
    this.selectedVisualizationlyr = event;
    //get the fields
    const layerOrgName = this.visualizationLayersData.filter((e) => e.layerName == event)[0].originalLayerTitle;
    const currentLayerFields = this.visualizationFieldsData.filter((e) => e[0].class && e[0].class == layerOrgName);
    if (currentLayerFields && currentLayerFields.length > 0 && currentLayerFields[0].length > 0) {
      currentLayerFields[0].forEach((field) => {
        if (!field.class && field.name.toLowerCase() != "shape") {
          this.field.push({ Field: field.name, FieldAlias: field.name, FieldAlias_SL: field.name });
        }
      });
      this.fieldDropdown = this.field.map((field) => {
        const dropdownItem: DropdownListItem = {} as DropdownListItem;
        dropdownItem.name = this.scndlyr == false ? field.FieldAlias : field.FieldAlias_SL;
        dropdownItem.id = field.Field;
        return dropdownItem;
      });
    } else {
      Swal.fire("No records found");
      this.emptySearchFields();
    }
  }
  private _filter(value: string): DropdownListItem[] {
    if (value != null) {
      const filterValue = value.toLowerCase();

      const filteredValues = this.codedValues.filter((option) => option.name.toLowerCase().includes(filterValue));

      return [...new Map(filteredValues.map((item) => [item["name"], item])).values()];
    }
    return [...new Map(this.codedValues.map((item) => [item["name"], item])).values()];
  }

  private setLayerListDropdown() {
    this.layerList = this.qb.getDropDownValues();
    if (this.visualizationLayersData) {
      this.visualizationLayersData.forEach((lyr) => {
        // Added for visualization layers
        this.layerList.push({ Layer: lyr.layerName, tableAlias: lyr.layerName, tableAliasSL: lyr.layerName, isVisualizationLayer: true });
      });
    }
    this.layerDropdown = this.layerList.map((lyr) => {
      const dropdownItem: Layer = {} as Layer;
      dropdownItem.name = this.scndlyr == false ? lyr.tableAlias : lyr.tableAliasSL;
      dropdownItem.id = lyr.Layer;
      dropdownItem.isVisualizationLayer = lyr.isVisualizationLayer == null ? false : lyr.isVisualizationLayer;
      dropdownItem.tableAliasSL = lyr.tableAliasSL;
      dropdownItem.tableAlias = lyr.tableAlias;
      dropdownItem.Layer = this.scndlyr == false ? lyr.tableAlias : lyr.tableAliasSL;
      return dropdownItem;
    });
  }
  //TSDRSAM-1805 : getting selected report format
  onChangeReportFormat(event) {
    this.reportFormatES = event;
  }

  uniq(a) {
    return a.filter((value, index, self) => index === self.findIndex((t) => t.place === value.place && t.name === value.name));
  }
  //
}
