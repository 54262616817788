import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class WeatherService {
  constructor(private httpClient: HttpClient) {}

  getWeatherForcastfordate(dateStr, lat, lng) {
    const date = new Date(dateStr);
    dateStr = date.toISOString();
    const promise = new Promise((resolve) => {
      const url = environment.weatherApiURL.replace("<lat>", lat).replace("<lon>", lng);
      this.httpClient.get(url).subscribe((data: any) => {
        if (data.list && data.list.length > 0) {
          const dayForecast = [];
          let timeForecast = null;
          data.list.forEach((element, i) => {
            const forcastDate = new Date(element.dt_txt + " UTC");

            if (forcastDate.toDateString() === date.toDateString()) {
              let time = forcastDate.toTimeString().substring(0, 5);

              if (data.list[i + 1] && new Date(data.list[i + 1].dt_txt + " UTC").toDateString() === date.toDateString()) {
                time += " - " + new Date(data.list[i + 1].dt_txt + " UTC").toTimeString().substring(0, 5);
                const forecast = {
                  temp: element.main.temp,
                  wind: element.wind ? element.wind.speed : "0",
                  humidity: element.main.humidity,
                  rain: element.rain ? element.rain["3h"] : "0",
                  time
                };
                dayForecast.push(this.getRecommendation(forecast));

                if (forcastDate.getTime() - date.getTime() <= 0) {
                  timeForecast = forecast;
                }
              }
            }
          });
          resolve({
            timeForecast,
            dayForecast
          });
        }
      });
    });
    return promise;
  }

  getRecommendation(forecast) {
    // humidity
    if (forecast.humidity <= environment.weatherThreshold.humidity.light) {
      forecast.humiditycolor = 1;
    } else if (forecast.humidity > environment.weatherThreshold.humidity.light && forecast.humidity <= environment.weatherThreshold.humidity.moderate) {
      forecast.humiditycolor = 2;
    } else {
      forecast.humiditycolor = 3;
    }

    // temp
    if (forecast.temp <= environment.weatherThreshold.temp.moderate) {
      forecast.tempcolor = 1;
    } else if (forecast.temp > environment.weatherThreshold.temp.moderate && forecast.temp <= environment.weatherThreshold.temp.heavy) {
      forecast.tempcolor = 2;
    } else {
      forecast.tempcolor = 3;
    }

    // wind
    if (forecast.wind <= environment.weatherThreshold.wind.light) {
      forecast.windcolor = 1;
    } else if (forecast.wind > environment.weatherThreshold.wind.light && forecast.wind <= environment.weatherThreshold.wind.moderate) {
      forecast.windcolor = 2;
    } else {
      forecast.windcolor = 3;
    }

    // rain
    if (forecast.rain <= environment.weatherThreshold.rain.moderate) {
      forecast.raincolor = 1;
    } else if (forecast.rain > environment.weatherThreshold.rain.moderate && forecast.rain <= environment.weatherThreshold.rain.heavy) {
      forecast.raincolor = 2;
    } else {
      forecast.raincolor = 3;
    }

    return forecast;
  }
}
