import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "hoursMinute"
})
export class HoursMinutePipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 60);
    return hours + ":" + (value - hours * 60) + " hh:mm";
  }
}
