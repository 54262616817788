import { Component, OnInit, Input } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { ILayer } from "src/app/interface/layer";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsStyle } from "../system.reports.style";
import { environment } from "src/environments/environment";
import { AssetDetailService } from "../../services/assetDetail.service";
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { DatePipe } from "@angular/common";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-assetdetail",
  templateUrl: "./assetDetail.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class AssetDetailComponent implements OnInit {
  @Input() map;
  public assetIdToSearch: string;
  public currentUser: any;
  public AssetDetailValues: any = [];
  public assetIDSelected;
  public RegionsListByClient = [];
  public LayersFromServices = [];
  LayerNameSelected;
  RegionSelected;
  AssetIdSelected;
  AssetIds = [];
  openReportsButton = true;
  JSONData: any = [];
  ContentVal=[];
  tablebody2=[];
  tablebody3=[];
  tablebody4=[];
  currentReportStyle;
  currentLayoutStyle;
  assetDetailReportDocs: any[];
  currentdate: Date;
  currenttimestamp: number;
  constructor(
    public systemReportsService: SystemReportsService,
    private authenticationService: AuthenticationService,
    private genericService: GenericLayerService,
    private translateService: TranslateService,
    private reportStyle: SystemReportsStyle,
    public objAssetDetailService: AssetDetailService ,
    public datepipe : DatePipe ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.RegionsListByClient = [];
    this.LayersFromServices = [];
    this.RegionsListByClient = this.map.smsRegiondata;
    this.LayersFromServices = this.map.smsLayerdata.userLayers.filter((obj: ILayer) => obj.layerType === "inventory");
    this.LayersFromServices.push({
      acessPrivilege: "Data Editor",
      layerId: 22,
      layerName: "Sign",
      layerType: "inventory",
      lyrObj: null,
      visible: true
    });
    this.LayersFromServices.push({
      acessPrivilege: "Data Editor",
      layerId: 23,
      layerName: "Light",
      layerType: "inventory",
      lyrObj: null,
      visible: true
    });
  }

  GetAssetDetails() {
    try {
      this.AssetIds = [];
      const Obj = "?AssetId=" + this.AssetIdSelected;
      this.systemReportsService.getAssetDetailsReport(Obj).subscribe(
        (res: any) => {
          if (res.tblspecificAssets.length > 0) {
            this.JSONData = res;
            res.tblspecificAssets.forEach((index) => {
              this.AssetIds.push(index.assetID);
            });

            const distinct = (value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            };

            this.AssetIds = this.AssetIds.filter(distinct);

            this.openReports();
          } else {
            Swal.fire(this.translateService.instant("Systemreports.alertassetdetail.nodata"));
            return;
          }
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  reset() {
    this.JSONData = [];
    this.AssetIds = [];
    this.openReportsButton = true;
  }

  onChangeAssetId() {
    this.openReportsButton = false;
  }

  getDocumentUrlFor(documentName: string) {
    //TSDRSAM-1945
    let contentType; let base64Data;
    const fileName = documentName.split('.'); const photoId = fileName[0]; const extension = fileName[1];
    return new Promise((resolve, reject) => {
      this.objAssetDetailService.getImageAsBase64(photoId, this.currentUser.clientId, extension.toLowerCase()).subscribe((res) => {
        if (extension.toLowerCase() == "pdf") {
          contentType = "application/pdf";
        } else {
          contentType = "image/png";
        }
        base64Data = res;

        const b64toBlob = (base64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(base64Data);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }
        const blob = b64toBlob(base64Data, contentType);
        resolve(blob);
      })
    });
  }

  getReportUrlForAsset(assetId: string) {
    try {
      const reportUrl = environment.apiBaseUrl + environment.apiroot + "SpecificAssetReport?AssetId=" + assetId;

      return reportUrl;
    } catch (error) {
      console.error(error);
    }
  }

  getFormattedReplaceHistorySegment(assetId: string, installDate: Date, isLastItem: boolean) {
    const finalFormattedSegment = " " + assetId + "-" + (installDate === null ? "?" : installDate.toLocaleString().split("T")[0]) + (isLastItem ? "" : " > ");

    return finalFormattedSegment;
  }

  openReports() {
    try {
      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      let arrData = typeof this.JSONData.tblspecificAssets !== "object" ? JSON.parse(this.JSONData.tblspecificAssets) : this.JSONData.tblspecificAssets;
      const arrData1 = typeof this.JSONData.tblspecificAssets1 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets1) : this.JSONData.tblspecificAssets1;
      const arrData2 = typeof this.JSONData.tblspecificAssets2 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets2) : this.JSONData.tblspecificAssets2;
      const arrData3 = typeof this.JSONData.tblspecificAssets3 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets3) : this.JSONData.tblspecificAssets3;
      const arrData4 = typeof this.JSONData.tblspecificAssets4 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets4) : this.JSONData.tblspecificAssets4;
      const arrData5 = typeof this.JSONData.tblspecificAssets5 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets5) : this.JSONData.tblspecificAssets5;
      const arrData6 = typeof this.JSONData.tblspecificAssets6 !== "object" ? JSON.parse(this.JSONData.tblspecificAssets6) : this.JSONData.tblspecificAssets6;

      arrData = arrData.filter((obj) => obj.assetID === this.AssetIdSelected);
      this.LayerNameSelected = arrData5[0]["layerName"];
      const tableheader = [];
      const tablebody = [];
      const tablebodyA = [];
      let tablerow = [];
      const tableheader1 = [];
      const tableHeader2 = [];
      const tableHeader3 = [];
      const tableHeader4 = [];
      const tablebody1 = [];

      this.tablebody2 = [];
      this.tablebody3 = [];
      this.tablebody4 = [];
      this.ContentVal = [];
      this.assetDetailReportDocs = [];
      this.currentReportStyle = this.reportStyle.getReportStyle();
       this.currentLayoutStyle = this.reportStyle.getReportLayout();

      this.ContentVal.push(
        {
          text: this.translateService.instant("Systemreports.headerassetdetail.assetdetail"),
          style: "header"
        },
        {
          stack: [
            {
              text: [
                {
                  text: this.translateService.instant("Systemreports.assetdetail.assetid") + ":" + "\xa0",
                  bold: true
                },
                {
                  text: this.AssetIdSelected
                }
              ]
            }
          ],
          style: "subheaderBottom"
        }
      );

      // Section 1
      let tb = [];

      if (this.LayerNameSelected === "Sign" || this.LayerNameSelected === "Light") {
        tableheader.push(
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetcode"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.width"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.height"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.installdate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.retiredate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.condition"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.status"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.replaceid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.facedir"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.sheettype"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.legend"), style: "tableHeader" }
        );
        tb = ["assetID", "assetcode", "dimWidth", "dimHeight", "installDate", "retireDate", "condition", "status", "replacesID", "faceDirection", "sheetType", "legend"];
      } else {
        tableheader.push(
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetcode"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.width"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.height"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.installdate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.retiredate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.condition"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.status"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.replaceid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.material"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.route"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.latitude"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.longitude"), style: "tableHeader" }
        );
        tb = ["assetID", "assetcode", "dimWidth", "dimHeight", "installDate", "retireDate", "condition", "status", "replacesID", "material", "route", "latitude", "longitude"];
      }

      tablebody.push(tableheader);

      tablerow = [];
      tb.forEach((index) => {
        if (arrData.length > 0) {
          if (index === "installDate" && arrData[0][index] !== null && arrData[0][index] !== undefined) {
            tablerow.push({ text: arrData[0][index].split("T")[0], style: "tableContent" });
          } else if (index === "retireDate" && arrData[0][index] !== null && arrData[0][index] !== undefined) {
            tablerow.push({ text: arrData[0][index].split("T")[0], style: "tableContent" });
          } else if (arrData[0][index] === null) {
            tablerow.push({ text: "", style: "tableContent" });
          } else {
            tablerow.push({ text: arrData[0][index], style: "tableContent" });
          }
        }
        else {
          console.log("No data in arrData");
        }
      });
      tablebody.push(tablerow);

      //Section-A - Replace history at end of section-1 as transposed table
      if (arrData6 != null && arrData6.length > 0) {
        tablerow = [];
        const tb6 = ["installDate", "assetID", "currentAssetMarker"];

        tb6.forEach((index) => {
          tablerow = [];

          for (let i = 0; i < arrData6.length; i++) {
            //write header column first
            if (i == 0) {
              if (index == "installDate") {
                tablerow.push({ text: this.translateService.instant("Systemreports.headerassetdetail.installdate"), style: "tableHeader" });
              } else if (index == "assetID") {
                tablerow.push({ text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" });
              } else {
                tablerow.push({ text: "", style: "tableContent", border: [false, false, false, false] });
              }
            }

            //then fill up content columns
            if (arrData6[i][index] === null) {
              tablerow.push({ text: "", style: "tableContent" });
            } else if (arrData6[i]["assetID"] === this.AssetIdSelected) {
              if (index != "currentAssetMarker") {
                tablerow.push({ text: arrData6[i][index], style: "rowGroupHeader" });
              } else {
                tablerow.push({ text: "*", style: "tableContent", alignment: "center", border: [false, false, false, false] });
              }
            } else {
              if (index != "currentAssetMarker") {
                tablerow.push({ text: arrData6[i][index], style: "tableContent" });
              } else {
                tablerow.push({ text: arrData6[i][index], style: "tableContent", border: [false, false, false, false] });
              }
            }
          }

          tablebodyA.push(tablerow);
        });
      }

      // Section 2
      if (arrData1 != null && arrData1.length > 0) {
        tableheader1.push(
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.assetcode"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.status"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.width"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.height"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.installdate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.retiredate"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.condition"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headerassetdetail.replaceid"), style: "tableHeader" }
        );
        tablebody1.push(tableheader1);
        const tb1 = ["assetID", "assetcode", "status", "dimWidth", "dimHeight", "installDate", "retireDate", "condition", "replacesID"];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arrData1.length; i++) {
          tablerow = [];
          tb1.forEach((index) => {
            if (arrData1[i][index] === null) {
              tablerow.push({ text: "", style: "tableContent" });
            } else {
              tablerow.push({ text: arrData1[i][index], style: "tableContent" });
            }
          });
          tablebody1.push(tablerow);
        }
      }

      // Section 3
      tableHeader2.push(
        { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.completedate"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.activity"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.reason"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.completedby"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.worker"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.comments"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.wodesc"), style: "tableHeader" }
      );
      this.tablebody2.push(tableHeader2);
      const tb2 = ["assetID", "completionDate", "activity", "reason", "completedBy", "worker", "comment", "workOrderDesc"];

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrData2.length; i++) {
        tablerow = [];
        tb2.forEach((index) => {
          if (index === "completionDate" && arrData2[i][index] !== null && arrData2[i][index] !== undefined) {
            tablerow.push({ text: arrData2[i][index].split("T")[0], style: "tableContent" });
          } else if (arrData2[i][index] === null) {
            tablerow.push({ text: "", style: "tableContent" });
          } else {
            tablerow.push({ text: arrData2[i][index], style: "tableContent" });
          }
        });
        this.tablebody2.push(tablerow);
      }

      // Section 4
      tableHeader3.push(
        { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.type"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.inspector"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.date"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.condition"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.comments"), style: "tableHeader" }
      );
      this.tablebody3.push(tableHeader3);
      const tb3 = ["assetID", "assessmentType", "inspector", "inspectionDate", "condition", "comment"];

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrData3.length; i++) {
        tablerow = [];
        tb3.forEach((index) => {
          if (index === "inspectionDate" && arrData3[i][index] !== null && arrData3[i][index] !== undefined) {
            tablerow.push({ text: arrData3[i][index].split("T")[0], style: "tableContent" });
          } else if (arrData3[i][index] === null) {
            tablerow.push({ text: "", style: "tableContent" });
          } else {
            tablerow.push({ text: arrData3[i][index], style: "tableContent" });
          }
        });
        this.tablebody3.push(tablerow);
      }

      // Section 5
      tableHeader4.push(
        { text: this.translateService.instant("Systemreports.headerassetdetail.assetid"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.uploaddate"), style: "tableHeader" },
        { text: this.translateService.instant("Systemreports.headerassetdetail.docname"), style: "tableHeader" }
      );
      this.tablebody4.push(tableHeader4);
      const tb4 = ["assetID", "uploadDate", "actualFileName"];


      // Section 1
      if (this.LayerNameSelected === "Sign" || this.LayerNameSelected === "Light") {
        this.ContentVal.push(
          {
            text: this.translateService.instant("Systemreports.headerassetdetail.assetSearched"),
            style: "rowGroupHeader"
          },
          {
            style: "tableExample",
            table: {
              widths: [65, "auto", 35, 40, "auto", "auto", 60, 40, "auto", "auto", "auto", "auto"],
              headerRows: 1,
              body: tablebody
            },
            layout: this.currentLayoutStyle
          }
        );
      } else {
        this.ContentVal.push(
          {
            text: this.translateService.instant("Systemreports.headerassetdetail.assetSearched"),
            style: "rowGroupHeader"
          },
          {
            style: "tableExample",
            table: {
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", 80, "auto", "auto"],
              headerRows: 1,
              body: tablebody
            },
            layout: this.currentLayoutStyle
          }
        );
      }

      //Section A(ReplaceHistory)
      this.ContentVal.push(
        {
          text: this.translateService.instant("Systemreports.headerassetdetail.replaceHistory"),
          style: "rowGroupHeader"
        },
        {
          style: "tableExample",
          table: {
            widths: Array(arrData6.length + 1).fill("auto"), //as we transposed table, ror count becomes column count
            headerRows: 0,
            body: tablebodyA
          },
          layout: this.currentLayoutStyle,
          margin: [0, 5, 0, 30]
        }
      );

      // Section 2
      if (arrData1 != null && arrData1.length > 0) {
        this.ContentVal.push(
          {
            text: this.translateService.instant("Systemreports.headerassetdetail.associatedAsset"),
            style: "rowGroupHeader"
          },
          {
            style: "tableExample",
            table: {
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              headerRows: 1,
              body: tablebody1
            },
            layout: this.currentLayoutStyle
          }
        );
      }

      if (arrData4.length > 0) {
        let b = 0;
        
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arrData4.length; i++) {
          let a = arrData4.length;
          this.getDocumentUrlFor(arrData4[i]["actualFileName"]).then((result) => {
            tablerow = [];
            tb4.forEach((index) => {
              if (index === "uploadDate" && arrData4[i][index] !== null && arrData4[i][index] !== undefined) {
                tablerow.push({ text: arrData4[i][index].split("T")[0], style: "tableContent" });
              } else if (arrData4[i][index] === null) {
                tablerow.push({ text: "", style: "tableContent" });
              } else if (index === "actualFileName") {
                
                let objDocuments = {
                  fileName: arrData4[i][index],
                  blobData: result,
                };
                this.assetDetailReportDocs.push(objDocuments);
                tablerow.push({ text: arrData4[i][index], style: "tableContent" });
                b++;
              } else {
                tablerow.push({ text: arrData4[i][index], style: "tableContent" });
              }
            });

            this.tablebody4.push(tablerow);
            if (a == b) {
              this.generatereport();
            }
          })
            .catch((error) => {
              console.error(error);
            });
        }
      }
      else {
        this.generatereport();
      }
    } catch (error) {
      console.error(error);
    }
  }

  generatereport()
  {
    this.ContentVal.push(
      {
        text: this.translateService.instant("Systemreports.headerassetdetail.maintenance"),
        style: "rowGroupHeader"
      },
      {
        style: "tableExample",
        table: {
          widths: ["auto", "auto", "auto", "auto", "auto", "auto", 160, 160],
          headerRows: 1,
          body: this.tablebody2
        },
        layout: this.currentLayoutStyle
      },
      {
        text: this.translateService.instant("Systemreports.headerassetdetail.assesments"),
        style: "rowGroupHeader"
      },
      {
        style: "tableExample",
        table: {
          widths: ["auto", "auto", "auto", "auto", "auto", 160],
          headerRows: 1,
          body: this.tablebody3
        },
        layout: this.currentLayoutStyle
      },
      {
        text: this.translateService.instant("Systemreports.headerassetdetail.documents"),
        style: "rowGroupHeader"
      },
      {
        style: "tableExample",
        table: {
          widths: ["auto", "auto", 160],
          headerRows: 1,
          body: this.tablebody4
        },
        layout: this.currentLayoutStyle
      }
    );
    const dd = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      fontSize: 18,
      footer: function (currentPage, pageCount) {
        return {
          height: 20,
          columns: [
            {
              stack: [
                {
                  table: {
                    widths: [20, "auto"],
                    body: [
                      [
                        {
                          text: "\xa0"
                        },
                        {
                          text: "* Indicates current record",
                          fillColor: "#d3e9ff"
                        }
                      ]
                    ]
                  },
                  layout: "noBorders"
                },
                {
                  text: new Date().toLocaleString(),
                  style: "footerLeft"
                }
              ]
            },
            {
              stack: [
                {
                  text: "\xa0",
                  style: "footerLeft"
                },
                {
                  text: currentPage.toString() + " of " + pageCount,
                  style: "footerCenter"
                }
              ]
            }
          ]
        };
      },
      content: this.ContentVal,
      styles: this.currentReportStyle
    };
    const pdfDocGenerator = pdfMake.createPdf(dd);
    pdfDocGenerator.getBlob((pdfblob) => {
      const zip = new JSZip();
      this.currentdate = new Date();
      this.currenttimestamp = this.currentdate.getTime();
      let fileName = "AssetDetails_" + this.AssetIdSelected + "_" + this.currenttimestamp;

      zip.file(fileName + ".pdf", pdfblob);
      this.assetDetailReportDocs.forEach((f: any) => {
        zip.file(f.fileName, f.blobData);
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver.saveAs(content, fileName + ".zip");
      });
    });
  }
}
