import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../../services/authentication.service";
import { GenericLayerService } from "../../../../services/genericLayer.service";
import { Subscription, Observable } from "rxjs";
import { IUser } from "src/app/interface/user";
import { CrewManagementService } from "../../../crewmanagement.service";

@Component({
  selector: "app-sms-assignment",
  templateUrl: "./assignment.component.html",
  styleUrls: ["./assignment.component.scss"]
})
export class AssignmentComponent implements OnInit {
  @Output() crewTasksData = new EventEmitter();
  @Input() events: Observable<void>;
  private eventsSubscription: Subscription;

  assignmentTdata;
  assignments;
  totalassignments;
  regions: any = [];
  asset: any = [];
  dateToday;
  selectedregion;
  selectedAssetClass;
  selectedRow;
  selectedDate;
  totalUserList;
  Primarylangvalid;
  Secodarylangvalid;
  private currentUser: IUser;
  constructor(private authenticationService: AuthenticationService, private genericLayerService: GenericLayerService, private crewManagementService: CrewManagementService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.currentUser = this.authenticationService.currentUserValue;
    this.currentUser = this.authenticationService.currentUserValue;
    this.selectedregion = this.crewManagementService.selectedRegion;
    this.selectedAssetClass = this.crewManagementService.selectedLayer;
    this.asset = this.crewManagementService.layersList;
    this.regions = this.crewManagementService.regionsList;

    this.totalassignments = this.crewManagementService.totalrecords;
    this.crewManagementService.regions.subscribe((result) => {
      console.log(this.crewManagementService.regionsList);
      this.selectedregion = this.crewManagementService.selectedRegion;
      this.regions = this.crewManagementService.regionsList;
    });
    this.crewManagementService.layers.subscribe((result) => {
      console.log(this.crewManagementService.layersList);
      this.selectedAssetClass = this.crewManagementService.selectedLayer;
      this.asset = this.crewManagementService.layersList;
    });
    this.crewManagementService.records.subscribe((result) => {
      const date = new Date();
      this.dateToday = date.toLocaleDateString();
      this.selectedDate = this.dateToday;
      this.totalassignments = result;
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    });
  }

  ngOnInit() {
    this.crewManagementService.getRecords();
    console.log(this.crewManagementService.layersList);
    console.log("task deleted event from assiment");
    const date = new Date();
    this.dateToday = date.toLocaleDateString();
    this.selectedDate = this.dateToday;
    document.getElementById("btntoday").classList.add("active");

    if (this.totalassignments && this.totalassignments.length > 0) {
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    }
  }
  groupByrecords(resultArray, date, selectedregion, selectedAssetClass, totalUserList) {
    try {
      const result = [];
      this.assignments = result;
      resultArray.reduce((res, value) => {
        if (value.attributes.Region != null && value.attributes.SheduledDate != null && value.attributes.AssetName != null) {
          if (new Date(value.attributes.SheduledDate).toLocaleDateString() === date && value.attributes.AssetName === selectedAssetClass && value.attributes.Region === selectedregion) {
            if (value.attributes.Worker == null) {
              value.attributes.Worker = "Unassigned";
            }

            if (!res[value.attributes.Worker]) {
              res[value.attributes.Worker] = {
                user: value.attributes.Worker,
                noOfTasks: 0,
                duration: 0,
                tasks: []
              };
              result.push(res[value.attributes.Worker]);
            }

            res[value.attributes.Worker].noOfTasks += 1;
            res[value.attributes.Worker].duration += value.attributes.EstimatedDuration;
            res[value.attributes.Worker].tasks.push(value.attributes);
          }
        }

        return res;
      }, {});

      // tslint:disable-next-line:prefer-for-of
      for (let user = 0; user < totalUserList.length; user++) {
        if (
          totalUserList[user].regions.filter((regionVal) => {
            if (regionVal.regionName === selectedregion && regionVal.accessPrivilege === "Data Editor") {
              return regionVal;
            }
          }).length > 0
        ) {
          // if(result.reduce(function(value){ return value.user == totalUserList[user].username }).length >0)
          if (result.filter((e) => e.user === totalUserList[user].username).length === 0) {
            result.push({
              user: totalUserList[user].username,
              noOfTasks: 0,
              duration: 0
            });
          }
        }
      }

      const sortBy = [
        {
          prop: "no_of_tasks",
          direction: -1
        },
        {
          prop: "username",
          direction: -1
        }
      ];

      this.assignments.sort((a, b) => {
        let i = 0;
        // tslint:disable-next-line:no-shadowed-variable
        let result = 0;

        while (i < sortBy.length && result === 0) {
          if (a[sortBy[i].prop] && b[sortBy[i].prop]) {
            result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0);
          }

          i++;
        }

        return result;
      });
      //  this.assignments.sort((a, b) => (a.user > b.user) ? 1 : ((b.user > a.user) ? -1 : 0));
    } catch (error) {
      console.error(error);
    }
  }
  filterdatabyDate(date) {
    try {
      if (date.tab.textLabel === "today") {
        this.selectedDate = this.dateToday;
        this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        this.changeactivebtn("today");
      } else {
        const tomorrow = new Date(this.dateToday);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.selectedDate = tomorrow.toLocaleDateString();
        this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        this.changeactivebtn("tomorrow");
      }
    } catch (error) {
      console.error(error);
    }
  }

  changeactivebtn(today) {
    try {
      if (today === "today") {
        document.getElementById("btntoday").classList.add("active");
        document.getElementById("btntomorrow").classList.remove("active");
      } else {
        document.getElementById("btntoday").classList.remove("active");
        document.getElementById("btntomorrow").classList.add("active");
      }
    } catch (error) {
      console.error(error);
    }
  }
  onRegionChange(evt) {
    try {
      console.log(evt);
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedRegion = this.selectedregion;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
    } catch (error) {
      console.error(error);
    }
  }
  onAssetChange(event) {
    try {
      console.log(event);
      this.selectedAssetClass = event;
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedLayer = this.selectedAssetClass;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
    } catch (error) {
      console.error(error);
    }
  }
  rowclicked(data, ind) {
    try {
      this.crewManagementService.tasksModified.emit(true);
      this.selectedRow = ind;
      data.date = this.selectedDate;
      data.region = this.selectedregion;
      data.assetclass = this.selectedAssetClass;
      this.crewTasksData.emit(data);
    } catch (error) {
      console.error(error);
    }
  }
}
