import { Component, OnInit, Input } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { UserService } from "src/app/services/user.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ChartOptions } from "chart.js";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import Swal from "sweetalert2";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import { TranslateService } from "@ngx-translate/core";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-democratizeassetinformation",
  templateUrl: "./democratizeAssetInformation.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class DemocratizeAssetInformation implements OnInit {
  constructor(
    public systemReportsService: SystemReportsService,
    private userService: UserService,
    private genericService: GenericLayerService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  @Input() map;
  region;
  isdisable = true;
  currentUser;
  barChartLabel;
  selectedRegion;
  RegionsListByClient;
  democratizeassets = [];
  humanfactor = [];
  buttons = [];
  faTimes = faTimes;
  faDownload = faDownload;
  AssetType = [];
  ServiceData = [];
  LayerName = [];
  arrayValue = [];
  public pieChartType = "pie";
  public pieChartLabelsRA: string[];
  public pieChartLabelsAC: string[];
  public pieChartLabelsAD: string[];
  public pieChartDataRA: number[];
  public pieChartDataAC: number[];
  public pieChartDataAD: number[];
  public PieChartRA = false;
  public PieChartAC = false;
  public PieChartAD = false;
  private showValues = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        color: "black",
        backgroundColor: "white",
        borderRadius: 1,
        borderWidth: 1,
        offset: 4,
        padding: 0,
        font: {
          size: 11
        }
      }
    }
  };

  public pieChartOptionsRA: ChartOptions = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data: any) {
          const dataset = data.datasets[0];
          const percent = ((dataset.data[tooltipItem.index] / dataset._meta[8].total) * 100).toFixed(2);
          return data.labels[tooltipItem.index] + " : " + data.datasets[0].data[tooltipItem.index] + " , " + percent + "%";
        }
      },
      backgroundColor: "#FFF",
      titleFontSize: 16,
      titleFontColor: "#0066ff",
      bodyFontColor: "#000",
      bodyFontSize: 14,
      displayColors: false
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        color: "black",
        backgroundColor: "white",
        borderRadius: 1,
        borderWidth: 1,
        offset: 4,
        padding: 0,
        font: {
          size: 12
        }
      }
    }
  };

  public pieChartOptionsAD: ChartOptions = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data: any) {
          const dataset = data.datasets[0];
          const percent = ((dataset.data[tooltipItem.index] / dataset._meta[11].total) * 100).toFixed(2);
          return data.labels[tooltipItem.index] + " : " + data.datasets[0].data[tooltipItem.index] + " , " + percent + "%";
        }
      },
      backgroundColor: "#FFF",
      titleFontSize: 16,
      titleFontColor: "#0066ff",
      bodyFontColor: "#000",
      bodyFontSize: 14,
      displayColors: false
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        color: "black",
        backgroundColor: "white",
        borderRadius: 1,
        borderWidth: 1,
        offset: 4,
        padding: 0,
        font: {
          size: 12
        }
      }
    }
  };
  public pieChartOptionsAC: ChartOptions = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data: any) {
          const dataset = data.datasets[0];
          const percent = ((dataset.data[tooltipItem.index] / dataset._meta[5].total) * 100).toFixed(2);
          return data.labels[tooltipItem.index] + " : " + data.datasets[0].data[tooltipItem.index] + " , " + percent + "%";
        }
      },
      backgroundColor: "#FFF",
      titleFontSize: 16,
      titleFontColor: "#0066ff",
      bodyFontColor: "#000",
      bodyFontSize: 14,
      displayColors: false
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        color: "black",
        backgroundColor: "white",
        borderRadius: 1,
        borderWidth: 1,
        offset: 4,
        padding: 0,
        font: {
          size: 12
        }
      }
    }
  };

  public barChartLabels: string[] = ["2006", "2007", "2008", "2009", "2010", "2011", "2012"];
  public barChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: "Graffiti" },
    { data: [28, 48, 40, 19, 86, 27, 90], label: "Vandalism" },
    { data: [24, 40, 45, 13, 66, 37, 70], label: "Accidents" }
  ];

  ngOnInit() {
    this.RegionsListByClient = [];
    this.RegionsListByClient = this.map.smsRegiondata;
    this.buttons = this.democratizeassets;
    this.buttons.unshift({ name: "Summary", count: 3 });
  }
  regionOnChange() {
    try {
      //const obj = "?RegionName=" + this.selectedRegion.regionName + "&RegionLevel=" + this.selectedRegion.regionLevel;
      this.ServiceData = [];
      // this.systemReportsService.getDemocratize(this.currentUser.clientId, obj).subscribe((res) => {
      //   this.ShowActivityTypes(res);
      // }, (err) => {
      //   console.error(err);
      // })
    } catch (error) {
      console.error(error);
    }
  }
  openReports() {
    try {
      if (this.selectedRegion !== undefined) {
        this.region = this.selectedRegion.regionName;
        const obj = "?RegionName=" + this.selectedRegion.regionName + "&RegionLevel=" + this.selectedRegion.regionLevel;
        this.ServiceData = [];
        this.systemReportsService.getDemocratize(this.currentUser.clientId, obj).subscribe(
          (res) => {
            document.getElementById("myForm").style.display = "block";
            this.ServiceData.push(res);
            this.YearClick();
            this.fetchLayers();
          },
          (err) => {
            Swal.fire("Please contact administrator. API is throwing an error");
          }
        );
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertdemocratizeasset.regionnotselected"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  ShowActivityTypes(res) {
    try {
      this.showValues = true;
      this.arrayValue = [];
      const val = res.table2.filter((obj) => obj.yqm.includes("Y"));
      val.forEach((data) => {
        this.arrayValue.push(data.damageCause);
      });
    } catch (error) {
      console.error(error);
    }
  }

  AssetClassValue(layer) {
    try {
      if (layer !== "Total") {
        const obj = "?RegionName=" + this.selectedRegion.regionName + "&RegionLevel=" + this.selectedRegion.regionLevel + "&LayerName=" + layer;
        this.ServiceData = [];
        this.systemReportsService.getDemocratize(this.currentUser.clientId, obj).subscribe(
          (res) => {
            document.getElementById("myForm").style.display = "block";
            this.ServiceData.push(res);
            this.YearClick();
          },
          (err) => {
            console.error(err);
          }
        );
      } else {
        this.openReports();
      }

      this.toggleLayerButton(layer);
    } catch (error) {
      console.error(error);
    }
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }
  public chartHovered(e: any): void {
    console.log(e);
  }

  close() {
    document.getElementById("myForm").style.display = "none";
  }

  YearClick() {
    try {
      this.barChartLabel = "1 Year - Damage Case";
      this.PieChartRA = this.PieChartAC = this.PieChartAD = true;
      this.toggleRemove("YearButton");
      this.ValuesBasedOnSection("Y");
    } catch (error) {
      console.error(error);
    }
  }

  MonthClick() {
    try {
      this.barChartLabel = "1 Month - Damage Case";
      this.toggleRemove("MonthButton");
      this.ValuesBasedOnSection("M");
    } catch (error) {
      console.error(error);
    }
  }

  QuarterClick() {
    try {
      this.barChartLabel = "3 Months - Damage Case";
      this.toggleRemove("QuarterButton");
      this.ValuesBasedOnSection("Q");
    } catch (error) {
      console.error(error);
    }
  }

  toggleRemove(stringName: string) {
    try {
      const ToggleText = ["YearButton", "QuarterButton", "MonthButton"];
      const componentType = document.getElementById(stringName);
      const hasClassCT = componentType.classList.contains("i-active");

      if (!hasClassCT) {
        componentType.classList.add("i-active");
      }

      ToggleText.forEach((val) => {
        if (val !== stringName) {
          if (document.getElementById(val).classList.contains("i-active")) {
            document.getElementById(val).classList.remove("i-active");
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  toggleLayerButton(stringName: string) {
    try {
      const componentType = document.getElementById(stringName);
      const hasClassCT = componentType.classList.contains("i-active");

      if (!hasClassCT) {
        componentType.classList.add("i-active");
      }

      this.LayerName.forEach((val) => {
        if (val.Layer !== stringName) {
          if (document.getElementById(val.Layer).classList.contains("i-active")) {
            document.getElementById(val.Layer).classList.remove("i-active");
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  fetchLayers() {
    try {
      this.LayerName = [];
      const Name = this.ServiceData[0].table.filter((obj) => obj.yqm.includes("Y"));
      Name.forEach((data) => {
        if (data.layer === "Total") {
          this.LayerName.push({ Layer: data.layer, alias: "Summary" });
        } else {
          this.LayerName.push({ Layer: data.layer, alias: data.layer });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  ValuesBasedOnSection(val) {
    try {
      this.Clear();
      this.democratizeassets = this.ServiceData[0].table.filter((obj) => obj.yqm.includes(val));
      this.humanfactor = this.ServiceData[0].table1.filter((obj) => obj.yqm.includes(val));
      const AssetConditionPieChart = this.ServiceData[0].table3.filter((obj) => obj.yqm.includes(val));
      AssetConditionPieChart.forEach((data) => {
        this.pieChartDataAC.push(data.conditionCount);
        this.pieChartLabelsAC.push(data.condition);
        // this.pieChartColorAC.push({
        //   backgroundColor: this.getRandomColorEachEmployee(AssetConditionPieChart.length)
        // })
      });
      const RoadAssetPieChart = this.ServiceData[0].table4.filter((obj) => obj.yqm.includes(val));
      RoadAssetPieChart.forEach((data) => {
        // this.arrayValue.forEach((val) => {
        //   if (val === data.damageCause && val.checked) {
        this.pieChartDataRA.push(data.replaceCount);
        this.pieChartLabelsRA.push(data.damageCause);
        //   }
        // })
      });
      const ActivityDamagesPieChart = this.ServiceData[0].table5.filter((obj) => obj.yqm.includes(val));
      ActivityDamagesPieChart.forEach((data) => {
        this.pieChartDataAD.push(data.activityCount);
        this.pieChartLabelsAD.push(data.activity);
      });
      const ActivityDamagesBarChart = this.ServiceData[0].table2.filter((obj) => obj.yqm.includes(val));

      if (ActivityDamagesBarChart.length > 0) {
        const barChartLabel = JSON.parse(ActivityDamagesBarChart[0].lastModifiedMonthName);
        barChartLabel.forEach((barChartVal) => {
          this.barChartLabels.push(barChartVal);
        });
      }

      ActivityDamagesBarChart.forEach((actDamageCal) => {
        this.barChartData.push({
          data: JSON.parse(actDamageCal.casesCount),
          label: actDamageCal.damageCause
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  getRandomColorEachEmployee(count) {
    try {
      const data = [];

      for (let i = 0; i < count; i++) {
        data.push(this.getRandomColor());
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  getRandomColor() {
    try {
      const letters = "0123456789ABCDEF".split("");
      let color = "#";

      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    } catch (error) {
      console.error(error);
    }
  }

  Clear() {
    this.pieChartDataAC = [];
    this.pieChartLabelsAC = [];
    this.pieChartDataRA = [];
    this.pieChartLabelsRA = [];
    this.pieChartDataAD = [];
    this.pieChartLabelsAD = [];
    this.barChartData = [];
    this.barChartLabels = [];
    this.democratizeassets = [];
    this.humanfactor = [];
  }

  makePDF() {
    try {
      html2canvas(document.querySelector("#myForm")).then((canvas) => {
        const docDefinition = {
          pageOrientation: "landscape",
          content: [
            {
              image: canvas.toDataURL(),
              width: 750,
              height: 500
            }
          ]
        };
        pdfMake.createPdf(docDefinition).open();
      });
    } catch (error) {
      console.error(error);
    }
  }
}
