import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-change-report',
  templateUrl: './change-report.component.html',
  styleUrls: ['./change-report.component.scss']
})
export class ChangeReportComponent {

  @Input() FeatureLayer: any;
  @Input() Graphic: any;

  jobId : number

  selectJob(event){
    this.jobId = event;
  }
}
