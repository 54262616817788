<div class="row">
    <ul class="search-tools pl-2">
        <li>
            <div class="btn-secondary mr-3 i-inactive search-left-btn" (click)="swithSearchWidget('Open')"
                [style.display]="searchWidgetOpen ? 'inline-block':'none'">
                <span class="icon icon-righarrow small"></span>
            </div>
        </li>
        <!-- <div [style.display]="searchWidgetClose ? 'inline-block':'none'"> -->
            <li class="mr-3">
                <div class="mr-3" [style.display]="textBlock ? 'none':'inline-block'">
                    <form data-dojo-attach-point="formNode" [style.display]="textBlock ? 'none':'inline-block'">
                        <input maxlength="128" type="text" tabindex="0" class="SearchInputBox" role="textbox"
                            placeholder="{{'Search-tool.icon.placeholdertxt'|translate}}" aria-label="Search">
                                               </form>
                </div>
                <div id='searchWidgetDiv' aria-label="Search Widget"></div>
            </li>
            
            <li>
                <div class="icon-bar">
                    <div class="action-item-container">
                        <div class="action-item">
                            <a href="#" title="{{'Search-tool.icon.assetsearch'|translate}}" id='AssetSearch'
                                [attr.aria-label]="''+'Search-tool.icon.assetsearch'|translate" 
                                [ngClass]="{ 'i-inactive': !isAssetSearchtEnabled, 'i-active': isAssetSearchtEnabled }"
                                (click)="AssetSearch('AssetSearch')" data-mainPageTabbing="yes">
                                <mat-icon svgIcon="find_in_page" [ngClass]="{ 'i-inactive': !isAssetSearchtEnabled, 'i-active': isAssetSearchtEnabled }"></mat-icon>
                            </a>
                        </div>
                        <div class="action-item">
                            <a href="#" title="{{'Search-tool.icon.addressearch'|translate}}" id='AddressSearch'
                                [attr.aria-label]="''+'Search-tool.icon.addressearch'|translate" data-mainPageTabbing="yes"
                                [ngClass]="{ 'i-inactive': !isAddressSearchEnabled, 'i-active': isAddressSearchEnabled }"

                                (click)="AddressSearch('AddressSearch')">
                                <mat-icon svgIcon="home_pin" [ngClass]="{ 'i-inactive': !isAddressSearchEnabled, 'i-active': isAddressSearchEnabled }"></mat-icon>
                            </a>
                        </div>
                        <div class="action-item">
                            <a href="#" title="{{'Search-tool.icon.proximitysearch'|translate}}"
                                (click)="ProximitySearch('ProximitySearch')" id='ProximitySearch'
                                [ngClass]="{ 'i-inactive': !isProximitySearchEnabled, 'i-active': isProximitySearchEnabled }"
                                [attr.aria-label]="''+'Search-tool.icon.proximitysearch'|translate" data-mainPageTabbing="yes"
                                *ngIf="currentUser.soultionTiers[0][0].proximity_Assets">
                                <mat-icon svgIcon="location_searching" [ngClass]="{ 'i-inactive': !isProximitySearchEnabled, 'i-active': isProximitySearchEnabled }"></mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        <!-- </div> -->
    </ul>
</div>