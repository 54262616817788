import { Component, OnInit, EventEmitter, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CellRenderComponent } from "../CellRenderComponent/cell-render.component";
import { HelpCenterService } from "../../services/help-center.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import { faUpload, faSearch, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { GridOptions } from "ag-grid-community";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { AssetDetailService } from "src/app/services/assetDetail.service";
@Component({
  selector: "sms-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
  //Icons
  faPlusSquare = faPlusSquare;
  faPaperclip = faPaperclip;

  @ViewChild("queryForm") content;
  viewMode: boolean;
  frameworkComponents: any;
  subject: any;
  description: any;
  solution: any;
  faUpload = faUpload;
  faSearch = faSearch;
  searchClosedTab: any;
  searchOpenTab: any;
  attachement: any;
  imageError: any;
  gridApiOpenTab: any;
  gridApiClosedTab: any;
  file: any = null;
  rowDataOpen: any = [];
  rowDataClosed: any = [];
  openTicketCount: any = "";
  currentUser: IUser;
  rowSelection = "single";
  tokenNo: any;
  url: any = "";
  imageSrc: any;
  showSolution: boolean;
  modifiedBy: any;
  modifiedDate: any;
  checkOpen: any = true;
  checkClose: any = true;
  @Output() closeHelpEvent = new EventEmitter();

  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true
    }
  };

  columnDefs: any = [
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.tokennumber"),
      field: "tokenNo",
      cellRenderer: "cellRendererComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.date"),
      field: "createdDate",
      cellRenderer: (data) => {
        return data.value ? this.datePipe.transform(data.value, "MM/dd/yyyy hh:mm a") : "";
      }
    },
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.username"),
      field: "createdBy"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.subject"),
      field: "subject"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.description"),
      field: "description"
    },
    {
      headerName: this.translateService.instant("Helpcenter.tableuser.attachment"),
      field: "url"
    }
  ];

  constructor(
    private modalService: NgbModal,
    private helpCenterService: HelpCenterService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    public objAssetDetailService: AssetDetailService
  ) {
    this.frameworkComponents = {
      cellRendererComponent: CellRenderComponent
    };
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    console.log("help center init");
    console.log(this.openTicketCount);
  }
  /** Close Help Center Popup */
  closeHelp() {
    this.closeHelpEvent.emit(false);
  }
  /** Reset All Fields */
  reset() {
    try {
      this.subject = null;
      this.description = null;
      this.attachement = null;

      if (this.file != null) {
        if (this.file.target.files && this.file.target.files[0]) {
          (document.getElementById("attachement") as HTMLInputElement).value = null;
        }
      }

      this.file = null;
    } catch (error) {
      console.error(error);
    }
  }
  /** Get All Ticket Details */
  getAllTickets() {
    try {
      this.helpCenterService.GetAllTickets().subscribe(
        (resultArray) => {
          const obj = JSON.parse(resultArray.toString());
          this.rowDataOpen = obj.filter((x) => x.status.toString().toLowerCase() === "open");

          if (this.checkOpen) {
            this.gridApiOpenTab.setRowData(
              this.rowDataOpen
                .sort((a: any, b: any) => {
                  return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
                })
                .filter((x) => x.createdBy.toString().toLowerCase() === this.currentUser.username.toString().toLowerCase())
            );
          } else {
            this.gridApiOpenTab.setRowData(
              this.rowDataOpen.sort((a: any, b: any) => {
                return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
              })
            );
          }

          this.rowDataClosed = obj.filter((x) => x.status.toString().toLowerCase() === "closed");

          if (this.checkClose) {
            this.gridApiClosedTab.setRowData(
              this.rowDataClosed
                .sort((a, b) => {
                  return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
                })
                .filter((x) => x.createdBy.toString().toLowerCase() === this.currentUser.username.toString().toLowerCase())
            );
          } else {
            this.gridApiClosedTab.setRowData(
              this.rowDataClosed.sort((a, b) => {
                return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
              })
            );
          }

          const myRequest = obj.filter((x) => x.createdBy.toString().toLowerCase() === this.currentUser.username.toString().toLowerCase() && x.status.toString().toLowerCase() === "open");

          if (myRequest.length > 0) {
            this.openTicketCount = myRequest.length;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  /** Create New Incident */
  onSubmit() {
    try {
      const formData = new FormData();

      if (this.subject == null || this.subject === undefined || this.subject === "") {
        Swal.fire(this.translateService.instant("Helpcenter.alertuser.entersubject"));
        return;
      }

      if (this.description == null || this.description === undefined || this.description === "") {
        Swal.fire(this.translateService.instant("Helpcenter.alertuser.enterdescription"));
        return;
      }

      if (this.file != null) {
        if (this.file.target.files && this.file.target.files[0]) {
          formData.append("File", this.file.target.files[0], this.file.target.files[0].name.toLowerCase());
        } else {
          formData.append("File", null);
        }
      }

      formData.append("Subject", this.subject);
      formData.append("Description", this.description);
      this.helpCenterService.CreateRequest(formData).subscribe(
        (resultArray: any) => {
          const result = JSON.parse(resultArray);

          if (result.message === "Inserted Successfully") {
            this.reset();
            this.file = null;
            this.getAllTickets();
            Swal.fire(this.translateService.instant("Helpcenter.alertuser.incidentcreated"));
            this.modalService.dismissAll();
          } else {
            Swal.fire(this.translateService.instant("Helpcenter.alertuser.erroronsaving"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  /** Load Incident Related Data to Fields */
  onRowEdit(tokenNo: string) {
    this.tokenNo = null;
    this.modifiedBy = null;
    this.modifiedDate = null;
    this.url = null;
    this.viewMode = false;
    this.modalService.open(this.content, { centered: true });
    this.helpCenterService.GetTicketByTokenID(tokenNo).subscribe(
      (resultArray: any[]) => {
        if (resultArray.length > 0) {
          this.subject = resultArray[0].subject;
          this.description = resultArray[0].description;
          this.solution = resultArray[0].solution;
          this.url = resultArray[0].url;
          this.tokenNo = resultArray[0].tokenNo;
          this.modifiedBy = resultArray[0].modifiedBy;
          this.modifiedDate = this.datePipe.transform(resultArray[0].modifiedDate, "MM/dd/yyyy hh:mm a");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /** View Attachement */
  showAttachement(DocViewer) {
    try {
      this.modalService.open(DocViewer, { centered: true, size: "lg" });

      if (this.url != null) {
        this.imageGenerate(); //TSDRSAM-2045
      }
    } catch (error) {
      console.error(error);
    }
  }
  imageGenerate(data?) {
    try {
      this.imageSrc = "";
      let contentType = "image/png";
      //here docType = (this.url)filename with extension.For example - Filename.jpg
      this.objAssetDetailService.getImageAsBase64(this.tokenNo, this.currentUser.clientId, this.url.toLowerCase(), true).subscribe((base64Image: any) => {
        if (base64Image) this.imageSrc = `data:${contentType};base64, ${base64Image}`;
      });
    } catch (error) {
      console.error(error);
    }
  }
  /** File Upload Validation */
  fileChangeEvent(fileInput: any) {
    try {
      this.imageError = null;

      if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const maxSize = 4000000;
        const allowedTypes = ["image/jpeg"];

        if (fileInput.target.files[0].size > maxSize) {
          this.imageError = "Maximum size allowed is 4 MB";
          this.attachement = null;
          Swal.fire(this.imageError);
          return false;
        }

        // Allow JPG Only
        if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
          this.imageError = "Only Images are allowed ( JPG )";
          this.attachement = null;
          Swal.fire(this.imageError);
          return false;
        }
      }

      this.file = fileInput;

      if (fileInput.target.files && fileInput.target.files[0]) {
        this.attachement = fileInput.target.files[0].name;
      } else {
        this.attachement = null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  /** Open Reviewer Popup */
  openModel(queryForm) {
    try {
      this.viewMode = true;
      this.modalService.open(queryForm, { centered: true });
      this.reset();
    } catch (error) {
      console.error(error);
    }
  }
  onGridReadyOpenTab(params) {
    this.gridApiOpenTab = params.api;
    this.getAllTickets();
  }
  onGridReadyClosedTab(params) {
    this.gridApiClosedTab = params.api;
  }
  /** Search Filter for Open Tickets */
  searchOpenTabFilter() {
    this.gridApiOpenTab.setQuickFilter(this.searchOpenTab);
  }
  /** Search Filter for Closed Tickets */
  searchCloseTabFilter() {
    this.gridApiClosedTab.setQuickFilter(this.searchClosedTab);
  }
  /** Shows logged in user open tickets */
  showMyRequestOpen(e) {
    try {
      this.searchOpenTab = null;

      if ((document.getElementsByClassName("checkbox")[0] as any).checked) {
        this.checkOpen = true;
        this.gridApiOpenTab.setRowData(
          this.rowDataOpen
            .sort((a, b) => {
              return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
            })
            .filter((x) => x.createdBy.toString().toLowerCase() === this.currentUser.username.toString().toLowerCase())
        );
      } else {
        this.checkOpen = false;
        this.gridApiOpenTab.setRowData(this.rowDataOpen);
        this.gridApiOpenTab.setQuickFilter();
      }
    } catch (error) {
      console.error(error);
    }
  }
  /** Shows logged in user closed tickets */
  showMyRequestClosed(e) {
    try {
      this.searchClosedTab = null;

      if (e.target.checked) {
        this.checkClose = true;
        this.gridApiClosedTab.setRowData(
          this.rowDataClosed
            .sort((a, b) => {
              return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
            })
            .filter((x) => x.createdBy.toString().toLowerCase() === this.currentUser.username.toString().toLowerCase())
        );
      } else {
        this.checkClose = false;
        this.gridApiClosedTab.setRowData(this.rowDataClosed);
        this.gridApiClosedTab.setQuickFilter();
      }
    } catch (error) {
      console.error(error);
    }
  }
  openTab() {
    this.showSolution = false;
  }
  closedTab() {
    this.showSolution = true;
  }
}
