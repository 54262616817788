import { Component, OnInit, Input,HostListener } from "@angular/core";
import { AssetAttributesService } from "../../services/assetAttibutes.service";
import { GenericLayerService } from "../../services/genericLayer.service";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitersService } from "src/app/services/eventEmiters.service";
import { Globals } from "src/app/select/globals";
import { CdkDragDrop, moveItemInArray, CdkDragEnd, CdkDragStart } from "@angular/cdk/drag-drop"; // Added by Abhilash for JIRA ID:-36 on 08/18/2021

@Component({
  selector: "app-sms-assetattributes",
  templateUrl: "./assetattributes.component.html",
  styleUrls: ["./assetattributes.component.scss"]
})
export class AssetattributesComponent implements OnInit {
  @Input() FeatureLayer: any;
  @Input() Query: any;
  @Input() Graphic: any;
  activeId = 1;
  RelationshipQuery: any;
  Primarylangvalid;
  Secodarylangvalid: any;
  result;
  layersSet;
  ftrLyr;
  asset;
  ftrLyrMntConfig;
  tablealias;
  ftrLyrTbl;
  currentUser: IUser;
  checked: false;
  private checkmax = false;
  scndvisible = false;
  maintanancelyr;
  selectedstatus;
  Status: any[];
  results;
  task;
  private isDisabled = false;
  maintainanceConfiguration: any;
  //private taskcheckmax= false;
  private taskCheckmax = false;
  public isUserUpdateFlag = false;
  public oldResultData;
  tabsList = [
    { tabName: "tableAliasTab", id: 1 },
    { tabName: "fieldAliasTab", id: 2 },
    { tabName: "taskAttrTab", id: 3 }
  ];

  private taskCheckmaxOpen = false;
  // Added by Madhuri Gupta for JIRA ID:-26 on 08/03/2021
  disabledFieldArr = ["OBJECTID", "Status", "AssetID", "GlobalID", "Latitude", "Longitude"];
  disabledcheckedfields = ["AssetCode", "AssetSubType"];
  val: any;
  // Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) { 
    if(event.target['type'] === 'checkbox' && ((event.target['id']).indexOf('chk')==0 ||
                                              (event.target['id']).indexOf('taskchk')==0) ){
      (<HTMLInputElement>event.target).click();
    }
  }
  //
  constructor(
    private assetAttributesService: AssetAttributesService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private genericLayerService: GenericLayerService,
    private eventEmitersService: EventEmitersService,
    public objGlobals: Globals
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  selectedlayer;
  curJustify = "start";
  activeTab;
  zIndexSerial = 10000;

  // Added by Abhilash for JIRA ID:-36 on 08/18/2021
  selectedLyrFeaturesList = [];
  currentSortOrder;
  // Added by Madhuri for JIRA ID:-36 on 08/18/2021
  onDragStarted(event: CdkDragStart<string[]>): void {
    try {
      event.source.element.nativeElement.style.zIndex = this.zIndexSerial + "";
      event.source.element.nativeElement.classList.add("draghighlight");
      this.zIndexSerial = this.zIndexSerial + 1;
    } catch (error) {
      console.error(error);
    }
  }
  // Added by Madhuri for JIRA ID:-36 on 08/18/2021
  onDragEnded(event: CdkDragEnd<string[]>): void {
    try {
      event.source.element.nativeElement.classList.remove("draghighlight");
    } catch (error) {
      console.error(error);
    }
  }
  // Added by Abhilash for JIRA ID:-36 on 08/18/2021
  drop(event: CdkDragDrop<string[]>): void {
    try {
      moveItemInArray(this.selectedLyrFeaturesList, event.previousIndex, event.currentIndex);
      this.currentSortOrder = event.container.data.map((draggable) => draggable["attributes"]);
      for (let index = 0; index < this.currentSortOrder.length; index++) {
        this.currentSortOrder[index].FieldOrder = index;
        this.result.features.filter(
          (element) => element.attributes.TableName === this.currentSortOrder[index].TableName && element.attributes.Field === this.currentSortOrder[index].Field && (element.attributes.FieldOrder = index)
        );
      }
      this.isUserUpdateFlag = true;
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    // this.activeTab = "tableAliasTab";
    this.activeTab = 1;
    this.getTables();
    this.ftrLyr = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.fieldAliasId, {
      outFields: ["*"]
    });

    // Added by Sowjanya for JIRA ID:-27  JIRA ID:-29 on 08/31/2021
    this.ftrLyrMntConfig = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceConfigId, {
      outFields: ["*"]
    });
    this.ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.tableAliasId, {
      outFields: ["*"]
    });
    this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      this.Primarylangvalid = res.primaryLanguageID;
      this.Secodarylangvalid = res.secondaryLanguageID;
    });
    this.assetAttributesService.getAssetAttributes().subscribe((results) => {
      this.result = results; //all features & fields of table
      this.oldResultData = JSON.stringify(results);
      this.result.features.filter((feature)=>(feature.attributes.Field==="AssetCode" ||feature.attributes.Field==="AssetSubType")).forEach(element => {
        //TSDRSAM-1739
        if (element.attributes.Field === "AssetCode") {
          element.attributes.DetailsVisible = 1;
          element.attributes.IsRequired = 1;
        }
        //Make Isrequired = 1 only for Sign - AssetSubType field
        if (element.attributes.TableName === 'Sign' && element.attributes.Field === "AssetSubType") {
          element.attributes.DetailsVisible = 1;
          element.attributes.IsRequired = 1;
        }
      });
     
           // Added by Abhilash for JIRA ID:-36 on 08/18/2021 
      this.selectedLayerFeatures(this.result); 
      //this.defaultchecks();
      // if(this.result){
    });

    this.assetAttributesService.getTaskSummary().subscribe((taskresult) => {
      this.task = taskresult;
    });

    if (this.currentUser.clientType === "PROFESSIONAL") {
      this.scndvisible = true;
    } else {
      this.scndvisible = false;
    }
  }
  getTables() {
    try {
      this.assetAttributesService.getTableAlias().subscribe((results: any) => {
        this.tablealias = [];
        this.layersSet = [];
        results.features.forEach((data) => {
          if (data.attributes.IsAuthorized === "T") {
            if (data.attributes.GroupType === "Parent Table" || data.attributes.GroupType === "Child Table") {
              this.tablealias.push(data);
              this.layersSet.push(data.attributes.Name);
            }
          }
        });
        this.selectedlayer = this.layersSet[0];
      });
    } catch (error) {
      console.error(error);
    }
  }
  onChangelayer(evt) {
    try {
      this.selectedlayer = evt.value;
      if (this.isUserUpdateFlag) {
        this.isUserUpdateFlag = false;
        this.result = JSON.parse(this.oldResultData);
      }
      this.selectedLayerFeatures(this.result); // Added by Abhilash for JIRA ID:-36 on 08/18/2021
    } catch (error) {
      console.error(error);
    }
  }
  // Added by Abhilash for JIRA ID:-36 on 08/18/2021
  selectedLayerFeatures(res) {
    try {
      this.selectedLyrFeaturesList = res.features
        .filter(
          (feature) =>
            feature.attributes.TableName.toLowerCase() === this.selectedlayer.toLowerCase() && //928 - toLowerCase already added
            !this.eventEmitersService.systemManagedFields.includes(feature.attributes.Field) //TSDRSAM-610 ,TSDRSAM-611
        )
        .sort(function (a, b) {
          return a.attributes.FieldOrder - b.attributes.FieldOrder;
        });
    } catch (error) {
      console.error(error);
    }
  }
  onChangestatus(event) {
    this.selectedstatus = event.value;
  }

  textchange(evt, sfeature) {
    try {
      this.result.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes.FieldAlias = evt.target.value;
      this.isUserUpdateFlag = true;
    } catch (error) {
      console.error(error);
    }
  }
  textchangesl(evt, sfeature) {
    try {
      // eslint-disable-next-line camelcase
      this.result.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes.FieldAlias_SL = evt.target.value;
      this.isUserUpdateFlag = true;
    } catch (error) {
      console.error(error);
    }
  }
  tabletextchange(evt, sfeature) {
    try {
      this.tablealias.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes.Alias = evt.target.value;
    } catch (error) {
      console.error(error);
    }
  }

  tabletextchangesl(evt, sfeature) {
    try {
      // eslint-disable-next-line camelcase
      this.tablealias.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes.Alias_SL = evt.target.value;
    } catch (error) {
      console.error(error);
    }
  }

  //defaultchecks() {
  //if(this.result.features.filter((feature)=>(feature.attributes.Field==="AssetCode" ||feature.attributes.Field==="AssetSubtype"))){
  // this.isDisabled=true;

  //}
  //let val=result.features.filter(feature=>feature)[0].Fields.join(",").includes(this.disabledcheckedfields.join(","))
  //}

  changeCheckbox(evt, sfeature, attribute) {
    try {
      let value;

      if (attribute != "IsCardviewColumnVisible" && evt.target.checked === true) {
        this.checkmax = false;
      }

      if (attribute === "IsCardviewColumnVisible") {
        this.checkmax = this.checkCountOfCardViewColumns(this.result, sfeature); //this.result=whole result , sfeature=selected layer features
      }
      // Added by Sowjanya for JIRA ID:-26 on 07/29/2021
      if (evt.target.checked === true && this.checkmax === true) {
        evt.target.checked = false;

        Swal.fire(this.translateService.instant("Userscreens.alertassetattributedefalt.Checkmorethanfive"));
      } else {
        evt.target.checked ? (value = 1) : (value = 0);
      }

      this.result.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes[attribute] = value;
      this.isUserUpdateFlag = true;

      // <!--TSDRSAM-610,TSDRSAM-611 By Mani -->
      if (attribute == "IsRequired" && value == 1) {
        this.result.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes["DetailsVisible"] = value;
        // this.disabledcheckedfields.push(this.result.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes.Field)
      }
    } catch (error) {
      console.error(error);
    }
  }
  activeTabChanged(evt) {
    try {
      if (this.isUserUpdateFlag) {
        this.isUserUpdateFlag = false;
        this.result = JSON.parse(this.oldResultData);
        this.selectedLayerFeatures(this.result);
      }
      // this.activeTab = this.tabsList.filter(item => item.id === evt.nextId)[0].id;
      this.activeTab = evt.activeId;
      console.log(this.activeTab);
    } catch (error) {
      console.error(error);
    }
  }

  SaveEdits() {
    try {
      const tabNmae = this.tabsList.filter((item) => item.id === this.activeTab)[0].tabName;
      if (tabNmae === "tableAliasTab") {
        this.saveTableAlias();
      } else if (tabNmae === "fieldAliasTab") {
        this.saveFieldAlias();
      } else if (tabNmae === "taskAttrTab") {
        this.saveMaintainanceConfiguration(); //  JIRA ID:-27  JIRA ID:-29
      }
    } catch (error) {
      console.error(error);
    }
  }

  saveTableAlias() {
    try {
      const featurese = [];
      this.tablealias.forEach((element) => {
        const features = new this.Graphic();
        features.setAttributes(element.attributes);
        featurese.push(features);
      });
      this.ftrLyrTbl.applyEdits(null, featurese, null).then(this.showMesage());
    } catch (error) {
      console.error(error);
    }
  }

  saveFieldAlias() {
    try {
      const featurese = [];
      this.result.features.forEach((element) => {
        const features = new this.Graphic();
        features.setAttributes(element.attributes);

        featurese.push(features);
      });
      this.ftrLyr.applyEdits(null, featurese, null).then(this.showMesage());
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Sowjanya  for JIRA ID:-27  JIRA ID:-29  on 08/17/2021
  saveMaintainanceConfiguration() {
    try {
      const featurese = [];
      this.task.features.forEach((element) => {
        const features = new this.Graphic();
        features.setAttributes(element.attributes);

        featurese.push(features);
      });
      this.ftrLyrMntConfig.applyEdits(null, featurese, null).then(this.showMesage());
    } catch (error) {
      console.error(error);
    }
  }

  showMesage() {
    try {
      this.isUserUpdateFlag = false;
      Swal.fire(this.translateService.instant("Userscreens.alertassetattributedefalt.savedsuccessful"));
      this.oldResultData = JSON.stringify(this.result);
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Madhuri  for JIRA ID:-27  JIRA ID:-29  on 08/18/2021
  checkCountOfCardViewColumns(totalResult, filteredData): boolean {
    try {
      let cardCount = 0;

      totalResult.features.forEach((element) => {
        if (element.attributes.TableName === filteredData.attributes.TableName) {
          if (element.attributes.IsCardviewColumnVisible === 1) {
            cardCount++;
          }
        }
      });

      if (cardCount > this.objGlobals.assetCardDisplayMaxCol - 1) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Madhuri  for JIRA ID:-27  JIRA ID:-29  on 08/18/2021
  //JIRA ID:-28
  changeTaskCheckbox(evt, sfeature, attribute) {
    try {
      let value;
      if (attribute !== "ForStatusClosed" || attribute !== "ForStatusOpen") {
        this.taskCheckmax = false;
        this.taskCheckmaxOpen = false;
      }
      if (attribute === "ForStatusClosed") {
        this.taskCheckmax = this.taskAttrCheckboxCount(this.task, attribute);
      } else if (attribute === "ForStatusOpen") {
        this.taskCheckmaxOpen = this.taskAttrCheckboxCount(this.task, attribute);
      }

      if (this.taskCheckmax === true && evt.target.checked === true) {
        evt.target.checked = false;
        Swal.fire(this.translateService.instant("Userscreens.alertassetattributedefalt.Checkmorethanfive"));
      } else if (this.taskCheckmaxOpen === true && evt.target.checked === true) {
        evt.target.checked = false;
        Swal.fire(this.translateService.instant("Userscreens.alertassetattributedefalt.Checkmorethanfive"));
      } else {
        evt.target.checked ? (value = 1) : (value = 0);
      }
      this.task.features.filter((feature) => feature.attributes.OBJECTID === sfeature.attributes.OBJECTID)[0].attributes[attribute] = value;
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Madhuri  for JIRA ID:-27  JIRA ID:-29 on 08/18/2021
  //JIRA ID:-28
  taskAttrCheckboxCount(totalResult, attribute): boolean {
    try {
      let count = 0;
      totalResult.features.forEach((element) => {
        if (element.attributes[attribute] === 1) {
          count++;
        }
      });
      if (count > this.objGlobals.taskCardDisplayMax - 1) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }
  // <!--TSDRSAM-610,TSDRSAM-611 By Mani -->
  fieldDisabled(attributes): boolean {
    let result = false;
    if (this.disabledcheckedfields.indexOf(attributes.Field) != -1 || attributes.IsRequired == 1) {
      result = true;
    }
    return result;
  }
}
