import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { APP_BASE_HREF, DatePipe } from "@angular/common";
import { AppComponent } from "./app.component";
import { EsriMapComponent } from "./esri-map/esri-map.component";
import { HeaderComponent } from "./header/header.component";
import { MapToolsComponent } from "./map-tools/map-tools.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { RedlineComponent } from "./redline/redline.component";
import { SearchToolComponent } from "./search-tool/search-tool.component";
import { SelectToolComponent } from "./select-tool/select-tool.component";
import { SelectModule } from "./select/select.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { UseradminComponent } from "./user-screens/useradmin/useradmin.component";
import { BulkinsertComponent } from "./user-screens/bulkinsert/bulkinsert.component";
import { GPSTrackComponent } from "./user-screens/gpstrack/gpstrack.component";
import { ReportsComponent } from "./user-screens/reports/reports.component";
import { RetroAdminComponent } from "./user-screens/retro-admin/retro-admin.component";
import { BaseLifeModifierClassComponent } from "src/app/user-screens/retro-admin/baseLifeModifier/baseLifeModifier.component";
import { BaseLifeClassComponent } from "src/app/user-screens/retro-admin/baseLife/baseLife.component";
// import { LoginComponent } from './login/login.component';
import { AuthGuard } from "./helpers/auth.gaurd";
import { AuthenticationService } from "./services/authentication.service";
import { AssetattributesComponent } from "./user-screens/assetattributesadditional/assetattributes.component";
import { AssetAttributesService } from "./services/assetAttibutes.service";
import { AssetDefaultsComponent } from "./user-screens/asset-defaults/asset-defaults.component";
import { SystemReportsModule } from "./systemreports/system.reports.module";
import { appRoutingModule } from "./app.routing";
import { SetDefaultsComponent } from "./user-screens/set-defaults/set-defaults.component";
import { MyMaintenanceComponent } from "./my-maintenance/my-maintenance.component";
import { ButtonRenderComponent } from "./my-maintenance/button.component";
import { CrewmanagementModule } from "./crewmanagement/crewmanagement.module";
import { QuerybuilderComponent } from "./querybuilder/querybuilder.component";
import { MapExportComponent } from "./user-screens/map-export/map-export.component";
// import {QueryBuilderConstants} from './querybuilder/queryBuilderConstants';
import { TaskDurationComponent } from "./user-screens/task-duration/task-duration.component";
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from "@ngx-translate/core";
import { MyMissingTranslationHandler } from "./shared/missing-translation";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HelpCenterComponent } from "./help-center/help-center.component";
import { AgGridModule } from "ag-grid-angular";
import { CellRenderComponent } from "./help-center/CellRenderComponent/cell-render.component";
import { RouteLogsComponent } from "./route-logs/route-logs.component";
import { MMMAdminModule } from "src/app/administrator/administrator.module";
import { QueryBuilder } from "src/app/services/queryBuilderService.service";
import { QueryBuilderConstants } from "./querybuilder/queryBuilderConstants";
// import { ImageViewerModule as ng2ImageViewerModule } from 'ng2-image-viewer'; // Added by Abhilash Jira Id:-40 on 9/28/2021
import { UserComponent } from "./help-center/user/user.component";
import { CrowdSourcedInfoComponent } from "./crowd-sourced-info/crowd-sourced-info.component";
import { CellRenderingComponent } from "./crowd-sourced-info/CellRenderingComponent/cell-rendering.component";
import { SolutionAdminComponent } from "./help-center/solution-admin/solution-admin.component";
import { TranslationLoaderService } from "src/app/services/translation.service";
import { LoginModule } from "src/app/login/login.module";
import { RollBackComponent } from "src/app/user-screens/rollBack/rollBack.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { AngularDraggableModule } from "angular2-draggable";
import { ImageCompressService, ResizeOptions } from "@artiomsu/ng2-image-compress";
import { ToastrModule } from "ngx-toastr";
import { SystemReportsStyle } from "./systemreports/system.reports.style";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgSelectModule } from "@ng-select/ng-select";
import { DocumentViewerComponent } from "./select/assetdetails/document-viewer/documentViewer.component";
import { CustomTranslatePipe } from "./shared/custom-translate.pipe";
import { ParamsTranslatePipe } from "./shared/params-translate.pipe";
import { TranslateService } from "./services/translate.service";
import { AssetInformationComponent } from "./systemreports/assetinformation/assetinformation.component";
import { ChartsModule } from "ng2-charts";
import { CreateEditUserComponent } from "./user-screens/create-edit-user/create-edit-user.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ChangeReportComponent } from "./change-report/change-report.component";
import { SelectionComponent } from "./change-report/selection/selection.component";
import { ViewComponent } from "./change-report/view/view.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatNativeDateModule } from "@angular/material/core";
import { SharedModule } from "./shared/shared.module";
import { MatTabsModule } from "@angular/material/tabs";
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { environment } from "src/environments/environment.base";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgIdleModule } from '@ng-idle/core';
import { IdleModalComponent } from './modals/idle-modal/idle-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    EsriMapComponent,
    HeaderComponent,
    MapToolsComponent,
    RedlineComponent,
    SearchToolComponent,
    SelectToolComponent,
    UseradminComponent,
    BulkinsertComponent,
    RetroAdminComponent,
    GPSTrackComponent,
    ReportsComponent,
    AssetattributesComponent,
    AssetDefaultsComponent,
    SetDefaultsComponent,
    MyMaintenanceComponent,
    QuerybuilderComponent,
    ButtonRenderComponent,
    MapExportComponent,
    BaseLifeModifierClassComponent,
    BaseLifeClassComponent,
    CreateEditUserComponent,
    TaskDurationComponent,
    HelpCenterComponent,
    CellRenderComponent,
    RouteLogsComponent,
    UserComponent,
    CrowdSourcedInfoComponent,
    CellRenderingComponent,
    SolutionAdminComponent,
    RollBackComponent,
    DocumentViewerComponent,
    CustomTranslatePipe,
    ParamsTranslatePipe,
    AssetInformationComponent,
    ChangeReportComponent,
    SelectionComponent,
    ViewComponent,
    IdleModalComponent
  ],
  imports: [
    MatButtonToggleModule,
    MatBadgeModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    CrewmanagementModule,
    BrowserModule,
    ChartsModule,
    NgbModule,
    FormsModule,
    MatFormFieldModule,
    SelectModule.forRoot(),
    MatIconModule,
    SharedModule,
    appRoutingModule,
    LoginModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    SystemReportsModule.forRoot(),
    MMMAdminModule.forRoot(),
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    AngularDraggableModule,
    DragDropModule,
    AgGridModule.withComponents([]),
    // ng2ImageViewerModule,
    // Added by Abhilash Jira Id:-40 on 9/28/2021
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService,
        deps: [HttpClient]
      },
      isolate: true,
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler }
    }),
    FontAwesomeModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatMenuModule,
    NgIdleModule.forRoot(),
    ServiceWorkerModule.register('sw.js?v=1', {scope: './'})
  ],
  providers: [AuthGuard, AuthenticationService, AssetAttributesService, TranslateService, QueryBuilder, QueryBuilderConstants, DatePipe, ImageCompressService, ResizeOptions, SystemReportsStyle, {provide: APP_BASE_HREF, useValue: environment.baseHref}],
  bootstrap: [AppComponent],
})
export class AppModule {}
