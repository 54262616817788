import { Component, OnInit, Input,HostListener } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { ILayer } from "src/app/interface/layer";
import Swal from "sweetalert2";
import { CardViewService } from "src/app/services/card-view.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsService } from "src/app/systemreports/system.reports.service";
import { QueryBuilder } from "src/app/services/queryBuilderService.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { QueryLayers } from "src/app/services/queryLayers.service";

@Component({
  selector: "app-sms-rollback",
  templateUrl: "./rollBack.component.html",
  styleUrls: ["./rollBack.component.scss"]
})
export class RollBackComponent implements OnInit {
  private activeTab: number;
  private currentUser: IUser;
  public regionList;
  public RegionLabel;
  public AssetClassLabel;
  public layerList;
  public selectedRegion;
  public selectedLayer;
  public FeaturesList;
  public ShowLayerList = true;
  private SelectedAssetId = null;
  private MaintanceLayer;
  ftrLyrActivity;
  activityLyrDomines;

  @Input() map;
  @Input() Query: any;
  @Input() QueryTask: any;
  @Input() Graphic: any;
  @Input() FeatureLayer: any;
  activityLyrFltDomines: any[];
  selectedActivity: any;
  activitydomain: any;
  public rollbackData:any;
  inventoryLayersData: any;
  getFilteredFeaturesByListOfValuesSubscribe: any;
  childRecords: any[];
  newAssetId: any;
  counter: number;
  selectedAssetRetireDate: any;
  //Added for ADA
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if(event.target['localName'] === 'a' && (event.target['id']).indexOf("rollBackLink_")==0){
      (<HTMLInputElement>event.target).click();
    }
  }
  //
  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private cs: CardViewService,
    private reportService: SystemReportsService,
    public qb: QueryBuilder,
    private genericLayerService: GenericLayerService,
    public query: QueryLayers
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.RegionLabel = "Userscreens.mapexport.regionlabel";
    this.AssetClassLabel = "Userscreens.mapexport.assetclasslabel";
    this.regionList = this.map.smsRegiondata;
    //Get Layerlist with child Asset classes
    this.qb.getsldata(this.Query, this.QueryTask, this.FeatureLayer).then(
      function (result) {
        this.qb.aliasData = result.features;
        this.inventoryLayersData = this.map.smsLayerdata.userLayers.filter((obj: ILayer) => obj.layerType === "inventory");
        this.qb.inventoryLayersData = this.inventoryLayersData;
        this.qb.FeatureLayer = this.FeatureLayer
        this.layerList = this.qb.getDropDownValues();
        this.layerList = this.layerList.filter(x => !["Maintenance", "Assessment", "Activity"].includes(x.Layer));
      }.bind(this)
    );
    this.MaintanceLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    this.ftrLyrActivity.on(
      "Load",
      function (evt) {
        this.activityLyrDomines = evt.layer.types;
      }.bind(this));
  }

  showLayers() {
    this.ShowLayerList = false;
    this.selectedLayer = undefined;
    this.FeaturesList = [];
    this.SelectedAssetId = null;
    this.selectedActivity = undefined;
    this.rollbackData = null;
  }

  ShowReplacedOrRetiresAssets() {
    try {
      if (this.selectedRegion === undefined || this.selectedRegion === "") {
        Swal.fire("Please select Region");
      } else if (this.selectedLayer === undefined || this.selectedLayer === "") {
        Swal.fire("Please select Layer");
      } else if (this.selectedActivity === undefined || this.selectedActivity === "") {
        Swal.fire("Please select Activity");
      } else {
        this.dataOnSelected();
      }
    } catch (error) {
      console.error(error);
    }
  }

  RollBackData(da: any) {
    try {
      const graphic = new this.Graphic();
      const att: any = [];
      att.OBJECTID = da.objectID;
      this.SelectedAssetId = da.assetID;
      att.Status = "Active";
      att.RetireDate = null;
      att.LastModifyDate = new Date(Date.now()).getTime();
      att.ModifiedBy = this.currentUser.username;
      
      if(this.selectedActivity === "Replace" && this.selectedLayer.Layer ==="Support")
      {
       //IF new asset had active child asset then set  ParentID = original assetID
       this.genericLayerService.getFilteredFeaturesByField(this.selectedLayer.layer_id.lyrObj.layerId, "ReplacesID", this.SelectedAssetId).subscribe((resultNewAsset: any) => {
         this.newAssetId = resultNewAsset.features[0].attributes.AssetID
         this.updateChildAssets(this.newAssetId); 
      });
      }
      graphic.setAttributes(att);
      this.genericLayerService.getFilteredFeaturesByField(this.selectedLayer.layer_id.lyrObj.layerId, "AssetID", da.assetID).subscribe((resultNewAsset: any) => {
        this.selectedAssetRetireDate = resultNewAsset.features[0].attributes.RetireDate;
      this.selectedLayer.layer_id.lyrObj.applyEdits(
        null,
        [graphic],
        null,
        (res) => {
          this.DeleteTask();
        },
        (error) => {
          console.log("===============================================");
          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
          console.log("error = ", error);
        }
      );
    });
    } catch (error) {
      console.error(error);
    }
  }

  DeleteTask() {
    try {
      const condition = "AssetID = '" + this.SelectedAssetId + "'";
      // TSDRSAM-352 By Mani
      this.cs.getFeaturesorderByLastModifyDate(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, condition, this.Query, this.QueryTask).then((res: any) => {
        res.sort((a, b) => {
          const x = a.attributes.OBJECTID;
          const y = b.attributes.OBJECTID;

          if (x > y) {
            return -1;
          }

          if (x < y) {
            return 1;
          }

          return 0;
        });

        const MaintenanceIdsList = [];
        res.forEach((val) => {
          MaintenanceIdsList.push("'" + val.attributes.MaintID + "'");
        });

        //Update closed replaced\retired and Inactive task to open for which asset need to rollback 
        const maintIDs = MaintenanceIdsList.toString();
        //Get All Activities records for which asset need to rollback
        this.getFilteredFeaturesByListOfValuesSubscribe = this.genericLayerService.getFilteredFeaturesByListOfValues(this.currentUser.serviceLyrInfo.activityId, "MaintID", maintIDs).subscribe((results: any) => {
          if (results.features && results.features[0] && results.features[0].attributes) {
            res.forEach((task) => {
              if (
                results.features.filter((acy) => {
                  if (acy.attributes.MaintID === task.attributes.MaintID) {
                    return acy;
                  }
                }).length > 0
              ) {
                task.attributes.Activity = results.features.filter((acy) => {
                  if (acy.attributes.MaintID === task.attributes.MaintID) {
                    return acy;
                  }
                })[0].attributes.Activity;
                console.log("valid activity");
              }
            });

            const featuresList = [];
            res.forEach(
              function (task) {
                const feature = new this.Graphic();
                const att: any = [];
                att.OBJECTID = task.attributes.OBJECTID;
                feature.setAttributes(att);

                if (((this.selectedActivity === "Replace" && task.attributes.Activity === "Replace" ) || (this.selectedActivity === "Retire" && task.attributes.Activity === "Retire")) && task.attributes.Status === "Closed") {
                  att.Status = "Open";
                  att.CompletedBy = null
                  att.CompletionDate = null
                  featuresList.push(feature);
                }
                else if (task.attributes.Status === "Inactive") {
                  att.Status = "Open";
                  featuresList.push(feature);
                }
              }.bind(this));
            
            this.MaintanceLayer.applyEdits(null, featuresList, null).then(function () {
              if (this.selectedActivity === "Replace") {
              this.deleteNewAssetRecords();
              }
              else if (this.selectedActivity === "Retire" && this.selectedLayer.Layer ==="Support" ) {
                //if parent assset has child assets then update child
                this.updateChildAssets(this.SelectedAssetId);
              }
              this.dataOnSelected();
              this.map.getLayer("sms-layers").refresh();
              this.map.graphics.clear();
              Swal.fire(this.translateService.instant("Task.alert.rollbacksucess"));
              }.bind(this));
          } 
        });
      },
      (error) => {
        console.log("===============================================");
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        console.log("error = ", error);
      });
    } catch (error) {
      console.error(error);
    }
  }

  dataOnSelected() {
    try {
      this.reportService.getRollbackData(this.selectedRegion.regionName, this.selectedLayer.Layer, this.selectedActivity).subscribe((result: any) => {
        this.rollbackData = [];
        if (result && result.tblRollback.length > 0) {
          this.rollbackData = result.tblRollback;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  
  ShowActivities()
  {
    try {

      this.activitydomain = this.activityLyrDomines.filter((val) => val.name === this.selectedLayer.Layer);//tst2

      this.activityLyrFltDomines = [];
      const filterValues = [];
      for (let ind = 0; ind < this.activitydomain[0].domains.Activity.codedValues.length; ind++) {
        if (this.activitydomain[0].domains.Activity.codedValues[ind].code == "Replace" || this.activitydomain[0].domains.Activity.codedValues[ind].code == "Retire" ) 
        {
          this.activityLyrFltDomines.push(this.activitydomain[0].domains.Activity.codedValues[ind]);
          }

      }
      if (this.selectedActivity)
        this.ShowReplacedOrRetiresAssets();
      
    } catch (error) {
      console.error(error);
    }
  }
  updateChildAssets(parentAssetID)
  {
    this.counter = 0;
    this.childRecords = [];
    const layerObj = this.selectedLayer.layer_id.lyrObj;
    const relationshipsIds = layerObj.relationships.filter((layeritem) => {
      const idstr = layeritem.relatedTableId.toString();
      if (this.currentUser.layerLevelInfo.map(({ gisLayerId }) => gisLayerId).includes(idstr)) {
        return layeritem;
      }
    });

    for (let i = 0; i < relationshipsIds.length; i++) {
      let relatedColumn = "AssetID";
      this.childRecords.push({ Layer: relationshipsIds[i].name, layer_id: relationshipsIds[i].relatedTableId });

      if (relationshipsIds[i].name.includes("Sign") || relationshipsIds[i].name.includes("Light")) {
        relatedColumn = "ParentID";
      } else {
        relatedColumn = "AssetID";
      }
      this.genericLayerService.getFilteredFeaturesByField(relationshipsIds[i].relatedTableId,relatedColumn,parentAssetID).subscribe(
          function (results: any) {
            this.counter++;
            let layerName;
            if (results && results.features && results.features[0]) {
              switch (results.features[0].attributes.AssetID.split("_")[0]) {
                case "SIG":
                  layerName = "SupportToSign";
                  break;
                case "LGT":
                  layerName = "SupportToLight";
                  break;
              }
              this.childRecords.filter((child) => {
                if (child.Layer === layerName) {
                  child.RelatedData = results.features.filter((item) => { 

                    const childRetireDate  = item.attributes.RetireDate + 10000 ; //10 seconds = 10000  milliseconds

                    if (item.attributes.Status === "Active" && this.selectedActivity === "Replace") {
                      return item;
                    }
                    else if(item.attributes.Status === "Inactive" && this.selectedActivity === "Retire"  && childRetireDate > this.selectedAssetRetireDate)
                    {
                      return item;
                    } 
                  });
                }
              });
            }
            if (this.counter === relationshipsIds.length) {
              const data = this.childRecords.filter((a) => a.RelatedData && a.RelatedData.length > 0);
              data.forEach(
                function (layer) {
                  const layerId = layer.layer_id;
                  layer.RelatedData.forEach(
                    function (rltdAsset) {
                      if (this.selectedActivity === "Replace") {
                        rltdAsset.attributes.ParentID = this.SelectedAssetId;
                      }
                      else if (this.selectedActivity === "Retire") {
                        rltdAsset.attributes.RetireDate = null;
                      }

                      rltdAsset.attributes.Status = "Active";
                      rltdAsset.attributes.ModifiedBy = this.currentUser.username;
                      rltdAsset.attributes.LastModifyDate = new Date(Date.now()).getTime();
                      const lyr = new this.FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + layerId);
                      this.query.updateAsset(lyr, rltdAsset);
                      
                      //Update child asset Inactive task to Open
                      if (this.selectedActivity === "Retire") {
                      this.genericLayerService.getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", rltdAsset.attributes.AssetID, "Status", "Inactive").subscribe(
                        function (result) {
                          console.log(result);
                          result.features.forEach((task) => {
                            task.attributes.Status = "Open";
                            task.attributes.LastModifyDate = new Date(Date.now()).getTime();
                            task.attributes.ModifiedBy = this.currentUser.username;
                            this.MaintanceLayer.applyEdits(null, [task], null).then((results) => {
                              console.log(results);
                            });
                          });
                        }.bind(this));
                      }
                    }.bind(this));
                }.bind(this));
          }
        }.bind(this));
    }
  }

  deleteNewAssetRecords()
  {
     this.genericLayerService.getFilteredFeaturesByField(this.selectedLayer.layer_id.lyrObj.layerId, "ReplacesID", this.SelectedAssetId).subscribe((resultNewAsset: any) => {
      const removeNewAssetRecord = resultNewAsset.features[0].attributes.OBJECTID
       this.newAssetId = resultNewAsset.features[0].attributes.AssetID

      //Delete New asset record
      const graphic = new this.Graphic();
      const att: any = [];
      att.OBJECTID = removeNewAssetRecord;
      graphic.setAttributes(att);
    
      this.selectedLayer.layer_id.lyrObj.applyEdits(null, null, [graphic], (res) => {
      });
    });
  }

}
