<div class="modal fade background" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
#appMovableArea aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content mod" [bounds]="appMovableArea" [inBounds]=true ngDraggable [handle]="DemoHandle">
      <div class="modal-header" #DemoHandle>
        <h3 class="modal-title" id="exampleModalCenterTitle">Agency Language </h3>
        
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body formbg-d2"> 
    <div class="form-group col-sm-3">
      <form name="frm">
        <div style="display: flex;width:650px;">
        <label class="lab text-green">Language Name : </label>
        <select class="form-control" (change)="Langselected($event)" id="filename1" *ngIf="showLangList == true" >
          <option disabled>Language</option>
          <option *ngFor="let PALValue of PALValues" [value]="PALValue.languageName">
            {{PALValue.languageName}}
          </option>
        </select>
        <input name="languagename" id="filename" type="search" autocomplete="off" placeholder="Enter File Name" (change)="Langname($event)" *ngIf="showLangList == false"/>
        <label class="lab1 text-green" *ngIf="!showLangList">Language Key: </label>
        <select class="form-control lab2" (change)="Langkeyselected($event)" *ngIf="showLangList == false" >
          <option disabled>Language Key</option>
          <option *ngFor="let Langkey of Langkeys" [value]="Langkey.value">
            {{Langkey.Name}}
          </option>
        </select>
        <fa-icon
          [icon]="faQuestionCircle" 
          class="h2 help" placement="top" ngbTooltip="Incase key not listed for language to be uploaded kindly choose any relevant language." *ngIf="showLangList == false"></fa-icon>
        <fa-icon [icon]="faPlusSquare" class="h2 icon pr-2" style="padding: 10px;left:6px;position: relative" (click)="Languagecondition()"></fa-icon>
        <fa-icon [icon]="faMinusSquare" class="text-danger h2 icon m-0" (click)="DeleteLang()"  style="padding: 10px;"*ngIf="showLangList == true"></fa-icon>
        </div>
        <br>
        <div style="display: flex;width:650px;">
        <b *ngIf="showLangList" style="cursor: context-menu;color: red;font-weight: 500">*Please choose a language from above which is required for downloading</b> 
        <b *ngIf="!showLangList" style="cursor: context-menu;">Choose File :</b> <input name="languagefile" type="file" accept=".json" id="file" (change)="handleFileInput($event.target.files)" *ngIf="!showLangList">
        </div>
        </form>
      </div>

      <div style="display: flex;width:650px;">
      <button type="button" class="btn btn-primary" (click)="Upload()" [disabled]="showLangList">Upload  <fa-icon [icon]="faAngleUp" class="fa-lg"></fa-icon></button>
      <button type="button" class="btn btn-secondary" (click)="Download()" [disabled]="!showLangList">Download  <fa-icon [icon]="faPlusSquare" class="fa-lg"></fa-icon></button>
      </div>
      </div> 

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
        
      </div>
    </div>
  </div>