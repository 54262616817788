// Angular Core
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from "@angular/core";
import { Renderer2, ElementRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ImageCompressService, ResizeOptions } from "@artiomsu/ng2-image-compress";

// PDF Excel
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// Services
import { QueryLayers } from "../../services/queryLayers.service";
import { AssetAttributesService } from "../../services/assetAttibutes.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GenericLayerService } from "../../services/genericLayer.service";
import { AssetDetailService } from "../../services/assetDetail.service";

// Added by Abhilash Jira Id:-39 on 9/28/2021
import { EsriMapComponent } from "../../esri-map/esri-map.component";
import { environment } from "src/environments/environment";

// User and Data
import { Globals } from "../globals";
import { IUser } from "../../interface/user";

// Swal alerts
import Swal from "sweetalert2";

// Events
import { EventEmitersService } from "../../services/eventEmiters.service";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { DropdownListItem } from "../../interface/DropDownListItem";

// ag-grid customToolTip
import { CustomTooltip } from "./customTooltip.component"; // Added by Abhilash Jira Id:-40 on 9/28/2021
import { MatSelect } from "@angular/material/select";
import { getMimeType } from "src/app/shared/utils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "sms-select-features",
  templateUrl: "./grid.component.html",
  styleUrls: ["../select.component.scss"]
})
export class GridComponent implements OnInit {
  @ViewChild("mySelect") mySelect: MatSelect;
  selected: string;

  OpenSel() {
    this.mySelect.open();
  }
  // @Input() rowdataobj: any;
  @Input() map;
  @Input() RelationshipQuery;
  @Input() FeatureLayer;
  @Input() FeatureTable;
  rowStyle = { padding: "8px 0" };
  @Output() selectedRow = new EventEmitter();

  currentUser: IUser;
  scndlyr = false;
  supportLayersData: any;
  featureLayer: any;
  myFeatureTable: any;
  relationshipIds: any;
  resultAssetRelations: string[] = [];
  allFeatureLayers: any[] = [];
  faWindowClose = faWindowClose;
  faDownload = faDownload;
  faPlusCircle = faPlusCircle;

  format = "CSV";
  selind = 0;

  layersfields: any;
  gridApi;
  gridColumnApi;
  paginationPageSize = 30;
  defaultColDef;
  columnDefs = [];
  rowData = [];
  rowSelection = "single";
  columnsArray = [];
  dataArray = [];
  detailCellRendererParams;

  tabsFeatureTables = [];
  coinwallet;
  // selectedwallet;

  activeTabId;
  result: any;

  // Added by Abhilash Jira Id:-40 on 9/28/2021 Start
  selFeaturerowData = [];
  columnDefsselFeature = [];
  menuItems = [];
  public frameworkComponents;
  public selectedRowData;
  @Input() activeAsset: any;
  @Input() Graphic: any;
  public photosData = [];
  public linearAssetValues = false;
  public modalReference: NgbModalRef;
  public samples: any;
  public imageSrc: any;
  public PhotoDate;
  public SourceTag;
  public MeasureValue;
  public showDateByOrder = false;
  public showSequenceByOrder = false;
  public showSourceByOrder = false;
  public photoFl;
  public imgBase64: any;
  findUserAccessByRoleRegionLayerVar = false;
  columnsFieldsList;
  uploadfileName;

  selectedRows = [];
  public docTypeDropdown: DropdownListItem[] = [];

  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["type"] === "checkbox" && (event.target["id"].indexOf("fieldChk_") == 0 || event.target["id"].indexOf("taskchk") == 0)) {
      (<HTMLInputElement>event.target).click();
    } else if ((<HTMLInputElement>event.target).className === "ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value ag-cell-focus") {
      if ((<HTMLInputElement>event.target).getAttribute("col-id") === "Photo") {
        (<HTMLInputElement>event.target).click();
      }
    } else if (event.target["type"] === "button") {
      (<HTMLInputElement>event.target).click();
    }
  }
  //
  // Added by Abhilash Jira Id:-40 on 9/28/2021 End
  constructor(
    private query: QueryLayers,
    private targetEl: ElementRef,
    private renderer: Renderer2,
    private objGlobals: Globals,
    private assetAttributesService: AssetAttributesService,
    public objAssetDetailService: AssetDetailService,
    private authService: AuthenticationService,
    private genericLayerService: GenericLayerService,
    public eventEmitersService: EventEmitersService,
    public esritools: EsriMapComponent,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private imageCompressService: ImageCompressService
  ) {
    this.currentUser = this.authService.currentUserValue;

    this.eventEmitersService.emitSelectedLayerIndexOnData.subscribe((result) => {
      this.selectTab(this.eventEmitersService.selectedLayerIndexOnData);
      // this.rowSelectionData();     // Added by Abhilash Jira Id:-40 on 9/28/2021
    });
    this.eventEmitersService.emitSelectedParentIndexOnData.subscribe((result) => {
      // this.rowSelectionData();     // Added by Abhilash Jira Id:-40 on 9/28/2021
      // TSDRSAM-1090 By Manikantha V -->
      this.activeSelectionChangedOnGrid(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID);
    });
    this.eventEmitersService.emitactiveAssetChangedBySelect.subscribe((result) => {
      // this.rowSelectionData();     // Added by Abhilash Jira Id:-40 on 9/28/2021
      // TSDRSAM-1090 By Manikantha V -->
      this.activeSelectionChangedOnGrid(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID);
    });
    this.eventEmitersService.emitSelectedChildIndexOnData.subscribe((result) => {
      //this.clearRowSelectionInGrid();
    });
  }
  ngOnInit() {
    this.assetAttributesService.getAssetAttributes().subscribe((results) => {
      this.result = results;
      this.layersfields = [];
      this.result.features.forEach((element) => {
        this.layersfields.push(element.attributes);
      });
      // Added by Abhilash Jira Id:-40 on 9/28/2021
      this.getRelatedSignsAndLights();
      // this.loadFeatureTable();
    });
    this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      if (res.primaryLanguageID === this.currentUser.languageID) {
        this.scndlyr = false;
      } else if (res.secondaryLanguageID === this.currentUser.languageID) {
        this.scndlyr = true;
      }
    });
    //ADA fix
    document.getElementById("closeAssetTable").focus();
  }

  // Added by Abhilash Jira Id:-40 on 9/28/2021 Start
  loadSelectedFeatureTable() {
    const columns = this.result.features.filter(
      (feature) =>
        //Jira Id-933
        feature.attributes.TableName.toLowerCase() === this.menuItems[this.activeTabId].LayerName.tableName.toLowerCase()
    );
    this.columnsFieldsList = columns;
    this.columnDefsselFeature = [];
    if (!this.scndlyr) {
      this.columnDefsselFeature.push({
        field: "Photo",
        tooltipField: "Photo",
        width: 80,
        cellRenderer: (params) => {
          return `<img class="table-image-icon" alt="Image" src="assets/images/icon_image.svg">`;
        }
      });
      columns.forEach((element) => {
        if (element.attributes.Field == "DoNotReplace") {
          this.columnDefsselFeature.push({
            field: "DoNotReplaceDomainValue",
            headerName: element.attributes.FieldAlias
          });
        } else if (element.attributes.GridVisible) {
          this.columnDefsselFeature.push({
            field: element.attributes.Field,
            headerName: element.attributes.FieldAlias
          });
        }
      });
    } else if (this.scndlyr) {
      this.columnDefsselFeature.push({
        field: "Photo",
        tooltipField: "Photo",
        width: 80,
        cellRenderer: function (params) {
          return `<img class="table-image-icon" alt="Image" src="assets/images/icon_image.svg">`;
        }
      });
      columns.forEach((element) => {
        if (element.attributes.Field == "DoNotReplace") {
          this.columnDefsselFeature.push({
            field: "DoNotReplaceDomainValue",
            headerName: element.attributes.FieldAlias_SL
          });
        } else if (element.attributes.GridVisible) {
          this.columnDefsselFeature.push({
            field: element.attributes.Field,
            headerName: element.attributes.FieldAlias_SL
          });
        }
      });
    }
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      tooltipComponent: "customTooltip"
    };
    this.frameworkComponents = { customTooltip: CustomTooltip };
    this.selFeaturerowData = [];
    this.menuItems[this.activeTabId].Data.forEach((feature) => {
      feature.attributes["Photo"] = "Photo";
      //TSDRSAM-2594
      feature.attributes.InstallDate = feature.attributes.InstallDate ? this.datePipe.transform(feature.attributes.InstallDate, "MM/dd/yyyy hh:mm a") : null;
      feature.attributes.RetireDate = feature.attributes.RetireDate ? this.datePipe.transform(feature.attributes.RetireDate, "MM/dd/yyyy hh:mm a") : null;
      feature.attributes.LastModifyDate = feature.attributes.LastModifyDate ? this.datePipe.transform(feature.attributes.LastModifyDate, "MM/dd/yyyy hh:mm a") : null;
      feature.attributes.CollectDate = feature.attributes.CollectDate ? this.datePipe.transform(feature.attributes.CollectDate, "MM/dd/yyyy hh:mm a") : null;
      //  Added by Sowjanya Jira Id:-32 on 10/11/2021
      // feature.attributes.DoNotReplace=  0 ? 'No':'Yes'
      if (feature._layer && feature._layer._fields && feature._layer._fields.length > 0) {
        // TSDRSAM-1090 By Manikantha V -->
        if (feature._layer._fields.filter((item) => item.name == "DoNotReplace")[0].domain.codedValues.filter((item) => item.code == feature.attributes.DoNotReplace)[0]) {
          feature.attributes["DoNotReplaceDomainValue"] = feature._layer._fields.filter((item) => item.name == "DoNotReplace")[0].domain.codedValues.filter((item) => item.code == feature.attributes.DoNotReplace)[0].name;
        }
      } else {
        feature.attributes["DoNotReplaceDomainValue"] = feature.attributes.DoNotReplace;
      }
      this.selFeaturerowData.push(feature.attributes);
    });
    this.gridColumnApi = undefined;
  }
  // TSDRSAM-1090 By Manikantha V -->
  rowDataChanged() {
    this.activeSelectionChangedOnGrid(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID);
  }
  // TSDRSAM-1090 By Manikantha V -->
  activeSelectionChangedOnGrid(assetId) {
    if (this.gridApi) {
      this.gridApi.forEachNode(
        function (node) {
          if (node.data.AssetID === assetId) {
            const toBeFocused: number = node.rowIndex;
            this.gridApi.setFocusedCell(toBeFocused, 0);
            this.gridApi.ensureIndexVisible(toBeFocused);
            this.gridApi.getRowNode(toBeFocused).setSelected(true, true);
            //this.gridApi.selectNode(node, true);
          }
          // Selecting child first record by default
          if (
            this.gridApi.getColumnDefs().filter((item) => {
              if (item.field == "ParentID") {
                return item;
              }
            }).length
          ) {
            let toBeFocused = 0;
            if (this.gridApi.getSelectedNodes()[0] && this.gridApi.getSelectedNodes()[0].rowIndex) {
              toBeFocused = this.gridApi.getSelectedNodes()[0].rowIndex;
            }
            this.gridApi.setFocusedCell(toBeFocused, 0);
            this.gridApi.ensureIndexVisible(toBeFocused);
            this.gridApi.getRowNode(toBeFocused).setSelected(true, true);
          }
        }.bind(this)
      );
    }
  }
  onCellClicked(evt, content): void {
    if (evt && evt.value == "Photo") {
      setTimeout(() => {
        if (this.menuItems[this.activeTabId].LayerName.layerName == "Sign" || this.menuItems[this.activeTabId].LayerName.layerName == "Light") {
          this.viewImage(content, true);
        } else {
          this.viewImage(content, false);
        }
      }, 1000);
    }
  }
  onGridReadyOpen(evtOpen): void {
    this.gridApi = evtOpen.api;
    this.gridColumnApi = evtOpen.columnApi;
    // this.gridApi.sizeColumnsToFit();
    // TSDRSAM-1090 By Manikantha V -->
    if (this.gridApi) {
      this.gridApi.forEachNode(
        function (node) {
          if (node.data.AssetID === this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID) {
            this.gridApi.selectNode(node, true);
          }
        }.bind(this)
      );
    }
  }
  onSelectionChangedOpen(evt): void {
    const selectedRows = evt.api.getSelectedRows();
    this.selectedRowData = evt.api.getSelectedRows();
    this.selectRowDataAg(selectedRows);
  }
  selectRowDataAg(event) {
    // TSDRSAM-1090 By Manikantha V -->
    let parentIndex = 0;

    if (this.menuItems[this.activeTabId].LayerName.layerName == "Sign" || this.menuItems[this.activeTabId].LayerName.layerName == "Light") {
      if (event[0] && event[0].ParentID) {
        parentIndex = this.menuItems
          .filter((tab) => {
            if (tab.LayerName.layerName == "Support") {
              return tab;
            }
          })[0]
          .Data.findIndex((feature) => feature.attributes.AssetID === event[0].ParentID);
        if (parentIndex !== this.eventEmitersService.selectedParentIndexOnData) {
          // this.eventEmitersService.setSelectedLayerIndexOnData(0);
          this.eventEmitersService.setSelectedParentIndexOnData(parentIndex);
        }
      }
    } else {
      if (event[0] && event[0].AssetID) {
        parentIndex = this.menuItems[this.activeTabId].Data.findIndex((feature) => feature.attributes.AssetID === event[0].AssetID);
        if (parentIndex !== this.eventEmitersService.selectedParentIndexOnData) {
          this.eventEmitersService.setSelectedParentIndexOnData(parentIndex);
        }
      }
    }
  }

  columnVisibilityCheck(event) {
    if (event.event.target.checked) {
      this.result.features.filter(
        (feature) =>
          //Jira Id-933
          feature.attributes.TableName.toLowerCase() === this.menuItems[this.activeTabId].LayerName.tableName.toLowerCase() && feature.attributes.Field == event.columnfield.attributes.Field
      )[0].attributes.GridVisible = 1;
    } else {
      this.result.features.filter(
        (feature) =>
          //Jira Id-933
          feature.attributes.TableName.toLowerCase() === this.menuItems[this.activeTabId].LayerName.tableName.toLowerCase() && feature.attributes.Field == event.columnfield.attributes.Field
      )[0].attributes.GridVisible = 0;
    }
    this.loadSelectedFeatureTable();
  }

  onFormatChange(event) {
    this.format = event;
    console.log(event);
  }

  // Added by Abhilash Jira Id:-40 on 9/28/2021 End

  // Added by Abhilash Jira Id:-39 on 9/28/2021 Start
  getRelatedSignsAndLights() {
    const signAssetIds = [];
    const lightAssetIds = [];
    this.menuItems = Object.assign([], this.eventEmitersService.selectedFeaturesOnMap);
    this.menuItems.forEach((item, index) => {
      if (item.LayerName.layerName === "Support") {
        item.LayerName["childElement"] = [];
        item.Data.forEach((feature) => {
          if (feature.attributes.opentasksobj && feature.attributes.opentasksobj.length > 0) {
            feature.attributes.opentasksobj.forEach((element) => {
              // const parientId = element.AssetId;
              if (element.Sign && element.Sign.length > 0) {
                element.Sign.forEach((signElement) => {
                  signAssetIds.push(signElement.AssetId);
                });
              }
              if (element.Light && element.Light.length > 0) {
                element.Light.forEach((lightElement) => {
                  lightAssetIds.push(lightElement.AssetId);
                });
              }
            });
          }
        });
        if (
          lightAssetIds.length > 0 &&
          this.currentUser.layerLevelInfo.length > 0 &&
          this.currentUser.layerLevelInfo.filter((layer) => parseInt(layer.gisLayerId, 10) == this.currentUser.serviceLyrInfo.lightId).length > 0
        ) {
          const lightQuery = new this.esritools.Query();
          lightQuery.returnGeometry = true;
          lightQuery.outFields = ["*"];
          lightQuery.where = "AssetID IN ('" + lightAssetIds.join("','") + "')";
          const lightQueryTask = new this.esritools.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId);
          lightQueryTask.execute(lightQuery).then((result) => {
            if (result && result.features) {
              const lyrObj = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId, { outFields: ["*"] });
              lyrObj.on("Load", (evt) => {
                result.features.forEach((feature) => {
                  feature["_layer"] = lyrObj;
                });
                if (this.menuItems.filter((item) => item.LayerName.layerId === this.currentUser.serviceLyrInfo.lightId).length === 0) {
                  this.menuItems.splice(index + 1, 0, {
                    Data: result.features,
                    LayerName: { layerId: this.currentUser.serviceLyrInfo.lightId, layerName: "Light", tableAlias: "Light", tableName: "Light" }
                  });
                }
              });
            }
          });
        }
        if (
          signAssetIds.length > 0 &&
          this.currentUser.layerLevelInfo.length > 0 &&
          this.currentUser.layerLevelInfo.filter((layer) => parseInt(layer.gisLayerId, 10) == this.currentUser.serviceLyrInfo.signId).length > 0
        ) {
          const signQuery = new this.esritools.Query();
          signQuery.returnGeometry = true;
          signQuery.outFields = ["*"];
          signQuery.where = "AssetID IN ('" + signAssetIds.join("','") + "')";
          const signQueryTask = new this.esritools.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId);
          signQueryTask.execute(signQuery).then((result) => {
            if (result && result.features) {
              const lyrObj = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId, { outFields: ["*"] });
              lyrObj.on("Load", (evt) => {
                result.features.forEach((feature) => {
                  feature["_layer"] = lyrObj;
                });
                if (this.menuItems.filter((item) => item.LayerName.layerId === this.currentUser.serviceLyrInfo.signId).length === 0) {
                  this.menuItems.splice(index + 1, 0, {
                    Data: result.features,
                    LayerName: { layerId: this.currentUser.serviceLyrInfo.signId, layerName: "Sign", tableAlias: "Sign", tableName: "Sign" }
                  });
                }
              });
            }
          });
        }
      }
    });
    setTimeout(() => {
      this.selectTab(this.eventEmitersService.selectedLayerIndexOnData);
      this.loadSelectedFeatureTable();
    }, 2000);
  }
  // Added by Abhilash Jira Id:-39 on 9/28/2021 End

  loadFeatureTable() {
    const columns = this.result.features.filter(
      (feature) =>
        //Jira Id-933
        feature.attributes.TableName.toLowerCase() === this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName.tableName.toLowerCase()
    );
    const fieldInfosdata = [];

    if (!this.scndlyr) {
      columns.forEach((element) => {
        fieldInfosdata.push({
          name: element.attributes.Field,
          alias: element.attributes.FieldAlias,
          visible: !!element.attributes.GridVisible
        });
      });
    } else if (this.scndlyr) {
      columns.forEach((element) => {
        fieldInfosdata.push({
          name: element.attributes.Field,
          alias: element.attributes.FieldAlias_SL,
          visible: !!element.attributes.GridVisible
        });
      });
    }

    if (this.myFeatureTable) {
      this.myFeatureTable.destroy();
    }

    if (document.getElementById("divfeatureTable")) {
      document.getElementById("divfeatureTable").remove();
    }

    const _$matCard = this.renderer.createElement("div");
    this.renderer.setProperty(_$matCard, "id", "divfeatureTable");
    const container = document.getElementById("attrGridMainContent");
    this.renderer.appendChild(container, _$matCard);
    this.myFeatureTable = new this.FeatureTable(
      {
        featureLayer: this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName.lyrObj,
        map: this.map,
        showAttachments: true,
        batchCount: 2000,
        showGridMenu: false,
        showStatistics: false,
        showRelatedRecords: false,
        fieldInfos: fieldInfosdata,
        gridOptions: {
          allowSelectAll: false,
          allowTextSelection: false,
          pagination: false,
          pageSizeOptions: [10, 25, 50],
          selectionMode: "single"
        }
      },
      "divfeatureTable"
    );

    const selfeatures = this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName.lyrObj.getSelectedFeatures();
    const idofselfeatures = [];
    selfeatures.forEach((element) => {
      idofselfeatures.push(element.attributes.OBJECTID);
    });

    this.myFeatureTable.startup();
    this.myFeatureTable.filterRecordsByIds(idofselfeatures);
    this.myFeatureTable.filterSelectedRecords();
    this.myFeatureTable.on(
      "row-select",
      function (evt) {
        let parentIndex = 0;

        if (evt.rows[0] && evt.rows[0].data && evt.rows[0].data.AssetID) {
          parentIndex = this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.findIndex((feature) => feature.attributes.AssetID === evt.rows[0].data.AssetID);

          if (parentIndex !== this.eventEmitersService.selectedParentIndexOnData) {
            this.eventEmitersService.setSelectedParentIndexOnData(parentIndex);
          }
        }

        // console.log(parentIndex)

        //this.selectedRows.push(evt.rows[0].data);
        // console.log(evt.rows[0].data);
        // selectedRows.push(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName);
        // this.selectedRow.emit(selectedRows);
        // this.objAssetDetailService.gridCardCommonSelectedAsset.emit(selectedRows);
      }.bind(this)
    );

    this.myFeatureTable.sort("OBJECTID");
    this.objGlobals.featureTableObj = this.myFeatureTable;
    setTimeout(() => {
      this.rowSelectionData();
    }, 2000);
  }

  rowSelectionData() {
    // this.clearRowSelectionInGrid();
    // const selectedRows = [];

    this.selectedRows = [];
    // this.selectedRows.push(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes);
    this.myFeatureTable.selectRows(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.OBJECTID, true);

    //selectedRows.push(this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName);
    // this.selectedRow.emit(selectedRows);
    // this.objAssetDetailService.gridCardCommonSelectedAsset.emit(selectedRows);
  }
  closeGrid() {
    //add call to toggle asset viewer size.
    if (this.myFeatureTable) {
      this.myFeatureTable.destroy();
    }

    this.eventEmitersService.setShowGrid(false);
  }
  getCloseIcon() {
    return "cross";
  }

  getAddIcon() {
    return "plus";
  }
  getDownloadIcon() {
    return "download";
  }
  clearRowSelectionInGrid() {
    if (this.myFeatureTable) {
      this.myFeatureTable.clearSelection();
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  selectTab(id) {
    // Added by Abhilash Jira Id:-39 on 9/28/2021
    this.menuItems.forEach((item, index) => {
      if (item.LayerName.layerName == this.eventEmitersService.selectedFeaturesOnMap[id].LayerName.layerName) {
        this.activeTabId = index;
      }
    });
    // this.loadFeatureTable();
    this.loadSelectedFeatureTable();
  }

  tabChangedFromUI(event) {
    // Added by Abhilash Jira Id:-39 on 9/28/2021
    if (event.tab.textLabel != "Sign" && event.tab.textLabel != "Light") {
      this.eventEmitersService.selectedFeaturesOnMap.forEach((item, index) => {
        if (item.LayerName.tableAlias == event.tab.textLabel) {
          //TSDRSAM-1913
          this.eventEmitersService.setSelectedLayerIndexOnData(index);
        }
      });
    }
    // TSDRSAM-1090 By Manikantha V -->
    else {
      this.eventEmitersService.setSelectedLayerIndexOnData(0);
    }
    this.activeTabId = event.index;
    this.loadSelectedFeatureTable();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  relatedAssetSet(colName, value, object, layer) {
    this.query.map = this.map;
    this.query.RelationshipQuery = this.RelationshipQuery;
  }
  // Added by Abhilash Jira Id:-39 on 9/28/2021
  customExportCSV(format) {
    // const data: any[][] = this.myFeatureTable.dataStore.data;
    const data: any[][] = this.selFeaturerowData;

    if (data == null || !data.length) {
      return null;
    }

    let csv;
    const tablebody = [];
    const tableheader = [];
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const columns = [];
    this.columnDefsselFeature.forEach((element) => {
      // if (!element.hidden) {
      if (element.field != "Photo") {
        columns.push(element.field);
      }
      // }
    });
    const arr = columns.map((item) => ({ key: item, value: "" }));
    const columnkeys = arr.reduce(function (map, obj) {
      map[obj.key] = obj.value;
      return map;
    }, {});
    const keys = Object.keys(columnkeys);
    let result = "";
    //TSDRSAM-2569 fixes
    let columnLength = keys.length;
    if (format === "PDF") { 
      columnLength = 15 
    }

    keys.forEach((key, i) => {
      if (i < columnLength) {
        tableheader.push({ text: key, style: "tableHeader" });
      }
    });
    tablebody.push(tableheader);
    result += tableheader.map(x=>x.text).join(columnDelimiter);
    result += lineDelimiter;
    let ctr = 0;

    // const selfeatures =
    //   this.eventEmitersService.selectedFeaturesOnMap[
    //     this.eventEmitersService.selectedLayerIndexOnData
    //   ].LayerName.lyrObj.getSelectedFeatures();
    const selfeatures = data;
    selfeatures.forEach((element) => {
      const tablerow = [];

      if (element) {
        keys.forEach(function (key, i) {
          if (i < columnLength) {
            result += element[key];
            tablerow.push(element[key] == undefined ? "" : element[key]);
            result += columnDelimiter;
          }
        });
        ctr++;
        tablebody.push(tablerow);
        result += lineDelimiter;
      }
    });

    if (ctr === selfeatures.length && format === "CSV") {
      csv = result;

      if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
      }

      const encodedUri = encodeURI(csv);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const filename = this.menuItems[this.activeTabId].LayerName.layerName + ".csv";
      link.setAttribute("download", filename);
      link.click();
    }

    if (ctr === selfeatures.length && format === "PDF") {
      const dd = {
        pageSize: "A4",
        pageOrientation: "landscape",
        fontSize: 8,
        widths: "auto",
        content: [
          {
            style: "tableExample",
            table: {
              body: tablebody
            },
            pageOrientation: "portrait",
            layout: {
              fillColor(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              }
            }
          }
        ],
        styles: {
          header: {
            fontSize: 9,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 8,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 8
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black"
          }
        }
      };
      Swal.fire("Only first 15 attributes will be downloaded in the PDF format.");
      pdfMake.createPdf(dd).download(
        // this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].LayerName.layerName + ".pdf"
        this.menuItems[this.activeTabId].LayerName.layerName + ".pdf"
      );
    }
  }
  exportExcel() {
    this.customExportCSV(this.format);
  }
  selectAssetFromCardView(asset) {
    if (this.gridApi) {
      this.gridApi.forEachNode(function (node) {
        node.setSelected(node.data.country === asset[0].OBJECTID);
      });
    }
  }
  ngOnDestroy() {
    if (this.myFeatureTable) {
      this.myFeatureTable.destroy();
    }
  }

  // Added by Abhilash Jira Id:-40 on 9/28/2021 Start
  viewImage(content, childFlag) {
    this.photosData = [];
    this.imageSrc = "";
    this.PhotoDate = "";
    this.SourceTag = "";
    this.MeasureValue = "";
    this.imgBase64 = "";
    this.samples = [];
    if (childFlag) {
      this.activeAsset = "";
    } else {
      this.activeAsset = this.eventEmitersService.activeAssetFeature;
    }
    this.findUserAccessByRoleRegionLayer();
    this.photoFl = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);
    if (childFlag) {
      this.activeAsset = "";
      this.linearAssetValues = false;
    } else if (this.activeAsset[2].geometry !== undefined && this.activeAsset[2].geometry.type === "polyline") {
      this.linearAssetValues = true;
    } else {
      this.linearAssetValues = false;
    }
    let assetId;
    if (childFlag) {
      assetId = this.selectedRowData[0].AssetID;
    } else if (this.activeAsset[0].AssetID) {
      assetId = this.activeAsset[0].AssetID;
    } else {
      assetId = this.activeAsset[0].signAttribute.AssetID;
    }
    this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.documentId, "AssetID", assetId).subscribe((results: any) => {
      results.features.forEach((data) => {
        this.photosData.push(data);
      });
      this.imageGenerate();
    });
    this.modalReference = this.modalService.open(content, {
      centered: true,
      windowClass: "asset-photo",
      size: "xl"
    });
  }
  cancel() {
    this.modalReference.close();
  }

  imageGenerate(data?) {
    this.samples = [];
    const photoData = data ? data : this.photosData;
    let contentType;
    if (photoData.length > 0) {
      photoData.forEach((photo) => {
        //70
        this.objAssetDetailService.getImageAsBase64(photo.attributes.OBJECTID, this.currentUser.clientId, photo.attributes.DocType).subscribe(
          (base64Image: any) => {
            if (photo.attributes.DocType.toLowerCase() == "pdf") {
              contentType = "application/pdf";
            } else {
              contentType = "image/png";
            }
            this.samples.push({
              label: photo.attributes.Filename,
              imgBase64: base64Image,
              url: `data:${contentType};base64, ${base64Image}`,
              attributes: photo.attributes,
              Date: photo.attributes.DocDate,
              SourceTag: photo.attributes.SourceTag,
              Measure: photo.attributes.Asset_Segment_Measure
            });
            this.DescendingOrder("Date");
          },
          (err) => {
            if (err.message && err.message == "File Not Found") {
              this.samples.push({
                label: photo.attributes.Filename,
                url: "",
                imgBase64: "",
                attributes: photo.attributes,
                Date: photo.attributes.DocDate,
                SourceTag: photo.attributes.SourceTag,
                Measure: photo.attributes.Asset_Segment_Measure
              });
              this.DescendingOrder("Date");
            }
          }
        );
      });
    }
  }
  DescendingOrder(sortKey) {
    this.samples.sort((a, b) => {
      const x = a[sortKey];
      const y = b[sortKey];
      if (x < y) {
        return 1;
      }
      if (x > y) {
        return -1;
      }
      return 0;
    });
    if (this.samples.length > 0) {
      this.imageSrc = this.samples[0].url;
      this.PhotoDate = this.samples[0].Date;
      this.SourceTag = this.samples[0].SourceTag;
      this.MeasureValue = this.samples[0].Measure;
      this.imgBase64 = this.samples[0].imgBase64;
    } else {
      this.imageSrc = "";
      this.PhotoDate = "";
      this.SourceTag = "";
      this.MeasureValue = "";
      this.imgBase64 = "";
    }
  }
  AscendingOrder(sortKey) {
    this.samples.sort((a, b) => {
      const x = a[sortKey];
      const y = b[sortKey];
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    if (this.samples.length > 0) {
      this.imageSrc = this.samples[0].url;
      this.PhotoDate = this.samples[0].Date;
      this.SourceTag = this.samples[0].SourceTag;
      this.MeasureValue = this.samples[0].Measure;
      this.imgBase64 = this.samples[0].imgBase64;
    } else {
      this.imageSrc = "";
      this.PhotoDate = "";
      this.SourceTag = "";
      this.MeasureValue = "";
      this.imgBase64 = "";
    }
  }
  OrderImages(sortKey) {
    if (sortKey === "Date") {
      if (this.showDateByOrder) {
        this.showDateByOrder = !this.showDateByOrder;
        this.DescendingOrder(sortKey);
      } else {
        this.showDateByOrder = !this.showDateByOrder;
        this.AscendingOrder(sortKey);
      }
    } else if (sortKey === "Measure") {
      if (this.showSequenceByOrder) {
        this.showSequenceByOrder = !this.showSequenceByOrder;
        this.DescendingOrder(sortKey);
      } else {
        this.showSequenceByOrder = !this.showSequenceByOrder;
        this.AscendingOrder(sortKey);
      }
    } else if (sortKey === "SourceTag") {
      if (this.showSourceByOrder) {
        this.showSourceByOrder = !this.showSourceByOrder;
        this.DescendingOrder(sortKey);
      } else {
        this.showSourceByOrder = !this.showSourceByOrder;
        this.AscendingOrder(sortKey);
      }
    }
  }
  EventChange(event) {
    this.imageSrc = event.value;
    this.samples.forEach((imageData) => {
      if (imageData.url === event.value) {
        this.PhotoDate = imageData.Date;
        this.SourceTag = imageData.SourceTag;
        this.MeasureValue = imageData.Measure;
        this.imgBase64 = imageData.imgBase64;
      }
    });
  }
  findUserAccessByRoleRegionLayer() {
    let layerinfo;
    // Region
    if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
      if (this.activeAsset && this.activeAsset.length > 0) {
        layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.activeAsset[1].lyrObj.layerId) {
              return layer;
            }
          }.bind(this)
        )[0];
      } else {
        layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.currentUser.serviceLyrInfo.signId || parseInt(layer.gisLayerId, 10) === this.currentUser.serviceLyrInfo.lightId) {
              return layer;
            }
          }.bind(this)
        )[0];
      }
      // Layer
      if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
        this.findUserAccessByRoleRegionLayerVar = true;
      } else {
        this.findUserAccessByRoleRegionLayerVar = false;
      }
    } else {
      this.findUserAccessByRoleRegionLayerVar = false;
    }
  }
  export() {
    if (this.imgBase64 !== undefined && this.imgBase64 !== "") {
      var selectedFile = { fileBase64: this.imageSrc };
      var mimeType = getMimeType(selectedFile.fileBase64);
      if (mimeType.toLowerCase() == "pdf") {
        var pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.imgBase64) + "'></iframe>");
      } else {
        var image = new Image();
        image.src = "data:image/" + mimeType + ";base64," + this.imgBase64;
        var w = window.open("_blank");
        w.document.write(image.outerHTML);
      }
    } else {
      Swal.fire(this.translateService.instant("Select.alertassessment.nodocument"));
    }
  }
  setThis() {
    return this;
  }
  uploadNew(files) {
    if (files.length > 0) {
      const fileElement = environment.DocumentSupportFormats.includes(files[0].name.split(".")[files[0].name.split(".").length - 1].toLowerCase());
      if (!fileElement) {
        Swal.fire(this.translateService.instant("Select.file.unsupportedformat"));
        const input: any = document.querySelector("input[type=file]");
        input.value = null;
        return;
      }
      const _this = this.setThis();
      const doctyp = files[0].name.split(".")[1].toUpperCase();

      const photoAsset = new this.esritools.Graphic();
      const attr: any = [];
      attr.AssetID = this.selectedRowData[0].AssetID;
      attr.SourceTag = "General";
      attr.DocType = doctyp;
      attr.DocID = this.selectedRowData[0].OBJECTID;
      attr.Filename = files[0].name;
      attr.DocDate = new Date(Date.now()).getTime();
      attr.ModifiedBy = this.currentUser.userId;
      photoAsset.setAttributes(attr);

      const photoFl = new this.esritools.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.documentId);

      photoFl.applyEdits(
        [photoAsset],
        null,
        null,
        (res) => {
          if (res[0].success) {
            _this.objAssetDetailService.UpdateDocId(_this.esritools.Graphic, res[0].objectId, photoFl);
            this.uploadfileName = files[0].name;
            const options = new ResizeOptions();
            // eslint-disable-next-line camelcase
            options.Resize_Max_Height = 1280;
            // eslint-disable-next-line camelcase
            options.Resize_Max_Width = 1280;
            // eslint-disable-next-line camelcase
            options.Resize_Quality = 80;
            // eslint-disable-next-line camelcase
            options.Resize_Type = "image/jpg";

            const reader = new FileReader();
            let fileInBase64Data;
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
              fileInBase64Data = reader.result;
            };

            if (files[0].name.split(".")[1].toUpperCase() == "PDF") {
              _this.objAssetDetailService.uploadImage(files[0], res[0].objectId, this.currentUser.clientId).subscribe(
                (uploadres: any) => {
                  if (uploadres.message === "Photo Saved Successfully") {
                    Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                    photoAsset.attributes.OBJECTID = res[0].objectId;
                    this.samples.push({
                      label: photoAsset.attributes.Filename,
                      imgBase64: fileInBase64Data.split(",")[1],
                      url: `data:application/pdf;base64, ${fileInBase64Data.split(",")[1]}`,
                      attributes: photoAsset.attributes,
                      Date: photoAsset.attributes.DocDate,
                      SourceTag: photoAsset.attributes.SourceTag,
                      Measure: photoAsset.attributes.Asset_Segment_Measure
                    });
                    if (this.samples.length > 0) {
                      this.imageSrc = this.samples[0].url;
                      this.PhotoDate = this.samples[0].Date;
                      this.SourceTag = this.samples[0].sourceTag;
                      this.MeasureValue = this.samples[0].Measure;
                      this.imgBase64 = this.samples[0].imgBase64;
                    } else {
                      this.imageSrc = "";
                      this.PhotoDate = "";
                      this.SourceTag = "";
                      this.MeasureValue = "";
                      this.imgBase64 = "";
                    }
                    this.DescendingOrder("Date");
                  }
                },
                (err) => {
                  if (err.error.message === "File Format/Size is Not Correct") {
                    const featurese = [];
                    const features = new this.esritools.Graphic();
                    const assetAttr: any = [];
                    assetAttr.OBJECTID = res[0].objectId;
                    features.setAttributes(assetAttr);
                    featurese.push(features);
                    photoFl
                      .applyEdits(null, null, featurese)
                      .then(
                        function () {
                          Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                        }.bind(this)
                      )
                      .catch(function (error) {
                        console.log("===============================================");
                        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                        console.log("error = ", error);
                      });
                  } else {
                    console.log(err.error.message);
                  }
                }
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const newFileDataC = _this.compressImage(files[0], options).then((newFileDataC: any) => {
                _this.objAssetDetailService.uploadImage(newFileDataC, res[0].objectId, this.currentUser.clientId).subscribe(
                  (uploadres: any) => {
                    if (uploadres.message === "Photo Saved Successfully") {
                      Swal.fire(this.translateService.instant("Select.alertassessment.fileuploadsuccess"));
                      photoAsset.attributes.OBJECTID = res[0].objectId;
                      this.samples.push({
                        label: photoAsset.attributes.Filename,
                        imgBase64: fileInBase64Data.split(",")[1],
                        url: `data:image/png;base64, ${fileInBase64Data.split(",")[1]}`,
                        attributes: photoAsset.attributes,
                        Date: photoAsset.attributes.DocDate,
                        SourceTag: photoAsset.attributes.SourceTag,
                        Measure: photoAsset.attributes.Asset_Segment_Measure
                      });
                      if (this.samples.length > 0) {
                        this.imageSrc = this.samples[0].url;
                        this.PhotoDate = this.samples[0].Date;
                        this.SourceTag = this.samples[0].sourceTag;
                        this.MeasureValue = this.samples[0].Measure;
                        this.imgBase64 = this.samples[0].imgBase64;
                      } else {
                        this.imageSrc = "";
                        this.PhotoDate = "";
                        this.SourceTag = "";
                        this.MeasureValue = "";
                        this.imgBase64 = "";
                      }
                      this.DescendingOrder("Date");
                    }
                  },
                  (err) => {
                    if (err.error.message === "File Format/Size is Not Correct") {
                      const featurese = [];
                      const features = new this.esritools.Graphic();
                      const assetAttr: any = [];
                      assetAttr.OBJECTID = res[0].objectId;
                      features.setAttributes(assetAttr);
                      featurese.push(features);
                      photoFl
                        .applyEdits(null, null, featurese)
                        .then(
                          function () {
                            Swal.fire(this.translateService.instant("Select.alertassessment.filelimit"));
                          }.bind(this)
                        )
                        .catch(function (error) {
                          console.log("===============================================");
                          console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                          console.log("error = ", error);
                        });
                    } else {
                      console.log(err.error.message);
                    }
                  }
                );
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  async compressImage(fileData, options) {
    const result = await this.compressImageAndUpload(fileData, options);
    return result;
  }
  compressImageAndUpload(fileData, options) {
    return new Promise((resolve) => {
      const files: any = [fileData];
      const images = [];
      let newfileData;
      // let images: Array<IImage> = [];
      this.imageCompressService.compressImageArray(files, options).subscribe(
        (image) => {
          images.push(image);
        },
        (error) => {
          console.log("Error while converting");
        },
        () => {
          let processedImages = images;
          console.log();
          newfileData = this.dataURItoBlob(processedImages[0].compressedImage.imageDataUrl);
          resolve(newfileData);
        }
      );
      // this.imageCompressService.compressImageArray(files, options).then(
      //   function (observableImages) {
      //     observableImages.subscribe(
      //       (image) => {
      //         images.push(image);
      //       },
      //       (error) => {
      //         console.log("Error while converting");
      //       },
      //       () => {
      //         this.processedImages = images;
      //         console.log();
      //         newfileData = this.dataURItoBlob(this.processedImages[0].compressedImage.imageDataUrl);
      //         resolve(newfileData);
      //       }
      //     );
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // return new Blob([ab], { type: 'image/jpeg' });
    return new File([ia], this.uploadfileName, { type: "image/jpeg" });
  }
  dataURItoBlobTest(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  deleteImage() {
    Swal.fire({
      title: environment.deleteMessage,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.translateService.instant("Select.alertassessment.delete")
    }).then((result) => {
      if (result.value) {
        this.samples.forEach((data, key) => {
          if (data.url === this.imageSrc) {
            const featurese = [];
            const features = new this.esritools.Graphic();
            features.setAttributes(data.attributes);
            featurese.push(features);
            this.photoFl
              .applyEdits(null, null, featurese)
              .then(
                function () {
                  Swal.fire(this.translateService.instant("Select.alertcarddisplay.deletedsucceffully"));
                  this.samples.splice(key, 1);
                  if (this.samples.length > 0) {
                    this.imageSrc = this.samples[0].url;
                    this.PhotoDate = this.samples[0].Date;
                    this.SourceTag = this.samples[0].SourceTag;
                    this.MeasureValue = this.samples[0].Measure;
                    this.imgBase64 = this.samples[0].imgBase64;
                  } else {
                    this.imageSrc = "";
                    this.PhotoDate = "";
                    this.SourceTag = "";
                    this.MeasureValue = "";
                    this.imgBase64 = "";
                  }
                }.bind(this)
              )
              .catch(function (error) {
                console.log("===============================================");
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                console.log("error = ", error);
              });
          }
        });
      }
    });
  }
  // Added by Abhilash Jira Id:-40 on 9/28/2021 End
}
