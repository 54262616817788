export const LANG_DE_NAME = "de";

export const LANG_DE_TRANS = {
  "Login.logo.name": "Zeichenverwaltungssystem",
  "Login.page.text1": "Sicherere, schnellere und leichtere Wege",
  "Login.page.text2": "die Welt in Bewegung halten",
  "Login.loginbox.heading": "Anmeldung",
  "Login.loginbox.text": "Geben Sie unten Ihre Anmeldeinformationen ein, um sich bei 3M Road Asset Manager anzumelden",
  "Login.loginbox.userplaceholder": "Geben Sie Ihre Benutzer-ID ein",
  "Login.loginbox.passwordplaceholder": "Geben Sie Ihr Passwort ein",
  "Login.loginbox.button": "Einloggen",
  "Login.loginvalidate.text1": "Ihre Benutzer-ID und Ihr Passwort stimmen nicht überein",
  "Login.loginvalidate.text2": "Bitte geben Sie gültige Anmeldeinformationen ein, um sich anzumelden",
  "Login.loginvalidate.tryagain": "Versuch es noch einmal?",
  "Login.loginvalidate.forgotpassword": "Passwort vergessen? Hier zurücksetzen.",
  "Login.loginpage.legaltext": "Legal",
  "Login.loginpage.privacytext": "Privatsphäre",
  "Login.loginpage.rightstext": "Alle Rechte vorbehalten",
  "Login.loginsuccess.welcome": "Herzlich willkommen!",
  "Login.loginsuccess.msgtext": "Sie haben sich erfolgreich angemeldet",
  "Login.loginsuccess.getstartedbtn": "LOSLEGEN",
  "Login.afterlogin.term&condition": "Geschäftsbedingungen",
  "Login.afterlogin.acceptbtn": "Akzeptieren",
  "Login.newlogin.changepasstxt": "Passwort ändern",
  "Login.newlogin.oldpasstxt": "Altes Passwort",
  "Login.newlogin.oldpassdesc": "Bitte geben Sie das alte Passwort an",
  "Login.newlogin.newpasstxt": "Neues Kennwort",
  "Login.newlogin.newpassdesc": "Bitte geben Sie ein neues Passwort ein",
  "Login.changepwdvalidate.newandold": "Das neue Passwort sollte sich vom alten Passwort unterscheiden",
  "Login.changepwdvalidate.confrmpwd": "Kennwort bestätigen",
  "Login.changepwdvalidate.confrmpwddesc": "Bitte geben Sie das Passwort bestätigen ein",
  "Login.changepwdvalidate.confrmpwddesc2": "Bestätigen Sie, dass das Passwort mit dem neuen Passwort übereinstimmen sollte",
  "Login.changepswd.changepswdbtn": "Passwort ändern",
  "User.Account.Inactive": "Ihr Konto ist inaktiv. Bitte wenden Sie sich an den Lösungsadministrator Ihrer Agentur.",
  "User.LockOut.Contact.SolutionAdmin": "Ihr Konto ist gesperrt. Bitte wenden Sie sich an den Lösungsadministrator Ihrer Agentur, um sie zu entsperren.",
  "User.Password.Expired": "Ihr Passwort ist abgelaufen, bitte erstellen Sie ein neues.",
  "User.AttemptRemaining.1": "Ungültiger Anmeldeversuch. ",
  "User.AttemptRemaining.2": " von ",
  "User.AttemptRemaining.3": " Versuchen verbleibend.",
  "Login.alert.reloginmessage": "Klicken Sie auf Ok, um sich erneut anzumelden",
  "Login.alert.passwordsuccessmsg": "Passwort wurde erfolgreich geändert",
  "Login.alertuseradmin.InvalidPassword": "Muss zwischen %s und %s Zeichen enthalten, einschließlich mindestens %s Großbuchstaben, %s Kleinbuchstaben, %s Zahlen und %s Sonderzeichen",
  "Login.changepswd.passUsernameSame":"Das Passwort darf nicht mit der Benutzer-ID identisch sein",
  "Login.changepswd.passNotValid":"Altes Passwort ist ungültig",
  "Login.changepswd.InvalidPasswordLast24":"Neues Passwort kann kein kürzlich verwendetes Passwort sein",
  "Login.loginbox.required":"Erforderlich",
  "Login.alert.username": "Bitte geben sie einen Benutzernamen ein",
  "Login.alert.password": "Bitte Passwort eingeben"

};
