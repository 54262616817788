<div class="card">
    <div class="card-body admin-model-height formbg-d2">
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Min Length</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="minLength" autocomplete="off" aria-label="Min Length" #minInput="ngModel" >
                    <div *ngIf="minInput.errors || (minLength > maxLength )" class="error">
                        Min length must be less than max length
                    </div>
                    <div *ngIf="minInput.errors || !(minLength >=(minUpperCase + minLowerCase + minSpecialChar + minNumeric))" class="error">
                        Min length must be greater than or equal of other minimum values ({{ minUpperCase + minLowerCase + minSpecialChar + minNumeric}})
                    </div>
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Max Length</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" max="100" oninput="validity.valid||(value='');"
                    [(ngModel)]="maxLength" autocomplete="off" aria-label="Max Length" #maxInput="ngModel">
                    <div *ngIf="maxInput.errors?.min || !(maxLength >=(minLength + minUpperCase + minLowerCase + minSpecialChar + minNumeric))" class="error">
                        Max length must be greater than or equal to the total minimum values ({{minLength + minUpperCase + minLowerCase + minSpecialChar + minNumeric}})
                    </div>
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Min Special Characters</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="minSpecialChar" autocomplete="off" aria-label="Min Special Characters">
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Min Upper Case</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="minUpperCase" autocomplete="off" aria-label="Min Upper Case">
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Min Lower Case</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="minLowerCase" autocomplete="off" aria-label="Min Lower Case">
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Min Numeric</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="minNumeric" autocomplete="off" aria-label="Min Numeric">
            </div>
        </div>
        <div class="form-row my-3">
            <div class="col-2">
                <label class="text-green"> Days Before Change</label>
            </div>
            <div class="col-3">
                <input class="form-control" type="number" min="1" oninput="validity.valid||(value='');"
                    [(ngModel)]="daysBeforeChange" autocomplete="off" aria-label="Days Before Change">
            </div>
        </div>
        <div class="row d-flex justify-content-end mr-2"><button [disabled] ="(minLength > maxLength ) || !(maxLength >=(minLength + minUpperCase + minLowerCase + minSpecialChar + minNumeric)) || !(minLength >=(minUpperCase + minLowerCase + minSpecialChar + minNumeric))" (click)="save()" class="btn btn-secondary ml-4">
                Save</button>

        </div>
    </div>