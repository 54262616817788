import { Injectable } from "@angular/core";

@Injectable()
export class SystemReportsStyle {
  public getReportStyle() {
    const reportStyle = {
      header: {
        fillColor: "#ffffff",
        color: "darkblue",
        fontSize: 30,
        margin: [0, 10, 0, 10],
        bold: true
      },
      subheader: {
        fontSize: 15,
        margin: [0, 11, 0, 21]
      },
      subheaderTop: {
        fontSize: 15,
        margin: [0, 11, 0, 2]
      },
      subheaderMiddle: {
        fontSize: 15,
        margin: [0, 2, 0, 2]
      },
      subheaderBottom: {
        fontSize: 15,
        margin: [0, 2, 0, 21]
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: "black",
        fillColor: "#e4e4e8",
        alignment: "left"
      },
      tableContent: {
        fontSize: 8,
        alignment: "left"
      },
      tableContentLink: {
        color: "#0000ee",
        decoration: "underline",
        fontSize: 13,
        alignment: "left"
      },
      rowGroupHeader: {
        fillColor: "#d3e9ff",
        bold: true
      },
      footerLeft: {
        margin: [30, 10, 10, 0],
        alignment: "left"
      },
      footerCenter: {
        margin: [-20, 10, 30, 0],
        alignment: "left"
      }
    };
    return reportStyle;
  }

  public getReportLayout() {
    const reportLayout = {
      fillColor(rowIndex, node, columnIndex) {
        return null;
      },
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
      },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? "#D3D3D3" : "#D3D3D3";
      },
      vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? "#D3D3D3" : "#D3D3D3";
      },
      hLineStyle(i, node) {
        return null;
      },
      vLineStyle(i, node) {
        return null;
      },
      paddingLeft: function (i, node) {
        return 4;
      },
      paddingRight: function (i, node) {
        return 4;
      },
      paddingTop: function (i, node) {
        return 2;
      },
      paddingBottom: function (i, node) {
        return 2;
      }
    };
    return reportLayout;
  }

  public getReportFooter() {
    const reportFooter = function (currentPage, pageCount) {
      return {
        height: 20,
        columns: [
          {
            text: new Date().toLocaleString(),
            style: "footerLeft"
          },
          {
            text: currentPage.toString() + " of " + pageCount,
            style: "footerCenter"
          }
        ]
      };
    };

    return reportFooter;
  }
}
