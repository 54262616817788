import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeadingComponent as HeadingSuperComponent } from "./heading-component/heading.component";
import { MatIconModule } from "@angular/material/icon";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { TextFieldComponent } from "./text-field/text-field.component";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ButtonComponent } from "./button/button.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LoginTranslateService } from "src/app/shared/login-translation.service";
import { LoginTranslatePipe } from "src/app/shared/login-translate.pipe";
import { TRANSLATION_PROVIDERS } from "src/app/shared/translation/translation";
@NgModule({
  imports: [CommonModule, MatIconModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatCheckboxModule, FormsModule, MatMenuModule, MatTooltipModule, MatInputModule],
  declarations: [HeadingSuperComponent, DropdownComponent, TextFieldComponent, ButtonComponent, LoginTranslatePipe],
  providers: [TRANSLATION_PROVIDERS, LoginTranslateService],
  exports: [HeadingSuperComponent, DropdownComponent, TextFieldComponent, LoginTranslatePipe]
})
export class SharedModule {}
