import { Component, OnInit } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { NgbModalConfig, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "sms-TandC",
  templateUrl: "./TandC.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class TandCComponent implements OnInit {
  modalReference: NgbModalRef;
  agencyName;
  agencyWebService;
  SolutionTier;
  agencySpatialDatabase;
  PrimaryAgencyLanguage;
  SecondaryAgencyLanguage;
  TermsandConditonList;
  Device = ["Web", "Mobile"];
  AgencyLanaguage = [];
  agencyLangSelect;
  DeviceSelect;
  UploadFile;
  showUploadButton = false;
  constructor(private adminService: MMMAdminService, private globalVariable: adminGlobal, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  Open(content) {
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  ngOnInit() {
    this.TermsandConditonList = [];

    if (this.globalVariable.agencyDetails === undefined || this.globalVariable.agencyDetails.length < 1) {
      Swal.fire("Please add or select Agency in the Agency Configuration Screen");
    } else {
      this.showUploadButton = true;
      const details = this.globalVariable.agencyDetails[0];
      this.agencyName = details.clientName;
      this.agencyWebService = details.webServiceUrl;
      this.SolutionTier = details.clientType;
      this.agencySpatialDatabase = details.clientDb;
      this.globalVariable.PALValues.forEach((dataVal) => {
        if (dataVal.languageID === details.primaryLanguageID) {
          this.PrimaryAgencyLanguage = dataVal.languageName;
          this.AgencyLanaguage.push(dataVal);
        }
      });

      if (details.secondaryLanguageID !== null) {
        this.globalVariable.PALValues.forEach((dataVal) => {
          if (dataVal.languageID === details.secondaryLanguageID) {
            this.SecondaryAgencyLanguage = dataVal.languageName;
            this.AgencyLanaguage.push(dataVal);
          }
        });
      }

      this.TermsAndConditions();
    }
  }
  TermsAndConditions() {
    try {
      const CheckURLS = [
        this.globalVariable.agencyDetails[0].clientType + "_" + this.globalVariable.agencyDetails[0].primaryLanguageID + "_mobile.html",
        this.globalVariable.agencyDetails[0].clientType + "_" + this.globalVariable.agencyDetails[0].primaryLanguageID + "_web.html"
      ];

      if (this.globalVariable.agencyDetails[0].secondaryLanguageID !== null && this.globalVariable.agencyDetails[0].secondaryLanguageID !== 0) {
        CheckURLS.push(this.globalVariable.agencyDetails[0].clientType + "_" + this.globalVariable.agencyDetails[0].secondaryLanguageID + "_mobile.html");
        CheckURLS.push(this.globalVariable.agencyDetails[0].clientType + "_" + this.globalVariable.agencyDetails[0].secondaryLanguageID + "_web.html");
      }

      this.CheckURL(CheckURLS);
    } catch (error) {
      console.error(error);
    }
  }

  CheckURL(URLS) {
    URLS.forEach((element) => {
      this.TermsandConditonList.push({
        fileName: element,
        perviewURL: environment.apiBaseUrl + environment.apiwebroot + "toc/" + element
      });
    });
  }

  UploadTOCDoc() {
    try {
      if (this.UploadFile === undefined) {
        Swal.fire("Please select TOC file");
      } else if (this.agencyLangSelect === undefined) {
        Swal.fire("Please select Agency Language");
      } else if (this.DeviceSelect === undefined) {
        Swal.fire("Please select Device");
      } else {
        this.adminService.postTOC(this.UploadFile[0], this.globalVariable.agencyDetails[0].clientType, this.agencyLangSelect, this.DeviceSelect).subscribe(
          (res) => {
            Swal.fire({
              title: "TOC File saved successfully"
            }).then((result) => {
              if (result.value) {
                this.modalReference.close();
                this.UploadFile = undefined;
                this.agencyLangSelect = undefined;
                this.DeviceSelect = undefined;
              }
            });
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  upload(file) {
    this.UploadFile = file;
  }

  Perview(URL) {
    window.open(URL, "_blank");
  }
}
