import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { loadModules } from "esri-loader";
import { AuthenticationService } from "../../../services/authentication.service";
import { GenericLayerService } from "../../../services/genericLayer.service";
import { Globals } from "../../../select/globals";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import Swal from "sweetalert2";
import { CrewManagementService } from "../../crewmanagement.service";
import { faCalendarAlt as faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-sms-crew",
  templateUrl: "./crew.component.html",
  styleUrls: ["./crew.component.scss"]
})
export class CrewComponent implements OnInit {
  @ViewChild("table", { static: true }) tableView: ElementRef;
  @ViewChild("mapViewNode", { static: true }) private mapViewEl: ElementRef;
  @Output() mapLoadedEvent = new EventEmitter<boolean>();
  @Output() closeform = new EventEmitter();
  dragPosition = { x: 0, y: 10 };
  deletetask = true;
  private zoom = 13;
  showtask = false;
  private center: number[] = [-118.250437, 34.171234];
  private basemap = "streets";
  currentUser;

  public map;
  // class variables
  faCalendar = faCalendar;
  faTimes = faTimes;
  public Query;
  public FeatureLayer;
  private GraphicsLayer;

  private BasemapGallery;

  public Graphic;

  public Point;
  private Draw;
  public webMercatorUtils;

  public scaleUtils;
  public request;
  public QueryTask;
  public Polyline;
  // other variables
  public TextSymbol;
  private basemapGallery;

  private satMapId: string;
  private streetMapId: string;
  // bool variables
  public showEsriWidgets = false;
  public showMeasure = false;
  public showBaseMapGallery = false;
  public showLayers = false;
  public showFilter = false;
  public streetBasemap = true; // default is street basemap
  public satelliteBasemap = false;
  public showSearch = false;
  public showSelect = false;
  public ProximityAssets = false;
  private SimpleFillSymbol;
  public rowdataobj;
  public showgrid;
  public showcard;
  public AttributeInspector;
  public all;
  public color;
  public SimpleLineSymbol;
  public SimpleMarkerSymbol;
  private PictureMarkerSymbol;
  public Color;
  private InfoTemplate;
  private drawTool;
  showroutebtn = false;
  eventsSubject: Subject<void>;
  graphicslayer;
  graphicsUtils;
  clientUsersList;
  todayDate = new Date();
  viewmicroform = false;
  selectedTasksList;
  scheduled;
  priority;
  worker;
  orange = "#FFA500";
  black = "#000000";
  blue = "#0048BA";
  green = "#0D591C";
  white = "#FFFFFF";

  acticePicturreMarker;
  routecomplete;

  editCreateTaskForm;
  orangePictureMarker;
  bluePictureMarker;
  blackPictureMarker;

  // Route related variables
  RouteParameters;
  FeatureSet;
  SpatialReference;
  units;
  urlUtils;
  connect;
  RouteTask;
  routeTask;
  routeParams;
  routeSymbols;
  routes = [];
  stopSymbol;
  routeTask1;
  routeParams1;
  selectedrowtasks = [];
  seqstops = [];

  async initializeMap() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const athis = this;
      const options = {
        version: "3.30",
        css: true,
        // insert the stylesheet link above the first <style> tag on the page
        insertCssBefore: "style",
        dojoConfig: {
          async: true,
          has: {
            "esri-featurelayer-webgl": 1
          },
          packages: [
            {
              name: "sms",
              location: location.pathname.replace(/\/[^/]+$/, "") + "assets/esri-widgets/"
            }
          ]
        }
      };
      // loadCss(options.version);
      // Load the modules for the ArcGIS API for JavaScript
      loadModules(
        [
          // esri modules
          "esri/map",
          "esri/graphic",
          "esri/request",
          "esri/layers/GraphicsLayer",
          // toolbars
          "esri/toolbars/draw",
          // tasks
          "esri/tasks/query",
          "esri/tasks/QueryTask",
          // layers
          "esri/layers/FeatureLayer",
          // dijit widgets
          "esri/dijit/BasemapGallery",
          "esri/dijit/Scalebar",
          "esri/dijit/AttributeInspector",

          // geometry
          "esri/geometry/Point",
          "esri/geometry/webMercatorUtils",
          "esri/geometry/scaleUtils",
          "esri/geometry/Polyline",

          // external widgets
          "esri/symbols/SimpleFillSymbol",
          // dojo
          "dojo/promise/all",
          "esri/symbols/SimpleLineSymbol",
          "esri/symbols/SimpleMarkerSymbol",
          "esri/symbols/PictureMarkerSymbol",
          "esri/Color",
          "esri/InfoTemplate",
          "esri/graphicsUtils",
          "esri/symbols/TextSymbol",

          // esri route
          "esri/tasks/RouteTask",
          "esri/tasks/RouteParameters",
          "esri/tasks/FeatureSet",
          "esri/SpatialReference",
          "esri/units",
          "esri/urlUtils",
          "dojo/_base/connect"
        ],
        options
      )
        .then(
          ([
            Map,
            Graphic,
            request,
            GraphicsLayer,
            Draw,
            Query,
            QueryTask,
            FeatureLayer,
            BasemapGallery,
            AttributeInspector,
            Point,
            webMercatorUtils,
            scaleUtils,
            Polyline,
            SimpleFillSymbol,
            all,
            SimpleLineSymbol,
            SimpleMarkerSymbol,
            PictureMarkerSymbol,
            Color,
            InfoTemplate,
            graphicsUtils,
            TextSymbol,
            RouteTask,
            RouteParameters,
            FeatureSet,
            SpatialReference,
            units,
            urlUtils,
            connect
          ]) => {
            athis.map = new Map(athis.mapViewEl.nativeElement, {
              center: athis.center,
              zoom: athis.zoom,
              basemap: athis.basemap,
              slider: false,
              showAttribution: false
            });
            athis.map.on("load", () => {
              this.mapLoadedEvent.emit(true);
              // set callbacks to class variables
              athis.Draw = Draw;
              athis.Query = Query;
              athis.FeatureLayer = FeatureLayer;

              athis.BasemapGallery = BasemapGallery;

              athis.Graphic = Graphic;

              athis.Point = Point;

              athis.webMercatorUtils = webMercatorUtils;

              athis.AttributeInspector = AttributeInspector;
              athis.SimpleFillSymbol = SimpleFillSymbol;
              athis.scaleUtils = scaleUtils;
              athis.request = request;
              athis.GraphicsLayer = GraphicsLayer;
              athis.QueryTask = QueryTask;
              athis.Polyline = Polyline;
              athis.all = all;

              athis.Color = Color;
              athis.SimpleLineSymbol = SimpleLineSymbol;
              athis.SimpleMarkerSymbol = SimpleMarkerSymbol;
              athis.PictureMarkerSymbol = PictureMarkerSymbol;
              athis.InfoTemplate = InfoTemplate; // Info template
              athis.graphicsUtils = graphicsUtils;
              athis.TextSymbol = TextSymbol;
              // route related
              athis.RouteTask = RouteTask;
              athis.RouteParameters = RouteParameters;
              athis.FeatureSet = FeatureSet;
              athis.SpatialReference = SpatialReference;
              athis.units = units;
              athis.urlUtils = urlUtils;
              athis.connect = connect;
              athis.initApp();
            });
          }
        )
        .catch((err) => {
          // handle any script or module loading errors
          console.error(err);
        });
    } catch (error) {
      console.log("EsriLoader: ", error);
    }
  }
  setEsriHeader(ioArgs) {
    if (ioArgs.url.includes("gisproxy")) {
      console.log(`--setting esri header in esri interceptor?`);

      // or, change some query parameters if necessary
      console.log(JSON.stringify(ioArgs.url));
      // don't forget to return ioArgs.
      ioArgs.headers = { ProxyAuthorization: `Bearer ${this.authenticationService.accessTokenObj.value.accessToken}` };
    }
    return ioArgs;
  }
  initApp() {
    try {
      this.initBasemap();
      this.addLayers();
      const width = 20;
      const height = 20;
      this.orangePictureMarker = new this.PictureMarkerSymbol("assets/images/circle_orange.png", width, height);
      this.blackPictureMarker = new this.PictureMarkerSymbol("assets/images/circle_black.png", width, height);
      this.bluePictureMarker = new this.PictureMarkerSymbol("assets/images/circle_blue.png", width, height);
      this.acticePicturreMarker = this.orangePictureMarker;
      this.request.setRequestPreCallback((ioArgs) => this.setEsriHeader(ioArgs));
      // route related code
      // Added proxy by Subba on 06/11/2020 for accessing ESRI route service

      this.routeTask = new this.RouteTask("https://route.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World");
      this.routeParams = new this.RouteParameters();
      this.routeParams.stops = new this.FeatureSet();
      this.routeParams.returnRoutes = true;
      this.routeParams.returnStops = true;
      this.routeParams.findBestSequence = true;
      this.routeParams.returnDirections = true;
      this.routeParams.directionsLengthUnits = this.units.MILES;
      this.routeParams.outSpatialReference = new this.SpatialReference({
        wkid: 102100
      });
      this.routeTask.on("solve-complete", this.showRoute.bind(this));
      this.routeTask.on("error", this.errorHandler);

      this.routeTask1 = new this.RouteTask("https://route.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World");
      this.routeParams1 = new this.RouteParameters();
      this.routeParams1.stops = new this.FeatureSet();
      this.routeParams1.returnRoutes = true;
      this.routeParams1.returnStops = true;
      this.routeTask1.on("solve-complete", this.showRoutesFull.bind(this));
      this.routeTask1.on("error", this.errorHandler);

      this.stopSymbol = new this.SimpleMarkerSymbol().setStyle(this.SimpleMarkerSymbol.STYLE_CROSS).setSize(15);
      this.stopSymbol.outline.setWidth(3);

      const barrierSymbol = new this.SimpleMarkerSymbol().setStyle(this.SimpleMarkerSymbol.STYLE_X).setSize(10);
      barrierSymbol.outline.setWidth(3).setColor(new this.Color([255, 0, 0]));

      this.routeSymbols = {
        Route1: new this.SimpleLineSymbol().setColor(new this.Color([0, 0, 255, 0.5])).setWidth(5),
        Route2: new this.SimpleLineSymbol().setColor(new this.Color([0, 255, 0, 0.5])).setWidth(5),
        Route3: new this.SimpleLineSymbol().setColor(new this.Color([255, 0, 255, 0.5])).setWidth(5)
      };
    } catch (error) {
      console.error(error);
    }
  }
  errorHandler(err) {
    alert("An error occured\n" + err.message + "\n" + err.details);
  }
  addLayers() {
    try {
      this.graphicslayer = new this.GraphicsLayer({
        id: "Crew Management"
      });

      this.graphicslayer.on(
        "click",
        function (evt) {
          if (evt.graphic.attributes) {
            this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", evt.graphic.attributes.MaintID).subscribe((results: any) => {
              if (results && results.features && results.features[0]) {
                this.showtask = true;
                evt.graphic.attributes.Activity = results.features[0].attributes.Activity;
                this.objGlobals.userSelectedActivity = results.features[0].attributes.Activity;
                this.objGlobals.selectedTask = evt.graphic.attributes;
              }
            });
          }
        }.bind(this)
      );
      this.map.addLayer(this.graphicslayer);
    } catch (error) {
      console.error(error);
    }
  }
  showTaksTab(evt) {
    this.showtask = evt;
  }
  setThis() {
    return this;
  }
  initBasemap() {
    try {
      // this.showBaseMapGallery = 'block';
      const athis = this.setThis();

      if (!this.basemapGallery) {
        this.basemapGallery = new this.BasemapGallery(
          {
            showArcGISBasemaps: true,
            map: athis.map
          },
          "basemapGalleryDiv1"
        );
        this.basemapGallery.startup();

        this.basemapGallery.on("error", (msg) => {
          console.log("basemap gallery error:  ", msg);
        });
        this.basemapGallery.on("selection-change", (o) => {
          if (o.target.getSelected().id === athis.satMapId) {
            athis.streetBasemap = false;
            athis.satelliteBasemap = true;
          } else if (o.target.getSelected().id === athis.streetMapId) {
            athis.streetBasemap = true;
            athis.satelliteBasemap = false;
          }
          //  athis.mapTools.switchMapInsetOnBaseMapChange(basemap);
        });
        this.basemapGallery.on("load", (o) => {
          // getting basemapid for sat & street
          o.target.basemaps.forEach((b) => {
            if (b.title === "Imagery") {
              athis.satMapId = b.id;
            }

            if (b.title === "Streets Map") {
              athis.streetMapId = b.id;
            }
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  constructor(public objGlobals: Globals, private authenticationService: AuthenticationService, private genericLayerService: GenericLayerService, private crewManagementService: CrewManagementService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.eventsSubject = new Subject<void>();
    this.crewManagementService.tasksModified.subscribe(() => {
      this.graphicslayer.clear();
    });
  }
  changePosition() {
    // this.dragPosition = {x: this.dragPosition.x + 50, y: this.dragPosition.y + 50};
  }
  ngOnInit() {
    this.editCreateTaskForm = {
      // eslint-disable-next-line camelcase
      workorder_id: false,
      wrkType: false,
      wrkStatus: false,
      createdBy: false,
      dateToday: false,
      priority: true,
      region: false,
      worker: true,
      location: false,
      reason: false,
      activity: false,
      duration: true,
      sheduledTime: true,
      startTime: false,
      endTime: false,
      assetId: false,
      desc: false,
      comments: false
    };

    this.initializeMap().then(() => {
      //console.log("Map Initialized");
    });

    // this.createTable()
  }
  close() {
    //  document.getElementById("crewForm").style.display = "none";
    this.closeform.emit(false);
  }
  loadUserListForMicroForm(region) {
    try {
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe((results: any) => {
        this.clientUsersList = [];
        const reg = region;

        const clientUsersListl = [];

        console.log(results);
        results.filter((user) => {
          user.regions.forEach((uregion: any) => {
            if (uregion.accessPrivilege === "Data Editor") {
              if (uregion.regionLevel === 4) {
                if (uregion.regionName === reg) {
                  clientUsersListl.push(user);
                  return user;
                }
              }

              if (uregion.regionLevel === 3) {
                if (uregion.regionName === reg) {
                  clientUsersListl.push(user);
                  return user;
                }
              }

              if (uregion.regionLevel === 2) {
                if (uregion.regionName === reg) {
                  clientUsersListl.push(user);
                  return user;
                }
              }
            }
          });
        });
        this.worker = null;
        this.clientUsersList = clientUsersListl.filter(this.onlyUnique);
        this.worker = null;
      });
    } catch (error) {
      console.error(error);
    }
  }
  closeMicroform() {
    try {
      this.viewmicroform = false;
      this.setGraphicsToBack();
    } catch (error) {
      console.error(error);
    }
  }
  saveTask() {
    try {
      let sheduledTime = null;

      if (this.scheduled.getTime()) {
        sheduledTime = this.scheduled.getTime();
      }

      const ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
      const featurese = [];
      this.selectedTasksList.forEach(
        function (task) {
          if (task.attributes) {
            const feature = new this.Graphic();
            task.attributes.Worker = this.worker;
            task.attributes.Priority = this.priority;
            task.attributes.SheduledDate = sheduledTime;
            feature.setAttributes(task.attributes);
            featurese.push(feature);
          }
        }.bind(this)
      );

      ftrLyrTbl.applyEdits(null, featurese, null).then(
        function () {
          this.crewManagementService.getRecords();
          this.crewManagementService.tasksModified.emit();
        }.bind(this)
      );
      this.worker = null;
      this.priority = null;
      this.scheduled = null;
      this.viewmicroform = false;
      this.setGraphicsToBack();
    } catch (error) {
      console.error(error);
    }
  }
  onTabChange(evt) {
    try {
      console.log(evt);

      if (evt.tab.textLabel === "Assignment") {
        this.acticePicturreMarker = this.orangePictureMarker;
        this.showroutebtn = false;
      }

      if (evt.tab.textLabel === "Scheduler") {
        this.acticePicturreMarker = this.bluePictureMarker;
        this.showroutebtn = false;
      }

      if (evt.tab.textLabel === "Optimizer") {
        this.acticePicturreMarker = this.orangePictureMarker;
        this.showroutebtn = true;
      }

      if (this.graphicslayer) {
        this.graphicslayer.clear();
      }
    } catch (error) {
      console.error(error);
    }
  }

  enableDraw() {
    try {
      this.viewmicroform = false;
      this.selectedTasksList = [];
      // this.showGrid.emit(false);
      // this.showCard.emit(false);

      this.map.disableMapNavigation();
      this.drawTool = new this.Draw(this.map);
      this.drawTool.activate("extent");

      this.drawTool.component = "select";

      const drawFillSymbol = new this.SimpleFillSymbol(this.SimpleFillSymbol.STYLE_SOLID, new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([255, 0, 0]), 2), new this.Color([0, 0, 0, 0.25]));
      this.drawTool.setFillSymbol(drawFillSymbol);

      const handler = this.drawTool.on("draw-end", async (evt) => {
        handler.remove();
        this.deactivateTool(this.drawTool);
        this.map.enableMapNavigation();
        console.log("draw ended");

        const query = new this.Query();
        query.geometry = evt.geometry;
        const g = this.graphicslayer.graphics;

        for (let loopCnt = 0; loopCnt < g.length; loopCnt++) {
          if (evt.geometry.contains(this.graphicslayer.graphics[loopCnt].geometry)) {
            // do whatever you want
            console.log(this.graphicslayer.graphics[loopCnt]);

            if (this.graphicslayer.graphics[loopCnt].attributes) {
              this.graphicslayer.graphics[loopCnt].setSymbol(this.blackPictureMarker);
              this.getActivity(this.graphicslayer.graphics[loopCnt], this.black);
              this.selectedTasksList.push(this.graphicslayer.graphics[loopCnt]);
            }
          }
        }
        //  this.graphicslayer.selectFeatures(query).then(function(sel_tasks)
        //  {
        //    console.log(sel_tasks);
        //  });

        if (this.selectedTasksList.length > 0) {
          this.loadUserListForMicroForm(this.selectedTasksList[0].attributes.Region);
          this.viewmicroform = true;
          this.worker = null;
          this.priority = null;
          this.scheduled = null;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  setGraphicsToBack() {
    try {
      const g = this.graphicslayer.graphics;

      for (let loopCnt = 0; loopCnt < g.length; loopCnt++) {
        // do whatever you want
        console.log(this.graphicslayer.graphics[loopCnt]);

        if (this.graphicslayer.graphics[loopCnt].attributes) {
          this.graphicslayer.graphics[loopCnt].setSymbol(this.acticePicturreMarker);
          this.getActivity(this.graphicslayer.graphics[loopCnt], this.orange);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  deactivateTool(tool: any) {
    try {
      if (tool) {
        tool.editingEnabled = false;
        tool.deactivate();
        tool = null;
      }
      // this.isDrawPolygonEnabled = false;
    } catch (error) {
      console.error(error);
    }
  }

  calWidthclass(duration) {
    try {
      let cssClass;

      if (duration === 1) {
        cssClass = "calWidthtask1hr";
      } else if (duration === 2) {
        cssClass = "calWidthtask2hr";
      } else if (duration === 3) {
        cssClass = "calWidthtask3hr";
      }

      return cssClass;
    } catch (error) {
      console.error(error);
    }
  }

  loadFeaturesOnMap(emmitedrow) {
    try {
      this.selectedrowtasks = [];
      this.routecomplete = false;
      this.graphicslayer.clear();
      this.routeParams.stops.features = [];
      // this.genericLayerService
      //   .getAllFeatures(this.currentUser.serviceLyrInfo.maintenanceId)
      //   .subscribe((results: any) => {
      const att = [];
      const infoTemplate = new this.InfoTemplate();
      infoTemplate.setContent("<b>AssetID </b>${AssetID}<br/>" + "<b>Status </b>${Status}<br/><br/>");
      emmitedrow.tasks.forEach((data) => {
        if (data.GeomWKT !== null && data.Region === emmitedrow.region && data.AssetName === emmitedrow.assetclass) {
          if (emmitedrow.user) {
            if (emmitedrow.date === new Date(data.SheduledDate).toLocaleDateString()) {
              const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.orangePictureMarker, data);
              this.selectedrowtasks.push(data);
              this.graphicslayer.add(ftr);
              // adding selected task to map stops

              if (data.Priority === 2) {
                this.routeParams.stops.features.push(
                  new this.Graphic(
                    new this.Point(JSON.parse(data.GeomWKT)),
                    this.stopSymbol,

                    { Name: data.OBJECTID, RouteName: "Route1" }
                  )
                );
              }

              if (data.Priority === 1) {
                this.routeParams.stops.features.push(
                  new this.Graphic(
                    new this.Point(JSON.parse(data.GeomWKT)),
                    this.stopSymbol,

                    { Name: data.OBJECTID, RouteName: "Route2" }
                  )
                );
              }

              if (data.Priority === 0) {
                this.routeParams.stops.features.push(
                  new this.Graphic(
                    new this.Point(JSON.parse(data.GeomWKT)),
                    this.stopSymbol,

                    { Name: data.OBJECTID, RouteName: "Route3" }
                  )
                );
              }

              this.getActivity(data, this.orange);
              att.push(ftr);
            }
          } else if (emmitedrow.schedule) {
            switch (emmitedrow.schedule) {
              case "Unscheduled": {
                if ((data.SheduledDate == null || data.SheduledDate === 0) && data.Status === "Open") {
                  const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.bluePictureMarker, data);
                  this.graphicslayer.add(ftr);
                  this.getActivity(data, this.blue);
                  att.push(ftr);
                }

                break;
              }

              case "Today": {
                const date = new Date();
                const dateToday = date.toLocaleDateString();

                if (dateToday === new Date(data.SheduledDate).toLocaleDateString() && data.Status === "Open") {
                  const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.bluePictureMarker, data);
                  this.graphicslayer.add(ftr);
                  this.getActivity(data, this.blue);
                  att.push(ftr);
                }

                break;
              }

              case "Tomorrow": {
                const date = new Date();
                const tomorrow = new Date(date);
                tomorrow.setDate(tomorrow.getDate() + 1);

                const dateToday = tomorrow.toLocaleDateString();

                if (dateToday === new Date(data.SheduledDate).toLocaleDateString() && data.Status === "Open") {
                  const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.bluePictureMarker, data);
                  this.graphicslayer.add(ftr);
                  this.getActivity(data, this.blue);
                  att.push(ftr);
                }

                break;
              }

              case "Later": {
                const date = new Date();
                const tomorrow = new Date(date);
                tomorrow.setDate(tomorrow.getDate() + 1);

                const dateToday = tomorrow.toLocaleDateString();

                if (new Date(data.SheduledDate).toLocaleDateString() > dateToday && data.Status === "Open") {
                  const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.bluePictureMarker, data);
                  this.graphicslayer.add(ftr);
                  this.getActivity(data, this.blue);
                  att.push(ftr);
                }

                break;
              }

              case "Past Due": {
                const date = new Date();
                const dateToday = date.toLocaleDateString();

                if (new Date(data.SheduledDate).toLocaleDateString() < dateToday && data.Status === "Open") {
                  const ftr = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), this.bluePictureMarker, data);
                  this.graphicslayer.add(ftr);
                  this.getActivity(data, this.blue);
                  att.push(ftr);
                }

                break;
              }
            }
          }
        }
      });
      console.log(att);

      if (att.length > 0) {
        const extGraphics = this.graphicsUtils.graphicsExtent(att);
        this.map.setExtent(extGraphics, true);
      }
      // });
    } catch (error) {
      console.error(error);
    }
  }
  getActivity(data, color) {
    try {
      const assetId = data.OBJECTID;

      if (this.showroutebtn) {
        const textSymbolv = {
          type: "text",
          color: { r: 250, g: 87, b: 52 },
          haloColor: "black",
          haloSize: "1px",
          font: { size: 12, family: "Josefin Slab", weight: "bold" }
        };
        const txtSymbol = new this.TextSymbol(textSymbolv);
        txtSymbol.text = assetId;
        txtSymbol.yoffset = Math.floor(Math.random() * 5) * 15 + 5;
        txtSymbol.xoffset = 25;
        //  txtSymbol.AngleAlignment  = (Math.floor(Math.random() * 10) * 36);
        txtSymbol.setColor(color);

        const ftr1 = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), txtSymbol, data);
        this.graphicslayer.add(ftr1);
      } else {
        this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", data.MaintID).subscribe(
          function (results: any) {
            if (results && results.features[0]) {
              const textSymbolv = {
                type: "text",
                color: { r: 250, g: 87, b: 52 },
                haloColor: "black",
                haloSize: "1px",
                font: { size: 12, family: "Josefin Slab", weight: "bold" }
              };
              const txtSymbol = new this.TextSymbol(textSymbolv);
              txtSymbol.text = results.features[0].attributes.Activity;
              txtSymbol.yoffset = Math.floor(Math.random() * 5) * 13 + 10;
              txtSymbol.xoffset = 25;
              // txtSymbol.AngleAlignment  = (Math.floor(Math.random() * 5) * 36);
              txtSymbol.setColor(color);

              const ftr1 = new this.Graphic(new this.Point(JSON.parse(data.GeomWKT)), txtSymbol, data);
              this.graphicslayer.add(ftr1);
            }
          }.bind(this)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  taskDeleted(evt) {
    try {
      console.log(evt);
      this.eventsSubject.next();
      this.graphicslayer.clear();
    } catch (error) {
      console.error(error);
    }
  }
  // route related code
  findRoute() {
    this.solveRoute();
  }

  addStopRoutes() {
    this.map.on("click", this.addStop.bind(this));
  }
  addStop(evt) {
    this.routeParams.stops.features.push(this.map.graphics.add(new this.Graphic(evt.mapPoint, this.stopSymbol, { RouteName: "Route1" })));
  }
  solveRoute() {
    try {
      console.log("route");

      if (this.routeParams.stops.features.length > 1) {
        this.routeTask.solve(this.routeParams);
      }
    } catch (error) {
      console.error(error);
    }
  }
  showRoute(evt) {
    try {
      // clearRoutes();
      evt.result.routeResults.sort((a, b) => (a.routeName > b.routeName ? 1 : b.routeName > a.routeName ? -1 : 0));
      this.routeParams1.stops.features = [];

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < evt.result.routeResults.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < evt.result.routeResults[i].stops.length; j++) {
          const ftr1 = new this.Graphic(evt.result.routeResults[i].stops[j].geometry, this.stopSymbol, {
            Name: evt.result.routeResults[i].stops[j].attributes.Name,
            RouteName: "Route1"
          });
          this.routeParams1.stops.features.push(ftr1);
        }
      }

      if (this.routeParams1.stops.features.length > 1) {
        console.log("Step 1 done");
      }

      this.routeTask1.solve(this.routeParams1);
    } catch (error) {
      console.error(error);
    }
  }

  showRoutesFull(evt) {
    try {
      this.seqstops = [];
      let Sequence = 0;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < evt.result.routeResults.length; i++) {
        this.routes.push(this.graphicslayer.add(evt.result.routeResults[i].route.setSymbol(this.routeSymbols[evt.result.routeResults[i].routeName])));
        this.seqstops.push(evt.result.routeResults[i].stops);

        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < evt.result.routeResults[i].stops.length; j++) {
          Sequence = Sequence + 1;

          const textSymbolv = {
            type: "text",
            color: { r: 250, g: 87, b: 52 },
            haloColor: "black",
            haloSize: "1px",
            font: { size: 18, family: "Josefin Slab", weight: "bold" }
          };
          const txtSymbol = new this.TextSymbol(textSymbolv);
          //  txtSymbol.text = evt.result.routeResults[i].stops[j].attributes.Sequence;
          txtSymbol.text = Sequence;
          txtSymbol.setColor(this.white);
          txtSymbol.yoffset = -5;
          txtSymbol.xoffset = -30;
          const ftr1 = new this.Graphic(evt.result.routeResults[i].stops[j].geometry, txtSymbol);

          // eslint-disable-next-line no-irregular-whitespace
          //  var circle = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_CIRCLE ,
          // eslint-disable-next-line no-irregular-whitespace
          //       new this.Color([255, 0, 0]), 3);

          const circle = new this.SimpleMarkerSymbol(
            this.SimpleMarkerSymbol.STYLE_CIRCLE,
            25,
            new this.SimpleLineSymbol().setStyle(this.SimpleLineSymbol.STYLE_SOLID).setColor(new this.Color([100, 100, 100])),
            new this.Color([0, 0, 0, 0])
          );
          circle.yoffset = 5;
          circle.xoffset = -30;
          circle.setColor(this.green);
          const ftr2 = new this.Graphic(evt.result.routeResults[i].stops[j].geometry, circle);
          this.graphicslayer.add(ftr2);
          this.graphicslayer.add(ftr1);
        }
      }

      const msgs = ["Server messages:"];
      evt.result.messages.forEach((message) => {
        msgs.push(message.type + " : " + message.description);
      });

      if (msgs.length > 1) {
        alert(msgs.join("\n - "));
      }

      this.routecomplete = true;
    } catch (error) {
      console.error(error);
    }
  }
  updateSequence() {
    try {
      Swal.fire({
        title: "Update Task Sequence",
        text: "Would you like to apply the suggested task sequence?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.value) {
          console.log(this.seqstops);
          console.log(this.selectedrowtasks);

          this.routecomplete = false;
          let duration = 0;
          const featurese = [];
          const hours = 7; // tslint:disable-next-line:prefer-for-of

          for (let ind = 0; ind < this.seqstops[0].length; ind++) {
            const attributes: any = this.selectedrowtasks.filter(
              function (element) {
                if (element.OBJECTID === parseInt(this.seqstops[0][ind].attributes.Name, 10)) {
                  return element;
                }
              }.bind(this)
            );
            // eslint-disable-next-line camelcase
            attributes[0].Task_Sequence = this.seqstops[0][ind].attributes.Sequence;

            const calSheduledDate: any = new Date(attributes[0].SheduledDate);
            calSheduledDate.setHours(hours + (duration - (duration % 60)) / 60);
            calSheduledDate.setMinutes(duration % 60);

            attributes[0].SheduledDate = calSheduledDate.getTime();
            const features = new this.Graphic();
            features.setAttributes(attributes[0]);
            featurese.push(features);

            duration += parseInt(attributes[0].EstimatedDuration, 10);
          }

          // let features = new this.Graphic();
          // let sheduledTime = null;
          // if (this.scheduled.getTime()) {
          //   sheduledTime = this.scheduled.getTime()
          // }
          // features.setAttributes({ });
          // featurese.push(features)

          const ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
          ftrLyrTbl
            .applyEdits(null, featurese, null)
            .then(
              function () {
                this.crewManagementService.getRecords();
                this.crewManagementService.tasksModified.emit();
              }.bind(this)
            )
            .catch((error) => {
              console.log("===============================================");
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  dateTimeChanged() {
    try {
      if (this.scheduled.getHours() > 18) {
        const today = new Date();

        if (this.scheduled.getDate() === today.getDate() && this.scheduled.getMonth() === today.getMonth() && this.scheduled.getFullYear() === today.getFullYear()) {
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          tomorrow.setHours(7, 0, 0);
          this.scheduled = tomorrow;
        } else {
          const dtime = new Date(this.scheduled.setHours(7, 0, 0));
          this.scheduled = dtime;
        }
      }

      if (this.scheduled.getHours() < 7) {
        const dtime = new Date(this.scheduled.setHours(7, 0, 0));
        this.scheduled = dtime;
      }

      if (this.scheduled && this.worker) {
        this.checkUserLoad(this.scheduled);
      }
    } catch (error) {
      console.error(error);
    }
  }
  workerChanged() {
    try {
      if (this.scheduled) {
        console.log("worker changed");
        this.checkUserLoad(this.scheduled);
      }
    } catch (error) {
      console.error(error);
    }
  }
  checkUserLoad(date: Date) {
    try {
      const SheduledDate = date.toLocaleDateString();
      const tomorrow = new Date(date);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const SheduledNextDate = tomorrow.toLocaleDateString();
      let duration = 0;

      // tslint:disable-next-line:prefer-for-of
      for (let ind = 0; ind < this.selectedTasksList.length; ind++) {
        duration += this.selectedTasksList[ind].attributes.EstimatedDuration;
        console.log(this.selectedTasksList[ind]);
      }

      const strCond = "SheduledDate >'" + SheduledDate + "' and SheduledDate <'" + SheduledNextDate + "'  and Worker";
      this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, strCond, this.worker).subscribe(
        function (results: any) {
          // this.activity = results.features[0].attributes.Activity;
          console.log(results.features);
          let totalDuration = 0;
          results.features.forEach((element) => {
            totalDuration += element.attributes.EstimatedDuration;
          });
          console.log(totalDuration);

          if (totalDuration + duration > 480) {
            this.showUserOverLoadedMsg();
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  showUserOverLoadedMsg() {
    try {
      Swal.fire("Selected User is Over Loaded! Please Select Another User");
      this.worker = null;
    } catch (error) {
      console.error(error);
    }
  }
}
