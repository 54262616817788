import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DropdownListItem } from 'src/app/interface/DropDownListItem';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})


export class DropdownComponent implements OnInit {

  @Input() label : string;
  @Input() dropdownList : DropdownListItem[];
  @Input() val : string;
  @Input() tooltip : string;
  @Input() isWorkerUsername = false;
  @Input() isManageWorkOrderReason = false;
  @Input() isReason = false;
  @Output() select = new EventEmitter();
  @Input() isAssessment : boolean = false;
  @Input() isRequired : boolean = false;
  @Input() isQueryReport : boolean = false;
  @Input() isQueryReportFormat : boolean = false;
  @Input() isJoinType : boolean = false;
  @Input() disableJoinTypeDropdown : boolean = false;
  @Input() isCrewManagement = false;
  @Input() isRegion = false;
  @Input() disableActivityDropdown = false;
  

  ngOnInit(){
    console.log(this.dropdownList);
  }
  selectItem(value) {
    console.log(this.dropdownList);
    this.select.emit(value);
  }
}
