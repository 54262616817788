<div class="bound">
  <div class="modal-content formu" cdkDragBoundary=".querybound" cdkDrag>
    <div cdkDragHandle>
      <app-heading-component [headingText]="'Helpcenter.solutionadmin.title' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="closeHelp()"> </app-heading-component>
    </div>
    <div id="help_tab" class="modal-body p-0 help show formbg-d2">
      <mat-tab-group (selectedTabChange)="viewMode ? completedTab() : pendingTab()">
        <mat-tab (click)="pendingTab()" style="width: 50%" label="{{ 'Helpcenter.solutionadmin.pendingtab' | translate }}">
          <div class="tab-content">
            <div class="card">
              <div class="card-body">
                <div class="input-group-prepend">
                  <div class="search-container">
                    <mat-form-field style="min-width: 240px; width: 45%" appearance="outline">
                      <mat-label class="col-6">{{ "Helpcenter.user.search" | translate }}</mat-label>
                      <input matInput type="text" [(ngModel)]="searchOpenTab" (keyup)="searchOpenTabFilter()" placeholder="{{ 'Helpcenter.user.search' | translate }}" aria-label="Search" />
                      <button mat-button matPrefix mat-icon-button aria-label="search">
                        <mat-icon svgIcon="search"></mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <ag-grid-angular
                  style="width: 100%; height: 300px"
                  class="ag-theme-balham"
                  [rowData]="rowDataOpen"
                  [columnDefs]="columnDefs"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [frameworkComponents]="frameworkComponents"
                  [gridOptions]="gridOptions"
                  (gridReady)="onGridReadyOpenTab($event)"
                  [suppressMovableColumns]="true"
                  [rowSelection]="rowSelection"
                  [overlayNoRowsTemplate]="noRowsTemplate"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab style="width: 50%" (click)="completedTab()" label="{{ 'Helpcenter.solutionadmin.completedtab' | translate }}">
          <div class="tab-content">
            <div class="card">
              <div class="card-body">
                <div class="input-group-prepend">
                  <div class="search-container">
                    <mat-form-field style="min-width: 240px; width: 45%" appearance="outline">
                      <mat-label>{{ "Helpcenter.user.search" | translate }}</mat-label>
                      <input matInput [(ngModel)]="searchClosedTab" (keyup)="searchCloseTabFilter()" placeholder="{{ 'Helpcenter.user.search' | translate }}" aria-label="Search" />
                      <button matPrefix mat-icon-button aria-label="search">
                        <mat-icon svgIcon="search"></mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </div>
                <ag-grid-angular
                  style="width: 100%; height: 300px"
                  class="ag-theme-balham"
                  [rowData]="rowDataClosed"
                  [columnDefs]="columnDefs"
                  [pagination]="true"
                  [paginationPageSize]="10"
                  [frameworkComponents]="frameworkComponents"
                  [suppressMovableColumns]="true"
                  (gridReady)="onGridReadyClosedTab($event)"
                  [rowSelection]="rowSelection"
                  [overlayNoRowsTemplate]="noRowsTemplate"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<ng-template #queryForm let-modal>
  <div class="modal-content">
    <app-heading-component
      [headingText]="viewMode === true ? ('Helpcenter.solutionadmin.editticket' | translate) : ('Helpcenter.solutionadmin.ticketinfo' | translate)"
      [variant]="'none'"
      [iconOne]="'cross'"
      [tooltipOne]="'Close'"
      (actionOne)="modal.dismiss()"
    >
    </app-heading-component>
    <div class="modal-body formbg-d2" style="display: flex; flex-direction: column; margin: 31px">
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.solutionadmin.tokenno" | translate }}</mat-label>
          <input matInput readonly [value]="tokenNo" />
        </mat-form-field>
      </div>
      <br />
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.solutionadmin.subject" | translate }}</mat-label>
          <input #box matInput maxlength="60" [(ngModel)]="subject" readonly />
        </mat-form-field>
      </div>
      <br />
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.solutionadmin.description" | translate }}</mat-label>
          <textarea #box1 matInput rows="4" maxlength="300" [(ngModel)]="description" readonly></textarea>
        </mat-form-field>
        <div *ngIf="url" style="text-align: right">
          <button style="width: auto" *ngIf="url" (click)="showAttachement(DocViewer)" mat-button matPrefix mat-icon-button>
            <mat-icon svgIcon="download"></mat-icon>
            {{ "Helpcenter.solutionadmin.view" | translate }}
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <mat-form-field class="ticket-form-input" appearance="outline">
          <mat-label>{{ "Helpcenter.solutionadmin.solution" | translate }}</mat-label>
          <textarea #box2 matInput rows="4" maxlength="300" [(ngModel)]="solution" [readonly]="!viewMode"></textarea>
          <span *ngIf="viewMode" style="color: red">{{ 300 - box2.value.length + " characters remaining" }}</span>
        </mat-form-field>
      </div>
    </div>

    <div class="modal-footer formbg-d2" *ngIf="viewMode">
      <button matButton type="button" class="primary-button" (click)="onSubmit()">{{ "Helpcenter.solutionadmin.savebtn" | translate }}</button>
      <button matButton type="button" class="inverse-button" (click)="onRowDelete()">{{ "Helpcenter.solutionadmin.deletebtn" | translate }}</button>
      <button matButton type="button" class="inverse-button" (click)="reset()">{{ "Helpcenter.solutionadmin.clearbtn" | translate }}</button>
    </div>
    <div class="modal-footer formbg-d2" *ngIf="!viewMode">
      <div class="col-md-12" style="text-align: right">
        <button matButton type="button" class="primary-button" (click)="onRowDelete()">{{ "Helpcenter.solutionadmin.deletebtn" | translate }}</button>
      </div>
    </div>
    <div class="footer formbg-d2" *ngIf="!viewMode">
      <div class="col-md-5" style="font-weight: bold">{{ "Helpcenter.user.modifiedby" | translate }}:{{ modifiedBy }}</div>
      <div class="col-md-7" style="font-weight: bold; text-align: right">{{ "Helpcenter.user.modifiedate" | translate }}:{{ modifiedDate }}</div>
    </div>
  </div>
</ng-template>
<ng-template #DocViewer let-modal let-d="dismiss">
  <app-heading-component [headingText]="'Helpcenter.solutionadmin.photoviewer' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="d('Cross click')"></app-heading-component>
  <div class="modal-body sms-model-imageviewer">
    <img *ngIf="imageSrc !== ''" [src]="imageSrc" />
  </div>
</ng-template>
