<div class="card-body">
  <div class="card-body">
    <mat-form-field class="form-element" style="margin-right: 16px">
      <mat-label> {{ "Systemreports.summaryofcondition.regionname" | translate }}: </mat-label>
      <mat-select (selectionChange)="regionListSelected($event)" [(ngModel)]="regionSelected">
        <mat-option [value]="region" *ngFor="let region of regionsListByClient">
          {{ region.regionName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="form-element" style="margin-right: 16px">
      <mat-label> {{ "Userscreens.useradmin.assetclasslabel" | translate }}: </mat-label>
      <mat-select (selectionChange)="onAssetClassChange()" [(ngModel)]="selectedLayer">
        <mat-option [value]="layer" *ngFor="let layer of layerList">
          {{ layer.gisLayerName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <label> {{ "Systemreports.headerassetdetail.assetcode" | translate }}: </label>
      <ng-multiselect-dropdown
        aria-labelledby="ccs_AssetDetLabelID"
        [data]="dropdownList"
        [settings]="dropdownSettings"
        (onSelect)="onItemSelect($event)"
        [(ngModel)]="selectedItems"
        (onSelectAll)="onSelectAll($event)"
        (onDeSelect)="onItemDeSelect($event)"
        (onDeSelectAll)="onDeSelectAll($event)"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
</div>
<div class="container">
  <div class="card-footer text-right">
    <div>
      <select aria-label="Choose File Type" class="select-control" [(ngModel)]="format">
        <option value="CSV">CSV</option>
        <option value="PDF">PDF</option></select
      >&nbsp; &nbsp;
      <button type="button" class="btn btn-primary" [attr.aria-label]="'' + 'Systemreports.btn.generatereport' | translate" (click)="exportFormat()">{{ "Systemreports.btn.generatereport" | translate }}</button>
    </div>
  </div>
</div>
