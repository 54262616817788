import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from "@angular/core";
import { DownloadService } from "../services/download.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitersService } from "../services/eventEmiters.service";
@Component({
  selector: "sms-redline",
  templateUrl: "./redline.component.html",
  styleUrls: ["./redline.component.scss"]
})
export class RedlineComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() map: any;
  @Input() drawTool: any;
  @Input() ColorPicker: any;
  @Input() SimpleFillSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() Color: any;
  @Input() Graphic: any;
  @Input() GraphicsLayer: any;

  public drawToolType = "polygon";
  public lineWidth = 4; // Set Default LineWidth to 4
  public fillSymbol;
  public lineSymbol;
  public markerSymbol;
  public colorPicker;
  public defaultFillSymbol;
  public defaultLineSymbol;
  public defaultMarkerSymbol;
  public eventHandler;
  public redlineGraphicsLyr;

  constructor(private renderer: Renderer2, private downloader: DownloadService, private translateService: TranslateService, private eventEmitersService: EventEmitersService) {}

  ngAfterViewInit() {
    if (!this.colorPicker) {
      const matCard = this.renderer.createElement("div");
      this.renderer.setProperty(matCard, "id", "widgetColorPicker");
      const container = document.getElementById("divColorPicker1");
      this.renderer.appendChild(container, matCard);
      this.colorPicker = new this.ColorPicker(
        {
          collapsed: true,
          collapsible: true,
          color: new this.Color([255, 0, 0, 0.25]),
          showTransparencySlider: true
        },
        "widgetColorPicker"
      );
      this.colorPicker.startup();
    }
  }
  ngOnInit() {
    this.redlineGraphicsLyr = this.map.getLayer("redline-graphics-lyr");

    if (!this.redlineGraphicsLyr) {
      this.redlineGraphicsLyr = new this.GraphicsLayer({ id: "redline-graphics-lyr" });
      this.map.addLayer(this.redlineGraphicsLyr);
    }
  }

  endSketch() {
    if (this.drawTool) {
      this.drawTool.deactivate();
    }
  }
  startSketch() {
    try {
      // this.drawTool = new this.Draw(this.map);
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(false);
      this.map.disableMapNavigation();
      this.defaultFillSymbol = this.drawTool.fillSymbol;
      this.defaultLineSymbol = this.drawTool.lineSymbol;
      this.defaultMarkerSymbol = this.drawTool.markerSymbol;
      const selectedColor = this.colorPicker.color;
      this.lineSymbol = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, selectedColor, this.lineWidth);
      this.fillSymbol = new this.SimpleFillSymbol(this.SimpleFillSymbol.STYLE_SOLID, this.lineSymbol, selectedColor);
      this.markerSymbol = new this.SimpleMarkerSymbol(this.SimpleMarkerSymbol.STYLE_SQUARE, 10, this.lineSymbol, selectedColor);
      this.drawTool.setFillSymbol(this.fillSymbol);
      this.drawTool.setLineSymbol(this.lineSymbol);
      this.drawTool.setMarkerSymbol(this.markerSymbol);
      this.drawTool.activate(this.drawToolType);
      this.drawTool.component = "redline";
      this.eventHandler = this.drawTool.on("draw-end", this.getSelectResults.bind(this));
    } catch (error) {
      console.error(error);
    }
  }
  // handleChangeComplete(event: ColorEvent) {
  //   this.selectedColor = new this.Color([event.color.rgb.r,event.color.rgb.g,event.color.rgb.b,event.color.rgb.a]);
  //   this.selectedColorhex = event.color.hex;
  // }
  getSelectResults(evt) {
    try {
      this.deactivateTool();
      let symbol;

      if (evt.geometry.type === "point" || evt.geometry.type === "multipoint") {
        symbol = this.markerSymbol;
      } else if (evt.geometry.type === "line" || evt.geometry.type === "polyline") {
        symbol = this.lineSymbol;
      } else {
        symbol = this.fillSymbol;
      }

      this.redlineGraphicsLyr.add(new this.Graphic(evt.geometry, symbol));
    } catch (error) {
      console.error(error);
    }
  }

  deactivateTool() {
    try {
      if (this.eventHandler) {
        this.eventHandler.remove();
      }

      if (this.drawTool) {
        this.drawTool.deactivate();
        this.drawTool.fillSymbol = this.defaultFillSymbol;
        this.drawTool.lineSymbol = this.defaultLineSymbol;
        this.drawTool.markerSymbol = this.defaultMarkerSymbol;
      }

      this.map.enableMapNavigation();
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    try {
      this.redlineGraphicsLyr.clear();
      this.deactivateTool();
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }
  setThis() {
    return this;
  }
  loadRedlines(files) {
    try {
      const _this = this.setThis();

      if (files.length > 0) {
        const f = files[0];
        const reader = new FileReader();
        reader.onload = (function () {
          return function (e:any) {
            _this.loadRedlineData(e.currentTarget.result);
          };
        })();
        // Read in the image file as a data URL.
        reader.readAsText(f);
      }
    } catch (error) {
      console.error(error);
    }
  }

  loadRedlineData(jsonStr) {
    let json;

    try {
      json = JSON.parse(jsonStr);
    } catch (e) {
      Swal.fire(this.translateService.instant("Redline.alert.uploaderror"));
      return false;
    }

    const _this = this.setThis();
    json.forEach((element) => {
      _this.redlineGraphicsLyr.add(new this.Graphic(element));
    });
  }

  saveRedlines() {
    try {
      const mapGraphics = this.redlineGraphicsLyr.graphics;
      const jsonGraphics = [];
      mapGraphics.forEach((element) => {
        jsonGraphics.push(element.toJson());
      });

      if (jsonGraphics.length > 0) {
        const currentdatetime = new Date();
        const formatteddate =
          currentdatetime.getFullYear() +
          "_" +
          (currentdatetime.getMonth() + 1) +
          "_" +
          currentdatetime.getDate() +
          "_" +
          currentdatetime.getHours() +
          "_" +
          currentdatetime.getMinutes() +
          "_" +
          currentdatetime.getSeconds();
        this.downloader.createAndDownloadFile(JSON.stringify(jsonGraphics), formatteddate);
      }
    } catch (error) {
      console.error(error);
    }
  }

  togglepopup() {
    try {
      this.closeEvent.emit(false);

      if (this.drawTool) {
        this.deactivateTool();
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy() {
    if (this.drawTool && this.drawTool.component === "redline") {
      this.deactivateTool();
    }

    if (this.eventHandler) {
      this.eventHandler.remove();
    }

    if (this.colorPicker) {
      this.colorPicker.destroy();
    }
  }
}
