<div class="select-tools-container">
  <div class="selectTools">
    <div class="action-item-text xxsmall">Select</div>
    <div class="action-item-container">
      <div class="action-item">
        <a href="#" title="{{ 'Select-tool.icon.selectbyextent' | translate }}" [ngClass]="{ 'i-inactive': !isDrawExtentEnabled, 'i-active': isDrawExtentEnabled }" (click)="optionClicked($event, 'Extent')" id="Extent">
          <mat-icon class="icon-new icon-new-select" svgIcon="select-by-extent" [ngClass]="{ 'i-inactive': !isDrawExtentEnabled, 'i-active': isDrawExtentEnabled }"></mat-icon>
        </a>
      </div>
      <div class="action-item">
        <a href="#" title="{{ 'Select-tool.icon.selectbypoly' | translate }}" [ngClass]="{ 'i-inactive': !isDrawPolygonEnabled, 'i-active': isDrawPolygonEnabled }" (click)="optionClicked($event, 'Polygon')" id="Polygon">
          <mat-icon class="icon-new icon-new-select" svgIcon="select-by-polygon" [ngClass]="{ 'i-inactive': !isDrawPolygonEnabled, 'i-active': isDrawPolygonEnabled }"></mat-icon>
        </a>
      </div>
      <div class="action-item">
        <a href="#" title="{{ 'Select-tool.icon.clearselection' | translate }}" class="i-inactive" (click)="optionClicked($event, 'Clear')" id="Clear">
          <mat-icon class="icon-new icon-new-select" svgIcon="erase"></mat-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="selectTools">
    <div class="action-item-text xxsmall">Display</div>
    <div class="action-item-container">
      <div class="action-item" title="{{ 'Select-tool.icon.tableviewer' | translate }}" *ngIf="currentUser.rolePermissions.tabularView && currentUser.soultionTiers[0][0].tabular_View">
        <a href="#" class="i-inactive select-icon-btn" (click)="optionClicked($event, 'resultGrid')" id="resultGrid" [attr.aria-label]="'' + 'Select-tool.icon.tableviewer' | translate">
          <mat-icon class="icon-new icon-new-select" svgIcon="table-view-bottom-2"></mat-icon>
        </a>
      </div>
      <div title="{{ 'Select-tool.icon.cardviewer' | translate }}" class="action-item">
        <a href="#" class="i-inactive select-icon-btn" (click)="optionClicked($event, 'resultCard')" id="resultCard" [attr.aria-label]="'' + 'Select-tool.icon.cardviewer' | translate">
          <mat-icon class="icon-new icon-new-select" svgIcon="table-view-right"></mat-icon>
        </a>
      </div>
      <div class="action-item" title="{{ 'Select-tool.icon.maintenanceviewer' | translate }}">
        <a
          href="#"
          class="i-inactive-maintenance select-icon-btn"
          (click)="optionClicked($event, 'maintenanceViewerSelTool')"
          id="maintenanceViewer"
          [attr.aria-label]="'' + 'Select-tool.icon.maintenanceviewer' | translate"
        >
          <mat-icon class="icon-new icon-new-select" svgIcon="table-view-bottom"></mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="maintenanceView">
  <sms-my-maintenance [Query]="Query" [QueryTask]="QueryTask" [map]="map" [tasksCategory]="category"
    [selectedData]="rowdataobj" [FeatureLayer]="FeatureLayer" [all]="all"></sms-my-maintenance>
</div> -->
<!--  
<ng-template class='model-content-height' #myTasks let-c="close" let-d="dismiss">
    <div class="modal-header">
        <div class="modal-title">
            <h2><b>My Tasks</b></h2>
            <h4>View my Tasks</h4>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body modalHeights">
        <sms-my-maintenance [Query]="Query" [QueryTask]="QueryTask" [map]="map" 
                [tasksCategory]="category" [selectedData]="rowdataobj" [FeatureLayer]="FeatureLayer" [all]="all"></sms-my-maintenance>
    </div>
</ng-template>-->
