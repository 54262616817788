import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-sms-crewmanagement",
  templateUrl: "./crewmanagement.component.html",
  styleUrls: ["./crewmanagement.component.scss"]
})
export class CrewmanagementComponent implements OnInit {
  opencrew = true;
  @Output() closeCrewManagementEvent = new EventEmitter();

  ngOnInit(): void {
    //console.log("Initialized Crew Management component");
  }
  // openCrew() {
  //   this.opencrew = true;

  // }
  close() {
    this.opencrew = false;
    this.closeCrewManagementEvent.emit(false);
  }
}
