import { Component, OnInit, Input } from "@angular/core";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-asset-information-reports",
  templateUrl: "./assetinformation.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class AssetInformationComponent implements OnInit {
  democratizeassets = [];
  humanfactor = [];
  buttons = [];
  faTimes = faTimes;
  faDownload = faDownload;
  @Input() region: any;

  public barChartOptions: any = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  public pieChartOptions: any = {
    responsive: true,
    legend: { position: "right" },
    maintainAspectRatio: false
  };

  public barChartLabels: string[] = ["2006", "2007", "2008", "2009", "2010", "2011", "2012"];
  public barChartType = "bar";
  public barChartLegend = true;

  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: "Graffiti" },
    { data: [28, 48, 40, 19, 86, 27, 90], label: "Vandalism" },
    { data: [24, 40, 45, 13, 66, 37, 70], label: "Accidents" }
  ];

  // Pie
  public pieChartLabels: string[] = ["D", "In-", "M"];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType = "pie";
  ngOnInit() {
    this.democratizeassets = [
      { name: "Sign Board", count: 3 },
      { name: "Sign Posts", count: 3 },
      { name: "Barrier", count: 3 },
      { name: "Guardrails", count: 3 },
      { name: "Crash Cussions", count: 3 },
      { name: "End Treatment", count: 3 },
      { name: "Bike Facilityes", count: 3 },
      { name: "abc", count: 3 },
      { name: "abc", count: 3 },
      { name: "abc", count: 3 },
      { name: "abc", count: 3 },
      { name: "abc", count: 3 }
    ];
    this.humanfactor = [
      { name: "Graffiti", count: 13 },
      { name: "Vandalism", count: 20 },
      { name: "Accidents", count: 17 },
      { name: "Total", count: 50 }
    ];
    this.buttons = this.democratizeassets;
    this.buttons.unshift({ name: "Summary", count: 3 });
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
  close() {
    document.getElementById("myForm").style.display = "none";
  }
}
