<div class="sms-model-height">
  <div class="card">
    <div class="card-body">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="{{ 'Userscreens.tabsetdefaults.assetdefaults' | translate }}">
          <ng-template matTabContent>
            <app-sms-asset-defaults [map]="map" [Graphic]="Graphic" [Query]="Query" [QueryTask]="QueryTask" [FeatureTable]="FeatureTable" [FeatureLayer]="FeatureLayer"></app-sms-asset-defaults>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="currentUser.soultionTiers[0][0].expected_Life" label="{{ 'Userscreens.tabsetdefaults.expectedlife' | translate }}">
          <ng-template matTabContent>
            <app-sms-retro-admin [map]="map" [Graphic]="Graphic" [FeatureTable]="FeatureTable" [FeatureLayer]="FeatureLayer"></app-sms-retro-admin>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="currentUser.soultionTiers[0][0].task_Duration_Tracking" label="{{ 'Userscreens.tabsetdefaults.defaulttaskduration' | translate }}">
          <ng-template matTabContent>
            <app-task-duration-defaults [Graphic]="Graphic" [FeatureTable]="FeatureTable" [FeatureLayer]="FeatureLayer"> </app-task-duration-defaults>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="currentUser.rolePermissions.assessmentEdit && currentUser.soultionTiers[0][0].activity_Rollback" label="RollBack">
          <ng-template matTabContent>
            <app-sms-rollback [map]="map" [Query]="Query" [QueryTask]="QueryTask" [FeatureLayer]="FeatureLayer" [Graphic]="Graphic"></app-sms-rollback>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
