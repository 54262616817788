import { Component, Input, Output, EventEmitter, Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
import { ILayer } from "../interface/layer";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Globals } from "../select/globals";
import { EventEmitersService } from "../services/eventEmiters.service";
import { AssetDetailService } from "../services/assetDetail.service";
import { BASE64_PREFIX } from "../shared/utils";
@Component({
  selector: "sms-search-tool",
  templateUrl: "./search-tool.component.html",
  styleUrls: ["./search-tool.component.scss"]
})
export class SearchToolComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public objGlobals: Globals,
    private eventEmitersService: EventEmitersService,
    public objAssetDetailService: AssetDetailService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  @Input() map: any;
  @Input() InfoTemplate: any;
  @Input() searchWidget: any;
  @Input() Circle: any;
  @Input() Units: any;
  @Input() SimpleFillSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() Color: any;
  @Input() Graphic: any;
  @Input() Query: any;
  @Input() QueryTask: any;
  @Input() Locator: any;
  @Input() FeatureLayer: any;
  @Input() Point: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() PictureMarkerSymbol: any;
  @Input() PopupTemplate: any;
  @Input() domConstruct: any;
  @Input() Popup: any;
  @Input() webMercatorUtils: any;
  @Input() layerData: any;
  @Input() ScreenPoint: any;
  @Input() GraphicsLayer: any;
  @Input() PopupExtended: any;
  @Input() RelationshipQuery: any;
  @Input() all: any;
  @Input() currentUser: any;
  @Input() drawTool: any;
  @Output() category = new EventEmitter();
  @Output() showmaintenanceViewer = new EventEmitter();
  @Output() index = new EventEmitter();
  @Output() layerIndex = new EventEmitter();
  @Output() data = new EventEmitter();
  @Output() showGrid = new EventEmitter();
  @Output() showCard = new EventEmitter();
  private rowdataobj;
  private cardView = false;
  public showOverviewMap: boolean;
  public x: any;
  public y: any;
  private sources: any;
  private _graphics: any;
  public searchWidgetClose = true; // default is street basemap
  public searchWidgetOpen = false;
  public textBlock = true;
  private __assetClassSources: any[];
  private _inventoryLayersData: ILayer[] = [] as ILayer[];
  private extendedPopup: any;
  private queriedLayers = [];
  public parenttasksCount;
  private SignLayer;
  private LightLayer;
  private SupportElement;
  public isAssetSearchtEnabled = true;
  public isAddressSearchEnabled = false;
  public isProximitySearchEnabled = false;
  swithSearchWidget(type: string) {
    try {
      if (type === "Open") {
        this._inventoryLayersData = [];
        this._inventoryLayersData = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
        // this.AddressSearch(document.getElementById('AddressSearch'), 'AddressSearch');
        //this.searchWidget.
        this.searchWidget.sources = [];
        //this.__assetClassSources = this.searchWidget.get("sources");
        this.__assetClassSources = [];
        this._inventoryLayersData.forEach((AssetClass) => {
          this.__assetClassSources.push({
            featureLayer: AssetClass.lyrObj,
            searchFields: ["AssetID", "Route"],
            suggestionTemplate: "Asset Id: ${AssetID} Route: ${Route}",
            exactMatch: false,
            name: AssetClass.layerName,
            outFields: ["*"],
            placeholder: "Search",
            maxResults: 6,
            maxSuggestions: 6,
            enableSuggestions: true,
            minCharacters: 1,
            infoTemplate: new this.PopupTemplate({
              title: AssetClass.layerName,
              fieldInfos: [
                {
                  fieldName: "AssetID",
                  label: "Asset Id",
                  visible: true
                },
                {
                  fieldName: "BaseType",
                  label: "Base Type",
                  visible: true
                },
                {
                  fieldName: "Material",
                  label: "Material",
                  visible: true
                },
                {
                  fieldName: "Route",
                  label: "Route",
                  visible: true
                }
              ]
            }),
            highlightSymbol: new this.PictureMarkerSymbol({
              angle: 0,
              xoffset: 0,
              yoffset: 0,
              type: "esriPMS",
              url: "http://static.arcgis.com/images/Symbols/Arrows/icon65.png",
              // tslint:disable-next-line:max-line-length
              imageData:
                "iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVEhL7c2xDYAwDETRjGk6Rskc0DBHMhxIiCtAIcSOHVH4S1faL9RaM8U1T7tsFK83/BzmzWFGDvPmMCOHefsrvCSay4eGS7SNx4GiIfgTRab4G4pM8C8UqeKtKFLBuSjqwqUoEuG9KGLhWihqwrVRVMWtUFTErVF0w0eh6MTFaAgHhHXoUFuIOKIAAAAASUVORK5CYII=",
              contentType: "image/png",
              width: 12,
              height: 12
            })
          });
        });
        this.AssetSearch("AssetSearch");
      } else if (type === "Close") {
        this.searchWidgetOpen = false;
        this.searchWidgetClose = true;
        this.sources = [];
        this.searchWidget.set("sources", this.sources);
        this.searchWidget.clear();
        this.toggleToRemoveSearch("All");
        this.map.infoWindow.clearFeatures();
      }
    } catch (error) {
      console.error(error);
    }
  }

  switchTextBlock(type: string) {
    try {
      if (type === "SearchWidget") {
        this.searchWidget.show();
        this.textBlock = true;
      } else if (type === "BufferSearch") {
        this.textBlock = false;
        this.searchWidget.hide();
      }
    } catch (error) {
      console.error(error);
    }
  }

  AssetSearch(stringName: string) {
    try {
      this.switchTextBlock("SearchWidget");

      this.optionClicked(stringName);

      this.sources = this.searchWidget.get("sources");

      if (this.sources.length > 0) {
        this.sources = [];
        this.searchWidget.clear();
        this.sources = this.__assetClassSources;
      } else {
        this.sources = this.__assetClassSources;
      }

      this.searchWidget.set("sources", this.sources);

    } catch (error) {
      console.error(error);
    }
  }

  AddressSearch(stringName: string) {
    try {
      this.switchTextBlock("SearchWidget");

      this.optionClicked(stringName);

      this.sources = this.searchWidget.get("sources");
      const AddressPopupTemplate = new this.PopupTemplate({
        title: "Address Search",
        fieldInfos: [
          {
            fieldName: "Addr_type",
            label: "Address Type",
            visible: false
          },
          {
            fieldName: "Match_addr",
            label: "Address",
            visible: true
          },
          {
            fieldName: "StAddr",
            label: "Street Address",
            visible: false
          },
          {
            fieldName: "City",
            label: "City",
            visible: true
          }
        ]
      });

      if (this.sources.length > 0) {
        this.sources = [];
        this.searchWidget.clear();
      }

      this.sources.push({
        locator: new this.Locator("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"),
        locationType: "street",
        outFields: ["Addr_type", "Match_addr", "StAddr", "City"],
        singleLineFieldName: "SingleLine",
        exactMatch: false,
        name: "Custom Geocoding Service",
        localSearchOptions: {
          minScale: 300000,
          distance: 50000
        },
        placeholder: "Search",
        maxResults: 6,
        maxSuggestions: 6,
        enableSuggestions: true,
        minCharacters: 1,
        infoTemplate: AddressPopupTemplate,
        highlightSymbol: new this.PictureMarkerSymbol({
          angle: 0,
          xoffset: 0,
          yoffset: 0,
          type: "esriPMS",
          url: "https://js.arcgis.com/3.30/esri/dijit/images/esriGreenPin16x26.png",
          contentType: "image/png",
          width: 16,
          height: 16
        })
      });

      this.searchWidget.set("sources", this.sources);

    } catch (error) {
      console.error(error);
    }
  }

  ProximityAsset(evt) {
    try {
      this.SignLayer = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId);
      this.LightLayer = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId);
      const symbol = new this.SimpleMarkerSymbol(
        this.SimpleMarkerSymbol.STYLE_CIRCLE,
        12,
        new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([0, 0, 255, 0.5]), 8),
        new this.Color([0, 0, 255, 0.9])
      );
      this._graphics = new this.Graphic(evt, symbol);
      this.map.graphics.add(this._graphics);
      const circle = new this.Circle({
        center: evt,
        geodesic: true,
        radius: 100,
        radiusUnit: this.Units.FEET
      });

      const circleSymb = new this.SimpleFillSymbol(this.SimpleFillSymbol.STYLE_NULL, new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([0, 0, 255]), 2), new this.Color([0, 0, 255, 0.25]));
      const graphic = new this.Graphic(circle, circleSymb);
      this.map.graphics.add(graphic);
      this.map.setExtent(graphic._extent);

      const query = new this.Query();
      query.geometry = circle;
      const listSelectData = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const selectionPointSymbol = new this.SimpleMarkerSymbol(
        this.SimpleMarkerSymbol.STYLE_CIRCLE,
        12,
        new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_NULL, new this.Color([247, 34, 101, 0.9]), 1),
        new this.Color([0, 255, 255, 9])
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const selectionLineSymbol = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([0, 255, 255, 9]), 3);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const selectionFillSymbol = new this.SimpleFillSymbol(
        this.SimpleFillSymbol.STYLE_SOLID,
        new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, new this.Color([247, 34, 101]), 2),
        new this.Color([247, 34, 101, 0.5])
      );

      const inventoryLayers = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
      const lyrCount = inventoryLayers.length;
      const flRequests = [];
      this.extendedPopup = new this.PopupExtended(
        {
          extended: {
            themeClass: "extended",
            draggable: false,
            defaultWidth: 100,
            actions: [],
            hideOnOffClick: false,
            multiple: true,
            smallStyleWidthBreak: 768
          },
          highlight: true
          // titleInBody: false,
        },
        this.domConstruct.create("div")
      );

      // set the map to use the exteneded popup
      this.extendedPopup.setMap(this.map);
      this.map.infoWindow = this.extendedPopup;

      this.queriedLayers = [];

      for (let i = 0; i < lyrCount; i++) {
        const ftrLayer = inventoryLayers[i].lyrObj;
        //const IsLayerVisible = inventoryLayers[i].visible;
        flRequests.push(ftrLayer.selectFeatures(query));
        this.queriedLayers.push(inventoryLayers[i]);
      }

      this.all(flRequests).then(
        function (results) {
          this.MultiplePopups(results, listSelectData, this.queriedLayers);
          results.forEach((element, i) => {
            if (element.length > 0) {
              listSelectData.push({ LayerName: this.queriedLayers[i], Data: element });
            }
          });
          this.rowdataobj = listSelectData;
          this.data.emit(this.rowdataobj);
          if (this.rowdataobj.length > 0) {
            this.eventEmitersService.setSelectedFeaturesOnMap(this.rowdataobj);
          }
          // let selectionGraphicsLyr;
          // let geometry;

          // if (this.map.getLayer("sms-selection")) {
          //     selectionGraphicsLyr = this.map.getLayer("sms-selection");
          //           } else {
          //              selectionGraphicsLyr = new this.GraphicsLayer({ id: "sms-selection" });
          //             this.map.addLayer(selectionGraphicsLyr);
          //           }
          //           selectionGraphicsLyr.on(
          //             "click",
          //             function (clkevt) {
          //               console.log(clkevt);
          //               let selected;
          //               let index;
          //               let selectedLayer;

          //               for (let i = 0; i < this.rowdataobj.length; i++) {
          //                 selected = this.rowdataobj[i].Data.find((d) => d.attributes.AssetID === clkevt.graphic.attributes.AssetID);
          //                 index = this.rowdataobj[i].Data.findIndex((d) => d.attributes.AssetID === clkevt.graphic.attributes.AssetID);
          //                 selectedLayer = i;

          //                 if (selected) {
          //                   break;
          //                 }
          //               }

          //               this.index.emit(index);
          //               this.layerIndex.emit(selectedLayer);
          //               this.data.emit(this.rowdataobj);
          //               if (this.rowdataobj.length > 0) {
          //                 this.eventEmitersService.setSelectedFeaturesOnMap(this.rowdataobj);
          //               }
          //               this.cardviewSelect(true);
          //             }.bind(this)
          //           );
          //           selectionGraphicsLyr.clear();
          // this.map.addLayer(selectionGraphicsLyr);
          this.cardviewSelect(true);
        }.bind(this)
      );
      this.all(flRequests).then((results) => {
        //console.log("success")
      });
    } catch (error) {
      console.error(error);
    }
  }
  getTasksCount(assetID) {
    try {
      return new Promise(
        function (resolve) {
          const query = new this.Query();
          const featureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId);
          query.where = "AssetID='" + assetID + "'";
          featureLayer.queryCount(query, (count) => {
            resolve(count);
          });
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SupportMultiplePopups(element, layer) {
    try {
      const SecondaryAsset = [];
      this.SupportElement = element;
      element.forEach((feature) => {
        const query = new this.Query();
        query.where = "ParentID ='" + feature.attributes.AssetID + "'";
        query.outFields = ["*"];
        query.returnGeometry = false;
        const queryTaskSign = this.SignLayer.execute(query);
        const queryTaskLight = this.LightLayer.execute(query);
        SecondaryAsset.push(queryTaskSign, queryTaskLight);
      });
      this.all(SecondaryAsset).then(
        function (res) {
          const SecondaryData = [];
          res.forEach((ele) => {
            ele.features.forEach((att) => {
              if (att.attributes.Status === "Active") {
                SecondaryData.push(att);
              }
            });
          });
          this.SupportElement.forEach((feature) => {
            const secondAsset = SecondaryData.filter((obj) => obj.attributes.ParentID === feature.attributes.AssetID);

            let parentAssetIconBase64;
            this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", feature.attributes.AssetCode, true).subscribe(
              (base64Image: any) => {
                if (base64Image) {
                  parentAssetIconBase64 = BASE64_PREFIX + base64Image

                  if (secondAsset.length > 0) {
                    let content =
                      '<div tabindex="0"><img tabindex="0" src="' + parentAssetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                      feature.attributes.AssetCode + '</div>';
                    let childassetIconBase64;
                    secondAsset.forEach((att) => {
                      //get child asset icons
                      this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", att.attributes.AssetCode, false).subscribe(
                        (base64Image: any) => {
                          if (base64Image) {
                            childassetIconBase64 = BASE64_PREFIX + base64Image;

                            content =
                              content +
                              '<br/><div tabindex="0"><img tabindex="0" src="' + childassetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                              att.attributes.AssetCode + '</div>';

                            this.ShowAssetClassPopupTemplate(feature, content);

                          }
                        },
                        error => {
                          if (error.status) {
                            console.log('No object found', error)
                            //
                            content =
                              content +
                              '<br/><div tabindex="0"><img tabindex="0" src="' + childassetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                              att.attributes.AssetCode + '</div>';
                            this.ShowAssetClassPopupTemplate(feature, content);

                            //end
                          }
                        });
                    });
                  } else {
                    const AssetClassPopupTemplate = new this.InfoTemplate();
                    const content =
                      '<div tabindex="0"><img  tabindex="0" src="' + parentAssetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                      feature.attributes.AssetCode + '</div>';
                    AssetClassPopupTemplate.setTitle(feature.attributes.AssetID);
                    AssetClassPopupTemplate.setContent(content);
                    feature.infoTemplate = AssetClassPopupTemplate;

                    if (typeof feature.geometry.paths !== "undefined") {
                      const loc = this.map.toScreen(feature.geometry.getExtent().getCenter());
                      this.map.infoWindow.setFeatures([feature]);
                      this.map.infoWindow.show(loc);
                    } else {
                      const loc = this.map.toScreen(feature.geometry);
                      this.map.infoWindow.setFeatures([feature]);
                      this.map.infoWindow.show(loc);
                    }
                  }
                }
              },
              error => {
                if (error.status) {
                  console.log('No object found', error)
                }
              })
          });
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MultiplePopups(results, listSelectData, inventoryLayers) {
    try {
      //const resultVal = [];
      results.forEach((element, i) => {
        if (element.length > 0) {
          if (inventoryLayers[i].layerName === "Support") {
            this.SupportElement = [];
            this.SupportMultiplePopups(element, inventoryLayers[i].lyrObj);
          } else {
            element.forEach((feature) => {

              let assetIconBase64; let content;
              this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", feature.attributes.AssetCode, true).subscribe(
                (base64Image: any) => {
                  if (base64Image) {
                    assetIconBase64 = BASE64_PREFIX + base64Image
                    content =
                      '<div tabindex="0"><img  tabindex="0" src="' + assetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                      feature.attributes.AssetCode + '</div>';

                    this.ShowAssetClassPopupTemplate(feature, content);

                  }
                },
                error => {
                  if (error.status) {
                    console.log('No object found', error)
                    const assetIconBase64 = "";
                    content =
                      '<div tabindex="0"><img  tabindex="0" src="' + assetIconBase64 + '" width="35" height="35" onerror=this.src="assets/images/nopic.png" alt="No image"/> &nbsp' +
                      feature.attributes.AssetCode + '</div>';
                    this.ShowAssetClassPopupTemplate(feature, content);
                  }
                })
            });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  ProximitySearch(stringName) {
    try {
      // Added by Madhuri JIRA ID :-35
      this.eventEmitersService.setEnableSingleClick(false);
      this.switchTextBlock("BufferSearch");
      this.optionClicked(stringName);

      this.drawTool.activate("point");
      const drawEnd = this.drawTool.on("draw-end", (evt) => {
        const points = this.webMercatorUtils.webMercatorToGeographic(evt.geometry);
        const long = points.x;
        const lat = points.y;
        const pt = new this.Point(long, lat);

        if (!this.currentUser.selectedRegion.Extent.contains(evt.geometry)) {
          Swal.fire(this.translateService.instant("Search-tool.alert.proximityoutsideregion"));

          if (this.map.graphics.graphics[0]) {
            this.map.graphics.clear();
          }

          this.toggleToRemoveSearch("All");
          this.drawTool.deactivate();
        } else {
          this.ProximityAsset(pt);
          this.drawTool.deactivate();
        }

        drawEnd.remove();
      });
    } catch (error) {
      console.error(error);
    }
  }

  optionClicked(val: string) {
    try {
      //TSDRSAM-1767
      if (val === "AssetSearch") {
        this.isAssetSearchtEnabled = true;
        this.isAddressSearchEnabled = false;
        this.isProximitySearchEnabled = false;

      } else if (val === "AddressSearch") {
        this.isAssetSearchtEnabled = false;
        this.isAddressSearchEnabled = true;
        this.isProximitySearchEnabled = false;
      }
      else if (val === "ProximitySearch") {
        this.isAssetSearchtEnabled = false;
        this.isAddressSearchEnabled = false;
        this.isProximitySearchEnabled = true;
      }


      this.toggleToRemoveSearch(val);
    } catch (error) {
      console.error(error);
    }
  }
  toggleToRemoveSearch(stringName) {
    try {
      let searchValues: any;

      if (this.currentUser.soultionTiers[0][0].proximity_Assets === 1) {
        searchValues = ["AssetSearch", "AddressSearch", "ProximitySearch"];
      } else {
        searchValues = ["AssetSearch", "AddressSearch"];
      }

      searchValues.forEach((val) => {
        if (val !== stringName) {
          const componentType = document.getElementById(val);
          const hasClassCT = componentType.classList.contains("i-active");

          if (hasClassCT) {
            componentType.classList.remove("i-active");
          }
        }

        this.fnClearLayerSelection();
        this.map.infoWindow.clearFeatures();

        if (this.extendedPopup) {
          this.extendedPopup.hide(this.map);
        }

        if (this.map.graphics.graphics[0]) {
          this.map.graphics.clear();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  fnClearLayerSelection() {
    try {
      const inventoryLayers = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
      const lyrCount = inventoryLayers.length;

      for (let i = 0; i < lyrCount; i++) {
        const ftrLayer = inventoryLayers[i].lyrObj;

        if (ftrLayer) {
          ftrLayer.clearSelection();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  cardviewSelect(visible) {
    try {
      if (visible) {
        if (!this.rowdataobj || this.rowdataobj.length === 0) {
          return;
        }
      }

      this.cardView = visible;
      this.objGlobals.IsAssetViewerClosed = !visible;
      this.showCard.emit(this.cardView);
    } catch (error) {
      console.error(error);
    }
  }
  //TSDRSAM-1767 : common code moved into single method
  ShowAssetClassPopupTemplate(feature, content) {
    const AssetClassPopupTemplate = new this.InfoTemplate();
    AssetClassPopupTemplate.setTitle(feature.attributes.AssetID);
    AssetClassPopupTemplate.setContent(content);
    feature.infoTemplate = AssetClassPopupTemplate;

    if (typeof feature.geometry.paths !== "undefined") {
      const loc = this.map.toScreen(feature.geometry.getExtent().getCenter());
      this.map.infoWindow.setFeatures([feature]);
      this.map.infoWindow.show(loc);
    } else {
      const loc = this.map.toScreen(feature.geometry);
      this.map.infoWindow.setFeatures([feature]);
      this.map.infoWindow.show(loc);
    }
  }
}
