import { Injectable } from "@angular/core";
import { SMSHttpClient } from "../helpers/http-client";
import { HttpHeaders } from "@angular/common/http";
import { IRequestOptions } from "src/app/interface/requestOption";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class HelpCenterService {
  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };

  constructor(private httpClient: SMSHttpClient) {}

  // POST Method for creating a new incident ticket */
  CreateRequest(formData) {
    return this.httpClient.post(environment.helpTicket, formData, this.responseType);
  }
  // GET Method for getting all ticket details */
  GetAllTickets() {
    return this.httpClient.get(environment.helpTicket, this.responseType);
  }
  // GET Method for getting ticket detail based on token id*/
  GetTicketByTokenID(tokenID) {
    return this.httpClient.get(environment.helpTicketByTokenID + tokenID);
  }
  // PATCH Method for update ticket detail based on token id*/
  UpdateRequestByAdmin(obj, tokenNo) {
    return this.httpClient.patch(environment.updateRequestByAdmin + tokenNo, obj, this.responseType);
  }
  // DELETE Method for delete ticket detail based on token id*/
  DeleteTicketByID(tokenID) {
    return this.httpClient.delete(environment.helpTicket + "?TokenNo=" + tokenID, this.responseType);
  }
}
