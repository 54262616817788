import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { AuthenticationService } from "../../../../services/authentication.service";
import { IUser } from "../../../../interface/user";
import { GenericLayerService } from "../../../../services/genericLayer.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { WeatherService } from "src/app/services/weatherForcast.service";
import { Globals } from "../../../globals";
import { QueryLayers } from "../../../../services/queryLayers.service";
import { TranslateService } from "@ngx-translate/core";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { AssetDetailService } from "src/app/services/assetDetail.service";
import { EventEmitersService } from "../../../../services/eventEmiters.service";
import { QueryBuilder } from "../../../../services/queryBuilderService.service";
import { UserService } from "src/app/services/user.service";
import { AssetAttributesService } from "../../../../services/assetAttibutes.service"; // Added by Abhilash for JIRA ID:-65 on 08/21/2021
import { faCalendarAlt as faCalendar, faSave } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle, faLock, faWrench, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

// import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
//@AutoUnsubscribe()
@Component({ selector: "create-tasks", templateUrl: "./createtask.component.html", styleUrls: ["../../../select.component.scss"], encapsulation: ViewEncapsulation.None })
export class CreateTaskComponent implements OnInit {
  public modalReference: NgbModalRef;
  getUserListSubscribe: Subscription;
  getFilteredFeaturesByFieldSubscribe: Subscription;
  getFilteredFeaturesByTwoFieldSubscribe: Subscription;
  getUserLayerAccessSubscribe: Subscription;

  constructor(
    private modalService: NgbModal,
    private assetAttributesService: AssetAttributesService, // Added by Abhilash for JIRA ID:-65 on 08/21/2021
    private authenticationService: AuthenticationService,
    public objGlobals: Globals,
    private genericLayerService: GenericLayerService,
    private weatherService: WeatherService,
    private translateService: TranslateService,
    private query: QueryLayers,
    private esrimap: EsriMapComponent,
    public objAssetDetailService: AssetDetailService,
    private eventEmitersService: EventEmitersService,
    private queryb: QueryBuilder,
    private userService: UserService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.userRoles = this.objGlobals.userRoles;
  }

  @Input() asset: any;
  @Input() LayerName: any;
  @Input() FeatureLayer: any;
  @Input() Graphic: any;
  @Input() webMercatorUtils: any;
  @Input() delete: boolean;
  @Input() editCreateTaskForm;

  @Output() maintenanceId = new EventEmitter();
  @Output() closeCard = new EventEmitter();
  @Output() showTasks = new EventEmitter();
  @Output() taskDeleted = new EventEmitter();

  currentUser: IUser;
  isEdit = "false";
  updateTask = false;
  clientUsersList;
  clientUsersListByLayer;
  rewtools: boolean;
  ftrLyrTbl;
  ftrLyrActivity;
  ftrLyrActivityToolKit;
  date;
  timestamp;
  dateToday;
  startTime;
  endTime;
  taskNameval;
  layerDomines;
  layerName;
  activityLyrDomines;
  activityLyrFltDomines;
  createdBy;
  workorderID;
  wrkType;
  wrkStatus;
  priority;
  region;
  faSave = faSave;
  faLock = faLock;
  faUnlock = faUnlock;
  faInfoCircle = faInfoCircle;
  faWrench = faWrench;
  faCalendar = faCalendar;
  regions; //7/28/20 jcc - need all regions to build out workers list
  worker;
  location;
  reason;
  activity;
  duration;
  desc;
  comments;
  geometry;
  scheduled: any;
  detailForecastData;
  showTable;
  assetTypeId;
  disableActivity: boolean;
  statusCodes;
  ReasonCodes;
  WorkOrderTypeCodes;
  dhours;
  dminutes;
  activityToolkitDomines;
  activityToolKitList;
  todayDate; //= new Date();
  createdDate: any = " ";
  defComments;
  defDesc;
  defReason;
  defWorker;
  defWorkOrder;
  userList1 = [];
  public disableWeatherIcon = false;
  disabledSave = false;
  LayerListByUser;
  userAccessByRoleRegionLayer = false;
  public taskInfo;
  TaskFormFieldsIsRequired: any = {}; // Added by Abhilash for JIRA ID:-65 on 08/21/2021
  isAdvancedFields = false;
  userRoles;
  ngOnInit() {
    console.log(this.desc?.length);
    console.log(this.desc?.length + 2);
    // Added by Abhilash for JIRA ID:-65 on 08/21/2021
    this.assetAttributesService.getTaskSummary().subscribe((taskresult) => {
      this.taskInfo = taskresult;
      if (taskresult["features"]) {
        taskresult["features"].forEach((item) => {
          this.TaskFormFieldsIsRequired[item.attributes.Field] = item.attributes.IsRequired ? true : false;
        });
      }
    });
    if (this.currentUser.clientType === "PROFESSIONAL") {
      this.isAdvancedFields = true;
    } else {
      this.isAdvancedFields = false;
    }
    this.clientUsersListByLayer = [];
    this.userAccessByRoleRegionLayer = this.findUserAccessByRoleRegionLayer();
    if (localStorage.getItem("workOrderDefaults") && JSON.parse(localStorage.getItem("workOrderDefaults"))) {
      const workOrderDefaults = JSON.parse(localStorage.getItem("workOrderDefaults"));
      this.defWorkOrder = this.workorderID = workOrderDefaults.WorkOrder;
      this.defWorker = this.worker = workOrderDefaults.Worker;
      this.defReason = this.reason = workOrderDefaults.Reason;
      this.defComments = this.comments = workOrderDefaults.Comments;
      this.defDesc = this.desc = workOrderDefaults.Description;
    }
    this.rewtools = false;
    // if (this.editCreateTaskForm) { }
    // else {
    if (!this.editCreateTaskForm) {
      this.editCreateTaskForm = {
        workorderID: true,
        wrkType: true,
        wrkStatus: false,
        createdBy: false,
        dateToday: false,
        priority: true,
        region: false,
        worker: true,
        location: false,
        reason: true,
        activity: true,
        duration: false,
        sheduledTime: true,
        startTime: false,
        endTime: false,
        assetId: false,
        desc: true,
        comments: true
      };
    }
    this.disableActivity = false;
    this.editCreateTaskForm.activity = true;
    this.updateTask = false;
    this.createdBy = this.currentUser.displayName;
    this.ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    this.ftrLyrActivityToolKit = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityToolkitId, { outFields: ["*"] });

    if (this.asset) {
      this.priority = 0;
      this.duration = "60";
      this.dminutes = this.duration % 60;
      this.dhours = (this.duration - this.dminutes) / 60;
      this.layerName = this.asset[1].layerName;
      this.objGlobals.selectedTask = null;
      if (this.asset[0].Region4) {
        this.region = this.asset[0].Region4;
      } else if (this.asset[0].Region3) {
        this.region = this.asset[0].Region3;
      } else if (this.asset[0].Region2) {
        this.region = this.asset[0].Region2;
      } else if (this.asset[4].region) {
        this.region = this.asset[4].region;
      }
      this.asset.forEach((el) => {
        if (el.regions) {
          this.regions = el.regions;
        }
      }); //7/28/20 jcc attach all regions to asset
    } else if (this.objGlobals.selectedTask) {
      if (this.objGlobals.regions) {
        this.regions = this.objGlobals.regions;
      } else {
        this.regions = {};
        this.regions.region1 = this.objGlobals.selectedTask.Region1;
        this.regions.region2 = this.objGlobals.selectedTask.Region2;
        this.regions.region3 = this.objGlobals.selectedTask.Region3;
        this.regions.region4 = this.objGlobals.selectedTask.Region4;
      }
      // this.regions = this.objGlobals.regions; //7/28/20 jcc attach all regions to asset
      this.workorderID = this.objGlobals.selectedTask.WorkOrder;
      this.wrkType = this.objGlobals.selectedTask.WorkOrderType;
      this.wrkStatus = this.objGlobals.selectedTask.Status;
      this.createdBy = this.objGlobals.selectedTask.CreatedBy;
      this.dateToday = new Date(this.objGlobals.selectedTask.CreationDate).toLocaleDateString();
      this.priority = parseInt(this.objGlobals.selectedTask.Priority, 10);
      this.region = this.objGlobals.selectedTask.Region;
      this.worker = this.objGlobals.selectedTask.Worker;
      this.location = this.objGlobals.selectedTask.Location;
      this.reason = this.objGlobals.selectedTask.Reason;
      this.activity = this.objGlobals.selectedTask.Activity;
      this.duration = this.objGlobals.selectedTask.EstimatedDuration;
      this.dminutes = this.duration % 60;
      this.dhours = (this.duration - this.dminutes) / 60;
      if (this.objGlobals.selectedTask.SheduledDate) {
        this.scheduled = new Date(this.objGlobals.selectedTask.SheduledDate);
      } else {
        this.scheduled = null;
      }
      if (this.objGlobals.selectedTask.CreationDate) {
        this.createdDate = new Date(this.objGlobals.selectedTask.CreationDate);
      } else {
        this.createdDate = null;
      }
      if (this.objGlobals.selectedTask.StartTime) {
        this.todayDate = new Date(this.objGlobals.selectedTask.StartTime);
      } else {
        this.todayDate = null;
      }
      this.startTime = this.objGlobals.selectedTask.StartTime;
      this.endTime = this.objGlobals.selectedTask.EndTime;
      this.desc = this.objGlobals.selectedTask.WorkOrderDesc;
      this.comments = this.objGlobals.selectedTask.Comment;
      this.updateTask = true;
      this.layerName = this.objGlobals.selectedTask.AssetName;
      this.geometry = this.objGlobals.taskGeometry;
      this.editCreateTaskForm.activity = false;

      if (this.objGlobals.selectedTask.Status === "Closed") {
        this.editCreateTaskForm = {
          workorderID: false,
          wrkType: false,
          wrkStatus: false,
          createdBy: false,
          dateToday: false,
          priority: false,
          region: false,
          worker: false,
          location: false,
          reason: false,
          activity: false,
          duration: false,
          sheduledTime: false,
          startTime: false,
          endTime: false,
          assetId: false,
          desc: false,
          comments: false
        };
        this.disabledSave = true;
        this.startTime = "";
      }
      this.getActivity(this.objGlobals.selectedTask.MaintID);
      this.getWeatherInformation();
    }
    this.getUserListSubscribe = this.genericLayerService
      .getUserList(this.currentUser.clientId) //7/28/20 jcc -- to do fix getUserList service to include asset class setttings.  Only list workers with DE perms for current asset class.
      .subscribe(
        function (results: any) {
          this.clientUsersList = [];
          this.clientUsersListByLayer = [];
          // const reg = this.region;

          //const reglevelsarray = reg.split(" ");  //7/29/20 jcc -- not used and breaks when region value is occasionally null (when asset does not have a lower region level that were active when task created.)

          function workerList(users, assetRegions) {
            // created jcc 7/28/20
            let userDataEditorRegions = {};
            const workers = [];
            users.forEach((user) => {
              userDataEditorRegions = { 1: [], 2: [], 3: [], 4: [] };
              user.regions.forEach((region) => {
                if (region.accessPrivilege === "Data Editor" && user.roleId != 1004) {
                  // 7/28/20 jcc - exclude 3M Admin role
                  userDataEditorRegions[region.regionLevel].push(region.regionName);
                }
              });
              if (
                userDataEditorRegions[4].includes(assetRegions.region4) ||
                userDataEditorRegions[3].includes(assetRegions.region3) ||
                userDataEditorRegions[2].includes(assetRegions.region2) ||
                userDataEditorRegions[1].includes(assetRegions.region1)
              ) {
                workers.push(user);
              }
            });
            return workers;
          }
          const editorUsers = results.filter(
            function (user) {
              if (
                user.roleId !=
                this.userRoles.filter((item) => {
                  if (item.roleName == "Readonly") {
                    return item;
                  }
                })[0].roleId
              ) {
                return user;
              }
            }.bind(this)
          );
          const clientUsersListl = workerList(editorUsers, this.regions);

          this.clientUsersList = clientUsersListl.filter(this.onlyUnique);
          // clientUsersListl.filter(function(user){if(user.regions.filter(function(item){ return item.regionName == this.currentUser.selectedRegion.regionName})){return user}}.bind(this))
          this.clientUsersList.forEach(
            function (element) {
              this.checkUserLayerAccess(this.authenticationService.currentUserValue.clientId, element.userId, this.clientUsersList, element);
            }.bind(this)
          );
        }.bind(this),
        (error) => {
          if (!this.clientUsersList) {
            this.clientUsersList = [this.currentUser];
          }
        }
      );
    this.date = new Date();
    this.timestamp = this.date.getFullYear().toString(); // 2011
    // JS months are 0-based, so +1 and pad with 0's
    this.timestamp += (this.date.getMinutes < 10 ? "0" : "") + this.date.getMinutes().toString();
    this.timestamp += (this.date.getSeconds < 10 ? "0" : "") + this.date.getSeconds().toString();
    this.dateToday = this.date.toLocaleDateString();
    this.ftrLyrActivity.on(
      "Load",
      function (evt) {
        this.saveDomine(evt.layer.types, this.layerName);
      }.bind(this)
    );
    this.ftrLyrActivityToolKit.on("Load", function (evt) {
      this.activityToolkitDomines = evt.types;
    });
    this.ftrLyrTbl.on(
      "Load",
      function (evt) {
        this.statusCodes = evt.layer.fields.filter(
          function (reason) {
            return reason.name === "Status";
          }.bind(this)
        )[0].domain.codedValues;
        this.ReasonCodes = evt.layer.fields.filter(
          function (reason) {
            return reason.name === "Reason";
          }.bind(this)
        )[0].domain.codedValues;
        this.WorkOrderTypeCodes = evt.layer.fields.filter(
          function (reason) {
            return reason.name === "WorkOrderType";
          }.bind(this)
        )[0].domain.codedValues;
        if (!this.wrkStatus) {
          this.wrkStatus = "Open";
        }
        if (!this.wrkType) {
          this.wrkType = this.WorkOrderTypeCodes[0].code;
        }
        if (!this.reason) {
          this.reason = this.ReasonCodes[0].code;
        }
      }.bind(this)
    );
  }
  ngOnDestroy(): void {
    console.log("destroy");
  }
  toolslist() {
    this.rewtools = !this.rewtools;
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  close() {
    this.showTasks.emit(false);
  }
  showUserOverLoadedMsg() {
    Swal.fire("Selected User is Over Loaded! Please Select Another User");
    this.worker = null;
  }

  saveDomine(data, layerName) {
    try {
      this.layerDomines = data;
      this.activityLyrDomines = this.layerDomines.filter(
        function (hero) {
          return hero.name === layerName;
        }.bind(this)
      );
      this.activityLyrFltDomines = [];
      const filterValues = [];
      // tslint:disable-next-line:prefer-for-of
      for (let ind = 0; ind < this.activityLyrDomines[0].domains.Activity.codedValues.length; ind++) {
        if (
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "New" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "Replace" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "Relocate" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "Retire" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "DetailUpdate" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "Collect" &&
          this.activityLyrDomines[0].domains.Activity.codedValues[ind].code !== "Reshape"
        ) {
          filterValues.push(this.activityLyrDomines[0].domains.Activity.codedValues[ind]);
        }
      }
      switch (this.objGlobals.userSelectedActivity) {
        case "General":
          this.activityLyrFltDomines = filterValues;
          if (!this.activity) {
            this.activity = this.activityLyrDomines[0].domains.Activity.codedValues[0].code;
          }
          break;
        // RSAM JIRA ID: 963 Bug
        default:
          this.activityLyrFltDomines = this.activityLyrDomines[0].domains.Activity.codedValues;
          if (!this.activity) {
            this.activity = this.objGlobals.userSelectedActivity;
          }
          this.editCreateTaskForm.activity = false;
          break;
      }
      this.activityChange(this.activity);
      this.assetTypeId = this.activityLyrDomines[0].id;
    } catch (error) {
      console.error(error);
    }
  }
  // Added by Abhilash for JIRA ID:-65 on 08/21/2021
  isValidateField = true;
  validateTaskForm() {
    this.isValidateField = true;
    const allFields = Object.keys(this.TaskFormFieldsIsRequired);
    allFields.forEach((element) => {
      if (this.TaskFormFieldsIsRequired[element]) {
        switch (element) {
          case "SheduledDate": {
            if ((!this.scheduled || !this.scheduled.getTime()) && this.isAdvancedFields) {
              this.isValidateField = false;
            }
            break;
          }
          case "EstimatedDuration": {
            if (!this.dhours && this.isAdvancedFields) {
              this.isValidateField = false;
            }
            break;
          }
          case "StartTime": {
            if (!this.todayDate && this.isAdvancedFields) {
              this.isValidateField = false;
            }
            break;
          }
          case "Priority": {
            if (!this.priority && this.priority !== 0 && this.isAdvancedFields) {
              this.isValidateField = false;
            }
            break;
          }
          case "CreatedBy": {
            if (!this.createdBy) {
              this.isValidateField = false;
            }
            break;
          }
          case "Region": {
            if (!this.region) {
              this.isValidateField = false;
            }
            break;
          }
          case "WorkOrder": {
            if (!this.workorderID) {
              this.isValidateField = false;
            }
            break;
          }
          case "Worker": {
            if (!this.worker) {
              this.isValidateField = false;
            }
            break;
          }
          case "Reason": {
            if (!this.reason) {
              this.isValidateField = false;
            }
            break;
          }
          case "WorkOrderDesc": {
            if (!this.desc) {
              this.isValidateField = false;
            }
            break;
          }
          case "Comment": {
            if (!this.comments) {
              this.isValidateField = false;
            }
            break;
          }
        }
      }
    });
    return this.isValidateField;
  }
  saveTask(): void {
    try {
      if (!this.validateTaskForm()) {
        // Added by Abhilash for JIRA ID:-65 on 08/21/2021
        Swal.fire(this.translateService.instant("Select.createtask.ValidationMessage"));
      } else {
        this.disabledSave = true;
        this.duration = this.dhours * 60 + this.dminutes;
        const featurese = [];
        const features = new this.Graphic();
        let assetId;

        if (this.asset) {
          if (this.asset[0].AssetID) {
            assetId = this.asset[0].AssetID;
          } else {
            assetId = this.asset[0].signAttribute.AssetID;
          }
        }
        let geomString;
        if (this.asset && this.asset[3].geometry != null) {
          geomString = this.asset[3].geometry.toJson();
        }
        if (this.updateTask) {
          this.taskDeleted.emit(true);
          let vsheduledTime = null;
          if (this.scheduled && this.scheduled.getTime()) {
            vsheduledTime = this.scheduled.getTime();
          }

          features.setAttributes({
            OBJECTID: this.objGlobals.selectedTask.OBJECTID,
            WorkOrder: this.workorderID,
            WorkOrderType: this.wrkType,
            Status: this.wrkStatus,
            AssetName: this.objGlobals.selectedTask.AssetName,
            CreatedBy: this.createdBy,
            LastModifyDate :new Date(Date.now()).getTime(),
            Priority: parseInt(this.priority, 10),
            Region: this.region,
            Worker: this.worker,
            Location: this.location,
            Reason: this.reason,
            Activity: this.activity,
            EstimatedDuration: parseInt(this.duration, 10),
            SheduledDate: vsheduledTime,
            StartTime: this.startTime,
            EndTime: this.endTime,
            AssetID: assetId,
            WorkOrderDesc: this.desc,
            Comment: this.comments,
            MaintID: "MNT_" + this.objGlobals.selectedTask.OBJECTID
          });
          featurese.push(features);
          this.ftrLyrTbl
            .applyEdits(null, featurese, null)
            .then(
              function () {
                this.taskDeleted.emit(true);
                this.objAssetDetailService.tasksModified.emit(true);
                this.eventEmitersService.emitTaskCreated.emit(true);
              }.bind(this),
              this.showUpdateMesage()
            )
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
        } else {
          let time;
          if (this.scheduled) {
            time = this.scheduled.getTime();
          } else {
            time = null;
          }

          if (geomString.paths) {
            geomString.x = geomString.paths[0][0][0];
            geomString.y = geomString.paths[0][0][1];
            delete geomString.paths;
          }

          features.setAttributes({
            WorkOrder: this.workorderID,
            WorkOrderType: this.wrkType,
            Status: this.wrkStatus,
            CreatedBy: this.createdBy,
            CreationDate: new Date(Date.now()).getTime(),
            Priority: parseInt(this.priority, 10),
            Region: this.region,
            Worker: this.worker,
            Location: this.location,
            Reason: this.reason,
            Activity: this.activity,
            EstimatedDuration: parseInt(this.duration, 10),
            SheduledDate: time,
            StartTime: this.startTime,
            EndTime: this.endTime,
            AssetID: assetId,
            AssetName: this.asset[1].layerName,
            WorkOrderDesc: this.desc,
            Comment: this.comments,
            GeomWKT: JSON.stringify(geomString)
          });
          featurese.push(features);

          if (this.assetTypeId !== null) {
            this.ftrLyrTbl
              .applyEdits(featurese, null, null)
              .then(
                function (newrow) {
                  // updateing MaintID for newly inserted Maintanance record
                  const featureseAssetID = [];
                  const featuresA = new this.Graphic();
                  featuresA.setAttributes({
                    OBJECTID: newrow[0].objectId,
                    MaintID: "MNT_" + newrow[0].objectId
                  });
                  const maintenanceRelId = "MNT_" + newrow[0].objectId;
                  featureseAssetID.push(featuresA);
                  this.ftrLyrTbl
                    .applyEdits(null, featureseAssetID, null)
                    .then(
                      function (newrow) {
                        const actfeaturese = [];
                        const actfeatures = new this.Graphic();

                        actfeatures.setAttributes({
                          // updateing MaintID for newly inserted Maintanance record
                          MaintID: maintenanceRelId,
                          Asset: this.assetTypeId,
                          Activity: this.activity,
                          Description: this.desc,
                          ModifiedBy: this.createdBy
                        });
                        actfeaturese.push(actfeatures);
                        this.ftrLyrActivity
                          .applyEdits(actfeaturese, null, null)
                          .then(
                            function () {
                              if (this.objGlobals.complaintID != null && this.objGlobals.complaintID !== undefined && this.objGlobals.complaintID !== "") {
                                (this.objGlobals.longitude = this.asset[0].Longitude), (this.objGlobals.latitude = this.asset[0].Latitude), (this.objGlobals.assetID = this.asset[0].AssetID);
                                this.esrimap.crowdsource();
                              }
                              this.objAssetDetailService.tasksModified.emit(true);
                              this.eventEmitersService.emitTaskCreated.emit(true);
                              this.showCreateMesage();
                            }.bind(this)
                          )
                          .catch(
                            function (error) {
                              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                              console.log("error = ", error);
                              this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
                            }.bind(this)
                          );
                        if (this.wrkStatus === "Closed" && (this.activity === "Replace" || this.activity === "Retire")) {
                          features.attributes.OBJECTID = newrow[0].objectId;
                          this.task.completeTask(features.attributes);
                        }
                      }.bind(this)
                    )
                    .catch(
                      function (error) {
                        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                        console.log("error = ", error);
                        this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
                      }.bind(this)
                    );
                  // End
                }.bind(this)
              )
              .catch((error) => {
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                console.log("error = ", error);
              });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  saveAndCompleteTask() {
    try {
      if (!this.validateTaskForm()) {
        // Added by Abhilash for JIRA ID:-65 on 08/21/2021
        Swal.fire(this.translateService.instant("Select.createtask.ValidationMessage"));
      } else {
        this.disabledSave = true;
        this.duration = this.dhours * 60 + this.dminutes;
        const featurese = [];
        const features = new this.Graphic();
        let geomString;
        let assetId;
        this.wrkStatus = "Closed";

        if (this.asset) {
          if (this.asset[0].AssetID) {
            assetId = this.asset[0].AssetID;
          } else {
            assetId = this.asset[0].signAttribute.AssetID;
          }
        } else {
          assetId = this.objGlobals.selectedTask.AssetID;
        }
        if (this.asset && this.asset[3].geometry != null) {
          geomString = this.asset[3].geometry.toJson();
        }
        if (this.updateTask) {
          let vsheduledTime = null;
          if (this.scheduled && this.scheduled.getTime()) {
            vsheduledTime = this.scheduled.getTime();
          }
          let _endTime = null;
          const endtime = new Date();
          _endTime = endtime.getTime();

          if (this.startTime) {
            const startTimeDate = new Date(this.startTime);
            this.startTime = startTimeDate.getTime();
          }
          features.setAttributes({
            OBJECTID: this.objGlobals.selectedTask.OBJECTID,
            WorkOrder: this.workorderID,
            WorkOrderType: this.wrkType,
            Status: this.wrkStatus,
            CreatedBy: this.createdBy,
            Priority: parseInt(this.priority, 10),
            Region: this.region,
            Worker: this.worker,
            Location: this.location,
            Reason: this.reason,
            Activity: this.activity,
            EstimatedDuration: parseInt(this.duration, 10),
            SheduledDate: vsheduledTime,
            StartTime: this.startTime,
            EndTime: _endTime,
            AssetID: assetId,
            WorkOrderDesc: this.desc,
            Comment: this.comments,
            CompletionDate: new Date(Date.now()).getTime(),
            CompletedBy: this.currentUser.username,
            AssetName: this.layerName
          });
          featurese.push(features);
          this.ftrLyrTbl
            .applyEdits(null, featurese, null)
            .then(
              function () {
                // Update Status of Asset if Retired or Replaced
                this.objAssetDetailService.Query = this.esrimap.Query;
                this.objAssetDetailService.QueryTask = this.esrimap.QueryTask;
                // const activityLayer =
                //   this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
                // const maintenanceLayer =
                //   this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId;
                // this.objAssetDetailService.setRetireDate(
                //   activityLayer, features.attributes.OBJECTID, this.objGlobals.activeAsset,
                //   this.ftrLyrTbl, this.currentUser, this.FeatureLayer, this.Graphic,features.attributes);
                if (this.activity === "Retire") {
                  //this.eventEmitersService.setShowCard(false);
                }
                this.esrimap.map.getLayer("sms-layers").refresh();
                //if New task completeed then update install date = completionDate
                if (this.wrkStatus === "Closed" && this.activity === "New") {
                  this.genericLayerService.getFilteredFeaturesByField(this.objGlobals.activeAsset[1].lyrObj.layerId, "AssetID", this.objGlobals.activeAsset[0].AssetID).subscribe((resultObj: any) => {
                    const features = new this.Graphic();
                    const att: any = [];
                    att.OBJECTID = resultObj.features[0].attributes.OBJECTID;
                    att.InstallDate = new Date(Date.now()).getTime();
                    features.setAttributes(att);
                    this.query.updateAsset(this.objGlobals.activeAsset[1].lyrObj, features).then((result) => {
                      this.eventEmitersService.emitTaskCompleted.emit(true);
                    });
                  });
                }


                if (this.wrkStatus === "Closed" && this.activity === "Replace") {
                  // Update Status of Asset if Retired or Replaced
                  this.objAssetDetailService.Query = this.esrimap.Query;
                  this.objAssetDetailService.QueryTask = this.esrimap.QueryTask;
                  const activityLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
                  this.objAssetDetailService.ReplaceTask(this.objGlobals.activeAsset, this.ftrLyrTbl, activityLayer, this.FeatureLayer, this.Graphic, this.currentUser, features.attributes);
                  this.esrimap.map.getLayer("sms-layers").refresh();
                }
                if (this.wrkStatus === "Closed" && this.activity === "Retire") {
                  this.objAssetDetailService.Query = this.esrimap.Query;
                  this.objAssetDetailService.QueryTask = this.esrimap.QueryTask;
                  const activityLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
                  this.objAssetDetailService.RetireAsset(this.objGlobals.activeAsset, this.ftrLyrTbl, this.currentUser,this.FeatureLayer);
                  this.closeCard.emit(true);

                  let assetName;
                  if (this.featureLayer) {
                    assetName = this.featureLayer._name;
                  } else if (this.objGlobals.selectedTask.AssetName) {
                    assetName = this.objGlobals.selectedTask.AssetName;
                  }

                  if (assetName == "Sign" || assetName == "Light") {
                    setTimeout(function () {
                      this.eventEmitersService.setchildDeleted(false);
                    }.bind(this),5000)
                  } else {
                    if (this.esrimap.map.getLayer("sms-selection")) {
                      this.esrimap.map.getLayer("sms-selection").remove(
                        this.esrimap.map.getLayer("sms-selection").graphics.filter((item) => {
                          if (item.attributes.AssetID == this.eventEmitersService.activeAssetFeature[0].AssetID) return item;
                        })[0]
                      );

                      this.esrimap.map.getLayer("sms-selection").refresh();
                      this.esrimap.map.graphics.clear();
                    }
                    if (this.eventEmitersService.selectedFeaturesOnMap && this.eventEmitersService.selectedFeaturesOnMap.length) {
                      this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.splice(this.eventEmitersService.selectedParentIndexOnData, 1);
                      this.eventEmitersService.setShowCard(false);

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length > 0) {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap, this.eventEmitersService.selectedLayerIndexOnData, 0);
                      } else {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap);
                      }

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length) {
                        this.eventEmitersService.setShowCard(true);
                      }
                    }

                    this.esrimap.map.getLayer("sms-selection").refresh();
                  }
                  this.esrimap.map.graphics.clear();
                  this.esrimap.map.getLayer("sms-layers").refresh();
                  this.objAssetDetailService.tasksModified.emit(true);
                }
                if (this.activity !== "Retire" && this.activity !== "Replace") {
                  this.objAssetDetailService.tasksModified.emit(true);
                }
                this.eventEmitersService.emitTaskAdded(true);
                this.objAssetDetailService.tasksModified.emit(true);
                this.eventEmitersService.emitTaskCreated.emit(true);
              }.bind(this),
              this.showUpdateMesage(),
              this.maintenanceId.emit(true)
            )
            // tslint:disable-next-line:only-arrow-functions
            .catch(function (error) {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
        } else {
          // let time;
          // if (this.scheduled) { time = this.scheduled.getTime(); } else { time = null; }

          if (geomString.paths) {
            geomString.x = geomString.paths[0][0][0];
            geomString.y = geomString.paths[0][0][1];
            delete geomString.paths;
          }
          let vsheduledTime = null;
          if (this.scheduled && this.scheduled.getTime()) {
            vsheduledTime = this.scheduled.getTime();
          }
          let _endTime = null;
          const endtime = new Date();
          _endTime = endtime.getTime();
          if (this.startTime) {
            const startTimeDate = new Date(this.startTime);
            this.startTime = startTimeDate.getTime();
          }
          features.setAttributes({
            WorkOrder: this.workorderID,
            WorkOrderType: this.wrkType,
            Status: this.wrkStatus,
            CreatedBy: this.createdBy,
            CreationDate: new Date(Date.now()).getTime(),
            Priority: parseInt(this.priority, 10),
            Region: this.region,
            Worker: this.worker,
            Location: this.location,
            Reason: this.reason,
            Activity: this.activity,
            EstimatedDuration: parseInt(this.duration, 10),
            SheduledDate: vsheduledTime,
            StartTime: this.startTime,
            EndTime: _endTime,
            AssetID: assetId,
            AssetName: this.asset[1].layerName,
            WorkOrderDesc: this.desc,
            Comment: this.comments,
            GeomWKT: JSON.stringify(geomString),
            CompletionDate: new Date(Date.now()).getTime(),
            CompletedBy: this.currentUser.username
          });
          featurese.push(features);
          if (this.assetTypeId !== null) {
            this.ftrLyrTbl
              .applyEdits(featurese, null, null)
              .then(
                function (newrow) {
                  // updateing MaintID for newly inserted Maintanance record
                  const featureseAssetID = [];
                  const featuresA = new this.Graphic();
                  featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
                  featureseAssetID.push(featuresA);
                  this.ftrLyrTbl
                    .applyEdits(null, featureseAssetID, null)
                    .then(
                      function () {
                        this.showCreateMesage();
                      }.bind(this)
                    )
                    .catch(
                      function (error) {
                        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                        console.log("error = ", error);
                        this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
                      }.bind(this)
                    );
                  // End
                  const actfeaturese = [];
                  const actfeatures = new this.Graphic();
                  actfeatures.setAttributes({
                    // updateing MaintID for newly inserted Maintanance record
                    MaintID: "MNT_" + newrow[0].objectId,
                    Asset: this.assetTypeId,
                    Activity: this.activity,
                    Description: this.desc,
                    ModifiedBy: this.createdBy
                  });
                  actfeaturese.push(actfeatures);
                  this.ftrLyrActivity
                    .applyEdits(actfeaturese, null, null)
                    .then(
                      function () {
                        this.showCreateMesage();
                        if (!(this.activity === "Replace" || this.activity === "Replace")) {
                          this.objAssetDetailService.tasksModified.emit(true);
                          this.eventEmitersService.emitTaskCreated.emit(true);
                        }
                      }.bind(this)
                    )
                    .catch(
                      function (error) {
                        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                        console.log("error = ", error);
                        this.ftrLyrTbl.applyEdits(null, null, featureseAssetID);
                      }.bind(this)
                    );
                  if (this.wrkStatus === "Closed" && this.activity === "Replace") {
                    // Update Status of Asset if Retired or Replaced
                    this.objAssetDetailService.Query = this.esrimap.Query;
                    this.objAssetDetailService.QueryTask = this.esrimap.QueryTask;
                    const activityLayer = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId;
                    this.objAssetDetailService.ReplaceTask(this.objGlobals.activeAsset, this.ftrLyrTbl, activityLayer, this.FeatureLayer, this.Graphic, this.currentUser, features.attributes);
                    this.esrimap.map.getLayer("sms-layers").refresh();

                    this.objAssetDetailService.tasksModified.emit(true);
                    this.closeCard.emit(true);
                  }
                  if (this.wrkStatus === "Closed" && this.activity === "Retire") {
                    this.objAssetDetailService.Query = this.esrimap.Query;
                    this.objAssetDetailService.QueryTask = this.esrimap.QueryTask;
                    this.objAssetDetailService.RetireAsset(this.objGlobals.activeAsset, this.ftrLyrTbl, this.currentUser,this.FeatureLayer);
                    this.closeCard.emit(true);

                    if (this.eventEmitersService.activeAssetFeature[1].layerName == "Sign" || this.eventEmitersService.activeAssetFeature[1].layerName == "Light") {
                      setTimeout(function () {
                      this.eventEmitersService.setchildDeleted(false);
                    }.bind(this),2000)
                    } else {
                      if (this.esrimap.map.getLayer("sms-selection")) {
                        if (this.esrimap.map.getLayer("sms-selection"))
                          this.esrimap.map.getLayer("sms-selection").remove(
                            this.esrimap.map.getLayer("sms-selection").graphics.filter((item) => {
                              if (item.attributes.AssetID == this.eventEmitersService.activeAssetFeature[0].AssetID) return item;
                            })[0]
                          );

                        this.esrimap.map.getLayer("sms-selection").refresh();
                        if (this.map) this.map.graphics.clear();
                      }
                      this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.splice(this.eventEmitersService.selectedParentIndexOnData, 1);
                      this.eventEmitersService.setShowCard(false);

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length > 0) {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap, this.eventEmitersService.selectedLayerIndexOnData, 0);
                      } else {
                        this.eventEmitersService.setSelectedFeaturesOnMap(this.eventEmitersService.selectedFeaturesOnMap);
                      }

                      if (this.eventEmitersService.selectedFeaturesOnMap[this.eventEmitersService.selectedLayerIndexOnData].Data.length) {
                        this.eventEmitersService.setShowCard(true);
                      }
                      this.esrimap.map.getLayer("sms-selection").refresh();
                    }
                    this.esrimap.map.getLayer("sms-layers").refresh();
                    this.objAssetDetailService.tasksModified.emit(true);
                  }
                  if (this.activity !== "Retire" && this.activity !== "Replace") {
                    this.objAssetDetailService.tasksModified.emit(true);
                  }
                }.bind(this),

                this.maintenanceId.emit(true)
              )
              .catch((error) => {
                console.log("===============================================");
                console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                console.log("error = ", error);
              });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  startTask() {
    try {
      // TSDRSAM-300
      this.duration = this.dhours * 60 + this.dminutes;
      const featurese = [];
      const features = new this.Graphic();

      if (this.updateTask) {
        let _startTime = null;
        const starttime = new Date();
        _startTime = starttime.getTime();
        features.setAttributes({
          OBJECTID: this.objGlobals.selectedTask.OBJECTID,
          StartTime: _startTime
        });
        featurese.push(features);
        this.ftrLyrTbl
          .applyEdits(null, featurese, null)
          .then(
            function () {
              this.taskDeleted.emit(true);
              this.objAssetDetailService.tasksModified.emit(true);
              this.eventEmitersService.emitTaskCreated.emit(true);
            }.bind(this),
            this.showUpdateMesage(),
            this.maintenanceId.emit(true)
          )
          .catch((error) => {
            console.log("===============================================");
            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      } else {
        this.todayDate = new Date();
        this.startTime = new Date().getTime();
      }
    } catch (error) {
      console.error(error);
    }
  }
  completeTask() {
    try {
      this.duration = this.dhours * 60 + this.dminutes;
      const featurese = [];
      const features = new this.Graphic();
      let assetId;

      if (this.asset) {
        if (this.asset[0].AssetID) {
          assetId = this.asset[0].AssetID;
        } else {
          assetId = this.asset[0].signAttribute.AssetID;
        }
      }
      // let geomString;
      if (this.asset && this.asset[3].geometry != null) {
        // geomString = this.asset[3].geometry.toJson();
      }
      if (this.updateTask) {
        let vsheduledTime = null;
        if (this.scheduled.getTime()) {
          vsheduledTime = this.scheduled.getTime();
        }
        let _endTime = null;
        const endtime = new Date();
        _endTime = endtime.getTime();
        if (this.startTime) {
          const startTimeDate = new Date(this.startTime);
          this.startTime = startTimeDate.getTime();
        }
        features.setAttributes({
          OBJECTID: this.objGlobals.selectedTask.OBJECTID,
          WorkOrder: this.workorderID,
          WorkOrderType: this.wrkType,
          Status: "Closed",
          AssetName: this.asset[1].layerName,
          CreatedBy: this.createdBy,
          CreationDate: new Date(Date.now()).getTime(),
          Priority: parseInt(this.priority, 10),
          Region: this.region,
          Worker: this.worker,
          Location: this.location,
          Reason: this.reason,
          Activity: this.activity,
          EstimatedDuration: parseInt(this.duration, 10),
          SheduledDate: vsheduledTime,
          StartTime: this.startTime,
          EndTime: _endTime,
          AssetID: assetId,
          WorkOrderDesc: this.desc,
          Comment: this.comments
        });
        featurese.push(features);
        this.ftrLyrTbl
          .applyEdits(null, featurese, null)
          .then(
            function () {
              this.objAssetDetailService.tasksModified.emit(true);
            }.bind(this),
            this.showUpdateMesage(),
            this.maintenanceId.emit(true)
          )
          .catch((error) => {
            console.log("===============================================");
            console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
  // showCreateMesage() {
  //   try {
  //   if (this.activityToolKitList && this.activityToolKitList.length === 1) {
  //     const toolname = this.activityToolKitList[0].attributes.ToolName;

  //     if (toolname !== "Standard Resources") {
  //       Swal.fire(
  //         this.translateService.instant("Select.createtask.taskcreate")
  //       );
  //       this.showTasks.emit(false);
  //     } else {
  //       this.todayDate = new Date();
  //       this.startTime = new Date().getTime();
  //     }
  //   }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  showCreateMesage() {
    try {
      if (this.activityToolKitList && this.activityToolKitList.length === 1) {
        const toolname = this.activityToolKitList[0].attributes.ToolName;

        if (toolname !== "Standard Resources") {
          Swal.fire(this.translateService.instant("Select.createtask.taskcreate"));
          this.showTasks.emit(false);
        } else {
          Swal.fire(this.translateService.instant("Select.createtask.tasksuccesswithstandardresource"));
          this.showTasks.emit(false);
        }
      } else {
        Swal.fire(this.translateService.instant("Select.createtask.taskcreate"));
        this.showTasks.emit(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  showUpdateMesage() {
    Swal.fire(this.translateService.instant("Select.createtask.taskupdate"));
    this.showTasks.emit(false);
  }

  daysBetween(first, second) {
    try {
      // Copy date parts of the timestamps, discarding the time parts.
      const one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
      const two = new Date(second.getFullYear(), second.getMonth(), second.getDate());
      // Do the math.
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const millisBetween = two.getTime() - one.getTime();
      const days = millisBetween / millisecondsPerDay;
      // Round down.
      return Math.floor(days);
    } catch (error) {
      console.error(error);
    }
  }
  showDetailTable() {
    try {
      if (this.daysBetween(new Date(), this.scheduled) > 5) {
        this.disableWeatherIcon = true;
        this.showTable = false;
        const icondiv = document.getElementById("weatherIconDiv");
        icondiv.setAttribute("class", "");
        icondiv.classList.add("w-icon");
        icondiv.classList.add("w-icon-grey");
      } else {
        this.disableWeatherIcon = false;
        this.showTable = !this.showTable;
      }
    } catch (error) {
      console.error(error);
    }
  }
  getWeatherInformation() {
    try {
      if (this.scheduled && this.webMercatorUtils) {
        let geom: any;
        let lat: any;
        let lng: any;

        if (this.asset && this.asset[3].geometry) {
          geom = this.webMercatorUtils.webMercatorToGeographic(this.asset[3].geometry);
        } else if (this.geometry) {
          geom = this.webMercatorUtils.webMercatorToGeographic(this.geometry);
        } else {
          geom = JSON.parse(this.objGlobals.selectedTask.GeomWKT);
        }
        if (geom.type === "point") {
          lat = geom.y;
          lng = geom.x;
        } else if (geom.type === "polyline") {
          lng = geom.paths[0][0][0];
          lat = geom.paths[0][0][1];
        }
        this.weatherService.getWeatherForcastfordate(this.scheduled, lat, lng).then(
          function (result) {
            if (result && result.dayForecast && result.dayForecast.length > 0) {
              this.setWeatherInfo(result);
            } else {
              const icondiv = document.getElementById("weatherIconDiv");
              icondiv.setAttribute("class", "");
              icondiv.classList.add("w-icon");
              icondiv.classList.add("w-icon-grey");
            }
          }.bind(this)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  setWeatherInfo(forecastData) {
    try {
      this.detailForecastData = forecastData.dayForecast;
      if (forecastData.timeForecast) {
        const icondiv = document.getElementById("weatherIconDiv");
        icondiv.setAttribute("class", "");
        icondiv.classList.add("w-icon");
        icondiv.classList.add(
          this.getRecommendationColorClassForIcon(forecastData.timeForecast.tempcolor, forecastData.timeForecast.windcolor, forecastData.timeForecast.humiditycolor, forecastData.timeForecast.raincolor)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  getRecommendationColorClassForIcon(temp, wind, humidity, rain) {
    try {
      if (temp === 3 || wind === 3 || humidity === 3 || rain === 3) {
        return "w-icon-red";
      } else if (temp === 2 || wind === 2 || humidity === 2 || rain === 2) {
        return "w-icon-yellow";
      }
      return "w-icon-green";
    } catch (error) {
      console.error(error);
    }
  }
  getColorClass(recommendation) {
    try {
      switch (recommendation) {
        case 1:
          return "w-r-green";
        case 2:
          return "w-r-yellow";
        case 3:
          return "w-r-red";
      }
    } catch (error) {
      console.error(error);
    }
  }
  getRecommendationColorClass(temp, wind, humidity, rain) {
    if (temp === 3 || wind === 3 || humidity === 3 || rain === 3) {
      return "fa fa-times r-red";
    } else if (temp === 2 || wind === 2 || humidity === 2 || rain === 2) {
      return "fa fa-check r-yellow";
    }
    return "fa fa-check r-green";
  }

  getActivity(MaintID) {
    try {
      const assetId = MaintID;
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", assetId).subscribe((results: any) => {
        this.activity = results.features[0].attributes.Activity;
      });
    } catch (error) {
      console.error(error);
    }
  }
  deleteTask() {
    try {
      Swal.fire({
        title: this.translateService.instant("Select.createtask.deletetask"),
        text: this.translateService.instant("Select.createtask.deletetaskconfirmmsg"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant("Select.createtask.continuebtn")
      }).then((result) => {
        if (result.value) {
          const featurese = [];
          const features = new this.Graphic();
          features.setAttributes({
            OBJECTID: this.objGlobals.selectedTask.OBJECTID,
            WorkOrder: this.workorderID,
            WorkOrderType: this.wrkType,
            Status: this.wrkStatus,
            AssetName: this.asset[1].layerName,
            CreatedBy: this.createdBy,
            CreationDate: new Date(Date.now()).getTime(),
            Priority: parseInt(this.priority, 10),
            Region: this.region,
            Worker: this.worker,
            Location: this.location,
            Reason: this.reason,
            Activity: this.activity,
            EstimatedDuration: parseInt(this.duration, 10),
            WorkOrderDesc: this.desc,
            Comment: this.comments
          });
          featurese.push(features);
          this.ftrLyrTbl
            .applyEdits(null, null, featurese)
            .then(
              function () {
                this.close();
                this.objAssetDetailService.tasksModified.emit(true);
              }.bind(this)
            )
            .catch((error) => {
              console.log("===============================================");
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  activityChange(event) {
    try {
      this.activity = event;
      if (this.asset) {
        this.getFilteredFeaturesByTwoFieldSubscribe = this.genericLayerService
          .getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.activityDurationId, "ActivityCode", this.activity, "AssetClass", this.layerName)
          .subscribe(
            function (results: any) {
              if (results.features.length > 0) {
                const totalminutes = results.features[0].attributes.RequiredTime;
                this.duration = totalminutes;
                this.dminutes = this.duration % 60;
                this.dhours = (this.duration - this.dminutes) / 60;
              } else {
                this.duration = 60;
                this.dminutes = this.duration % 60;
                this.dhours = (this.duration - this.dminutes) / 60;
              }
            }.bind(this)
          );
      }
      const assetclassid = this.activityLyrDomines.filter(
        function (element) {
          if (element.name === this.layerName) {
            return element;
          }
        }.bind(this)
      )[0].id;
      this.getFilteredFeaturesByTwoFieldSubscribe = this.genericLayerService
        .getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.activityToolkitId, "Activity", this.activity, "AssetClass", assetclassid)
        .subscribe(
          function (results: any) {
            this.activityToolKitList = results.features;
          }.bind(this)
        );
    } catch (error) {
      console.error(error);
    }
  }
  saveWorkOrderDefaults() {
    try {
      // let workOrderDefaults;
      const workOrderDefaults = { WorkOrder: this.defWorkOrder, Worker: this.defWorker, Reason: this.defReason, Comments: this.defComments, Description: this.defDesc };
      localStorage.setItem("workOrderDefaults", JSON.stringify(workOrderDefaults));
      this.workorderID = this.defWorkOrder;
      this.worker = this.defWorker;
      this.reason = this.defReason;
      this.comments = this.defComments;
      this.desc = this.defDesc;
      this.modalService.dismissAll();
    } catch (error) {
      console.error(error);
    }
  }
  clearDef() {
    this.defComments = "";
    this.defDesc = "";
    this.defReason = "";
    this.defWorker = "";
    this.defWorkOrder = "";
  }
  dateTimeChanged() {
    try {
      if (new Date(this.scheduled).getHours() >= 18) {
        const today = new Date();
        if (this.scheduled.getDate() === today.getDate() && this.scheduled.getMonth() === today.getMonth() && this.scheduled.getFullYear() === today.getFullYear()) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          tomorrow.setHours(7, 0, 0);

          this.scheduled = tomorrow;
        } else {
          const dtime = new Date(this.scheduled.setHours(7, 0, 0));
          this.scheduled = dtime;
        }
      }
      if (new Date(this.scheduled).getHours() < 7) {
        const dtime = new Date(this.scheduled.setHours(7, 0, 0));
        this.scheduled = dtime;
      }
      if (this.scheduled && this.worker) {
        this.checkUserLoad(this.scheduled);
      }
      if (new Date(new Date(this.scheduled).getTime() + parseInt(this.duration, 10) * 60000).getHours() >= 18) {
        this.scheduled = new Date(new Date(this.scheduled).getTime() - parseInt(this.duration, 10) * 60000);
      }
      this.getWeatherInformation();
    } catch (error) {
      console.error(error);
    }
  }
  workerChanged(event) {
    this.worker = event;
    if (this.scheduled) {
      this.checkUserLoad(this.scheduled);
    }
  }
  checkUserLoad(date: Date) {
    try {
      const SheduledDate = date.toLocaleDateString();
      const tomorrow = new Date(date);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const SheduledNextDate = tomorrow.toLocaleDateString();
      this.disabledSave = true;
      const strCond = "SheduledDate >'" + SheduledDate + "' and SheduledDate <'" + SheduledNextDate + "'  and Worker";
      this.getFilteredFeaturesByFieldSubscribe = this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, strCond, this.worker).subscribe(
        function (results: any) {
          this.disabledSave = false;
          let totalDuration = 0;
          results.features.forEach((element) => {
            totalDuration += element.attributes.EstimatedDuration;
          });
          if (this.objGlobals.selectedTask && this.objGlobals.selectedTask.OBJECTID) {
            if (this.objGlobals.selectedTask.Worker !== this.worker) {
              totalDuration += this.duration;
            } else {
              totalDuration += 0;
            }
          } else {
            totalDuration += this.duration;
          }
          if (totalDuration > 480) {
            this.showUserOverLoadedMsg();
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  checkUserLayerAccess(clientId, userId, list, element) {
    try {
      // 25772
      this.getUserLayerAccessSubscribe = this.userService.getUserLayerAccess(clientId, userId).subscribe(
        (evt: any) => {
          let layeridstr;
          if (this.eventEmitersService.activeAssetFeature && this.eventEmitersService.activeAssetFeature[1]) {
            layeridstr = this.eventEmitersService.activeAssetFeature[1].lyrObj.layerId.toString();
          } else {
            if (
              this.esrimap.map._layers["sms-layers"].layerInfos.filter((layer) => {
                if (layer.name == this.objGlobals.selectedTask.AssetName) {
                  return layer;
                }
              }).length == 1
            ) {
              layeridstr = this.esrimap.map._layers["sms-layers"].layerInfos
                .filter((layer) => {
                  if (layer.name == this.objGlobals.selectedTask.AssetName) {
                    return layer;
                  }
                })[0]
                .id.toString();
            } else {
              console.log(this.currentUser);
              layeridstr = this.currentUser.serviceLyrInfo[this.objGlobals.selectedTask.AssetName.toLowerCase() + "Id"].toString();
            }
          }
          if (
            evt.filter(
              function (item: any) {
                if (item.gisLayerId == layeridstr && item.accessPrivilege == "Data Editor") {
                  return item;
                }
              }.bind(this)
            ).length > 0
          ) {
            this.clientUsersListByLayer.push(element);
          }
          // else { for (var i = 0; i < list.length; i++) { if (list[i]["userId"] === userId) { list.splice(i, 1); } } }
        },
        (error) => {
          console.log(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  findUserAccessByRoleRegionLayer() {
    try {
      // 25772
      if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
        if (this.asset && this.asset[1]) {
          const layerinfo = this.currentUser.layerLevelInfo.filter(
            function (layer) {
              if (parseInt(layer.gisLayerId, 10) === this.asset[1].lyrObj.layerId) {
                return layer;
              }
            }.bind(this)
          )[0];
          if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
            return true;
          }
          // else { return false; }
          return false;
        }
        // else {return true; }
        return true;
      }
      // else { return false; }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  openMaintenanceForm(content) {
    this.modalService.open(content, { windowClass: "modal-md-task" });
  }
  reasonChanged(event) {
    this.reason = event;
  }

  managedWorkerChanged(event) {
    this.defWorker = event;
  }
  managedReasonChanged(event) {
    this.defReason = event;
  }
  cancel() {
    this.modalService.dismissAll();
  }
}
