import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { AssetAttributesService } from "../../services/assetAttibutes.service";
import { GenericLayerService } from "../../services/genericLayer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-task-duration-defaults",
  templateUrl: "./task-duration.component.html",
  styleUrls: ["./task-duration.component.scss"]
})
export class TaskDurationComponent implements OnInit {
  @Input() FeatureTable;
  @Input() FeatureLayer;
  @Input() Graphic;
  private adFeatureTable;
  private adFeatureLayer;
  private updates = [];
  private add = [];
  private delete = [];
  private loading = true;
  private currentUser: IUser;
  public selectedLayer: any;
  selectedLayerObj;
  selectedLayerAct;
  private tableData: any;
  selectedRam;
  hours;
  minites;
  ftrLyrActivity;
  ftrLyrActivityDuration;

  layerdominedata;
  constructor(
    private renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private genericLayerService: GenericLayerService,
    private assetAttributesService: AssetAttributesService,
    private translateService: TranslateService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.ftrLyrActivityDuration = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityDurationId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    this.ftrLyrActivity.on(
      "Load",
      function (evt) {
        this.saveDomine(evt.layer.types, this.layerName);
      }.bind(this)
    );
    this.hours = 0;
    this.minites = 0;
  }
  saveDomine(data) {
    try {
      this.genericLayerService.getAllFeatures(this.currentUser.serviceLyrInfo.tableAliasId).subscribe((ret:any) => {
        const clientFeatures = ret.features.filter(feature => feature.attributes.IsAuthorized === "T" && (feature.attributes.GroupType === "Parent Table" || feature.attributes.GroupType === "Child Table"));
        const filteredData = data.filter(feature => clientFeatures.map(cf => cf.attributes.Name).includes(feature.name));
        this.layerdominedata = filteredData;
        this.selectedLayerObj = filteredData[0];
        this.selectedLayer = filteredData[0].name;
        this.selectedLayerAct = this.selectedLayerObj.domains.Activity.codedValues[0].code;
        this.onChangelayerAct();
      });
    } catch (error) {
      console.error(error);
    }
  }
  onChangelayerAct() {
    try {
      this.genericLayerService.getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.activityDurationId, "ActivityCode", this.selectedLayerAct, "AssetClass", this.selectedLayer).subscribe(
        function (results: any) {
          if (results.features.length > 0) {
            const totalminutes = results.features[0].attributes.RequiredTime;
            this.minites = totalminutes % 60;

            this.hours = (totalminutes - this.minites) / 60;
            console.log(results.features[0].attributes.RequiredTime);
          } else {
            this.minites = 0;
            this.hours = 0;
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  save() {
    try {
      this.genericLayerService.getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.activityDurationId, "ActivityCode", this.selectedLayerAct, "AssetClass", this.selectedLayer).subscribe(
        function (results: any) {
          this.updateRecords(results);
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  updateRecords(result) {
    try {
      const featurese = [];
      const features = new this.Graphic();
      let upsheduledTime = null;
      upsheduledTime = this.hours * 60 + this.minites;

      if (result.features.length === 0) {
        features.setAttributes({
          AssetClass: this.selectedLayer,
          ActivityCode: this.selectedLayerAct,
          CreatedBy: this.currentUser.username,
          RequiredTime: upsheduledTime
        });
        featurese.push(features);
        this.ftrLyrActivityDuration.applyEdits(
          featurese,
          null,
          null,
          //success
          () => {
            Swal.fire(this.translateService.instant("Userscreens.alerttaskduration.editsaved"));
          },
          //error
          () => {
            Swal.fire(this.translateService.instant("Userscreens.alerttaskduration.errorsavingedits"));
          }
        );
      } else {
        features.setAttributes({
          OBJECTID: result.features[0].attributes.OBJECTID,
          AssetClass: this.selectedLayer,
          ActivityCode: this.selectedLayerAct,
          CreatedBy: this.currentUser.username,
          RequiredTime: upsheduledTime
        });
        featurese.push(features);

        this.ftrLyrActivityDuration.applyEdits(
          null,
          featurese,
          null,
          //success
          () => {
            Swal.fire(this.translateService.instant("Userscreens.alerttaskduration.editupdated"));
          },
          //error
          () => {
            Swal.fire(this.translateService.instant("Userscreens.alerttaskduration.errorupdatingedits"));
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  onChangelayer() {
    this.selectedLayerObj = null;
    this.selectedLayerObj = this.layerdominedata.filter((data) => data.name === this.selectedLayer)[0];
    this.selectedLayerAct = null;
    this.selectedLayerAct = this.selectedLayerObj.domains.Activity.codedValues[0].code;
  }
}
