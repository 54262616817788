import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import Swal from "sweetalert2";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sms-baselifemodifier",
  templateUrl: "./baseLifeModifier.component.html",
  styleUrls: ["../retro-admin.component.scss"]
})
export class BaseLifeModifierClassComponent implements OnInit {
  @Input() map: any;
  @Input() FeatureTable;
  @Input() FeatureLayer;
  @Input() Graphic;
  private currentUser: IUser;
  public LayerList: any[];
  public layerListSelected;
  public RetroAdmin;
  public retroAdminSelected;
  private RetroBaseLifeTable;
  private DomainValues: any = [];
  private domainValuesCode = [];
  public BLFDomainValue;
  OnClicked = [];
  adFeatureLayer: any;

  constructor(private renderer: Renderer2, private authenticationService: AuthenticationService, private assetAttributesService: AssetAttributesService, private translateService: TranslateService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.assetAttributesService.getELUnquieField(this.currentUser.serviceLyrInfo.modifierConfigId, "AssetClass").subscribe((results: any) => {
      if (results.features.length > 0) {
        this.init(results);
      }
    });
    this.assetAttributesService.getDomainValues().subscribe((res: any) => {
      this.DomainValues = res.domains;
    });
    this.adFeatureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.modifierValueId);
  }

  init(res) {
    const result = res.features;
    this.LayerList = [];
    result.forEach((data) => {
      this.LayerList.push(data.attributes);
    });
  }

  onChangeLayerList() {
    try {
      this.RetroAdmin = [];
      this.clear();
      this.assetAttributesService.getELSheetingType(this.currentUser.serviceLyrInfo.baseLifeConfigId, "AssetClass", this.layerListSelected.AssetClass).subscribe((res: any) => {
        if (res.features.length > 0) {
          res.features.forEach((data) => {
            this.BLFDomainValue = data.attributes.BLF_Domain;
          });
        }
      });
      this.assetAttributesService.getELSheetingType(this.currentUser.serviceLyrInfo.modifierConfigId, "AssetClass", this.layerListSelected.AssetClass).subscribe((res: any) => {
        if (res.features.length > 0) {
          res.features.forEach((data) => {
            this.RetroAdmin.push(data.attributes);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  clear() {
    this.BLFDomainValue = null;
    const table = document.querySelector("table");

    for (let i = table.rows.length; i > 0; i--) {
      table.deleteRow(i - 1);
    }

    this.domainValuesCode = [];
    this.OnClicked = [];
  }

  onChangeAdminList() {
    this.RetroBaseLifeTable = [];

    this.assetAttributesService.getTableValues(this.currentUser.serviceLyrInfo.modifierValueId, "Modifier_Domain", this.retroAdminSelected.Modifier_Domain).subscribe((res: any) => {
      if (res.features.length > 0) {
        this.dataAssetFeature(res.features);
      }
    });
  }

  dataAssetFeature(val) {
    try {
      // 4-12-2020 Manikantha Start
      val.sort((a, b) => (a.attributes.BLF_Domain_Code > b.attributes.BLF_Domain_Code ? 1 : b.attributes.BLF_Domain_Code > a.attributes.BLF_Domain_Code ? -1 : 0));
      // 4-12-2020 Manikantha End
      let blfDomainCode = [];
      val.forEach((data) => {
        this.RetroBaseLifeTable.push(data.attributes);
        blfDomainCode.push(data.attributes.BLF_Domain_Code);
      });

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      blfDomainCode = blfDomainCode.filter(distinct);

      const modifierDomainCode = [];
      modifierDomainCode.push(this.RetroBaseLifeTable[0].BLF_Column);
      // 4-12-2020 Manikantha Start
      const modifierDomainCodeSort = [];
      this.RetroBaseLifeTable.filter((obj) => obj.BLF_Domain_Code.includes(this.RetroBaseLifeTable[0].BLF_Domain_Code)).forEach((data) => {
        modifierDomainCodeSort.push(data.Modifier_Domain_Code);
      });

      modifierDomainCodeSort.sort();

      for (const obj of modifierDomainCodeSort) {
        modifierDomainCode.push(obj);
      }

      const Endvalue = [];
      blfDomainCode.forEach((data) => {
        let retroBaseLifeTableVal = [];
        const subDomainValues = [];
        retroBaseLifeTableVal = this.RetroBaseLifeTable.filter((obj) => obj.BLF_Domain_Code === data);

        if (this.DomainValues.filter((obj) => obj.name.includes(this.BLFDomainValue))[0] && this.DomainValues.filter((obj) => obj.name.includes(this.BLFDomainValue))[0].codedValues) {
          this.domainValuesCode = this.DomainValues.filter((obj) => obj.name.includes(this.BLFDomainValue))[0].codedValues;
          this.domainValuesCode.forEach((dataVC) => {
            if (dataVC.code === data) {
              subDomainValues.push(dataVC.name);
            }
          });
          retroBaseLifeTableVal.sort((a, b) => (a.Modifier_Domain_Code > b.Modifier_Domain_Code ? 1 : b.Modifier_Domain_Code > a.Modifier_Domain_Code ? -1 : 0));
          retroBaseLifeTableVal.forEach((subdata) => {
            subDomainValues.push(subdata);
          });
          // subDomainValues.sort((a, b) => { return a.Modifier_Domain_Code - b.Modifier_Domain_Code; });
        }

        Endvalue.push({ subDomainValues });
      });

      this.GenerateTable(modifierDomainCode, Endvalue);
      // 4-12-2020 Manikantha End
    } catch (error) {
      console.error(error);
    }
  }

  // Create a table programmatically
  GenerateTable(modifierDomainCode, Endvalue) {
    try {
      const table = document.querySelector("table");
      table.innerHTML = "";
      this.generateTableHead(table, modifierDomainCode);
      this.generateTable(table, Endvalue);
    } catch (error) {
      console.error(error);
    }
  }
  generateTable(table, data) {
    try {
      for (const element of data) {
        const row = table.insertRow();
        element.subDomainValues.forEach((subDomineValdata: any, key) => {
          const cell = row.insertCell();

          if (key === 0) {
            const text = document.createTextNode(subDomineValdata);
            //Added for ADA
            cell.setAttribute("tabindex", "0");
            //
            cell.appendChild(text);
          } else {
            const input = document.createElement("input");
            input.setAttribute("id", subDomineValdata.OBJECTID);
            input.type = "number";
            input.style.cssText = "width: 40px";
            input.value = subDomineValdata.Adjust_Years;
            input.ariaLabel = subDomineValdata.Adjust_Years;
            cell.appendChild(input);
          }

          row.onclick = function (event) {
            this.OnClicked.push(event.srcElement.id);

            const distinct = (value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            };

            this.OnClicked = this.OnClicked.filter(distinct);
          }.bind(this);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  generateTableHead(table, data) {
    try {
      const thead = table.createTHead();
      const row = thead.insertRow();

      for (const key of data) {
        const th = document.createElement("th");
        //Added for ADA
        th.setAttribute("tabindex", "0");
        th.setAttribute("aria-label", key);
        //
        const text = document.createTextNode(key);
        th.appendChild(text);
        row.appendChild(th);
      }
    } catch (error) {
      console.error(error);
    }
  }

  SaveEdits() {
    try {
      Swal.fire({
        title: "Are you sure want to save data?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.SaveEditData();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  SaveEditData() {
    try {
      const saveData = [];
      this.RetroBaseLifeTable.forEach((data) => {
        // tslint:disable-next-line:prefer-for-of
        // for (let i = 0; i < this.OnClicked.length; i++) {
        //   if (data.OBJECTID.toString() === this.OnClicked[i]) {
        saveData.push([
          { OBJECTID: data.OBJECTID },
          { AssetClass: data.AssetClass },
          // eslint need to fix
          // eslint-disable-next-line camelcase
          { BLF_Domain: data.BLF_Column },
          // eslint-disable-next-line camelcase
          { BLF_Domain_Code: data.BLF_Domain_Code },
          // eslint-disable-next-line camelcase
          { Modifier_Domain: data.Modifier_Domain },
          // eslint-disable-next-line camelcase
          { Modifier_Domain_Code: data.Modifier_Domain_Code },
          { oldValue: data.Adjust_Years },
          {
            newValue: parseFloat((document.getElementById(data.OBJECTID.toString()) as HTMLInputElement).value)
          }
        ]);
        //   }
        // }
      });
      const APIDatasSave = [];
      const featuresSave = [];
      saveData.forEach((data) => {
        const featureSave = new this.Graphic();
        const updateAssetAttr: any = [];
        updateAssetAttr.OBJECTID = data[0].OBJECTID;
        // eslint need to fix
        // eslint-disable-next-line camelcase
        updateAssetAttr.Adjust_Years = data[7].newValue;
        updateAssetAttr.UpdatedBy = this.currentUser.username;
        updateAssetAttr.UpdatedOn = new Date(Date.now()).getTime();
        featureSave.setAttributes(updateAssetAttr);
        featuresSave.push(featureSave);
        const APIDataSave = {
          assetClass: "string",
          blFDomain: "string",
          blFDomainCode: "string",
          modifierDomain: "string",
          modifierDomainCode: "string",
          oldValue: 0,
          newValue: 0
        };
        APIDataSave.assetClass = data[1].AssetClass;
        APIDataSave.blFDomain = data[2].BLF_Domain;
        APIDataSave.blFDomainCode = data[3].BLF_Domain_Code;
        APIDataSave.modifierDomain = data[4].BLF_Domain;
        APIDataSave.modifierDomainCode = data[5].BLF_Domain_Code;
        APIDataSave.newValue = data[7].newValue;
        APIDataSave.oldValue = data[6].oldValue;
        APIDatasSave.push(APIDataSave);
      });
      this.adFeatureLayer.applyEdits(
        null,
        featuresSave,
        null,
        (res) => {
          this.assetAttributesService.postExpectedLifeValues(this.currentUser.userId, APIDatasSave).subscribe(
            (evt) => {
              Swal.fire({
                title: this.translateService.instant("Userscreens.alertbaselife.changesdone"),
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok"
              }).then((result) => {
                this.clear();
                this.layerListSelected = [];
                this.retroAdminSelected = [];
              });
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
