<div class="card">
  <div class="card-body">
    <div class="sms-model-height">
      <!-- TSDRSAM-856 By Manikantha -->
      <mat-form-field appearance="outline">
        <mat-label>{{ "Userscreens.assetdefaults.selectlyr" | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedLayer" (ngModelChange)="onChangelayer($event)">
          <mat-option [value]="table.attributes.OBJECTID" *ngFor="let table of tableData">
            {{ !scndlyr ? table.attributes.Alias : table.attributes.Alias_SL }}
          </mat-option>
        </mat-select>

        <div class="dropdown-icons">
          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
        </div>
      </mat-form-field>
      <div class="row pl-4 pr-4">
        <table tabindex="0" class="table table-hover" aria-label="Table of AssetDefaults">
          <thead>
            <tr *ngIf="assetCodeDefaults.length > 0">
              <th colspan="8">
                <div class="float-right">
                  <ng-select
                    *ngIf="assetCodeDefaults.length > 0"
                    style="width: 250px !important; background: white"
                    [(ngModel)]="filterVal"
                    [items]="assetCodeDefaults"
                    bindLabel="AssetCode"
                    bindValue="AssetCode"
                    placeholder="Type to filter"
                    [searchFn]="customSearchFn"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <span style="color: #000000"
                        ><strong>{{ item.AssetCode }}</strong></span
                      >
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <span style="color: #808084"
                        ><small>{{ item.DESCRIPTION }}</small></span
                      >
                    </ng-template>
                  </ng-select>
                </div>
              </th>
            </tr>
            <tr>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.assetcode' | translate">{{ "Userscreens.assetdefaultstable.assetcode" | translate }}</th>

              <th tabindex="0">Description</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.manufacturer' | translate">{{ "Userscreens.assetdefaultstable.manufacturer" | translate }}</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.material' | translate">{{ "Userscreens.assetdefaultstable.material" | translate }}</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.dimlength' | translate">{{ "Userscreens.assetdefaultstable.dimlength" | translate }}</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.dimwidth' | translate">{{ "Userscreens.assetdefaultstable.dimwidth" | translate }}</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.dimheight' | translate">{{ "Userscreens.assetdefaultstable.dimheight" | translate }}</th>
              <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetdefaultstable.mountheight' | translate">{{ "Userscreens.assetdefaultstable.mountheight" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let defaultAssetCode of assetCodeDefaults; let i = index">
              <tr *ngIf="!filterVal">
                <td tabindex="0">{{ defaultAssetCode.AssetCode }}</td>
                <td tabindex="0">{{ defaultAssetCode.DESCRIPTION }}</td>
                <td>
                  <input id="manufacturer_{{ i }}" aria-label="Edit Manufacturer" [(ngModel)]="assetCodeDefaults[i].Manufacturer" id="manufacturer_{{ i }}" class="inputCSS" value="{{ defaultAssetCode.Manufacturer }}" />
                </td>
                <td>
                  <select aria-label="Material Combo box" class="form-control" id="defaultCondition + i" [(ngModel)]="assetCodeDefaults[i].Material">
                    <option for="material" aria-label="materialOptions" *ngFor="let codeValue of codeValues" [value]="codeValue.name">
                      {{ codeValue.code }}
                    </option>
                  </select>
                </td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimLength" id="dimLength_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimLength }}" aria-label="Set Dimention Length" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimWidth" id="dimWidth_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimWidth }}" aria-label="Set Dimention Width" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimHeight" id="dimHeight_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimHeight }}" aria-label="Set Dimention Height" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].MountHeight" id="mountHeight_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.MountHeight }}" aria-label="Set Mounted Height" /></td>
              </tr>
              <tr *ngIf="filterVal && defaultAssetCode.AssetCode.toLowerCase().includes(filterVal.toLowerCase())">
                <td tabindex="0">{{ defaultAssetCode.AssetCode }}</td>
                <td tabindex="0">{{ defaultAssetCode.DESCRIPTION }}</td>
                <td>
                  <input id="manufacturer_{{ i }}" aria-label="Edit Manufacturer" [(ngModel)]="assetCodeDefaults[i].Manufacturer" id="manufacturer_{{ i }}" class="inputCSS" value="{{ defaultAssetCode.Manufacturer }}" />
                </td>
                <td>
                  <select aria-label="Material Combo box" class="form-control" id="defaultCondition + i" [(ngModel)]="assetCodeDefaults[i].Material">
                    <option for="material" *ngFor="let codeValue of codeValues" [value]="codeValue.name">
                      {{ codeValue.code }}
                    </option>
                  </select>
                </td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimLength" id="dimLength_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimLength }}" aria-label="Set Dimention Length" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimWidth" id="dimWidth_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimWidth }}" aria-label="Set Dimention Width" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].DimHeight" id="dimHeight_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.DimHeight }}" aria-label="Set Dimention Height" /></td>
                <td><input [(ngModel)]="assetCodeDefaults[i].MountHeight" id="mountHeight_{{ i }}" type="number" class="inputCSS" value="{{ defaultAssetCode.MountHeight }}" aria-label="Set Mounted Height" /></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3 float-right">
          <button class="btn btn-secondary mr-3" type="button" (click)="cancel()">{{ "Userscreens.assetdefaults.canceledits" | translate }}</button>
          <button class="btn btn-primary" type="button" (click)="save()">{{ "Userscreens.assetdefaults.saveedits" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
