<div class="card">
  <div class="card-body">
    <div class="container">
      <div class="selections" style="display: flex; flex-direction: column; max-width: 320px">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Userscreens.baselifemodifier.assetclass" | translate }}</mat-label>
          <mat-select [(ngModel)]="layerListSelected" (ngModelChange)="onChangeLayerList()">
            <mat-option [value]="layerList" *ngFor="let layerList of LayerList">
              {{ "Userscreens.assetattributedefalt." + layerList.AssetClass | translate }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Userscreens.baselifemodifier.baselifefld" | translate }}</mat-label>
          <input matInput readonly value="{{ BLFDomainValue }}" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "Userscreens.baselifemodifier.modifier" | translate }}:</mat-label>
          <mat-select [(ngModel)]="retroAdminSelected" (ngModelChange)="onChangeAdminList()">
            <mat-option [value]="retroAdmin" *ngFor="let retroAdmin of RetroAdmin">
              {{ retroAdmin.Modifier_Column }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
      </div>
      <div class="pb-2" id="assetDefaultsMainContent"></div>
      <div class="row">
        <table id="ExpectedLife" tabindex="0" class="table table-hover table-bordered" aria-label="table of baselifemodifier"></table>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <div class="row justify-content-end">
      <!-- <div class="col">
                <label>
                    {{'Userscreens.baselifemodifier.footer'|translate}}.
                </label>
            </div> -->

      <div class="col-auto">
        <button type="button" aria-label="saveedits" class="btn btn-secondary float-right" (click)="SaveEdits()">{{ "Userscreens.baselifemodifier.saveedits" | translate }}</button>
      </div>
    </div>
  </div>
</div>
