import { Component, OnInit, Input } from "@angular/core";
import { SystemReportsService } from "./system.reports.service";
import { AuthenticationService } from "../services/authentication.service";
import Swal from "sweetalert2";
@Component({
  selector: "app-sms-system-reports",
  templateUrl: "./system.reports.component.html",
  styleUrls: ["./system.reports.component.scss"]
})
export class SystemReportsComponent implements OnInit {
  public ActivityTool = false;
  public sheetingType = false;
  public ActivityByReason = false;
  public RetiredAssets = false;
  public AssetDetail = false;
  public employeeLog = false;
  public democratizeAssetInformation = false;
  public replaceYearAssetClass = false;
  public brushPickup = false;
  //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
  public customReportARTESP = false;
  public currentConditionSummary = false;
  public maintActivitySummary = false;
  @Input() map;
  currentUser: any;
  reportLists: any = [];

  constructor(public systemReportsService: SystemReportsService, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.systemReportsService.getSystemReport(this.currentUser.clientId).subscribe(
      (res: any) => {
        console.log(res);
        console.log();
        this.reportLists = res.sort(this.sortReportList);
      },
      (err) => {
        Swal.fire("Please contact administrator. API's is throwing an error");
      }
    );
  }

  reportOnChange(value) {
    try{
    switch (value) {
      case "Activity Totals":
        this.ActivityTool = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Sheeting Type Count":
        this.sheetingType = true;
        this.ActivityTool =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Maintenance Activity by Reason":
        this.ActivityByReason = true;
        this.sheetingType =
          this.ActivityTool =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Retired Assets":
        this.RetiredAssets = true;
        this.sheetingType =
          this.ActivityByReason =
          this.ActivityTool =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Asset Detail":
        this.AssetDetail = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.ActivityTool =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Employee Actions Logs":
        this.employeeLog = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.ActivityTool =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Democratize Asset Information":
        this.democratizeAssetInformation = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.ActivityTool =
          this.replaceYearAssetClass =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Projected replace year by Asset Class":
        this.replaceYearAssetClass = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.ActivityTool =
          this.brushPickup =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      case "Brush Pickup":
        this.brushPickup = true;
        this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.ActivityTool =
          this.replaceYearAssetClass =
          //added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
          this.customReportARTESP =
          this.maintActivitySummary =
          this.currentConditionSummary =
            false;
        break;
      //added by sowjanya JIRA ID:-48 on 27/09/2021
      case "Custom Report (ARTESP)":
        this.customReportARTESP = true;
        this.ActivityByReason =
          this.sheetingType =
          this.ActivityTool =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          this.maintActivitySummary =
          this.currentConditionSummary =
          false;
        break;
      //added by sowjanya JIRA ID-51 on 27/09/2021
      case "Maintenance Activity Summary":
        this.maintActivitySummary = true;
        this.currentConditionSummary =
          this.customReportARTESP =
          this.ActivityByReason =
          this.sheetingType =
          this.ActivityTool =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          false;
        break;
      //added by sowjanya JIRA ID-52 on 27/09/2021  
      case "Current Condition Summary":
        this.currentConditionSummary = true;
        this.customReportARTESP =
          this.ActivityByReason =
          this.sheetingType =
          this.ActivityTool =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.replaceYearAssetClass =
          this.brushPickup =
          this.maintActivitySummary =
          false;
        break;
      default:
        this.replaceYearAssetClass =
          this.sheetingType =
          this.ActivityByReason =
          this.RetiredAssets =
          this.AssetDetail =
          this.employeeLog =
          this.democratizeAssetInformation =
          this.ActivityTool =
          this.brushPickup =
            false;
    }
  } catch (error) {
    console.error(error);
  }
  }
  sortReportList(a, b) {
    try {
      if (a.reportName < b.reportName) {
        return -1;
      }
      if (a.reportName > b.reportName) {
        return 1;
      }
      return 0;
    } catch (error) {
      console.error(error);
    }
  }
}
