import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import Swal from "sweetalert2";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { CreateEditUserComponent } from "../create-edit-user/create-edit-user.component";
import { LoginTranslateService } from "src/app/shared/login-translation.service";
import { Observable } from "rxjs";

interface ClientList {
  clientID: number;
  clientName: string;
  primaryLanguageID: any;
  secondaryLanguageID: any;
}

interface UserDataList {
  active: number;
  clientId: number;
  firstName: string;
  lastName: string;
  roleId: number;
  userId: number;
  username: string;
  visible: number;
  languageID: number;
}

interface RoleList {
  roleId: number;
  roleName: string;
}

interface RegionList {
  userId: number;
  accessPrivilege: string;
  regionId: number;
  regionName: string;
  arcGISRegionId: number;
}

interface ILayerUser {
  gisLayerId: string;
  accessPrivilege: string;
}

@Component({
  selector: "app-sms-useradmin",
  templateUrl: "./useradmin.component.html",
  styleUrls: ["./useradmin.component.scss"]
})
export class UseradminComponent implements OnInit {
  //view child
  @ViewChild(CreateEditUserComponent) createEditUserForm: CreateEditUserComponent;
  modalReference: NgbModalRef;
  public currentUser$: Observable<IUser> = this.authenticationService.currentUser;
  public firstname;
  public lastname;
  public username;
  public pwd;
  public active;
  public clientId;
  public roleId;
  public userroleSelected;
  public userActiveSelected = 0;
  public userVisibilitySelected = 0;
  public Primarylangvalid;
  public Secodarylangvalid;
  public currentUser;
  userActive = [
    {
      id: 0,
      Value: "False"
    },
    {
      id: 1,
      Value: "True"
    }
  ];
  userVisibility = [
    {
      id: 0,
      Value: "False"
    },
    {
      id: 1,
      Value: "True"
    }
  ];
  LayerList = [];
  ClientList: ClientList[] = [];
  UserTableData: UserDataList[] = [];
  RoleList: RoleList[] = [];
  RegionTableData: RegionList[] = [];
  RegionsByClient = [];
  AssetClassByClient = [];
  RegionsListByClient = [];
  LayerListByUser = [];
  selectedUser = {};
  RegionRole = [
    {
      role: 1,
      roleName: this.translateService.instant("UserAdmin.DataEditor"),
      roleKey: "Data Editor" //TSDRSAM-731
    },
    {
      role: 2,
      roleName: this.translateService.instant("UserAdmin.ReadOnly"),
      roleKey: "Read Only" //TSDRSAM-731
    }
  ];
  userButton = false;
  assetClassButton = false;
  regionsButton = false;
  GlobalClientId = null;
  GlobalUserId = null;
  saved = 0;
  regionsListSelected;
  regionRoleSelected;
  assetRoleSelected;
  layerListSelected;
  selectedRowIndex = -1;
  LayersFromServices = [];
  textValue;
  agencyLanguage = [];
  agencyLanguageSelected;
  //Jira Id-731
  selectedRegionRole;
  selectedAssetRole;
  regionsSelected: any;
  layerSelected: any;
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    //TableOnClick
    if (event.target["localName"] === "tr" && event.target["id"].indexOf("existingUserRow_") == 0) {
      (<HTMLInputElement>event.target).click();
      let allAnchorTags = (<HTMLInputElement>event.target).parentNode.querySelectorAll("a");
      allAnchorTags.forEach((element) => {
        element.className = "RemoveBtnColor";
      });
      let anchorTags = (<HTMLInputElement>event.target).querySelectorAll("a");
      anchorTags.forEach((element) => {
        element.className = "RemoveBtnColor1";
      });
    }
    if (event.target["localName"] === "a" && (event.target["id"].indexOf("editExistingUserRow_") == 0 || event.target["id"].indexOf("removeExistingUserRow_") == 0)) {
      (<HTMLInputElement>event.target).click();
      if (event.target["id"].indexOf("editExistingUserRow_") == 0) {
        let allElements = document.querySelectorAll('[data-uadmin="yes"]');
        if (allElements.length > 0) {
          allElements.forEach((element) => {
            element.setAttribute("tabindex", "-1");
          });
        }
      }
    }
  }
  //
  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private userService: UserService,
    private genericService: GenericLayerService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public translate: LoginTranslateService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit() {
    this.currentUser$.subscribe((user) => {
      console.log("Getting curr user changes");
      if (user.clientId != null) {
        this.userService.getClientListUser(user.clientId).subscribe(
          (evt: any) => {
            this.ClientList = [];
            this.ClientList.push(evt);
            this.textValue = evt.clientName;
            if (user.serviceLyrInfo != null) {
              this.genericService.getAllFeatures(user.serviceLyrInfo.tableAliasId).subscribe(
                (res: any) => {
                  this.CallLayers(res.features);
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          },
          (error) => {
            Swal.fire(error.error);
          }
        );

        this.genericService.getClientList(user.clientId).subscribe((res: any) => {
          this.Primarylangvalid = res.primaryLanguageID;
          this.Secodarylangvalid = res.secondaryLanguageID;
        });
      }
      this.currentUser = user;
    });
  }
  ngAfterViewInit() {
    // For ADA story - by Subba
    let allElements = document.querySelectorAll('[data-uadmin="yes"]');
    if (allElements.length > 0) {
      allElements.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
  }
  getLanguages() {
    this.genericService.getAPILanguage().subscribe(
      (res: any) => {
        res.forEach((data) => {
          if (data.languageID === this.ClientList[0].primaryLanguageID) {
            this.agencyLanguage.push(data);
          }

          if (data.languageID === this.ClientList[0].secondaryLanguageID) {
            this.agencyLanguage.push(data);
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  CallLayers(res) {
    try {
      this.genericService.getLayerInforBasedOnService().subscribe(
        (results: any) => {
          this.userService.getRoleslist().subscribe((evt: RoleList[]) => {
            this.onChange();
            this.getLanguages();
            this.RoleList = [];
            evt.forEach(
              (data) => {
                this.RoleList.push(data);
              },
              (error) => {
                Swal.fire(error.error);
              }
            );
            this.RoleList.sort((a, b) => {
              const x = a.roleName.toLowerCase();
              const y = b.roleName.toLowerCase();

              if (x < y) {
                return -1;
              }

              if (x > y) {
                return 1;
              }

              return 0;
            });
          });
          const layers = [];
          results.layers.forEach((data: any) => {
            if (data.name === "Inventory") {
              data.subLayerIds.forEach((val: any) => {
                layers.push({ gisId: val, gisLayerName: results.layers[val].name });
              });
            }
          });
          results.tables.forEach((data) => {
            if (data.name === "Sign") {
              layers.push({ gisId: data.id, gisLayerName: data.name });
            } else if (data.name === "Light") {
              layers.push({ gisId: data.id, gisLayerName: data.name });
            }
          });
          layers.forEach((val: any) => {
            res.forEach((data: any) => {
              if (data.attributes.Name === val.gisLayerName) {
                if (data.attributes.IsAuthorized === "T") {
                  if (this.Primarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias });
                  } else if (this.Secodarylangvalid === this.currentUser.languageID) {
                    this.LayersFromServices.push({ gisId: val.gisId, gisLayerName: data.attributes.Alias_SL });
                  }
                }
              }
            });
          });
        },
        (error) => {
          Swal.fire(error.error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  onChange() {
    try {
      this.selectedRowIndex = -1;
      this.userButton = true;
      const newVal = this.currentUser.clientId;
      this.GlobalClientId = newVal;
      this.RegionTableData = [];
      this.LayerList = [];
      this.LayerListByUser = [];
      this.RegionsListByClient = [];
      this.CallUserTable(newVal);
      this.callLayersByClient();
      this.callRegionsByClient();
    } catch (error) {
      console.error(error);
    }
  }

  TableOnClick(event: Event, data: any, index: any) {
    try {
      this.selectedRowIndex = index;
      this.assetClassButton = true;
      this.regionsButton = true;
      const clientId = data.clientId;
      const userId = data.userId;
      this.GlobalUserId = data.userId;
      this.CallRegionsTable(clientId, userId);
      this.CallLayerTable(clientId, userId);
      //TSDRSAM-1863 Unwanted code removed
      //Added for ADA
      // let allAnchorTags = document.querySelectorAll('#exisitngUserTable a');
      // allAnchorTags.forEach(element => {
      //   element.className="RemoveBtnColor";
      // });
      // let anchorTags = document.querySelectorAll('#exisitngUserTable a');
      // let k=0;
      // anchorTags.forEach(element => {
      //   if(k==index){
      //     element.className="RemoveBtnColor1";
      //     return;
      //   }
      //   k++;
      // });
      //
    } catch (error) {
      console.error(error);
    }
  }

  EditUser(userdata) {
    try {
      this.selectedUser = {
        ...userdata
      };
      this.firstname = userdata.firstName;
      this.lastname = userdata.lastName;
      this.username = userdata.username;
      this.userActiveSelected = userdata.active;
      this.clientId = userdata.clientId;
      this.userroleSelected = userdata.roleId;
      this.userVisibilitySelected = userdata.visible;
      this.saved = userdata.userId;
      this.pwd = userdata.password;
      this.agencyLanguageSelected = userdata.languageID;
    } catch (error) {
      console.error(error);
    }
  }

  RemoveUser(userdata) {
    try {
      const ClientId = userdata.clientId;
      const userId = userdata.userId;
      Swal.fire({
        title: this.translateService.instant("Userscreens.alertuseradmin.removeuserconfirm"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant("Userscreens.alertuseradmin.deletebtn")
      }).then((result) => {
        if (result.value) {
          this.userService.deleteUserDataByClient(ClientId, userId).subscribe(
            (evt: any) => {
              if (evt.message === "Deleted Successfully") {
                Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.userdeletesuccess"));
                this.CallUserTable(this.GlobalClientId);
                this.LayerListByUser = [];
                this.RegionTableData = [];
              } else {
                Swal.fire(evt.message);
              }
            },
            (error) => {
              Swal.fire(error.error.message);
            }
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  SaveUser($event) {
    try {
      // if (this.firstname === undefined || this.firstname === "") {
      //   Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.enterfirstname"));
      // } else if (this.lastname === undefined || this.lastname === "") {
      //   Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.enterlastname"));
      // }
      // else
      console.log($event);

      const data = {
        username: "string",
        firstName: "string",
        lastName: "string",
        password: "string",
        roleId: 0,
        active: 0,
        visible: 0,
        languageID: 0
      };
      //  25606 reverted
      data.firstName = $event.firstName;
      data.lastName = $event.lastName;
      data.username = $event.username;
      data.languageID = $event.languageID;

      if ($event.password === undefined || $event.password === "") {
        data.password = "string";
      } else {
        data.password = $event.password;
      }

      data.roleId = $event.roleId;
      data.active = $event.active;
      data.visible = $event.visible;

      if (this.saved !== 0) {
        this.userService.UpdateUserDataByClient(this.GlobalClientId, this.saved, data).subscribe(
          (evt: any) => {
            if (evt.message === "User Updated") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.userupdated"));
              this.CallUserTable(this.GlobalClientId);
              this.d();
              //Added for ADA
              let allElements = document.querySelectorAll('[data-uadmin="yes"]');
              if (allElements.length > 0) {
                allElements.forEach((element) => {
                  element.setAttribute("tabindex", "0");
                });
              }
              //
            } else {
              Swal.fire(evt.message);
            }
          },
          (error) => {
            if (error.error.message === "invalid change user name" || error.error.message === "invalid user name") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.addspecialcharacter"));
            } else if (error.error.message === "Invalid Password") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.addspecialcharacter"));
            } else if (error.error.message === "invalid password has sequence,") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.pswdhassequence"));
            } else if (error.error.message === "Login.changepswd.InvalidPasswordLast24") {
              //TSDRSAM-1927
              Swal.fire(this.translate.instant("Login.changepswd.InvalidPasswordLast24"));
            } else {
              Swal.fire(error.error.message);
            }
          }
        );
      } else {
        this.userService.postUserDataByClient(this.GlobalClientId, data).subscribe(
          (evt: any) => {
            if (evt.message === "Inserted Successfully") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.newusercreated"));
              this.CallUserTable(this.GlobalClientId);
              this.d();
              //Added for ADA
              let allElements = document.querySelectorAll('[data-uadmin="yes"]');
              if (allElements.length > 0) {
                allElements.forEach((element) => {
                  element.setAttribute("tabindex", "0");
                });
              }
              //
            } else if (evt.message === "User Already Exists") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.usernameinuse"));
            } else {
              Swal.fire(evt.message);
            }
          },
          (error) => {
            if (error.error.message === "invalid username/password length is not match") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.usernamepswdlengthnotmatch"));
            } else if (error.error.message === "invalid username/password length is not match/password same as username") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.pswdsameasusername"));
            } else if (error.error.message === "invalid password length is not match") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.pswdlengthnotmatch"));
            } else if (error.error.message === "invalid username/password") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.addspclchar"));
            } else if (error.error.message === "Invalid Password") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.addspclcharpswd"));
            } else if (error.error.message === "invalid username") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.addspclcharusername"));
            } else {
              Swal.fire(error.error.message);
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  d() {
    document.getElementById("newUserModal").style.display = "none";
    this.ClearForms();
  }

  ClearForms() {
    this.firstname = "";
    this.lastname = "";
    this.username = "";
    this.pwd = "";
    this.userroleSelected = 1;
    this.userActiveSelected = 0;
    this.userVisibilitySelected = 0;
    this.saved = 0;
  }

  CallUserTable(newVal) {
    try {
      console.log("22");
      this.userService.getUserListByClient(newVal).subscribe(
        (evt: UserDataList[]) => {
          this.UserTableData = [];
          evt.forEach((data) => {
            if (data.roleId !== environment.SuperAdminId) {
              this.UserTableData.push(data);
            }
          });
          this.UserTableData.sort((a, b) => {
            const x = a.firstName.toLowerCase();
            const y = b.firstName.toLowerCase();

            if (x < y) {
              return -1;
            }

            if (x > y) {
              return 1;
            }

            return 0;
          });
        },
        (error) => {
          console.log(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  callLayersByClient() {
    try {
      this.LayerList = [];
      this.LayerList = this.LayersFromServices;
      this.LayerList.sort((a, b) => {
        const x = a.gisLayerName.toLowerCase();
        const y = b.gisLayerName.toLowerCase();

        if (x < y) {
          return -1;
        }

        if (x > y) {
          return 1;
        }

        return 0;
      });
      this.layerSelected = this.LayerList[0].gisLayerName;
    } catch (error) {
      console.error(error);
    }
  }

  callRegionsByClient() {
    try {
      this.RegionsListByClient = [];

      for (let i = 1; i < 5; i++) {
        this.genericService.getUniqueValuesByField(this.currentUser.serviceLyrInfo.regionId, "RegionName" + i).subscribe(
          (results: any) => {
            results.features.forEach((data) => {
              if (data.attributes["RegionName" + i] !== null) {
                this.RegionsListByClient.push({ RegionLevel: i, RegionName: data.attributes["RegionName" + i] });
                this.regionsSelected = this.RegionsListByClient[0].RegionName;
              }
            });
            this.RegionsListByClient.sort((a, b) => {
              const x = a.RegionName.toLowerCase();
              const y = b.RegionName.toLowerCase();

              if (x < y) {
                return -1;
              }

              if (x > y) {
                return 1;
              }

              return 0;
            });
          },
          (error) => {
            Swal.fire(error.error);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  CallRegionsTable(clientId, userId) {
    try {
      this.userService.getUserRegionAccess(clientId, userId).subscribe(
        (evt: RegionList[]) => {
          this.RegionTableData = [];
          evt.forEach((data) => {
            this.RegionTableData.push(data);
          });
          this.RegionTableData.sort((a, b) => {
            const x = a.regionName.toLowerCase();
            const y = b.regionName.toLowerCase();

            if (x < y) {
              return -1;
            }

            if (x > y) {
              return 1;
            }

            return 0;
          });
        },
        (error) => {
          this.RegionTableData = [];
          console.log(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  CallLayerTable(clientId, userId) {
    try {
      this.userService.getUserLayerAccess(clientId, userId).subscribe(
        (evt: ILayerUser[]) => {
          this.LayerListByUser = [];
          evt.forEach((data) => {
            this.LayersFromServices.forEach((val: any) => {
              if (data.gisLayerId === val.gisId.toString()) {
                this.LayerListByUser.push({ gisLayerId: val.gisId, gisLayerName: val.gisLayerName, accessPrivilege: data.accessPrivilege });
              }
            });
          });
          this.LayerListByUser.sort((a, b) => {
            const x = a.gisLayerName.toLowerCase();
            const y = b.gisLayerName.toLowerCase();

            if (x < y) {
              return -1;
            }

            if (x > y) {
              return 1;
            }

            return 0;
          });
        },
        (error) => {
          this.LayerListByUser = [];
          console.log(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  AddRegionsToUser() {
    if (this.regionsListSelected === undefined || this.regionsListSelected === "") {
      Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.selectregion"));
    } else if (this.regionRoleSelected === undefined || this.regionRoleSelected === "") {
      Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.selectrole"));
    } else {
      const RegionsLength = this.RegionTableData.length;

      if (this.currentUser.clientType === "BASIC") {
        if (RegionsLength > 0) {
          Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.basicaccregionlimit"));
        } else {
          this.SaveRegion();
        }
      } else if (this.currentUser.clientType === "PREMIUM") {
        if (RegionsLength > 1) {
          Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.premiumaccregionlimit"));
        } else {
          this.SaveRegion();
        }
      } else if (this.currentUser.clientType === "PROFESSIONAL") {
        if (RegionsLength > 11) {
          Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.professionalaccregionlimit"));
        } else {
          this.SaveRegion();
        }
      }
    }
  }

  SaveRegion() {
    try {
      let AddRegion = false;
      this.RegionTableData.forEach((evt) => {
        if (evt.regionName === this.regionsListSelected.RegionName) {
          AddRegion = true;
        }
      });

      if (!AddRegion) {
        const data = {
          regionName: "string",
          regionLevel: 0,
          accessPrivilege: "string"
        };
        data.regionName = this.regionsListSelected.RegionName;
        data.regionLevel = this.regionsListSelected.RegionLevel;
        data.accessPrivilege = this.RegionRole.find((regionRole) => regionRole.roleName == this.selectedRegionRole).roleKey; //Jira-731
        this.userService.postUserRegionAccess(this.GlobalClientId, this.GlobalUserId, data).subscribe(
          (evt: any) => {
            if (evt.message === "Updated Successfully" || evt.message === "Inserted Successfully") {
              Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.regionsuccesadd"));
              this.CallRegionsTable(this.GlobalClientId, this.GlobalUserId);
            } else {
              Swal.fire(evt.message);
            }
          },
          (error) => {
            Swal.fire(error.error.message);
          }
        );
      } else {
        Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.regionalreadyadded"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  RemoveRegion(Region) {
    try {
      this.userService.RemoveUserRegionAccess(this.GlobalClientId, this.GlobalUserId, Region.regionName, Region.regionLevel).subscribe(
        (evt: any) => {
          if (evt.message === "Deleted Successfully") {
            Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.regionsuccesremove"));
            this.CallRegionsTable(this.GlobalClientId, this.GlobalUserId);
          } else {
            Swal.fire(evt.message);
          }
        },
        (error) => {
          Swal.fire(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  AddAssetToUser() {
    try {
      if (this.layerListSelected === undefined || this.layerListSelected === "") {
        Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.selectasset"));
      } else if (this.assetRoleSelected === undefined || this.assetRoleSelected === "") {
        Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.selectrole"));
      } else {
        let AddAsset = false;
        this.LayerListByUser.forEach((evt) => {
          if (evt.gisLayerId === this.layerListSelected.gisId) {
            AddAsset = true;
          }
        });

        if (!AddAsset) {
          const data = {
            userId: 0,
            gisLayerId: "string",
            accessPrivilege: "string"
          };
          data.userId = this.GlobalUserId;
          data.gisLayerId = this.layerListSelected.gisId;
          data.accessPrivilege = this.RegionRole.find((regionRole) => regionRole.roleName == this.selectedAssetRole).roleKey;
          this.userService.postUserLayerAccess(this.GlobalClientId, this.GlobalUserId, data).subscribe(
            (evt: any) => {
              if (evt.message === "Updated Successfully" || evt.message === "Inserted Successfully") {
                Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.assetsuccessadd"));
                this.CallLayerTable(this.GlobalClientId, this.GlobalUserId);
              } else {
                Swal.fire(evt.message);
              }
            },
            (error: any) => {
              Swal.fire(error.error.message);
            }
          );
        } else {
          Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.assetalreadyadded"));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  RemoveAssetToUser(layerId) {
    try {
      this.userService.RemoveUserLayerAccess(this.GlobalClientId, this.GlobalUserId, layerId).subscribe(
        (evt: any) => {
          if (evt.message === "Deleted Successfully") {
            Swal.fire(this.translateService.instant("Userscreens.alertuseradmin.assetsuccessremove"));
            this.CallLayerTable(this.GlobalClientId, this.GlobalUserId);
          } else {
            Swal.fire(evt.message);
          }
        },
        (error: any) => {
          Swal.fire(error.error.message);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  //Jira Id-731
  onSelectRegionRole(event) {
    this.selectedRegionRole = event;
    this.regionRoleSelected = event;
  }
  //Jira Id-731
  onSelectAssetRole(event) {
    this.selectedAssetRole = event;
    this.assetRoleSelected = event;
  }
  //TSDRSAM-2081
  selectRegion(event) {
    this.regionsSelected = event.RegionName;
    this.regionsListSelected = event;
  }
  selectLayer(event) {
    this.layerSelected = event.gisLayerName;
    this.layerListSelected = event;
  }
}
