import { Injectable } from "@angular/core";
import { SMSHttpClient } from "../helpers/http-client";
import { HttpHeaders } from "@angular/common/http";
import { IRequestOptions } from "src/app/interface/requestOption";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class CrowdSourceInfoService {
  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };

  constructor(private httpClient: SMSHttpClient) {}

  // POST Method for create a new complaint*/
  CreateComplaint(obj) {
    return this.httpClient.post(environment.crowdComplaint, obj, this.responseType);
  }
  //  GET Method for getting all complaint details*/
  GetAllComplaint() {
    return this.httpClient.get(environment.crowdComplaint);
  }
  // POST Method for update complaint details*/
  UpdateComplaint(obj, complaintID, status) {
    return this.httpClient.post(environment.crowdComplaint + "/" + complaintID + "?status=" + status, obj, this.responseType);
  }
}
