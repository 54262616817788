import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CrewmanagementComponent } from "./crewmanagement/crewmanagement.component";
import { CrewComponent } from "./crewmanagement/crew/crew.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AssignmentComponent } from "./crewmanagement/crew/assignment/assignment.component";
import { OptimizerComponent } from "./crewmanagement/crew/optimizer/optimizer.component";
import { ShedulerComponent } from "./crewmanagement/crew/sheduler/sheduler.component";
import { FormsModule } from "@angular/forms";
import { SelectModule } from "../select/select.module";
import { NinePipe } from "./filterpipe";
import { HoursMinutePipe } from "./hourspipe";
import { TranslateModule } from "@ngx-translate/core";
import { CrewManagementService } from "./crewmanagement.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { SharedModule } from "../shared/shared.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
@NgModule({
  declarations: [CrewmanagementComponent, CrewComponent, AssignmentComponent, OptimizerComponent, ShedulerComponent, NinePipe, HoursMinutePipe],
  imports: [
    CommonModule,
    DragDropModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    SelectModule.forRoot(),
    MatDatepickerModule,
    MatInputModule,
    TranslateModule,
    SharedModule,
    MatSelectModule,
    MatIconModule,
    MatTabsModule
  ],
  providers: [CrewManagementService],
  exports: [CrewmanagementComponent, AssignmentComponent, OptimizerComponent, ShedulerComponent]
})
export class CrewmanagementModule {
  static forRoot(): ModuleWithProviders<CrewmanagementComponent> {
    return { ngModule: CrewmanagementComponent, providers: [] };
  }
}
