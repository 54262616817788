import { Component, OnInit } from "@angular/core";
// import { DualListComponent } from 'angular-dual-listbox';
import { DualListComponent } from "../dual-list/dual-list.component";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
@Component({
  selector: "sms-assetConfig",
  templateUrl: "./assetConfig.component.html",
  styleUrls: ["../administrator.component.scss"]
})
export class AssetConfigComponent implements OnInit {
  tab = 1;
  keepSorted = true;
  key: string;
  display: string;
  filter = false;
  source: any[];
  confirmed: any[];
  userAdd = "";
  disabled = false;

  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;
  TitlePage: any = "Asset List";
  SourceSideValue: any = "(Available)";
  DestinationSideValue: any = "(Assigned to Customer)";

  private sourceStations: any[] = [];

  private confirmedStations: any[] = [];
  private stations: any[] = [];
  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {}

  ngOnInit() {
    this.confirmedStations = new Array<any>();
    this.sourceStations = new Array<any>();

    if (this.adminGlobal.HideAsset.length > 0) {
      this.stations = this.adminGlobal.HideAsset;
      this.confirmedStations = this.adminGlobal.ShowAsset;
      this.sourceStations = JSON.parse(JSON.stringify(this.stations));
      this.useStations();
    } else {
      this.MMMAdminService.getLayerRecords(
        environment.proxy + this.adminGlobal.agencyDetails[0].webServiceUrl,
        this.adminGlobal.serviceLyrInfo.tableAliasId
      ).subscribe(
        (res: any) => {
          res.features.forEach((data) => {
            if (data.attributes.GroupType === "Parent Table" || data.attributes.GroupType === "Child Table") {
              this.adminGlobal.HideAsset.push(data.attributes);
              this.stations.push(data.attributes);

              if (data.attributes.IsAuthorized === "T") {
                this.adminGlobal.ShowAsset.push(data.attributes);
                this.confirmedStations.push(data.attributes);
              }
            }
          });
          this.sourceStations = JSON.parse(JSON.stringify(this.stations));
          this.useStations();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  private useStations() {
    this.key = "OBJECTID";
    this.display = "Alias";
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }

  filterBtn() {
    return this.filter ? "Hide Filter" : "Show Filter";
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return this.disabled ? "Enable" : "Disabled";
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }

  OnChange(event) {
    try {
      this.adminGlobal.ShowAsset = [];

      if (this.adminGlobal.agencyDetails[0].clientType === "BASIC") {
        if (event.length < 3) {
          this.adminGlobal.ShowAsset = event;
        } else {
          Swal.fire("Basic soltuion Tier allows upto 2 asset classes");
          this.source = this.adminGlobal.HideAsset;
          this.confirmed = [];
        }
      } else if (this.adminGlobal.agencyDetails[0].clientType === "PREMIUM") {
        if (event.length < 6) {
          this.adminGlobal.ShowAsset = event;
        } else {
          Swal.fire("Premium soltuion Tier allows upto 5 asset classes");
          this.source = this.adminGlobal.HideAsset;
          this.confirmed = [];
        }
      } else if (this.adminGlobal.agencyDetails[0].clientType === "PROFESSIONAL") {
        if (event.length < 11) {
          this.adminGlobal.ShowAsset = event;
        } else {
          Swal.fire("Professional soltuion Tier allows upto 10 asset classes");
          this.source = this.adminGlobal.HideAsset;
          this.confirmed = [];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
