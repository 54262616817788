export const TRANSLATIONS = [
  {
    en: "Search",
    es: "¡Hola mundo!",
    ru: "Привет мир!",
    hi: "खोज"
  },
  {
    en: "Regions",
    es: "rojo",
    ru: "красный",
    hi: "फिल्टर"
  },
  {
    en: "Layers",
    es: "rojo",
    ru: "красный",
    hi: "परत"
  },
  {
    en: "Select",
    es: "rojo",
    ru: "красный",
    hi: "चुनते हैं"
  },
  {
    en: "Redline",
    es: "rojo",
    ru: "красный",
    hi: "लाल रेखा"
  },
  {
    en: "Measure",
    es: "rojo",
    ru: "красный",
    hi: "उपाय"
  },
  {
    en: "Edit",
    es: "rojo",
    ru: "красный",
    hi: "संपादित करें"
  },
  {
    en: "Add",
    es: "rojo",
    ru: "красный",
    hi: "संपादित करें"
  },
  {
    en: "My Maintenance",
    es: "rojo",
    ru: "красный",
    hi: "संपादित करें"
  }
];
