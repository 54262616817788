<div class="sms-model-height">
  <div class="card">
    <div class="card-body">
      <ul role="list" ngbNav #attributeNav="ngbNav" (navChange)="activeTabChanged($event)" class="nav-pills" [(activeId)]="activeTab">
        <li [ngbNavItem]="1" [id]="tabsList[0].id" title="{{ 'Userscreens.labelassetattributedefalt.tablealias' | translate }}">
          <a ngbNavLink>{{ "Userscreens.labelassetattributedefalt.tablealias" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="card">
              <div class="card-body">
                <div class="tablealiasFixHead">
                  <table tabindex="0" *ngIf="result && layersSet && scndvisible" aria-label="table of TableAliases" id="table_scndvisible">
                    <thead class="thead-container">
                      <tr>
                        <th hidden>table of TableAliases</th>
                      </tr>
                      <tr class="th-row">
                        <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.tablename' | translate">{{ "Userscreens.assetattributedefalt.tablename" | translate }}</th>
                        <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.tablealias' | translate">{{ "Userscreens.assetattributedefalt.tablealias" | translate }}</th>
                        <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.secondryalias' | translate">{{ "Userscreens.assetattributedefalt.secondryalias" | translate }}</th>
                        <!-- <th></th>
                                  <th></th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let tablealias of tablealias; let lid = index">
                      <tr>
                        <!-- TSDRSAM-731 -->
                        <td>{{  tablealias["attributes"].Name }}</td>
                        <td class="alias-input">
                          <mat-form-field appearance="outline">
                            <mat-label>Alias</mat-label>
                            <input
                              matInput
                              class="input-sm"
                              id="{{ tablealias['attributes'].Alias + ' inputAliasName' }}"
                              type="text"
                              (change)="tabletextchange($event, tablealias)"
                              value="{{ tablealias['attributes'].Alias }}"
                              name="aliasname"
                              aria-label="Table Alias"
                            />
                          </mat-form-field>
                        </td>
                        <td class="alias-input">
                          <mat-form-field appearance="outline">
                            <mat-label>Alias</mat-label>
                            <input
                              matInput
                              class="input-sm"
                              id="{{ tablealias['attributes'].Alias_SL + ' inputAlias_SL' }}"
                              type="text"
                              (change)="tabletextchangesl($event, tablealias)"
                              value="{{ tablealias['attributes'].Alias_SL }}"
                              name="secondaryaliasname"
                              aria-label="Secondary Language Alias"
                            />
                          </mat-form-field>
                        </td>
                        <!-- <td>
                                      <td colspan="3"></td> -->
                      </tr>
                    </tbody>
                  </table>
                  <table tabindex="0" *ngIf="result && layersSet && !scndvisible" aria-label="table of TableAliases" id="table_!scndvisible">
                    <thead>
                      <tr>
                        <th hidden>table of TableAliases</th>
                      </tr>
                      <tr>
                        <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.tablename' | translate">{{ "Userscreens.assetattributedefalt.tablename" | translate }}</th>
                        <th tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.tablename' | translate">{{ "Userscreens.assetattributedefalt.tablealias" | translate }}</th>
                        <!-- <th></th>
                                  <th></th>
                                  <th></th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let tablealias of tablealias; let lid = index">
                      <tr>
                        <!-- TSDRSAM-731 -->
                        <td>{{ "Userscreens.assetattributedefalt." + tablealias["attributes"].Name | translate }}</td>

                        <td><input class="input-sm" id="inputsm" type="text" (change)="tabletextchange($event, tablealias)" value="{{ tablealias['attributes'].Alias }}" /></td>
                        <!-- <td>
                                      <td colspan="3"></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" [id]="tabsList[1].id" title="{{ 'Userscreens.labelassetattributedefalt.field' | translate }}">
          <a ngbNavLink>{{ "Userscreens.labelassetattributedefalt.field" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="card">
              <div class="card-body">
                <div class="card-title" style="padding-bottom: 1rem">
                  <label id="selLayerLabelID" for="exampleFormControlSelect2">{{ "Userscreens.assetattributedefalt.selectlayer" | translate }}</label>
                  <select aria-labelledby="selLayerLabelID" class="form-control" (change)="onChangelayer($event.target)" name="selectlayer" id="exampleFormControlSelect2">
                    <option *ngFor="let i of layersSet" value="{{ i }}">{{ "Userscreens.assetattributedefalt." + i | translate }}</option>
                  </select>
                </div>

                <div class="tableFixHead">
                  <table tabindex="0" *ngIf="result && layersSet && scndvisible">
                    <thead class="thcol">
                      <tr>
                        <th hidden>FieldAliases table</th>
                      </tr>
                      <tr>
                        <!-- Added by Abhilash for JIRA ID:-36 on 08/21/2021 -->
                        <td class="thCol1" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.field' | translate">{{ "Userscreens.assetattributedefalt.field" | translate }}</td>
                        <td class="thCol2" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.alias' | translate">{{ "Userscreens.assetattributedefalt.alias" | translate }}</td>
                        <td class="thCol3" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.secondryalias' | translate">{{ "Userscreens.assetattributedefalt.secondryalias" | translate }}</td>
                        <td class="thCol4" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.details' | translate">{{ "Userscreens.assetattributedefalt.details" | translate }}</td>
                        <td class="thCol5" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.query' | translate">{{ "Userscreens.assetattributedefalt.query" | translate }}</td>
                        <td class="thCol6" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.grid' | translate">{{ "Userscreens.assetattributedefalt.grid" | translate }}</td>
                        <td class="thCol7" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.required' | translate">{{ "Userscreens.assetattributedefalt.required" | translate }}</td>
                        <td class="thCol8" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.cardviewcolumn' | translate">{{ "Userscreens.assetattributedefalt.cardviewcolumn" | translate }}</td>
                        <!-- Added by Sowjanya for JIRA ID:-26 on 07/29/2021 -->
                      </tr>
                    </thead>
                  </table>
                  <!-- Added by Abhilash for JIRA ID:-36 on 08/18/2021 -->
                  <table>
                    <tbody>
                      <tr *ngIf="result && layersSet && scndvisible" [cdkDropListData]="selectedLyrFeaturesList" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                        <div
                          class="example-box"
                          cdkDragLockAxis="y"
                          cdkDragBoundary=".example-list"
                          *ngFor="let assetdetails of selectedLyrFeaturesList; let lid = index"
                          cdkDrag
                          (cdkDragStarted)="onDragStarted($event)"
                          (cdkDragEnded)="onDragEnded($event)"
                          [cdkDragData]="assetdetails"
                        >
                          <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
                          <td tabindex="0" class="tdCol1">
                            {{ assetdetails["attributes"].Field }}
                          </td>
                          <td class="tdCol2">
                            <input
                              class="input-sm"
                              id="inputsm"
                              type="text"
                              (change)="textchange($event, assetdetails)"
                              value="{{ assetdetails['attributes'].FieldAlias }}"
                              [attr.aria-label]="assetdetails['attributes'].FieldAlias"
                            />
                          </td>
                          <td class="tdCol3">
                            <input
                              class="input-sm"
                              id="inputsm"
                              type="text"
                              (change)="textchangesl($event, assetdetails)"
                              value="{{ assetdetails['attributes'].FieldAlias_SL }}"
                              [attr.aria-label]="assetdetails['attributes'].FieldAlias_SL"
                            />
                          </td>

                          <td class="thCol4">
                            <div class="custom-control custom-checkbox">
                              <!-- added by sowjanya for JIRA ID-26 on 10/28/2021 -->
                              <!--TSDRSAM-610,TSDRSAM-611 By Mani -->
                              <input
                                type="checkbox"
                                [checked]="assetdetails['attributes'].DetailsVisible == 1 ? true : false"
                                [disabled]="fieldDisabled(assetdetails['attributes'])"
                                class="custom-control-input"
                                id="{{ lid + 'chk1' }}"
                                (change)="changeCheckbox($event, assetdetails, 'DetailsVisible')"
                                value=""
                                name="detailsvisiblecheckbox"
                                aria-label="details"
                              />
                              <label for="detailsvisiblecheckbox" class="custom-control-label" for="{{ lid + 'chk1' }}"><span hidden>{{ lid + 'chk1' }}</span></label>
                            </div>
                          </td>
                          <td class="thCol5">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk2' }}"
                                (change)="changeCheckbox($event, assetdetails, 'QueryVisible')"
                                [checked]="assetdetails['attributes'].QueryVisible == 1 ? true : false"
                                value=""
                                name="queryvisiblecheckbox"
                                aria-label="query"
                              />
                              <label for="queryvisiblecheckbox" class="custom-control-label" for="{{ lid + 'chk2' }}"><span hidden>{{ lid + 'chk2' }}</span></label>
                            </div>
                          </td>
                          <td class="thCol6">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk3' }}"
                                (change)="changeCheckbox($event, assetdetails, 'GridVisible')"
                                [checked]="assetdetails['attributes'].GridVisible == 1 ? true : false"
                                value=""
                                name="gridvisiblecheckbox"
                                aria-label="grid"
                              />
                              <label for="gridvisiblecheckbox" class="custom-control-label" for="{{ lid + 'chk3' }}"><span hidden>{{ lid + 'chk3' }}</span></label>
                            </div>
                          </td>
                          <td class="thCol7">
                            <div class="custom-control custom-checkbox">
                              <!-- added by sowjanya for JIRA ID-26 on 10/28/2021 -->
                              <input
                                type="checkbox"
                                [disabled]="disabledcheckedfields.indexOf(assetdetails.attributes.Field) != -1 ? true : false"
                                class="custom-control-input"
                                id="{{ lid + 'chk4' }}"
                                (change)="changeCheckbox($event, assetdetails, 'IsRequired')"
                                [checked]="assetdetails['attributes'].IsRequired == 1 ? true : false"
                                value=""
                                name="isrequiredcheckbox"
                                aria-label="required"
                              />

                              <label for="isrequiredcheckbox" class="custom-control-label" for="{{ lid + 'chk4' }}"><span hidden>{{ lid + 'chk4' }}</span></label>
                            </div>
                          </td>
                          <td class="thCol8">
                            <!-- Added by Sowjanya for JIRA ID:-26 on 07/29/2021 -->
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk5' }}"
                                name="chk5"
                                (change)="changeCheckbox($event, assetdetails, 'IsCardviewColumnVisible')"
                                [checked]="assetdetails['attributes'].IsCardviewColumnVisible == 1 ? true : false"
                                value=""
                                name="iscardviewcolumnvisiblecheckbox"
                                aria-label="display in card view"
                              />
                              <label for="iscardviewcolumnvisiblecheckbox" class="custom-control-label" for="{{ lid + 'chk5' }}"><span hidden>{{ lid + 'chk5' }}</span></label>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>

                  <table tabindex="0" *ngIf="result && layersSet && !scndvisible" aria-lablel="table of FieldAliases" id="FieldAliases_!scndvisible">
                    <thead class="secAliasThCol">
                      <tr>
                        <th hidden>table of FieldAliases</th>
                      </tr>
                      <tr>
                        <!-- Added by Abhilash for JIRA ID:-36 on 08/21/2021 -->
                        <th class="secAliasThCol1" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.field' | translate">{{ "Userscreens.assetattributedefalt.field" | translate }}</th>
                        <th class="secAliasThCol2" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.alias' | translate">{{ "Userscreens.assetattributedefalt.alias" | translate }}</th>
                        <th class="secAliasThCol3" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.details' | translate">{{ "Userscreens.assetattributedefalt.details" | translate }}</th>
                        <th class="secAliasThCol4" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.query' | translate">{{ "Userscreens.assetattributedefalt.query" | translate }}</th>
                        <th class="secAliasThCol5" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.grid' | translate">{{ "Userscreens.assetattributedefalt.grid" | translate }}</th>
                        <th class="secAliasThCol6" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.required' | translate">{{ "Userscreens.assetattributedefalt.required" | translate }}</th>
                        <!-- Added by Sowjanya for JIRA ID:-26 on 07/29/2021 -->
                        <th class="secAliasThCol7" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.cardviewcolumn' | translate">
                          {{ "Userscreens.assetattributedefalt.cardviewcolumn" | translate }}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <!-- Added by Abhilash for JIRA ID:-36 on 08/18/2021 -->
                  <table>
                    <tbody>
                      <tr *ngIf="result && layersSet && !scndvisible" [cdkDropListData]="selectedLyrFeaturesList" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let assetdetails of selectedLyrFeaturesList; let lid = index" cdkDrag [cdkDragData]="assetdetails">
                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          <td tabindex="0" class="secAliasTdCol1">
                            {{ assetdetails["attributes"].Field }}
                          </td>
                          <td class="secAliasTdCol2">
                              
                              <input class="input-sm" id="{{ assetdetails['attributes'].FieldAlias + ' inputFieldAlias' }}"  type="text" (change)="textchange($event, assetdetails)" value="{{ assetdetails['attributes'].FieldAlias }}" name="secondaryaliasname" />
                          </td>
                          <td class="secAliasThdCol4">
                            <div class="custom-control custom-checkbox">
                              <!-- added by sowjanya for JIRA ID-26 on 10/28/2021  -->
                              <!--TSDRSAM-610,TSDRSAM-611 By Mani -->
                              <input
                                type="checkbox"
                                [disabled]="fieldDisabled(assetdetails['attributes'])"
                                class="custom-control-input"
                                id="{{ lid + 'chk1' }}"
                                (change)="changeCheckbox($event, assetdetails, 'DetailsVisible')"
                                [checked]="assetdetails['attributes'].DetailsVisible == 1 ? true : false"
                                value=""
                                name="detailsvisiblecheckbox"
                              />
                              <label class="custom-control-label" for="{{ lid + 'chk1' }}"></label>
                            </div>
                          </td>
                          <td class="secAliasThCol5">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk2' }}"
                                (change)="changeCheckbox($event, assetdetails, 'QueryVisible')"
                                [checked]="assetdetails['attributes'].QueryVisible == 1 ? true : false"
                                value=""
                                name="queryvisiblecheckbox"
                              />
                              <label class="custom-control-label" for="{{ lid + 'chk2' }}"></label>
                            </div>
                          </td>
                          <td class="secAliasThCol6">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk3' }}"
                                (change)="changeCheckbox($event, assetdetails, 'GridVisible')"
                                [checked]="assetdetails['attributes'].GridVisible == 1 ? true : false"
                                value=""
                                name="gridvisiblecheckbox"
                              />
                              <label class="custom-control-label" for="{{ lid + 'chk3' }}"></label>
                            </div>
                          </td>
                          <td class="secAliasThCol7">
                            <div class="custom-control custom-checkbox">
                              <!-- added by sowjanya for JIRA ID-26 on 10/28/2021 -->
                              <input
                                type="checkbox"
                                [disabled]="disabledcheckedfields.indexOf(assetdetails.attributes.Field) != -1 ? true : false"
                                class="custom-control-input"
                                id="{{ lid + 'chk4' }}"
                                (change)="changeCheckbox($event, assetdetails, 'IsRequired')"
                                [checked]="assetdetails['attributes'].IsRequired == 1 ? true : false"
                                value=""
                                name="isrequiredcheckbox"
                              />
                              <label class="custom-control-label" for="{{ lid + 'chk4' }}"></label>
                            </div>
                          </td>
                          <td class="secAliasThCol8">
                            <!-- Added by Sowjanya for JIRA ID:-26 on 07/29/2021 -->
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="{{ lid + 'chk5' }}"
                                name="chk5"
                                (change)="changeCheckbox($event, assetdetails, 'IsCardviewColumnVisible')"
                                [checked]="assetdetails['attributes'].IsCardviewColumnVisible == 1 ? true : false"
                                value=""
                              />
                              <label class="custom-control-label" for="{{ lid + 'chk5' }}"></label>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" [id]="tabsList[2].id" title="{{ 'Userscreens.labeltasktattribute.title' | translate }}">
          <a ngbNavLink>{{ "Userscreens.labeltasktattribute.title" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="card">
              <div class="card-body">
                <div class="tableFixHead">
                  <table tabindex="0" *ngIf="task" aria-lablel="table of TaskAttribute">
                    <thead>
                      <tr>
                        <th class="taskAttrCheckboxf" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.fieldname' | translate">{{ "Userscreens.taskattribute.fieldname" | translate }}</th>
                        <!-- JIRA ID:-28  Closed and Open Column-->
                        <th class="taskAttrCheckbox1" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.Closed' | translate">{{ "Userscreens.taskattribute.Closed" | translate }}</th>
                        <th class="taskAttrCheckbox2" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.Open' | translate">{{ "Userscreens.taskattribute.Open" | translate }}</th>
                        <th class="taskAttrCheckbox3" tabindex="0" [attr.aria-label]="'' + 'Userscreens.assetattributedefalt.IsRequired' | translate">{{ "Userscreens.taskattribute.IsRequired" | translate }}</th>
                        <!-- <th>{{'Userscreens.taskattribute.IsCreateFormVisible'|translate}}</th> -->
                      </tr>
                    </thead>
                    <!-- </table>
                          <table *ngIf="task"> -->
                    <tbody>
                      <tr *ngFor="let layerf of task.features; let lid = index">
                        <td tabindex="0" class="taskAttrtdCheckboxf">{{ layerf["attributes"].Field }}</td>
                        <td class="taskAttrtdCheckbox1">
                          <div class="custom-control custom-checkbox" style="margin-left: 60px">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="{{ lid + 'taskchk2' }}"
                              (change)="changeTaskCheckbox($event, layerf, 'ForStatusClosed')"
                              [checked]="layerf['attributes'].ForStatusClosed == 1 ? true : false"
                              value=""
                              name="forstatusclosedcheckbox"
                            />
                            <label class="custom-control-label" for="{{ lid + 'taskchk2' }}"><span hidden>{{ lid + 'taskchk2' }}</span></label>
                          </div>
                        </td>
                        <td class="taskAttrtdCheckbox2">
                          <div class="custom-control custom-checkbox" style="margin-left: 60px">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="{{ lid + 'taskchk3' }}"
                              (change)="changeTaskCheckbox($event, layerf, 'ForStatusOpen')"
                              [checked]="layerf['attributes'].ForStatusOpen == 1 ? true : false"
                              value=""
                              name="forstatusopencheckbox"
                            />

                            <label class="custom-control-label" for="{{ lid + 'taskchk3' }}"><span hidden>{{ lid + 'taskchk3' }}</span></label>
                          </div>
                        </td>

                        <td class="taskAttrtdCheckbox3">
                          <div class="custom-control custom-checkbox" style="margin-left: 20px">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="{{ lid + 'taskchk4' }}"
                              (change)="changeTaskCheckbox($event, layerf, 'IsRequired')"
                              [checked]="layerf['attributes'].IsRequired == 1 ? true : false"
                              value=""
                              name="taskisrequiredcheckbox"
                            />

                            <label class="custom-control-label" for="{{ lid + 'taskchk4' }}"><span hidden>{{ lid + 'taskchk4' }}</span></label>
                          </div>
                        </td>
                        <!-- <td>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="{{lid +'taskchk6' }}"
        (change)="changeTaskCheckbox($event,layerf,'IsCreateFormVisible')"
        [checked]="layerf['attributes'].IsCreateFormVisible == 1 ?true:false" value="">
      <label class="custom-control-label" for="{{lid +'taskchk6' }}"></label>
    </div>                  
  </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="attributeNav"></div>
    </div>

    <div class="card-footer text-right">
      <!-- <button type="button" class="btn btn-secondary" (click)="cancelEdits()">Cancel Edits</button> -->
      <button type="button" class="btn btn-primary" (click)="SaveEdits()">
        {{ "Userscreens.assetattributedefalt.saveedits" | translate }}
      </button>
    </div>
  </div>
</div>
