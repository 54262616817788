import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GenericLayerService } from "../services/genericLayer.service";
import { SMSHttpClient } from "../helpers/http-client";
import { HttpClient } from "@angular/common/http";
import { ETLJob, ETLJobDetailRequest, JobReviewedRequest } from "../change-report/change-report.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { GISHttpClient } from "../helpers/gishttp-client";

@Injectable()
export class MMMAdminService {
  private etlJobListSubject = new BehaviorSubject<ETLJob[]>(undefined);
  private etlJobDetailsSubject = new BehaviorSubject<any>(undefined);
  
  constructor(private httpClient: SMSHttpClient, private httpclient: GISHttpClient,private httpclienttest: HttpClient, private genericService: GenericLayerService) {}

  getSystemReport() {
    return this.httpClient.get(environment.getReport);
  }
  getLayerRecords(URL, LayerID) {
    return this.httpclient.get(URL + environment.featureServer + LayerID + environment.queryAllPostFixGeomFalse);
  }
  getAllLayersInformation(url) {
    console.log("-------------Mani---------------------------------------");
    console.log(url + "MapServer?f=pjson");
    return this.httpclient.get(url + "MapServer?f=pjson");
  }

  getClientList() {
    return this.httpClient.get(environment.getClientlist);
  }
  postClientList(Obj) {
    return this.httpClient.post(environment.getClientlist, Obj);
  }
  updateClientList(Obj, ClientId) {
    return this.httpClient.post(environment.getClientlist + "/" + ClientId, Obj);
  }

  getAPILanguage() {
    return this.httpClient.get(environment.getLanguages);
  }

  postAPILanguage(obj) {
    return this.httpClient.post(environment.getLanguages, obj);
  }

  deleteAPILanguage(languageID) {
    return this.httpClient.delete(environment.getLanguages + "?languageID=" + languageID);
  }

  getSolutionsTiers() {
    return this.httpClient.get(environment.getSolutionTiers);
  }

  getJSON(jsonFile) {
    return this.httpclient.get(jsonFile);
  }

  getSystemReportByclientId(clientId) {
    return this.httpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getReport);
  }

  postSystemReportByclientId(clientId, Obj) {
    return this.httpClient.post(environment.getClientlist + "/" + clientId + "/" + environment.getReport + Obj, null);
  }

  postTOC(file, ClientType, Language, Device) {
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post(environment.getTOC + "?ClientType=" + ClientType + "&Language=" + Language + "&Device=" + Device, formData);
  }

  getDomainValues(URL, LayerID) {
    return this.httpclient.get(URL + environment.featureServer + "queryDomains?layers=%5B" + LayerID + "%5D&f=pjson");
  }

  getAllFeatures(URL, lyrId) {
    return this.httpclient.get(URL + environment.featureServer + lyrId + environment.queryAllPostFixGeomFalse);
  }

  getDomainValuesFields(URL, LayerID) {
    return this.httpclient.get(URL + environment.featureServer + LayerID + "?f=pjson");
  }

  //Added by Sowjanya for TSDRSAM-71
  fileUpload(filecontent) {
    const formData = new FormData();
    formData.append("file",filecontent);
    formData.append("f","JSON");
    return this.httpclient.post(environment.proxy + environment.customerNameService+"/uploads/upload",formData, {responseType: 'json'});
  }

  //fetches ETL Job summaries from the server
  fetchETLJobSummary() : Observable<ETLJob[]>{
    return this.httpClient.post(environment.GetETLJobSummary, null).pipe(
      switchMap((rawData : any) => {
          const list : ETLJob[] = [];
          rawData.tblETLJobSummary.map((job: ETLJob) => {
            list.push(job);
          })

          this.etlJobListSubject.next(list);
          return this.etlJobListSubject.asObservable();
      })
    );
  }

  //fetches ETL Job details from the server
  getETLJobDetails(request : ETLJobDetailRequest) : Observable<any>{
    return this.httpClient.post(environment.GetETLJobDetails, request).pipe(
      map((rawData : any) => {
          this.etlJobDetailsSubject.next(rawData);
          return rawData;
      })
    );
  }

  //marks ETL Job as reviewed by current user at current timestamp
  markJobAsReviewed(request : JobReviewedRequest) : Observable<ETLJob[]>{
    return this.httpClient.post(environment.MarkJobAsReviewed, request).pipe(
      map(() => {
        const currentValue = this.etlJobListSubject.value;

        //construct a new list with updated reviewed by information for the updated job Id to push to the behavior subject.
        const updatedJobSummary = currentValue.map(obj => {
          if (obj.jobId === request.jobId) {
            return {...obj, reviewDate: new Date(), reviewComments : request.reviewComments, reviewedBy : request.reviewedBy};
          }
          return obj;
        });

        this.etlJobListSubject.next(updatedJobSummary);
        return updatedJobSummary;
      })
    );
  }

  //returns the behavior subject if it is not null or makes an http request if it is
  getJobDetails(): Observable<ETLJob[]> {
    if(this.etlJobListSubject.value == null){
      return this.fetchETLJobSummary();
    }
    return this.etlJobListSubject.asObservable();
  }
}
