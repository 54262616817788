import { Component, OnInit, Input, Output, EventEmitter, Renderer2, HostListener, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "../services/authentication.service";
import { IUser } from "../interface/user";
import { EsriMapComponent } from "../esri-map/esri-map.component";
import { environment } from "src/environments/environment";
import { Globals } from "src/app/select/globals";
// #TFS 25016
import { EventEmitersService } from "../services/eventEmiters.service";
// #TFS 25016
import Swal from "sweetalert2";
import { faInfo, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import version from "../../../package.json";
import { MMMAdminService } from "../administrator/administrator.reports.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
@Component({
  selector: "sms-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @ViewChild("adminTabs", { static: false }) tabs;
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["id"] === "more") {
      (<HTMLInputElement>event.target).click();
    }
  }
  constructor(
    private global: Globals,
    private renderer: Renderer2,
    private EsriMapComponent: EsriMapComponent,
    private translateService: TranslateService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    public auth: AuthenticationService,
    // #TFS 25016
    private eventEmitersService: EventEmitersService, // #TFS 25016,
    private adminService: MMMAdminService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.currentUser = this.auth.currentUserValue;
    translateService.setDefaultLang("1");
    translateService.use(this.currentUser.languageID);
    // translateService.addLangs(['en', 'es']);
  }

  @Input() FeatureLayer: any;
  @Input() Query: any;
  @Input() Graphic: any;
  @Input() FeatureTable: any;
  @Input() QueryTask: any;
  @Input() map: any;
  @Input() TimeExtent;
  @Input() SimpleLineSymbol;
  @Input() SimpleRenderer;
  @Input() Color;
  @Input() TimeSlider;
  @Input() TimeInfo;
  @Input() registry;
  @Input() Popup;
  @Input() domConstruct;
  @Input() Geoprocessor;
  @Output() headerOptionEvent = new EventEmitter<string>();
  @Output() showCard = new EventEmitter();
  @Output() closeCrowdSourceBuilder = new EventEmitter();
  @Output() createTaskforAsset = new EventEmitter();
  @Output() crowdsource = new EventEmitter();

  public isCollapsed = true;
  jobSummarySubscription: Subscription;
  version = 1;
  notificationCount = 0;
  asstClickedES = true;
  currentJustify = "justified";
  currentUser: IUser = {} as IUser;
  dataEditorSettings;
  inBounds = true;
  activeTab = "";
  faInfo = faInfo;
  faQuestion = faQuestion;
  faQuesitonCircle = faQuestionCircle;
  setti = false;
  admi = false;
  ramhelp;
  appVersion = version.version;
  showAdministration() /** Show Related Assets Accordian in card View */ {
    this.asstClickedES = !this.asstClickedES;
  }

  closeBuilder(event: any) {
    this.closeCrowdSourceBuilder.emit(event);
  }

  createTask(event: any) {
    this.createTaskforAsset.emit(event);
  }

  crowdSource() {
    this.crowdsource.emit();
  }

  optionClicked(event: any, type: string, content?) {
    if (type !== "more") this.isCollapsed = true;
    const className = "i-active";
    const hasClass = event.target.parentElement.classList.contains(className);
    if (this.activeTab === type && !hasClass) {
      event.target.parentElement.classList.add("i-active");
    } else if (this.activeTab === type) {
      this.activeTab = "";
    } else if (type !== "crowdSource") {
      this.activeTab = type;
    }
    // #TFS 25016
    this.eventEmitersService.setSelectedFeaturesOnMap(null);
    // #TFS 25016
    // tslint:disable-next-line:only-arrow-functions

    if (type === "add") {
      //TODO: Review this timeout to remove it to wait until setShowCard is set.
      if (this.eventEmitersService.addNewParent === false) {
        this.eventEmitersService.setShowCard(false);
        setTimeout(() => {
          this.eventEmitersService.setShowCard(true);
          this.eventEmitersService.addNewParent = true;
          this.eventEmitersService.setSelectedFeaturesOnMap(null);
        }, 1000);
      }
    } else {
      this.eventEmitersService.setShowCard(false);
    }
    if (hasClass) {
      if (type === "administrator") {
        content.hide();
      }

      if (type === "settings") {
        this.global.content = content;
        if (content) {
          content.hide();
        }
      }

      if (type !== "more") {
        this.renderer.removeClass(event.target, className);
      }

      if (type === "add") {
        this.showCard.emit(false);
      }
    } else {
      if (type === "administrator") {
        this.hideOtherComponent();
        this.showCard.emit(false);
        this.EsriMapComponent.closeMoreToggle();
        this.EsriMapComponent.invokePopup("");
        this.global.hideAdmin = content;
        this.global.adminstyle = event.target;

        if (this.global.hideSetting !== undefined) {
          this.global.hideSetting.hide();

          if (this.global.settingStyle !== undefined) {
            this.renderer.removeClass(this.global.settingStyle, className);
          }
        }

        content.show();
      }

      if (type === "settings") {
        this.EsriMapComponent.closeMoreToggle();
        this.EsriMapComponent.invokePopup("");
        this.hideOtherComponent();
        this.showCard.emit(false);
        this.global.hideSetting = content;
        this.global.content = content;
        this.global.settingStyle = event.target;

        if (this.global.hideAdmin !== undefined) {
          this.global.hideAdmin.hide();

          if (this.global.adminstyle !== undefined) {
            this.renderer.removeClass(this.global.adminstyle, className);
          }
        }

        //content.show();
      }
      if (type == "add") {
        this.showCard.emit(true);
      }
    }

    if (type !== "administrator" && type !== "settings" && type !== "crowdSource") {
      if (this.global.hideSetting !== undefined) {
        this.global.hideSetting.hide();

        if (this.global.settingStyle !== undefined) {
          this.renderer.removeClass(this.global.settingStyle, className);
        }
      }

      if (this.global.hideAdmin !== undefined) {
        this.global.hideAdmin.hide();

        if (this.global.adminstyle !== undefined) {
          this.renderer.removeClass(this.global.adminstyle, className);
        }
      }

      if (type === "more") {
        this.showCard.emit(false);
        this.eventEmitersService.setEnableSingleClick(true);
        this.hideOtherComponent();
      }
      this.headerOptionEvent.emit(type);
    } else {
      this.headerOptionEvent.emit(type);
    }

    if (type === "about") {
      Swal.fire({
        imageUrl: "././assets/images/logo30px.svg",
        imageAlt: "3M RSAM",
        html: "<hr> <br> <h2>Version " + this.appVersion + "</h2><br>",
        confirmButtonText: "OK",
        inputAttributes: {
          "aria-label": "control not in use"
        }
      });
      this.eventEmitersService.setEnableSingleClick(true);
    } else {
      this.eventEmitersService.setShowCard(false);
      this.eventEmitersService.addNewParent = false;
    }
  }

  ngOnInit(): void {
    this.dataEditorSettings = JSON.parse(localStorage.getItem("dataEditorSettings"));
    //creates a subscription to the result of the getJobDetailsMethod() in the service it returns the most recent value from the behavior subject and reads all updates until destroyed.
    this.jobSummarySubscription = this.adminService
      .getJobDetails()
      .pipe(filter((data) => data !== null))
      .subscribe((data) => {
        this.notificationCount = 0;
        for (const job of data) {
          if (job.reviewDate == null && job.needsReview != null) {
            this.notificationCount++;
          }
        }
      });

    this.ramhelp = environment.apiBaseUrl + environment.helproot;
  }

  ngOnDestroy(): void {
    this.jobSummarySubscription.unsubscribe();
    console.log(this.ramhelp);
    console.log(this.currentUser);
    console.log(this.appVersion);
  }

  // for ADA story - by Subba
  ngAfterViewInit() {
    const mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
    if (mainPageTools.length > 0) {
      mainPageTools.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  OpenPopup(content: any) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  logout() {
    this.auth.logout(this.currentUser.clientId, this.currentUser.userId);
  }

  capturescreen() {
    return this.EsriMapComponent.capturescreen();
  }
  hideOtherComponent() {
    this.EsriMapComponent.showSelect =
      this.EsriMapComponent.showSearch =
      this.EsriMapComponent.showLayers =
      this.EsriMapComponent.showFilter =
      this.EsriMapComponent.showMeasure =
      this.EsriMapComponent.showAdd =
      this.EsriMapComponent.showRedlineDraw =
      this.EsriMapComponent.closeHelpVar =
      this.EsriMapComponent.closeQueryBuilderVar =
      this.EsriMapComponent.DataEditor =
        false;
  }

  clicksetting() {
    this.setti = !this.setti;
    this.hideOtherComponent();

    if (document.getElementById("more").classList.contains("i-active")) {
      document.getElementById("more").classList.remove("i-active");
    }
    if (document.getElementById("select-new").classList.value.includes("i-active")) {
      document.getElementById("select-new").classList.remove("i-active");
      document.getElementById("setting-icon").classList.remove("i-active");
      document.getElementById("setting-icon-text").classList.remove("i-active");
      document.getElementById("select-new").parentElement.classList.remove("i-active");
    }
    //Added for ADA
    const mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
    const bMapTools = document.querySelectorAll('[data-BMapTabbing="yes"]');
    if (bMapTools.length > 0) {
      bMapTools.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
    mainPageTools.forEach((element) => {
      element.setAttribute("tabindex", "0");
    });
  }
  catch(error) {
    console.error(error);
  }

  clickadmin() {
    this.admi = !this.admi;
    this.hideOtherComponent();

    if (document.getElementById("more").classList.contains("i-active")) {
      document.getElementById("more").classList.remove("i-active");
    }
  }
}
