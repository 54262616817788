import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Globals } from "../select/globals";
import { ILayer } from "../interface/layer";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { EventEmitersService } from "../services/eventEmiters.service";
import { GenericMapMethodsService } from "../services/generic-map-methods.service";
import { QueryLayers } from "../services/queryLayers.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { QueryBuilder } from "../services/queryBuilderService.service";
@Component({
  selector: "sms-select-tool",
  templateUrl: "./select-tool.component.html",
  styleUrls: ["./select-tool.component.scss"]
})
export class SelectToolComponent implements OnInit {
  constructor(
    public objGlobals: Globals,
    private authenticationService: AuthenticationService,
    private queryLayerService : QueryLayers,
    config: NgbModalConfig,
    public eventEmitersService: EventEmitersService,
    public genericMapMethodsService: GenericMapMethodsService,
    public translateService: TranslateService,
    public qbService: QueryBuilder,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  // event Emitter done
  @Output() data = new EventEmitter();

  @Output() showGrid = new EventEmitter();
  @Output() showCard = new EventEmitter();
  @Output() isOpenCard = new EventEmitter();
  @Output() showmaintenanceViewer = new EventEmitter();

  // @Output() category = new EventEmitter();
  @Output() index = new EventEmitter();
  @Output() layerIndex = new EventEmitter();

  @Input() map: any;
  @Input() all: any;
  @Input() SimpleFillSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() Query: any;
  @Input() QueryTask: any;
  @Input() drawTool: any;
  @Input() Color: any;
  @Input() Graphic: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() FeatureLayer: any;
  @Input() GraphicsLayer: any;
  @Input() Point: any;
  @Input() webMercatorUtils: any;
  @Input() PictureMarkerSymbol: any;

  public isDrawExtentEnabled = true;
  public isDrawPolygonEnabled = false;
  public rowdataobj;
  public gridView = false;
  public cardView = false;
  public maintenanceView = false;
  public queriedLayers = [];
  public currentUser: IUser;
  // public category:any;
  public inventoryLayersData: ILayer[] = [] as ILayer[];
  public selectGraphicslayer: any;

  public parenttasksCount;

  //Added for visulization layers
  visualizationLayersData: any[] = [];

  ngOnInit() {
    this.objGlobals.IsAssetViewerClosed = true;
    this.objGlobals.IsGridViewClosed = true;
    this.objGlobals.IsMntViewClosed = true;
    this.inventoryLayersData = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
    this.selectGraphicslayer = this.map.smsLayerdata.graphicLayers[0]; // by default first layer
    this.visualizationLayersData = [];
    if (this.map.visualizationLayers != undefined) {
      this.visualizationLayersData = this.map.visualizationLayers; //Added for visulization layers
    }
    if (this.objGlobals.complaintID != null && this.objGlobals.complaintID !== "" && this.objGlobals.complaintID !== undefined) {
      this.objGlobals.closeCrowdSource = true;
    }

    if (
      this.objGlobals.latitude != null &&
      this.objGlobals.latitude !== undefined &&
      this.objGlobals.latitude !== "" &&
      this.objGlobals.longitude != null &&
      this.objGlobals.longitude !== undefined &&
      this.objGlobals.longitude !== ""
    ) {
      this.fnClearSelection();
      const geopint = new this.Point({
        x: this.objGlobals.longitude,
        y: this.objGlobals.latitude,
        spatialReference: { wkid: 4326 }
      });
      this.map.centerAndZoom(geopint, 15);
      const selectionPointSymbol = new this.SimpleMarkerSymbol(
        this.SimpleMarkerSymbol.STYLE_CIRCLE,
        12,
        new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_NULL, new this.Color([247, 34, 101, 0.9]), 1),
        new this.Color([0, 255, 255, 9])
      );
      const pointingsymbol = new this.Graphic(geopint, selectionPointSymbol);
      this.map.graphics.add(pointingsymbol);
    }

    this.map.on("click", this.myClickHandler);
  }
  myClickHandler() {
    console.log("map clickedd");
  }
  ngAfterViewInit() {
    if (
      this.objGlobals.latitude != null &&
      this.objGlobals.latitude !== undefined &&
      this.objGlobals.latitude !== "" &&
      this.objGlobals.longitude != null &&
      this.objGlobals.longitude !== undefined &&
      this.objGlobals.longitude !== ""
    ) {
      this.map.enableMapNavigation();
      this.objGlobals.latitude = null;
      this.objGlobals.longitude = null;
    } else {
      this.defaultExtentSelect();
    }
  }

  defaultExtentSelect() {
    this.fnDraw();
  }
  optionClicked(event: any, type: string) {
    try {
      if (type === "Clear") {
        // nothing to do
        this.fnClearSelection();
      } else if (type === "Extent") {
        this.isDrawExtentEnabled = true;
        this.isDrawPolygonEnabled = false;
      } else if (type === "Polygon") {
        this.isDrawPolygonEnabled = true;
        this.isDrawExtentEnabled = false;
      }

      this.toggleDrawTool(event, type);
    } catch (error) {
      console.error(error);
    }
  }

  toggleDrawTool(event: any, type: any) {
    try {
      console.log("test here");
      const className = "i-active";
      const hasClass = event.target.classList.contains(className);

      if (hasClass) {
        this.deactivateTool(type);
      } else {
        this.fnSelect(type);
      }
    } catch (error) {
      console.error(error);
    }
  }
  deactivateTool(tool: any) {
    try {
      if (tool) {
        tool.editingEnabled = false;
        tool.deactivate();
        tool = null;
      }

      this.isDrawExtentEnabled = false;
      this.isDrawPolygonEnabled = false;
    } catch (error) {
      console.error(error);
    }
  }
  fnSelect(selectType: any) {
    try {
      switch (selectType) {
        case "Extent":
        case "Polygon":
          this.fnDraw();
          break;
        case "Clear":
          this.fnClearSelection();
          break;
        case "resultGrid":
          this.gridviewSelect();
          break;
        case "resultCard":
          this.cardviewSelect();
          break;
        case "maintenanceViewerSelTool":
          this.maintenanceViewerSelect(!this.maintenanceView);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Method to select the features by Extent(Rectangle) & Polygon

  fnDraw() {
    try {
      const listSelectData = [];
      // this.cardviewSelect();
      // this.gridviewSelect();
      this.eventEmitersService.setShowCard(false);
      this.eventEmitersService.setShowGrid(false);
      this.map.disableMapNavigation();

      if (this.isDrawExtentEnabled) {
        this.drawTool.activate("extent");
        //Added by Mdhuri JIRA ID:-35
        this.eventEmitersService.setEnableSingleClick(false);
        //TSDRSAM-1655
        this.genericMapMethodsService.queryBuilderZoomExtent = false;
      } else if (this.isDrawPolygonEnabled) {
        this.drawTool.activate("polygon");
        //Added by Mdhuri JIRA ID:-35
        this.eventEmitersService.setEnableSingleClick(false);
      }

      this.drawTool.component = "select";

      // START DRAWING POLYGON/ RECTANGLE
      const handler = this.drawTool.on(
        "draw-end",
        function (evt) {
          handler.remove(); // REMOVE DRAW EVENT
          this.deactivateTool(this.drawTool);
          this.map.enableMapNavigation();

          // Clear all graphics before selection
          this.map.graphicsLayerIds.forEach(
            function (id) {
              this.map.getLayer(id).clear();
            }.bind(this)
          );
          // Query Data in Selected Geometry
          const query = new this.Query();
          query.geometry = evt.geometry;
          console.log(query);
          const flRequests = [];
          this.queriedLayers = [];

          this.inventoryLayersData.forEach((lyr, i) => {
            if (lyr.visible) {
              const ftrLayer = lyr.lyrObj;
              console.log(ftrLayer);
              flRequests.push(ftrLayer.selectFeatures(query));
              this.queriedLayers.push(lyr);
            }
          });
          //Added for Visualization layers
          //Added by Venkatesh for visualization layers
          let allSelectedData = [];
          let coordinateString;
          if (evt.geometry.xmin && evt.geometry.ymin) {
            let minX = evt.geometry.xmin;
            let minY = evt.geometry.ymin;
            let maxX = evt.geometry.xmax;
            let maxY = evt.geometry.ymax;
            let bBoxString = minX.toString() + "," + minY.toString() + "," + maxX.toString() + "," + maxY.toString();
            coordinateString = "bbox=" + bBoxString + ",'EPSG:3857'";
          } else {
            if (evt.geometry.rings && evt.geometry.rings[0]) {
              evt.geometry.rings[0].forEach((coordinate) => {
                coordinateString = coordinate[0].toString() + "," + coordinate[1].toString() + " ";
              });
              coordinateString =
                "Filter=<Filter><Intersects><PropertyName>Shape</PropertyName><gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>" +
                coordinateString +
                "</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects></Filter>";
            }
          }
          if (this.visualizationLayersData) {
            this.visualizationLayersData.forEach((lyr) => {
              if (lyr.visible) {
                let lastIndex = lyr.wmsUrl.lastIndexOf("/");
                if (
                  lyr.wmsUrl
                    .substring(lastIndex + 1)
                    .toLowerCase()
                    .includes("wms")
                ) {
                  let wfsUrl = lyr.wmsUrl.substring(0, lastIndex + 2) + "f" + lyr.wmsUrl.substring(lastIndex + 3); // wms => wfs
                  lyr.originalLayerTitle = lyr.originalLayerTitle;
                  let getFeatures = wfsUrl + "?service=WFS&REQUEST=GetFeature&VERSION=1.0.0&typeName=" + lyr.originalLayerTitle + "&" + coordinateString + "&srsName='EPSG:3857'";
                  allSelectedData.push(this.eventEmitersService.getSelectedWFSData(getFeatures, lyr.originalLayerTitle));
                }
              }
            });
          }
          //}

          let visualizationSelectedData = [];
          if (flRequests.length > 0) {
            this.all(flRequests).then(
              function (results) {
                results.forEach((element, i) => {
                  element.sort(function (a, b) {
                    const x = a.attributes.OBJECTID;
                    const y = b.attributes.OBJECTID;
                    return x < y ? -1 : x > y ? 1 : 0;
                  });

                  if (element.length > 0) {
                    listSelectData.push({
                      LayerName: this.queriedLayers[i],
                      Data: element
                    });
                  }
                });
                if (allSelectedData.length > 0) {
                  this.all(allSelectedData).then(
                    function (selectedData) {
                      visualizationSelectedData = selectedData;
                      this.eventEmitersService.getSelectedVisualizationData(selectedData).forEach((data) => {
                        listSelectData.push(data);
                      });
                      this.setCardData(listSelectData, visualizationSelectedData);
                    }.bind(this)
                  );
                } else {
                  this.setCardData(listSelectData, visualizationSelectedData);
                }
              }.bind(this)
            );
          } else {
            //Added for Visualization layers
            if (allSelectedData.length > 0) {
              this.all(allSelectedData).then(
                function (selectedData) {
                  visualizationSelectedData = selectedData;
                  this.eventEmitersService.getSelectedVisualizationData(selectedData).forEach((data) => {
                    listSelectData.push(data);
                  });
                  this.setCardData(listSelectData, visualizationSelectedData);
                }.bind(this)
              );
            }
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  getTasksCount(assetID) {
    try {
      //7/27/20 jcc -- this function is not called from code.  called from view?  appears to be copy of old function in qerybuilder.component.ts
      return new Promise(
        function (resolve) {
          const query = new this.Query();
          const queryTask = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId);
          // let featureLayer = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId);
          query.where = "AssetID='" + assetID + "'";
          query.outFields = ["AssetID", "Status"];
          // featureLayer.queryCount(query, function (count) {
          //   resolve(count);
          // })
          queryTask.execute(query).then((result) => {
            const openCount = result.features.filter((res) => res.attributes.Status === "Open");
            resolve(openCount);
          });
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  getOpenTasks(assetIDList) {
    try {
      //jcc 7/26/20 -- expand to include Assets where their related Sign Assets have open maintenance.
      //to do -- refactor: make more generic for all child asset types.  Filter list of IDs to the parent asset class before querying for child assets.  Only check children if Support layer is visible.
      return new Promise(
        function (resolve) {
          const queryTaskMaintenance = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId);
          const queryTaskSign = new this.QueryTask(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId);
          const query = new this.Query();
          const assetIDsWithOpenTasks = []; //self or related asset

          //get AssetsIDs with open maintenance
          query.where = "AssetID IN (" + assetIDList.toString() + ") and Status = 'Open'";
          query.outFields = ["AssetID"];
          queryTaskMaintenance.execute(query).then((result) => {
            result.features.forEach((element) => {
              assetIDsWithOpenTasks.push(element.attributes.AssetID);
            });

            //get sign asset children of selected assets
            query.where = "ParentID IN (" + assetIDList.toString() + ") and Status <> 'Inactive'";
            query.outFields = ["AssetID", "ParentID"];
            queryTaskSign.execute(query).then((result) => {
              if (result.features.length > 0) {
                const childAssetIDList = [];
                const signIdParentId = {};
                result.features.forEach((element) => {
                  childAssetIDList.push("'" + element.attributes.AssetID + "'");
                  signIdParentId[element.attributes.AssetID] = element.attributes.ParentID;
                });

                //get open maintenance for signs
                query.where = "AssetID IN (" + childAssetIDList.toString() + ") and Status = 'Open'";
                query.outFields = ["AssetID"];
                queryTaskMaintenance.execute(query).then((result) => {
                  if (result.features.length > 0) {
                    let parentId = "";
                    result.features.forEach((element) => {
                      parentId = signIdParentId[element.attributes.AssetID];

                      if (assetIDsWithOpenTasks.indexOf(parentId) < 0) {
                        assetIDsWithOpenTasks.push(parentId);
                      }
                    });
                    resolve(assetIDsWithOpenTasks); //including assets with related assets having open tasks
                  } else {
                    resolve(assetIDsWithOpenTasks); //only assets with tasks, no related assets have open tasks
                  }
                });
              } else {
                resolve(assetIDsWithOpenTasks); //only assets with tasks, there are no related assets
              }
            });
          });
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  // To Deactivate the Draw tool

  fnClearLayerSelection() {
    try {
      const inventoryLayers = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
      const lyrCount = inventoryLayers.length;

      for (let i = 0; i < lyrCount; i++) {
        const ftrLayer = inventoryLayers[i].lyrObj;

        if (ftrLayer) {
          ftrLayer.clearSelection();
        }
      }
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }

  fnClearSelection() {
    try {
      this.map.graphicsLayerIds.forEach(
        function (id) {
          this.map.getLayer(id).clear();
        }.bind(this)
      );
      this.map.graphics.clear();
      this.showGrid.emit(false);
      this.showCard.emit(false);
      this.isOpenCard.emit(false);
      this.data.emit([]);
      // this.eventEmitersService.setSelectedFeaturesOnMap([]);
      this.isDrawExtentEnabled = false;
      this.isDrawPolygonEnabled = false;
      this.deactivateTool(this.drawTool);
      this.map.enableMapNavigation();
      this.fnClearLayerSelection();
      this.rowdataobj = null;
      this.eventEmitersService.setShowCard(false);
      this.eventEmitersService.setShowGrid(false);
      console.log("Grid View Close  " + 15);

      this.eventEmitersService.setSelectedFeaturesOnMap([]);
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      // re-enabled for 1765, historical research did not show why the below line was commented out.
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }

  gridviewSelect() {
    console.log("selecting grid view");
    try {
      if (!this.rowdataobj || this.rowdataobj.length === 0) {
        return;
      }
      
      this.eventEmitersService.setShowGrid(this.eventEmitersService.showGrid === true ? false : true);
      console.log("Grid View Open/Close  " + 17);
      //   this.gridView = visible;
      //  // this.objGlobals.IsGridViewClosed = !visible;
      //   // this.showGrid.emit(this.gridView);
      //   // if (!this.gridView && this.objGlobals.featureTableObj) {
      //   //   this.objGlobals.featureTableObj.destroyRecursive();
      //   //   this.objGlobals.featureTableObj = null;
      //   // }
    } catch (error) {
      console.error(error);
    }
  }
  cardviewSelect() {
    try {
      if (!this.rowdataobj || this.rowdataobj.length === 0) {
        return;
      }

      // this.cardView = visible;
      // this.objGlobals.IsAssetViewerClosed = !visible;
      this.eventEmitersService.setShowCard();
      this.eventEmitersService.addNewParent = false;
      //  this.showCard.emit(this.cardView);
    } catch (error) {
      console.error(error);
    }
  }

  maintenanceViewerSelect(visible) {
    try {
      if (visible) {
        if (!this.rowdataobj || this.rowdataobj.length === 0) {
          return;
        }
      }

      this.maintenanceView = visible;
      // this.category.emit('selectedTasks');
      this.objGlobals.IsMntViewClosed = !visible;
      this.showmaintenanceViewer.emit(this.maintenanceView);
    } catch (error) {
      console.error(error);
    }
  }

  //Added for Visualization layers
  //Added by Venkatesh for visualization layers

  setCardData(listSelectData: any[], visualizationSelectedData) {
    this.rowdataobj = listSelectData;
    this.data.emit(this.rowdataobj);

    if (this.rowdataobj.length > 0) {
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setSelectedFeaturesOnMap(this.rowdataobj);
      if (visualizationSelectedData.length > 0) {
        //Select Visualization assets
        this.eventEmitersService.emitWFSData(visualizationSelectedData);
      }
    } else {
      this.eventEmitersService.setEnableSingleClick(true);
    }
    if (this.rowdataobj.length > 0) {
      const maxLengthRecord = this.rowdataobj.reduce((max, row) => Math.max(max, row.Data.length), 0);
      if (maxLengthRecord == this.map.smsLayerdata.dynamicMapLayer.maxRecordCount) {
       this.qbService.updateMaxRecordMessage(this.map.smsLayerdata.dynamicMapLayer.maxRecordCount);
      }
      this.eventEmitersService.setShowCard(true);
    }

    this.eventEmitersService.setShowGrid(false);
  }
  //
}
