import { Routes, RouterModule } from "@angular/router";
import { EsriMapComponent } from "./esri-map/esri-map.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./helpers/auth.gaurd";

const routes: Routes = [
  { path: "", component: EsriMapComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent }
];

export const appRoutingModule = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: "legacy" });
