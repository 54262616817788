<div class="form-popup" id="myForm">
    <form class="form-container">
        <div class="animated fadeIn">

            <div class="col-12">

                <div class="row">
                    <div class="col-11"></div>
                    <div class="col-1 float-right">

                         
                        <button type="button" (click)="close()" class="close" aria-label="Close">
                                <fa-icon [icon]="faTimes" aria-hidden="true"></fa-icon>
                        </button>
                        <button type="button" (click)="close()" class="close">
                            <fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>
                            </button>

                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="card">
                            <div class="card-header">
                                <button class="btn btn-primary" type="button" (click)="null">YTD</button>
                                <button class="btn btn-primary" type="button" (click)="null">QTD</button>
                                <button class="btn btn-primary" type="button" (click)="null">MTD</button>


                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">Region</div>
                                    <div class="col-6">{{region}}</div>
                                </div>
                                <div class="row">

                                    <div class="col-12" style="text-align: center;">
                                        <span>
                                            Number Of Road Assets
                                        </span>
                                    </div>


                                </div>
                                <div class=" row tablealiasFixHead">
                                    <table class="col-12">
                                        <thead>
                                            <tr>
                                                <th>Asset Class</th>
                                                <th>Total No: of Asets</th>

                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let flayer of democratizeassets;let lid = index">
                                            <tr>
                                                <td>{{flayer.name}}</td>
                                                <td>{{flayer.count}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">

                                    <div class="col-12" style="text-align: center;">
                                        <span>
                                            No: of Human factor Related Damages:
                                        </span>
                                    </div>


                                </div>
                                <div class=" row tableFixHead">
                                    <table class="col-12">
                                        <thead>
                                            <tr>
                                                <th>Caused By</th>
                                                <th>Total No: of Cases</th>

                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let flayer of humanfactor;let lid = index">
                                            <tr>
                                                <td>{{flayer.name}}</td>
                                                <td>{{flayer.count}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row">

                            <div class="col-12">
                                <div class="card text-white bg-dark">

                                    <div class="card-body">
                                        <div class="chart-wrapper">
                                            <canvas baseChart class="chart barCanvas" [datasets]="barChartData"
                                                [labels]="barChartLabels" [options]="barChartOptions"
                                                [legend]="barChartLegend" [chartType]="barChartType"
                                                (chartHover)="chartHovered($event)"
                                                (chartClick)="chartClicked($event)"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-12">
                                <div class="card text-white bg-dark">

                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-4">
                                                <div class="chart-wrapper">
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartData"
                                                        [labels]="pieChartLabels" [chartType]="pieChartType"
                                                        (chartHover)="chartHovered($event)" [options]="pieChartOptions"
                                                        (chartClick)="chartClicked($event)"></canvas>

                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="chart-wrapper">
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartData"
                                                        [labels]="pieChartLabels" [chartType]="pieChartType"
                                                        (chartHover)="chartHovered($event)" [options]="pieChartOptions"
                                                        (chartClick)="chartClicked($event)"></canvas>

                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="chart-wrapper">
                                                    <canvas baseChart class="chart pieCanvas" [data]="pieChartData"
                                                        [labels]="pieChartLabels" [chartType]="pieChartType"
                                                        (chartHover)="chartHovered($event)" [options]="pieChartOptions"
                                                        (chartClick)="chartClicked($event)"></canvas>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 float-left">
                        <div class="row">
                            <!-- <button type="button" class="btn btn-primary btn-sm btn-block responsive-width">
                                Summary</button> -->
                            <div *ngFor="let flayer of democratizeassets;let lid = index">
                                <button type="button" class="btn btn-primary btn-sm btn-block responsive-width">
                                    {{flayer.name}}</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>