import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import Swal from "sweetalert2";
import { CellRenderingComponent } from "./CellRenderingComponent/cell-rendering.component";
import { CrowdSourceInfoService } from "../services/crowd-source-info.service";
import { DatePipe } from "@angular/common";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../select/globals";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt as faCalendar, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { GridOptions } from "ag-grid-community";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "sms-crowd-sourced-info",
  templateUrl: "./crowd-sourced-info.component.html",
  styleUrls: ["./crowd-sourced-info.component.scss"]
})
export class CrowdSourcedInfoComponent implements OnInit {
  @Output() closeCrowdSourceEvent = new EventEmitter();
  @Output() createTask = new EventEmitter();
  @Output() crowdsource = new EventEmitter();

  faMapMarker = faMapMarker;
  faPlusSquare = faPlusSquare;
  asstClickedES = false;
  reviewComment: any;
  dateofComplaint: any;
  latitude: any;
  faCalendar = faCalendar;
  faSearch = faSearch;
  longitude: any;
  assetID: any;
  source: any;
  todayDate: Date = new Date();
  description: any;
  frameworkComponents: any;
  viewMode: boolean;
  gridApi: any;
  search: any;
  data: any = [];
  complaint: any;
  currentUser: IUser;
  rowSelection = "single";
  showclose: boolean;
  showbutton: boolean;
  showlocation = true;
  showPopup: boolean;
  showSummaryView = true;
  inBounds = true;
  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true
    }
  };

  columnDefs: any = [
    {
      headerName: this.translateService.instant("Crowdsource.table.complaintid"),
      field: "complaintId",
      width: 120,
      cellRenderer: "cellRenderingComponent",
      cellRendererParams: {
        onClick: this.onRowEdit.bind(this)
      }
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.date"),
      field: "complaintDate",
      cellRenderer: (data) => {
        return data.value ? this.datePipe.transform(data.value, "MM/dd/yyyy hh:mm a") : "";
      },
      width: 150
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.source"),
      field: "sourceName",
      width: 150
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.description"),
      field: "description",
      width: 200
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.status"),
      field: "statusCode",
      width: 150
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.reviewercomment"),
      field: "reviewComment",
      width: 200
    },
    {
      headerName: this.translateService.instant("Crowdsource.main.assetid"),
      field: "assetID",
      width: 115
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.latitude"),
      field: "latitude",
      width: 115
    },
    {
      headerName: this.translateService.instant("Crowdsource.table.longitude"),
      field: "longitude",
      width: 115
    }
  ];
  rowData: any = [];
  constructor(
    private crowdSourceInfoService: CrowdSourceInfoService,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public objGlobal: Globals
  ) {
    this.frameworkComponents = {
      cellRenderingComponent: CellRenderingComponent
    };
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.objGlobal.closeCrowdSource = false;
    // this.objGlobal.content.hide();
    this.getAllComplaint();
  }
  /** Close Crowd Source Popup */
  closeCrowdAsset() {
    this.closeCrowdSourceEvent.emit(false);
  }
  /** Open Reviewer Popup */
  openModel() {
    this.showclose = true;
    this.showPopup = true;
    this.showbutton = true;
    this.reset();
    this.source = "Manual Entry";
    this.viewMode = true;
  }
  /** Close Reviewer Popup */
  modelClose() {
    if (this.objGlobal.closeCrowdSource === true) {
      this.closeCrowdSourceEvent.emit(false);
    } else {
      this.showPopup = false;
    }
  }
  /** Load Complaint Related Data to Fields */
  onRowEdit(complaintID: string) {
    try {
      this.showPopup = true;
      this.showlocation = true;
      this.dateofComplaint = null;
      this.viewMode = false;
      this.data = this.rowData.filter((x) => x.complaintId === complaintID);
      this.reset();
      //this.dateofComplaint = this.data[0].complaintDate;
      //Modified by Subba on 06/11/2020 for localizing date [as changes made in getAllComplaint()]
      this.dateofComplaint = new Date(this.data[0].complaintDate);

      this.longitude = this.data[0].longitude;
      this.latitude = this.data[0].latitude;
      this.assetID = this.data[0].assetID;
      this.description = this.data[0].description;
      this.source = this.data[0].sourceName;
      this.reviewComment = this.data[0].reviewComment;
      this.complaint = complaintID;

      if (this.data[0].statusCode === "Not Reviewed") {
        this.showbutton = true;
      } else {
        this.showbutton = false;
      }

      if (
        this.objGlobal.complaintID != null &&
        this.objGlobal.complaintID !== undefined &&
        this.objGlobal.complaintID !== "" &&
        this.objGlobal.latitude != null &&
        this.objGlobal.latitude !== undefined &&
        this.objGlobal.latitude !== "" &&
        this.objGlobal.longitude != null &&
        this.objGlobal.longitude !== undefined &&
        this.objGlobal.longitude !== "" &&
        this.objGlobal.assetID != null &&
        this.objGlobal.assetID !== undefined &&
        this.objGlobal.assetID !== ""
      ) {
        this.latitude = this.objGlobal.latitude;
        this.longitude = this.objGlobal.longitude;
        this.assetID = this.objGlobal.assetID;
        this.showlocation = false;
      }
    } catch (error) {
      console.error(error);
    }
  }
  /** Reset All Fields */
  reset() {
    try {
      this.dateofComplaint = new Date();
      this.latitude = null;
      this.longitude = null;
      this.assetID = null;
      this.source = "Manual Entry";
      this.description = null;
      this.complaint = null;
      this.reviewComment = null;
    } catch (error) {
      console.error(error);
    }
  }
  onGridReady(params) {
    try {
      this.gridApi = params.api;
    } catch (error) {
      console.error(error);
    }
  }
  /** Search Filter for Summary Complaint Table */
  searchFilter() {
    try {
      this.gridApi.setQuickFilter(this.search);
    } catch (error) {
      console.error(error);
    }
  }
  /** Get All Complaint Details */
  getAllComplaint() {
    try {
      this.crowdSourceInfoService.GetAllComplaint().subscribe(
        (resultArray: any) => {
          resultArray.forEach((u) => {
            u.statusCode = u.statusCode === 0 ? "Not Reviewed" : u.statusCode === 1 ? "Reviewed & Discarded" : u.statusCode === 2 ? "Task Created" : "";
            // Added by Subba on 06/11/2020 for localizing date
            u.complaintDate = u.complaintDate.replace("T", " ") + " UTC";
          });

          this.rowData = resultArray.sort((a, b) => {
            if (a.complaintDate === b.complaintDate) {
              return a.complaintId < b.complaintId ? 1 : -1;
            }

            return (new Date(b.complaintDate) as any) - (new Date(a.complaintDate) as any);
          });

          if (this.objGlobal.complaintID != null && this.objGlobal.complaintID !== undefined && this.objGlobal.complaintID !== "") {
            this.onRowEdit(this.objGlobal.complaintID);
          }

          if (this.objGlobal.assetID != null && this.objGlobal.assetID !== undefined && this.objGlobal.assetID !== "") {
            this.showclose = false;
          } else {
            this.showclose = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  /** Create New Complaint Detail */
  onSubmit() {
    try {
      if (this.description == null || this.description === undefined || this.description === "") {
        Swal.fire(this.translateService.instant("Crowdsource.alert.enterdescription"));
        return;
      }

      const obj = {
        longitude: this.longitude,
        latitude: this.latitude,
        sourceName: this.source,
        description: this.description,
        externalComplaintID: 0,
        complaintDate: new Date()
      };
      this.crowdSourceInfoService.CreateComplaint(obj).subscribe(
        (resultArray: any) => {
          const result = JSON.parse(resultArray);

          if (result.message === "Inserted Successfully") {
            this.reset();
            this.getAllComplaint();
            Swal.fire(this.translateService.instant("Crowdsource.alert.complaintcreated"));
            this.showPopup = false;
          } else {
            Swal.fire(this.translateService.instant("Crowdsource.alert.erroronsavingrecords"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  createNewTask() {
    try {
      this.asstClickedES = true;
      const obj = {
        longitude: this.longitude,
        latitude: this.latitude,
        ComplaintId: this.complaint
      };
      this.showPopup = true;
      this.showSummaryView = false;
      this.createTask.emit(obj);
    } catch (error) {
      console.error(error);
    }
  }
  /** Updating Complaint Details */
  updateComplaint(status) {
    try {
      this.asstClickedES = false;

      if (status === 2) {
        if (
          this.objGlobal.complaintID === "" ||
          this.objGlobal.complaintID === undefined ||
          this.objGlobal.complaintID == null ||
          this.objGlobal.latitude == null ||
          this.objGlobal.latitude === undefined ||
          this.objGlobal.latitude === "" ||
          this.objGlobal.longitude == null ||
          this.objGlobal.longitude === undefined ||
          this.objGlobal.longitude === "" ||
          this.objGlobal.assetID == null ||
          this.objGlobal.assetID === undefined ||
          this.objGlobal.assetID === ""
        ) {
          Swal.fire(this.translateService.instant("Crowdsource.alert.createtask"));
          return;
        }

        if (
          this.latitude == null ||
          this.latitude === "" ||
          this.latitude === undefined ||
          this.latitude === 0 ||
          this.longitude == null ||
          this.longitude === "" ||
          this.longitude === undefined ||
          this.longitude === 0
        ) {
          Swal.fire(this.translateService.instant("Crowdsource.alert.selectlanglong"));
          return;
        }
      }

      if (this.reviewComment == null || this.reviewComment === undefined || this.reviewComment === "") {
        Swal.fire(this.translateService.instant("Crowdsource.alert.enterreviewercomment"));
        return;
      }

      const obj = {
        assetID: this.assetID,
        longitude: this.longitude,
        latitude: this.latitude,
        reviewComment: this.reviewComment
      };

      this.crowdSourceInfoService.UpdateComplaint(obj, this.complaint, status).subscribe(
        (resultArray: any) => {
          const result = JSON.parse(resultArray);

          if (result.message === "Updated Successfully") {
            if (status === 1 && this.objGlobal.closeCrowdSource === true) {
              this.crowdsource.emit();
            }

            this.objGlobal.complaintID = null;
            this.objGlobal.latitude = null;
            this.objGlobal.longitude = null;
            this.objGlobal.assetID = null;
            this.objGlobal.closeCrowdSource = false;
            this.reset();
            this.getAllComplaint();
            Swal.fire(this.translateService.instant("Crowdsource.alert.complaintupdated"));
            this.showPopup = false;
            this.showSummaryView = true;
          } else {
            Swal.fire(this.translateService.instant("Crowdsource.alert.erroronsavingrecord"));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
