import { Component, OnInit } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SystemReportsStyle } from "../system.reports.style";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-retiredassets",
  templateUrl: "./retiredAssets.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class RetiredAssetsComponent implements OnInit {
  private currentUser: any;
  private tableAliases: any;
  constructor(public systemReportsService: SystemReportsService, private authenticationService: AuthenticationService, private translateService: TranslateService, private reportStyle: SystemReportsStyle,private assetAttributesService: AssetAttributesService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    console.log("Componenet Init");
  }
  exportPDF() {
    try {
      this.systemReportsService.getAssetRetireReport(this.currentUser.selectedRegion.regionName).subscribe(
        (res) => {
          this.assetAttributesService.getTableAlias().subscribe((tblresults: any) => {
            this.tableAliases = tblresults.features;
            this.JSONToPDFConvertor(res, true);
          });
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];

      if (res.tblRetireAssets.length > 0) {
        JSONData = res.tblRetireAssets;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertretireyrassetclass.nodata"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const tableheader = [];
      const tablebody = [];
      let tablerow = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();
     
      // This condition will generate the Label/Header
      if (ShowLabel) {
        // This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          if (index !== "assetName") {
            switch (index) {
              case "assetID":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.assetid"),
                  style: "tableHeader"
                });
                break;
              case "assetCode":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.assetcode"),
                  style: "tableHeader"
                });
                break;
              case "installDate":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.installdate"),
                  style: "tableHeader"
                });
                break;
              case "retireDate":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.retiredate"),
                  style: "tableHeader"
                });
                break;
              case "route":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.route"),
                  style: "tableHeader"
                });
                break;
              case "latitude":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.latitude"),
                  style: "tableHeader"
                });
                break;
              case "longitude":
                tableheader.push({
                  text: this.translateService.instant("Systemreports.headerretireyrassetclass.longitude"),
                  style: "tableHeader"
                });
                break;
              default:
                tableheader.push({ text: index, style: "tableHeader" });
                break;
            }
          }
        }

        tablebody.push(tableheader);
      }

      let assetClass = [];
      arrData.forEach((data) => {
        if (data.assetName !== "") {
          assetClass.push(data.assetName);
        }
      });

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      assetClass = assetClass.filter(distinct);

      // 1st loop is to extract each row
      for (let j = 0; j < assetClass.length; j++) {
        const arr = arrData.filter((obj) => obj.assetName.includes(assetClass[j]));
        const tableAlias = this.tableAliases.map(a => a['attributes']).filter((lyr) => lyr.Name === assetClass[j])[0].Alias;
        if (j === 0) {
          tablebody.push([
            {
              colSpan: 7,
              text: tableAlias,
              style: "rowGroupHeader"
            },
            "",
            "",
            "",
            "",
            "",
            ""
          ]);
        } else {
          tablebody.push([
            {
              colSpan: 7,
              text: tableAlias,
              style: "rowGroupHeader",
              pageBreak: "before"
            },
            "",
            "",
            "",
            "",
            "",
            ""
          ]);
        }

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          tablerow = [];

          // 2nd loop will extract each column and push in the table row
          for (const index in arr[i]) {
            if (index !== "assetName") {
              if (index === "installdate" && arr[i][index] !== null) {
                tablerow.push({ text: arr[i][index].split("T")[0], style: "tableContent" });
              } else if (index === "retireDate" && arr[i][index] !== null) {
                tablerow.push({ text: arr[i][index].split("T")[0], style: "tableContent" });
              } else {
                tablerow.push({ text: arr[i][index], style: "tableContent" });
              }
            }
          }

          // add a line break after each row
          tablebody.push(tablerow);
        }
      }

      const dd = {
        pageSize: "A4",
        pageOrientation: "landscape",
        fontSize: 18,
        footer: currentFooter,
        content: [
          {
            text: this.translateService.instant("Systemreports.headerretireyrassetclass.retiredasset"),
            style: "header"
          },
          {
            style: "tableExample",
            alignment: "justify",
            table: {
              widths: [90, 90, 90, 90, 90, 90, 90, 90],
              heights: 15,
              headerRows: 1,
              body: tablebody
            },
            pageOrientation: "landscape",
            layout: currentLayoutStyle
          }
        ],
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
