import { Injectable } from "@angular/core";
@Injectable()
export class adminGlobal {
  public AgencyId = 0;
  public agencyDetails: any = [];
  public PALValues: any = [];
  public serviceLyrInfo: any = [];
  public ShowAsset: any = [];
  public HideAsset: any = [];
  public ShowSystemReport: any = [];
  public HideSystemReport: any = [];
  public AssetClassList: any = [];
  public ExistBaselifeConfig: any = [];
  public ExistBaselifeModifier: any = [];
  public NewBaselifeConfig: any = [];
  public NewBaselifeModifier: any = [];
  public ExistBaseLife: any = [];
  public ExistBaseLifeModify: any = [];
  public ResourceRequirement: any = [];
  public ResourceSaveValues: any = [];
  public ResourceRequirementFilename = null;
  //Added by Sowjanya for TSDRSAM-71
  public AdminData = {};
  public SuperAdminUserId;
  public NewClientGisUrl;
}
