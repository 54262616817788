export const LANG_PT_NAME = "pt";

export const LANG_PT_TRANS = {
  "Login.logo.name": "Sistema de gerenciamento de sinal",
  "Login.page.text1": "Maneiras mais seguras, rápidas e leves",
  "Login.page.text2": "para manter o mundo em movimento",
  "Login.loginbox.heading": "Conecte-se",
  "Login.loginbox.text": "Digite suas credenciais abaixo para fazer login no 3M Road Asset Manager",
  "Login.loginbox.userplaceholder": "Digite seu ID de usuário",
  "Login.loginbox.passwordplaceholder": "Coloque sua senha",
  "Login.loginbox.button": "Conecte-se",
  "Login.loginvalidate.text1": "Seu ID de usuário e senha não correspondem",
  "Login.loginvalidate.text2": "Digite credenciais válidas para fazer login",
  "Login.loginvalidate.tryagain": "Tente novamente?",
  "Login.loginvalidate.forgotpassword": "Esqueceu a senha? Redefina aqui.",
  "Login.loginpage.legaltext": "Legal",
  "Login.loginpage.privacytext": "Privacidade",
  "Login.loginpage.rightstext": "Todos os direitos reservados",
  "Login.loginsuccess.welcome": "Bem-vinda!",
  "Login.loginsuccess.msgtext": "Você fez login com sucesso",
  "Login.loginsuccess.getstartedbtn": "INICIAR",
  "Login.afterlogin.term&condition": "termos e Condições",
  "Login.afterlogin.acceptbtn": "Aceitar",
  "Login.newlogin.changepasstxt": "Mudar senha",
  "Login.newlogin.oldpasstxt": "Senha Antiga",
  "Login.newlogin.oldpassdesc": "Forneça a senha antiga",
  "Login.newlogin.newpasstxt": "Nova senha",
  "Login.newlogin.newpassdesc": "Forneça a nova senha",
  "Login.changepwdvalidate.newandold": "A nova senha deve ser diferente da senha antiga",
  "Login.changepwdvalidate.confrmpwd": "Confirme a Senha",
  "Login.changepwdvalidate.confrmpwddesc": "Forneça Confirmar senha",
  "Login.changepwdvalidate.confrmpwddesc2": "Confirmar senha deve ser igual à nova senha",
  "Login.changepswd.changepswdbtn": "Mudar senha",
  "Login.alert.loginfail": "Falha na autenticação..!!",
  "Login.alert.pswdchange": "Senha alterada",
  "Login.alert.reloginconfirmation": "Pressione Ok para entrar novamente",
  "User.Account.Inactive": "Sua conta está inativa. Entre em contato com o administrador de solução da sua agência.",
  "User.LockOut.Contact.SolutionAdmin": "Sua conta está bloqueada. Entre em contato com o administrador da solução da sua agência para desbloqueá-lo.",
  "User.Password.Expired": "Sua senha expirou, crie uma nova.",
  "User.AttemptRemaining.1": "Tentativa de login inválida. ",
  "User.AttemptRemaining.2": " de ",
  "User.AttemptRemaining.3": " tentativas restantes.",
  "Login.alert.reloginmessage": "Clique em Ok para relogar",
  "Login.alert.passwordsuccessmsg": "A senha foi alterada com sucesso",
  "Login.alertuseradmin.InvalidPassword": "Deve ter entre %se %s caracteres, incluindo pelo menos %s letra maiúscula, %s letra minúscula, %s número e %s caractere especial",
  "Login.changepswd.passUsernameSame":"A senha não pode ser igual ao ID do usuário",
  "Login.changepswd.passNotValid":"A senha antiga não é válida",
  "Login.changepswd.InvalidPasswordLast24":"A nova senha não pode ser uma senha usada recentemente",
  "Login.loginbox.required":"Requeridos",
  "Login.alert.username": "Por favor, digite o nome de usuário",
  "Login.alert.password": "Por favor, digite a senha"

};


