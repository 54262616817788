import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { IUser } from "../interface/user";
import { GenericLayerService } from "./genericLayer.service";

@Injectable()
export class AssetAttributesService {
  currentUser: IUser;
  constructor(private authenticationService: AuthenticationService, private genericService: GenericLayerService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  getAssetAttributes() {
    return this.genericService.getAllFeatures(this.currentUser.serviceLyrInfo.fieldAliasId);
  }
  getTableAlias() {
    return this.genericService.getAllFeatures(this.currentUser.serviceLyrInfo.tableAliasId);
  }
  getELifeBaseLifeConfig(layerID) {
    return this.genericService.getAllFeatures(layerID);
  }
  getELUnquieField(layerId, fieldName) {
    return this.genericService.getUniqueValuesByField(layerId, fieldName);
  }
  getELSheetingType(layerId, fieldName, value) {
    return this.genericService.getFilteredFeaturesByField(layerId, fieldName, value);
  }
  getTableValues(layerId, fieldName, value) {
    return this.genericService.getFilteredFeaturesByField(layerId, fieldName, value);
  }
  getElifeTableValues(layerId) {
    return this.genericService.getAllFeatures(layerId);
  }
  getDomainValues() {
    return this.genericService.getDomainValues();
  }
  postExpectedLifeValues(UserId, Obj) {
    return this.genericService.postExpectedLife(UserId, Obj);
  }
  // Added by Abhilash for JIRA ID:-27 JIRA ID:-29 on 08/18/2021
  getTaskSummary() {
    return this.genericService.getAllFeatures(this.currentUser.serviceLyrInfo.maintenanceConfigId);
  }
}
