<ul ngbNav #adminNav="ngbNav" class="nav nav-pills nav-justified" [(activeId)]="active">
  <li [ngbNavItem]="1" title="Agency Configuration">
    <a ngbNavLink>Agency Configuration</a>
    <ng-template ngbNavContent>
      <sms-agencyConfig></sms-agencyConfig>
    </ng-template>
  </li>
  <!-- //Added for Visualization layers-->
  <li [ngbNavItem]="2" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Reference Layer">
    <a ngbNavLink>Reference Layer</a>
    <ng-template ngbNavContent>
      <sms-visualizationDataLayer></sms-visualizationDataLayer>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Asset Configuration">
    <a ngbNavLink>Asset Configuration</a>
    <ng-template ngbNavContent>
      <sms-assetConfig></sms-assetConfig>
    </ng-template>
  </li>
  <li [ngbNavItem]="4" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Expected Life">
    <a ngbNavLink>Expected Life</a>
    <ng-template ngbNavContent>
      <sms-ExpectedLife></sms-ExpectedLife>
    </ng-template>
  </li>
  <li [ngbNavItem]="5" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Terms and Condition">
    <a ngbNavLink>Terms and Condition</a>
    <ng-template ngbNavContent>
      <sms-TandC></sms-TandC>
    </ng-template>
  </li>
  <li [ngbNavItem]="6" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Predefined Query">
    <a ngbNavLink>Predefined Query</a>
    <ng-template ngbNavContent>
      <sms-PredefinedQuery (data)="showQueryData($event)"></sms-PredefinedQuery>
    </ng-template>
  </li>
  <li [ngbNavItem]="7" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="System Report">
    <a ngbNavLink>System Report</a>
    <ng-template ngbNavContent>
      <sms-systemReport></sms-systemReport>
    </ng-template>
  </li>
  <li [ngbNavItem]="8" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Resource Requirements">
    <a ngbNavLink>Resource Requirements</a>
    <ng-template ngbNavContent>
      <sms-ResourceRequrie></sms-ResourceRequrie>
    </ng-template>
  </li>
  <li [ngbNavItem]="9" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Password Config">
    <a ngbNavLink>Password Config</a>
    <ng-template ngbNavContent>
      <sms-passwordConfig></sms-passwordConfig>
    </ng-template>
  </li>
  <li [ngbNavItem]="10" *ngIf="adminGlobal.agencyDetails !== undefined && adminGlobal.agencyDetails.length > 0" title="Summary">
    <a ngbNavLink>Summary</a>
    <ng-template ngbNavContent>
      <sms-summary></sms-summary>
    </ng-template>
  </li>
  <div style="width: 100%" [ngbNavOutlet]="adminNav"></div>
</ul>
