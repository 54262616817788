<div class="card-body">
  <div class="h6" style="display: flex; justify-content: space-evenly">
    <div style="padding-top: 16px">{{ "Systemreports.assetdetail.desctext" | translate }}</div>

    <mat-form-field appearance="outline">
      <mat-label>{{ "Systemreports.headerassetdetail.assetid" | translate }}</mat-label>
      <input
        id="textAssetID"
        matInput
        type="text"
        [(ngModel)]="AssetIdSelected"
        placeholder="Asset Id"
        (change)="reset()"
        autocomplete="on"
        [attr.aria-label]="'Textbox for ' + 'Systemreports.headerassetdetail.assetid' | translate"
      />
    </mat-form-field>
  </div>
  <div class="card-footer text-right" style="background-color: white">
    <button [attr.aria-label]="'' + 'Systemreports.btn.generatereport' | translate" (click)="GetAssetDetails()" class="report-button h7" mat-button>
      <mat-icon variant="outlined" class="material-icons-outlined" class="report-icon" svgIcon="report"></mat-icon>{{ "Systemreports.btn.generatereport" | translate }}
    </button>
  </div>
</div>
