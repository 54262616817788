<div class="card-body">
  {{ "Systemreports.employeelog.employeeactionlog" | translate }}
  <div class="form-row pb-5">
    <div class="col-3"></div>
    <div class="col-2">
      <span id="empLog_fDateLabelID">{{ "Systemreports.employeelog.fromdate" | translate }}:</span>
    </div>
    <div class="col-7">
      <div class="form-group d-flex">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Systemreports.employeelog.fromdate" | translate }}</mat-label>
          <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [max]="endDate" [(ngModel)]="startDate" autocomplete="off" />

          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form-row pb-5">
    <div class="col-3"></div>
    <div class="col-2">
      <span id="empLog_tDatelabelID">{{ "Systemreports.employeelog.todate" | translate }}:</span>
    </div>
    <div class="col-7">
      <div class="form-group d-flex">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Systemreports.employeelog.todate" | translate }}</mat-label>
          <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [max]="todayDate" [min]="startDate" [(ngModel)]="endDate" autocomplete="off" />

          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="card-footer text-right">
  <button type="button" class="btn btn-primary" [attr.aria-label]="'' + 'Systemreports.btn.generatereport' | translate" (click)="openReports()">{{ "Systemreports.btn.generatereport" | translate }}</button>
</div>
