import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { QueryBuilder } from "src/app/services/queryBuilderService.service";
import { QueryBuilderConstants } from "src/app/querybuilder/queryBuilderConstants";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import * as deepEqual from "deep-equal";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";
import { GenericLayerService } from "src/app/services/genericLayer.service";
import { QueryLayers } from "src/app/services/queryLayers.service";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { faCalendarAlt as faCalendar, faPlusSquare, faMinusSquare, faTrashAlt, faSave } from "@fortawesome/free-regular-svg-icons";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
export interface Querytable {
  jointype: string;
  layer: string;
  field: "";
  operator: string;
  value: string;
}

@Component({
  selector: "sms-MMMQuery",
  templateUrl: "./MMMQuery.component.html",
  styleUrls: ["../../administrator.component.scss"]
})
export class MMMQueryComponent implements OnInit {
  constructor(
    public auth: AuthenticationService,
    public esriMapTool: EsriMapComponent,
    public qb: QueryBuilder,
    public translateService: TranslateService,
    public assetAttributesService: AssetAttributesService,
    public constants: QueryBuilderConstants,
    public genericService: GenericLayerService,
    public query: QueryLayers,
    public adminGlobalobj: adminGlobal,
    public adminService: MMMAdminService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }
  faPlusSquare = faPlusSquare;
  faPencilSquare = faPenSquare;
  faCalendar = faCalendar;
  faTrash = faTrashAlt;
  trash = faTrashAlt;
  faSave = faSave;
  faMinusSquare = faMinusSquare;

  public currentUser: any;
  isResultPresent: boolean;
  public todayDate: Date = new Date();
  public savedQueriesList: any;
  public layersfields: any[] = [];
  public inventoryLayersData: any;
  public field: any[] = [];
  public selectedlayr: any;
  public fields: any;
  public oprtrs: any[] = [];
  public jointypes: any[] = [];
  public showRecordCount = false;
  public slData = [];

  public selectedLoadFile: string;

  public querytableArr: any[] = [];

  public fileName: any;
  public querytable: Querytable = {} as Querytable;

  public jointype: any;

  public codedValues: any[] = [];
  public codedvalue: any;
  public layr: any;
  public layerList: any[] = [];

  public operator: any;
  public enableDateTimePicker: any;
  public lyrfld: any;

  public index: any;
  public isRowClicked: boolean;

  public selectionGraphicsLyr: any;
  public queryDataObj = [];
  @Output() data = new EventEmitter();

  public multiplePrimaryLayerQuery: any;
  public objectIds: any[] = [];
  public loading = false;
  public multipleResults = false;

  public multipleResultCounts = [];

  /** Save from query table to database */
  public queryDescription: string;

  public queryAssigned: any = "PQ-A";
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngOnInit() {
    // All Layers Data
    this.qb.getsldata(this.esriMapTool.Query, this.esriMapTool.QueryTask, this.esriMapTool.FeatureLayer).then(
      function (result) {
        this.slData = result.features;
        this.loadLayers().then((ldataresult) => {
          this.inventoryLayersData = ldataresult;
          console.log(this.inventoryLayersData);
          this.savedQueriesList = this.getSavedQueries("PQ");
          this.savedQueriesList.concat(this.getSavedQueries("PQ-A"));
          this.initQBServiceVariables();
          this.layerList = this.qb.getDropDownValues();
          this.layr = this.layerList[0];
          this.layr.layer_id.lyrObj.fields.forEach((fld) => {
            this.field.push({
              Field: fld.name,
              FieldAlias: fld.alias
            });
          });
          this.oprtrs = this.constants.getOperators();
          this.jointypes = this.constants.getJoinType();
          this.defaultDropdownValues();
        });
      }.bind(this)
    );
  }
  loadLayers() {
    try {
      return new Promise((resolve) => {
        const featureLayerOptions = { mode: this.esriMapTool.FeatureLayer.MODE_ONDEMAND, outFields: ["*"] };
        const inventory = [];
        this.adminService.getAllLayersInformation(this.adminGlobalobj.agencyDetails[0].webServiceUrl).subscribe((result) => {
          console.log(result);
          // allLayers eslint camel case fix
          const allLayers: any = result;
          const inventoryLayer = allLayers.layers.filter((layer) => layer.name === "Inventory")[0];
          inventoryLayer.subLayerIds.forEach((id) => {
            const temp = allLayers.layers.filter((ids) => ids.id === id);
            const flObj: any = new this.esriMapTool.FeatureLayer(this.adminGlobalobj.agencyDetails[0].webServiceUrl + environment.featureServer + id, featureLayerOptions);
            this.adminService.getDomainValuesFields(this.adminGlobalobj.agencyDetails[0].webServiceUrl, temp[0].id).subscribe((result: any) => {
              console.log(result);
              flObj.relationships = result.relationships;
              temp.layerName = temp[0].name;
              temp.lyrObj = flObj;
              // aliasValuesm eslint fix camel case
              const aliasValuesm = this.adminGlobalobj.ShowAsset.filter((val) => val.Name === temp[0].name);

              if (aliasValuesm && aliasValuesm.length > 0) {
                temp.tableAlias = aliasValuesm[0].Alias;
                temp.tableSL = aliasValuesm[0].Alias_SL;
              }

              inventory.push(temp);

              if (inventory.length === inventoryLayer.subLayerIds.length) {
                console.log(this.adminGlobalobj.ShowAsset);
                console.log(inventory);
                const filtered = [];
                this.adminGlobalobj.ShowAsset.forEach((assetClass) => {
                  filtered.push(inventory.filter((inv) => inv.layerName === assetClass.Name)[0]);
                });
                resolve(filtered);
              }
            });
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  getSavedQueries(queryTool) {
    try {
      queryTool = this.queryAssigned;
      const userActionsQueryLayer = this.adminGlobalobj.agencyDetails[0].webServiceUrl + environment.featureServer + this.adminGlobalobj.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new this.esriMapTool.FeatureLayer(userActionsQueryLayer);
      const promise = this.qb.getSavedQueryWithoutUser(userActionsQueryLayerObj, this.esriMapTool.Query, this.esriMapTool.QueryTask, queryTool); // Populating Default Values in New Asset
      const savedQueryNames = [];
      const savedQueryList = [];
      promise.then(
        function (result) {
          if (result) {
            savedQueryNames.push(result);
            savedQueryNames[0].features.forEach((savedQuery) => {
              if (savedQuery.attributes.QueryName != null) {
                savedQueryList.push({
                  fileName: savedQuery.attributes.QueryName,
                  queryString: savedQuery.attributes.Query,
                  queryDescription: savedQuery.attributes.QueryDescription,
                  queryTool: savedQuery.attributes.QueryTool
                });
              }
            });
          }
        }.bind(this)
      );
      return savedQueryList;
    } catch (error) {
      console.error(error);
    }
  }
  onFileNameChange(event, queryTool) {
    try {
      this.selectedLoadFile = event;
      this.populateQueryTable(this.selectedLoadFile, queryTool);
    } catch (error) {
      console.error(error);
    }
  }
  populateQueryTable(selectedFile, queryTool) {
    try {
      if (queryTool === "PQ-A" || queryTool === "PQ") {
        this.querytableArr = [];
        const queryStringSelected = this.savedQueriesList.filter((savedQuery) => savedQuery.fileName === this.selectedLoadFile);
        this.parseQuery(queryStringSelected);
        // Get Layer Name
        let layer = queryStringSelected[0].queryString.split("?")[0].split(":")[0];
        // primaryLayer eslint fix camel case
        let primaryLayer;

        if (layer === "Sign" || layer === "Light" || layer === "Maintenance" || layer === "Assessment" || layer === "Activity") {
          primaryLayer = layer;
          layer = "Support";
        }

        const layerListRelated = this.qb.getDropDownValuesLayer(layer, this.inventoryLayersData, this.esriMapTool.FeatureLayer);
        this.qb.disableLayerDropdown(layerListRelated, primaryLayer);
        this.queryDescription = queryStringSelected[0].queryDescription;
        this.queryAssigned = queryStringSelected[0].queryTool;
      }
    } catch (error) {
      console.error(error);
    }
  }
  parseQuery(queryStringSelected) {
    this.querytableArr = [];

    try {
      const queryString = queryStringSelected[0].queryString;
      this.fileName = queryStringSelected[0].fileName;
      let queries = [];
      queries = queryString.split("?");
      const layers = queries[0].split(":");
      const clauses = queries[1].split(";");

      for (let i = 1; i < clauses.length; i++) {
        this.querytable = {} as Querytable;
        const conditionSplit = clauses[i].trim().split(" ");

        if (conditionSplit.length === 3) {
          // tablePrefix eslint fix
          const tablePrefixM = conditionSplit[0].split(".")[0];
          this.querytable.layer = this.constants.getQueryTables(tablePrefixM, layers[0]);
          const fieldName = conditionSplit[0].split(".");
          this.querytable.field = fieldName[1];
          this.querytable.operator = conditionSplit[1];
          this.querytable.value = conditionSplit[2].replace(/'/g, "");
        } else {
          const tablePrefixM = conditionSplit[1].split(".")[0];
          this.querytable.layer = this.constants.getQueryTables(tablePrefixM, layers[0]);
          this.querytable.jointype = conditionSplit[0];
          const fieldName = conditionSplit[1].split(".");
          this.querytable.field = fieldName[1];
          this.querytable.operator = conditionSplit[2];
          this.querytable.value = conditionSplit[3].replace(/'/g, "");
        }

        this.querytableArr.push(Object.create(this.querytable));
      }
    } catch (err) {
      if (err) {
        Swal.fire("Invalid Format");
      }
    }
  }
  onJoinChange(event) {
    this.jointype = event;
  }
  onLayerChange(event) {
    try {
      this.selectedlayr = event; /** Set Selected Layer */

      if (this.selectedlayr) {
        /** Reset Fields and Values if layers is changed */
        this.fields = "";
        this.field = [];
        this.codedValues = [];
        this.codedvalue = "";
        this.layr = this.layerList.filter((astCls) => astCls.Layer === this.selectedlayr);
        this.layr[0].layer_id.lyrObj.fields.forEach((fld) => {
          this.field.push({
            Field: fld.name,
            FieldAlias: fld.alias
          });
        });
      }

      return this.field;
    } catch (error) {
      console.error(error);
    }
  }
  onFieldChange(event) {
    try {
      this.fields = event;
      this.operator = "=";
      this.lyrfld = this.layr[0].layer_id.lyrObj._fields.filter((fld) => fld.alias === this.fields);
      this.codedValues = [];
      this.codedvalue = "";
      this.enableDateTimePicker = false;

      if (this.constants.userFields.includes(this.fields)) {
        const codedValues = [];
        this.qb.getWorkerData(this.fields, this.esriMapTool.Query, this.esriMapTool.QueryTask, this.layr, this.currentUser).then((result) => {
          const workers: any = result;
          workers.features.forEach((worker) => {
            codedValues.push({ name: worker.attributes[this.fields], value: worker });

            if (codedValues.length === workers.features.length) {
              this.codedValues = codedValues.filter((cv) => cv.name != null);
            }
          });
        });
      }

      if (this.fields.includes("Date")) {
        this.enableDateTimePicker = true;
      } // UC  – 20134:

      if (this.lyrfld[0] && this.lyrfld[0].domain) {
        this.codedValues = this.lyrfld[0].domain.codedValues;
      }

      if (this.fields === "Activity") {
        // for all primary layers if only activity is selected
        const baseurl = this.adminGlobalobj.agencyDetails[0].webServiceUrl + environment.featureServer + this.adminGlobalobj.serviceLyrInfo.activityId;
        const activityLayer = new this.esriMapTool.FeatureLayer(baseurl);
        const promise = this.qb.getActivityDomains(activityLayer);
        const activityDomainsList = [];
        promise.then((results) => {
          activityDomainsList.push(results);
          this.codedValues = activityDomainsList[0];
        });
      }

      return this.codedValues;
    } catch (error) {
      console.error(error);
    }
  }
  onValueChange(event) {
    try {
      if (event instanceof Date) {
        const month = event.getMonth();
        this.codedvalue = event.getFullYear() + "-" + (month + 1) + "-" + event.getDate();
        this.qb.selectedDate = event;
      } else {
        this.codedvalue = event;
      }
    } catch (error) {
      console.error(error);
    }
  }
  onOperatorChange(event) {
    this.operator = event;
  }
  defaultDropdownValues() {
    try {
      this.selectedlayr = this.layerList[0].Layer;
      this.layr = this.layerList.filter((astCls) => astCls.Layer === this.selectedlayr);
      this.fields = this.field[0].FieldAlias;
      this.operator = "=";
    } catch (error) {
      console.error(error);
    }
  }
  initQBServiceVariables() {
    try {
      this.qb.map = this.esriMapTool.map;
      this.qb.Query = this.esriMapTool.Query;
      (this.qb.QueryTask = this.esriMapTool.QueryTask), (this.qb.RelationshipQuery = this.esriMapTool.RelationshipQuery);
      (this.qb.inventoryLayersData = this.inventoryLayersData), (this.qb.FeatureLayer = this.esriMapTool.FeatureLayer);
      this.qb.aliasData = this.slData;
    } catch (error) {
      console.error(error);
    }
  }
  addItem() {
    try {
      /** Create a new row to Insert into table */
      this.querytable = {} as Querytable;
      /** Add selected data into table row */
      this.querytable.field = this.fields;
      this.querytable.layer = this.selectedlayr;
      this.querytable.operator = this.operator;

      if (this.codedvalue instanceof Date) {
        const formattedDate = this.codedvalue.getMonth() + 1 + "-" + this.codedvalue.getDate() + "-" + this.codedvalue.getFullYear();
        this.querytable.value = formattedDate;
      } else {
        if (this.fields === "AssetCode") {
          this.qb.assetCode = this.codedValues;
        }

        this.querytable.value = this.codedvalue;
      }

      if (this.jointype) {
        this.querytable.jointype = this.jointype;
      } else {
        this.querytable.jointype = undefined;
      }

      if (this.querytableArr.length > 0 && !this.querytable.jointype) {
        Swal.fire("Please add join type");
        return;
      }

      if (this.querytable.field && this.querytable.layer && this.querytable.operator && this.querytable.value) {
        // Refresh DropdownList to Fetch related Tables : Neha 1/10
        if (this.querytable.layer !== "Activity" && this.querytable.layer !== "Assessment" && this.querytable.layer !== "Maintenance" && this.querytable.layer !== "Light" && this.querytable.layer !== "Sign") {
          const layerListRelated = this.qb.getDropDownValuesLayer(this.querytable.layer, this.inventoryLayersData, this.esriMapTool.FeatureLayer);
          this.qb.disableLayerDropdown(layerListRelated, "");
        }

        if (this.querytableArr.length === 1) {
          const joinTemp = this.querytable.jointype;
          this.querytable.jointype = undefined;
          const sameQueryValidation = this.querytableArr.filter((fil) => deepEqual(this.querytable, fil.__proto__));

          if (sameQueryValidation.length >= 1) {
            Swal.fire("Query cannot be same");
            return;
          }

          this.querytable.jointype = joinTemp;
        } else {
          const sameQueryValidation = this.querytableArr.filter((fil) => deepEqual(this.querytable, fil.__proto__));

          if (sameQueryValidation.length >= 1) {
            Swal.fire("Query cannot be same");
            return;
          }
        }

        // End
        this.querytableArr.push(Object.create(this.querytable));
        this.querytable = {} as Querytable;

        /** Dont show Primary layers when Sign or Light is selectethis.querytable = <Querytable>{};d first */
        if (this.querytableArr.length === 1 && (this.querytableArr[0].layer === "Sign" || this.querytableArr[0].layer === "Light")) {
          const layerListRelated = this.qb.getDropDownValuesLayer("Support", this.inventoryLayersData, this.esriMapTool.FeatureLayer);
          this.qb.disableLayerDropdown(layerListRelated, this.querytableArr[0].layer);
        }

        /** Dont show Primary layers or Asset Layers when Assessment, Maintenance or Activity Selected first */
        if (this.querytableArr.length === 1 && (this.querytableArr[0].layer === "Maintenance" || this.querytableArr[0].layer === "Assessment" || this.querytableArr[0].layer === "Activity")) {
          const layerListRelated = this.qb.getDropDownValuesLayer("Support", this.inventoryLayersData, this.esriMapTool.FeatureLayer);
          this.qb.disableLayerDropdown(layerListRelated, this.querytableArr[0].layer);
        }
      } else {
        // Default Layers List : Neha 1/10
        this.layerList = this.qb.getDropDownValues();
        Swal.fire("Enter all values");
      }

      // this.lyrfld[0].empty();
    } catch (error) {
      console.error(error);
    }
  }
  setClickedRow(index) {
    try {
      this.index = index;
      // Set Clicked Row
      this.isRowClicked = true;
      // Set all Drop down Values
      this.field = this.onLayerChange(this.querytableArr[index].layer);
      this.codedValues = this.onFieldChange(this.querytableArr[index].field);

      // Set SearchFields
      this.selectedlayr = this.querytableArr[index].layer;
      this.fields = this.querytableArr[index].field;

      if (this.enableDateTimePicker) {
        this.codedvalue = new Date(this.querytableArr[index].value);
      } else {
        this.codedvalue = this.querytableArr[index].value;
      }

      this.operator = this.querytableArr[index].operator;
      this.jointype = this.querytableArr[index].jointype;
    } catch (error) {
      console.error(error);
    }
  }

  emptySearchFields() {
    this.jointype = "";
    this.selectedlayr = "";
    this.codedvalue = "";
    this.operator = "";
    this.fields = "";
  }

  deleteFieldValue(i) {
    if (this.querytableArr.length > 1 && i === 0) {
      Swal.fire("Cannot delete primary Query");
      return;
    }

    this.querytableArr.splice(i, 1);

    if (this.querytableArr.length === 0) {
      /** Default values in Search Fields */
      this.emptySearchFields();
      /** Join type should be null */
      this.querytable.jointype = null;
      /** Saved Query Filename text box empty */
      this.fileName = "";
      /** Reload all Saved Queries */
      this.savedQueriesList = this.getSavedQueries("PQ-A");
      this.savedQueriesList.concat(this.getSavedQueries("PQ"));
      /* Empty Fields and Values*/
      this.field = [];
      this.codedValues = [];
      // Cannot edit Hide Button
      this.isRowClicked = false;
      this.showRecordCount = false;
      this.queryDescription = "";
      // Clear Prev Selected for Query Behavior
      /** Reload Layers */
      this.layerList = this.qb.getDropDownValues();
      this.selectedlayr = this.layerList[0].Layer;
      this.layr = this.layerList.filter((astCls) => astCls.Layer === this.selectedlayr);
      this.layr[0].layer_id.lyrObj.fields.forEach((fld) => {
        this.field.push({
          Field: fld.name,
          FieldAlias: fld.alias
        });
      });
      this.fields = this.field[0].Field;
      this.onFieldChange(this.fields);
    } else if (this.querytableArr.length >= 1) {
      this.qb.getDropDownValuesLayer(this.querytable.layer, this.inventoryLayersData, this.esriMapTool.FeatureLayer);
      this.qb.disableLayerDropdown(this.querytable.layer, "");
    } else {
      this.layerList = this.qb.getDropDownValues();
    }
  }
  cleartable() {
    try {
      this.layerList = this.qb.getDropDownValues();
      this.emptySearchFields();
      this.querytableArr = [];
      this.selectionGraphicsLyr = this.esriMapTool.map.getLayer("sms-selection");

      if (this.selectionGraphicsLyr) {
        this.selectionGraphicsLyr.clear();
      }

      this.esriMapTool.map.graphics.clear();
      this.queryDataObj = [];
      this.data.emit([]);
      this.fileName = "";
      this.selectedLoadFile = "";
      this.isRowClicked = false;
      this.showRecordCount = false;
      this.queryDescription = "";
    } catch (error) {
      console.error(error);
    }
  }

  editItem() {
    try {
      this.querytable = {} as Querytable;

      this.querytable.layer = this.selectedlayr;
      this.querytable.field = this.fields;

      if (this.enableDateTimePicker) {
        const formattedDate = this.codedvalue.getMonth() + 1 + "-" + this.codedvalue.getDate() + "-" + this.codedvalue.getFullYear();
        this.querytable.value = formattedDate;
      } else {
        if (this.fields === "AssetCode") {
          this.qb.assetCode = this.codedValues;
        }

        this.querytable.value = this.codedvalue;
      }

      this.querytable.operator = this.operator;

      if (this.querytable.layer && this.querytable.field && this.querytable.operator && this.querytable.value) {
        if (this.index === 0) {
          if (this.querytable.jointype) {
            this.jointype = null;
            Swal.fire("Cannot edit Join type for primary Query");
            return;
          }

          this.querytable.jointype = undefined;
        } else {
          this.querytable.jointype = this.jointype;
        }

        this.querytableArr[this.index] = this.querytable;
        this.isRowClicked = false;
      } else {
        Swal.fire("Enter all Values");
      }
    } catch (error) {
      console.error(error);
    }
  }
  buildQuery() {
    try {
      this.loading = true;
      this.multipleResults = false;

      if (this.multiplePrimaryLayerQuery && this.multiplePrimaryLayerQuery.queryId && (this.multiplePrimaryLayerQuery.queryId === "QRY_10" || this.multiplePrimaryLayerQuery.queryId === "QRY_11")) {
        // this.buildQueryMultiple();
        return;
      }

      // Set Service Variables
      this.selectionGraphicsLyr = this.esriMapTool.map.getLayer("sms-selection");

      if (this.selectionGraphicsLyr) {
        this.selectionGraphicsLyr.clear();
      }

      // Set Resutts Object to empty : Refresh results object for new Query
      this.queryDataObj = [];
      // Hide Card View if already opened
      this.data.emit(this.queryDataObj);
      this.objectIds = [];

      if (this.querytableArr.length === 0) {
        Swal.fire("Please add a query to execute");
        this.loading = false;
        return;
      } else if (this.querytableArr.length === 1 && this.querytableArr[0].jointype != null) {
        this.jointype = null;
        Swal.fire("Query cannot start with a jointype");
        this.loading = false;
        return;
      }

      if (this.querytableArr[0].jointype != null) {
        this.jointype = null;
        Swal.fire("Query cannot start with a jointype");
        this.loading = false;
        return;
      }

      this.loading = true;
      const queryString = this.qb.createQueryString(this.querytableArr, this.currentUser);
      const functionname = "test";
      this.genericService.getQueryResults(queryString, functionname).subscribe(
        function (results: any) {
          if (results && results.table && results.table.length > 0) {
            results.table.forEach((v) => {
              this.objectIds.push(v.objectid);
            });
          }

          if (this.objectIds.length === 0) {
            Swal.fire("No records found");
            this.loading = false;
            this.showRecordCount = false;
            return;
          }

          this.loading = false;

          if (this.querytableArr[0].layer === "Assessment" || this.querytableArr[0].layer === "Maintenance" || this.querytableArr[0].layer === "Activity") {
            this.multipleResults = true;
            this.buildQueryMultiple();
            return;
          }

          this.loading = false;
          this.showRecordCount = true;
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  buildQueryMultiple() {
    try {
      this.multipleResults = true;
      this.loading = true;
      // Set Resutts Object to empty : Refresh results object for new Query
      this.queryDataObj = [];

      if (this.querytableArr.length === 0) {
        Swal.fire("Please add a query to execute");
        return;
      } else if (this.querytableArr.length === 1 && this.querytableArr[0].jointype != null) {
        this.querytableArr[0].jointype = "";
        Swal.fire("Query cannot start with a jointype");
      } else {
        // Get QueryString to fetch Data
        const queryString = this.qb.createQueryString(this.querytableArr, this.currentUser);
        const functionname = "test";
        this.genericService.getQueryResults(queryString, functionname).subscribe((results: any) => {
          console.log(results);

          if (results.table.length === 0) {
            this.showRecordCount = false;
            this.loading = false;
            Swal.fire("No recourds Found");
            return;
          }

          this.showRecordCount = true;
          this.loading = false;
          const allResults = [];

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < results.table.length; i++) {
            allResults.push({
              layerName: results.table[i].layerName,
              dataLength: results.table[i].objectid.split(",").length
            });
          }

          this.multipleResultCounts = allResults;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  saveQuery(queryTool) {
    try {
      queryTool = this.queryAssigned;

      if (this.queryDescription == null || this.queryDescription === "") {
        Swal.fire("Please enter Query Description before saving");
        return;
      }

      if (this.fileName == null || this.fileName === "") {
        Swal.fire("Please enter Query Name before saving");
        return;
      }

      /*Validate if file Name already present*/
      const validateFileName = this.savedQueriesList.filter((savedQuery) => savedQuery.fileName === this.fileName);

      if (this.querytableArr.length === 0) {
        Swal.fire("Add the query before Saving");
        return;
      }

      const queryString = this.qb.createQueryString(this.querytableArr, this.currentUser);

      if (validateFileName.length > 0) {
        Swal.fire("Query Name cannot be duplicate");
        return;
      }

      this.qb.saveQuery(this.fileName, this.esriMapTool.FeatureLayer, queryString, this.esriMapTool.Graphic, queryTool, this.queryDescription).then(
        function (result) {
          this.savedQueriesList = this.getSavedQueries("PQ");
          this.savedQueriesList.concat(this.getSavedQueries("PQ-A"));
          Swal.fire("Query Saved to the database");
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  assignToUser(event) {
    try {
      if (event.target.checked === true) {
        this.queryAssigned = "PQ-A";
      } else {
        this.queryAssigned = "PQ";
      }

      if (this.fileName) {
        this.updateQuery(this.queryAssigned, this.fileName);
      } else {
        Swal.fire("Please select a query To assign");
      }
    } catch (error) {
      console.error(error);
    }
  }
  updateQuery(queryAssigned, fileName) {
    try {
      const query = this.esriMapTool.Query();
      const layerobj = new this.esriMapTool.FeatureLayer(this.adminGlobalobj.agencyDetails[0].webServiceUrl + environment.featureServer + this.adminGlobalobj.serviceLyrInfo.userQueryActionsId);
      const queryTask = this.esriMapTool.QueryTask(layerobj.url);
      query.where = "QueryName = '" + fileName + "'";
      query.outFields = ["OBJECTID", "QueryTool"];
      queryTask.execute(
        query,
        function (result) {
          console.log(result);
          result.features[0].attributes.QueryTool = queryAssigned;
          layerobj.applyEdits(null, [result.features[0]], null);
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  deleteQuery(queryTool) {
    try {
      if (this.fileName == null || this.fileName === "") {
        Swal.fire("Enter a Query Name to delete");
        return;
      }

      const checkFilePresent = this.savedQueriesList.filter((list) => list.fileName === this.fileName);

      if (checkFilePresent.length <= 0) {
        Swal.fire("Entered Query Name not available in database");
        return;
      }

      const userActionsQueryLayer = this.adminGlobalobj.agencyDetails[0].webServiceUrl + environment.featureServer + this.adminGlobalobj.serviceLyrInfo.userQueryActionsId;
      const userActionsQueryLayerObj = new this.esriMapTool.FeatureLayer(userActionsQueryLayer);
      const promise = this.qb.deleteQuery(this.esriMapTool.FeatureLayer, this.fileName, this.esriMapTool.Query, this.esriMapTool.QueryTask, queryTool);
      let deleteRecord;
      promise.then(
        function (result) {
          if (result) {
            deleteRecord = result;

            if (deleteRecord.length === 0) {
              Swal.fire("No Such Query Name available");
              return;
            }

            this.query.deleteAsset(userActionsQueryLayerObj, deleteRecord.features[0]).then(
              function (result) {
                if (result) {
                  Swal.fire("Successfully Deleted");
                  this.savedQueriesList = this.getSavedQueries("PQ");
                  this.savedQueriesList.concat(this.getSavedQueries("PQ-A"));
                  this.querytableArr = [];
                  this.fileName = "";
                }
              }.bind(this)
            );
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
}
