
<div class="card">
  <div class="card-body formbg-d2">
<div class="row" style="margin-top: 10px;margin-bottom: 10px;">

    <div class="col-2">
      <app-dropdown aria-label="select region" [label]="'Organisation'" [dropdownList]="regions" [val]="selectedregion"
        [isRegion]="true" (select)="onRegionChange($event)"> </app-dropdown>
    
    </div>
    <div class="col-2">
      <app-dropdown aria-label="select asset" [label]="'Type'" [dropdownList]="asset" [val]="selectedAssetClass"
        [isCrewManagement]="true" (select)="onAssetChange($event)"> </app-dropdown>
    </div>
  </div>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th style="width: 40%; text-align: left;">{{'Crewmanagement.scheduler.schedule'|translate}}</th>
          <th style="width: 30%;" class="text-center">{{'Crewmanagement.scheduler.numbertasks'|translate}}</th>
          <th style="width: 30%;" class="text-center">{{'Crewmanagement.scheduler.totalestimatedduration'|translate}}</th>
        </tr>
      </thead>
    </table>
  
    <div class="tablescroll">
      <table *ngIf="assignments" class="table">
        <tbody *ngIf="assignments.length >0" >
          <tr *ngFor="let assignment of assignments;let lid = index" (click) ="rowclicked(assignment,lid)" [class.active]="lid == selectedRow">
      
            <td style="width: 40%; text-align: left;" *ngIf="assignment" >{{assignment.schedule|translate}}</td>
            <td style="width: 30%;" *ngIf="assignment" class="text-center">{{assignment.no_of_tasks}}</td>
            <td style="width: 30%;" *ngIf="assignment" class="text-center">{{assignment.duration|hoursMinute}}</td>
          </tr>
          
        </tbody>
      </table>
    </div>
     
</div>
</div>