import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/services/user.service";
import Swal from "sweetalert2";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { LoginTranslateService } from "src/app/shared/login-translation.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Client } from "src/app/interface/client";
@Component({
  selector: "app-create-edit-user",
  templateUrl: "./create-edit-user.component.html",
  styleUrls: ["./create-edit-user.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CreateEditUserComponent implements OnInit {
  //inputs
  @Input() selectedUser;
  @Input() clientList;
  @Input() RoleList;
  @Input() agencyLanguage;
  //outputs
  @Output() saveUser = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();

  //public  variables
  createEditUserForm: UntypedFormGroup;
  submitted = false;
  locked = 0;
  faLock = faLock;
  //FORM CONSTANTS
  userActive = [
    {
      id: 0,
      Value: "Inactive"
    },
    {
      id: 1,
      Value: "Active"
    }
  ];

  userVisibility = [
    {
      id: 0,
      Value: "False"
    },
    {
      id: 1,
      Value: "True"
    }
  ];

  RegionRole = [
    {
      role: 1,
      roleName: this.translateService.instant("UserAdmin.DataEditor")
    },
    {
      role: 2,
      roleName: this.translateService.instant("UserAdmin.ReadOnly")
    }
  ];
  constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private userService: UserService, public translate: LoginTranslateService,private authenticationService: AuthenticationService) {
    this.userService.getClientListUser(this.authenticationService.currentUserValue.clientId).subscribe((res: Client) => {
      this.authenticationService.setPasswordRules(res);
      this.authenticationService.updatePasswordFeedback();
    });
  }

  ngOnInit(): void {
    //instantiate form
    this.createEditUserForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      // TODO: check existing validators in api 
      //TSDRSAM-1687
      username: ["", [Validators.required, Validators.pattern(this.authenticationService.getUsernamePattern())]],
      password: ["", [Validators.required, Validators.pattern(this.authenticationService.passwordPattern)]],
      userRole: ["", Validators.required],
      userActiveSelected: [1, Validators.required],
      visiblity: [1, Validators.required],
      language: ["", Validators.required]  
    });
  }
  ngOnChanges(): void {
    //when we get passed a user id, set values of the form to the user obj passed in.
    if (this.selectedUser && this.selectedUser.userId) {
      this.f.firstName.setValue(this.selectedUser.firstName);
      this.f.lastName.setValue(this.selectedUser.lastName);
      this.f.username.setValue(this.selectedUser.username);
      this.f.userRole.setValue(this.selectedUser.roleId);
      this.f.visiblity.setValue(this.selectedUser.visible);
      this.f.language.setValue(this.selectedUser.languageID);
      this.f.userActiveSelected.setValue(this.selectedUser.active);

      this.f.password.setValue("");
      this.f.password.setValidators(Validators.pattern(this.authenticationService.passwordPattern));
      this.f.password.updateValueAndValidity();
      this.locked = this.selectedUser.locked; // Added by Abhilash Jira Id:-57 on 10/11/2021
    }
  }
  //shorthand method to get form controls object
  get f() {
    return this.createEditUserForm.controls;
  }
  onSubmit(): void {
    try {
      this.submitted = true;
      // stop here if form is invalid
      if (this.createEditUserForm.invalid) {
        return;
      }
      // call save user in parent class
      this.saveUser.emit({
        username: this.f.username.value,
        firstName: this.f.firstName.value,
        lastName: this.f.lastName.value,
        password: this.f.password.value,
        roleId: this.f.userRole.value,
        active: this.f.userActiveSelected.value,
        visible: this.f.visiblity.value,
        languageID: this.f.language.value
      });
    } catch (error) {
      console.error(error);
    }
  }

  //reset form and clear errors.
  clear(formDirective: FormGroupDirective): void {
    try {
      formDirective.resetForm();
      this.createEditUserForm.reset();
      this.createEditUserForm.markAsPristine;
      this.createEditUserForm.markAsUntouched;
      this.createEditUserForm.setErrors(null);
      this.createEditUserForm.updateValueAndValidity();
      Object.keys(this.createEditUserForm.controls).forEach((key) => {
        if (key == "userActiveSelected" || key == "visiblity") {
          this.createEditUserForm.get(key).setValue(1);
        }
        //clear errors on field
        this.createEditUserForm.get(key).markAsUntouched();
        this.createEditUserForm.get(key).markAsPristine();
        this.createEditUserForm.get(key).setErrors(null);
        this.createEditUserForm.get(key).updateValueAndValidity();
        //set default values of fields
      });
    } catch (error) {
      console.error(error);
    }
  }

  // clear and close modal ( calls parent to close )
  close(formDirective: FormGroupDirective): void {
    this.clear(formDirective);
    this.submitted = false;
    this.closeModal.emit();
    //Added for ADA
    let allElements = document.querySelectorAll('[data-uadmin="yes"]');
    if (allElements.length > 0) {
      allElements.forEach(element => {
        element.setAttribute('tabindex', '0');
      });
    }
  }
  unlockUser() {
    try {
      if (this.locked !== 0 && this.selectedUser) {
        this.userService.userAccountUnlock(this.selectedUser.userId).subscribe((res: any) => {
          // Added by Abhilash Jira Id:-57 on 10/11/2021
          if (res && res.message == "User.AccountUnlocked") {
            this.locked = 0;
            Swal.fire(this.translateService.instant(res.message));
          } else {
            Swal.fire(this.translateService.instant("User.AccountUnlocked.failMessage"));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
