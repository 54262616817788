// import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// // import {CreateTaskComponent} from '../select/assetdetails/tasks/createtask/createtask.component';
// import { Globals } from '../select/globals';
// import { AuthenticationService } from '../services/authentication.service';
// import { GenericLayerService } from '../services/genericLayer.service';
// @Component({
//   templateUrl: './button.component.html',
//   selector: 'button-render',
//   providers: []
// })
// export class ButtonRenderComponent implements OnInit {
//   showtask = false; currentUser;
//   FeatureLayer; layerName;
//   Graphic;
//   task;
//   @Input() value: any;
//   @Input() rowData: any;

//   @Output() save: EventEmitter<any> = new EventEmitter();

//   constructor(
//     public objGlobals: Globals,
//     private authenticationService: AuthenticationService,
//     private genericLayerService: GenericLayerService
//   ) {

//     this.currentUser = this.authenticationService.currentUserValue;
//   }
//   ngOnInit(): void {
//     //console.log(this.value);
//     this.FeatureLayer = this.value.flayer;
//     this.Graphic = this.value.graphic;
//     this.layerName = this.value.row.asset_class;
//     //this.getSelectedRecord();
//   }
//   showTaksTab(evt) { this.showtask = evt; }
//   onClick() {
//     this.getSelectedRecord();
//     //this.objGlobals.selectedTask = this.task;

//     this.save.emit();

//   }

//   getSelectedRecord() {
//     let assetId = this.value.row.WorkOrder;

//     this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId,
//       'WorkOrder', assetId).subscribe((results: any) => {
//         this.objGlobals.selectedTask = results.features[0].attributes;
//         this.showtask = true;
//       })
//   }
// }

import { Component } from "@angular/core";

@Component({
  selector: "sms-cell-renderer",
  template: ` <a href="#" (click)="onClick($event)">{{ label }}</a> `
})
export class ButtonRenderComponent {
  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.value || null;
  }

  onClick($event) {
    try {
      // put anything into params u want pass into parents component

      console.log($event);
      const params = {
        event: $event,
        rowData: this.params
      };
      this.params.onClick(params.rowData);
    } catch (error) {
      console.error(error);
    }
  }
}
