import { Pipe, PipeTransform } from "@angular/core";
import { LoginTranslateService } from "src/app/shared/login-translation.service"; // our translate service

@Pipe({
  name: "ltranslate",
  pure: false
})
export class LoginTranslatePipe implements PipeTransform {
  constructor(private translate: LoginTranslateService) {}

  transform(value: string): any {
    if (!value) {
      return;
    }

    return this.translate.instant(value);
  }
}
