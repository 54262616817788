import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SystemReportsComponent } from "./system.reports.component";
import { ChartsModule } from "ng2-charts";
import { SystemReportsService } from "./system.reports.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DemocratizeAssetInformation } from "./democratizeAssetInformation/democratizeAssetInformation.component";
import { FormsModule } from "@angular/forms";
import { ActivityTotalsComponent } from "./activityTotals/activityTotals.component";
import { ActivityByReasonComponent } from "./activityByReason/activityByReason.component";
import { RetiredAssetsComponent } from "./retiredAssets/retiredAssets.component";
import { EmployeeLogComponent } from "./employeeLog/employeeLog.component";
import { AssetDetailComponent } from "./assetDetail/assetDetail.component";
import { replaceYearAssetClassComponent } from "./replaceYearAssetClass/replaceYearAssetClass.component";
import { BrushPickupComponent } from "./brushPickup/brushPickup.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SheetingTypeComponent } from "./sheetingType/sheetingType.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
//added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
import { CustomreportsARTESPComponent } from "./customreports-artesp/customreports-artesp.component";
// added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
import { MaintactivitysummaryComponent } from "./maintactivitysummary/maintactivitysummary.component";
import { CurrentConditionSummaryComponent } from "./current-condition-summary/current-condition-summary.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    SystemReportsComponent,
    DemocratizeAssetInformation,
    ActivityTotalsComponent,
    ActivityByReasonComponent,
    RetiredAssetsComponent,
    EmployeeLogComponent,
    AssetDetailComponent,
    replaceYearAssetClassComponent,
    BrushPickupComponent,
    SheetingTypeComponent,
    // added by sowjanya JIRA ID:-48,51,52 on 27/09/2021
    CustomreportsARTESPComponent,
    CurrentConditionSummaryComponent,
    MaintactivitysummaryComponent
  ],
  imports: [
    CommonModule,
    ChartsModule,
    NgbModule,
    FormsModule,
    TranslateModule,
    NgMultiSelectDropDownModule.forRoot(),
    FontAwesomeModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule
  ],
  exports: [SystemReportsComponent],
  providers: [SystemReportsService]
})
export class SystemReportsModule {
  static forRoot(): ModuleWithProviders<SystemReportsModule> {
    return { ngModule: SystemReportsModule, providers: [] };
  }
}
