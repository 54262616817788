/* eslint-disable camelcase */
// camel case fix for layer_id
import { Component, OnInit, Input, Renderer2, ViewEncapsulation, HostListener } from "@angular/core";
import { QueryLayers } from "../../services/queryLayers.service";
import { AssetDetailService } from "../../services/assetDetail.service";
import { Globals } from "../globals";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IUser } from "src/app/interface/user";
import { environment } from "src/environments/environment";
import { GenericLayerService } from "../../services/genericLayer.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { EsriMapComponent } from "src/app/esri-map/esri-map.component";
import { CardViewService } from "src/app/services/card-view.service";
import { AssetAttributesService } from "../../services/assetAttibutes.service";
import { EventEmitersService } from "../../services/eventEmiters.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import { faAngleDown, faAngleUp, faChevronLeft, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
//TSDRSAM-1099 By Manikantha  -->
import { isNumeric } from "rxjs/internal-compatibility";
import { GenericMapMethodsService } from "../../services/generic-map-methods.service";
import { BASE64_PREFIX } from "src/app/shared/utils";

enum paginationType {
  First,
  Previous,
  Next,
  Last
}
@Component({
  selector: "sms-select-cardview-features",
  templateUrl: "./cardview.component.html",
  styleUrls: ["../select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .dark-modal .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 127%;
        pointer-events: auto;
        /* background-color: #fff; */
        /* background-clip: padding-box; */
        /* border: 1px solid rgba(0, 0, 0, 0.2); */
        /* border-radius: 0.3rem; */
        background-color: transparent;
        outline: 0;
        align-items: flex-end;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 1) !important;
      }
      .dark-modal .close {
        color: white;
      }
      .light-blue-backdrop {
        background-color: #5cb3fd;
      }
    `
  ]
})

//OnChanges
export class CardViewComponent implements OnInit {
  // Objects
  // @ViewChild(AssetDetailComponent, { static: false })
  // assetDetails: AssetDetailComponent;
  // ******Input Variables***********
  // Map Related Classes Start
  @Input() AI: any;
  @Input() map;
  @Input() Editor: any;
  @Input() Query: any;
  @Input() RelationshipQuery;
  @Input() FeatureLayer;
  @Input() Draw: any; // Draw Point / Line / Polygon to add a new Feature
  @Input() webMercatorUtils: any; // Convert to Webmercator cordinates 4326
  @Input() Graphic: any;
  @Input() Color: any;
  @Input() SimpleMarkerSymbol: any;
  @Input() SimpleLineSymbol: any;
  @Input() Edit: any;
  @Input() QueryTask: any;
  @Input() geometryEngine: any;
  @Input() Polygon: any;
  @Input() geometryJsonUtils: any;
  @Input() isFromQB: any;
  @Input() Point: any;
  // Map Related Classes End

  // Icons
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faPlus = faPlus;

  faAngleRight = faAngleRight;
  faAngleLeft = faAngleLeft;

  // Data From Parent Start
  @Input() rowdataobj: any;
  @Input() layerData;
  @Input() regionData: any;
  @Input() attribs: any;
  // Data From Parent End

  selectedDomain;
  @Input() selectedIndex: any;
  //these 4 not present, added to make prod build happy
  activeAsset;
  featureLayer;
  asset;
  relatedAsset;
  relasstClickedES = [true, true, true, true, true];
  activeAssetId; //9/22/20 -- the support and a related sign can both have the same ObjectID (unique to their table), so using it to determine which is active for purposes of highlighting the card is problematic.  Refactor to use activeAssetID
  // Html Labels Start
  assetViewerLabel: any;
  // Html Labels Start
  // Sub Layer Access
  lightAddAccess;
  signAddAccess;
  // Sub Layers
  // show/hide  Html Elements
  showreshape;
  showAssetDtl = false;
  // End show/hide  Html Elements
  // Adding New Assets
  expand = this.translateService.instant("Select.carddisplay.expand");
  minimize = this.translateService.instant("Select.carddisplay.minimize");
  newChildId;
  dataEditorSettings;
  //   private isAssetNew = false;
  dropdownValues: any[] = [];
  relatedFeatures: any = [];
  // Selected Active Data
  // lyrSel;
  lyrObj: any;
  counter = 0;
  // userselLyr; // user selected layer for new assets
  selectedLayer: any = []; // 0 index- First Layer By default
  selectedCount; // Nuber of features in selected Layer - by default 0 index layer
  activeParentAsset; // active asset
  parenttasksCount; // number of tasks assigned to parent ;
  // layerName: any;
  layerId: any;
  childRecords;
  @Input() selLayerindex; // 0 by default
  selectedFeature;
  selectRelAsset;
  assetTypeId;
  rollbackData: any = {};
  // End
  // Language Related
  tableAliases: any = {};
  public scndlayer = false;
  // Language Related
  // Loader
  loading = false; // Loading Control //
  // Layer Object
  ftrLyrTblMaintenance;
  ftrLyrActivity;
  // Esri Tool Intractions
  draw: any;
  clickMap: any;
  selectionSymbol;
  // Events
  eventemitSelectedLayerIndexOnData;
  eventemitSelectedParentIndexOnData;
  eventemitSelectedFeaturesOnMap;
  eventgenericLayerService;

  eventEmitTaskCreated;
  eventEmitchildDeleted;
  eventEmitCloseModel;
  eventEmitTaskCompleted;
  eventEmitreplaceTaskCreated;
  //JIRA ID:-61
  isShown = true; // hidden by default

  // assessmentlyr
  assessmentlyr;
  currentUser: IUser;
  // Added by Abhilash for JIRA ID:-34 on 08/12/2021
  fieldsInfo = [];
  fieldsInfoLight = [];
  fieldsInfoSign = [];
  result;
  isDeleteClicked: boolean;
  showChildCard: boolean;
  title = "appBootstrap";

  closeResult: string;
  closeOpenModelService: any;
  mymodelIsVisible = false;
  counterVar;
  dotGraphics;
  optenTasksJson: any;
  newChildFeature: any;
  newChildFClass: any;

  //visualization layers
  eventemitSelectedData;
  visualizationLayersData;
  currentLayerData;
  activeVisualizationAsset = false;
  currentVisualizationDataIndex = 0;
  //
  reshapeIconDiv;
  relocateIconDiv;

  // User Variables End..........................
  //Added for ADA
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.target["localName"] === "div" && ["assestViewerbtn", "cardViewClose", "assetCard", "assetCard1", "assetCard2", "assetCard3", "assetCard4"].includes(event.target["id"])) {
      (<HTMLInputElement>event.target).click();
    }
  }
  //
  constructor(
    private query: QueryLayers,
    private genericLayerService: GenericLayerService,
    public objAssetDetailService: AssetDetailService,
    public objGlobal: Globals,
    private renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private cs: CardViewService,
    public esri: EsriMapComponent,
    private assetAttributesService: AssetAttributesService,
    public eventEmitersService: EventEmitersService,
    private modalService: NgbModal,
    private userService: UserService,
    //TSDRSAM-1099 By Manikantha  -->
    public genericMapMethodsService: GenericMapMethodsService
  ) {
    this.userService.getRoleslist().subscribe((evt) => {
      this.objGlobal.userRoles = evt;
    });
    this.closeOpenModelService = modalService;
    this.query.FeatureLayer = this.FeatureLayer;
    this.currentUser = this.authenticationService.currentUserValue;
    //TSDRSAM-1099 By Manikantha  -->
    this.genericMapMethodsService.currentUser = this.currentUser;
    this.setReplaceYearData();
    // Language
    this.eventgenericLayerService = this.genericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
      if (res.primaryLanguageID === this.currentUser.languageID) {
        this.scndlayer = false;
      } else if (res.secondaryLanguageID === this.currentUser.languageID) {
        this.scndlayer = true;
      }
    });
    //visualization layers
    this.eventemitSelectedData = this.eventEmitersService.emitSetVisualizationAssetViewer.subscribe((res) => {
      this.visualizationLayersData = res;
    });
    //
    this.eventemitSelectedFeaturesOnMap = this.eventEmitersService.emitSelectedFeaturesOnMap.subscribe((res) => {
      this.rowdataobj = res;
      if (this.selectedCount > 0) {
        this.reloadAssetViewer();
      }
    });
    this.eventemitSelectedParentIndexOnData = this.eventEmitersService.emitSelectedParentIndexOnData.subscribe((res) => {
      this.loadSelectedParentFeatureInView();
    });

    this.eventemitSelectedLayerIndexOnData = this.eventEmitersService.emitSelectedLayerIndexOnData.subscribe(
      function (result) {
        // #TFS 25016
        if (!this.rowdataobj) {
          this.selectedLayer = this.dropdownValues[this.eventEmitersService.selectedLayerIndexOnData].layer_id.layerName;
        }
        // #TFS 25016
        else {
          this.selectedLayer = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.layerName;
        }
        this.layerChanged();
        // this.layerChanged(this.eventEmitersService.selectedLayerIndexOnData);
      }.bind(this)
    );
    this.dropdownValues = this.objAssetDetailService.getDropDownValues();
    this.eventEmitTaskCreated = this.eventEmitersService.emitTaskCreated.subscribe(() => {
      this.loadSelectedParentFeatureInView();
    });
    this.eventEmitchildDeleted = this.eventEmitersService.emitchildDeleted.subscribe(() => {
      this.loadSelectedParentFeatureInView();
    });
    this.eventEmitCloseModel = this.eventEmitersService.emitCloseModel.subscribe(() => {
      this.closeModel();
    });
    this.eventEmitTaskCompleted = this.eventEmitersService.emitTaskCompleted.subscribe(() => {
      this.loadSelectedParentFeatureInView();
    });
    this.eventEmitreplaceTaskCreated = this.eventEmitersService.emitreplaceTaskCreated.subscribe(() => {
      this.loadSelectedParentFeatureInView();
    });
  }

  open(content) {
    try {
      this.mymodelIsVisible = true;
      this.closeOpenModelService.open(content, { windowClass: "dark-modal" }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  closeModel(): void {
    try {
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
      this.mymodelIsVisible = false;
      this.closeOpenModelService.dismissAll();
      //this.closeOpenModelService.close();
      this.reloadAssetViewer();

      // const object = {} //={layerName:selectedAssetslist.split(',')};
      // const layerName = this.selectedLayer;
      // object[layerName] = [this.selectedFeature[0].AssetID];

      // this.genericLayerService.getOpentasks(object).subscribe((opentasksRes: any) => {
      //   this.optenTasksJson = JSON.parse(opentasksRes);
      //   this.updateChildFeaturesTaskCount()
      // // RSAM Bug Fix JIRA ID: 333
      //     if (JSON.parse(opentasksRes).OpenTaskByAsset[0][this.selectedLayer]) {
      //       this.activeParentAsset[5]['task'] = JSON.parse(opentasksRes).OpenTaskByAsset[0][this.selectedLayer][0].OpenTaskCount;
      //     }
      // })
    } catch (error) {
      console.error(error);
    }
  }
  private getDismissReason(reason: any): string {
    try {
      if (reason === ModalDismissReasons.ESC) {
        return "by pressing ESC";
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return "by clicking on a backdrop";
      }
      // else {
      //   return `with: ${reason}`;
      // }
      return `with: ${reason}`;
    } catch (error) {
      console.error(error);
    }
  }
  ngOnInit() {
    this.reloadAssetViewer();
    this.assessmentlyr = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.assessmentId, { outFields: ["*"] });

    //Jira Id 935
    this.objAssetDetailService.getAssetIDFormat(this.Query, this.QueryTask, this.FeatureLayer);
    //ADA fix
    //document.getElementById("cardViewClose").focus();
  }
  ngOnDestroy() {
    if (this.draw) {
      this.draw.finishDrawing();
      this.draw.deactivate();
      this.draw = null;
    }
    if (this.clickMap) {
      this.clickMap.remove();
    }
    this.eventemitSelectedLayerIndexOnData.unsubscribe();
    this.eventemitSelectedParentIndexOnData.unsubscribe();
    this.eventemitSelectedFeaturesOnMap.unsubscribe();
    this.eventgenericLayerService.unsubscribe();
    this.eventEmitTaskCreated.unsubscribe();
    this.eventEmitchildDeleted.unsubscribe();
    this.eventEmitCloseModel.unsubscribe();
    this.eventEmitTaskCompleted.unsubscribe();
    this.eventEmitreplaceTaskCreated.unsubscribe();
  }

  reloadAssetViewer() {
    try {
      this.rowdataobj = this.eventEmitersService.selectedFeaturesOnMap;
      //Added for visualization layers
      this.visualizationLayersData = this.eventEmitersService.visualizationLyrData;
      //Check if only Visualization layers are selected
      let onlyVisualizationLayers = false;
      if (this.visualizationLayersData && this.visualizationLayersData.length > 0 && this.eventEmitersService.addNewParent === false) {
        onlyVisualizationLayers = true;
        //TSDRSAM-1788  working solution
        if (this.rowdataobj && this.rowdataobj.length > 0) {
          let result;
          if (this.visualizationLayersData[0].LayerName.layerName) {
            result = this.rowdataobj.filter((o1) => !this.visualizationLayersData.some((o2) => o2.LayerName.layerName === o1.LayerName.layerName));
          } else {
            result = this.rowdataobj.filter((o1) => !this.visualizationLayersData.some((o2) => o2.LayerName === o1.LayerName.layerName));
          }
          if (result && result.length > 0) {
            onlyVisualizationLayers = false;
          }
        }
        if (onlyVisualizationLayers && this.visualizationLayersData && this.visualizationLayersData.length > 0) {
          this.currentLayerData = [];
          let visualizationLyrObj;
          visualizationLyrObj = this.visualizationLayersData;
          if (visualizationLyrObj && visualizationLyrObj[0]) {
            this.currentLayerData = visualizationLyrObj[0].Data[0].attributes;
          }
          this.activeVisualizationAsset = true;
          this.activeParentAsset = false;
          this.showChildCard = false;
          this.selectedCount = visualizationLyrObj[0].Data.length;
          //this.selectedLayer = visualizationLyrObj[0].LayerName.layerName ? visualizationLyrObj[0].LayerName.layerName : visualizationLyrObj[0].LayerName;
          if (typeof visualizationLyrObj[0].LayerName === "string") {
            this.selectedLayer = visualizationLyrObj[0].LayerName;
          } else if (typeof visualizationLyrObj[0].LayerName.layerName === "string") {
            this.selectedLayer = visualizationLyrObj[0].LayerName.layerName;
          } else {
            this.selectedLayer = visualizationLyrObj[0].LayerName.layerName.tableAlias;
          }
          this.eventEmitersService.updateActiveAsset(visualizationLyrObj[0].Data[0].geometry);
          this.eventEmitersService.setShowVisualizationAssetDetails(this.selectedLayer, true, this.currentLayerData);
        }
      }
      if (onlyVisualizationLayers) {
        this.rowdataobj = [];
        this.visualizationLayersData.forEach((data) => {
          if (typeof data.LayerName === "string") {
            data.LayerName = { layerName: data.LayerName, tableAlias: data.LayerName, tableSL: data.LayerName };
          } else {
            data.LayerName = { layerName: data.LayerName.layerName, tableAlias: data.LayerName.tableAlias, tableSL: data.LayerName.tableSL };
          }
          this.rowdataobj.push(data);
        });
        this.assetViewerLabel = this.translateService.instant("AssetViewer.Title");
        return;
      }
      //////
      // If Parent Is support we need to Show child assets add function
      const lightlyr = this.currentUser.layerLevelInfo.filter(
        function (item) {
          if (item.gisLayerId.toString() === this.currentUser.serviceLyrInfo.lightId.toString()) {
            return item;
          }
        }.bind(this)
      );
      const signlyr = this.currentUser.layerLevelInfo.filter(
        function (item) {
          if (item.gisLayerId.toString() === this.currentUser.serviceLyrInfo.signId.toString()) {
            return item;
          }
        }.bind(this)
      );
      if (lightlyr[0] && lightlyr[0].accessPrivilege === "Data Editor" && (this.currentUser.serviceLyrInfo.lightId !== null || this.currentUser.serviceLyrInfo.lightId !== undefined)) {
        this.lightAddAccess = true;
      } else {
        this.lightAddAccess = false;
      }
      if (signlyr[0] && signlyr[0].accessPrivilege === "Data Editor" && (this.currentUser.serviceLyrInfo.signId !== null || this.currentUser.serviceLyrInfo.signId !== undefined)) {
        this.signAddAccess = true;
      } else {
        this.signAddAccess = false;
      }
      // End

      this.assetAttributesService.getTableAlias().subscribe((tblresults: any) => {
        tblresults.features.forEach((element) => {
          this.tableAliases[element.attributes.Name] = element.attributes.Alias;
        });
      });
      // For Selection Assects
      this.dataEditorSettings = JSON.parse(localStorage.getItem("dataEditorSettings"));
      if (this.rowdataobj && this.rowdataobj.length > 0) {
        this.assetViewerLabel = this.translateService.instant("AssetViewer.Title");
        this.selectedLayer = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.layerName;

        this.selectedCount = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data.length;
        if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.lyrObj.geometryType === "esriGeometryPolyline") {
          this.showreshape = true;
        } else {
          this.showreshape = false;
        }
        this.loadSelectedParentFeatureInView();
      } else {
        this.selectedLayer = null;
        const inventoryLayers = this.map.smsLayerdata.userLayers.filter((lyr) => lyr.layerType === "inventory");
        const lyrCount = inventoryLayers.length;
        for (let i = 0; i < lyrCount; i++) {
          const ftrLayer = inventoryLayers[i].lyrObj;
          if (ftrLayer) {
            ftrLayer.clearSelection();
          }
        }
        /** Initialize Cardview Label and Dropdownlist for Add New Asset */
        this.assetViewerLabel = this.translateService.instant("Select.alertcarddisplay.createnewasset");
        if (this.currentUser.selectedLayer) {
          this.dropdownValues.filter((layer) => {
            if (layer.Layer === this.currentUser.selectedLayer) {
              this.selectedLayer = layer.Layer;

              this.layerId = layer.layer_id.lyrObj.id;
              this.lyrObj = layer.layer_id;
            }
          });
        } else {
          this.selectedLayer = this.dropdownValues[0].Layer;

          this.layerId = this.dropdownValues[0].layer_id.lyrObj.id;
          this.lyrObj = this.dropdownValues[0].layer_id;
        }
        this.addNewAsset();
      }
    } catch (error) {
      console.error(error);
    }
  }
  //   // Load Parent Class Feature for Card View
  showParentAssetCard(layerName) {
    try {
      this.eventEmitersService.toggleCardView(layerName);
    } catch (error) {
      console.error(error);
    }
  }
  loadSelectedParentFeatureInView() {
    try {
      this.rowdataobj = this.eventEmitersService.selectedFeaturesOnMap;
      this.selectedFeature = [];
      if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData]) {
        this.selectedFeature.push(this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes);
        // Adding Active Layer object to array
        this.selectedFeature.push(this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName);
        // duplicate need to delete
        this.selectedFeature.push(this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData]);
        // Adding Active Asset  geometry to array if it is child element then adding parent geom  to array
        this.selectedFeature.push({ geometry: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].geometry });
        let region;
        if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4) {
          region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4;
        } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3) {
          region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3;
        } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2) {
          region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2;
        } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1) {
          region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1;
        }
        this.selectedFeature.push({ region });
        this.selectedFeature.push({ task: 0 }); // 7/29/20 jcc -- added this here to maintain expected array positoin for task object -- referenced in view.

        let regions; //jcc 7/26/20 -- capture all regions for current asset
        // eslint-disable-next-line prefer-const
        regions = {};
        regions.region1 = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1;
        regions.region2 = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2;
        regions.region3 = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3;
        regions.region4 = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4;
        this.selectedFeature.push({ regions });
        this.eventEmitersService.setActiveAssetFeature(this.selectedFeature);
        //get Yes or No to display instead of 1 or 0
        this.selectedDomain = this.selectedFeature[1].lyrObj.getDomain("DoNotReplace").codedValues;
        this.activeParentAsset = this.selectedFeature;
        //#region Jira-70

        if (this.activeParentAsset[0].AssetCode) {
          this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", this.activeParentAsset[0].AssetCode, true).subscribe(
            (base64Image: any) => {
              if (base64Image && this.activeParentAsset.length >= 7) {
                let ImageBase64 = {};
                ImageBase64 = BASE64_PREFIX + base64Image;
                this.activeParentAsset.splice(7, 0, { "ImageBase64": ImageBase64 });
              }
            },
            (error) => {
              if (error.status) {
                console.log("No object found", error);
              }
            }
          );
        }

        //#endregion
        const layerName = this.selectedLayer;
        const object = {}; //={layerName:selectedAssetslist.split(',')};
        object[layerName] = [this.selectedFeature[0].AssetID];
        this.getFieldsInfo(); // Added by Abhilash for JIRA ID:-34 on 08/12/2021

        this.genericLayerService.getOpentasks(object).subscribe((opentasksRes: any) => {
          console.log(opentasksRes);

          console.log(this.childRecords);
          this.optenTasksJson = JSON.parse(opentasksRes);
          this.updateChildFeaturesTaskCount();
          // RSAM Bug Fix JIRA ID: 333
          if (JSON.parse(opentasksRes).OpenTaskByAsset[0][this.selectedLayer]) {
            if (this.activeParentAsset[5]) {
              this.activeParentAsset[5]["task"] = JSON.parse(opentasksRes).OpenTaskByAsset[0][this.selectedLayer][0].OpenTaskCount;
            }
          }
        });
        this.childRecords = [];
        this.objGlobal.relatedAssetData = [];
        if (this.activeParentAsset) {
          this.objAssetDetailService.parentAsset = this.activeParentAsset;
        }
        if (this.selectedLayer == "Support") {
          this.loadSelectedChildFeatureInView();
        } else {
          this.loading = false;
        }

        // }
      }
    } catch (error) {
      console.error(error);
    }
  }
  loadSelectedChildFeatureInView() {
    try {
      // if (!this.loading) {
      this.childRecords = [];
      this.loading = true;
      this.query.map = this.map;
      this.query.RelationshipQuery = this.RelationshipQuery;
      this.query.FeatureLayer = this.FeatureLayer;
      // const layer = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName;
      // const objectID = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[
      //   this.eventEmitersService.selectedParentIndexOnData
      // ].attributes.OBJECTID;
      // const relatedColumnName = "AssetID";
      // const relatedId = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[
      //   this.eventEmitersService.selectedParentIndexOnData
      // ].attributes[relatedColumnName];
      this.counter = 0;
      this.getRelatedDataDetails();
      // }
    } catch (error) {
      console.error(error);
    }
  }
  getRelatedDataDetails() {
    this.childRecords = [];
    const layerObj = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.lyrObj;
    const relationshipsIds = layerObj.relationships.filter((layeritem) => {
      const idstr = layeritem.relatedTableId.toString();
      if (this.currentUser.layerLevelInfo.map(({ gisLayerId }) => gisLayerId).includes(idstr)) {
        return layeritem;
      }
    });

    console.log(relationshipsIds);
    for (let i = 0; i < relationshipsIds.length; i++) {
      let relatedColumn = "AssetID";
      this.childRecords.push({ Layer: relationshipsIds[i].name, layer_id: relationshipsIds[i].relatedTableId });

      if (relationshipsIds[i].name.includes("Sign") || relationshipsIds[i].name.includes("Light")) {
        relatedColumn = "ParentID";
      } else {
        relatedColumn = "AssetID";
      }
      this.genericLayerService
        .getFilteredFeaturesByField(
          relationshipsIds[i].relatedTableId,
          relatedColumn,
          this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID
        )
        .subscribe(
          function (results: any) {
            this.counter++;
            console.log(results);
            let layerName;
            if (results && results.features && results.features[0]) {
              // 15-01-2021 Manikantha Start 24790
              results.features.sort(function (a, b) {
                let aSize = a.attributes.MountHeight;
                let bSize = b.attributes.MountHeight;
                if (aSize == null) {
                  aSize = 0;
                }
                if (bSize == null) {
                  bSize = 0;
                }
                const aLow = a.attributes.OBJECTID;
                const bLow = b.attributes.OBJECTID;
                //console.log(aLow + " | " + bLow);

                if (aSize == bSize) {
                  return aLow - bLow;
                } //jcc if same mountheight, sort newest to oldest
                // else { return bSize - aSize } //jcc sort descending
                return bSize - aSize;
              });
              // 15-01-2021 Manikantha End 24790
              switch (results.features[0].attributes.AssetID.split("_")[0]) {
                case "SIG":
                  layerName = "SupportToSign";
                  break;
                case "LGT":
                  layerName = "SupportToLight";
                  break;
              }
              if (results.features && results.features[0] && results.features[0].attributes.MaintID) {
                layerName = "SupportToMaintenance";
              }
              this.childRecords.filter((child) => {
                if (child.Layer === layerName) {
                  // TSDRSAM-1070 By Manikantha V
                  // Added by Manikantha for JIRA ID:-34
                  child.RelatedData = results.features.filter((item) => {
                    if (item.attributes.Status != "Inactive") {
                      return item;
                    }
                  });
                  //#region  Jira-70
                  child.RelatedData.forEach((relatedData) => {
                    this.objAssetDetailService.getAssetIconAsBase64(this.currentUser.clientId, "en-US", relatedData.attributes.AssetCode, false).subscribe(
                      (base64Image: any) => {
                        if (base64Image) {
                          relatedData.ImageBase64 = BASE64_PREFIX + base64Image;
                        }
                      },
                      (error) => {
                        if (error.status) {
                          console.log("No object found", error);
                        }
                      }
                    );
                  });
                  //#endregion
                  if (child.Layer === "SupportToSign" || child.Layer.toLowerCase() === "sign") {
                    child.RelatedData.forEach(
                      function (asset) {
                        const codeAndDes = this.getCodedValue(asset);
                        asset.attributes.codeAndDes = codeAndDes;
                      }.bind(this)
                    );
                  }
                  // Jira Id-34 Bug-TSDRSAM-1546
                  if (child.Layer.toLowerCase() === "light" || child.Layer === "SupportToLight") {
                    child.RelatedData.forEach(
                      function (asset) {
                        const codeAndDes = this.getCodedValueForLight(asset);
                        asset.attributes.codeAndDes = codeAndDes;
                      }.bind(this)
                    );
                  }
                }
                if (child.Layer === "SupportToMaintenance") {
                  // TSDRSAM-1070 By Manikantha V
                  child.RelatedData = results.features.filter((item) => {
                    if (item.attributes.Status != "Inactive") {
                      return item;
                    }
                  });
                  child.RelatedData.forEach((asset) => {
                    this.genericLayerService.getFeaturesByCond(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID='" + asset.attributes.AssetID + "' AND Status='Open'").subscribe((result: any) => {
                      asset.attributes.task = result.features.length;
                    });
                  });
                }
              });
            }

            if (this.counter === relationshipsIds.length) {
              this.cardsAreLoading = false;
              this.selectChildAndOpenAtt();
              // const taskCount = this.getTasksCount(this.childRecords);
              this.objGlobal.relatedAssetData = this.childRecords;

              this.updateChildFeaturesTaskCount();
              this.showChildCard = true;
              if (this.eventEmitersService.selectedChildClass) {
                const relatedRecords = this.childRecords.filter((data) => {
                  if (data.Layer.includes(this.eventEmitersService.selectedChildClass)) {
                    return data;
                  }
                })[0];
                const childId = this.eventEmitersService.selectedChildIndexOnData;
                if (this.eventEmitersService.selectedChildIndexOnData && relatedRecords && childId) {
                  if (relatedRecords.RelatedData) {
                    // TSDRSAM-1070 By Manikantha V
                    let childAsset = relatedRecords.RelatedData.filter((data) => {
                      if (data.attributes.OBJECTID == childId) {
                        return data;
                      }
                    })[0];

                    if (!childAsset) {
                      childAsset = relatedRecords.RelatedData.filter((data) => {
                        if (data.attributes.AssetID == this.eventEmitersService.assitId) {
                          return data;
                        }
                      })[0];
                    }
                    if (childAsset) {
                      this.OpenAssetDetail("Child", childAsset, relatedRecords);
                    }
                  }
                }
                if (this.eventEmitersService.selectedChildIndexOnData == null) {
                  // TSDRSAM-1070 By Manikantha V
                  if (relatedRecords && relatedRecords.RelatedData) {
                    const childAsset = relatedRecords.RelatedData[0];
                    if (this.eventEmitersService.selectedChildClass == "Sign") {
                      this.eventEmitersService.toggleCardView("SupportToSign");
                    }
                    if (this.eventEmitersService.selectedChildClass == "Light") {
                      this.eventEmitersService.toggleCardView("SupportToLight");
                    }
                    //TSDRSAM-1654
                    if (childAsset && this.showAssetDtl == true) {
                      this.OpenAssetDetail("Child", childAsset, relatedRecords);
                    }
                  } else {
                    if (this.eventEmitersService.selectedChildClass == "Sign") {
                      this.eventEmitersService.toggleCardView("SupportToSign", 0);
                    }
                    if (this.eventEmitersService.selectedChildClass == "Light") {
                      this.eventEmitersService.toggleCardView("SupportToLight", 0);
                    }
                  }
                }
              }

              console.log("loading false");
              this.loading = false;
            }
          }.bind(this)
        );
    }
    if (relationshipsIds.length == 0) {
      this.loading = false;
    }
  }
  // Get Tasks related to Feature
  getTasksCount(childRecords) {
    try {
      const maintLyr = childRecords.filter((lyr) => lyr.Layer.includes("Maintenance")); // 7/28/20 jcc -- this maintenance layer holds assessment records??
      if (maintLyr[0] && maintLyr[0].RelatedData && maintLyr[0].RelatedData.length) {
        this.parenttasksCount = maintLyr[0].RelatedData.length;
      } else {
        this.parenttasksCount = 0;
      }
    } catch (error) {
      console.error(error);
    }
  }

  handlePagination(type: paginationType): void {
    try {
      let assetData;
      //if statement evaluating type
      switch (type) {
        case paginationType.First:
          if (this.activeVisualizationAsset) {
            if (this.currentVisualizationDataIndex > 0) {
              this.currentVisualizationDataIndex = 0;
            }
          } else {
            if (this.eventEmitersService.selectedParentIndexOnData > 0) {
              this.eventEmitersService.selectedParentIndexOnData = 0;
              this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
            }
          }
          break;
        case paginationType.Previous:
          if (this.activeVisualizationAsset) {
            if (this.currentVisualizationDataIndex > 0) {
              this.currentVisualizationDataIndex = this.currentVisualizationDataIndex - 1;
            }
          } else {
            if (this.eventEmitersService.selectedParentIndexOnData > 0) {
              this.eventEmitersService.selectedParentIndexOnData = this.eventEmitersService.selectedParentIndexOnData - 1;
              this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
            }
          }
          break;
        case paginationType.Next:
          if (this.activeVisualizationAsset) {
            if (this.currentVisualizationDataIndex + 1 < this.selectedCount) {
              this.currentVisualizationDataIndex = this.currentVisualizationDataIndex + 1;
            }
          } else {
            if (this.eventEmitersService.selectedParentIndexOnData < this.selectedCount - 1) {
              this.eventEmitersService.selectedParentIndexOnData += 1;
              this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
            }
          }
          break;
        case paginationType.Last:
          if (this.activeVisualizationAsset) {
            if (this.currentVisualizationDataIndex + 1 < this.selectedCount) {
              this.currentVisualizationDataIndex = this.selectedCount - 1;
            }
          } else {
            if (this.eventEmitersService.selectedParentIndexOnData < this.selectedCount - 1) {
              this.eventEmitersService.selectedParentIndexOnData = this.selectedCount - 1;
              this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
            }
          }
          break;
      }
      if (this.activeVisualizationAsset) {
        if (this.visualizationLayersData[0].LayerName.layerName) {
          assetData = this.visualizationLayersData.filter((e) => e.LayerName.layerName == this.selectedLayer)[0];
        } else {
          assetData = this.visualizationLayersData.filter((e) => e.LayerName == this.selectedLayer)[0];
        }
        this.currentLayerData = assetData.Data[this.currentVisualizationDataIndex].attributes;
        this.eventEmitersService.updateActiveAsset(assetData.Data[this.currentVisualizationDataIndex].geometry);
      } else {
        this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
      }
      //applicable index update and call event emitter.
    } catch (error) {
      console.error(error);
    }
  }

  layerChangedFromUi(layerinfo, idx) {
    //Updated for visualization layers
    if (layerinfo.isUserInput === true) {
      this.currentLayerData = [];
      let visualizationLyrObj;
      if (this.visualizationLayersData && this.visualizationLayersData.length > 0) {
        //TSDRSAM-1788
        if (this.visualizationLayersData[0].LayerName.layerName) {
          if (typeof layerinfo === "string") {
            visualizationLyrObj = this.visualizationLayersData.filter((e) => e.LayerName.layerName == layerinfo);
          } else {
            visualizationLyrObj = this.visualizationLayersData.filter((e) => e.LayerName.layerName == layerinfo.source.value);
          }
        } else {
          visualizationLyrObj = this.visualizationLayersData.filter((e) => e.LayerName == (typeof layerinfo == "string" ? layerinfo : layerinfo.source.value));
        }
        if (visualizationLyrObj && visualizationLyrObj[0]) {
          this.currentLayerData = visualizationLyrObj[0].Data[0].attributes;
        }
      }

      if (this.currentLayerData.length > 0) {
        this.eventEmitersService.removePreviousActiveAsset();
        this.activeVisualizationAsset = true;
        this.activeParentAsset = false;
        this.showChildCard = false;
        this.selectedCount = visualizationLyrObj[0].Data.length;
        this.selectedLayer = typeof layerinfo === "string" ? layerinfo : layerinfo.source.value;
        this.eventEmitersService.updateActiveAsset(visualizationLyrObj[0].Data[0].geometry);
        this.eventEmitersService.setShowVisualizationAssetDetails(this.selectedLayer, true, this.currentLayerData);
        this.currentVisualizationDataIndex = 0;
      } else {
        this.eventEmitersService.removePreviousActiveAsset();
        this.activeVisualizationAsset = false;
        if (layerinfo.isUserInput) {
          this.eventEmitersService.setSelectedLayerIndexOnData(idx);
        } else if (layerinfo.selectedIndex) {
          this.eventEmitersService.setSelectedLayerIndexOnData(layerinfo.selectedIndex);
        }
      }
    }
  }
  setThis() {
    return this;
  }
  layerChanged() {
    try {
      /** Selection by Select Tool  */
      if (this.rowdataobj && this.rowdataobj.length > 0) {
        this.selectedLayer = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.layerName;
        // Manikantha V 2/8/2021
        console.log("layer changed from card view");
        this.selectedCount = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data.length;
        if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].LayerName.lyrObj.geometryType === "esriGeometryPolyline") {
          this.showreshape = true;
        } else {
          this.showreshape = false;
        }
        this.loadSelectedParentFeatureInView();
      } else {
        if (this.draw) {
          this.draw.deactivate();
        }
        this.loading = false;
        this.selectedLayer = this.dropdownValues[this.eventEmitersService.selectedLayerIndexOnData].Layer;
        this.layerId = this.dropdownValues[this.eventEmitersService.selectedLayerIndexOnData].layer_id.lyrObj.id;
        this.lyrObj = this.dropdownValues[this.eventEmitersService.selectedLayerIndexOnData].layer_id;
        this.currentUser.selectedLayer = this.selectedLayer;
        this.addNewAsset();
      }
    } catch (error) {
      console.error(error);
    }
  }

  addNewAsset() {
    try {
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(false);
      const geometryType = this.lyrObj.lyrObj.geometryType;
      let drawType = "";
      if (geometryType === "esriGeometryPoint") {
        drawType = "point";
        this.selectionSymbol = new this.SimpleMarkerSymbol(
          this.SimpleMarkerSymbol.STYLE_DIAMOND,
          12,
          new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_NULL, new this.Color([247, 34, 101, 0.9]), 1),
          new this.Color([0, 255, 255, 9])
        );
      } else if (geometryType === "esriGeometryPolyline") {
        drawType = "polyline";
      } else if (geometryType === "esriGeometryPolygon") {
        drawType = "polygon";
      }
      if (!this.draw) this.draw = this.eventEmitersService.draw = new this.Draw(this.map);
      this.draw.activate(drawType);

      this.counterVar = 0;
      const mapClick = (this.eventEmitersService.mapclick = this.draw.on("draw-end", (evt) => {
        if (this.counterVar == 0) {
          this.counterVar++;
          const wm = this.webMercatorUtils.webMercatorToGeographic(evt.geometry);
          if (this.objAssetDetailService.isOutsideRegion(evt.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
            Swal.fire(this.translateService.instant("Select.alertcard.noassetoutregion"));
            mapClick.remove();
            this.draw.deactivate();
            return;
          }
          const asset = new this.Graphic(wm, this.selectionSymbol);
          this.map.graphics.add(asset);
          mapClick.remove();
          if (this.draw) {
            this.draw.deactivate();
          }
          asset.attributes = []; // Create rowdataobj and added Default Region
          // For Model 23/03/2021
          this.showAssetDetail();
          const attr: any = [];
          let regionData;
          const promise = this.populateDefaultRegionValues(); // Populating Default Values in New Asset
          promise.then(
            function (result) {
              regionData = result;
              regionData.features.forEach((ftr) => {
                // let rgn;
                const rgn = this.webMercatorUtils.webMercatorToGeographic(ftr.geometry);
                if (!this.objAssetDetailService.isOutsideRegion(wm, this.geometryEngine, rgn)) {
                  attr.Region1 = ftr.attributes.RegionName1;
                  attr.Region2 = ftr.attributes.RegionName2;
                  attr.Region3 = ftr.attributes.RegionName3;
                  attr.Region4 = ftr.attributes.RegionName4;
                }
              });
              if (wm.paths) {
                attr.Latitude = wm.paths[0][0][1];
                attr.Longitude = wm.paths[0][0][0];
                attr.Latitude2 = wm.paths[0][wm.paths[0].length - 1][1];
                attr.Longitude2 = wm.paths[0][wm.paths[0].length - 1][0];
              } else {
                attr.Latitude = wm.y;
                attr.Longitude = wm.x;
              }

              attr.Status = "Active";
              attr.Condition = "Good";
              attr.InstallDate = new Date(Date.now()).getTime(); //8/9/20 jcc default install date to now
              asset.setAttributes(attr);
              this.rowdataobj = []; // Add New Asset to current rowdataobj
              this.selectedCount = 1;

              this.currentUser.selectedLayer = this.userselLyr;
              this.lyrObj.lyrObj
                .applyEdits([asset], null, null)
                .then((editsResult) => {
                  this.lyrSel = this.userselLyr = this.dropdownValues[0].layer_id.tableName;
                  this.layerId = this.lyrObj.id;
                  // this.lyrObj = this.lyrObj;
                  if (editsResult) {
                    const objectId = editsResult[0].objectId;
                    this.newAssessment(editsResult[0].objectId, this.lyrObj.layerName);
                    // AutoGenerate Asset ID
                    const query = new this.Query();
                    query.where = "OBJECTID=" + objectId;
                    const _this = this.setThis();
                    this.lyrObj.lyrObj.refresh();
                    this.lyrObj.lyrObj.clearSelection();
                    this.lyrObj.lyrObj.selectFeatures(query, this.lyrObj.lyrObj.SELECTION_NEW, function (res) {
                      _this.map.getLayer("sms-layers").refresh();
                      if (res.length > 0) {
                        _this.rowdataobj.push({ Data: [res[0]], LayerName: _this.lyrObj });
                        // _this.saveTask(res);
                        _this.showAssetDtl = true;

                        _this.generateAssetID(_this.rowdataobj[0].Data[0], _this.selectedLayer);
                        _this.eventEmitersService.setSelectedFeaturesOnMap(_this.rowdataobj);
                      }
                    });
                  }
                })
                .catch((error) => {
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                });
            }.bind(this)
          );
        }
      }));
    } catch (error) {
      console.error(error);
    }
  }

  newAssessment(newAssetid, layerName) {
    try {
      console.log("assetment");
      const features = new this.Graphic();
      const assetAttr: any = [];
      //Jira Id-935
      const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === layerName)[0].attributes.LayerCode;
      assetAttr.AssetID = prefix + "_" + newAssetid;
      //assetAttr.AssetID = this.objAssetDetailService.getAssetIDFormat(layerName)[0].value + '_' + newAssetid;

      assetAttr.Condition = "Good";
      assetAttr.Inspector = this.currentUser.displayName;
      assetAttr.ModifiedBy = this.currentUser.userId;
      assetAttr.InspectionDate = new Date(Date.now()).getTime();
      features.setAttributes(assetAttr);
      this.assessmentlyr.applyEdits(
        [features],
        null,
        null,
        (evt) => {
          const upfeatures = new this.Graphic();
          const upassetAttr: any = [];
          upassetAttr.OBJECTID = evt[0].objectId;
          upassetAttr.AssessmentID = evt[0].objectId;
          upfeatures.setAttributes(upassetAttr);
          this.assessmentlyr.applyEdits(null, [upfeatures], null);
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  populateDefaultRegionValues() {
    try {
      const _this = this.setThis();
      return new Promise(function (resolve) {
        const regionLayer = _this.currentUser.webServiceURL + environment.featureServer + _this.currentUser.serviceLyrInfo.regionId;
        const getRegionData = new _this.Query();
        if (_this.currentUser.selectedRegion.regionLevel === 4) {
          getRegionData.where = "RegionName4='" + _this.currentUser.selectedRegion.regionName + "'";
        }
        if (_this.currentUser.selectedRegion.regionLevel === 3) {
          getRegionData.where = "RegionName3='" + _this.currentUser.selectedRegion.regionName + "'";
        }
        if (_this.currentUser.selectedRegion.regionLevel === 2) {
          getRegionData.where = "RegionName2='" + _this.currentUser.selectedRegion.regionName + "'";
        }
        if (_this.currentUser.selectedRegion.regionLevel === 1) {
          getRegionData.where = "RegionName1='" + _this.currentUser.selectedRegion.regionName + "'";
        }
        getRegionData.outFields = ["*"];
        getRegionData.returnGeometry = true;
        const getRegionDataExecute = new _this.QueryTask(regionLayer);
        getRegionDataExecute.execute(getRegionData).then(function (result) {
          resolve(result);
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  //   /** Gets AssetID prefix from a global Variable and Sets it to Current Asset */
  generateAssetID(asset, layerName) {
    try {
      const attr = asset.attributes;
      //this.asset = asset;

      //Jira Id-935
      const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === layerName)[0].attributes.LayerCode;
      attr.AssetID = prefix + "_" + attr.OBJECTID;
      asset.setAttributes(attr);
    } catch (error) {
      console.error(error);
    }
  }

  //   /** Select Navigation */

  //   /** Creates an Active Asset which opens in the Asset Details Page for selected assets */

  //   /** Gets Related Assets for a Primary Asset based on Asset ID
  //    * Uses Esri QueryRelatedFeatures function.
  //    * Returns ressult in "this.relatedAsset(local)" and "this.objGlobal.relatedAssetData(global)"
  //    */

  //   /** Close Card Viewer */
  closeAssetViewer() {
    try {
      if (this.draw) {
        this.draw.deactivate();
      }
      if (this.clickMap) {
        this.clickMap.remove();
      }

      this.eventEmitersService.setShowCard(false);
    } catch (error) {
      console.error(error);
    }
  }
  showAssetDetail() /** Open Asset Viewer */ {
    this.showAssetDtl = true;
  }

  closeAssetDetail() /** Close Asset Details */ {
    this.showAssetDtl = false;
    this.modalService.dismissAll();
  }
  showRelAssetCard(id, layerName) /** Show Related Assets Accordian in card View */ {
    this.relasstClickedES[id] = !this.relasstClickedES[id];
    this.eventEmitersService.toggleCardView(layerName);
  }
  getIfNewAsset() {
    // this.eventEmitersService.addNewParent = event;
  }
  //   /** Creates an Active Asset which opens in the Asset Details Page for related and Primary Assets */
  OpenAssetDetail(assetType: any, assetData: any, layer_id: any) {
    try {
      let assetStatus = false;
      if (assetType === "Child") {
        this.eventEmitersService.setSelectedChildIndexOnData(assetData.attributes.OBJECTID, assetData.attributes.AssetID);
        this.eventEmitersService.setActiveAsset(assetData);

        if (assetData && assetData.attributes.Status !== "Inactive") {
          assetStatus = true;
        }
        const lyrName = layer_id.Layer.substring(this.selectedLayer.length + 2, layer_id.Layer.length);
        const selectedChildFeature = [];
        // By Default 0 item in 0 index Layer

        // Adding Active Feature Attributes to array
        selectedChildFeature.push(assetData.attributes);
        // Adding Active Layer object to array
        // const baseUrl = assetData._layer.url;
        const lyrObj = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + layer_id.layer_id, { outFields: ["*"] });
        lyrObj.on(
          "Load",
          function (evt) {
            this.assetAttributesService.getTableAlias().subscribe((tblresults) => {
              tblresults.features.forEach((element) => {
                // const lyrname = element.attributes.Name;

                if (element.attributes.Name === lyrName) {
                  assetData._layer = lyrObj;
                  const layerObject = assetData._layer;
                  selectedChildFeature.push({
                    layerName: lyrName,
                    lyrObj: layerObject,
                    tableName: lyrName,
                    tableAlias: element.attributes.Alias,
                    assetCode: element.attributes.LayerCode,
                    tableSL: element.attributes.Alias_SL
                  });

                  // duplicate need to delete
                  selectedChildFeature.push(assetData);

                  // Adding Active Asset  geometry to array if it is child element then adding parent geom  to array
                  selectedChildFeature.push({
                    geometry: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].geometry
                  });
                  let region;
                  if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4) {
                    region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4;
                  } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3) {
                    region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3;
                  } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2) {
                    region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2;
                  } else if (this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1) {
                    region = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1;
                  }
                  // Adding Active Region if it is child element then adding parent region  to array
                  selectedChildFeature.push({ region });

                  //7/28/20 - get all regions for child asset
                  const regions = {
                    region1: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region1,
                    region2: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region2,
                    region3: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region3,
                    region4: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.Region4
                  };
                  selectedChildFeature.push({ regions: regions });

                  this.activeAsset = selectedChildFeature;
                  this.activeChildAsset = selectedChildFeature;
                  this.activeObjectId = this.activeChildAsset[0].OBJECTID;
                  this.activeAssetId = this.activeChildAsset[0].AssetID; //jcc 9/22/20
                  this.IsOpenAssetDetail = true;
                  this.eventEmitersService.setActiveAssetFeature(this.activeChildAsset);
                  this.IsOpenAssetDetail = false;
                  if (this.assetDetails) {
                    this.assetDetails.initAssetDetail();
                  }
                }
              });
            });
          }.bind(this)
        );
      } else if (assetType === "Parent") {
        if (this.activeParentAsset[0].Status !== "Inactive") {
          this.eventEmitersService.selectedChildIndexOnData = null;
          this.eventEmitersService.selectedChildClass = null;
          this.eventEmitersService.setActiveAsset(true);
          this.eventEmitersService.setActiveAssetFeature(this.selectedFeature);
          this.activeAssetId = this.activeParentAsset[0].AssetID;
          assetStatus = true;
        }
      }

      if (this.activeParentAsset[0].Status !== "Inactive") {
        if (assetStatus) {
          this.showAssetDetail();
        } else {
          this.closeAssetDetail();
        }
      } else {
        this.closeAssetDetail();
      }
    } catch (error) {
      console.error(error);
    }
  }

  //   /** Get Asset Attributes after saving in Asset Details Page */
  getAssetAttributes() {
    // this.attribs = event;
  }

  //   /** Create New Related Asset */
  relAssetSelect(selRelAsset) {
    this.createNewRelatedAsset(selRelAsset);
  }
  createNewRelatedAsset(selectRelAsset) {
    try {
      // this.isAssetNew = true;
      this.selectRelAsset = selectRelAsset;
      let relatedLayerurl: any;
      this.eventEmitersService.addNewChild = true;
      if (selectRelAsset === "Light") {
        //  this.layerName = this.selectRelAsset;
        relatedLayerurl = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.lightId;
      }
      if (selectRelAsset === "Sign") {
        //  this.layerName = this.selectRelAsset;
        relatedLayerurl = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.signId;
      }

      // For Model 23/03/2021
      this.showAssetDetail();
      const featureLayer = new this.FeatureLayer(relatedLayerurl);
      const geometry = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].geometry;
      const newChildAsset = new this.Graphic(geometry);
      const assetAttr: any = [];
      newChildAsset.setAttributes(assetAttr);

      //Jira Id-935
      const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === selectRelAsset)[0].attributes.LayerCode;

      assetAttr.ParentID = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID;
      assetAttr.Status = "Active";
      assetAttr.InstallDate = new Date(Date.now()).getTime(); //8/9/20 jcc default install date to now          doesn't flow through from here
      assetAttr.Condition = "Good"; //8/11/20 jcc default new child asset to Good condition
      newChildAsset.setAttributes(assetAttr);
      newChildAsset.geometry = null;
      newChildAsset._layer = featureLayer;
      featureLayer.applyEdits([newChildAsset], null, null).then(
        function (newchild) {
          const featurese = [];
          const features = new this.Graphic();
          const assetidcon = prefix + "_" + newchild[0].objectId;
          this.newChildId = newchild[0].objectId;
          features.setAttributes({
            OBJECTID: newchild[0].objectId,
            AssetID: assetidcon,
            ParentID: this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].attributes.AssetID
          });
          const featTest = features;
          // features.setAttributes(attributes)
          featurese.push(features);
          this.eventEmitersService.setSelectedChildIndexOnData(features.attributes.OBJECTID, assetidcon);
          this.eventEmitersService.setActiveAsset(features);
          featureLayer
            .applyEdits(null, featurese, null)
            .then(
              function () {
                this.newChildFeature = featTest;
                this.newChildFClass = featureLayer.name;
                // this.saveTask(featTest, featureLayer.name);
                this.loadSelectedParentFeatureInView();
                this.objAssetDetailService.tasksModified.emit(true);
              }.bind(this)
            )
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
          this.newAssessment(newchild[0].objectId, this.selectRelAsset);
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }
  selectChildAndOpenAtt() {
    try {
      if (this.eventEmitersService.addNewParent && this.newChildId) {
        this.relatedFeatures = this.childRecords.filter(
          function (rec) {
            if (rec.Layer.includes(this.selectRelAsset)) {
              return rec;
            }
          }.bind(this)
        )[0];

        if (this.relatedFeatures.RelatedData && this.relatedFeatures.RelatedData.length > 0) {
          const relatedFeature = this.relatedFeatures.RelatedData.filter(
            function (fea) {
              if (fea.attributes.OBJECTID === this.newChildId) {
                return fea;
              }
            }.bind(this)
          )[0];
          this.OpenAssetDetail("Child", relatedFeature, this.relatedFeatures);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  //   /** Set images for primary and related assets */
  setSignPic(event, relatedFeature) {
    try {
      event.target.src = "assets/images/nopic.png";
      this.renderer.listen(event.target, "error", this.onPicError);
    } catch (error) {
      console.error(error);
    }
  }
  onPicError(event) {
    event.target.src = "assets/images/nopic.png";
  }

  relocateAsset() {
    // Mani for disableing single click on map
    this.eventEmitersService.setEnableSingleClick(false);
    this.SaveExistData();
    this.eventEmitersService.disablebutton();
    this.EnableIcon(false); //TSDRSAM-1869
    // TSDRSAM-1099 By Manikantha V
    if (this.eventEmitersService.activeAssetFeature[0].AssetID.includes("SIG") || this.eventEmitersService.activeAssetFeature[0].AssetID.includes("LGT")) {
      this.eventEmitersService.setEnableSingleClick(true);
      this.relocateChildAsset(this.eventEmitersService.activeAssetFeature[2]);
    } else {
      this.relocateAssetBasedOnGeom(this.eventEmitersService.activeAssetFeature[2], this.eventEmitersService.activeAssetFeature[1].lyrObj, this.eventEmitersService.activeAssetFeature[1].lyrObj.geometryType);
    }
  }
  // JIRA ID:1099 By Manikantha V
  relocateChildAsset(relatedFeature) {
    this.SaveExistData();
    this.eventEmitersService.disablebutton();
    this.genericMapMethodsService.relocateChildAsset = relatedFeature;
    this.genericMapMethodsService.esriBundle.toolbars.draw.addPoint = "Click to move to new parent asset.";
    this.draw = this.Draw(this.map);
    this.draw.activate("point");

    //this.draw.activate("point");
    this.genericMapMethodsService.relocateChild = true;
    this.clickMap = this.draw.on(
      "draw-end",
      function (evt) {
        this.genericMapMethodsService.esriBundle.toolbars.draw.addPoint = "Click to add a point";
        this.draw.deactivate();
        this.eventEmitersService.enablebutton();
        this.EnableIcon(true); //TSDRSAM-1869
      }.bind(this)
    );
  }

  relocateAssetBasedOnGeom(selectedAsset, featureLayer, geometryType) {
    // TSDRSAM-1070 By Manikantha V
    let graphic = new this.Graphic();
    graphic = selectedAsset;
    const old_geom = graphic.geometry;

    if (geometryType === "esriGeometryPolyline" || geometryType === "esriGeometryPolygon") {
      this.map.disableDoubleClickZoom(true);
      const edit = new this.Edit(this.map, {
        ghostLineSymbol: new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_DASH, new this.Color([0, 0, 255]))
      });
      const lineSymbol = new this.SimpleLineSymbol({ color: new this.Color([0, 0, 255, 1]), type: "simplelinesymbol" });
      graphic.setSymbol(new this.SimpleLineSymbol(lineSymbol).setWidth(this.genericMapMethodsService.linearAssetRelocateWidth));
      this.map.graphics.add(graphic);
      edit.activate(this.Edit.MOVE, graphic);
      this.clickMap = this.map.on(
        "dbl-click",
        function (evt) {
          this.eventEmitersService.enablebutton();
          this.EnableIcon(true);
          this.map.enableDoubleClickZoom(true);
          if (this.eventEmitersService.selectedFeaturesOnMap.length == 1 && this.eventEmitersService.selectedFeaturesOnMap[0].Data.length == 1) {
            this.eventEmitersService.setEnableSingleClick(true);
          }
          this.populateRegionGeometry().then((results) => {
            let isAssetinAnyRegion = true;
            if (this.objAssetDetailService.isOutsideRegion(graphic.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
              isAssetinAnyRegion = false;
              console.log("isOutsideRegion + " + isAssetinAnyRegion);
            } else {
              isAssetinAnyRegion = true;
              this.assetPlacedinRegion = this.currentUser.selectedRegion;
              console.log("isOutsideRegion + " + isAssetinAnyRegion);
            }
            if (isAssetinAnyRegion) {
              Swal.fire({
                title: this.translateService.instant("Select.alertcarddisplay.relocateasset"),
                text: this.translateService.instant("Select.alertcarddisplay.confirmrelocateasset"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
              }).then((result) => {
                if (result.value) {
                  this.populateChangedAssetDefaultValues(this.assetPlacedinRegion, graphic, this.Query, this.QueryTask).then(
                    function (result) {
                      featureLayer.applyEdits(null, [result], null).then(
                        function (res) {
                          this.updateLatLong(result);
                          this.query.updateAsset(featureLayer, result).then(
                            function (res) {
                              this.refreshAfterEdit(result);
                              edit.deactivate();
                              this.createRelocateTask(result, old_geom);
                            }.bind(this)
                          );
                        }.bind(this)
                      );
                    }.bind(this)
                  );
                } else {
                  //graphic.geometry = old_geom;
                  if (this.map.getLayer("sms-layers")) this.map.getLayer("sms-layers").refresh();
                  this.map.graphics.remove(
                    this.map.graphics.graphics.filter((item) => {
                      // TSDRSAM-975 By Manikantha V
                      if (item.symbol.width == this.genericMapMethodsService.linearAssetRelocateWidth) {
                        return item;
                      }
                    })[0]
                  );
                  this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayer.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                    function (feature) {
                      console.log(feature);
                      this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                      // TSDRSAM-975 By Manikantha V
                      this.refreshAfterEdit(feature.features[0], old_geom);
                    }.bind(this)
                  );

                  this.clickMap.remove();
                }
              });
              this.clickMap.remove();
              edit.deactivate();
            } else {
              Swal.fire(this.translateService.instant("Select.newasset.outside"));
              if (this.map.getLayer("sms-layers")) this.map.getLayer("sms-layers").refresh();
              this.map.graphics.remove(
                this.map.graphics.graphics.filter((item) => {
                  if (item.symbol.width == this.genericMapMethodsService.linearAssetRelocateWidth) {
                    return item;
                  }
                })[0]
              );

              // this.map.graphics.graphics.filter((item) => { console.log(item.symbol.style);console.log(item.graphictype) })
              this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayer.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                function (feature) {
                  this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                  this.refreshAfterEdit(feature.features[0]);
                }.bind(this)
              );
              edit.deactivate();
              this.clickMap.remove();

              this.refreshAfterEdit();
              return;
            }
          });
        }.bind(this)
      );
    } else if (geometryType === "esriGeometryPoint") {
      this.draw = this.Draw(this.map);
      this.draw.activate("point");
      let isAssetinAnyRegion = false;
      this.clickMap = this.draw.on(
        "draw-end",
        function (evt) {
          this.eventEmitersService.enablebutton();
          this.EnableIcon(true);
          if (this.eventEmitersService.selectedFeaturesOnMap.length == 1 && this.eventEmitersService.selectedFeaturesOnMap[0].Data.length == 1) {
            this.eventEmitersService.setEnableSingleClick(true);
          }
          this.populateRegionGeometry().then((results) => {
            //  for (let i = 0; i < results.length; i++) {
            if (this.objAssetDetailService.isOutsideRegion(evt.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
              isAssetinAnyRegion = false;
            } else {
              isAssetinAnyRegion = true;
              this.assetPlacedinRegion = this.currentUser.selectedRegion;
              // break;
            }
            // }
            if (isAssetinAnyRegion) {
              let destination = new this.Graphic();
              const final_asset = [];
              Swal.fire({
                title: this.translateService.instant("Select.alertcarddisplay.relocateasset"),
                text: this.translateService.instant("Select.alertcarddisplay.confirmrelocateasset"),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
              })
                .then((result) => {
                  if (result.value) {
                    destination = selectedAsset;
                    destination.geometry = evt.geometry;
                    this.populateChangedAssetDefaultValues(this.assetPlacedinRegion, destination, this.Query, this.QueryTask).then(
                      function (result) {
                        final_asset.push(result);
                        featureLayer.applyEdits(null, [result], null).then(
                          function (res) {
                            this.updateLatLong(result);
                            this.query.updateAsset(featureLayer, result).then(
                              function (res) {
                                this.refreshAfterEdit(result);
                                if (this.draw) {
                                  this.draw.deactivate();
                                }
                                this.createRelocateTask(result, old_geom);
                              }.bind(this)
                            );
                          }.bind(this)
                        );
                      }.bind(this)
                    );
                  }
                })
                .catch((error) => {
                  console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
                });
              this.clickMap.remove();
              if (this.draw) {
                this.draw.deactivate();
              }
            } else {
              Swal.fire(this.translateService.instant("Select.newasset.outside"));
              this.refreshAfterEdit(selectedAsset);
              if (this.draw) {
                this.draw.deactivate();
              }
              return;
            }
          });
        }.bind(this)
      );
    }
  }
  reshape() {
    this.eventEmitersService.disablebutton();
    //TSDRSAM-1869
    this.EnableIcon(false);
    this.SaveExistData();
    this.map.disableDoubleClickZoom(true);
    // Mani
    this.eventEmitersService.setEnableSingleClick(false);
    const editToolbar = new this.Edit(this.map, { showTooltips: true });
    const tool = this.Edit.EDIT_VERTICES; // Tool for Editing Vertices

    editToolbar.on(
      "vertex-move-stop",
      function (evt) {
        this.map.graphics.remove(evt.graphic);
        this.map.graphics.remove(
          this.map.graphics.graphics.filter((item) => {
            if (item.graphictype == "dotline") {
              return item;
            }
          })[0]
        );
        const lineSymbolShortDot = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SHORTDOT, "black", 2);
        const newGraphic = new this.Graphic(evt.graphic.geometry, lineSymbolShortDot);
        newGraphic.graphictype = "dotline";
        this.map.graphics.add(newGraphic);
        this.map.graphics.refresh();
        editToolbar.activate(tool, evt.graphic);
      }.bind(this)
    );

    let graphic = this.eventEmitersService.activeAssetFeature[2];
    graphic.symbol = null;
    this.map.graphics.add(graphic);
    const old_geom = graphic.geometry;
    const current_asset_location = editToolbar.getCurrentState();
    current_asset_location.graphic = graphic;
    const featureLayerTemp = this.eventEmitersService.activeAssetFeature[1].lyrObj;
    if (featureLayerTemp.geometryType === "esriGeometryPoint") {
      Swal.fire(this.translateService.instant("Select.alertcarddisplay.cannotbereshaped"));
    } else {
      editToolbar.activate(tool, graphic);
      this.clickMap = this.map.on(
        "dbl-click",
        function () {
          this.eventEmitersService.enablebutton();
          this.EnableIcon(true); //TSDRSAM-1869
          if (this.eventEmitersService.selectedFeaturesOnMap.length == 1 && this.eventEmitersService.selectedFeaturesOnMap[0].Data.length == 1) {
            this.eventEmitersService.setEnableSingleClick(true);
          }
          if (this.objAssetDetailService.isOutsideRegion(graphic.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
            this.populateRegionGeometry().then(
              function (results) {
                let isAssetinAnyRegion = false;
                for (let i = 0; i < results.length; i++) {
                  if (this.objAssetDetailService.isOutsideRegion(graphic.geometry, this.geometryEngine, results[i])) {
                    isAssetinAnyRegion = false;
                  } else {
                    isAssetinAnyRegion = true;
                    this.assetPlacedinRegion = results[i];
                    break;
                  }
                }
                if (isAssetinAnyRegion) {
                  this.map.enableDoubleClickZoom(true);
                  this.clickMap.remove();
                  editToolbar.deactivate();
                  editToolbar.refresh();

                  this.map.graphics.remove(
                    this.map.graphics.graphics.filter((item) => {
                      if (item.graphictype == "dotline") {
                        return item;
                      }
                    })[0]
                  );
                  this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayerTemp.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                    function (feature) {
                      this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                      this.refreshAfterEdit(feature.features[0]);
                    }.bind(this)
                  );
                  this.map.graphics.refresh();
                } else {
                  this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayerTemp.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                    function (feature) {
                      this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                    }.bind(this)
                  );
                  if (this.objAssetDetailService.crossesPolygon(graphic.geometry, this.geometryEngine, this.currentUser.selectedRegion)) {
                    Swal.fire(this.translateService.instant("Select.newasset.differentregion"));
                  } else {
                    Swal.fire(this.translateService.instant("Select.newasset.outside"));
                  }
                  this.map.enableDoubleClickZoom(true);
                  this.clickMap.remove();
                  editToolbar.deactivate();
                  editToolbar.refresh();

                  this.map.graphics.remove(
                    this.map.graphics.graphics.filter((item) => {
                      if (item.graphictype == "dotline") {
                        return item;
                      }
                    })[0]
                  );
                  this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayerTemp.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                    function (feature) {
                      this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                      this.refreshAfterEdit(feature.features[0]);
                    }.bind(this)
                  );
                  this.map.graphics.refresh();
                  return;
                }
              }.bind(this)
            );
          } else {
            Swal.fire({
              title: this.translateService.instant("Select.alertcarddisplay.reshapeasset"),
              text: this.translateService.instant("Select.alertcarddisplay.confirmreshapeasset"),
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.translateService.instant("Select.alertcarddisplay.savebtn")
            }).then(
              function (result) {
                if (result.value) {
                  graphic = this.updateLatLong(graphic);

                  this.query.updateAsset(featureLayerTemp, graphic).then(
                    function () {
                      this.map.enableDoubleClickZoom(true);
                      this.clickMap.remove();
                      editToolbar.deactivate();
                      editToolbar.refresh();
                      this.createReshapeTask(graphic, old_geom);
                      this.refreshAfterEdit(graphic);
                      // this.map.graphics.remove(this.map.graphics.graphics.filter((item) => { if (item.graphictype == "dotline") { return item } })[0]);
                      this.map.graphics.refresh();
                    }.bind(this)
                  );
                } else {
                  this.map.enableDoubleClickZoom(true);
                  this.clickMap.remove();
                  editToolbar.deactivate();
                  editToolbar.refresh();

                  this.map.graphics.remove(
                    this.map.graphics.graphics.filter((item) => {
                      if (item.graphictype == "dotline") {
                        return item;
                      }
                    })[0]
                  );
                  this.map.graphics.graphics.filter((item) => {
                    if (item.attributes && item.symbol && item.symbol.width == 4) return item;
                  })[0] = old_geom;
                  this.genericLayerService.getFilteredFeaturesByFieldWithGeom(featureLayerTemp.layerId, "AssetID", graphic.attributes.AssetID).subscribe(
                    function (feature) {
                      this.eventEmitersService.activeAssetFeature[2].geometry.paths = feature.features[0].geometry.paths;
                      this.refreshAfterEdit(feature.features[0]);
                    }.bind(this)
                  );
                  this.map.graphics.refresh();
                }
              }.bind(this)
            );
          }
        }.bind(this)
      );
    }
  }
  refreshAfterEdit(modifiedAsset?, old_geom?): void {
    this.refreshFeatures(modifiedAsset, old_geom);
    // if (this.map.getLayer("sms-layers")) {
    //   this.map.getLayer("sms-layers").refresh();
    // }

    // if (this.map.getLayer("sms-selection")) {
    //   this.map.getLayer("sms-selection").refresh();
    // }

    // this.map.graphics.refresh();
    // this.map.enableMapNavigation();

    // if (modifiedAsset && modifiedAsset.geometry) {
    //   const countFea = this.map.getLayer("sms-selection").graphics.filter((item) => {
    //     if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
    //   }).length;
    // }
    // if (modifiedAsset && modifiedAsset.geometry &&
    //    this.map.getLayer("sms-selection").graphics
    //    .filter((item)=>{if(item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item}).length>0) {
    //   if (this.map.getLayer("sms-selection")){
    //     const wm = this.webMercatorUtils.webMercatorToGeographic(modifiedAsset.geometry);
    //     if ((wm && wm.paths) || modifiedAsset.geometry.paths) {
    //       for(let i=0;i<this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item}).length;i++){
    //       if(this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[i].geometry.paths){
    //         this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[i].geometry = modifiedAsset.geometry;
    //       }
    //       // else{
    //       //   this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[i].geometry = this.getPolylineMidPoint(modifiedAsset.geometry);
    //       // }

    //       }

    //     }
    //     else {
    //       this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[0].geometry = this.getPolylineMidPoint(modifiedAsset.geometry);
    //      }
    //   }
    //   for(let j=0;j<this.map.graphics.graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item}).length;j++){
    //     if(this.map.graphics.graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[j].geometry.paths){
    //       this.map.graphics.graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[j].geometry = modifiedAsset.geometry;
    //     }
    //     else{
    //       this.map.getLayer("sms-selection").graphics.filter((item)=>{if( item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item})[j].geometry = modifiedAsset.geometry;
    //     }

    //     }
    // }
  }
  refreshFeatures(modifiedAsset?, old_geom?): void {
    let geometryAsset = modifiedAsset.geometry;
    if (!geometryAsset.spatialReference && old_geom && old_geom.spatialReference) {
      geometryAsset = old_geom;
    }
    const smsSelectionFeatures = this.map.getLayer("sms-selection").graphics.filter((item) => {
      if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
    });
    if (modifiedAsset && modifiedAsset.geometry) {
      if (smsSelectionFeatures.length) {
        for (let i = 0; i < smsSelectionFeatures.length; i++) {
          const selAsset = this.map.getLayer("sms-selection").graphics.filter((item) => {
            if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
          })[i];
          if (selAsset && selAsset.geometry.paths) {
            this.map.getLayer("sms-selection").graphics.filter((item) => {
              if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
            })[i].geometry = geometryAsset;
          } else {
            if (modifiedAsset.geometry.paths) {
              this.map.getLayer("sms-selection").graphics.filter((item) => {
                if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
              })[i].geometry = this.genericMapMethodsService.getPolylineMidPoint(geometryAsset);
            } else {
              this.map.getLayer("sms-selection").graphics.filter((item) => {
                if (item.attributes && item.attributes.AssetID == modifiedAsset.attributes.AssetID) return item;
              })[i].geometry = geometryAsset;
            }
          }
        }
      }
    }

    if (this.map.getLayer("sms-layers")) {
      this.map.getLayer("sms-layers").refresh();
    }
    if (this.map.getLayer("sms-selection")) {
      this.map.getLayer("sms-selection").refresh();
    }
    this.map.graphics.refresh();
    this.map.enableMapNavigation();
  }
  updateLatLong(graphic) {
    const wm = this.webMercatorUtils.webMercatorToGeographic(graphic.geometry);
    if (wm.paths) {
      graphic.attributes.Latitude = wm.paths[0][0][1];
      graphic.attributes.Longitude = wm.paths[0][0][0];
      graphic.attributes.Latitude2 = wm.paths[0][wm.paths[0].length - 1][1];
      graphic.attributes.Longitude2 = wm.paths[0][wm.paths[0].length - 1][0];
    } else {
      // TSDRSAM-975 By Manikantha V
      graphic.attributes.Latitude = wm.y;
      graphic.attributes.Longitude = wm.x;
    }
    return graphic;
  }
  populateRegionGeometry() {
    return new Promise((resolve) => {
      const rdata = [];
      const tempRegion = this.map.smsRegiondata;
      const fl = this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.regionId;
      tempRegion.forEach((region) => {
        const regionObj = region;
        const query = new this.Query();
        const qtask = new this.QueryTask(fl);
        query.where = "RegionName" + regionObj.regionLevel + "='" + regionObj.regionName + "'";
        query.returnGeometry = true;
        qtask.execute(query).then((evt) => {
          if (evt.features.length === 1) {
            const poly = new this.Polygon(evt.features[0].geometry);
            regionObj.Extent = poly.getExtent();
            regionObj.geom = poly;
            rdata.push(regionObj);
          } else {
            const subRegionsList = [];
            for (let i = 0; i < evt.features.length; i++) {
              subRegionsList.push(evt.features[i].geometry);
            }
            const geom_union = this.geometryEngine.union(subRegionsList);
            const poly = new this.Polygon(geom_union);
            regionObj.Extent = poly.getExtent();
            regionObj.geom = poly;
            rdata.push(regionObj);
          }
          if (rdata.length === this.map.smsRegiondata.length) {
            resolve(rdata);
          }
        });
      });
    });
  }
  populateChangedAssetDefaultValues(placedinRegion, asset) {
    return new Promise(
      function (resolve) {
        const promise = this.objAssetDetailService.populateDefaultRegionValues(this.currentUser, placedinRegion, this.Query, this.QueryTask);
        promise.then((result) => {
          // let regionData: any;
          const regionData = result;
          const attrib = asset.attributes;
          regionData.features.forEach((ftr) => {
            // let rgn, geom;
            const rgn = this.webMercatorUtils.webMercatorToGeographic(ftr.geometry);
            const geom = this.webMercatorUtils.webMercatorToGeographic(asset.geometry);
            if (!this.objAssetDetailService.isOutsideRegion(geom, this.geometryEngine, rgn)) {
              attrib.Region1 = ftr.attributes.RegionName1;
              attrib.Region2 = ftr.attributes.RegionName2;
              attrib.Region3 = ftr.attributes.RegionName3;
              attrib.Region4 = ftr.attributes.RegionName4;
            }
          });
          asset.setAttributes(attrib);
          resolve(asset);
        });
      }.bind(this)
    );
  }
  saveTask(asset, featureClassName?) {
    this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    if (this.selectedLayer) {
      this.ftrLyrActivity.on(
        "Load",
        function (evt) {
          this.getLayerId(evt.layer.types, this.selectedLayer);
        }.bind(this)
      );
    } else {
      this.ftrLyrActivity.on(
        "Load",
        function (evt) {
          this.getLayerId(evt.layer.types, this.selectRelAsset);
        }.bind(this)
      );
    }
    const featurese = [];
    const features = new this.Graphic();
    // this.asset = asset;
    let assetId;
    if (asset.attributes && asset.attributes.AssetID) {
      assetId = asset.attributes.AssetID;
    } else {
      //Jira Id-935
      const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === this.selectedLayer)[0].attributes.LayerCode;
      assetId = prefix + "_" + asset[0].attributes.OBJECTID;
    }

    let geomString;

    if (asset[0] && asset[0].geometry) {
      geomString = asset[0].geometry.toJson();
    } else {
      geomString = this.rowdataobj[this.eventEmitersService.selectedLayerIndexOnData].Data[this.eventEmitersService.selectedParentIndexOnData].geometry.toJson();
    }
    if (geomString.paths) {
      geomString.x = geomString.paths[0][0][0];
      geomString.y = geomString.paths[0][0][1];
      delete geomString.paths;
    }
    let region;
    if (asset[0] && asset[0].attributes) {
      if (asset[0].attributes.Region4) {
        region = asset[0].attributes.Region4;
      } else if (asset[0].attributes.Region3) {
        region = asset[0].attributes.Region3;
      } else if (asset[0].attributes.Region2) {
        region = asset[0].attributes.Region2;
      } else if (asset[0].attributes.Region1) {
        region = asset[0].attributes.Region1;
      } else if (asset[4].attributes.region) {
        region = asset[4].attributes.region;
      }
    } else {
      region = null;
    }

    if (!region) {
      // console.log(this.activeParentAsset);
      if (this.activeParentAsset[0]) {
        if (this.activeParentAsset[0].Region4) {
          region = this.activeParentAsset[0].Region4;
        } else if (this.activeParentAsset[0].Region3) {
          region = this.activeParentAsset[0].Region3;
        } else if (this.activeParentAsset[0].Region2) {
          region = this.activeParentAsset[0].Region2;
        } else if (this.activeParentAsset[0].Region1) {
          region = this.activeParentAsset[0].Region1;
        } else if (this.activeParentAsset[0].region) {
          region = this.activeParentAsset[0].region;
        }
      }
    }
    let status;
    if (this.dataEditorSettings.taskType === "New") {
      status = "Open";
    } else {
      status = "Closed";
    }
    let assetName;
    if (featureClassName) {
      assetName = featureClassName;
    } else {
      assetName = this.selectedLayer;
    }
    features.setAttributes({
      Status: status,
      CreatedBy: this.currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      Region: region,
      AssetID: assetId,
      AssetName: assetName,
      WorkOrderDesc: "",
      Comment: "",
      GeomWKT: JSON.stringify(geomString)
    });
    featurese.push(features);

    this.ftrLyrTblMaintenance
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          // updateing MaintID for newly inserted Maintanance record
          const featureseAssetID = [];
          const featuresA = new this.Graphic();
          featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
          featureseAssetID.push(featuresA);
          // TSDRSAM-1304 Manikantha V
          this.ftrLyrTblMaintenance
            .applyEdits(null, featureseAssetID, null)
            .then(
              function () {
                console.log("updated");
              }.bind(this)
            )
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            });
          // End
          const actfeaturese = [];
          const actfeatures = new this.Graphic();
          actfeatures.setAttributes({
            MaintID: "MNT_" + newrow[0].objectId,
            Asset: this.assetTypeId,
            Activity: this.dataEditorSettings.taskType,
            Description: " ",
            ModifiedBy: this.currentUser.username
          });
          actfeaturese.push(actfeatures);
          this.ftrLyrActivity.applyEdits(actfeaturese, null, null);
          if (this.wrkStatus === "Closed" && (this.activity === "Replace" || this.activity === "Retire")) {
            features.attributes.OBJECTID = newrow[0].objectId;
            this.task.completeTask(features.attributes);
          }
        }.bind(this)
      )
      .catch(function (error) {
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
      });
  }
  getLayerId(data, layer_name) {
    this.assetTypeId = data.filter(
      function (hero) {
        return hero.name === layer_name;
      }.bind(this)
    )[0].id;
  }
  createRelocateTask(asset, old_geom) {
    console.log(old_geom);
    this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    const featurese = [];
    const features = new this.Graphic();

    // this.asset = asset;
    //const prefix = this.objAssetDetailService.getAssetIDFormat(this.selectedLayer);
    const assetId = asset.attributes.AssetID;
    const geomString = asset.geometry.toJson();
    if (geomString.paths) {
      geomString.x = geomString.paths[0][0][0];
      geomString.y = geomString.paths[0][0][1];
      delete geomString.paths;
    }
    let region;
    if (asset.attributes.Region4) {
      region = asset.attributes.Region4;
    } else if (asset.attributes.Region3) {
      region = asset.attributes.Region3;
    } else if (asset.attributes.Region2) {
      region = asset.attributes.Region2;
    } else if (asset.attributes.region) {
      region = asset.attributes.region;
    }
    const status = "Closed";
    // TSDRSAM-1114 By Manikantha V
    features.setAttributes({
      Status: status,
      CreatedBy: this.currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      CompletedBy: this.currentUser.username,
      CompletionDate: new Date(Date.now()).getTime(),
      Region: region,
      Reason: "Other",
      AssetID: assetId,
      AssetName: this.selectedLayer,
      WorkOrderDesc: "",
      Comment: "",
      GeomWKT: JSON.stringify(geomString),
      PendingChanges: JSON.stringify(this.generatePendingChangesForGeometryChange(this.rollbackData.Shape, asset.geometry))
    });
    featurese.push(features);
    this.ftrLyrTblMaintenance
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          const featureseAssetID = [];
          const featuresA = new this.Graphic();
          featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
          featureseAssetID.push(featuresA);
          //TSDRSAM-1304 Manikantha V
          //TSDRSAM-1580 added by Harshal,code fix for TSDRSAM-1304 merged to Dev branch
          this.ftrLyrTblMaintenance
            .applyEdits(null, featureseAssetID, null)
            .then(
              function () {
                console.log("updated");
              }.bind(this)
            )
            .catch((error) => {
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
            });
          // End
          const actfeaturese = [];
          const actfeatures = new this.Graphic();
          actfeatures.setAttributes({
            MaintID: "MNT_" + newrow[0].objectId,
            Asset: this.assetTypeId,
            Activity: "Relocate",
            Description: " ",
            ModifiedBy: this.currentUser.username
          });
          actfeaturese.push(actfeatures);
          this.ftrLyrActivity.applyEdits(actfeaturese, null, null).then(
            function () {
              this.eventEmitersService.setreplaceTaskCreated(true);
            }.bind(this)
          );
          this.objAssetDetailService.assetChanged.emit(this.activeParentAsset);
        }.bind(this)
      )
      .catch((error) => {
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
      });
  }
  createReshapeTask(asset, old_geom) {
    console.log(old_geom);
    this.ftrLyrTblMaintenance = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
    this.ftrLyrActivity = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.activityId, { outFields: ["*"] });
    const featurese = [];
    const features = new this.Graphic();
    //this.asset = asset;
    // const prefix = this.objAssetDetailService.getAssetIDFormat(this.selectedLayer);
    const assetId = asset.attributes.AssetID;
    const geomString = asset.geometry.toJson();
    if (geomString.paths) {
      geomString.x = geomString.paths[0][0][0];
      geomString.y = geomString.paths[0][0][1];
      delete geomString.paths;
    }
    let region;
    if (asset.attributes.Region4) {
      region = asset.attributes.Region4;
    } else if (asset.attributes.Region3) {
      region = asset.attributes.Region3;
    } else if (asset.attributes.Region2) {
      region = asset.attributes.Region2;
    } else if (asset.attributes.region) {
      region = asset.attributes.region;
    }
    const status = "Closed";

    features.setAttributes({
      Status: status,
      CreatedBy: this.currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      Region: region,
      AssetID: assetId,
      AssetName: this.selectedLayer,
      WorkOrderDesc: "",
      Comment: "",
      CompletedBy: this.currentUser.username,
      CompletionDate: new Date(Date.now()).getTime(),
      GeomWKT: JSON.stringify(geomString),
      PendingChanges: JSON.stringify(this.generatePendingChangesForGeometryChange(this.rollbackData.Shape, asset.geometry))
    });
    featurese.push(features);

    this.ftrLyrTblMaintenance
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          // updateing MaintID for newly inserted Maintanance record
          const featureseAssetID = [];
          const featuresA = new this.Graphic();
          featuresA.setAttributes({
            OBJECTID: newrow[0].objectId,
            MaintID: "MNT_" + newrow[0].objectId
          });
          featureseAssetID.push(featuresA);
          this.ftrLyrTblMaintenance
            .applyEdits(null, featureseAssetID, null)
            .then(
              function () {
                console.log("reshaped");
              }.bind(this)
            )
            .catch(function (error) {
              // console.log("===============================================");
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              // console.log("error = ", error);
            });
          // End
          const actfeaturese = [];
          const actfeatures = new this.Graphic();

          actfeatures.setAttributes({
            // updateing MaintID for newly inserted Maintanance record
            // "MaintID": newrow[0].objectId,
            MaintID: "MNT_" + newrow[0].objectId,
            Asset: this.assetTypeId,
            Activity: "Reshape",
            Description: " ",
            ModifiedBy: this.currentUser.username
          });
          actfeaturese.push(actfeatures);
          this.ftrLyrActivity.applyEdits(actfeaturese, null, null).then(
            function () {
              // console.log(newrow);
              this.eventEmitersService.setreshapeTaskCreated(true);
            }.bind(this)
          );
          this.objAssetDetailService.assetChanged.emit(this.activeParentAsset);
        }.bind(this)
      )
      .catch(function (error) {
        // console.log("===============================================");
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
      });
  }

  SaveExistData() {
    this.cs.getFeatures(this.eventEmitersService.activeAssetFeature[1].lyrObj.url, "ObjectID = " + this.eventEmitersService.activeAssetFeature[0].OBJECTID, this.Query, this.QueryTask).then((val) => {
      const result = val[0];
      // console.log(result);
      this.rollbackData = {};
      this.rollbackData = result.attributes;
      this.rollbackData.Shape = result.geometry;
    });
  }
  updateTaskCountOnActiveAsset() {
    this.genericLayerService.getFeaturesByCond(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID = '" + this.selectedFeature[0].AssetID + "'").subscribe((results: any) => {
      const openMaint = results.features.filter((maint) => {
        return maint.attributes.Status == "Open";
      }); //7/28/20 jcc - filter to open maintenance only
      // let i = this.objectIndexInArrayOfObjects('task', this.activeParentAsset);
      // if (i >= 0) {
      if (this.activeParentAsset[5]) {
        this.activeParentAsset[5]["task"] = openMaint.length;
      }
      // } else {
      //   this.activeParentAsset.push({ task: openMaint.length });
      // }
    });
  }
  childLayerAccess(layerNameid) {
    if (layerNameid.layer_id) {
      layerNameid = layerNameid.layer_id;
    }
    const layerinfo = this.currentUser.layerLevelInfo.filter(
      function (layer) {
        if (parseInt(layer.gisLayerId, 10) === parseInt(layerNameid, 10)) {
          return layer;
        }
      }.bind(this)
    )[0];
    // Layer
    if (layerinfo) {
      return true;
    }
    return false;
  }
  updateChildFeaturesTaskCount() {
    if (this.optenTasksJson && this.optenTasksJson.OpenTaskByAsset.length > 0) {
      console.log(this.childRecords);
      this.childRecords.forEach(
        function (layer) {
          if (layer.RelatedData && layer.RelatedData.length > 0) {
            layer.RelatedData.forEach(
              function (feature) {
                console.log(feature);
                // .OpenTaskCount
                let layerName;
                switch (feature.attributes.AssetID.split("_")[0]) {
                  case "SIG":
                    layerName = "Sign";
                    break;
                  case "LGT":
                    layerName = "Light";
                    break;
                }
                let opentask;
                if (this.optenTasksJson && this.optenTasksJson.OpenTaskByAsset[0] && this.optenTasksJson.OpenTaskByAsset[0].Support && this.optenTasksJson.OpenTaskByAsset[0].Support[0][layerName]) {
                  opentask = this.optenTasksJson.OpenTaskByAsset[0].Support[0][layerName].filter((item) => {
                    if (item.AssetId == feature.attributes.AssetID) {
                      return item;
                    }
                  })[0];
                  if (opentask) {
                    feature.attributes.task = opentask.OpenTaskCount;
                  } else {
                    feature.attributes.task = 0;
                  }
                }

                console.log(opentask);
              }.bind(this)
            );
          }
        }.bind(this)
      );
    }
  }
  findUserAccessByRoleRegionLayer() {
    if (this.currentUser.selectedRegion.accessPrivilege === "Data Editor") {
      if (this.activeParentAsset && this.activeParentAsset[1] && this.activeParentAsset[1].lyrObj.layerId) {
        const layerinfo = this.currentUser.layerLevelInfo.filter(
          function (layer) {
            if (parseInt(layer.gisLayerId, 10) === this.activeParentAsset[1].lyrObj.layerId) {
              return layer;
            }
          }.bind(this)
        )[0];
        // Layer
        if (layerinfo && layerinfo.accessPrivilege === "Data Editor") {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }
  public setReplaceYearData() {
    this.assetAttributesService.getElifeTableValues(this.currentUser.serviceLyrInfo.baseLifeValuesId).subscribe((res: any) => {
      if (res.features.length > 0) {
        this.eventEmitersService.baseLifeValuesData = res;
      }
    });
    this.assetAttributesService.getElifeTableValues(this.currentUser.serviceLyrInfo.modifierValueId).subscribe((res: any) => {
      if (res.features.length > 0) {
        this.eventEmitersService.modifierValueData = res;
      }
    });
    this.assetAttributesService.getElifeTableValues(this.currentUser.serviceLyrInfo.modifierConfigId).subscribe((res: any) => {
      if (res.features.length > 0) {
        this.eventEmitersService.modifierConfig = res;
      }
    });
  }
  // Added by Abhilash for JIRA ID:-34 on 08/12/2021
  getFieldsInfo() {
    try {
      this.activeAsset = this.eventEmitersService.activeAssetFeature;
      this.assetAttributesService.getAssetAttributes().subscribe((results) => {
        this.result = results;
        if (this.result) {
          const columns = this.result.features.filter(
            (feature) =>
              //Jira Id-933
              feature.attributes.TableName.toLowerCase() === this.activeAsset[1].tableName.toLowerCase()
          );
          this.fieldsInfo = this.fieldsInfoData(columns, false);
          if (this.activeAsset[1].tableName === "Support") {
            // Mani 11/19/2021  Added if condition
            const columnsForLight = this.result.features.filter((feature) => feature.attributes.TableName === "Light");
            const columnsForSign = this.result.features.filter((feature) => feature.attributes.TableName === "Sign");

            this.fieldsInfoSign = this.fieldsInfoData(columnsForSign, true);
            this.fieldsInfoLight = this.fieldsInfoData(columnsForLight, true);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Abhilash for JIRA ID:-34 on 08/12/2021
  fieldsInfoData(value, flag) {
    try {
      const fieldInfosdata = [];
      const CodedValues = {};
      if (!flag) {
        for (let ind = 0; ind < this.activeAsset[1].lyrObj._fields.length; ind++) {
          if (this.activeAsset[1].lyrObj._fields[ind].domain) {
            for (let index = 0; index < this.activeAsset[1].lyrObj._fields[ind].domain.codedValues.length; index++) {
              const val = this.activeAsset[1].lyrObj._fields[ind].domain.codedValues[index];
              CodedValues[val.code] = val.name;
            }
          }
        }
      }
      // else {
      //   if (this.childRecords.length && this.childRecords[0].RelatedData) {
      //   }
      // }
      value.forEach((element) => {
        if (element.attributes.IsCardviewColumnVisible === 1) {
          fieldInfosdata.push({
            fieldName: element.attributes.Field,
            IsRequired: element.attributes.IsRequired,
            labelSL: element.attributes.FieldAlias_SL,
            label: element.attributes.FieldAlias,
            type: "esriFieldTypeString",
            FieldOrder: element.attributes.FieldOrder,
            codedValues: CodedValues,
            tableName: element.attributes.TableName,
            IsCardviewColumnVisible: element.attributes.IsCardviewColumnVisible
          });
        }
      });
      return fieldInfosdata.sort(function (a, b) {
        return a.FieldOrder - b.FieldOrder;
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Added by Abhilash for JIRA ID:-34 on 08/12/2021
  getCodedValue(asset, layer): string {
    try {
      if (layer === "SupportToSign") {
        let description = "";
        for (let ind = 0; ind < this.genericMapMethodsService.signTypes.length; ind++) {
          for (let i = 0; i < this.genericMapMethodsService.signTypes[ind].domains["AssetCode"].codedValues.length; i++) {
            if (this.genericMapMethodsService.signTypes[ind].domains["AssetCode"].codedValues[i].code == asset.attributes.AssetCode) {
              description = this.genericMapMethodsService.signTypes[ind].domains["AssetCode"].codedValues[i].code + " " + this.genericMapMethodsService.signTypes[ind].domains["AssetCode"].codedValues[i].name;
            }
          }
        }
        return description;
      } else if (layer === "SupportToLight") {
        let description = "";
        for (let ind = 0; ind < this.genericMapMethodsService.lightTypes.length; ind++) {
          for (let i = 0; i < this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues.length; i++) {
            if (this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues[i].code == asset.attributes.AssetCode) {
              description = this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues[i].name;
            }
          }
        }
        return description;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Jira Id-34 Bug-TSDRSAM-1546
  getCodedValueForLight(asset): string {
    try {
      let description = "";
      for (let ind = 0; ind < this.genericMapMethodsService.lightTypes.length; ind++) {
        for (let i = 0; i < this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues.length; i++) {
          if (this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues[i].code == asset.attributes.AssetCode) {
            description = this.genericMapMethodsService.lightTypes[ind].domains["AssetCode"].codedValues[i].name;
          }
        }
      }
      return description;
    } catch (error) {
      console.error(error);
    }
  }
  // JIRA ID:61
  minimizeAssetViewer() {
    try {
      this.isShown = !this.isShown;
      const assetDiv = document.querySelector("#assestDragDiv");
      if (!this.isShown) {
        assetDiv.setAttribute("style", "height: 57px;");
        document.getElementById('cardview').style.height='fit-content';
      } else {
        assetDiv.setAttribute("style", "height: 100%;overflow:hidden; border-radius: none;");
        document.getElementById('cardview').style.height='100%';
      }
    } catch (error) {
      console.error(error);
    }
  }
  //HEADING LOGIC
  getCloseIcon() {
    return !this.eventEmitersService.addNewParent && !this.eventEmitersService.addNewChild ? "cross" : null;
  }

  getAssetAddIcon() {
    if (this.selectedLayer == "Support" && this.currentUser.rolePermissions.assetAdd && (this.signAddAccess || this.lightAddAccess)) {
      //TSDRSAM-1911
      return this.currentUser.rolePermissions.assetAdd && !this.eventEmitersService.addNewParent && this.currentUser.selectedRegion.accessPrivilege === "Data Editor" ? "plus" : null;
    } else if (this.showreshape && !this.eventEmitersService.addNewParent && this.currentUser.rolePermissions.assetRelocate && !this.activeVisualizationAsset) {
      return "draw";
    }

    return null;
  }
  getAssetAddTooltip() {
    if (this.selectedLayer == "Support" && this.currentUser.rolePermissions.assetAdd && (this.signAddAccess || this.lightAddAccess)) {
      return this.currentUser.rolePermissions.assetAdd && this.currentUser.selectedRegion.accessPrivilege === "Data Editor" ? this.translateService.instant("Select.carddisplay.addasset") : null;
    } else if (this.showreshape && this.currentUser.rolePermissions.assetRelocate && !this.activeVisualizationAsset) {
      return this.translateService.instant("Select.carddisplay.reshapebtn");
    }

    return null;
  }

  getRelocateAssetIcon() {
    return !this.eventEmitersService.addNewParent && this.currentUser.rolePermissions.assetRelocate && this.findUserAccessByRoleRegionLayer() && this.eventEmitersService.activeAssetFeature[0].AssetID
      ? //TSDRSAM-1818: relocate icon was not availble for child asset
        // &&
        // !this.eventEmitersService.activeAssetFeature[0].AssetID.includes("SIG") &&
        // !this.eventEmitersService.activeAssetFeature[0].AssetID.includes("LGT")
        "add-query"
      : null;
  }
  //Visualization layers
  OpenVisualizationAssetDetail() {
    this.showAssetDetail();
  }

  //TSDRSAM-1426
  generatePendingChangesForGeometryChange(shapeBefore, shapeAfter) {
    let deltaResult = { before: { Shape: {} }, after: { Shape: {} } };

    deltaResult.before.Shape = shapeBefore;

    //remove unwanted attributes in geometry field
    if ("cache" in shapeAfter) {
      delete shapeAfter["cache"];
    }
    if ("_path" in shapeAfter) {
      delete shapeAfter["_path"];
    }
    deltaResult.after.Shape = shapeAfter;

    return deltaResult;
  }
  //TSDRSAM-1869
  EnableIcon(isEnable: boolean) {
    this.reshapeIconDiv = document.getElementById("draw");
    this.relocateIconDiv = document.getElementById("relocate");
    if (isEnable) {
      this.reshapeIconDiv?.classList.remove("not-active");
      this.relocateIconDiv?.classList.remove("not-active");
    } else {
      this.reshapeIconDiv?.classList.add("not-active");
      this.relocateIconDiv?.classList.add("not-active");
    }
  }
  //TSDRSAM-1911
  getMinMaxIcon() {
    if (!this.eventEmitersService.addNewParent) {
      return this.isShown == false ? "arrow-down" : "arrow-up";
    }
    return null;
  }
}
