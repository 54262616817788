<div class="text-dark">
    <div class="form-row ">
        <div class="form-group col-sm-3">
            <label>Agency Name</label>
            <input id="agencyName" class="form-control" placeholder="Enter customer name…" aria-label="Agency Name"
                [(ngModel)]="agencyName" autocomplete="off" readonly />
        </div>
        <div class="form-group col-sm-3">
            <label for="solutionTire">Solution Tier</label>
            <input id="SolutionTier" class="form-control" placeholder="Enter customer name…" aria-label="SolutionTier"
                [(ngModel)]="SolutionTier" autocomplete="off" readonly />
        </div>
        <div class="form-group col-sm-3">
            <label for="inputState">Primary Agency Language</label>
            <input id="PrimaryAgencyLanguage" class="form-control" placeholder="Enter customer name…" aria-label="PrimaryAgencyLanguage"
                [(ngModel)]="PrimaryAgencyLanguage" autocomplete="off" readonly />
        </div>
        <div class="form-group col-sm-3">
            <label for="inputState">Secondary Agency Language</label>
            <input id="SecondaryAgencyLanguage" class="form-control" placeholder="Enter customer name…" aria-label="SecondaryAgencyLanguage"
                [(ngModel)]="SecondaryAgencyLanguage" autocomplete="off" readonly />
        </div>
    </div>
    <div class="form-row ">
        <div class="form-group col-sm-3">
            <label>Agency Web Service</label>
            <input id="agencyWebService" class="form-control" [(ngModel)]="agencyWebService" aria-label="agencyWebService"
                placeholder="Enter Agency Web Service …" autocomplete="off" readonly />
        </div>
        <div class="form-group col-sm-3">
            <label>Agency Spatial Database</label>
            <input id="agencySpatialDatabase" class="form-control" [(ngModel)]="agencySpatialDatabase" aria-label="agencySpatialDatabase Name"
                placeholder="Enter Agency Spatial Database…" autocomplete="off" readonly />
        </div>
    </div>
</div>