import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdministratorComponent } from "src/app/administrator/administrator.component";
import { AgencyConfigComponent } from "src/app/administrator/agencyConfig/agency.component";
import { TandCComponent } from "src/app/administrator/TandC/TandC.component";
import { systemReportComponent } from "src/app/administrator/systemReport/systemReport.component";
import { summaryComponent } from "src/app/administrator/summary/summary.component";
import { PredefinedQueryComponent } from "src/app/administrator/PredefinedQuery/PredefinedQuery.component";
import { AssetConfigComponent } from "src/app/administrator/assetConfig/assetConfig.component";
import { ExpectedLifeComponent } from "src/app/administrator/ExpectedLife/ExpectedLife.component";
// import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import { AgGridModule } from "ag-grid-angular";
import { LangConfigComponent } from "src/app/administrator/agencyConfig/LangConfig/langconfig.component";
import { TranslateModule } from "@ngx-translate/core";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MMMBaseLifeModifier } from "src/app/administrator/ExpectedLife/BaseLifeModifier/BaseLifeModifier.component";
import { MMMBaseLife } from "src/app/administrator/ExpectedLife/BaseLife/BaseLife.component";
import { MMMQueryComponent } from "src/app/administrator/PredefinedQuery/QueryBuilder/MMMQuerycomponent";
import { DualListComponent } from "./dual-list/dual-list.component";
import { ResourceRequireComponent } from "src/app/administrator/ResourceRequire/ResourceRequire.component";
import { headAdminComponent } from "src/app/administrator/HeaderMMMAdmin/headAdmin.component";
import { AngularDraggableModule } from "angular2-draggable";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
//Added for Visualization layers
import { visualizationDataLayerComponent } from "src/app/administrator/visualizationDataLayer/visualizationDataLayer.component";
import { PasswordConfigComponent } from './passwordConfig/password-config/password-config.component';
@NgModule({
  declarations: [
    AdministratorComponent,
    AgencyConfigComponent,
    TandCComponent,
    systemReportComponent,
    summaryComponent,
    PredefinedQueryComponent,
    AssetConfigComponent,
    ExpectedLifeComponent,
    LangConfigComponent,
    MMMBaseLifeModifier,
    MMMBaseLife,
    MMMQueryComponent,
    DualListComponent,
    ResourceRequireComponent,
    headAdminComponent,
    visualizationDataLayerComponent,//Added for Visualization layers
    PasswordConfigComponent 
  ],
  imports: [CommonModule, FontAwesomeModule, NgbModule, FormsModule, ReactiveFormsModule, AgGridModule, TranslateModule, DragDropModule, AngularDraggableModule, MatFormFieldModule, MatDatepickerModule, MatInputModule],
  exports: [AdministratorComponent],
  providers: [MMMAdminService, adminGlobal]
})
export class MMMAdminModule {
  static forRoot(): ModuleWithProviders<MMMAdminModule> {
    return { ngModule: MMMAdminModule, providers: [] };
  }
}
