import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SMSHttpClient } from "../helpers/http-client";
import { HttpHeaders } from "@angular/common/http";
import { IRequestOptions } from "src/app/interface/requestOption";

@Injectable()
export class SystemReportsService {
  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };
  constructor(private httpClient: SMSHttpClient) {}

  // Democratize Report
  getDemocratize(clientId, obj) {
    return this.httpClient.get(environment.democratizeReport + obj);
  }

  // Activity Reports
  getActivityTotalReport() {
    return this.httpClient.get(environment.activityTotalReport);
  }
  // Sheet Count Report
  getSheetCountReport() {
    return this.httpClient.get(environment.sheetCountReport);
  }
  // Activity By Reason Report
  getActivityByReasonReport(Obj) {
    return this.httpClient.get(environment.activityByReasonReport + Obj);
  }
  // Asset Retire Report
  getAssetRetireReport(region) {
    return this.httpClient.get(environment.assetRetireReport + "?Region=" + region);
  }
  // Asset Details Report
  getAssetDetailsReport(Obj) {
    return this.httpClient.get(environment.specificAssetReport + Obj);
  }
  // Project Replace Asset Details Report
  getProjectReplaceAssetDetails() {
    return this.httpClient.get(environment.getProjectReplaceAssetDetails);
  }
  // Employee Action Log Report
  getEmployeeActionLog(Obj) {
    return this.httpClient.get(environment.getEmployeeActionLog + Obj);
  }
  //Brush pickup Report
  getBrushPickupData(Obj) {
    return this.httpClient.get(environment.getBrushPickupData + Obj);
  }
  // Get System Report from Client
  getSystemReport(ClientID) {
    return this.httpClient.get(environment.getClientlist + "/" + ClientID + "/" + environment.getReport);
  }
  //added by sowjanya JIRA ID:-48 on 27/09/2021
  //Get Custom Reports
  GetCustomReportARTESP(Url) {
    return this.httpClient.get(environment.getCustomReportARTESP + Url);
  }
  //added by sowjanya JIRA ID-51,52 on 27/09/2021
  //Get Summary Reports
  GetSummaryReport(Obj1) {
    return this.httpClient.post(environment.getSummaryReport, Obj1);
  }
  //Get Replaced and Retired Asset data
  getRollbackData(region, layerName, activity) {
    return this.httpClient.get(environment.getRollbackData + "?Region=" + region + "&AssetClass=" + layerName + "&Activity=" + activity);
  }
}
