import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, HostListener, OnDestroy } from "@angular/core";
import { loadModules } from "esri-loader";
import { ILayer } from "../interface/layer";
import { UserService } from "../services/user.service";
import { IRegion } from "../interface/region";
import { MapToolsComponent } from "../map-tools/map-tools.component";
import { SearchToolComponent } from "../search-tool/search-tool.component";
import { AssetDetailService } from "../services/assetDetail.service";
import { IUser } from "../interface/user";
import { AuthenticationService } from "../services/authentication.service";
import Swal from "sweetalert2";
import { forkJoin } from "rxjs";
import { AssetAttributesService } from "../services/assetAttibutes.service";
import { SelectToolComponent } from "../select-tool/select-tool.component";
import { QueryLayers } from "../services/queryLayers.service";
import { environment } from "src/environments/environment";
import html2canvas from "html2canvas";
import { TranslateService } from "@ngx-translate/core";
import { GenericLayerService } from "../services/genericLayer.service";
import { Globals } from "../select/globals";
import { CrowdSourcedInfoComponent } from "src/app/crowd-sourced-info/crowd-sourced-info.component";

import { EventEmitersService } from "../services/eventEmiters.service";
import { GenericMapMethodsService } from "../services/generic-map-methods.service";
import { DatePipe } from "@angular/common";
import { faAngleDoubleLeft, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { BASE64_PREFIX } from "../shared/utils";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Client } from "../interface/client";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { IdleModalComponent } from "../modals/idle-modal/idle-modal.component";
import { map } from "rxjs/operators";


@Component({
  selector: "sms-esri-map",
  templateUrl: "./esri-map.component.html",
  styleUrls: ["./esri-map.component.scss"],
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: "w-100" }
})
export class EsriMapComponent implements OnInit, OnDestroy {
  isOpenCard = false;
  isFromQB: boolean;
  ExportStatus;
  gpServiceTrackUrl;
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (
      event.target["localName"] === "span" &&
      event.target["className"] == "dijit dijitReset dijitInline esriButton esriButtonActive dijitActive esriButtonFocused esriButtonActiveFocused dijitActiveFocused dijitFocused"
    ) {
      (<HTMLInputElement>event.target).click();
      let measureToolsText = (<HTMLInputElement>event.target).querySelectorAll("span.dijitReset.dijitInline.dijitButtonText.dijitDisplayNone");
      //let toolNames = ["area", "distance", "location"];
      this.measurement.setTool(measureToolsText[0].innerHTML.toLowerCase(), true);
    }
    if (event.target["type"] === "checkbox" && event.target["id"].indexOf("lyr-chk-") == 0) {
      (<HTMLInputElement>event.target).click();
    }
  }
  constructor(
    private userService: UserService,
    private assetAttributesService: AssetAttributesService,
    public assetService: AssetDetailService,
    private authenticationService: AuthenticationService,
    private queryService: QueryLayers,
    private translateService: TranslateService,
    private GenericLayerService: GenericLayerService,
    public objGlobal: Globals,
    public eventEmitersService: EventEmitersService,
    public genericMapMethodsService: GenericMapMethodsService,
    public datepipe: DatePipe,
    private idle: Idle,
    private modalService: NgbModal
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.proLicense = this.currentUser.clientType === "PROFESSIONAL" ? true : false;
    this.BaseMapproLicense = this.currentUser.clientType === "PREMIUM" || this.currentUser.clientType === "PROFESSIONAL" ? true : false;
    this.eventEmitersService.emitShowGrid.subscribe(() => {
      this.showgrid = this.eventEmitersService.showGrid;
    });
    this.eventEmitersService.emitSelectedFeaturesOnMap.subscribe((data) => {
      this.rowdataobj = data;
    });
    this.eventEmitersService.emitShowCard.subscribe((shwocard) => {
      this.showcard = shwocard;
    });
    this.eventEmitersService.emitMaintenanceView.subscribe((showSelectedTasksData) => {
      this.showSelectedTasksData = showSelectedTasksData;
      if (this.showSelectedTasksData) {
        this.showTask = false;
      }
    });
    //Added for Visualization layers
    //Visualization layers
    this.eventEmitersService.emitSetVisualizationAssetViewer.subscribe((data) => {
      this.visualizationLayerData = data;
    });
  }

  get mapLoaded(): boolean {
    return this.loaded;
  }

  @Output() mapLoadedEvent = new EventEmitter<boolean>();
  @Output() basemapChangeEvent = new EventEmitter<boolean>();
  @ViewChild(MapToolsComponent) mapTools: MapToolsComponent;
  @ViewChild(SearchToolComponent)
  searchTools: SearchToolComponent;
  @ViewChild(CrowdSourcedInfoComponent)
  crowdSource: CrowdSourcedInfoComponent;
  @ViewChild(SelectToolComponent)
  selectTools: SelectToolComponent;
  // The <div> where we will place the map
  @ViewChild("mapViewNode", { static: true }) private mapViewEl: ElementRef;

  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faAngleDoubleLeft = faAngleDoubleLeft;

  public chkimage;
  public Primarylangvalid;
  public Secodarylangvalid;
  public showLidar = false;
  public scndlyr = false;
  public langkeyrec;
  public loading = false;
  public zoom = 6;
  public center: number[] = [-118.250437, 34.171234];
  public basemap = "streets";
  public loaded = false;
  public proLicense = true;
  public BaseMapproLicense = true;
  public map;
  region;
  // Injected Service
  public currentUser: IUser;
  public _userService;
  // class variables
  public Draw;
  public Measure;
  public Query;
  public FeatureLayer;
  public FeatureTable;
  public GraphicsLayer;
  public ArcGISDynamicMapServiceLayer;
  public BasemapGallery;
  public BasemapToggle;
  public LocateButton;
  public Extent;
  public OverviewMap;
  public SearchWidget;
  public InfoTemplate;
  public Color;
  public SimpleLineSymbol;
  public SimpleFillSymbol;
  public TextSymbol;
  public Font;
  public SimpleMarkerSymbol;
  public Graphic;
  public Circle;
  public Units;
  public Point;
  public Locator;
  public ColorPicker;
  public Legend;
  public webMercatorUtils;
  public PictureMarkerSymbol;
  public PopupTemplate;
  public Popup;
  public domConstruct;
  public registry;
  public VectorTileLayer;
  public ScreenPoint;
  public PopupExtended;
  public RelationshipQuery;
  public TemplatePicker;
  public Editor;
  public Edit;
  public Geoprocessor;
  public scaleUtils;
  public request;
  public QueryTask;
  public Polyline;
  public Polygon;
  public SimpleRenderer;
  public TimeInfo;
  public TimeSlider;
  public TimeExtent;
  public geometryEngine;
  public mathUtils;
  public geometryJsonUtils;
  // other variables
  public drawTool;
  public measurement;
  public measureGlayer;
  public basemapGallery;
  public basemapToggle;
  public sources = [];
  public searchWidget;
  public MapOnClick;
  public satMapId: string;
  public streetMapId: string;
  public category: any;
  // bool variables
  public showEsriWidgets = false;
  public showMeasure = false;
  public showBaseMapGallery = false;
  public showLayers = false;
  public showFilter = false;
  public streetBasemap = true; // default is street basemap
  public satelliteBasemap = false;
  public showSearch = false;
  public showSelect = false;
  public ProximityAssets = false;
  public showRedlineDraw = false;
  public layerTabActive = true;
  public showLayerList = true;
  public showFilterList = true;
  public showAdd = false;
  public closeQueryBuilderVar = false;
  public closeHelpVar = false;
  public crowdSourceVar = false;
  public closeAdministrator = false;
  public showTask;
  public queryBuilderArray: any[] = [];
  public event: any;
  // harcoded data
  public token = null;
  public layerData: any = {
    userLayers: [] as ILayer[],
    graphicLayers: [],
    dynamicMapLayer: {}
  };
  public regionData: IRegion[] = [] as IRegion[];
  public inventoryLayersData: ILayer[] = [] as ILayer[];
  public contextLayersData: ILayer[] = [] as ILayer[];
  public tracksLayersData: ILayer[] = [] as ILayer[];
  public rowdataobj;
  public showgrid = false;
  public showcard = false;
  public AttributeInspector;
  public all;

  expand = this.translateService.instant("Select.carddisplay.expand");
  minimize = this.translateService.instant("Select.carddisplay.minimize");
  public mapServiceLyr: any;
  showSelectedTasksData;
  public DataEditor = false;
  admin;
  public idletimeout;
  public dataEditorSettings;
  taskType;
  public index: any;
  public layerIndex: any;
  public crewmanagement: any;
  // Added by Madhuri JIRA ID:35
  public graphicsUtils;
  // TSDRSAM-1099 Manikantha V
  public esriBundle;
  //Added for Visualization layers
  //Added by Venkatesh for Jira Id:677
  public visualizationLyrTabActive = false;
  public visualizationLayersData: any[] = [];
  public wmsLayer;
  public showVisualizationDetails;
  public visualizationLayerData;
  public wmsUrlHostNames: any[] = [];
  isQueryBuilderMinimized = false;
  //
  public basemapsForToggle: string[] = ["Streets", "Streets Map"];
  setThis() {
    return this;
  }
  toggleMinimizeQb(minimize: boolean): void {
    this.isQueryBuilderMinimized = minimize;
  }
  ngOnInit() {
    const _this = this.setThis();
    if (this.currentUser.clientId === 1) {
      _this.basemap = "gray";
    }

    this.dataEditorSettings = JSON.parse(localStorage.getItem("dataEditorSettings"));
    forkJoin([
      _this.userService.getUserLayerAccess(this.currentUser.clientId, this.currentUser.userId),
      _this.userService.getUserRegionAccess(this.currentUser.clientId, this.currentUser.userId),
      _this.queryService.getAllLayersInformation(environment.proxy + this.currentUser.webServiceURL)
    ]).subscribe(
      (results) => {
        _this.currentUser.layerLevelInfo = results[0];
        _this.regionData = results[1] as IRegion[];
        _this.processServiceLayerInformation(results[0], results[2]);
        if (_this.regionData.length > 0) {
          this.GenericLayerService.getClientList(this.currentUser.clientId).subscribe((res: any) => {
            this.Primarylangvalid = res.primaryLanguageID;
            this.Secodarylangvalid = res.secondaryLanguageID;
            if (this.Primarylangvalid === this.currentUser.languageID) {
              this.scndlyr = false;
            } else if (this.Secodarylangvalid === this.currentUser.languageID) {
              this.scndlyr = true;
            }
          });
          this.assetAttributesService.getTableAlias().subscribe((tblresults: any) => {
            tblresults.features.forEach((element) => {
              const lyrname = element.attributes.Name;
              const lyrObj = this.layerData.userLayers.filter((lyr) => lyr.layerName === lyrname);
              if (lyrObj.length > 0) {
                lyrObj[0].tableAlias = element.attributes.Alias;
                lyrObj[0].tableName = element.attributes.Name;
                lyrObj[0].assetCode = element.attributes.LayerCode;
                lyrObj[0].tableSL = element.attributes.Alias_SL;
              }
            });
          });
        } else {
          Swal.fire(this.translateService.instant("Esri-map.alert.noregionmsg"));
        }
      },
      (err) => {
        Swal.fire(this.translateService.instant("Esri-map.alert.noregionmsg"));
        this.queryService.getAllLayersInformation(this.currentUser.webServiceURL).subscribe((res) => {
          this.processServiceLayerInformation([], res);
        });
      }
    );
    //Wms layer issue fix data was not displaying on map
    this.GenericLayerService.getVisualizationLayers().subscribe((layerList: any) => {
      var wmsUrlList = layerList.map(function (a) {
        return a.wmsUrl;
      });
      console.log(wmsUrlList);
      wmsUrlList.forEach((wmsUrl) => {
        let domain = new URL(wmsUrl);
        this.wmsUrlHostNames.push(domain.hostname);
      });
    });
    this.GenericLayerService.getuserAPILanguage().subscribe(
      (res: any) => {
        this.langkeyrec = res.languageKey;

        // this.initializeMap().then((mapView) => {});
        this.initializeMap();
      },
      (err) => {
        console.log(err);
      }
    );
    // Initialize MapView and return an instance of MapView
  }

  ngAfterViewInit() {
    // For ADA story - by Subba
    let mainPageTools2 = document.querySelectorAll('[data-BMapTabbing="yes"]');
    if (mainPageTools2.length > 0) {
      mainPageTools2.forEach((element) => {
        element.setAttribute("tabindex", "0");
      });
    }
  }
  //TODO ADD TO A SERVICE
  setEsriHeader(ioArgs) {
    if (ioArgs.url.includes("gisproxy")) {
      // don't forget to return ioArgs.
      ioArgs.headers = { ProxyAuthorization: `Bearer ${this.authenticationService.accessTokenObj.value.accessToken}` };
    }
    return ioArgs;
  }
  async initializeMap() {
    try {
      const _this = this.setThis();
      const options = {
        version: "3.43",
        css: true,
        // insert the stylesheet link above the first <style> tag on the page
        insertCssBefore: "style",
        dojoConfig: {
          locale: this.langkeyrec,
          parseOnLoad: true,
          async: true,
          packages: [
            {
              name: "sms",
              location: location.pathname.replace(/\/[^/]+$/, "") + "assets/esri-widgets/"
            }
          ]
        }
      };
      // loadCss(options.version);
      // Load the modules for the ArcGIS API for JavaScript
      loadModules(
        [
          // esri modules
          "esri/map",
          "esri/graphic",
          "esri/InfoTemplate",
          "esri/units",
          "esri/Color",
          "esri/request",
          "esri/TimeExtent",
          // toolbars
          "esri/toolbars/draw",
          "esri/toolbars/edit",
          // tasks
          "esri/tasks/query",
          "esri/tasks/locator",
          "esri/tasks/RelationshipQuery",
          "esri/tasks/QueryTask",
          "esri/tasks/Geoprocessor",
          // layers
          "esri/layers/FeatureLayer",
          "esri/layers/VectorTileLayer",
          "esri/layers/GraphicsLayer",
          "esri/layers/ArcGISDynamicMapServiceLayer",
          "esri/layers/TimeInfo",
          "esri/layers/WMSLayer", // Added for visualization layers
          // dijit widgets
          "esri/dijit/Measurement",
          "esri/dijit/BasemapGallery",
          "esri/dijit/BasemapToggle",
          "esri/dijit/LocateButton",
          "esri/dijit/OverviewMap",
          "esri/dijit/Search",
          "esri/dijit/ColorPicker",
          "esri/dijit/Legend",
          "esri/dijit/Popup",
          "esri/dijit/PopupTemplate",
          "esri/dijit/Scalebar",
          "esri/dijit/AttributeInspector",
          "esri/dijit/FeatureTable",
          "esri/dijit/TimeSlider",
          // geometry
          "esri/geometry/Extent",
          "esri/geometry/Point",
          "esri/geometry/Circle",
          "esri/geometry/webMercatorUtils",
          "esri/geometry/ScreenPoint",
          "esri/geometry/scaleUtils",
          "esri/geometry/Polyline",
          "esri/geometry/Polygon",
          "esri/geometry/mathUtils",
          "esri/geometry/geometryEngine",
          "esri/geometry/jsonUtils",
          // symbols
          "esri/symbols/SimpleLineSymbol",
          "esri/symbols/SimpleFillSymbol",
          "esri/symbols/SimpleMarkerSymbol",
          "esri/symbols/PictureMarkerSymbol",
          "esri/symbols/TextSymbol",
          "esri/symbols/Font",
          // renderers
          "esri/renderers/SimpleRenderer",
          // external widgets
          "sms/multipopup/PopupExtended",
          // dojo
          "dojo/dom-construct",
          "dojo/promise/all",
          // TSDRSAM-1099 Manikantha V
          "dojo/i18n!esri/nls/jsapi",
          "esri/graphicsUtils",
          "dojo/_base/event",
          // dijit
          "dijit/registry",
          "esri/urlUtils",
          "esri/config"
        ],
        options
      )
        .then(
          ([
            Map,
            Graphic,
            InfoTemplate,
            Units,
            Color,
            request,
            TimeExtent,
            Draw,
            Edit,
            Query,
            Locator,
            RelationshipQuery,
            QueryTask,
            Geoprocessor,
            FeatureLayer,
            VectorTileLayer,
            GraphicsLayer,
            ArcGISDynamicMapServiceLayer,
            TimeInfo,
            wmsLayer, // Added for visualization layers
            Measurement,
            BasemapGallery,
            BasemapToggle,
            LocateButton,
            OverviewMap,
            Search,
            ColorPicker,
            Legend,
            Popup,
            PopupTemplate,
            Scalebar,
            AttributeInspector,
            FeatureTable,
            TimeSlider,
            Extent,
            Point,
            Circle,
            webMercatorUtils,
            ScreenPoint,
            scaleUtils,
            Polyline,
            Polygon,
            mathUtils,
            geometryEngine,
            geometryJsonUtils,
            SimpleLineSymbol,
            SimpleFillSymbol,
            SimpleMarkerSymbol,
            PictureMarkerSymbol,
            TextSymbol,
            Font,
            SimpleRenderer,
            PopupExtended,
            domConstruct,
            all,
            // TSDRSAM-1099 Manikantha V
            esriBundle,
            graphicsUtils,
            event,
            registry,
            urlUtils,
            esriConfig
          ]) => {
            request.setRequestPreCallback((ioArgs) => this.setEsriHeader(ioArgs));
            const urlPrefix = this.getUrlPrefix(environment.proxy) + "/arcgis/rest";
            const proxyUrl = environment.proxy.slice(0, -1); //e.g. "https://ramuatonline.com/proxy/proxy.ashx"
            const ret = urlUtils.addProxyRule({
              urlPrefix: urlPrefix, // specify resource location
              proxyUrl: proxyUrl // specify location of proxy file
            });
            this.wmsUrlHostNames.forEach((hostName) => {
              esriConfig.defaults.io.corsEnabledServers.push(hostName);
            });
            _this.map = new Map(_this.mapViewEl.nativeElement, {
              center: _this.center,
              zoom: _this.zoom,
              basemap: _this.basemap,
              slider: false,
              showAttribution: false,
              logo: false
            });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const scalebar = new Scalebar({
              map: _this.map,
              attachTo: "bottom-right",
              // "dual" displays both miles and kilometers
              // "english" is the default, which displays miles
              // use "metric" for kilometers
              scalebarUnit: "dual"
            });
            _this.map.on("load", () => {
              this.mapLoadedEvent.emit(true);
              // set callbacks to class variables
              _this.Draw = Draw;
              _this.Measure = Measurement;
              _this.Query = Query;
              _this.FeatureLayer = FeatureLayer;
              _this.GraphicsLayer = GraphicsLayer;
              _this.ArcGISDynamicMapServiceLayer = ArcGISDynamicMapServiceLayer;
              _this.BasemapGallery = BasemapGallery;
              _this.BasemapToggle = BasemapToggle;
              _this.LocateButton = LocateButton; // Locate
              _this.Extent = Extent; // setExtent
              _this.OverviewMap = OverviewMap; // InsetMap
              _this.SearchWidget = Search; // Search Widget
              _this.InfoTemplate = InfoTemplate; // Info template
              _this.Color = Color;
              _this.SimpleLineSymbol = SimpleLineSymbol;
              _this.SimpleMarkerSymbol = SimpleMarkerSymbol;
              _this.SimpleFillSymbol = SimpleFillSymbol;
              _this.TextSymbol = TextSymbol;
              _this.Font = Font;
              _this.Legend = Legend;
              _this.Graphic = Graphic;
              _this.Circle = Circle;
              _this.Units = Units;
              _this.Point = Point;
              _this.Locator = Locator;
              _this.ColorPicker = ColorPicker;
              _this.webMercatorUtils = webMercatorUtils;
              _this.PictureMarkerSymbol = PictureMarkerSymbol;
              _this.PopupTemplate = PopupTemplate;
              _this.Popup = Popup;
              _this.domConstruct = domConstruct;
              _this.registry = registry;
              _this.VectorTileLayer = VectorTileLayer;
              _this.ScreenPoint = ScreenPoint;
              _this.PopupExtended = PopupExtended;
              _this.RelationshipQuery = RelationshipQuery;
              _this.AttributeInspector = AttributeInspector;
              _this.FeatureTable = FeatureTable;
              _this.Edit = Edit;
              _this.scaleUtils = scaleUtils;
              _this.request = request;
              _this.QueryTask = QueryTask;
              _this.Polyline = Polyline;
              _this.Polygon = Polygon;
              _this.all = all;
              _this.SimpleRenderer = SimpleRenderer;
              _this.TimeInfo = TimeInfo;
              _this.TimeSlider = TimeSlider;
              _this.TimeExtent = TimeExtent;
              _this.Geoprocessor = Geoprocessor;
              _this.geometryEngine = geometryEngine;
              _this.mathUtils = mathUtils;
              _this.graphicsUtils = graphicsUtils;
              _this.event = event;
              _this.geometryJsonUtils = geometryJsonUtils;
              // TSDRSAM-1099 Manikantha V
              _this.esriBundle = esriBundle;
              _this.wmsLayer = wmsLayer; // Added for visualization layers
              var isDragging = false;
              var dragExtent = null;

              _this.map.on("pointer-down", function (event) {
                isDragging = true;
                dragExtent = event.mapPoint.clone();
              });

              _this.map.on("pointer-up", function (event) {
                if (isDragging) {
                  var startPoint = dragExtent;
                  var endPoint = event.mapPoint.clone();
                  var extent = {
                    xmin: Math.min(startPoint.x, endPoint.x),
                    ymin: Math.min(startPoint.y, endPoint.y),
                    xmax: Math.max(startPoint.x, endPoint.x),
                    ymax: Math.max(startPoint.y, endPoint.y),
                    spatialReference: _this.map.spatialReference
                  };
                  console.log(extent);
                  isDragging = false;
                  dragExtent = null;
                }
              });
              _this.initApp();
            });
          }
        )
        .catch((err) => {
          // handle any script or module loading errors
          console.error(err);
        });
    } catch (error) {
      console.log("EsriLoader: ", error);
    }
  }

  initApp() {
    try {
      // Added by Manikantha for JIRA ID:-34
      this.genericMapMethodsService.currentUser = this.currentUser;
      this.genericMapMethodsService.setEsriModules(
        this.map,
        this.Draw,
        this.Measure,
        this.Query,
        this.FeatureLayer,
        this.GraphicsLayer,
        this.ArcGISDynamicMapServiceLayer,
        this.BasemapGallery,
        this.LocateButton,
        this.Extent,
        this.OverviewMap,
        this.SearchWidget,
        this.InfoTemplate,
        this.Color,
        this.SimpleLineSymbol,
        this.SimpleMarkerSymbol,
        this.SimpleFillSymbol,
        this.TextSymbol,
        this.Font,
        this.Legend,
        this.Graphic,
        this.Circle,
        this.Units,
        this.Point,
        this.Locator,
        this.ColorPicker,
        this.webMercatorUtils,
        this.PictureMarkerSymbol,
        this.PopupTemplate,
        this.Popup,
        this.domConstruct,
        this.registry,
        this.VectorTileLayer,
        this.ScreenPoint,
        this.PopupExtended,
        this.RelationshipQuery,
        this.AttributeInspector,
        this.FeatureTable,
        this.Edit,
        this.scaleUtils,
        this.request,
        this.QueryTask,
        this.Polyline,
        this.Polygon,
        this.all,
        this.SimpleRenderer,
        this.TimeInfo,
        this.TimeSlider,
        this.TimeExtent,
        this.Geoprocessor,
        this.geometryEngine,
        this.mathUtils,
        this.event,
        this.geometryJsonUtils,
        this.graphicsUtils,
        // TSDRSAM-1099 Manikantha V
        this.esriBundle
      );
      this.initTimeOut();
      this.loadLayers();
      this.initBasemap();
      this.initToolbars();
      this.initMeasureTool();
      this.initSearchToolbar();
      var isDragging = false;
      var dragExtent = null;

      this.map.on("pointer-down", function (event) {
        isDragging = true;
        dragExtent = event.mapPoint.clone();
      });

      this.map.on("pointer-up", function (event) {
        if (isDragging) {
          var startPoint = dragExtent;
          var endPoint = event.mapPoint.clone();
          var extent = {
            xmin: Math.min(startPoint.x, endPoint.x),
            ymin: Math.min(startPoint.y, endPoint.y),
            xmax: Math.max(startPoint.x, endPoint.x),
            ymax: Math.max(startPoint.y, endPoint.y),
            spatialReference: this.map.spatialReference
          };
          console.log(extent);
          isDragging = false;
          dragExtent = null;
        }
      });
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }

  initTimeOut() {
    let idleModelRef: NgbModalRef;
    this.userService.getClientListUser(this.authenticationService.currentUserValue.clientId)
      .pipe(
        map((client: Client) => client.setTimeOut ?? environment.SetTimeOut))
      .subscribe((setTimeOut: number) => {
        const secondsBeforelogout = 60; 
        const secondsBeforeIdle = setTimeOut - secondsBeforelogout;

        this.idle.setIdle(secondsBeforeIdle); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(secondsBeforelogout); // how long can they be idle before considered timed out, in seconds
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

        // pop up a warning modal when the user becomes idle
        this.idle.onIdleStart.subscribe(() => {
          if (!this.modalService.hasOpenModals()) {
            idleModelRef = this.modalService.open(IdleModalComponent, {
              centered: true,
              size: "sm",
              backdrop: "static",
              keyboard: false,
              windowClass: "idle-modal"
            });
            // pass in timout to the modal component for countdown
            idleModelRef.componentInstance.secondsBeforelogout = secondsBeforelogout;
          }
        });

        // log out the user when they've been idle too long 
        this.idle.onTimeout.subscribe(() => {
          this.authenticationService.logout(this.currentUser.clientId, this.currentUser.userId)
        });

        this.idle.watch();
      });
  }

  initToolbars() {
    try {
      this.showEsriWidgets = true;
      this.drawTool = new this.Draw(this.map);
    } catch (error) {
      console.error(error);
    }
  }
  initBasemap() {
    try {
      const _this = this.setThis();
      if (!this.basemapGallery) {
        this.basemapGallery = new this.BasemapGallery(
          {
            showArcGISBasemaps: true,
            map: _this.map
          },
          "basemapGalleryDiv"
        );
        this.basemapGallery.startup();
        this.basemapGallery.on("error", function (msg) {
          console.log("basemap gallery error:  ", msg);
        });
        this.basemapToggle = new this.BasemapToggle({ showArcGISBasemaps: true, map: _this.map, basemap: "satellite" }, "BasemapToggleDiv");
        this.basemapToggle.startup();
      }
    } catch (error) {
      console.error(error);
    }
  }

  toggleBasemapGallery() {
    try {
      this.showBaseMapGallery = !this.showBaseMapGallery;
      const slider = document.getElementById("basemaps-more");
      let mainPageTools = document.querySelectorAll('[data-mainPageTabbing="yes"]');
      if (this.showBaseMapGallery) {
        slider.classList.remove("fa-angle-double-left");
        slider.classList.add("fa-angle-double-right");
        //Added for ADA
        mainPageTools.forEach((element) => {
          element.setAttribute("tabindex", "-1");
        });
      } else {
        slider.classList.add("fa-angle-double-left");
        slider.classList.remove("fa-angle-double-right");
        //Added for ADA
        mainPageTools.forEach((element) => {
          element.setAttribute("tabindex", "0");
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  initMeasureTool() {
    try {
      if (!this.measurement) {
        const _this = this.setThis();
        // symbols for measure tool -------------------------------------
        const selectedColor = new this.Color([255, 0, 0, 1]);
        const lineSymbol = new this.SimpleLineSymbol(this.SimpleLineSymbol.STYLE_SOLID, selectedColor, 4);
        const fillSymbol = new this.SimpleFillSymbol(this.SimpleFillSymbol.STYLE_SOLID, lineSymbol, new this.Color([255, 255, 0, 0]));
        this.measurement = new this.Measure(
          {
            map: _this.map,
            fillSymbol,
            lineSymbol
          },
          "measureWidgetDiv"
        );
        this.measurement.startup();
        // creating graphics layer for measure
        _this.measureGlayer = new _this.GraphicsLayer({
          id: "measure-result-glayer"
        });
        _this.map.addLayer(_this.measureGlayer, 100);
        const font = new _this.Font("20pt", _this.Font.STYLE_NORMAL, _this.Font.WEIGHT_BOLD, "Courier");
        this.measurement.on("measure-start", (evt) => {
          const tool = this.measurement.getTool();
          if (tool.toolName === "area") {
            _this.measureGlayer.clear();
          }
        });
        this.measurement.on("measure", (evt) => {
          _this.measureGlayer.clear();
          const textSym = new _this.TextSymbol(evt.values.toFixed(2) + " " + evt.unitName, font);
          _this.measureGlayer.add(new _this.Graphic(evt.geometry, textSym));
          this.measurement.resultValue.srcNodeRef.innerHTML = evt.values.toFixed(2) + " " + evt.unitName;
        });
        this.measurement.on("measure-end", function (evt) {
          // added whole function
          this.measurement.resultValue.srcNodeRef.innerHTML = evt.values.toFixed(2) + " " + evt.unitName;
        });
        this.measurement.on("tool-change", (evt) => {
          _this.measureGlayer.clear();
        });
        //Added for ADA
        let measureTools = document.querySelectorAll("span.dijit.dijitReset.dijitInline.esriButton");
        measureTools.forEach((element) => {
          element.setAttribute("tabindex", "0");
        });
        let measureTools2 = document.querySelectorAll("span.dijit.dijitReset.dijitInline.esriToggleButton");
        measureTools2.forEach((element) => {
          element.setAttribute("tabindex", "0");
        });
      } else {
        this.measurement.clearResult();
        this.measureGlayer.clear();
      }
    } catch (error) {
      console.error(error);
    }
  }
  initSearchToolbar() {
    try {
      const _this = this.setThis();
      if (!this.searchWidget) {
        this.searchWidget = new this.SearchWidget(
          {
            map: _this.map,
            allPlaceholder: "Search",
            sources: []
          },
          "searchWidgetDiv"
        );
        this.searchWidget.startup();
      } else {
        this.searchWidget.clear();
      }
    } catch (error) {
      console.error(error);
    }
  }

  initLegend(layersInfo) {
    try {
      const legendDijit = new this.Legend(
        {
          map: this.map,
          layerInfos: layersInfo
        },
        "divLegendWidget"
      );
      legendDijit.startup();
    } catch (error) {
      console.error(error);
    }
  }

  deactivateMeasure() {
    try {
      if (this.measurement) {
        this.measurement.clearResult();
      }
      if (this.measureGlayer) {
        this.measureGlayer.clear();
      }
      const tool = this.measurement.getTool();
      if (tool) {
        this.measurement.setTool(tool.toolName, false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleMeasure() {
    try {
      this.measurement.clearResult();
      this.measureGlayer.clear();
      this.showMeasure = !this.showMeasure;
      if (this.showMeasure === true) {
        // when measure is visible
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showRedlineDraw =
          this.showAdd =
          this.closeQueryBuilderVar =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.measurement.setTool("distance", true);
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(false);
      } else {
        // when measure is not visible
        const tool = this.measurement.getTool();
        if (tool) {
          this.measurement.setTool(tool.toolName, false);
        }
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
      }
      this.showCardView(false);
    } catch (error) {
      console.error(error);
    }
  }

  toggleLayers() {
    try {
      this.showLayers = !this.showLayers;
      this.showLayerList = !this.showLayers ? this.showLayers : this.showLayerList;
      if (this.showLayers === true) {
        this.showMeasure =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showRedlineDraw =
          this.showAdd =
          this.closeQueryBuilderVar =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleAdministrator() {
    try {
      this.closeAdministrator = !this.closeAdministrator;
      if (this.closeAdministrator === true) {
        this.showLayers = this.showFilter = this.showSearch = this.showSelect = this.showMeasure = this.showAdd = this.showRedlineDraw = this.isOpenCard = this.DataEditor = false;
        this.deactivateMeasure();
        if (this.showSelect) {
          this.selectTools.fnClearSelection();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleQueryBuilder() {
    try {
      this.closeQueryBuilderVar = !this.closeQueryBuilderVar;
      if (this.closeQueryBuilderVar === true) {
        if (this.showSelect) {
          this.selectTools.fnClearSelection();
        }
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showMeasure =
          this.showAdd =
          this.showcard =
          this.showRedlineDraw =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.isFromQB = true;
        this.eventEmitersService.setShowCard(false);
        this.deactivateMeasure();
      } else {
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
        // Added by Madhuri Gupta for JIRA ID:-62 on 08/11/2021
        this.genericMapMethodsService.queryBuilderZoomExtent = false;
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleHelp() {
    try {
      this.closeHelpVar = !this.closeHelpVar;
      if (this.closeHelpVar === true) {
        if (this.showSelect) {
          this.selectTools.fnClearSelection();
        }
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.crowdSourceVar =
          this.closeQueryBuilderVar =
          this.showMeasure =
          this.showAdd =
          this.showcard =
          this.isOpenCard =
          this.showRedlineDraw =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.eventEmitersService.setShowCard(false);
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleCrowdSourced() {
    try {
      this.crowdSourceVar = !this.crowdSourceVar;
      if (this.crowdSourceVar === true) {
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.closeQueryBuilderVar =
          this.showMeasure =
          this.showAdd =
          this.showRedlineDraw =
          this.isOpenCard =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleCrewManagement() {
    try {
      this.crewmanagement = !this.crewmanagement;
      if (this.crewmanagement === true) {
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.closeQueryBuilderVar =
          this.showMeasure =
          this.showAdd =
          this.showRedlineDraw =
          this.isOpenCard =
          this.DataEditor =
          this.crowdSourceVar =
          this.showTask =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  crowdsource() {
    try {
      this.showSelect = false;
      this.eventEmitersService.setShowCard(false);
      this.selectTools.fnClearSelection();
      if (this.crowdSource) {
        this.crowdSource.getAllComplaint();
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleRedlineDraw() {
    try {
      this.showSelect = !this.showSelect;
      this.showRedlineDraw = !this.showRedlineDraw;
      if (this.showRedlineDraw === true) {
        this.showLayers =
          this.showFilter =
          this.showSearch =
          this.showSelect =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showMeasure =
          this.showAdd =
          this.closeQueryBuilderVar =
          this.isOpenCard =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
      } else {
        this.eventEmitersService.setEnableSingleClick(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  saveQBTableData(event) {
    this.queryBuilderArray = event;
  }
  closeQueryBuilder(event) {
    try {
      const componentType = document.getElementById("querybuilder");
      const hasClass = componentType.classList.contains("i-active");
      if (hasClass) {
        componentType.classList.remove("i-active");
      }
      this.closeQueryBuilderVar = event;
    } catch (error) {
      console.error(error);
    }
  }
  closeHelpBuilder(event) {
    try {
      const componentType = document.getElementById("help");
      const hasClass = componentType.classList.contains("i-active");
      if (hasClass) {
        componentType.classList.remove("i-active");
      }
      this.closeHelpVar = event;
    } catch (error) {
      console.error(error);
    }
  }
  closeCrowdSourceBuilder(event) {
    this.crowdSourceVar = event;
  }
  closeCrewManagement(event) {
    this.crewmanagement = event;
  }
  toggleSearch() {
    try {
      this.searchWidget.clear();
      this.showSearch = !this.showSearch;
      if (this.showSearch === true) {
        this.searchTools.swithSearchWidget("Open");
        this.showLayers = this.showFilter = this.showMeasure = this.showSelect = this.closeHelpVar = this.showRedlineDraw = this.showAdd = this.closeQueryBuilderVar = this.isOpenCard = this.DataEditor = false;
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleFilter() {
    try {
      this.showFilter = !this.showFilter;
      if (this.showFilter === true) {
        this.showLayers =
          this.showSearch =
          this.showMeasure =
          this.showSelect =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showRedlineDraw =
          this.showAdd =
          this.closeQueryBuilderVar =
          this.isOpenCard =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleSelect() {
    try {
      if (!this.showSelect) {
        if (this.closeQueryBuilderVar === true && this.rowdataobj && this.rowdataobj.length > 0) {
          this.isOpenCard = true;
          this.isFromQB = false;
        }
        this.showLayers = this.showSearch = this.showMeasure = this.showFilter = this.closeHelpVar = this.showRedlineDraw = this.showAdd = this.closeQueryBuilderVar = this.DataEditor = this.crewmanagement = false;
        this.deactivateMeasure();
      } else {
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
      }
      this.showSelect = !this.showSelect;
    } catch (error) {
      console.error(error);
    }
  }
  toggleMaintenanceViewer() {
    try {
      if (!this.showTask) {
        if (this.showSelect) {
          this.selectTools.fnClearSelection();
        }
        this.showLayers =
          this.showSearch =
          this.showMeasure =
          this.showFilter =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showRedlineDraw =
          this.showAdd =
          this.closeQueryBuilderVar =
          this.isOpenCard =
          this.showSelect =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.deactivateMeasure();
        this.showCardView(false);
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(false);
      } else {
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
      }
      this.showTask = !this.showTask;
      if (this.showTask) {
        this.eventEmitersService.setMaintenanceView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleAdd() {
    try {
      this.showAdd = !this.showAdd;
      if (this.showAdd === true) {
        if (this.showSelect) {
          this.selectTools.fnClearSelection();
        }
        this.showLayers =
          this.showSearch =
          this.showMeasure =
          this.showFilter =
          this.closeHelpVar =
          this.crowdSourceVar =
          this.showRedlineDraw =
          this.showSelect =
          this.closeQueryBuilderVar =
          this.isOpenCard =
          this.DataEditor =
          this.crewmanagement =
          false;
        this.isFromQB = false;
        this.deactivateMeasure();
      }
    } catch (error) {
      console.error(error);
    }
  }
  createTaskforAsset(event) {
    try {
      this.objGlobal.complaintID = event.ComplaintId;
      this.objGlobal.latitude = event.latitude;
      this.objGlobal.longitude = event.longitude;
      this.toggleSelect();
    } catch (error) {
      console.error(error);
    }
  }
  initLocationButton() {
    try {
      const _this = this.setThis();
      const geoLocate = new this.LocateButton(
        {
          map: _this.map,
          highlightLocation: true
        },
        "locateBtn"
      );
      geoLocate.startup();
    } catch (error) {
      console.error(error);
    }
  }

  loadLayers() {
    try {
      const _this = this.setThis();
      const layersInfo: any = [];
      const layerDefinitions = [];
      const visibleLyrs = [];
      if (this.layerData.userLayers.length > 0) {
        // Added By Sathish to get all the attribute fields
        const featureLayerOptions: any = {
          mode: _this.FeatureLayer.MODE_ONDEMAND,
          outFields: ["*"]
        };
        // creating ArcGISDynamicMapServiceLayer layer
        this.mapServiceLyr = new this.ArcGISDynamicMapServiceLayer(this.currentUser.webServiceURL.trim() + environment.mapServer, {
          id: "sms-layers"
        });
        // selection layer to show graphics
        const selectionGraphicsLyr = new this.GraphicsLayer({
          id: "sms-selection"
        });
        // get region definition exp
        const defExp = this.getRegionFilterExp(this.regionData[0]);
        // create feature layer object
        this.layerData.userLayers.forEach((lyr: ILayer) => {
          const url = this.currentUser.webServiceURL.trim() + "FeatureServer/" + lyr.layerId;
          if (lyr.layerName === "GPSTrackLog") {
            featureLayerOptions.infoTemplate = this.getInfoTemplateForTrackLogLayer();
          }
          // if (_this.token) url = url + "?token=" + _this.token;
          featureLayerOptions.id = lyr.layerName + lyr.layerId;
          const fl = new _this.FeatureLayer(url, featureLayerOptions); // Layer Options Added By Sathish
          if (lyr.layerName.includes("Region")) {
            this.getExtentFromRegion(fl);
          }
          lyr.lyrObj = fl;
          if (lyr.layerType === "inventory") {
            layerDefinitions[lyr.layerId] = defExp;
            fl.setDefinitionExpression(defExp);
          }
          if (lyr.visible) {
            visibleLyrs.push(lyr.layerId);
          }
          if (lyr.layerName === "GPSTrackLog") {
            layerDefinitions[lyr.layerId] = "Inspector='" + this.currentUser.username + "'";
          }
          if (!this.scndlyr) {
            if (lyr.tableAlias == null || lyr.tableAlias === undefined) {
              layersInfo.push({ layer: fl, title: lyr.layerName });
            } else if (lyr.tableAlias.length > 0) {
              layersInfo.push({ layer: fl, title: lyr.tableAlias });
            }
          } else if (this.scndlyr) {
            if (lyr.tableAlias == null || lyr.tableAlias === undefined) {
              layersInfo.push({ layer: fl, title: lyr.layerName });
            } else if (lyr.tableAlias.length > 0) {
              layersInfo.push({ layer: fl, title: lyr.tableSL });
            }
          }
        });
        this.mapServiceLyr.setLayerDefinitions(layerDefinitions);
        this.mapServiceLyr.setVisibleLayers(visibleLyrs);
        this.map.addLayer(this.mapServiceLyr);
        this.map.addLayer(selectionGraphicsLyr);
        if (layersInfo.length > 0) {
          this.initLegend(layersInfo);
        }
        this.layerData.graphicLayers.push(selectionGraphicsLyr);
        this.layerData.dynamicMapLayer = this.mapServiceLyr;
        this.map.smsLayerdata = this.layerData;
        this.map.smsRegiondata = this.regionData;
        this.eventEmitersService.inventoryLayersData = this.assetService.layerData = this.inventoryLayersData;
        //Added for visualization layers
        //Load Visualization Layers
        this.getVisualizationLayers();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getRegionFilterExp(regionObj) {
    try {
      return "Region" + regionObj.regionLevel + "='" + regionObj.regionName + "' AND Status <> 'Inactive'";
    } catch (error) {
      console.error(error);
    }
  }

  toggleMapLayers(event, lyr: ILayer) {
    try {
      lyr.visible = !lyr.visible;
      let visibleLyrs = this.mapServiceLyr.visibleLayers;
      if (visibleLyrs.includes(lyr.layerId)) {
        visibleLyrs = visibleLyrs.filter((id) => id !== lyr.layerId);
      } else {
        visibleLyrs.push(lyr.layerId);
      }
      this.mapServiceLyr.setVisibleLayers(visibleLyrs);
    } catch (error) {
      console.error(error);
    }
  }

  switchBasemap(type: string) {
    try {
      if (type === "satellite") {
        this.basemapGallery.select(this.satMapId);
      } else if (type === "streets") {
        this.basemapGallery.select(this.streetMapId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  switchTabs(tab) {
    this.layerTabActive = tab === "layers" ? true : false;
    this.visualizationLyrTabActive = tab === "visualizationlayers" ? true : false; // Added for Visualization layers
  }

  invokePopup(type) {
    try {
      if (type === "querybuilder") {
        this.isFromQB = true;
      } else {
        this.isFromQB = false;
      }
      this.map.graphicsLayerIds.forEach(
        function (id) {
          this.map.getLayer(id).clear();
        }.bind(this)
      );
      if (type !== "maintenanceViewer") {
        this.showTask = false;
        this.eventEmitersService.setMaintenanceView(false);
      }
      this.map.graphics.clear();
      this.rowdataobj = null;
      this.closeCardView(true);

      const allHeaders = [
        "measure",
        "layers",
        "search",
        "redline",
        "filter",
        "select",
        "user-profile",
        "add",
        "querybuilder",
        "administrator",
        "help",
        "crowdSource",
        "maintenanceViewer",
        "datacollectorSettings",
        "crewmanagement"
      ];
      allHeaders.forEach((val) => {
        if (val !== type) {
          this.close(null, val);
        }
      });
      if (type === "measure") {
        this.toggleMeasure();
      } else if (type === "basemap") {
        this.toggleBasemapGallery();
      } else if (type === "layers") {
        this.toggleLayers();
      } else if (type === "search") {
        this.toggleSearch();
      } else if (type === "redline") {
        this.toggleRedlineDraw();
      } else if (type === "filter") {
        this.toggleFilter();
      } else if (type === "select") {
        this.closeMoreToggle();
        this.toggleSelect();
      } else if (type === "add") {
        this.closeMoreToggle();
        this.toggleAdd();
      } else if (type === "querybuilder") {
        this.closeMoreToggle();
        this.toggleQueryBuilder();
      } else if (type === "maintenanceViewer") {
        this.closeMoreToggle();
        this.toggleMaintenanceViewer();
      } else if (type === "administrator") {
        this.toggleAdministrator();
      } else if (type === "help") {
        this.closeMoreToggle();
        this.toggleHelp();
      } else if (type === "crowdSource") {
        this.toggleCrowdSourced();
      } else if (type === "datacollectorSettings") {
        this.toggleDataEditor();
      } else if (type === "crewmanagement") {
        this.toggleCrewManagement();
      }
    } catch (error) {
      console.error(error);
    }
  }

  errback(err) {
    console.log("Error :", err);
  }
  displayTaskinViewer(rowObj) {
    this.rowdataobj = rowObj;
  }
  ngOnDestroy() {
    // console
  }
  /**
   * Closes the header popup
   * Makes the respective header icon Inactive
   *
   */
  close(event, type) {
    try {
      const componentType = document.getElementById(type);
      if (!componentType) {
        return;
      }
      const hasClass = componentType.classList.contains("i-active");
      if (hasClass) {
        componentType.classList.remove("i-active");
      }
      if (this.showRedlineDraw && type === "redline") {
        if (document.getElementById("more").classList.contains("i-active")) {
          document.getElementById("more").classList.remove("i-active");
        }
        this.showRedlineDraw = false;
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
      }
      this.deactivateTools();
      if (this.showMeasure && type === "measure") {
        if (document.getElementById("more").classList.contains("i-active")) {
          document.getElementById("more").classList.remove("i-active");
        }
        this.showMeasure = false;
        // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
        this.eventEmitersService.setEnableSingleClick(true);
      }
      this.deactivateTools();
    } catch (error) {
      console.error(error);
    }
  }
  deactivateTools() {
    try {
      if (this.drawTool) {
        this.drawTool.deactivate();
        this.map.enableMapNavigation();
      }
      if (this.measurement && this.measurement.getTool()) {
        this.measurement.setTool(this.measurement.getTool().toolName, false);
        this.measurement.clearResult();
        this.measureGlayer.clear();
      }
      if (this.showSearch) {
        this.searchTools.swithSearchWidget("Close");
      }
    } catch (error) {
      console.error(error);
    }
  }

  emittedData(event) {
    if (event.length === 0) {
      this.rowdataobj = undefined;
    } else {
      this.rowdataobj = event;
    }
  }
  setRowIndex(event) {
    this.index = event;
  }
  setSelLayerIndex(event) {
    this.layerIndex = event;
  }
  showQueryData(event) {
    try {
      if (event.length === 0) {
        this.eventEmitersService.setShowCard(false);
      } else {
        this.eventEmitersService.setShowCard(false);
      }
      this.rowdataobj = event;
    } catch (error) {
      console.error(error);
    }
  }

  showGridData(event) {
    try {
      this.showgrid = event;
      if (event === false) {
        const resultGrid = document.getElementById("resultGrid");
        if (resultGrid != null && resultGrid !== undefined) {
          resultGrid.classList.remove("i-active");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  showTaskData(event) {
    try {
      this.showTask = event;
      if (this.showTask) {
        this.eventEmitersService.setMaintenanceView(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  showTaskViewTable(evt) {
    try {
      this.eventEmitersService.setMaintenanceView(evt);
    } catch (error) {
      console.error(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showCardView(event) {
    try {
      this.eventEmitersService.setShowCard(true);
    } catch (error) {
      console.error(error);
    }
  }
  OpenCardView(event) {
    this.isOpenCard = event;
  }
  closeCardView(event) {
    try {
      this.eventEmitersService.setShowCard(false);
      this.isOpenCard = false;
      this.close(event, "add");
    } catch (error) {
      console.error(error);
    }
  }
  closeMoreToggle() {
    try {
      this.showTask = false;
      const obj = document.getElementById("demo");
      if (document.getElementById("more").classList.contains("i-active")) {
        document.getElementById("more").classList.remove("i-active");
      }
      if (obj.classList.contains("show")) {
        obj.classList.remove("show");
      }
    } catch (error) {
      console.error(error);
    }
  }
  closeGrid(event) {
    try {
      this.showgrid = !event;
      const resultGrid = document.getElementById("resultGrid");
      resultGrid.classList.remove("i-active");
    } catch (error) {
      console.error(error);
    }
  }

  closeTable(event) {
    try {
      this.showTask = !event;
      this.close(event, "maintenanceViewer");
      this.eventEmitersService.setMaintenanceView(false);
      // Added by Madhuri Gupta for JIRA ID:-35 on 07/29/2021
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }
  tasksSelected(event) {
    this.category = event;
  }

  changeRegion(event, reg) {
    try {
      if (reg.regionName) {
        const regionLayer = this.contextLayersData.filter((obj) => obj.layerName.includes("Region"));
        this.getExtentFromRegion(regionLayer[0].lyrObj, reg);
        const defExp = this.getRegionFilterExp(reg);
        this.layerData.userLayers.forEach((lyr: ILayer) => {
          if (lyr.layerType === "inventory") {
            this.mapServiceLyr.layerDefinitions[lyr.layerId] = defExp;
            lyr.lyrObj.setDefinitionExpression(defExp);
          }
        });
      } else {
        Swal.fire(this.translateService.instant("Esri-map.alert.noregiondatamsg"));
      }
    } catch (error) {
      console.error(error);
    }
  }

  getUrlPrefix(inputUrl: string) {
    return inputUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]; //e.g. "ramuatonline.com" from "https://ramuatonline.com/proxy/proxy.ashx?")
  }

  getExtentFromRegion(fl, region?) {
    const regionObj = region ? region : this.regionData[0];
    const query = new this.Query();
    query.where = "RegionName" + regionObj.regionLevel + "='" + regionObj.regionName + "'";
    fl.selectFeatures(
      query,
      null,
      (evt) => {
        if (evt.length > 0) {
          let poly;
          if (evt.length === 1) {
            poly = new this.Polygon(evt[0].geometry);
          } else {
            const geometries = [];
            evt.forEach((geom) => {
              geometries.push(geom.geometry);
            });
            poly = this.geometryEngine.union(geometries);
          }
          this.map.setExtent(poly.getExtent(), true);
          regionObj.Extent = poly.getExtent();
          regionObj.geom = poly;
          this.currentUser.selectedRegion = regionObj;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  processServiceLayerInformation(layerAccessData, gisServiceData) {
    try {
      if (gisServiceData && gisServiceData.layers) {
        const serviceLyrInfo: any = {};
        let inventoryGroupId = 0;
        let trackGroupId = 0;
        let contextGroupId = 0;
        const groupLayers = gisServiceData.layers.filter((lyr) => lyr.type === "Group Layer");
        groupLayers.forEach((lyr) => {
          switch (lyr.name.toLowerCase()) {
            case "inventory":
              inventoryGroupId = lyr.id;
              break;
            case "tracks":
              trackGroupId = lyr.id;
              break;
            case "context":
              contextGroupId = lyr.id;
              break;
          }
        });
        let accessPrivilege;
        gisServiceData.layers.forEach((lyr) => {
          const lyrAccess = layerAccessData.filter((ad) => ad.gisLayerId === lyr.id.toString());
          if (lyr.type === "Group Layer") {
            if (lyr.parentLayerId !== inventoryGroupId) {
              accessPrivilege = "Read Only";
            }
            return;
          } else if (lyr.parentLayerId === inventoryGroupId && lyrAccess.length === 0) {
            return;
          }
          if (lyrAccess[0]) {
            accessPrivilege = lyrAccess[0].accessPrivilege;
          }

          const layer: ILayer = this.getLayerObject(lyr, inventoryGroupId, trackGroupId, contextGroupId, accessPrivilege);

          switch (layer.layerName) {
            case "Regions":
              serviceLyrInfo.regionId = lyr.id;
              break;
            case "GPSTrackLog":
              serviceLyrInfo.gpstracklogId = lyr.id;
              break;
          }
        });
        gisServiceData.tables.forEach((lyr) => {
          switch (lyr.name) {
            case "TableAliases":
              serviceLyrInfo.tableAliasId = lyr.id;
              break;
            case "FieldAliases":
              serviceLyrInfo.fieldAliasId = lyr.id;
              break;
            case "InventoryDeletes":
              serviceLyrInfo.inventoryDeleteId = lyr.id;
              break;
            case "Maintenance":
              serviceLyrInfo.maintenanceId = lyr.id;
              break;
            case "Assessment":
              serviceLyrInfo.assessmentId = lyr.id;
              break;
            case "Activity":
              serviceLyrInfo.activityId = lyr.id;
              break;
            case "Photo":
              serviceLyrInfo.photoId = lyr.id;
              break;
            case "Light":
              serviceLyrInfo.lightId = lyr.id;
              break;
            case "AssetCodeDefaults":
              serviceLyrInfo.assetCodeDefaultsId = lyr.id;
              break;
            case "Sign":
              serviceLyrInfo.signId = lyr.id;
              break;
            case "Documents":
              serviceLyrInfo.documentId = lyr.id;
              break;
            case "UserQueryActions":
              serviceLyrInfo.userQueryActionsId = lyr.id;
              break;
            case "ELife_BaseLife_Config":
              serviceLyrInfo.baseLifeConfigId = lyr.id;
              break;
            case "ELife_BaseLife_Values":
              serviceLyrInfo.baseLifeValuesId = lyr.id;
              break;
            case "ELife_Modifier_Config":
              serviceLyrInfo.modifierConfigId = lyr.id;
              break;
            case "ELife_Modifier_Values":
              serviceLyrInfo.modifierValueId = lyr.id;
              break;
            case "ActivityDuration":
              serviceLyrInfo.activityDurationId = lyr.id;
              break;
            case "ActivityToolkit":
              serviceLyrInfo.activityToolkitId = lyr.id;
              break;
            case "Fields_NonEditable_ByUser":
              serviceLyrInfo.fieldsNonEditableByUserId = lyr.id;
              break;
            // Added by Sowjanya for JIRA ID:-27  JIRA ID:-29 on 08/17/2021
            case "MaintenanceConfiguration":
              serviceLyrInfo.maintenanceConfigId = lyr.id;
              break;
          }
        });
        this.currentUser.serviceLyrInfo = serviceLyrInfo;
        if (layerAccessData.length !== 0) {
          this.layerData.userLayers = [...this.contextLayersData, ...this.tracksLayersData, ...this.inventoryLayersData];
        }
        this.authenticationService.currentUserSubject.next(this.currentUser);
      }
    } catch (error) {
      console.error(error);
    }
  }

  getLayerObject(lyr, inventoryGroupId, trackGroupId, contextGroupId, layerAccess) {
    try {
      const layer: ILayer = {} as ILayer;
      layer.layerId = lyr.id;
      layer.layerName = lyr.name;
      layer.layerType = this.getLayerType(lyr, layer, inventoryGroupId, trackGroupId, contextGroupId);
      if (layer.layerType === "tracks" || layer.layerName === "Roads" || layer.layerName === "ProjectRoads") {
        layer.visible = false;
      } else {
        layer.visible = true;
      }
      layer.accessPrivilege = layerAccess;
      return layer;
    } catch (error) {
      console.error(error);
    }
  }

  getLayerType(servivelyr, layer, inventoryGroupId, trackGroupId, contextGroupId) {
    try {
      switch (servivelyr.parentLayerId) {
        case inventoryGroupId:
          this.inventoryLayersData.push(layer);
          return "inventory";
          break;
        case trackGroupId:
          this.tracksLayersData.push(layer);
          return "tracks";
          break;
        case contextGroupId:
          this.contextLayersData.push(layer);
          return "context";
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getInfoTemplateForTrackLogLayer() {
    try {
      const infoTemplate = new this.InfoTemplate();
      infoTemplate.setTitle("Track Log Info");
      // JIRA ID:-54
      infoTemplate.setContent(
        "<b>Weather Conditions : </b>${WeatherCondition}<br/>" +
        "<b>Start Time: </b>${StartTime}<br/><br/>" +
        "<b>Comments: </b>${Comment}<br/>" +
        "<b>Vehicle Model: </b>${VehicleModel}<br/>" +
        "<b>End Time: </b>${EndTime}<br/>" +
        "<b>Vehicle Year: </b>${VehicleYear}<br/>" +
        "<b>Method: </b>${Method}<br/>" +
        "<b>Inspector: </b>${Inspector}</br>" +
        "<b>Total reported points:</b>${TotalPointsCollected}</br>" +
        "<b>Total discarded points:</b>${TotalPointsDiscarded}</br>" +
        "<b>System crashes:</b>${TotalSystemCrashes}"
      );
      return infoTemplate;
    } catch (error) {
      console.error(error);
    }
  }
  closeTimeSlider() {
    try {
      const component = this.registry.byId("timeSliderWidget");
      if (component) {
        component.destroy();
      }
      document.getElementById("timeSliderHeaderLabels").innerHTML = "";
      document.getElementById("timeSliderDiv").style.display = "none";
      const featureLayerGPS = this.map.smsLayerdata.userLayers.filter((obj) => obj.layerName === "GPSTrackLog")[0].lyrObj;
      this.map.removeLayer(featureLayerGPS);
      this.map.infoWindow.hide();
      //Enable single click for asset selection
      this.eventEmitersService.setEnableSingleClick(true);
    } catch (error) {
      console.error(error);
    }
  }

  capturescreen() {
    try {
      this.loading = true;
      const config = {
        useCORS: true,
        logging: false,
        allowTaint: true
      };
      html2canvas(this.mapViewEl.nativeElement, config).then((canvas) => {
        const image = canvas.toDataURL("image/jpg").replace("tmage/jpg", "image/octet-stream");
        this.loading = false;

        this.chkimage = image;
        Swal.fire({
          title: this.translateService.instant("Esri-map.alert.screencaptured"),
          imageUrl: image,
          imageWidth: 500,
          imageHeight: 200,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.translateService.instant("Esri-map.alert.screensave"),
          showLoaderOnConfirm: true,
          cancelButtonAriaLabel: "Cancel",
          showCloseButton: true,
          customClass: {
            header: "header-swal-override",
            cancelButton: "cancel-button-class",
            closeButton: "close-button-class"
          },
          preConfirm: () => {
            const link = document.createElement("a");
            link.download = "image.jpg";

            const uri = canvas.toBlob(function (blob) {
              link.href = URL.createObjectURL(blob);
              link.click();
            }, "image/png");
          },
          inputAttributes: {
            "aria-label": "control not in use"
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  closeDataCollector() {
    try {
      this.DataEditor = false;
      if (document.getElementById("more").classList.contains("i-active")) {
        document.getElementById("more").classList.remove("i-active");
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleDataEditor() {
    try {
      this.dataEditorSettings = JSON.parse(localStorage.getItem("dataEditorSettings"));
      if (this.dataEditorSettings && Object.prototype.hasOwnProperty.call(this.dataEditorSettings, "hardDelete")) {
        this.DataEditor = !this.DataEditor;
        if (this.DataEditor === true) {
          this.showLayers = this.showFilter = this.showSearch = this.showSelect = this.closeQueryBuilderVar = this.closeHelpVar = this.showMeasure = this.showAdd = this.showRedlineDraw = false;
          this.deactivateMeasure();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  choose(target) {
    try {
      if (target.id === "New") {
        this.taskType = "New";
        if (document.getElementById("add_span")) {
          document.getElementById("add_span").className = "icon icon-edit medium";
        }
      } else {
        this.taskType = "Collect";
        if (document.getElementById("add_span")) {
          document.getElementById("add_span").className = "icon addNew-orange medium";
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  saveDataEditor() {
    try {
      if (this.taskType) {
        this.dataEditorSettings.taskType = this.taskType;
      }
      const inthardDel = this.dataEditorSettings.hardDelete ? 1 : 0;
      const isOpenCreateEditTaskForm = this.dataEditorSettings.IsOpenCreateEditTaskForm ? 1 : 0;
      const dataEditorSettings = { hardDelete: inthardDel, IsOpenCreateEditTaskForm: isOpenCreateEditTaskForm };
      localStorage.setItem("dataEditorSettings", JSON.stringify(this.dataEditorSettings));
      this.GenericLayerService.postHardDelete(this.currentUser.clientId, dataEditorSettings).subscribe((res: any) => {
        console.log(res);
      });
      this.closeDataCollector();
    } catch (error) {
      console.error(error);
    }
  }
  deleteType() {
    console.log(this.dataEditorSettings);
    localStorage.setItem("dataEditorSettings", JSON.stringify(this.dataEditorSettings));
  }
  closeFromQB(event) {
    this.isFromQB = event;
  }
  //added by sowjanya JIRA ID-53 15-10-2021
  ExportShapefile() {
    try {
      if (this.Geoprocessor) {
        this.gpServiceTrackUrl = new this.Geoprocessor(environment.gpService);
      }
      if (this.objGlobal.gpstracklogData["startDate"] > this.objGlobal.gpstracklogData["endDate"]) {
        Swal.fire("Esri-map.alert.dateentryvalidate");
      } else if (this.currentUser.clientId === undefined) {
        Swal.fire(this.translateService.instant("Esri-map.alert.clientIDvalidate"));
      } else {
        console.log(this.objGlobal.gpstracklogData);
        const params = {
          // eslint-disable-next-line camelcase
          in_clientID: this.currentUser.clientId,
          // eslint-disable-next-line camelcase
          in_region: "Region",
          // eslint-disable-next-line camelcase
          in_layerName: "GPSTrackLog",
          // eslint-disable-next-line camelcase
          user_id: this.objGlobal.gpstracklogData["selectedUser"],
          // eslint-disable-next-line camelcase
          start_time: this.datepipe.transform(this.objGlobal.gpstracklogData["startDate"], "MM/dd/yyyy"),
          // eslint-disable-next-line camelcase
          end_time: this.datepipe.transform(this.objGlobal.gpstracklogData["endDate"], "MM/dd/yyyy")
        };
        //Eslint camcase confirm - Mani
        this.gpServiceTrackUrl.submitJob(params, this.gpJobComplete.bind(this), this.gpJobStatus.bind(this), this.gpJobFailed.bind(this));
      }
    } catch (error) {
      console.error(error);
    }
  }
  gpJobComplete(jobinfo) {
    try {
      const URLData = environment.gpService + "/jobs/" + jobinfo.jobId + "/results/outputPath";
      this.GenericLayerService.getServiceInfo(URLData).subscribe(
        (urlData: any) => {
          const OpenURL = urlData.value;
          window.open(OpenURL);
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  gpJobStatus(jobinfo) {
    try {
      switch (jobinfo.jobStatus) {
        case "esriJobSubmitted":
          this.ExportStatus = this.translateService.instant("Esri-map.alert.statussubmitted");
          this.loading = true;
          break;
        case "esriJobExecuting":
          this.ExportStatus = this.translateService.instant("Esri-map.alert.statusprocessing");
          this.loading = true;
          break;
        case "esriJobSucceeded":
          this.ExportStatus = this.translateService.instant("Esri-map.alert.chkdownloadfldr");
          this.loading = false;
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
  gpJobFailed(error) {
    Swal.fire(error);
  }
  //Added for Visualization layers
  //Added by Venkatesh for jira-677,678
  async getVisualizationLayers() {
    await this.GenericLayerService.getVisualizationLayers().subscribe((layerList: any) => {
      console.log(layerList);
      layerList.sort(function (a, b) {
        return a.layerOrder - b.layerOrder;
      });
      layerList.forEach((layer) => {
        layer.legend = layer.wmsUrl + "?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=15&HEIGHT=15&LAYER=" + layer.originalLayerName;
        layer.visible = false;
        layer.imgLogo = BASE64_PREFIX + layer.logo;
        this.visualizationLayersData.push(layer);
        var currentLayer = new this.wmsLayer(layer.wmsUrl, {
          format: "png",
          visibleLayers: [layer.originalLayerName]
        });
        currentLayer.id = layer.layerName;
        this.map.addLayer(currentLayer);
        currentLayer.setVisibility(false);
      });
      this.map.visualizationLayers = this.visualizationLayersData;
    });
  }

  toggleVisualizationLayers(lyr) {
    try {
      lyr.visible = !lyr.visible;
      if (lyr.visible) {
        this.map.getLayer(lyr.layerName).setVisibility(true);
      } else {
        this.map.getLayer(lyr.layerName).setVisibility(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  //
}
