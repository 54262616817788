import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../../services/authentication.service";
import { GenericLayerService } from "../../../../services/genericLayer.service";
import { Subscription, Observable } from "rxjs";
import { Globals } from "../../../../select/globals";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { IUser } from "src/app/interface/user";
import { CrewManagementService } from "../../../crewmanagement.service";

@Component({
  selector: "app-sms-optimizer",
  templateUrl: "./optimizer.component.html",
  styleUrls: ["./optimizer.component.scss"]
})
export class OptimizerComponent implements OnInit {
  @Output() crewTasksData = new EventEmitter();
  @Output() showTask = new EventEmitter();
  @Input() events: Observable<void>;
  @Input() FeatureLayer;
  @Input() Graphic;
  private eventsSubscription: Subscription;
  assignmentTdata;
  assignments;
  totalassignments;
  regions: any = [];
  asset: any = [];
  dateToday;
  selectedregion;
  selectedAssetClass;
  selectedRow;
  selectedDate;
  totalUserList;
  arrdragPosition = [];

  timeslots = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  selectedRowindex;
  Primarylangvalid;
  Secodarylangvalid;
  private currentUser: IUser;

  constructor(
    private authenticationService: AuthenticationService,
    private genericLayerService: GenericLayerService,
    public objGlobals: Globals,
    private translateService: TranslateService,
    private crewManagementService: CrewManagementService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.currentUser = this.authenticationService.currentUserValue;
    this.currentUser = this.authenticationService.currentUserValue;
    this.selectedregion = this.crewManagementService.selectedRegion;
    this.selectedAssetClass = this.crewManagementService.selectedLayer;
    this.asset = this.crewManagementService.layersList;
    this.regions = this.crewManagementService.regionsList;
    const date = new Date();
    this.dateToday = date.toLocaleDateString();
    this.selectedDate = this.dateToday;
    this.crewManagementService.regions.subscribe((result) => {
      console.log(this.crewManagementService.regionsList);
      this.selectedregion = this.crewManagementService.selectedRegion;
      this.regions = this.crewManagementService.regionsList;
    });
    this.crewManagementService.layers.subscribe((result) => {
      console.log(this.crewManagementService.layersList);
      this.selectedAssetClass = this.crewManagementService.selectedLayer;
      this.asset = this.crewManagementService.layersList;
    });
    this.totalassignments = this.crewManagementService.totalrecords;
    this.crewManagementService.records.subscribe((result) => {
      this.arrdragPosition.push({ x: 100, y: 0 });
      this.totalassignments = result;
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    });
  }

  ngOnInit() {
    this.crewManagementService.getRecords();
    this.eventsSubscription = this.events.subscribe(
      function () {
        this.loadAllTasks();
      }.bind(this)
    );
    const date = new Date();
    this.dateToday = date.toLocaleDateString();
    this.selectedDate = this.dateToday;
    this.arrdragPosition.push({ x: 100, y: 0 });

    if (this.totalassignments.length > 0) {
      this.genericLayerService.getUserList(this.currentUser.clientId).subscribe(
        function (totalUserList) {
          this.totalUserList = totalUserList;
          this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        }.bind(this)
      );
    }

    document.getElementById("btntodayopt").classList.add("active");
    document.getElementById("btntomorrowopt").classList.remove("active");
  }
  groupByrecords(resultArray, date, selectedregion, selectedAssetClass, totalUserList) {
    try {
      const result = [];
      this.assignments = result;
      resultArray.reduce((res, value) => {
        if (value.attributes.Region != null && value.attributes.SheduledDate != null && value.attributes.AssetName != null) {
          if (new Date(value.attributes.SheduledDate).toLocaleDateString() === date && value.attributes.AssetName === selectedAssetClass && value.attributes.Region === selectedregion) {
            if (value.attributes.Worker == null) {
              value.attributes.Worker = "Unassigned";
            }

            if (!res[value.attributes.Worker]) {
              res[value.attributes.Worker] = {
                user: value.attributes.Worker,
                noOfTasks: 0,
                duration: 0,
                tasks: []
              };
              result.push(res[value.attributes.Worker]);
            }

            res[value.attributes.Worker].noOfTasks += 1;
            res[value.attributes.Worker].duration += value.attributes.EstimatedDuration;

            if (value.attributes.SheduledDate) {
              const tdate = new Date(value.attributes.SheduledDate);
              console.log(date);
              value.attributes.hours = tdate.getHours();
              value.attributes.minutes = tdate.getMinutes();
            }
            // db filed
            // eslint-disable-next-line camelcase
            value.attributes.Task_Sequence = res[value.attributes.Worker].tasks.length + 1;
            res[value.attributes.Worker].tasks.push(value.attributes);
          }
        }

        return res;
      }, {});

      // tslint:disable-next-line:prefer-for-of
      for (let user = 0; user < totalUserList.length; user++) {
        if (
          totalUserList[user].regions.filter((regionVal) => {
            if (regionVal.regionName === selectedregion && regionVal.accessPrivilege === "Data Editor") {
              return regionVal;
            }
          }).length > 0
        ) {
          // if(result.reduce(function(value){ return value.user == totalUserList[user].username }).length >0)
          if (result.filter((e) => e.user === totalUserList[user].username).length === 0) {
            result.push({
              user: totalUserList[user].username,
              noOfTasks: 0,
              duration: 0
            });
          }
        }
      }
      // this.assignments.sort((a, b) => (a.no_of_tasks < b.no_of_tasks) ? 1 : ((b.no_of_tasks < a.no_of_tasks) ? -1 : 0));

      const sortBy = [
        {
          prop: "no_of_tasks",
          direction: -1
        },
        {
          prop: "username",
          direction: -1
        }
      ];

      this.assignments.sort((a, b) => {
        let i = 0;
        let sortResult = 0;

        while (i < sortBy.length && sortResult === 0) {
          if (a[sortBy[i].prop] && b[sortBy[i].prop]) {
            sortResult = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0);
          }

          i++;
        }

        return sortResult;
      });
      console.log(this.assignments);
      //  this.assignments.sort((a, b) => (a.user > b.user) ? 1 : ((b.user > a.user) ? -1 : 0));
      setTimeout(
        function () {
          this.createdragPositionMulArray();
        }.bind(this),
        1000
      );
    } catch (error) {
      console.error(error);
    }
  }
  change() {
    this.arrdragPosition[0][0] = { x: -30, y: 0 };
  }

  createdragPositionMulArray() {
    try {
      const positionarray = [];

      const cdktable = document.getElementById("cdktable");
      console.log(cdktable);

      if (cdktable) {
        const cellwidth = cdktable.clientWidth;

        if (cdktable.clientWidth) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.assignments.length; i++) {
            const innerarray = [];

            if (this.assignments[i].tasks) {
              // tslint:disable-next-line:prefer-for-of
              for (let j = 0; j < this.assignments[i].tasks.length; j++) {
                const xval = cellwidth * (this.assignments[i].tasks[j].minutes / 60);
                innerarray.push({ x: xval, y: 0 });
              }

              positionarray.push(innerarray);
            } else {
              positionarray.push({ x: 0, y: 0 });
            }
          }
        }
      }

      this.arrdragPosition = positionarray;
      console.log(this.arrdragPosition);
    } catch (error) {
      console.error(error);
    }
  }
  filterdatabyDate(date) {
    try {
      if (date === "today") {
        this.selectedDate = this.dateToday;
        this.groupByrecords(this.totalassignments, this.dateToday, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        this.changeactivebtn("today");
      } else {
        const tomorrow = new Date(this.dateToday);
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.selectedDate = tomorrow.toLocaleDateString();
        this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
        this.changeactivebtn("tomorrow");
      }
    } catch (error) {
      console.error(error);
    }
  }
  changeactivebtn(today) {
    try {
      if (today === "today") {
        document.getElementById("btntodayopt").classList.add("active");
        document.getElementById("btntomorrowopt").classList.remove("active");
      } else {
        document.getElementById("btntodayopt").classList.remove("active");
        document.getElementById("btntomorrowopt").classList.add("active");
      }
    } catch (error) {
      console.error(error);
    }
  }
  onRegionChange(evt) {
    try {
      console.log(evt);
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedRegion = this.selectedregion;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
    } catch (error) {
      console.error(error);
    }
  }
  onAssetChange(event) {
    try {
      console.log(event);
      this.crewManagementService.tasksModified.emit(true);
      this.crewManagementService.selectedLayer = this.selectedAssetClass;
      this.groupByrecords(this.totalassignments, this.selectedDate, this.selectedregion, this.selectedAssetClass, this.totalUserList);
    } catch (error) {
      console.error(error);
    }
  }
  rowclicked(data, ind) {
    try {
      //  this.selectedRowIndex =i;
      // this.selectedRowIndex = event;
      this.crewManagementService.tasksModified.emit(true);

      for (let indr = 0; indr < this.assignments.length; indr++) {
        if (indr !== ind) {
          document.getElementById("row_" + indr).classList.remove("active");
        } else {
          document.getElementById("row_" + indr).classList.add("active");
        }
      }

      this.selectedRowindex = ind;

      for (let i = 0; i < data.tasks.length; i++) {
        data.tasks[i].sequence = i;
      }

      this.selectedRow = ind;
      data.date = this.selectedDate;
      data.region = this.selectedregion;
      data.assetclass = this.selectedAssetClass;
      console.log(data);
      this.crewTasksData.emit(data);
    } catch (error) {
      console.error(error);
    }
  }

  filterData(data, hours) {
    try {
      if (data) {
        const arr = data.filter((object) => {
          return object.hours === hours;
        });
        // this.filteredLength = arr.length;
        return arr;
      }
    } catch (error) {
      console.error(error);
    }
  }

  openTask(task) {
    try {
      this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.activityId, "MaintID", task.MaintID).subscribe((results: any) => {
        if (results && results.features && results.features[0]) {
          task.Activity = results.features[0].attributes.Activity;
          this.objGlobals.userSelectedActivity = results.features[0].attributes.Activity;
          this.objGlobals.selectedTask = task;
          this.showTask.emit(true);
        }
      });

      console.log("need to open task form");
    } catch (error) {
      console.error(error);
    }
  }
  dragEnd(evt, pind, cind, task) {
    try {
      const cdktable = document.getElementById("cdktable");

      if (cdktable) {
        const cellwidth = cdktable.clientWidth;
        const cellheight = cdktable.clientHeight;

        console.log(evt.distance);
        console.log(pind);
        console.log(cind);
        console.log(evt);

        //const rowsChangedCount = evt.distance.y % cellheight;
        const columnChange = evt.distance.x;

        let newuserrow;

        if (evt.distance.y > 0) {
          newuserrow = (evt.distance.y - (evt.distance.y % cellheight)) / cellheight;
        }

        if (evt.distance.y < 0) {
          newuserrow = (evt.distance.y - (evt.distance.y % cellheight)) / cellheight;
        }

        if (evt.distance.y === 0) {
          newuserrow = 0;
        }

        console.log(newuserrow);
        const timechange = (evt.distance.x / cellwidth) * 60;
        const sheduledTime = new Date(task.SheduledDate);
        let praposedtime = sheduledTime.getTime() + Math.floor(timechange) * 60000;

        if (new Date(praposedtime + parseInt(task.EstimatedDurationn, 10) * 60000).getHours() >= 18) {
          praposedtime -= parseInt(task.EstimatedDurationn, 10) * 60000;
        }

        if (new Date(praposedtime).getHours() < 7) {
          const dtime = new Date(new Date(praposedtime).setHours(7, 0, 0));
          praposedtime = dtime.getTime();
        }

        if (new Date(praposedtime).getHours() >= 18) {
          const dtime = new Date(new Date(praposedtime).getTime() - parseInt(task.EstimatedDuration, 10) * 60000);
          // const dtime = new Date(new Date(praposedtime).setHours(7, 0, 0));
          praposedtime = dtime.getTime();
        }

        console.log(sheduledTime);

        Swal.fire({
          title: this.translateService.instant("Crewmanagement.alertoptimizer.updatetask"),
          text: this.translateService.instant("Crewmanagement.alertoptimizer.verifytoupdate"),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.translateService.instant("Crewmanagement.alertoptimizer.continuebtn")
        }).then((result) => {
          if (result.value) {
            console.log("");
            // Start check task before asign
            const SheduledDate = new Date(praposedtime).toLocaleDateString();
            const tomorrow = new Date(praposedtime);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const SheduledNextDate = tomorrow.toLocaleDateString();

            const strCond = "SheduledDate >'" + SheduledDate + "' and SheduledDate <'" + SheduledNextDate + "'  and Worker";
            this.genericLayerService.getFilteredFeaturesByField(this.currentUser.serviceLyrInfo.maintenanceId, strCond, this.assignments[pind + newuserrow].user).subscribe(
              function (results: any) {
                // this.activity = results.features[0].attributes.Activity;
                console.log(results.features);
                let totalDuration = 0;
                results.features.forEach((element) => {
                  totalDuration += element.attributes.EstimatedDuration;
                });
                console.log(totalDuration);

                if (task.Worker === this.assignments[pind + newuserrow].user) {
                  totalDuration += 0;
                } else {
                  totalDuration += task.EstimatedDuration;
                }

                if (totalDuration > 480) {
                  Swal.fire("Selected User is Over Loaded! Please Select Another User");
                  this.arrdragPosition[pind][cind] = {
                    x: this.arrdragPosition[pind][cind].x,
                    y: this.arrdragPosition[pind][cind].y
                  };
                } else {
                  this.arrdragPosition[pind][cind] = {
                    x: +this.arrdragPosition[pind][cind].x + columnChange,
                    y: this.arrdragPosition[pind][cind].y + newuserrow * cellheight
                  };
                  const ftrLyrTbl = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.maintenanceId, { outFields: ["*"] });
                  const featurese = [];
                  const feature = new this.Graphic();
                  task.Worker = this.assignments[pind + newuserrow].user;
                  task.SheduledDate = praposedtime;
                  feature.setAttributes(task);
                  featurese.push(feature);
                  ftrLyrTbl.applyEdits(null, featurese, null).then(
                    function (newrow) {
                      this.crewManagementService.getRecords();
                      this.crewManagementService.tasksModified.emit();
                      console.log(newrow);
                    }.bind(this)
                  );
                }
              }.bind(this)
            );

            // End
          } else {
            this.arrdragPosition[pind][cind] = {
              x: this.arrdragPosition[pind][cind].x,
              y: this.arrdragPosition[pind][cind].y
            };
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  getActivity(data) {
    if (data) {
      const assetId = data.attributes.OBJECTID;
      console.log(assetId);
    }
  }
  showUserOverLoadedMsg() {
    Swal.fire("Selected User is Over Loaded! Please Select Another User");
  }
}
