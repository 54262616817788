import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "../services/authentication.service";
import { UserService } from "../services/user.service";
import Swal from "sweetalert2";
import { IUser } from "../interface/user";
import { LoginTranslateService } from "src/app/shared/login-translation.service";
import { faCheckCircle, faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  host: { class: "w-100" }
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  faCheckCircle = faCheckCircle;
  faUser = faUser;
  faLock = faLock;
  faTimesCircle = faTimesCircle;
  cpsubmitted = false;
  returnUrl: string;
  tocUrl;
  showTOCModal: false;
  @ViewChild("tocmodalpopup", { static: true }) private tocmodalpopup;
  @ViewChild("ChangePasswordForm", { static: true }) private ChangePasswordForm;
  user: any;
  TCOModel: NgbModalRef;
  ChangePWDModel: NgbModalRef;
  public supportedLangs: any[];
  public translatedText: string;
  public capslockOn: boolean;
  selectedLang = "English";
  codelang = "us";
  //JIRA ID:-47 & JIRA ID:-56
  // eslint fix
  loginattemptErrMsg = "";
  loginArialabel: string = "Login disable";
  //TSDRSAM-1687
  username: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private userService: UserService,
    private translate: LoginTranslateService
  ) {
    // translateService.setDefaultLang('en');
    // this.translateService.use(navigator.language);
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPwd: ["", Validators.required],
        //TSDRSAM-1687
        newPwd: ["", [Validators.required]],
        confirmPwd: ["", Validators.required]
      },
      { validator: this.checkPasswords.bind(this) }
    );

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // this.onSubmit()
    // removing any pending route logger data
    localStorage.removeItem("sms-route-logger");

    this.supportedLangs = [
      { display: "English", value: "en" },
      { display: "Español", value: "es" },
      { display: "Deutsche", value: "de" },
      { display: "Português", value: "pt" },
      { display: "Hindi", value: "hi" }
    ];

    // set current langage
    this.selectLang("us");
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get ChangePWD() {
    return this.changePasswordForm.controls;
  }

  keyPress(event: any) {
    if (event.getModifierState("CapsLock") === true) {
      this.capslockOn = true;
    } else if (event.getModifierState("CapsLock") === false) {
      this.capslockOn = false;
    }
    if (this.loginForm.valid) {
      this.loginArialabel = "Login";
    } else {
      this.loginArialabel = "Login disabled";
    }
  }

  onSubmit() {
    try {
      this.submitted = true;

      //Added for ADA
      const fieldsToCheck = ["username", "password"];
      for (let i = 0; i < fieldsToCheck.length; i++) {
        const fieldName = fieldsToCheck[i];
        if (this.loginForm.get(fieldName).invalid) {
          (<any>this.loginForm.get(fieldName)).nativeElement.focus();
          break;
        }
      }
      //

      // reset alerts on submit
      // this.alertService.clear();
      // stop here if form is invalid
      if (this.loginForm.invalid) {
        return;
      }

      this.loading = true;
      const Creditinals = {
        userName: this.f.username.value,
        password: this.f.password.value,
        device: "web"
      };
      this.username = this.f.username.value; //TSDRSAM-1687
      //after logging in with expired password will get message and userid clientid and token
      this.authenticationService
        .login(Creditinals)
        .then((res: any) => {
          this.user = res.source.value;

          if (!this.user.defaultPasswordStatus) {
            this.showChangePasswordPopup();
          } else if (!this.user.webTOCStatus) {
            this.showToc(this.user);
          } else if (res.message === "User.Password.Expired") {
            this.loading = false;
            Swal.fire({
              title: this.translate.instant(res.message),
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok"
            }).then((result) => {
              if (result.value) {
                this.showChangePasswordPopup();
              }
            });
          } else {
            this.redirectToMap();
          }
        })
        .catch((e) => {
          // Added by Abhilash JIRA ID:-47 & JIRA ID:-56 on 10/11/2021
          if (e.error.resultset && e.error.message === "User.AttemptRemaining." + e.error.resultset.attemptRemaining + "/" + e.error.resultset.maxAttempt) {
            document.getElementById("txtpassword").focus(); //Added for ADA
            this.loading = false;
            this.loginattemptErrMsg =
              this.translate.instant("User.AttemptRemaining.1") +
              e.error.resultset.attemptRemaining +
              this.translate.instant("User.AttemptRemaining.2") +
              e.error.resultset.maxAttempt +
              this.translate.instant("User.AttemptRemaining.3");
          } else if (e.error.message === "User.Account.Inactive") {
            this.loading = false;
            this.loginattemptErrMsg = this.translate.instant(e.error.message);
          } else if (e.error.message === "User.LockOut.Contact.SolutionAdmin") {
            this.loading = false;
            this.loginattemptErrMsg = this.translate.instant(e.error.message);
          } else if (e.error.message === "User.Password.Expired") {
            this.loading = false;
            this.user = e.error;
            Swal.fire({
              title: this.translate.instant(e.error.message),
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok"
            }).then((result) => {
              if (result.value) {
                this.showChangePasswordPopup();
              }
            });
          } else {
            this.loading = false;
            //this.loginattemptErrMsg = "Login Failed..!!";
            this.loginattemptErrMsg = e.error.message;
            document.getElementById("txtuser").focus(); //Added for ADA
            if (e.error && e.error.error && e.error.error.message) {
              console.error("Login failed..!!", e);
            }
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  showToc(res: IUser) {
    try {
      this.TCOModel = this.modalService.open(this.tocmodalpopup, {
        centered: true,
        size: "xl",
        backdrop: "static",
        keyboard: false,
        windowClass: "h-75"
      });
      this.tocUrl = "toc/" + res.clientType + "_" + res.languageID + "_" + "web.html";
      document.getElementById("divtoc").innerHTML = '<embed class="w-100" src="' + this.tocUrl + '">';
    } catch (error) {
      console.error(error);
    }
  }
  changePassword() {
    try {
      this.cpsubmitted = true;

      if (this.changePasswordForm.invalid) {
        return;
      }

      const data = {
        oldPassword: "string",
        newPassword: "string",
        confirmnewPassword: "string"
      };
      data.oldPassword = this.ChangePWD.oldPwd.value;
      data.newPassword = this.ChangePWD.newPwd.value;
      data.confirmnewPassword = this.ChangePWD.confirmPwd.value;

      if (this.user) {
        this.authenticationService
          .PasswordChange(this.user.clientID ?? this.user.clientId, this.user.userID ?? this.user.userId, data)
          .then((res: any) => {
            Swal.fire({
              title: this.translate.instant("Login.alert.passwordsuccessmsg"),
              text: this.translate.instant("Login.alert.reloginmessage"),
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK"
            }).then((result) => {
              if (result.value) {
                this.ChangePWDModel.close();
                location.reload();
              }
            });
          })
          .catch((e) => {
            //TSDRSAM-1948
            if (e.error.resultset && e.error.message === "User.AttemptRemaining." + e.error.resultset.attemptRemaining + "/" + e.error.resultset.maxAttempt) {
              Swal.fire(this.translate.instant("Login.changepswd.passNotValid"));
            } else if (e.error.message === "User.LockOut.Contact.SolutionAdmin") {
              Swal.fire(this.translate.instant(e.error.message));
            } else if (e.error.message === "Login.changepswd.InvalidPasswordLast24") {
              Swal.fire(this.translate.instant("Login.changepswd.InvalidPasswordLast24"));
            } else {
              Swal.fire(e.error.message);
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
  acceptTOC() {
    try {
      this.authenticationService.updateTOCStatus().subscribe(
        (evt) => {
          this.authenticationService.currentUserValue.webTOCStatus = 1;
          this.TCOModel.close();
          this.redirectToMap();
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  showChangePasswordPopup() {
    try {
      this.authenticationService.updatePasswordFeedback();
      this.ChangePWD.newPwd.setValidators(Validators.pattern(this.authenticationService.passwordPattern));
      this.ChangePWDModel = this.modalService.open(this.ChangePasswordForm, {
        centered: true,
        size: "lg",
        backdrop: "static",
        keyboard: false
      });
    } catch (error) {
      console.error(error);
    }
  }

  redirectToMap() {
    try {
      this.authenticationService.saveCredsForRedirect();
      this.router.navigate(["/"]);
    } catch (error) {
      console.error(error);
    }
  }

  checkPasswords(group: UntypedFormGroup) {
    try {
      // here we have the 'passwords' group
      const validation = { confirmPassSame: true, oldPassSame: false, passUsernameSame: false };
      const newPass = group.get("newPwd").value;
      const confirmPass = group.get("confirmPwd").value;
      const oldPass = group.get("oldPwd").value;

      if (newPass === confirmPass) {
        validation.confirmPassSame = false;
      }

      if (oldPass === newPass) {
        validation.oldPassSame = true;
      }
      //TSDRSAM-1687
      if (this.username === newPass) {
        validation.passUsernameSame = true;
      }

      return !validation.oldPassSame && !validation.confirmPassSame ? null : validation;
    } catch (error) {
      console.error(error);
    }
  }

  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    return lang === this.translate.currentLang;
  }

  selectLang(lang: string) {
    // set current lang;
    this.translate.use(lang);
    this.codelang = lang;
    //TSDRSAM-1927
    localStorage.setItem("currentUser_language", lang);
  }

  selectbehave(event) {
    try {
      switch (event.target.value) {
        case "English":
          this.selectLang("en");
          break;
        case "Español":
          this.selectLang("es");
          break;
        case "Deutsche":
          this.selectLang("de");
          break;
        case "Português":
          this.selectLang("pt");
          break;
        case "Hindi":
          this.selectLang("hi");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
  myFunction(callback) {
    this.loading = false;
    callback();
    this.ChangePWDModel.close();
    location.reload();
  }

  valueClick(value: string) {
    this.selectedLang = value;
  }
}
