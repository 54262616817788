import { Pipe } from "@angular/core";
// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
  name: "ampipe"
})
export class NinePipe {
  transform(objects: any[]): any[] {
    if (objects) {
      return objects.filter((object) => {
        return object.hours === 9;
      });
    }
  }
}
