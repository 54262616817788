import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { environment } from "src/environments/environment";
import { SMSHttpClient } from "../helpers/http-client";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IRequestOptions } from "src/app/interface/requestOption";
import { QueryLayers } from "./queryLayers.service";
import { Globals } from "../select/globals";
import { AuthenticationService } from "./authentication.service";
import { RetireAssetService } from "./retire-asset.service";
import { EventEmitersService } from "../services/eventEmiters.service";
import { GenericLayerService } from "./genericLayer.service";
import { GISHttpClient } from "../helpers/gishttp-client";
@Directive()
@Injectable({
  providedIn: "root"
})
export class AssetDetailService {
  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };
  public Query: any;
  public QueryTask: any;
  public rollback: any;
  public currentUser: any;
  public parentAsset: any;
  public parentAssetAttributeForm = null;
  public childAssetId = null;
  public childAssetClassName = null;
  public assetFormatCodes = [];
  public activetasksData=[];
  constructor(
    private httpClient: SMSHttpClient,
    public query: QueryLayers,
    public objGlobals: Globals,
    public currentuser: AuthenticationService,
    private plainhttpClient: HttpClient,
    public eventEmitersService: EventEmitersService,
    private genericLayerService: GenericLayerService,
    private gisHttpClient: GISHttpClient
  ) {
    this.currentUser = this.currentuser.currentUserValue;
  }

  @Output() public assetChanged: EventEmitter<any> = new EventEmitter();
  @Output() public tasksModified: EventEmitter<any> = new EventEmitter();
  @Output() public gridCardCommonSelectedAsset: EventEmitter<any> = new EventEmitter();
  @Output() public assetReplaced: EventEmitter<any> = new EventEmitter();
  dropdownValues = [];
  layerData: any;
  getDropDownValues() {
    const layers = [];
    // layers.push({ "Layer": "Select Layer", "layer_id": null })
    this.layerData.forEach((lyr) => {
      if (lyr.layerName !== "Sign" && lyr.accessPrivilege === "Data Editor") {
        layers.push({ Layer: lyr.layerName, layer_id: lyr });
      }
    });
    this.dropdownValues = layers;
    return this.dropdownValues;
  }

    //Jira Id-928,935 additional asset changes
    getAssetIDFormat(Query, QueryTask, FeatureLayer) {
    
      if (this.assetFormatCodes.length == 0) {
        const baseurl = this.currentUser.webServiceURL + environment.featureServer;
        const query = new Query();
        const queryTask = new QueryTask(new FeatureLayer(baseurl + this.currentUser.serviceLyrInfo.tableAliasId).url);
        //query.where = "Name='" +layername+"'";
        query.where = "1=1";
        query.outFields = ["Name", "LayerCode"];
        query.returnDistinctValues = true;
        query.returnGeometry = false;
        queryTask.execute(query, (result) => {
          if (result) {
            console.log("aliss" + result);
            this.assetFormatCodes = result.features;
  
          }
        });
      }
    }

  getAssetIDClassName(layerCode) {
    const assetFormatCodes = [
      { value: "Barrier", key: "BAR" },
      { value: "CrashCushion", key: "CCU" },
      { value: "EndTreatment", key: "ETM" },
      { value: "Guardrail", key: "GUR" },
      { value: "PavementMarking", key: "PVM" },
      { value: "PavementMarking_Symbol", key: "PVS" },
      { value: "Support", key: "SUP" },
      { value: "Sign", key: "SIG" },
      { value: "Light", key: "LGT" }
    ];
    return assetFormatCodes.filter((prefix) => prefix.key === layerCode);
  }

  uploadImage(fileData, photoId, clientId) {
    const formData = new FormData();
    formData.append("file", fileData);
    return this.httpClient.post(environment.getImage + "?ClientID=" + clientId + "&photoid=" + photoId, formData);
  }
  //70 Doc Type column added
  getImageAsBase64(photoId, clientId, docType,isRequestedByHelpCenter = false) {// Added by Abhilash Jira Id:-40 on 10/11/2021
    return this.httpClient.get(environment.GetImageAsBase64 + "?ClientID=" + clientId + "&photoid=" + photoId + "&Extension=" + docType + "&isRequestedByHelpCenter=" + isRequestedByHelpCenter);
  }
  //Method added to get Asset Icon 70
  getAssetIconAsBase64(clientId, languageCode, assetCode, isParentAsset) {// //Jira Id-731
    return this.httpClient.get(environment.GetAssetIconAsBase64 + "?ClientID=" + clientId + "&LanguageCode=" + languageCode + "&AssetCode=" + assetCode + "&IsParentAsset=" + isParentAsset);
  }
  RetireAsset(asset, maintenancelyr, currentUser,FeatureLayer) {
    asset[2].attributes.RetireDate = new Date(Date.now()).getTime();
    asset[2].attributes.Status = "Inactive";
    asset[2].attributes.LastModifyDate = new Date(Date.now()).getTime();
    asset[2].attributes.ModifiedBy = this.currentUser.username;
    this.query.updateAsset(asset[1].lyrObj, asset[2]).then((result) => {
      console.log("Asset Updated");
      this.updateOldAssetTasksToInactive(asset[2], maintenancelyr, currentUser, asset[2].attributes.AssetID);
      if(this.objGlobals.activeAsset[1].layerName ==='Support' ){//TSDRSAM-2674
      this.retireRelatedAssets(this.objGlobals.relatedAssetData, FeatureLayer,maintenancelyr);
      }
    });
  }
  ReplaceTask(asset, maintenancelyr, layerUrl, FeatureLayer, Graphic, currentUser, replaceTaskAttributes) {
    // Manikantha - Replace Asset Start
    const retireAsset = new RetireAssetService(FeatureLayer, Graphic, this.currentUser, this.objGlobals, this.eventEmitersService, this.plainhttpClient, this,this.gisHttpClient);
    retireAsset.updateOldReplacedAssetStatus(asset[1].lyrObj, asset, replaceTaskAttributes);
    this.updateOldAssetTasksToInactive(asset[2], maintenancelyr, currentUser, asset[2].attributes.AssetID);
    retireAsset.eventReplaceCompleted.subscribe((result) => {
      console.log("completed replace task .........");
      if (asset[1].layerName === "Sign" || asset[1].layerName === "Light") {
        this.parentAssetAttributeForm = "Child";

        this.childAssetClassName = asset[1].layerName;
        this.childAssetId = asset[0].OBJECTID;

        this.eventEmitersService.selectedChildIndexOnData = asset[0].OBJECTID;
        this.eventEmitersService.selectedChildClass = asset[1].layerName;
        this.eventEmitersService.setSelectedChildIndexOnData(result[0], result[1]);
        this.eventEmitersService.setreplaceTaskCreated(true);
      } else {
        this.parentAssetAttributeForm = "Parent";
        this.childAssetId = null;
        this.childAssetClassName = null;
        this.eventEmitersService.selectedChildIndexOnData = null;
        this.eventEmitersService.setSelectedParentIndexOnData(this.eventEmitersService.selectedParentIndexOnData);
        this.eventEmitersService.selectedChildClass = null;
      }
      this.assetReplaced.emit([this.parentAsset, asset]);
      //this.eventEmitersService.emitTaskEdited.emit(true);
    });
    //  Manikantha - Replace Asset End

    // console.log("replace task service");
    // const _this = this;
    // this.SaveExistData(asset);
    // // Replace old Asset
    // const layer_replace = asset[1].lyrObj;
    // const status = asset[2].attributes.Status;
    // const asset_id = asset[2].attributes.AssetID;
    // this.query.replaceAsset(layer_replace, asset[2], this.objGlobals.relatedAsset, asset[1].layerName);
    // // this.query.retireAsset(layer_replace, asset[2]);

    // // asset[1].lyrObj.applyEdits(null, [asset], null).then((results) => {
    // //     console.log("new asset created");
    // //   });
    // const attr = asset[2].attributes;
    // asset[1].lyrObj.applyEdits([asset[2]], null, null).then((editsResult) => {
    //     if (editsResult) {
    //         const objectId = editsResult[0].objectId;
    //         const prefix = this.getAssetIDFormat(asset[1].layerName);
    //         attr.OBJECTID = objectId;
    //         attr.AssetID = prefix[0].value + "_" + objectId;
    //         attr.Status = "Active";
    //         attr.ReplacesID = asset_id;
    //         attr.ReplaceYear = new Date().getFullYear();
    //         attr.Latitude = asset[2].attributes.Latitude;
    //         attr.Longitude = asset[2].attributes.Longitude;
    //         attr.InstallDate = new Date(Date.now()).getTime();
    //         attr.RetireDate = null;
    //         // 11/20/2020 Manikantha Start
    //         // asset[2].setAttributes(attr);
    //            asset[2].attributes = attr;
    //         // 11/20/2020 Manikantha Start

    //         asset[1].lyrObj.applyEdits(null, [asset[2]], null).then((result) => {
    //             if (asset[1].layerName !== "Sign" && asset[1].layerName !== "Light") {
    //                 this.query.linkRelatedAssets(asset[2], this.objGlobals.relatedAsset).then(function(result) {
    //                     console.log("linked related features");
    //                 });
    //             }
    //             this.saveTask(asset[2], maintenancelyr, layerUrl, FeatureLayer, Graphic, currentUser);
    //         })
    //         .catch((error) => {
    //             console.log("===============================================");
    //             console.error( "[ applyEdits ] FAILURE: ", error.code, error.name, error.message );
    //             console.log("error = ", error);
    //           });

    //     }
    // })
    // .catch((error) => {
    //     console.log("===============================================");
    //     console.error( "[ applyEdits ] FAILURE: ", error.code, error.name, error.message );
    //     console.log("error = ", error);
    //   });
  }
  setThis() {
    return this;
  }
  setRetireDate(layerUrl, objectId, asset, maintenancelyr, currentUser, FeatureLayer, Graphic, replaceTaskAttributes) {
    const _this = this.setThis();

    return new Promise((resolve) => {
      const query = new _this.Query();
      const queryTask = new _this.QueryTask(layerUrl);
      query.where = "MaintID='MNT_" + objectId + "'";
      query.outFields = ["Activity"];
      query.returnGeometry = true;
      let res;
      queryTask.execute(query).then((result) => {
        if (result && result.features[0]) {
          resolve(result);
          res = result;
          // const mnth = new Date().getMonth();
          // const date = mnth + 1 + "/" + new Date().getDate() + "/" + new Date().getFullYear();
          if (res.features[0].attributes.Activity === "Retire") {
            asset[2].attributes.RetireDate = new Date(Date.now()).getTime();
            asset[2].attributes.Status = "Inactive";
            _this.query.updateAsset(asset[1].lyrObj, asset[2]).then((result) => {
              console.log("Asset Updated");
              this.updateOldAssetTasksToInactive(asset[2], maintenancelyr, currentUser, asset[2].attributes.AssetID);
              this.retireRelatedAssets(this.objGlobals.relatedAssetData, FeatureLayer,maintenancelyr);
              this.eventEmitersService.emitTaskEdited.emit(true);
            });
            if (document.getElementById("gen1")) {
              const x = document.getElementById("gen1");
              // tslint:disable-next-line:no-string-literal
              x["disabled"] = true;
              const y = document.getElementById("gen2");
              // tslint:disable-next-line:no-string-literal
              y["disabled"] = true;
              const z = document.getElementById("gen3");
              // tslint:disable-next-line:no-string-literal
              z["disabled"] = true;
            }
          } else if (res.features[0].attributes.Activity === "Replace") {
            // Manikantha - Replace Asset Start
            this.ReplaceTask(asset, "", "", FeatureLayer, Graphic, "", replaceTaskAttributes);
            // Manikantha - Replace Asset End

            // this.SaveExistData(asset);
            // // Replace old Asset
            // const layer_replace = asset[1].lyrObj;
            // const status = asset[2].attributes.Status;
            // const asset_id = asset[2].attributes.AssetID;
            // this.query.replaceAsset(layer_replace, asset[2], this.objGlobals.relatedAsset, asset[1].layerName);
            // this.query.retireAsset(layer_replace, asset[2]);

            // const attr = asset[2].attributes;
            // this.query.addAsset(layer_replace, asset[2])
            //     .then((editsResult) => {
            //         if (editsResult) {
            //             const objectId = editsResult[0].objectId;
            //             const prefix = this.getAssetIDFormat(asset[1].layerName);
            //             attr.OBJECTID = objectId;
            //             attr.AssetID = prefix[0].value + "_" + objectId;
            //             attr.Status = status;
            //             attr.ReplacesID = asset_id;
            //             attr.ReplaceYear = new Date().getFullYear();
            //             attr.Latitude = asset[2].attributes.Latitude;
            //             attr.Longitude = asset[2].attributes.Longitude;
            //             attr.InstallDate = new Date(Date.now()).getTime();
            //             attr.RetireDate = null;
            //              // 11/20/2020 Manikantha Start
            //              // asset[2].setAttributes(attr);
            //                asset[2].attributes = attr;
            //              // 11/20/2020 Manikantha Start
            //             this.query.updateAsset(asset[1].lyrObj, asset[2])
            //                 .then((result) => {
            //                     console.log("Asset Updated");
            //                     if (asset[1].layerName !== "Sign" && asset[1].layerName !== "Light") {
            //                         this.query.linkRelatedAssets(asset[2], this.objGlobals.relatedAsset)
            //                             .then(function(result) {
            //                                 // this.saveTask(asset[2], maintenancelyr, layerUrl,
            //                                 //     FeatureLayer, Graphic, currentUser);
            //                             });
            //                     } else {
            //                         // this.saveTask(asset[2], maintenancelyr, layerUrl, FeatureLayer, Graphic, currentUser);
            //                     }
            //                     this.saveTask(asset[2], maintenancelyr, layerUrl, FeatureLayer, Graphic, currentUser);
            //                 });
            //         }
            //     });
          } else {
            this.eventEmitersService.emitTaskEdited.emit(true);
          }
        }
      });
    });
  }
  retireRelatedAssets(relatedAsset, FeatureLayer, maintenancelyr) {
    console.log(relatedAsset);
    return new Promise(
      function (resolve) {
        const data = relatedAsset.filter((a) => (a.Layer.includes("Sign") || a.Layer.includes("Light")) && a.RelatedData && a.RelatedData.length > 0);
        data.forEach(
          function (layer) {
            const layerId = layer.layer_id;
            layer.RelatedData.forEach(
              function (rltdAsset) {
                rltdAsset.attributes.Status = "Inactive";
                rltdAsset.attributes.RetireDate = new Date(Date.now()).getTime();
                rltdAsset.attributes.ModifiedBy = this.currentUser.username;
                rltdAsset.attributes.LastModifyDate = new Date(Date.now()).getTime();
                const lyr = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + layerId);
                this.query.updateAsset(lyr, rltdAsset);
                //Update child asset task to Inactive
                this.genericLayerService.getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", rltdAsset.attributes.AssetID, "Status", "Open").subscribe(
                  function (result) {
                    console.log(result);
                    result.features.forEach((task) => {
                      task.attributes.Status = "Inactive";
                      task.attributes.LastModifyDate = new Date(Date.now()).getTime();
                      task.attributes.ModifiedBy = this.currentUser.username;
                      maintenancelyr.applyEdits(null, [task], null).then((results) => {
                        console.log(results);
                      });
                    });
                  }.bind(this));
                resolve([]);
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }
  UpdateDocId(Graphic, OID, photoFl) {
    const photoAsset = new Graphic();
    const attr: any = [];
    attr.objectId = OID;
    attr.DocID = OID;
    photoAsset.setAttributes(attr);
    photoFl.applyEdits(
      null,
      [photoAsset],
      null,
      (res) => {
        console.log("Upload Successfully");
      },
      (err) => {
        console.log(err);
      }
    );
  }
  SaveExistData(activeAsset) {
    this.getFeatures(activeAsset[1].lyrObj.url, "ObjectID = " + activeAsset[0].OBJECTID, this.Query, this.QueryTask).then((val) => {
      const result = val[0];
      console.log(result);
      this.rollback = {};
      this.rollback = result.attributes;
    });
  }
  getFeatures(layerURL, WhereCondition, Query, QueryTask) {
    return new Promise((resolve) => {
      const query = new Query();
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.where = WhereCondition;
      const queryTask = new QueryTask(layerURL);
      queryTask.execute(query).then((result) => {
        if (result && result.features[0]) {
          resolve(result.features);
        }
      });
    });
  }

  completeAllRelatedTasks(asset, maintenanceLyr, currentUser, assetID) {
    const query = new this.Query();
    const queryTask = new this.QueryTask(maintenanceLyr.url);
    query.where = "AssetID='" + assetID + "'";
    query.outFields = ["OBJECTID", "Status", "CompletedBy", "CompletionDate"];
    query.returnGeometry = false;
    queryTask.execute(query).then((result) => {
      result.features.forEach((task) => {
        task.attributes.Status = "Inactive";
        task.attributes.AssetID = asset.attributes.AssetID;
        task.attributes.CompletedBy = currentUser.username;
        maintenanceLyr.applyEdits(null, [task], null);
        // maintenanceLyr.applyEdits(null, [task], null).then((results) => {});
      });
    });
  }

  saveTask(asset, mntLayer, layerUrl, FeatureLayer, Graphic, currentUser) {
    const featurese = [];
    const features = new Graphic();
    let assetId;

    // if (asset && asset.attributes.AssetID) {
    // }
    {
      assetId = asset.attributes.AssetID;
    }
    let geomString;
    if (asset.geometry) {
      if (asset && asset.geometry != null) {
        geomString = asset.geometry.toJson();
      }
      if (geomString.paths) {
        geomString.x = geomString.paths[0][0][0];
        geomString.y = geomString.paths[0][0][1];
        delete geomString.paths;
      }
    }
    features.setAttributes({
      Status: "Closed",
      CreatedBy: currentUser.username,
      CreationDate: new Date(Date.now()).getTime(),
      CompletedBy: this.currentUser.username,
      CompletionDate: new Date(Date.now()).getTime(),
      Region: asset.attributes.Region1,
      AssetID: assetId,
      AssetName: asset[1].layerName,
      WorkOrderDesc: "",
      Comment: "",
      GeomWKT: JSON.stringify(geomString),
      PendingChanges: JSON.stringify(this.rollback)
    });
    featurese.push(features);
    // let mntFeatureLayer = new FeatureLayer(mntLayer);
    mntLayer
      .applyEdits(featurese, null, null)
      .then(
        function (newrow) {
          console.log(newrow);
          // updateing MaintID for newly inserted Maintanance record
          const featureseAssetID = [];
          const featuresA = new Graphic();
          featuresA.setAttributes({ OBJECTID: newrow[0].objectId, MaintID: "MNT_" + newrow[0].objectId });
          featureseAssetID.push(featuresA);
          mntLayer
            .applyEdits(null, featureseAssetID, null)
            .then(function (newrow) {
              console.log(newrow);
            })
            .catch(function (error) {
              console.log("===============================================");
              console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
              console.log("error = ", error);
            });
          // End
          const actfeaturese = [];
          const actfeatures = new Graphic();
          actfeatures.setAttributes({
            // updateing MaintID for newly inserted Maintanance record
            // "MaintID": newrow[0].objectId,
            MaintID: "MNT_" + newrow[0].objectId,
            Asset: 0,
            Activity: "New",
            ModifiedBy: currentUser.username
          });
          actfeaturese.push(actfeatures);
          const activityLayer = new FeatureLayer(layerUrl);
          activityLayer.applyEdits(actfeaturese, null, null).then(
            function (newrow) {
              if (this.objGlobals.complaintID != null && this.objGlobals.complaintID !== undefined && this.objGlobals.complaintID !== "") {
                (this.objGlobals.longitude = asset.attributes.Longitude), (this.objGlobals.latitude = asset.attributes.Latitude), (this.objGlobals.assetID = asset.attributes.AssetID);
                this.esrimap.crowdsource();
              }
              console.log(newrow);
            }.bind(this)
          );
          // if (this.wrkStatus == 'Closed' && (this.activity == 'Replace' || this.activity == 'Retire')) {
          //   features.attributes['OBJECTID'] = newrow[0].objectId;
          //   this.task.completeTask(features.attributes);
          // }
          console.log(newrow);
        }.bind(this)
      )
      .catch(function (error) {
        console.log("===============================================");
        console.error("[ applyEdits ] FAILURE: ", error.code, error.name, error.message);
        console.log("error = ", error);
      });
  }

  RollBackReplace(event, asset) {
    console.log(event);
    console.log(asset);
  }

  isOutsideRegion(geometry, geometryEngine, selectedRegion) {
    let geom;
    if (selectedRegion.geom) {
      geom = selectedRegion.geom;
    } else {
      geom = selectedRegion;
    }
    return !geometryEngine.contains(geom, geometry);
  }
  populateDefaultRegionValues(currentUser, selectedRegion, Query, QueryTask) {
    return new Promise(function (resolve) {
      const regionLayer = currentUser.webServiceURL + environment.featureServer + currentUser.serviceLyrInfo.regionId;
      const getRegionData = new Query();

      if (selectedRegion.regionLevel === 4) {
        getRegionData.where = "RegionName4='" + selectedRegion.regionName + "'";
      }
      if (selectedRegion.regionLevel === 3) {
        getRegionData.where = "RegionName3='" + selectedRegion.regionName + "'";
      }
      if (selectedRegion.regionLevel === 2) {
        getRegionData.where = "RegionName2='" + selectedRegion.regionName + "'";
      }
      if (selectedRegion.regionLevel === 1) {
        getRegionData.where = "RegionName1='" + selectedRegion.regionName + "'";
      }
      getRegionData.outFields = ["*"];
      getRegionData.returnGeometry = true;
      const getRegionDataExecute = new QueryTask(regionLayer);
      getRegionDataExecute.execute(getRegionData).then(function (result) {
        resolve(result);
      });
    });
  }
  crossesPolygon(geometry, geometryEngine, selectedRegion) {
    return geometryEngine.crosses(geometry, selectedRegion.geom, geometry);
  }
  updateOldAssetTasksToInactive(asset, maintenanceLyr, currentUser, assetID) {
    this.genericLayerService.getFilteredFeaturesByTwoField(this.currentUser.serviceLyrInfo.maintenanceId, "AssetID", assetID, "Status", "Open").subscribe(
      function (result) {
        console.log(result);
        result.features.forEach((task) => {
          task.attributes.Status = "Inactive";
          // task.attributes.AssetID = asset.attributes.AssetID;
          // task.attributes.CompletionDate = new Date(Date.now()).getTime();
          // task.attributes.CompletedBy = currentUser.username;
          maintenanceLyr.applyEdits(null, [task], null).then((results) => {
            console.log(results);
          });
        });
      }.bind(this)
    );
    // console.log("updateOldAssetTasksToInactive ");
    // const query = new this.Query();
    // const queryTask = new this.QueryTask(maintenanceLyr.url);
    // query.where = "AssetID='" + assetID;
    // query.outFields = ["OBJECTID", "Status", "CompletedBy", "CompletionDate"];
    // query.returnGeometry = false;
    // queryTask.execute(query)
    //     .then((result) => {

    //     });
  }

  //Method to find if replace task is open
  isReplaceTaskOpen(taskData) {
    const isOpenReplaceTask = taskData.filter((taskData) => taskData.attributes.Activity === "Replace" && taskData.attributes.Status === "Open");
    if (isOpenReplaceTask.length > 0) {
      return true;
    }
    return false;
  }

  public getRegions(asset) {
    // jcc 7/28/20 - helper function.  Find, and return, the element in the asset array that holds regions data.
    try {
      let regions;
      asset.forEach((el) => {
        if (el.regions) {
          regions = el.regions;
        }
      });
      return regions;
    } catch (error) {
      console.error(error);
    }
  }
}
