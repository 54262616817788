<div class="sms-useradmin-height">
  <div class="row" id="userAdmin">
    <div class="col">
      <table id="exisitngUserTable" class="table table-hover table-bordered">
        <thead>
          <tr>
            <app-heading-component [headingText]="'Userscreens.useradmin.users' | translate" [variant]="'none'"> </app-heading-component>
          </tr>
          <tr>
            <td style="display: flex; justify-content: space-between; align-items: center">
              <div>
                <mat-label class="h7"
                  ><span>{{ "Userscreens.useradmin.client" | translate }} </span>{{ textValue }}</mat-label
                >
              </div>
              <div>
                <mat-icon data-toggle="modal" data-target="#newUserModal" variant="outlined" class="icon-heading material-icons-outlined" style="height: initial" svgIcon="plus"></mat-icon>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let userTableData of UserTableData; let i = index"
            data-uadmin="yes"
            tabindex="0"
            (click)="TableOnClick($event, userTableData, i)"
            id="{{ 'existingUserRow_' + i }}"
            [attr.aria-label]="'' + userTableData.firstName"
            [ngClass]="{ highlight: selectedRowIndex == i }"
          >
            <td colspan="2" style="word-break: break-word">
              <!--//  25606 reverted  {{ userTableData.username }} -->
              <b> {{ userTableData.firstName }} {{ userTableData.lastName }} </b> <br />
              {{ userTableData.displayName }}
            </td>
            <td style="padding-left: 0px !important">
              <div *ngFor="let rolelist of RoleList">
                <div *ngIf="userTableData.roleId === rolelist.roleId">
                  {{ rolelist.roleName }}
                </div>
              </div>
            </td>
            <td style="display: flex; justify-content: space-around; align-items: center; height: 100%; min-height: 75px">
              <mat-icon svgIcon="pencil" (click)="EditUser(userTableData)" [attr.aria-label]="'Edit ' + userTableData.firstName + ' user'" data-toggle="modal" data-target="#newUserModal"></mat-icon>
              <mat-icon svgIcon="delete" (click)="RemoveUser(userTableData)" [attr.aria-label]="'Remove ' + userTableData.firstName + ' user'" data-toggle="modal"></mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <app-heading-component [headingText]="'Userscreens.useradmin.regions' | translate" [variant]="'none'"> </app-heading-component>
          </tr>
          <tr>
            <td class="table-content">
              <div style="display: flex; flex-grow: 1; justify-content: start">
                <button [matMenuTriggerFor]="regionmenu" style="margin-right: 12px" class="export-type-btn small dropdwon-button">
                  <div>
                    {{ regionsListSelected?.RegionName ?? "" }}
                  </div>
                  <div>
                    <mat-icon variant="outlined" class="material-icons-outlined" style="vertical-align: baseline; height: initial" matSuffix svgIcon="arrow-up-down"></mat-icon>
                  </div>
                </button>
                <mat-menu #regionmenu="matMenu">
                  <ng-container *ngFor="let regionsByClient of RegionsListByClient">
                    <button mat-menu-item class="small" (click)="selectRegion(regionsByClient)" [value]="regionsByClient">{{ regionsByClient.RegionName }}</button>
                  </ng-container>
                </mat-menu>
                <div>
                  <button [matMenuTriggerFor]="regionRoleMenu" class="export-type-btn small dropdwon-button">
                    <div>
                      {{ regionRoleSelected }}
                    </div>
                    <div>
                      <mat-icon variant="outlined" class="material-icons-outlined" matSuffix style="height: initial" svgIcon="arrow-up-down"> </mat-icon>
                    </div>
                  </button>
                  <mat-menu #regionRoleMenu="matMenu">
                    <ng-container *ngFor="let regionRole of RegionRole">
                      <button mat-menu-item class="small" (click)="onSelectRegionRole(regionRole.roleName)" [value]="regionRole.roleKey">{{ regionRole.roleName }}</button>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
              <div>
                <mat-icon (click)="AddRegionsToUser()" aria-label="btnAddRegionId" variant="outlined" class="material-icons-outlined" svgIcon="plus"></mat-icon>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="display: flex; justify-content: space-between; align-items: center" *ngFor="let regiontable of RegionTableData">
            <td>
              <b> {{ regiontable.regionName }} </b><br />
              {{ regiontable.accessPrivilege }}
            </td>
            <td style="text-align: center">
              <mat-icon tabindex="0" data-uadmin="yes" svgIcon="delete" (click)="RemoveRegion(regiontable)" [attr.aria-label]="'Remove ' + regiontable.regionName"></mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <app-heading-component [headingText]="'Userscreens.useradmin.assetclass' | translate" [variant]="'none'"></app-heading-component>
          </tr>
          <tr>
            <td class="table-content">
              <div style="display: flex; flex-grow: 1; justify-content: start">
                <button [matMenuTriggerFor]="layerMenu" style="margin-right: 12px" class="export-type-btn small dropdwon-button">
                  <div>
                    {{ layerListSelected?.gisLayerName ?? "" }}
                  </div>
                  <div>
                    <mat-icon variant="outlined" class="material-icons-outlined" style="vertical-align: baseline; height: initial" matSuffix svgIcon="arrow-up-down"></mat-icon>
                  </div>
                </button>
                <mat-menu #layerMenu="matMenu" data-uadmin="yes" aria-labelledby="assetClasslabelID">
                  <ng-container *ngFor="let assetClassByClient of LayerList">
                    <button mat-menu-item class="small" (click)="selectLayer(assetClassByClient)" [value]="assetClassByClient">{{ assetClassByClient.gisLayerName }}</button>
                  </ng-container>
                </mat-menu>

                <button [matMenuTriggerFor]="assetRoleMenu" class="export-type-btn small dropdwon-button">
                  <div>
                    {{ assetRoleSelected }}
                  </div>
                  <div>
                    <mat-icon variant="outlined" class="material-icons-outlined" style="height: initial" matSuffix svgIcon="arrow-up-down"> </mat-icon>
                  </div>
                </button>
                <mat-menu #assetRoleMenu="matMenu" data-uadmin="yes" aria-labelledby="accessLabelID2">
                  <ng-container *ngFor="let assetRole of RegionRole">
                    <button mat-menu-item class="small" (click)="onSelectAssetRole(assetRole.roleName)" [value]="assetRole.roleKey">{{ assetRole.roleName }}</button>
                  </ng-container>
                </mat-menu>
              </div>
              <div>
                <mat-icon (click)="AddAssetToUser()" aria-label="addAssestBtnID" variant="outlined" class="material-icons-outlined" svgIcon="plus"></mat-icon>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="display: flex; justify-content: space-between; align-items: center" *ngFor="let layerlist of LayerListByUser">
            <td>
              <b>{{ layerlist.gisLayerName }} </b><br />
              {{ layerlist.accessPrivilege }}
            </td>
            <td style="text-align: center">
              <mat-icon tabindex="0" data-uadmin="yes" svgIcon="delete" (click)="RemoveAssetToUser(layerlist.gisLayerId)" [attr.aria-label]="'Remove ' + layerlist.gisLayerName"></mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal bound" id="newUserModal" role="dialog" aria-label="creat_edituser_modal" aria-hidden="true" data-backdrop="false" cdkDrag style="overflow-y: hidden">
  <div class="modal-dialog" role="document">
    <div id="modal-content" class="modal-content" cdkDragBoundary=".modal" cdkDrag>
      <div class="modal-header" cdkDragHandle>
        <h4 class="modal-title" id="modal-title">{{ "Userscreens.useradmin.create_edituser" | translate }}</h4>
      </div>
      <div class="modal-body" id="modal-body" >
        <app-create-edit-user
          *ngIf="ClientList && ClientList.length > 0 && agencyLanguage && agencyLanguage.length > 0"
          (closeModal)="d()"
          (saveUser)="SaveUser($event)"
          [agencyLanguage]="agencyLanguage"
          [clientList]="ClientList"
          [RoleList]="RoleList"
          [selectedUser]="selectedUser"
        >
        </app-create-edit-user>
      </div>
    </div>
  </div>
</div>
