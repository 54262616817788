import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GridComponent } from "./gridview/grid.component";
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from "@angular/forms";
import { CardViewComponent } from "./cardview/cardview.component";
import { QueryLayers } from "../services/queryLayers.service";
import { AssetDetailComponent } from "./assetdetails/details/assetDetail.component";
import { TasksComponent } from "./assetdetails/tasks/tasks.component";
import { AssessmentComponent } from "./assetdetails/assessment/assessment.component";
import { Globals } from ".//globals";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CreateTaskComponent } from "./assetdetails/tasks/createtask/createtask.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipePipe } from "./custom-date-pipe.pipe";
import { NgSelectModule } from "@ng-select/ng-select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTabsModule } from "@angular/material/tabs";
// import { ImageViewerModule as ng2ImageViewerModule } from 'ng2-image-viewer'; // Added by Abhilash Jira Id:-40 on 9/28/2021
import { CustomTooltip } from "./gridview/customTooltip.component"; // Added by Abhilash Jira Id:-40 on 9/28/2021
import { SharedModule } from "../shared/shared.module";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { PdfViewerModule } from "ng2-pdf-viewer";
@NgModule({
  declarations: [GridComponent, CardViewComponent, AssetDetailComponent, CreateTaskComponent, CustomDatePipePipe, TasksComponent, AssessmentComponent, CustomTooltip],
  imports: [
    BrowserModule,
    PdfViewerModule,
    NgbModule,
    FormsModule,
    MatIconModule,
    FontAwesomeModule,
    AgGridModule.withComponents([CustomTooltip]),
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    SharedModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    TranslateModule,
    DragDropModule,
    NgSelectModule
  ],
  exports: [GridComponent, CardViewComponent, AssetDetailComponent, CreateTaskComponent],
  providers: [QueryLayers, Globals]
})
export class SelectModule {
  static forRoot(): ModuleWithProviders<SelectModule> {
    return { ngModule: SelectModule, providers: [] };
  }
}
