import { Injectable, Output, EventEmitter, Directive, Input } from "@angular/core"; // updated for visualization layers
import { GenericMapMethodsService } from "../services/generic-map-methods.service";
import { Globals } from "src/app/select/globals"; // JIRA ID :-35 Added by Abhilash
import Swal from "sweetalert2";
//Added for Visualization layers
import { GenericLayerService } from "../services/genericLayer.service";
import * as xml2js from "xml2js";
import { lidarData } from "../interface/lidarData";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { QueryLayers } from "./queryLayers.service";
import { ToastrService } from "ngx-toastr";
@Directive()
@Injectable({
  providedIn: "root"
})
export class EventEmitersService {
  public selectedFeaturesOnMap: any;
  // JIRA ID :-35 Added by Madhuri
  private singleClickOnMapEvt;
  private queriedLayers: any;
  public inventoryLayersData;
  public listSelectData;
  private TappedPoint;
  // rename numOfOpenPanels
  // represents how many panels are open on the bottom of the screen for setting height of the cardview.component.html. Appropriate values are 0, 1 and 2.
  public numOfOpenPanels: number = 0;
  public viewChange: boolean = false;
  //Card View Selected Parent and Childs
  public selectedLayerIndexOnData = 0;
  public selectedParentIndexOnData = 0;
  public selectedChildIndexOnData = null;
  public selectedChildClass = null;
  // Open Card - Grid - Maintance Forms
  public showGrid = false;
  public showCard = false;
  public maintenanceView = false;
  //show lidar represents the view state of the iframe
  public showLidar = false;
  //view lidar data represents weather or not to query for lidar data against GIS services
  public viewLidarData = false;
  public lidarUrl: SafeResourceUrl;
  public assitId: any = null;
  public activeAssetChangedBySelect;
  public activeAssetFeature: any;

  // Adding New Assets
  public addNewParent = false;
  public addNewChild = false;
  //Deleteing Asset
  private childDeleted = false;
  // Task related Events
  private taskCreated = false;
  private taskEdited = false;
  private replaceTaskCreated = false;
  private reshapeTaskCreated = false;
  private taskCompleted = false;
  private taskRollback = false;
  public draw;
  public mapclick;

  public closeModel;

  public toggleParent = false;
  //var toggleParent = false;
  // public toggleLight = true;
  // public toggleSign = true;

  public toggleLight = false;
  public toggleSign = false;

  public baseLifeValuesData;
  public modifierValueData;
  // for bug TSDRSAM-1112 by Manikantha
  //public baseLifeConfig;
  //public modifierConfig;
  // DetailedMaintenance
  public openDetailedMaintenance;
  public closedDetailedMaintenance;
  public activeDetailedMaintenance;
  public doubleClickFlagTimeStamp = 0; // JIRA ID :-669 Added by Abhilash
  public singleClickFlagTime = 0; // JIRA ID :-669 Added by Abhilash
  public selectLidar = false;
  public modifierConfig;
  //Added for Visualization layers
  public showVisualizationDetails;
  public visualizationLyrData: any;
  @Input() map: any;
  //
  // <!--TSDRSAM-610,TSDRSAM-611 By Mani -->
  public systemManagedFields = ["OBJECTID", "GlobalID"];
  @Output() public emitOpenDetailedMaintenance: EventEmitter<any> = new EventEmitter();
  @Output() public emitClosedDetailedMaintenance: EventEmitter<any> = new EventEmitter();
  @Output() public emitActiveDetailedMaintenance: EventEmitter<any> = new EventEmitter();

  @Output() public emitSelectedFeaturesOnMap: EventEmitter<any> = new EventEmitter();
  @Output() public emitSelectedLayerIndexOnData: EventEmitter<any> = new EventEmitter();
  @Output() public emitSelectedParentIndexOnData: EventEmitter<any> = new EventEmitter();
  @Output() public emitSelectedChildIndexOnData: EventEmitter<any> = new EventEmitter();
  // Added by Sowjanya Jira Id:-24 on 11/02/2021
  @Output() public eventassessmentnewAssessment: EventEmitter<any> = new EventEmitter();

  @Output() public emitShowGrid: EventEmitter<any> = new EventEmitter();
  @Output() public emitShowCard: EventEmitter<any> = new EventEmitter();
  @Output() public emitMaintenanceView: EventEmitter<any> = new EventEmitter();

  @Output() public emitTaskCreated: EventEmitter<any> = new EventEmitter();
  @Output() public emitTaskEdited: EventEmitter<any> = new EventEmitter();
  @Output() public emitTaskCompleted: EventEmitter<any> = new EventEmitter();

  @Output() public emitactiveAssetChangedBySelect: EventEmitter<any> = new EventEmitter();
  @Output() public emitactiveAssetFeature: EventEmitter<any> = new EventEmitter();

  @Output() public emitreplaceTaskCreated: EventEmitter<any> = new EventEmitter();
  @Output() public emitreshapeTaskCreated: EventEmitter<any> = new EventEmitter();
  @Output() public emitchildDeleted: EventEmitter<any> = new EventEmitter();
  @Output() public emittaskRollback: EventEmitter<any> = new EventEmitter();
  // JIRA ID :-35 Added by Madhuri
  @Output() public emitEnableSingleClick: EventEmitter<any> = new EventEmitter();

  @Output() public emitCloseModel: EventEmitter<any> = new EventEmitter();

  @Output() public emitTaskCreateFormOpened: EventEmitter<any> = new EventEmitter();

  //Added for Visualization layers
  @Output() public emitShowVisualizationDetails: EventEmitter<any> = new EventEmitter();
  @Output() public emitSetVisualizationAssetViewer: EventEmitter<any> = new EventEmitter();
  // lidar data
  @Output() public emitToggleLidar: EventEmitter<lidarData> = new EventEmitter();

  constructor(
    public genericMapMethodsService: GenericMapMethodsService,
    public toast: ToastrService,
    public objGlobals: Globals, // JIRA ID :-35 Added by Abhilash
    //Added for Visualization layers,
    private sanitizer: DomSanitizer,
    private genericLayerService: GenericLayerService,
    private queryLayerService: QueryLayers
  ) {
    this.genericMapMethodsService.emitSelectedGraphicsOnMap.subscribe((result) => {
      this.setSelectedLayerIndexOnData(result[0], result[1]);
      this.setShowCard(true);
    });
  }

  public setSelectedFeaturesOnMap(selectedFeaturesOnMap, layerIndex?, parentIndex?): void {
    if (selectedFeaturesOnMap && selectedFeaturesOnMap.length > 0) {
      this.selectedFeaturesOnMap = selectedFeaturesOnMap;

      if (layerIndex !== null && parentIndex !== null && layerIndex !== undefined && parentIndex !== undefined) {
        this.selectedLayerIndexOnData = layerIndex;
        this.selectedParentIndexOnData = parentIndex;
      } else {
        this.selectedLayerIndexOnData = 0;
        this.selectedParentIndexOnData = 0;
      }

      this.selectedChildIndexOnData = null;
      this.emitSelectedFeaturesOnMap.emit(this.selectedFeaturesOnMap);
      // Jira 871
      if (this.selectedFeaturesOnMap.length == 1) {
        if (this.selectedFeaturesOnMap[0].Data.length == 1) {
          this.setEnableSingleClick(true);
        } else {
          this.setEnableSingleClick(false);
        }
      } else {
        this.setEnableSingleClick(false);
      }
      this.genericMapMethodsService.getOpenTasks(this.selectedFeaturesOnMap);
      this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
    } else {
      this.selectedFeaturesOnMap = selectedFeaturesOnMap;
      this.emitSelectedFeaturesOnMap.emit(this.selectedFeaturesOnMap);
    }
  }
  public setSelectedLayerIndexOnData(index, parentIndex?): void {
    this.selectedLayerIndexOnData = index;
    // this.selectedParentIndexOnData = 0;

    if (parentIndex !== null && parentIndex !== undefined) {
      this.selectedParentIndexOnData = parentIndex;
    } else {
      //this.selectedLayerIndexOnData = 0;
      this.selectedParentIndexOnData = 0;
    }
    this.selectedChildIndexOnData = null;

    console.log("Layer Changed ");
    if (this.selectedFeaturesOnMap && this.selectedFeaturesOnMap.length > 0) {
      this.assitId = this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData].attributes.AssetID;
    }

    if (index == 0) {
      this.toggleCardView("Support");
    } else {
      this.toggleCardView("Others");
    }
    this.emitSelectedLayerIndexOnData.emit(this.selectedLayerIndexOnData);
    this.emitactiveAssetChangedBySelect.emit(true);
    if (this.selectedFeaturesOnMap && this.selectedFeaturesOnMap[this.selectedLayerIndexOnData])
      this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  public setSelectedParentIndexOnData(index: number): void {
    this.selectedParentIndexOnData = index;
    if (this.selectedFeaturesOnMap && this.selectedFeaturesOnMap.length > 0) {
      this.assitId = this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData].attributes.AssetID;
      this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
    }
    this.selectedChildIndexOnData = null;
    // this.selectedChildClass = null;
    this.emitSelectedParentIndexOnData.emit(this.selectedParentIndexOnData);
    this.emitactiveAssetChangedBySelect.emit(true);
  }
  public setSelectedChildIndexOnData(index: number, childClass): void {
    console.log("Child Changed ");
    this.selectedChildIndexOnData = index;
    this.selectedChildClass = childClass;
    if (childClass.includes("SIG")) {
      this.selectedChildClass = "Sign";
    } else {
      this.selectedChildClass = "Light";
    }
    this.assitId = childClass;

    this.emitSelectedChildIndexOnData.emit(this.selectedChildIndexOnData);
    this.emitTaskEdited.emit(true);
  }
  public setShowCard(showCard?: boolean): void {
    if (showCard === true || showCard === false) {
      this.showCard = showCard;
    } else {
      this.showCard = !this.showCard;
    }

    if (!this.showCard) {
      if (this.draw) {
        this.draw.finishDrawing();
        this.draw.deactivate();
        this.draw.deactivate();
      }
      if (this.mapclick) {
        this.mapclick.remove();
      }
    }
    this.emitShowCard.emit(this.showCard);
  }
  public setShowGrid(showGrid?: boolean): void {
    //TODO - style for this 259 +67
    (showGrid != this.showGrid || showGrid === undefined) ? this.viewChange = true : this.viewChange = false;
    if (showGrid === true || showGrid === false) {
      this.showGrid = showGrid;
      if(this.viewChange == true){
      if(showGrid === true){
        this.numOfOpenPanels += 1
      }else{
        this.numOfOpenPanels -= 1;
      }
    }
    }else {
      this.showGrid = !this.showGrid;
    }
    this.emitShowGrid.emit(this.showGrid);
  }
  public setMaintenanceView(value?: boolean): void {
    //TODO - style for this
    // 259 + 67
    value != this.maintenanceView ? this.viewChange = true : this.viewChange = false;

    if (value === true || value === false) {
      this.maintenanceView = value;
      if(this.viewChange == true){
      if(value === true){
        this.numOfOpenPanels += 1;
      }else{
        this.numOfOpenPanels -= 1;
      }
    }
    } else {
      this.maintenanceView = !this.maintenanceView;
    }

    this.emitMaintenanceView.emit(this.maintenanceView);
  }
  public emitTaskUpdate(): void {
    this.emitTaskEdited.emit(true);
    this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public emitTaskAdded(value): void {
    this.emitTaskCreated.emit(true);
    if (this.selectedFeaturesOnMap && this.selectedFeaturesOnMap.length)
      this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  public setActiveAsset(active): void {
    this.activeAssetChangedBySelect = active;
    this.emitactiveAssetChangedBySelect.emit(this.activeAssetChangedBySelect);
  }
  public setActiveAssetFeature(feature): void {
    this.activeAssetFeature = feature;
    this.emitactiveAssetFeature.emit(this.activeAssetFeature);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setreplaceTaskCreated(data?: any): void {
    this.replaceTaskCreated = this.activeAssetFeature;
    this.emitreplaceTaskCreated.emit(this.replaceTaskCreated);
    if (this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData].attributes.OBJECTID == this.replaceTaskCreated[0].OBJECTID) {
      if (!this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer.queryFeatures) {
        this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer = this.replaceTaskCreated[1].lyrObj;
      }
    }
    this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }

  public setEmitTaskRollback(value): void {
    this.emittaskRollback.emit(value);

    if (this.replaceTaskCreated && this.replaceTaskCreated[0] && this.selectedFeaturesOnMap) {
      if (this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData].attributes.OBJECTID == this.replaceTaskCreated[0].OBJECTID) {
        if (!this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer.queryFeatures) {
          this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer = this.replaceTaskCreated[1].lyrObj;
        }
      }
    }

    this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setreshapeTaskCreated(data?: any): void {
    this.reshapeTaskCreated = this.activeAssetFeature;
    this.emitreshapeTaskCreated.emit(this.reshapeTaskCreated);
    if (this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData].attributes.OBJECTID == this.reshapeTaskCreated[0].OBJECTID) {
      if (!this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer.queryFeatures) {
        this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData]._layer = this.reshapeTaskCreated[1].lyrObj;
      }
    }
    this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setchildDeleted(data?: any): void {
    this.emitchildDeleted.emit(true);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public settaskCompleted(data?: any): void {
    this.emitTaskCompleted.emit(true);
    this.genericMapMethodsService.selectActiveAssetOnMap(this.selectedFeaturesOnMap[this.selectedLayerIndexOnData].Data[this.selectedParentIndexOnData], this.activeAssetFeature);
  }
  public closeModelWindow(): void {
    this.emitCloseModel.emit(true);
  }

  toggleCardView(SelectedlayerName, selectedShildRecordsCount?): void {
    // if(SelectedlayerName == 'Support'){
    //     this.toggleParent = false;
    //     this.toggleLight = true;
    //     this.toggleSign = true;
    // }
    // else if(SelectedlayerName == 'SupportToSign' || SelectedlayerName == 'Sign'){
    //     this.toggleParent = true;
    //     this.toggleLight = true;
    //     this.toggleSign = false;
    //     this.selectedChildClass = "Sign";
    // }
    // else if(SelectedlayerName == 'SupportToLight' || SelectedlayerName == 'Light'){
    //     this.toggleParent = true;
    //     this.toggleLight = false;
    //     this.toggleSign = true;
    //     this.selectedChildClass = "Light";
    // }
    // else{
    //     this.toggleParent = false;
    //     this.toggleLight = true;
    //     this.toggleSign = true;
    // }

    // if(selectedShildRecordsCount == 0){
    //     this.toggleParent = false;
    // }
    // if(selectedShildRecordsCount > 0){
    //     this.toggleParent = true;
    // }

    if (SelectedlayerName == "Support") {
      this.toggleParent = false;
      this.toggleLight = false;
      this.toggleSign = false;
    } else if (SelectedlayerName == "SupportToSign" || SelectedlayerName == "Sign") {
      this.toggleParent = false;
      this.toggleLight = false;
      this.toggleSign = false;
      this.selectedChildClass = "Sign";
    } else if (SelectedlayerName == "SupportToLight" || SelectedlayerName == "Light") {
      this.toggleParent = false;
      this.toggleLight = false;
      this.toggleSign = false;
      this.selectedChildClass = "Light";
    } else {
      this.toggleParent = false;
      this.toggleLight = false;
      this.toggleSign = false;
    }

    if (selectedShildRecordsCount == 0) {
      this.toggleParent = false;
    }
    if (selectedShildRecordsCount > 0) {
      this.toggleParent = false;
    }
  }

  public disablebutton(): void {
    const buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      (button as HTMLButtonElement).disabled = true;
    }
  }
  public enablebutton(): void {
    const buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      if (button.id != "assetsavebtn") (button as HTMLButtonElement).disabled = false;
    }
  }
  public getCalculatedReplaceYear(layerName, feature): void {
    let replaceYear = null;
    this.baseLifeValuesData.features.filter(
      function (item) {
        // TSDRSAM-671
        if (item.attributes.AssetClass.toUpperCase() == layerName.toUpperCase() && feature.attributes[item.attributes.BLF_Column] == item.attributes.BLF_Domain_Code) {
          replaceYear = new Date(feature.attributes.InstallDate).getFullYear() + item.attributes.BaseLife;

          this.modifierValueData.features.filter(
            function (item) {
              if (item.attributes.AssetClass.toUpperCase() == layerName.toUpperCase() && feature.attributes[item.attributes.BLF_Column] == item.attributes.BLF_Domain_Code) {
                // for bug TSDRSAM-1112 by Manikantha
                if (this.modifierConfig != null) {
                  const columnNameRow = this.modifierConfig.features.filter((config) => {
                    if (config.attributes.AssetClass.toUpperCase() == layerName.toUpperCase() && config.attributes.Modifier_Domain == item.attributes.Modifier_Domain) {
                      return item;
                    }
                  })[0];
                  if (columnNameRow && feature.attributes[columnNameRow.attributes.Modifier_Column] == item.attributes.Modifier_Domain_Code) {
                    replaceYear += item.attributes.Adjust_Years;
                  }
                }
              }
            }.bind(this)
          );
        }
      }.bind(this)
    );
    console.log(replaceYear);
    return replaceYear;
  }

  // Added by Madhuri Gupta for JIRA ID:-35 on 08/05/2021
  public setEnableSingleClick(value: boolean): void {
    if (value) {
      if (!this.singleClickOnMapEvt) {
        this.addMapSingleClickEvent();
      }
    } else {
      if (this.singleClickOnMapEvt) {
        this.singleClickOnMapEvt.remove();
        this.singleClickOnMapEvt = undefined;
      }
    }
  }

  // Added by Madhuri Gupta for JIRA ID:-35 on 08/05/2021
  // Added for Double click to Zoom to map And Single Click selection
  public addMapSingleClickEvent(): void {
    this.singleClickOnMapEvt = this.genericMapMethodsService.map.on(
      "click",
      function (evt) {
        this.TappedPoint = evt.mapPoint;
        if (this.singleClickFlagTime === 0) {
          this.singleClickFlagTime = evt.timeStamp;
        }
        if (this.singleClickFlagTime) {
          this.doubleClickFlagTimeStamp = evt.timeStamp;
        }
        if (this.doubleClickFlagTimeStamp - this.singleClickFlagTime > 250) {
          this.selectFeatureOnMap();
          this.doubleClickFlagTimeStamp = 0;
          this.singleClickFlagTime = 0;
        } else {
          setTimeout(() => {
            if (this.singleClickFlagTime === this.doubleClickFlagTimeStamp && this.singleClickFlagTime != 0 && this.doubleClickFlagTimeStamp != 0) {
              this.selectFeatureOnMap();
            }
            this.doubleClickFlagTimeStamp = 0;
            this.singleClickFlagTime = 0;
          }, 400);
        }
      }.bind(this)
    );
  }

  // Added by Madhuri Gupta for JIRA ID:-35 on 08/05/2021
  public selectFeatureOnMap(): void {
    this.genericMapMethodsService.map.enableMapNavigation();
    // Clear all graphics before selection
    this.genericMapMethodsService.map.graphicsLayerIds.forEach(
      function (id) {
        this.genericMapMethodsService.map.getLayer(id).clear();
      }.bind(this)
    );
    if (this.viewLidarData === true){
      const buffer = this.genericMapMethodsService.getBuffer(this.TappedPoint, 100, "meters");
      if (buffer.rings && buffer.rings[0]) {
        let extent = buffer.getExtent();
        let minX = extent.xmin;
        let minY = extent.ymin;
        let maxX = extent.xmax;
        let maxY = extent.ymax;
        this.queryLayerService.getLidarAssets(maxX, minX, maxY, minY).subscribe(res => {
          if(res != null && res.features != null && res.features[0] != null){
            const closestLidarRun = res.features[0].attributes;
            return this.toggleLidarIframe(this.TappedPoint.y, this.TappedPoint.x, closestLidarRun.Custom1,  closestLidarRun.Custom2,  closestLidarRun.Custom3);
          }else{
            this.toast.info("No lidar data available near the selected point.")
          }
        });
      }
    }else{
    // Query Data in Selected Geometry
    const query = new this.genericMapMethodsService.Query();

    //Generate buffer of mouse location
    // query.geometry = this.TappedPoint;

    // Added by Manikantha V for JIRA ID:-35 on 11/09/2021
    // For single click buffer units in pixel
    query.geometry = this.TappedPoint; // this.pointToExtent(this.genericMapMethodsService.map, this.TappedPoint, this.objGlobals.toleranceDistance);
    query.distance = this.calculateDistanceFromPixels();
    // Map units will be considered for Query Distance
    const flRequests = [];
    this.queriedLayers = [];
    this.listSelectData = [];

    this.inventoryLayersData.forEach((lyr, i) => {
      if (lyr.visible) {
        const ftrLayer = lyr.lyrObj;
        flRequests.push(ftrLayer.selectFeatures(query));
        this.queriedLayers.push(lyr);
      }
    });
    //Get buffer geometry
    const buffer = this.genericMapMethodsService.getBuffer(this.TappedPoint, query.distance, "meters");
    let coordinateString;
    let allSelectedData = [];
    if (buffer.rings && buffer.rings[0]) {
      let extent = buffer.getExtent();
      let minX = extent.xmin;
      let minY = extent.ymin;
      let maxX = extent.xmax;
      let maxY = extent.ymax;
      let bBoxString = minX.toString() + "," + minY.toString() + "," + maxX.toString() + "," + maxY.toString();
      coordinateString = "bbox=" + bBoxString + ",'EPSG:3857'";
    }


    //Null\undefined handled added by Harshal K
    if (this.genericMapMethodsService.map.visualizationLayers) {
      this.genericMapMethodsService.map.visualizationLayers.forEach((lyr) => {
        if (lyr.visible) {
          let lastIndex = lyr.wmsUrl.lastIndexOf("/");
          if (
            lyr.wmsUrl
              .substring(lastIndex + 1)
              .toLowerCase()
              .includes("wms")
          ) {
            let wfsUrl = lyr.wmsUrl.substring(0, lastIndex + 2) + "f" + lyr.wmsUrl.substring(lastIndex + 3); // wms => wfs
            lyr.originalLayerTitle = lyr.originalLayerTitle;
            let getFeatures = wfsUrl + "?service=WFS&REQUEST=GetFeature&VERSION=1.0.0&typeName=" + lyr.originalLayerTitle + "&" + coordinateString + "&srsName='EPSG:3857'";
            allSelectedData.push(this.getSelectedWFSData(getFeatures, lyr.originalLayerTitle, false));
          }
        }
      });
    }

    if (flRequests.length > 0) {
      this.genericMapMethodsService.all(flRequests).then(
        function (results) {
          results.forEach((element, i) => {
            if (element.length > 0) {
              element.forEach((data) => {
                const assetData = [];
                assetData.push(data);
                this.listSelectData.push({
                  LayerName: this.queriedLayers[i],
                  Data: assetData
                });
              });
            }
          });
          if (allSelectedData.length > 0) {
            this.genericMapMethodsService.all(allSelectedData).then(
              function (visualizationSelectedData) {
                this.getSelectedVisualizationData(visualizationSelectedData).forEach((data) => {
                  this.listSelectData.push(data);
                });
                this.prepareCardView(this.listSelectData, visualizationSelectedData);
              }.bind(this)
            );
          } else {
            this.prepareCardView(this.listSelectData, []);
          }
        }.bind(this)
      );
    } else {
      if (allSelectedData.length > 0) {
        this.genericMapMethodsService.all(allSelectedData).then(
          function (visualizationSelectedData) {
            this.getSelectedVisualizationData(visualizationSelectedData).forEach((data) => {
              this.listSelectData.push(data);
            });
            this.prepareCardView(this.listSelectData, visualizationSelectedData);
          }.bind(this)
        );
      }
    }
  }
  }
  private calculateDistanceFromPixels() {
    const tolerance = this.objGlobals.toleranceDistance;
    const screenPoint = this.genericMapMethodsService.map.toScreen(this.genericMapMethodsService.map.extent.getCenter());
    const upperLeftScreenPoint = new this.genericMapMethodsService.Point(screenPoint.x - tolerance, screenPoint.y - tolerance);
    const lowerRightScreenPoint = new this.genericMapMethodsService.Point(screenPoint.x + tolerance, screenPoint.y + tolerance);
    const upperLeftMapPoint = this.genericMapMethodsService.map.toMap(upperLeftScreenPoint);
    const lowerRightMapPoint = this.genericMapMethodsService.map.toMap(lowerRightScreenPoint);
    const ext = new this.genericMapMethodsService.Extent(upperLeftMapPoint.x, upperLeftMapPoint.y, lowerRightMapPoint.x, lowerRightMapPoint.y, this.genericMapMethodsService.map.spatialReference);
    console.log(ext.getWidth() / 2);
    return ext.getWidth() / 2;
  }

  public getUpdatedDetailedMaintenance(condition: any, requettype: string): void {
    this.genericMapMethodsService.getDetailedMaintenance(condition).subscribe((jsonObj) => {
      if (requettype === "Open") {
        this.openDetailedMaintenance = jsonObj;
        this.emitOpenDetailedMaintenance.emit(this.openDetailedMaintenance);
      } else if (requettype === "Closed") {
        this.closedDetailedMaintenance = jsonObj;
        this.emitClosedDetailedMaintenance.emit(this.closedDetailedMaintenance);
      } else if (requettype === "Active") {
        this.activeDetailedMaintenance = jsonObj;
        this.emitActiveDetailedMaintenance.emit(this.activeDetailedMaintenance);
      }
    });
  }

  // Added by Sowjanya Jira Id:-24 on 11/02/2021
  public setEventAssessment(value) {
    this.eventassessmentnewAssessment.emit(value);
  }
  //TSDRSAM 1109
  public selectAssetforTasks(AssetID, event) {
    const query = new this.genericMapMethodsService.Query();
    query.where = "AssetID = '" + AssetID + "'";
    const flRequests = [];
    this.queriedLayers = [];
    this.listSelectData = [];

    this.inventoryLayersData.forEach((lyr, i) => {
      if (lyr.visible) {
        const ftrLayer = lyr.lyrObj;
        flRequests.push(ftrLayer.selectFeatures(query));
        this.queriedLayers.push(lyr);
      }
    });
    if (flRequests.length > 0) {
      this.genericMapMethodsService.all(flRequests).then(
        function (results) {
          results.forEach((element, i) => {
            if (element.length > 0) {
              element.forEach((data) => {
                const assetData = [];
                assetData.push(data);
                this.listSelectData.push({
                  LayerName: this.queriedLayers[i],
                  Data: assetData
                });
              });
            }
          });
          const pointData = [];
          const polylineData = [];
          const polygonData = [];
          this.rowdataobj = [];
          this.listSelectData.forEach((row) => {
            const geometryType = row.LayerName.lyrObj.geometryType;
            switch (geometryType) {
              case "esriGeometryPoint":
                pointData.push(row);
                break;
              case "esriGeometryPolyline":
                polylineData.push(row);
                break;
              case "esriGeometryPolygon":
                polygonData.push(row);
                break;
            }
          });
          //  combine data based on type

          this.rowdataobj = [].concat(pointData, polylineData, polygonData);
          if (this.rowdataobj.length > 0) {
            this.selecteddata = [];
            this.selecteddata.push(this.rowdataobj[0]);
            if (this.selecteddata.length > 0) {
              this.setSelectedFeaturesOnMap(this.selecteddata);
              this.setEnableSingleClick(false);
              let childClass;
              if (event[0] && event[0].AssetID) {
                childClass = event[0].AssetID;
                this.selectedChildIndexOnData = event[0].OBJECTID;
              } else {
                childClass = event.graphic.attributes.AssetID;
                this.selectedChildIndexOnData = event.graphic.attributes.OBJECTID;
              }
              this.selectedChildClass = childClass;

              if (childClass.includes("SIG")) {
                this.selectedChildClass = "Sign";
              } else if (childClass.includes("LGT")) {
                this.selectedChildClass = "Light";
              }
              this.assitId = childClass;

              //  this.emitSelectedChildIndexOnData.emit(this.selectedChildIndexOnData);
              //  this.emitTaskEdited.emit(true);
            }
            if (this.selecteddata.length > 0) {
              this.setShowCard(true);
            }
          } else {
            this.setShowCard(false);
            //    this.genericMapMethodsService.map.graphics.clear();
          }
        }.bind(this)
      );
    }
  }
  // JTSDRSAM-1099 Manikantha V
  selectParentBeforeRelocate(attributes): void {
    try {
      if (this.singleClickOnMapEvt) {
        this.genericMapMethodsService.map.enableMapNavigation();
        // Clear all graphics before selection
        this.genericMapMethodsService.map.graphicsLayerIds.forEach(
          function (id) {
            this.genericMapMethodsService.map.getLayer(id).clear();
          }.bind(this)
        );
        // Query Data in Selected Geometry
        const query = new this.genericMapMethodsService.Query();
        query.where = "AssetID" + "='" + attributes.ParentID + "'";
        const flRequests = [];
        this.queriedLayers = [];
        this.listSelectData = [];
        this.inventoryLayersData.forEach((lyr, i) => {
          if (lyr.visible) {
            const ftrLayer = lyr.lyrObj;
            flRequests.push(ftrLayer.selectFeatures(query));
            this.queriedLayers.push(lyr);
          }
        });
        if (flRequests.length > 0) {
          this.genericMapMethodsService.all(flRequests).then(
            function (results) {
              results.forEach((element, i) => {
                if (element.length > 0) {
                  element.forEach((data) => {
                    const assetData = [];
                    assetData.push(data);
                    this.listSelectData.push({
                      LayerName: this.queriedLayers[i],
                      Data: assetData
                    });
                  });
                }
              });
              const pointData = [];
              const polylineData = [];
              const polygonData = [];
              this.rowdataobj = [];
              this.listSelectData.forEach((row) => {
                const geometryType = row.LayerName.lyrObj.geometryType;
                switch (geometryType) {
                  case "esriGeometryPoint":
                    pointData.push(row);
                    break;
                  case "esriGeometryPolyline":
                    polylineData.push(row);
                    break;
                  case "esriGeometryPolygon":
                    polygonData.push(row);
                    break;
                }
              });
              //  combine data based on type
              this.rowdataobj = [].concat(pointData, polylineData, polygonData);
              if (this.rowdataobj.length > 0) {
                this.selecteddata = [];
                this.selecteddata.push(this.rowdataobj[0]);
                if (this.selecteddata.length > 0) {
                  this.setSelectedFeaturesOnMap(this.selecteddata);
                }
                if (this.selecteddata.length > 0) {
                  this.setShowCard(true);
                }
              } else {
                this.setShowCard(false);
                this.genericMapMethodsService.map.graphics.clear();
                this.genericMapMethodsService.relocateChild = false;
              }
            }.bind(this)
          );
        }
      } else {
        let selected;
        let index;
        let selectedLayer;
        for (let i = 0; i < this.selectedFeaturesOnMap.length; i++) {
          selected = this.selectedFeaturesOnMap[i].Data.find((d) => d.attributes.AssetID === attributes.ParentID);
          index = this.selectedFeaturesOnMap[i].Data.findIndex((d) => d.attributes.AssetID === attributes.ParentID);
          selectedLayer = i;
          if (selected) {
            break;
          }
        }
        if (index >= 0 && selectedLayer >= 0) {
          this.setSelectedLayerIndexOnData(selectedLayer, index);
          this.setShowCard(true);
        }
      }
      console.log(attributes);
    } catch (error) {
      console.error(error);
    }
  }

  public createTaskOpened(isOpen: any): void {
    this.emitTaskCreateFormOpened.emit(isOpen);
  }

  //TSDRSAM-1788
  public setShowVisualizationAssetDetails(showDetails, activeVisualizationAsset, selectedLayerData): void {
    this.emitShowVisualizationDetails.emit({ selectedLayer: showDetails, isVisualizationAsset: activeVisualizationAsset, currentLayerData: selectedLayerData });
  }

  public setVisualizationAssetViewer(data): void {
    this.visualizationLyrData = data;
    this.emitSetVisualizationAssetViewer.emit(this.visualizationLyrData);
  }

  public emitWFSData(visualizationSelectedData) {
    let finalData = [];
    visualizationSelectedData.forEach((layerData) => {
      layerData.forEach((dataArray) => {
        finalData.push(dataArray);
      });
    });
    this.setVisualizationAssetViewer(finalData);
  }

  public getSelectedWFSData(getFeaturesUrl, layerTitle, isPost, postBody?: any) {
    try {
      return new Promise(
        function (resolve) {
          if (isPost) {
            this.genericLayerService.postWMSLayers(getFeaturesUrl, postBody).subscribe(
              (res: any) => {
                this.processWFSData(res, layerTitle, resolve);
              },
              (err) => {
                console.log(err);
                resolve([]);
              }
            );
          } else {
            this.genericLayerService.getWMSLayers(getFeaturesUrl).subscribe(
              (res: any) => {
                this.processWFSData(res, layerTitle, resolve);
              },
              (err) => {
                console.log(err);
                resolve([]);
              }
            );
          }
        }.bind(this)
      );
    } catch (error) {
      console.error(error);
    }
  }

  processWFSData(res, layerTitle, resolve) {
    // return new Promise(
    //  function (resolve) {
    let wfsData = [];
    let parser = new xml2js.Parser({ trim: true, explicitArray: true });
    if (res) {
      parser.parseString(res, (err, result) => {
        if (result && result.hasOwnProperty("wfs:FeatureCollection") && result["wfs:FeatureCollection"].hasOwnProperty("gml:featureMember")) {
          result["wfs:FeatureCollection"]["gml:featureMember"].forEach((element) => {
            let currentAssetData = [];
            let currentAssetGeometry;
            let currentAssetKey = Object.keys(element)[0];
            if (currentAssetKey) {
              let dataJson = element[currentAssetKey][0];
              let dataKeys = Object.keys(dataJson);
              dataKeys.forEach((key) => {
                if (key != "$") {
                  let fieldName = key.split(":")[1];
                  let fieldValue = dataJson[key][0];
                  //convert gml geometry to esri geometry
                  if (fieldName == "Shape") {
                    let key = Object.keys(fieldValue);
                    if (key.length == 0) {
                      return;
                    }
                    if (key[0] == "gml:MultiLineString") {
                      let geomArray = fieldValue[key[0]][0];
                      for (let array in geomArray) {
                        if (array == "gml:lineStringMember") {
                          let geomObject = geomArray["gml:lineStringMember"][0];
                          let coordinatesArray = geomObject["gml:LineString"][0]["gml:coordinates"];
                          currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                          break;
                        }
                      }
                    } else if (key[0] == "gml:LineString") {
                      let coordinatesArray = fieldValue[key[0]][0]["gml:coordinates"];
                      currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                    } else if (key[0] == "gml:MultiPoint") {
                      let geomArray = fieldValue[key[0]][0];
                      for (let array in geomArray) {
                        if (array == "gml:pointMember") {
                          let geomObject = geomArray["gml:pointMember"][0];
                          let coordinatesArray = geomObject["gml:Point"][0]["gml:coordinates"];
                          currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                          break;
                        }
                      }
                    } else if (key[0] == "gml:Point") {
                      let coordinatesArray = fieldValue[key[0]][0]["gml:coordinates"];
                      currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                    } else if (key[0] == "gml:MultiPolygon") {
                      let geomArray = fieldValue[key[0]][0];
                      for (let array in geomArray) {
                        if (array == "gml:polygonMember") {
                          let geomObject = geomArray["gml:polygonMember"][0];
                          let coordinatesArray = geomObject["gml:Polygon"][0]["gml:outerBoundaryIs"][0]["gml:LinearRing"][0]["gml:coordinates"];
                          currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                          break;
                        }
                      }
                    } else if (key[0] == "gml:Polygon") {
                      let coordinatesArray = fieldValue[key[0]][0]["gml:outerBoundaryIs"][0]["gml:LinearRing"][0]["gml:coordinates"];
                      currentAssetGeometry = this.genericMapMethodsService.getGMLPointGeometry(coordinatesArray[0]);
                    }
                  } else {
                    currentAssetData.push({ Label: fieldName, Value: fieldValue });
                  }
                }
              });
            }
            if (currentAssetData.length > 0) {
              let assetData = {};
              assetData["attributes"] = currentAssetData;
              assetData["geometry"] = currentAssetGeometry;
              wfsData.push(assetData);
            }
          });
          //
          let selectedData = [];
          if (wfsData.length > 0) {
            selectedData.push({
              LayerName: layerTitle,
              Data: wfsData
            });
          }
          resolve(selectedData);
          console.log(selectedData);
        } else {
          resolve([]);
        }
      });
    } else {
      resolve([]);
    }
    // }.bind(this)

    //   );
  }

  getSelectedVisualizationData(visualizationSelectedData) {
    let selectedData = [];
    visualizationSelectedData.forEach((layerData) => {
      layerData.forEach((dataArray) => {
        selectedData.push({
          LayerName: { layerName: dataArray.LayerName, tableSL: dataArray.LayerName, tableAlias: dataArray.LayerName },
          Data: dataArray.Data
        });
      });
    });
    return selectedData;
  }

  updateActiveAsset(geometry) {
    //remove previous active asset
    this.removePreviousActiveAsset();
    //add new active asset
    this.genericMapMethodsService.setActiveVisualizationAsset(geometry);
  }

  public removePreviousActiveAsset() {
    let oldActiveAssetIndex = this.genericMapMethodsService.map.graphics.graphics.findIndex((e) => e.attributes && e.attributes.isActiveAsset);
    if (oldActiveAssetIndex > -1) {
      let activeAssetGraphic = this.genericMapMethodsService.map.graphics.graphics[oldActiveAssetIndex];
      this.genericMapMethodsService.map.graphics.remove(activeAssetGraphic);
    }
  }

  prepareCardView(listSelectData, visualizationSelectData) {
    const pointData = [];
    const polylineData = [];
    const polygonData = [];
    let rowdataobj = [];
    listSelectData.forEach((row) => {
      let geometryType;
      let isVisualizationAsset = false;
      if (row.LayerName.lyrObj) {
        geometryType = row.LayerName.lyrObj.geometryType;
      } else {
        geometryType = row.Data[0].geometry.type;
        if (geometryType.toLowerCase().includes("point")) {
          geometryType = "point";
        } else if (geometryType.toLowerCase().includes("line")) {
          geometryType = "line";
        } else if (geometryType.toLowerCase().includes("polygon")) {
          geometryType = "polygon";
        }
        isVisualizationAsset = true;
      }
      switch (geometryType) {
        case "esriGeometryPoint":
        case "point":
          if (isVisualizationAsset) {
            pointData.push({ LayerName: row.LayerName, Data: [row.Data[0]] });
          } else {
            pointData.push(row);
          }
          break;
        case "esriGeometryPolyline":
        case "line":
          if (isVisualizationAsset) {
            polylineData.push({ LayerName: row.LayerName, Data: [row.Data[0]] });
          } else {
            polylineData.push(row);
          }
          break;
        case "esriGeometryPolygon":
        case "polygon":
          if (isVisualizationAsset) {
            polygonData.push({ LayerName: row.LayerName, Data: [row.Data[0]] });
          } else {
            polygonData.push(row);
          }
          break;
      }
    });
    //  combine data based on type
    rowdataobj = [].concat(pointData, polylineData, polygonData);
    if (rowdataobj.length > 0) {
      let selecteddata = [];
      selecteddata.push(rowdataobj[0]);
      if (selecteddata.length > 0) {
        // JTSDRSAM-1099 Manikantha V
        if (this.genericMapMethodsService.relocateChild) {
          if (selecteddata[0].LayerName.layerName == "Support") {
            this.selectedFeaturesOnMap = selecteddata;
            this.genericMapMethodsService.moveToNewParent(selecteddata[0].Data[0].attributes.AssetID, 0, 0);
            // this.setSelectedFeaturesOnMap(this.selecteddata);
          } else {
            Swal.fire("Please Select Support");
            this.genericMapMethodsService.relocateChild = false;
          }
        } else {
          this.setSelectedFeaturesOnMap(selecteddata);
          if (visualizationSelectData && visualizationSelectData.length > 0) {
            //Select Visualization assets
            this.emitWFSData(visualizationSelectData);
          }
        }
      }
      if (selecteddata.length > 0) {
        this.setShowCard(true);
      }
    } else {
      this.setShowCard(false);
      this.genericMapMethodsService.map.graphics.clear();
      // JTSDRSAM-1099 Manikantha V
      this.genericMapMethodsService.relocateChild = false;
    }
  }
  //lat and long come in as web mercator (x/y)
  toggleLidarIframe(Latitude: number, Longitude: number, database: string, project:string, run: string) {
    if (!this.showLidar) {
      const coords = this.genericMapMethodsService.convertXYToLatLng(Longitude, Latitude);
      this.lidarUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://3m.smartlidar.com/web/VirtualNavigator.html?db=${database}&project=${project}&run=${run}#lat=${coords[1]}&lon=${coords[0]}`);
    }
    this.showLidar = !this.showLidar;
  }
}
