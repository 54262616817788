<div class="card">
    <div class="card-body  admin-model-height formbg-d2">
        <sms-headAdmin></sms-headAdmin>
        <div class="row">
            <div class="col-md-12">
                <div class="row bg-white">
                    <form class="col-12 py-3 sumry_agency_Config">
                        <div class="form-group">
                            <h3 class="text-green mr-2">Please select choose file for upload: </h3>
                            
                            <input type="file" #InputFile accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                (change)="Resoucefiles($event)" multiple="false" aria-label="resource requirement file" />

                           
                            <!-- <input type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                #ResouceFile class="d-none" (change)="Resoucefiles(ResouceFile.files)">
                            <button type="button" class="btn btn-secondary" (click)="ResouceFile.click()">Resouce
                                File</button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-end mr-2"><h4 class="pt-2"> Please click... </h4><button [disabled]="!ResourceSaveButton" (click)="save()" class="btn btn-secondary ml-4"> Save</button>
    </div>
</div>