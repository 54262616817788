<div class="sms-model-height">
  <div class="card">
    <div class="card-header">
      <h3>
        {{ "Userscreens.bulkinsert.desctext1" | translate }} <br /><span class="badge badge-secondary">{{ "Userscreens.bulkinsert.desctext2" | translate }}</span>
      </h3>
    </div>
    <div class="card-body">
      <div class="container">
        <mat-form-field>
          <mat-label>{{ "Userscreens.bulkinsert.selectasset" | translate }}</mat-label>
          <mat-select [(ngModel)]="primaryAssetClassSelected" (ngModelChange)="onChange($event)">
            <mat-option [value]="assetServiceList.layerName" *ngFor="let assetServiceList of AssetServiceList">
              {{ showlang ? assetServiceList.tableSL : assetServiceList.tableAlias }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <form action="/api/upload" method="post" enctype="multipart/form-data" id="inputGroupFile01">
          <mat-form-field id="shpUploadDIv" (click)="binsertUpload.click()">
            <mat-label for="shpfile"> {{ "Userscreens.bulkinsert.selectshapefile" | translate }}</mat-label>
            <input id="shpfile" #binsertUpload type="file" accept=".zip" style="visibility: hidden" (change)="handleShapefile($event)" class="form-control-file" aria-label="inputGroupFileAddon01" matInput />
          </mat-form-field>
        </form>
        <mat-form-field [style.display]="showGridForRelatedAsset ? 'block' : 'none'">
          <mat-label>{{ "Userscreens.bulkinsert.selectrelatedasset" | translate }}</mat-label>
          <mat-select [(ngModel)]="relatedAssetClassSelected">
            <mat-option [value]="relatedAssetClassDropDown.layername" *ngFor="let relatedAssetClassDropDown of RelatedAssetClassDropDown">
              {{ relatedAssetClassDropDown.layername }}
            </mat-option>
          </mat-select>

          <div class="dropdown-icons">
            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
          </div>
        </mat-form-field>
        <form action="/api/upload" method="post" enctype="multipart/form-data" id="inputGroupFile02">
          <mat-form-field id="shpUploadDIv" (click)="binserUpload.click()">
            <mat-label for="csfile">{{ "Userscreens.bulkinsert.selectcsvassetdata" | translate }}</mat-label>
            <input id="csfile" #binserUpload type="file" accept=".csv" style="visibility: hidden" (change)="handleCSVFile($event)" class="form-control-file" aria-describedby="inputGroupFile02" matInput />
          </mat-form-field>
        </form>

        <mat-label>{{ "Userscreens.bulkinsert.commitrelateddatatodatabase" | translate }}</mat-label>
        <button mat-button id="CommitData" (click)="AddrelatedDataInService($event)">{{ "Userscreens.bulkinsert.commitdatabtn" | translate }}</button>

        <div id="col2" class="col colo">
          <div [style.display]="showTableAssetId ? 'block' : 'none'">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{{ "Userscreens.bulkinsert.objectid" | translate }}</th>
                  <th>{{ "Userscreens.bulkinsert.assetid" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let assetTableValue of AssetTableValue">
                  <td>
                    {{ assetTableValue.OBJECTID }}
                  </td>
                  <td>
                    {{ assetTableValue.AssetID }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
