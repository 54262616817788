import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MMMAdminService } from "src/app/administrator/administrator.reports.service";
import Swal from "sweetalert2";
import { adminGlobal } from "src/app/administrator/adminGlobal";
import { faPlusSquare, faMinusSquare, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
// import * as Ajv from 'ajv'

export interface languageRequest {
  languageName?: string;
  languageKey?: string;
  languageContent?: any;
}

@Component({
  selector: "sms-LangConfig",
  templateUrl: "./langconfig.component.html",
  styleUrls: ["./langconfig.component.scss"]
})
export class LangConfigComponent implements OnInit {
  // icons
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faQuestionCircle = faQuestionCircle;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  PALValues = [];
  fileext = false;
  fileToUpload: File = null;
  filename: string;
  Langselect;
  savedfiles;
  downloadfiles;
  downloadfileid;
  Keyselect;
  showLangList = true;
  Primarylangvalid: any[];
  Secodarylangvalid: any[];
  Primarylangvalid1: any[];
  Secodarylangvalid1: any[];
  languagecontent;
  langtest = false;
  filecontent;
  langname;
  // Uploadfile1;
  Uploadfile: languageRequest = {} as languageRequest;
  @Output() closeform = new EventEmitter();

  public Langkeys: any[] = [
    { Name: "English (en)", value: "en" },
    { Name: "Arabic (ar)", value: "ar" },
    { Name: "Bosnian (bs)", value: "bs" },
    { Name: "Simplified Chinese (zh-cn)", value: "zh-cn" },
    { Name: "Traditional Chinese (Hong Kong) (zh-hk) ", value: "zh-hk" },
    { Name: "Traditional Chinese (Taiwan) (zh-tw)", value: "zh-tw" },
    { Name: "Catalan (ca)", value: "ca" },
    { Name: "Croatian (hr)", value: "hr" },
    { Name: "Czech (cs)", value: "cs" },
    { Name: "Danish (da)", value: "da" },
    { Name: "Dutch (nl)", value: "nl" },
    { Name: "Estonian (et)", value: "et" },
    { Name: "Finnish (fi)", value: "fi" },
    { Name: "French (fr)", value: "fr" },
    { Name: "German (de)", value: "de" },
    { Name: "Greek (el)", value: "el" },
    { Name: "Hebrew (he)", value: "he" },
    { Name: "Hungarian (hu)", value: "hu" },
    { Name: "Indonesian (id)", value: "id" },
    { Name: "Italian (it)", value: "it" },
    { Name: "Japanese (ja)", value: "ja" },
    { Name: "Korean (ko)", value: "ko" },
    { Name: "Latvian (lv)", value: "lv" },
    { Name: "Lithuanian (lt)", value: "lt" },
    { Name: "Norwegian (nb)", value: "nb" },
    { Name: "Polish (pl)", value: "pl" },
    { Name: "Portuguese (Brazil) (pt-br)", value: "pt-br" },
    { Name: "Portuguese (Portugal) (pt-pt)", value: "pt-pt" },
    { Name: "Romanian (ro)", value: "ro" },
    { Name: "Russian (ru)", value: "ru" },
    { Name: "Serbian (sr)", value: "sr" },
    { Name: "Slovenian (sl)", value: "sl" },
    { Name: "Spanish (es)", value: "es" },
    { Name: "Swedish (sv)", value: "sv" },
    { Name: "Thai (th)", value: "th" },
    { Name: "Turkish (tr)", value: "tr" },
    { Name: "Ukrainian (uk)", value: "uk" },
    { Name: "Vietnamese (vi)", value: "vi" }
  ];

  constructor(private MMMAdminService: MMMAdminService, private adminGlobal: adminGlobal) {
    this.CallLanguage();
  }

  CallLanguage() {
    try {
      this.MMMAdminService.getAPILanguage().subscribe(
        (res: any[]) => {
          this.PALValues = res;
          this.adminGlobal.PALValues = [];
          this.adminGlobal.PALValues = res;
        },
        (err) => {
          console.log(err);
        }
      );
      this.MMMAdminService.getClientList().subscribe((res: any[]) => {
        this.Primarylangvalid = [];
        res.forEach((data) => {
          this.Primarylangvalid.push(data.primaryLanguage);

          if (data.secondaryLanguage !== null && data.secondaryLanguage !== 0) {
            this.Primarylangvalid.push(data.secondaryLanguage);
          }
        });
        this.Primarylangvalid = this.Primarylangvalid.filter((x, i, a) => a.indexOf(x) === i);
      });
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    this.MMMAdminService.getClientList().subscribe((res: any[]) => {
      this.Primarylangvalid = [];
      res.forEach((data) => {
        this.Primarylangvalid.push(data.primaryLanguage);

        if (data.secondaryLanguage !== null && data.secondaryLanguage !== 0) {
          this.Primarylangvalid.push(data.secondaryLanguage);
        }
      });
      this.Primarylangvalid = this.Primarylangvalid.filter((x, i, a) => a.indexOf(x) === i);
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    if (!this.validateFile(files[0].name)) {
      Swal.fire("Selected file format is not supported");
      this.fileToUpload = null;
      this.fileext = false;
    } else {
      this.fileext = true;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(this.fileToUpload, "UTF-8");

    fileReader.onload = () => {
      this.filecontent = fileReader.result;
    };
  }

  validateFile(name: string) {
    try {
      const ext = name.substring(name.lastIndexOf(".") + 1);

      if (ext.toLowerCase() === "json") {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  Langname(event) {
    try {
      this.langname = event.target.value;
      this.langtest = false;
      this.MMMAdminService.getAPILanguage().subscribe((res: any[]) => {
        res.forEach((data) => {
          if (data.languageName.includes(this.langname)) {
            this.langtest = true;
          } else if (!data.languageName.includes(this.langname)) {
            // this.langtest;
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  uploadFileToActivity() {
    try {
      const filecontent1 = JSON.parse(this.filecontent);
      this.Uploadfile.languageName = this.langname;
      this.Uploadfile.languageKey = this.Keyselect;
      this.Uploadfile.languageContent = filecontent1;
      this.MMMAdminService.postAPILanguage(this.Uploadfile).subscribe(
        (data) => {
          console.log(data);
          Swal.fire("File has been Uploaded Successfully");
          console.log("file upload");
          // this.closeform.emit(true);
          this.CallLanguage();
        },
        (error) => {
          console.log(error);
          Swal.fire("Error File Not Uploaded");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  Upload() {
    try {
      // this.langtest = false;
      this.MMMAdminService.getAPILanguage().subscribe(
        (res: any[]) => {
          res.forEach((data) => {
            if (data.languageName.includes(this.filename)) {
              this.langtest = true;
            } else if (!data.languageName.includes(this.filename)) {
              // this.langtest;
            }
          });
          // tslint:disable-next-line:no-string-literal
          this.filename = document.forms["frm"].languagename.value;

          if (this.filename.length < 1) {
            Swal.fire("Please Enter the Language Name");
          } else if (this.Keyselect == null) {
            Swal.fire("Please Enter Language Key");
          } else if (this.fileToUpload == null) {
            Swal.fire("Please Upload a file");
          } else if (this.fileext === false) {
            Swal.fire("Please Upload a supported file");
          } else if (this.langtest === false) {
            Swal.fire({
              title: "New language is about to be entered",
              showCancelButton: true,
              confirmButtonText: "Ok",
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.uploadFileToActivity();
              }
            });
          } else if (this.langtest === true) {
            Swal.fire({
              title: "Are you sure to overwrite existing language file",
              showCancelButton: true,
              confirmButtonText: "Yes",
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.uploadFileToActivity();
              }
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );

      // else {
      //     this.uploadFileToActivity();
      // }
    } catch (error) {
      console.error(error);
    }
  }

  Download() {
    try {
      if (!this.Langselect) {
        Swal.fire("Please select a file to download");
      } else {
        if (!this.downloadfiles) {
          this.MMMAdminService.getAPILanguage().subscribe((res: any) => {
            res.forEach((data) => {
              if (this.downloadfileid === data.languageID) {
                this.languagecontent = data.languageContent;
                const sJson = JSON.stringify(this.languagecontent, null, "\t");
                const element = document.createElement("a");
                element.setAttribute("href", "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
                element.setAttribute("download", this.Langselect + ".json");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click(); // simulate click
                document.body.removeChild(element);
                //const link = document.createElement("a");
              }
            });
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  Langselected(event) {
    try {
      this.Langselect = event.target.value;
      this.MMMAdminService.getAPILanguage().subscribe(
        (res: any[]) => {
          res.forEach((data) => {
            if (data.languageName === this.Langselect) {
              this.downloadfiles = data.fileName;
              this.downloadfileid = data.languageID;
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  Langkeyselected(event) {
    this.Keyselect = event.target.value;
  }

  Languagecondition() {
    this.showLangList = !this.showLangList;
  }

  DeleteLang() {
    try {
      // this.downloadfileid;
      if (this.downloadfileid == null) {
        Swal.fire("Please select a file to delete");
      } else if (!this.Primarylangvalid.includes(this.downloadfileid)) {
        Swal.fire({
          title: "Are you sure to delete a language file ?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.DeleteAPICall(this.downloadfileid);
          }
        });
      } else if (this.Primarylangvalid.includes(this.downloadfileid)) {
        Swal.fire("File Cannot be deleted because assigned to a client");
      }
    } catch (error) {
      console.error(error);
    }
  }

  DeleteAPICall(LangID) {
    try {
      this.MMMAdminService.deleteAPILanguage(LangID).subscribe(
        (data) => {
          console.log(data);
          Swal.fire("File has been Deleted Successfully");
          console.log("file Deleted");
          this.CallLanguage();
        },
        (error) => {
          console.log(error);
          // Swal.fire('Error File cannot be Deleted');
          this.CallLanguage();
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  closeModal() {
    try {
      document.getElementById("exampleModalCenter").style.display = "none";
    } catch (error) {
      console.error(error);
    }
  }
}
