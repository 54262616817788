import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { SMSHttpClient } from "../helpers/http-client";
import { IUser } from "../interface/user";
import { environment } from "src/environments/environment";
import { IRequestOptions } from "../interface/requestOption";
import { GISHttpClient } from "../helpers/gishttp-client";

@Injectable({
  providedIn: "root"
})
export class GenericLayerService {
  currentUser: IUser;

  constructor(private httpClient: GISHttpClient, private smshttpClient: SMSHttpClient, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  public responseType: IRequestOptions = { responseType: "text" as "json", headers: new HttpHeaders() };
  public xmlResponseType: IRequestOptions = { responseType: "xml" as "json", headers: new HttpHeaders() }; //Added by Abhilash for jira-677 (Visualization layers)
  //Added by Abhilash for jira-677 (Visualization layers)

  //10/27/20 jcc -- replace "https://ramuatonline.com/proxy/proxy.ashx?" with new environment.proxy throughout

  getAllFeatures(lyrId: number) {
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + environment.queryAllPostFixGeomFalse);
  }
  getAllFeaturesOrderby(lyrId: number) {
    console.log(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + environment.queryAllPostFixGeomFalse);
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + environment.queryAllPostFixGeomFalse + "&orderByFields=Worker,SheduledDate,Priority");
  }
  getUniqueValuesByField(lyrId: number, fieldName: string) {
    const urlPostFix = environment.queryDistinctValuesPostFix.replace("<fieldNames>", fieldName);
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlPostFix);
  }
  getFilteredFeaturesByField(lyrId: number, fieldName: string, value: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", fieldName + "='" + value + "'");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  getFilteredFeaturesByFieldWithGeom(lyrId: number, fieldName: string, value: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomTrue.replace("1=1", fieldName + "='" + value + "'");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  getFilteredFeaturesByObjectIdField(lyrId: number, fieldName: string, value: number) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", fieldName + "=" + value + "");
    console.log(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  getFilteredFeaturesByListOfValues(lyrId: number, fieldName: string, value: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", fieldName + " IN (" + value.toString() + ")");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }

  getRelatedRecords(lyrId: number, objectidlist: string, relationId: string) {
    return this.httpClient.get(
      environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + "/queryRelatedRecords?objectIds=" + objectidlist + "&relationshipId=" + relationId + "&outFields=*&f=pjson"
    );
  }
  getRecordsByIds(lyrId: number, objectidlist: string) {
    console.log(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + "/query?where=AssetID in (" + objectidlist + ")&outFields=*&f=pjson");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + "/query?where=AssetID in (" + objectidlist + ")&outFields=*&f=pjson");
  }
  getFilteredFeaturesByTwoField(lyrId: number, fieldName: string, value: string, fieldName1: string, value1: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", fieldName + "='" + value + "'" + " and " + fieldName1 + "='" + value1 + "'");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  getGroupByFeatures(lyrId: number, GroupByFields: string, OutputStatistics: string) {
    let Condition = "/query?where=1=1";
    Condition += "&groupByFieldsForStatistics=" + GroupByFields;
    Condition += "&outStatistics=" + OutputStatistics;
    // [{"statisticType": "count","onStatisticField": "Worker","outStatisticFieldName": "Count"}] ;
    // let urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace('1=1',fieldName+"='"+value+"'");
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + Condition);
  }
  getLayerInforBasedOnService() {
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.mapServer + "?f=pjson");
  }
  // getUserList(clientId) {
  //     return this.httpClient.get(environment.apiBaseUrl + environment.apiroot +
  //         environment.getClientlist + '/' + clientId + '/' + environment.getUserlist, {});
  // }
  // getUserLayersList(clientId) {
  //     console.log()
  //     return this.httpClient.get(environment.apiBaseUrl + environment.apiroot +
  //         environment.getClientlist + '/' + clientId + '/Layers', {});
  // }
  getDomainValues() {
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + "queryDomains?layers=&f=pjson");
  }
  postExpectedLife(UserId, Obj) {
    return this.smshttpClient.post(environment.ExpectedLife + UserId, Obj, this.responseType);
  }
  postHardDelete(ClientId, resultObj) {
    return this.smshttpClient.post("Clients/" + ClientId + "/HardDeletePermission", resultObj, this.responseType);
  }
  getServiceInfo(URLVal) {
    // Added for proxy
    if (!URLVal.includes(environment.proxy)) {
      URLVal = environment.proxy + URLVal;
    }

    return this.httpClient.get(URLVal + "?f=pjson");
  }
  SetExpectedLifeForNewAssets(UserId, AssetClassName, Obj) {
    return this.smshttpClient.post(environment.expectedLifeNewAsset + AssetClassName, Obj, this.responseType);
  }
  getUserList(clientId) {
    return this.smshttpClient.get(environment.getClientlist + "/" + clientId + "/" + environment.getUserlist);
  }
  getUserLayersList(clientId) {
    return this.smshttpClient.get(environment.getClientlist + "/" + clientId + "/Layers");
  }
  getQueryResults(queryString, functionName) {
    //queryString = encodeURI(queryString);
    //return this.smshttpClient.get(environment.queryBuilder + "?FunctionName=" + functionName + "&Param=" + queryString);

    //Request Type changed from GET to POST Jira:- 658
    const queryRequest = {
      FunctionName: functionName,
      Param: queryString
    };
    return this.smshttpClient.post(environment.queryBuilder, queryRequest);
  }

  getAPILanguage() {
    return this.smshttpClient.get(environment.getLanguages);
  }

  getuserAPILanguage() {
    console.log("lan1");
    return this.smshttpClient.get(environment.getLanguages + "/" + this.currentUser.languageID);
  }

  getClientList(Clientid) {
    return this.smshttpClient.get(environment.getClient + "/" + Clientid);
  }
  getFeaturesByCond(lyrId: number, cond: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", cond);
    return this.httpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  getOpentasks(object) {
    return this.smshttpClient.post(environment.getOpenTasks, object, this.responseType);
  }
  // Added by Sowjanya for JIRA ID:-66 on  08/19/2021
  getMaintainanceConfigInfo() {
    return this.getAllFeatures(this.currentUser.serviceLyrInfo.maintenanceConfigId);
  }
  // tasks related data
  getMaintananceTasks(object) {
    return this.smshttpClient.post(environment.getMaintananceTasks, object, this.responseType);
  }

  //Added by Sowjanya for TSDRSAM-71
  getLayerInforBasedOnServiceUrl(serviceUrl) {
    return this.httpClient.get(environment.proxy + serviceUrl + environment.mapServer + "?f=pjson");
  }

  //Added by Abhilash for jira-677 (Visualization layers)
  //TSDRSAM-1578 environment.proxy removed for wms url by Harshal k
  getWMSLayers(url) {
    return this.httpClient.get(url, this.xmlResponseType);
  }

  //Added for Visualization layers
  addorUpdateVisualizationLayers(obj) {
    return this.smshttpClient.post(environment.addorUpdateVisualizationLayers, obj);
  }
  getVisualizationLayers() {
    return this.smshttpClient.get(environment.getVisualizationLayers);
  }

  //TSDRSAM-1673 proxy removed - additional changes
  postWMSLayers(url, obj) {
    return this.httpClient.post(url, obj, this.xmlResponseType);
  }
}
