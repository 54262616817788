<!-- 8/10/20 jcc -- asset detail form using primary agency language if user account is associated with it. -->
<div *ngIf="activeAsset" style="height:100%" cdkDrag cdkDragBoundary="#cardViewDiv" class="avPanel pr-0" >
  <app-heading-component
    cdkDragHandle
    [headingText]="asstDtlTitle"
    [variant]="'none'"
    (actionOne)="minimizeAssetDetail()"
    [tooltipOne]="isShown == false ? expand : minimize"
    [iconOne]="isShown == false ? 'arrow-down' : 'arrow-up'"
    [tooltipTwo]="'Close'"
    [iconTwo]="'cross'"
    (actionTwo)="closeAssetDetail()"
  >
  </app-heading-component>

  <!-- 89.5 for double panel -->
  <div class="avDetailPanel overflow-hidden" id="avDetailPanelDiv" (mousedown)="$event.stopPropagation()">
    <mat-tab-group disablePagination="true" style="height:100%">
      <mat-tab class="asset-detail" style="height:100%" [attr.aria-label]="'Select.carddisplay.detailtab' | translate" [label]="'Select.carddisplay.detailtab' | translate">
        <div class="avRow" style="height:100%">
          <div *ngIf="activeAsset" style="height:100%" class="col-12 border-0 p-0 tab-content text-center">
            <div class="tab-pane p-0 border-top active" style="height:100%">
              <div class="card border-0" style="height:100%">
                <div *ngIf="!isVisualizationAsset" style="height:100%" class="scroll-detail avDetailScrollArea">
                  <div class="asset-detail-header">
                    <div style="display: flex" class="img-fields-container">
                      <div class="img-container">
                        <img
                          class="text-center justify-content-center align-self-center"
                          alt="{{ activeAsset[0]['AssetCode'] }}"
                          [src]="activeAsset[2]?.ImageBase64 != null ? activeAsset[2]?.ImageBase64 : activeAsset[7]?.ImageBase64"
                          (error)="onPicError($event)"
                        />
                      </div>
                      <div class="key-columns">
                        <span class="h7" *ngIf="fieldsInfo.length > 0 && fieldsInfo[0].codedValues">{{ activeAsset[0]["AssetCode"] }}</span>
                        <span class="h7" *ngIf="fieldsInfo.length > 0 && !fieldsInfo[0].codedValues">{{ activeAsset[0]["AssetCode"] }}</span>
                        <span class="xxsmall">{{ activeAsset[0]["AssetID"] }}</span>
                      </div>
                    </div>
                    <div class="lidar" (click)="loadLidarData()" *ngIf="currentUser.clientId === 509">
                      <a href="#" title="View Lidar Data" aria-label="View Lidar Data" class="i-inactive" data-mainPageTabbing="yes">
                        <mat-icon style="color:#323237;" Zclass="icon-new icon-new-select">radar</mat-icon>
                      </a>
                    </div>
                  </div>
                  <div *ngIf="fieldsInfo && defaultDisabledFields && defaultDisabledFields.length > 0">
                    <div *ngFor="let agencyList of fieldsInfo" class="w-100">
                      <div *ngIf="agencyList.type == 'esriFieldTypeString' && agencyList.fieldName == 'AssetCode' && assetCodeFrmAssetSubType.length > 0" class="w-100 special-dropdown">
                        <mat-form-field
                          [ngClass]="{
                            'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                          }"
                          appearance="outline"
                        >
                          <mat-label [attr.aria-label]="agencyList.label">
                            <span
                              [ngClass]="{
                                'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                              }"
                              >{{ scndlyr ? agencyList.labelSL : agencyList.label }}<span *ngIf="agencyList.IsRequired == 1">★</span></span
                            >
                          </mat-label>
                          <input matInput [(ngModel)]="activeAsset[0][agencyList.fieldName]" hidden="true" />
                          <ng-select
                            (ngModelChange)="getAssetDefaultValues($event)"
                            [(ngModel)]="activeAsset[0][agencyList.fieldName]"
                            [ngClass]="{
                              'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                            }"
                            [items]="assetCodeFrmAssetSubType"
                            bindLabel="name"
                            bindValue="code"
                            #selection="ngModel"
                            [searchFn]="customSearchFn"
                            class="w-100"
                            [disabled]="findFieldisDisable(agencyList.fieldName)"
                            style="margin-bottom: 2px; border: none; padding: 0px !important"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <span style="font-weight: bold; color: black" *ngIf="item.code && item.code.length > 1">{{ item.code }}</span
                              >&nbsp;<span>{{ item.name != item.code ? item.name.replace(item.code, "") : item.name }}</span>
                            </ng-template>
                          </ng-select>

                          <mat-error *ngIf="selection.hasError('required')"> This field is required </mat-error>
                        </mat-form-field>
                      </div>

                      <div *ngIf="agencyList.fieldName == 'AssetCode' && assetCodeFrmAssetSubType.length == 0" class="w-100 special-dropdown">
                        <mat-form-field
                          [ngClass]="{
                            'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                          }"
                          appearance="outline"
                        >
                          <mat-label [attr.aria-label]="agencyList.label">
                            <span
                              [ngClass]="{
                                'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                              }"
                              class="label-wrapper"
                              >{{ scndlyr ? agencyList.labelSL : agencyList.label }}<span *ngIf="agencyList.IsRequired == 1">★</span></span
                            >
                          </mat-label>
                          <input matInput [(ngModel)]="activeAsset[0][agencyList.fieldName]" hidden="true" />
                          <ng-select
                            [ngClass]="{
                              'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                            }"
                            (ngModelChange)="getAssetDefaultValues($event)"
                            [(ngModel)]="activeAsset[0][agencyList.fieldName]"
                            [items]="agencyList.codedValues"
                            bindLabel="name"
                            bindValue="code"
                            #selection="ngModel"
                            class="w-100"
                            [searchFn]="customSearchFn"
                            [disabled]="findFieldisDisable(agencyList.fieldName)"
                            style="margin-bottom: 2px; border: none; padding: 0px !important"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <span style="font-weight: bold; color: black" *ngIf="item.code && item.code.length > 1">{{ item.code }}</span
                              >&nbsp;
                              <span>{{ item.name != item.code ? item.name.replace(item.code, "") : item.name }}</span>
                            </ng-template>
                          </ng-select>

                          <mat-error *ngIf="selection.hasError('required')"> This field is required </mat-error>
                        </mat-form-field>
                        <!-- <div class="dropdown-icons">
                          <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                        </div> -->
                      </div>

                  <!-- AssetSubType start-->
                  <div
                    *ngIf="agencyList.type == 'esriFieldTypeString' && agencyList.fieldName == 'AssetSubType' && assetCodeFrmAssetSubType.length > 0">
                    <mat-form-field [ngClass]="{
                        'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                      }" appearance="outline">
                      <mat-label [attr.aria-label]="agencyList.label">
                        <span [ngClass]="{
                            'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                          }">{{ scndlyr ? agencyList.labelSL : agencyList.label }}<span
                            *ngIf="agencyList.IsRequired == 1">★</span></span>
                      </mat-label>
                      <mat-select [ngClass]="{
                          'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                        }" [(ngModel)]="activeAsset[0][agencyList.fieldName]" #assetSubType="ngModel"
                        (selectionChange)="objSubTypeChanged()">
                        <mat-option [value]="item.id" *ngFor="let item of agencyList.codedValues">
                          <span>{{ item.name }}</span>
                        </mat-option>
                      </mat-select>
                      <div class="dropdown-icons">
                        <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                      </div>
                      
                      <mat-error *ngIf="assetSubType.hasError('required')"> This field is required </mat-error>
                    </mat-form-field>
                  </div>
                  <!-- AssetSubType end -->

                      <mat-form-field
                        [ngClass]="{
                          'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                        }"
                        *ngIf="agencyList.type && agencyList.fieldName != 'AssetCode' && agencyList.fieldName != 'AssetSubType'"
                        appearance="outline"
                      >
                        <mat-label [attr.aria-label]="agencyList.label">
                          <span
                            [ngClass]="{
                              'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                            }"
                            >{{ scndlyr ? agencyList.labelSL : agencyList.label }}<span *ngIf="agencyList.IsRequired == 1">★</span></span
                          >
                        </mat-label>
                        <input
                          *ngIf="agencyList.fieldName != 'AssetCode' && (!agencyList.codedValues || agencyList.codedValues.length === 0) && agencyList.type != 'esriFieldTypeDate'"
                          matInput
                          [ngClass]="{
                            'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                          }"
                          #inputText="ngModel"
                          [disabled]="findFieldisDisable(agencyList.fieldName)"
                          [type]="getInputType(agencyList.fieldName, agencyList.type)"
                          [(ngModel)]="activeAsset[0][agencyList.fieldName]"
                        />

                        <input
                          *ngIf="agencyList.fieldName != 'AssetCode' && (!agencyList.codedValues || agencyList.codedValues.length === 0) && agencyList.type == 'esriFieldTypeDate'"
                          matInput
                          [ngClass]="{
                            'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                          }"
                          #inputText="ngModel"
                          [matDatepicker]="picker"
                          (dateChange)="changeTime($event, agencyList.fieldName)"
                          placeholder="Date Time"
                          [ngModel]="activeAsset[0][agencyList.fieldName] | customDatePipe"
                          name="agencyList.fieldName"
                          [disabled]="findFieldisDisable(agencyList.fieldName)"
                          autocomplete="off"
                        />

                        <mat-datepicker-toggle
                          [ngStyle]="{
                            display: agencyList.fieldName != 'AssetCode' && (!agencyList.codedValues || agencyList.codedValues.length === 0) && agencyList.type == 'esriFieldTypeDate' ? 'initial' : 'none'
                          }"
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>

                        <div *ngIf="agencyList.codedValues && agencyList.codedValues.length > 0 && agencyList.fieldName != 'AssetSubType' && agencyList.fieldName != 'AssetCode' 
                          && agencyList.fieldName != 'DoNotReplace'">
                          <mat-select
                            [ngClass]="{
                              'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                            }"
                            #dropdown="ngModel"
                            [(ngModel)]="activeAsset[0][agencyList.fieldName]"
                          >
                            <mat-option [value]="item.code ?? item.name" *ngFor="let item of agencyList.codedValues">
                              <span>{{ item.name }}</span>
                            </mat-option>
                          </mat-select>
                          <div class="dropdown-icons">
                            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                          </div>
                          <mat-error *ngIf="dropdown.hasError('required')"> This field is required </mat-error>
                        </div>

                        <div *ngIf="agencyList.codedValues && agencyList.codedValues.length > 0 && agencyList.fieldName != 'AssetSubType' && agencyList.fieldName != 'AssetCode' && agencyList.fieldName == 'DoNotReplace'">
                          <mat-select [disabled]="doNotReplaceDisable(agencyList.fieldName)"
                            [ngClass]="{
                              'error-state': agencyList.IsRequired == 1 && (activeAsset[0][agencyList.fieldName] == null || activeAsset[0][agencyList.fieldName] == '') && activeAsset[0][agencyList.fieldName] != 0
                            }"
                            #dropdown="ngModel"
                            [(ngModel)]="activeAsset[0][agencyList.fieldName]"
                          >
                            <mat-option [value]="item.code ?? item.name" *ngFor="let item of agencyList.codedValues">
                              <span>{{ item.name }}</span>
                            </mat-option>
                          </mat-select>
                          <div class="dropdown-icons">
                            <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                          </div>
                          <mat-error *ngIf="dropdown.hasError('required')"> This field is required </mat-error>
                        </div>

                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="isVisualizationAsset" class="scroll-detailVis avDetailScrollArea">
                  <div>
                    <div *ngFor="let data of currentLayerData" class="row">
                      <div style="text-align: left; padding-right: 0px" class="col-12">
                        <label style="display: block" class="avFormLabel">
                          {{ data.Label }}
                        </label>
                      </div>
                      <div class="col-12 mb-2">
                        <input class="avDetailFormControl" disabled="true" style="background-color: #f3f5fd !important" type="text" value="{{ data.Value }}" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="avFooter footer-container">
                  <button
                    title="Manage Images for Asset"
                    class="i-inactive"
                    type="button"
                    [ngStyle]="{ visibility: isAssetNew || eventEmitersService.addNewChild ? 'hidden' : 'visible' }"
                    [disabled]="isVisualizationAsset"
                    mat-button
                    class="h7 primary-button"
                    (click)="viewImage(content)"
                  >
                    Manage Images
                  </button>
                  <button title="Save Changes" type="button" mat-button class="h7 primary-button" (click)="saveAsset()">Save</button>
                  <button
                    class="i-inactive"
                    type="button"
                    mat-button
                    title="Delete"
                    class="h7 inverse-button"
                    (click)="deleteAsset()"
                    [disabled]="isVisualizationAsset"
                    [class.disabled-contenct]="dataEditorSettings?.hardDelete == 1 ? false : true"
                    *ngIf="currentUser.rolePermissions.assetDeleteRecord && !ismodelpopup && findUserAccessByRoleRegionLayerVar"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab style="height:100%; overflow:none" *ngIf="!isVisualizationAsset && !this.eventEmitersService.addNewParent && !this.eventEmitersService.addNewChild" [attr.aria-label]="'Select.carddisplay.tasktab' | translate" [label]="'Select.carddisplay.tasktab' | translate">
        <sms-tasks
          [Graphic]="Graphic"
          [Point]="Point"
          [FeatureLayer]="FeatureLayer"
          [asset]="activeAsset"
          [relatedAsset]="relatedAsset"
          [activeAsset]="activeAsset"
          [Query]="Query"
          [QueryTask]="QueryTask"
          [webMercatorUtils]="webMercatorUtils"
          [geometryJsonUtils]="geometryJsonUtils"
          [map]="map"
          (closeCard)="closeCardViewForRetire()"
        ></sms-tasks>
      </mat-tab>
      <mat-tab style="height:100%" [attr.aria-label]="'Select.carddisplay.assessmenttab' | translate" *ngIf="!isVisualizationAsset && !this.eventEmitersService.addNewParent && !this.eventEmitersService.addNewChild" [label]="'Select.carddisplay.assessmenttab' | translate">
        <sms-Assessment [relatedAsset]="relatedAsset" [activeAsset]="activeAsset" [webMercatorUtils]="webMercatorUtils"></sms-Assessment>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!-- document viewer -->
  <ng-template #content let-modal let-d="dismiss">
    <app-heading-component [headingText]="'Select.photoviewer.header' | translate" [variant]="'none'" [iconOne]="'cross'" [tooltipOne]="'Close'" (actionOne)="d('Cross click')"> </app-heading-component>

    <div class="modal-body sms-model-imageviewer">
      <div class="sort-container">
        <button class="small pv-btn" id="Date" data-toggle="collapse" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" (click)="OrderImages('Date')">
          {{ "Select.docassessment.datefilter" | translate }}
          <ng-container>
            <mat-icon title="Sort Descending" *ngIf="!showDateByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
            <ng-template #ElseBlock>
              <mat-icon title="Sort Ascending" aria-hidden="true" svgIcon="arrow-down"></mat-icon>
            </ng-template>
          </ng-container>
        </button>
        <button class="small pv-btn" id="Sequence" *ngIf="linearAssetValues && currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('Measure')">
          {{ "Select.docassessment.sequencefilter" | translate }}
          <ng-container>
            <mat-icon title="Sort Descending" *ngIf="!showSequenceByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
            <ng-template #ElseBlock>
              <mat-icon title="Sort Ascending" svgIcon="arrow-down"></mat-icon>
            </ng-template>
          </ng-container>
        </button>
        <button class="small pv-btn" id="Source" *ngIf="currentUser.soultionTiers[0][0].sort_Documents" data-toggle="collapse" (click)="OrderImages('SourceTag')">
          {{ "Select.docassessment.sourcefilter" | translate }}
          <ng-container>
            <mat-icon title="Sort Descending" *ngIf="!showSourceByOrder; else ElseBlock" aria-hidden="true" svgIcon="arrow-up"></mat-icon>
            <ng-template #ElseBlock>
              <mat-icon title="Sort Ascending" aria-hidden="true" svgIcon="arrow-down"></mat-icon>
            </ng-template>
          </ng-container>
        </button>
      </div>
      <form fxLayout="column" class="mb">
        <mat-button-toggle-group class="button-group" #group="matButtonToggleGroup" [value]="imageSrc" (change)="EventChange($event)">
          <mat-button-toggle *ngFor="let sample of samples; let i = index" color="primary" [value]="sample.url"> {{ sample.label }}</mat-button-toggle>
        </mat-button-toggle-group>
      </form>
      <img *ngIf="imageSrc !== '' && !imageSrc.includes('application/pdf')" imageSrc [src]="imageSrc" style="width: 100%" />
      <ng-container *ngIf="imageSrc.includes('application/pdf')"><pdf-viewer [src]="imageSrc" [render-text]="true" [original-size]="false" style="height: 500px"></pdf-viewer></ng-container>
      <input type="file" accept=".jpg, .jpeg, .pdf" #assetPhotoUpload class="d-none" (change)="uploadNew(assetPhotoUpload.files)" aria-label="select file" />
    </div>
    <div class="photo-data">
      <div class="xsmall photo-date">{{ "Select.docassessment.photodate" | translate }} : {{ PhotoDate | date : "medium" }}</div>
      <div class="xsmall photo-date">{{ "Select.docassessment.sourceandcondition" | translate }} : {{ SourceTag }}</div>
      <div class="xsmall photo-date" *ngIf="linearAssetValues">{{ "Select.docassessment.measureinterval" | translate }}: {{ MeasureValue }}</div>
    </div>
    <div class="modal-footer">
      <div class="justify-content-end">
        <button
          title="{{ 'Select.photoviewer.uploadbtn' | translate }}"
          mat-button
          *ngIf="currentUser.rolePermissions.documentAdd && findUserAccessByRoleRegionLayerVar"
          type="button"
          class="primary-button btn-sm"
          (click)="assetPhotoUpload.click()"
        >
          {{ "Select.photoviewer.uploadbtn" | translate }}
        </button>
        <button title="{{ 'Select.photoviewer.exportbtn' | translate }}" mat-button type="button" class="primary-button btn-sm" (click)="export()">{{ "Select.photoviewer.exportbtn" | translate }}</button>
        <button
          title="{{ 'Select.photoviewer.deletebtn' | translate }}"
          mat-button
          *ngIf="currentUser.rolePermissions.documentRemove && findUserAccessByRoleRegionLayerVar"
          type="button"
          class="inverse-button btn-sm"
          (click)="deleteImage()"
        >
          {{ "Select.photoviewer.deletebtn" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="showtask" role="navigation" aria-label="Show Task">
  <create-tasks [Graphic]="Graphic" (showTasks)="showTaksTab($event)" [FeatureLayer]="FeatureLayer"
    [asset]="selectedActiveAsset"  >
</create-tasks>
</div>
