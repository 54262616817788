<div class="card mt-2">
    <ul ngbNav #lifeEditNav="ngbNav" class="nav-pills" [(activeId)]="active" role="list">
        <li [ngbNavItem]="1" title="{{'Userscreens.retroadmin.baselifetab'|translate}}">
            <a ngbNavLink>{{'Userscreens.retroadmin.baselifetab'|translate}}</a>
            <ng-template ngbNavContent>
                <app-sms-baselife [map]="map" [Graphic]="Graphic" [FeatureTable]="FeatureTable"
                    [FeatureLayer]="FeatureLayer"></app-sms-baselife>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" title="{{'Userscreens.retroadmin.baselifemodifiertab'|translate}}">
            <a ngbNavLink>{{'Userscreens.retroadmin.baselifemodifiertab'|translate}}</a>
            <ng-template ngbNavContent>
                <app-sms-baselifemodifier [map]="map" [Graphic]="Graphic" [FeatureTable]="FeatureTable"
                    [FeatureLayer]="FeatureLayer"></app-sms-baselifemodifier>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="lifeEditNav"></div>
</div>