<mat-form-field appearance="outline" style="width: 100%">
  <mat-label [ngClass]="{ hide: label == 'hide' }"
    >{{ label }}
    <span *ngIf="isRequired == true">★</span>
  </mat-label>
  <mat-select
    [(value)]="val"
    *ngIf="isWorkerUsername == false && isManageWorkOrderReason == false && isReason == false && isAssessment == false && isQueryReport == false && isQueryReportFormat == false && isJoinType == false 
     && isCrewManagement == false
     && isRegion == false">
    <mat-option [disabled]="item.disabled" [value]="item.id" *ngFor="let item of dropdownList" (click)="selectItem(item.id)">
      {{ item.name }}
    </mat-option>
  </mat-select>
  <mat-select [disabled]="disableJoinTypeDropdown" [(value)]="val" *ngIf="isJoinType == true">
    <mat-option [disabled]="item.disabled" [value]="item.id" *ngFor="let item of dropdownList" (click)="selectItem(item.id)">
      {{ item.name }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isReason == true">
    <mat-option [disabled]="item.disabled" [value]="item.code" *ngFor="let item of dropdownList" (click)="selectItem(item.code)">
      {{ item.code }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isWorkerUsername == true && isManageWorkOrderReason == false">
    <mat-option [disabled]="item.disabled" [value]="item.displayName" *ngFor="let item of dropdownList" (click)="selectItem(item.displayName)">
      {{ item.displayName }}
    </mat-option>
  </mat-select>
  <mat-select [disabled]="disableActivityDropdown" [(value)]="val" *ngIf="isManageWorkOrderReason == true && isWorkerUsername == false">
    <mat-option [disabled]="item.disabled" [value]="item.code" *ngFor="let item of dropdownList" (click)="selectItem(item.code)">
      {{ item.name }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isAssessment == true || isRegion == true">
    <mat-option [disabled]="item.disabled" [value]="item" *ngFor="let item of dropdownList" (click)="selectItem(item)">
      {{ item }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isQueryReport == true">
    <mat-option [disabled]="item.disabled" [value]="item" *ngFor="let item of dropdownList" (click)="selectItem(item)">
      {{ item }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isQueryReportFormat == true">
    <mat-option [disabled]="item.disabled" [value]="item.name" *ngFor="let item of dropdownList" (click)="selectItem(item.name)">
      {{ item.name }}
    </mat-option>
  </mat-select>
  <mat-select [(value)]="val" *ngIf="isCrewManagement == true">
    <mat-option [disabled]="item.disabled" [value]="item.Name" *ngFor="let item of dropdownList" (click)="selectItem(item.Name)">
      {{ item.Alias }}
    </mat-option>
  </mat-select>

  <div class="dropdown-icons">
    <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
    <mat-icon variant="outlined" class="material-icons-outlined" style="line-height: 1.25; font-size: 18px; text-align: center" *ngIf="tooltip != null && tooltip != ''" matSuffix [matTooltip]="tooltip">info</mat-icon>
  </div>
</mat-form-field>
