<div class="form-popup-task" id="frmCreateTask" style="display: block">
  <div class="row" id="frmCreateTaskrow">
    <form class="form-container" cdkDragBoundary=".form-popup-task" cdkDrag>
      <app-heading-component
        cdkDragHandle
        [headingText]="'Select.createtask.edittasktxt' | translate"
        [variant]="'none'"
        [iconThree]="'cross'"
        (actionThree)="close()"
        [iconTwo]="'edit_note'"
        (actionTwo)="openMaintenanceForm(content)"
        [iconTwoDropdown]="false"
        [isCreateEditTaskHeader]="true"
        [tooltipThree]="'Close'"
        [tooltipTwo]="'Manage Work Order Form'"
      >
      </app-heading-component>
      <div class="task-form">
        <div class="sub-heading task-subheading h6">
          {{ "Select.createtask.scheduledtxt" | translate }}
        </div>
      </div>
      <div class="divider divider-aftersubheading"></div>
      <div class="modal-body sms-model-height formbg-d2 createtask-items-container" id="create-edit-task">
        <div *ngIf="currentUser.soultionTiers[0][0].task_Prioritization && isAdvancedFields">
          <div class="form-row">
            <div class="form-group col-3">
              <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.SheduledDate == 1 && (scheduled == null || (scheduled == '' && scheduled != 0)) }">
                <mat-label>
                  <span class="label-wrapper">Date<span *ngIf="TaskFormFieldsIsRequired.SheduledDate">★</span></span>
                </mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [disabled]="editCreateTaskForm.sheduledTime == false ? true : false && disableWeatherIcon"
                  placeholder="Date Time"
                  [min]="todayDate"
                  [(ngModel)]="scheduled"
                  (ngModelChange)="dateTimeChanged()"
                  name="wrkshedule"
                  readonly="readonly"
                  autocomplete="off"
                />

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error>Error</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-3">
              <div class="form-row">
                <div class="form-group col-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Estimated Hours</mat-label>
                    <input matInput [disabled]="editCreateTaskForm.duration == false ? true : false" [(ngModel)]="dhours" type="number" name="hours" min="0" max="11" />
                    <mat-error>Error</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group col-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Estimated Minutes</mat-label>
                    <input matInput [disabled]="editCreateTaskForm.duration == false ? true : false" [(ngModel)]="dminutes" type="number" name="minutes" min="0" max="59" name="dminutes" />
                    <mat-error>Error</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="form-group col-3">
              <button name="taskstarttime" style="margin-top: 2px" mat-stroked-button class="task-button inverse-button h7" [disabled]="startTime != null ? true : false" (click)="startTask()">Start Task</button>
            </div>
            <div class="form-group col-3">
              <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.StartTime == 1 && (todayDate == null || (todayDate == '' && todayDate != 0)) }">
                <mat-label>
                  <span class="label-wrapper">Date/Time<span *ngIf="TaskFormFieldsIsRequired.StartTime">★</span></span>
                </mat-label>
                <input matInput [disabled]="editCreateTaskForm.startTime == false ? true : false" [(ngModel)]="todayDate" type="text" name="start_date" />
                <mat-error>Error</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-row">
            <div *ngIf="currentUser.soultionTiers[0][0].resource_Requirements_Check" class="form-group col">
              <button name="resourcelistbtn" style="margin: 0.25em" *ngIf="currentUser.soultionTiers[0][0].resource_Requirements_Check" mat-stroked-button class="h7 inverse-button task-button" (click)="toolslist()">
                {{ "Select.createtask.resourcelistbtn" | translate }}
              </button>
            </div>
            <div class="form-group col-3">
              <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.Priority == 1 && (priority == null || (priority == '' && priority != 0)) }">
                <mat-label>
                  <span class="label-wrapper">{{ "Select.createtask.prioritytxt" | translate }} <span *ngIf="TaskFormFieldsIsRequired.Priority">★</span></span>
                </mat-label>
                <mat-select [disabled]="editCreateTaskForm.priority == false ? true : false" [(ngModel)]="priority" name="wrkpriority">
                  <mat-option [value]="0">
                    {{ "Select.createtask.prioritylowtxt" | translate }}
                  </mat-option>
                  <mat-option [value]="1">
                    {{ "Select.createtask.prioritymediumtxt" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "Select.createtask.priorityhightxt" | translate }}
                  </mat-option>
                </mat-select>
                <div class="dropdown-icons">
                  <mat-icon matSuffix svgIcon="arrow-up-down"></mat-icon>
                </div>
              </mat-form-field>
            </div>

            <div class="form-group col-3">
              <div id="weatherIconDiv" class="w-icon w-icon-grey" (click)="showDetailTable()"></div>
            </div>
            <div class="form-group col"></div>
          </div>

          <form *ngIf="rewtools && activityToolKitList.length > 0" class="result-popup" style="background-color: #fff">
            <ul class="list-group">
              <li *ngFor="let tool of activityToolKitList; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
                {{ tool.attributes.ToolName }}
                <span class="badge badge-primary badge-pill">1</span>
              </li>
            </ul>
          </form>
          <div *ngIf="showTable">
            <div class="form-row">
              <div class="form-group col"></div>

              <div class="form-group col"></div>
            </div>
            <div class="form-row" *ngIf="showTable">
              <div class="form-group col">
                <table class="table table-bordered">
                  <thead>
                    <tr class="color-primary">
                      <th scope="col">#</th>
                      <th scope="col">{{ "Select.Weathertable.temperature" | translate }}</th>
                      <th scope="col">{{ "Select.Weathertable.wind" | translate }}</th>
                      <th scope="col">{{ "Select.Weathertable.humidity" | translate }}</th>
                      <th scope="col">{{ "Select.Weathertable.precipitation" | translate }}</th>
                      <th scope="col">{{ "Select.Weathertable.recommendation" | translate }}</th>
                    </tr>
                  </thead>
                  <tbody class="overflow-auto">
                    <tr *ngFor="let f of detailForecastData">
                      <th scope="row" class="text-center color-primary">{{ f.time }}</th>
                      <td class="text-center" [ngClass]="getColorClass(f.tempcolor)">{{ f.temp }}</td>
                      <td class="text-center" [ngClass]="getColorClass(f.windcolor)">{{ f.wind }}</td>
                      <td class="text-center" [ngClass]="getColorClass(f.humiditycolor)">
                        {{ f.humidity }}
                      </td>
                      <td class="text-center" [ngClass]="getColorClass(f.raincolor)">{{ f.rain }}</td>
                      <td class="text-center"><i [ngClass]="getRecommendationColorClass(f.tempcolor, f.windcolor, f.humiditycolor, f.raincolor)"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- End Table for Weather Information -->
        </div>
        <div class="sub-heading task-subheading h6">Properties</div>
        <div class="divider divider-aftersubheading"></div>
        <div class="form-row">
          <div class="form-group col-3">
            <mat-form-field appearance="outline" class="input-disable">
              <mat-label>{{ "Select.createtask.createddatetxt" | translate }}</mat-label>
              <input [disabled]="isEdit == 'false' ? true : false" matInput [(ngModel)]="createdDate" type="text" name="created_date" />
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group col-3">
            <mat-form-field appearance="outline" class="input-disable">
              <mat-label>{{ "Select.createtask.createdbytxt" | translate }}</mat-label>
              <input [disabled]="isEdit == 'false' ? true : false" matInput [(ngModel)]="createdBy" type="text" name="wrkcreated_by" />
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group col-3">
            <mat-form-field appearance="outline" class="input-disable">
              <mat-label>{{ "Select.createtask.regionstxt" | translate }}</mat-label>
              <input [disabled]="isEdit == 'false' ? true : false" matInput [(ngModel)]="region" type="text" name="wrkregion" />
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group col"></div>
        </div>
        <div class="form-row">
          <div class="form-group col-3">
            <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.WorkOrder == 1 && (workorderID == null || (workorderID == '' && workorderID != 0)) }">
              <mat-label>
                <span class="label-wrapper">{{ "Select.createtask.workorderidtxt" | translate }}<span *ngIf="TaskFormFieldsIsRequired.WorkOrder">★</span></span>
              </mat-label>
              <input [disabled]="editCreateTaskForm.workorderID == false ? true : false" matInput [(ngModel)]="workorderID" type="text" id="userIdFirstWay" name="workorderid" />
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group col-3">
            <!-- <app-dropdown
              [isRequired]="TaskFormFieldsIsRequired.Worker"
              [label]="'Select.createtask.workertxt' | translate"
              [dropdownList]="clientUsersListByLayer"
              [(ngModel)]="worker"
              [val]="worker"
              [isWorkerUsername]="true"
              [tooltip]="'Select.createtask.workerinfoicontxt' | translate"
              (select)="workerChanged($event)"
            >
            </app-dropdown> -->
            <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.Worker == 1 && (worker == null || worker == '') }">
              <mat-label
                ><span class="label-wrapper">{{ "Select.createtask.workertxt" | translate }}<span *ngIf="TaskFormFieldsIsRequired.Worker">★</span></span></mat-label
              >
              <mat-select [(ngModel)]="worker" [(value)]="worker" (ngModelChange)="workerChanged($event)" [disabled]="editCreateTaskForm.worker == false ? true : false">
                <mat-option [value]="item.displayName" *ngFor="let item of clientUsersListByLayer">
                  {{ item.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group col-3">
            <app-dropdown [isRequired]="TaskFormFieldsIsRequired.Reason" [label]="'Select.createtask.reasontxt' | translate" [dropdownList]="ReasonCodes" [val]="reason" [isReason]="true" (select)="reasonChanged($event)">
            </app-dropdown>
          </div>
          <div *ngIf="activity" class="form-group col-3">
            <app-dropdown
              [isRequired]="TaskFormFieldsIsRequired.Status"
              [label]="'Select.createtask.activitytxt' | translate"
              [dropdownList]="activityLyrFltDomines"
              [val]="activity"
              [isManageWorkOrderReason]="true"
              (select)="activityChange($event)"
              [disableActivityDropdown]="editCreateTaskForm.activity == false ? true : false"
            >
            </app-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.WorkOrderDesc == 1 && (desc == null || (desc == '' && desc != 0)) }">
              <mat-label>
                <span class="label-wrapper">{{ "Select.createtask.workorderdesctxt" | translate }} <span *ngIf="TaskFormFieldsIsRequired.WorkOrderDesc">★</span></span>
              </mat-label>
              <textarea [disabled]="editCreateTaskForm.desc == false ? true : false" matInput [(ngModel)]="desc" type="text" name="wrkdesc" rows="3" maxlength="250"></textarea>
              <span *ngIf="desc" style="color: red">{{ 250 - desc.length + " characters remaining" }}</span>
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>

          <div class="form-group col-6">
            <mat-form-field appearance="outline" [ngClass]="{ 'error-state': TaskFormFieldsIsRequired.Comment == 1 && (comments == null || (comments == '' && comments != 0)) }">
              <mat-label>
                <span class="label-wrapper">{{ "Select.createtask.commenttxt" | translate }} <span *ngIf="TaskFormFieldsIsRequired.Comment">★</span></span>
              </mat-label>
              <textarea [disabled]="editCreateTaskForm.comments == false ? true : false" matInput [(ngModel)]="comments" type="text" name="comments" maxlength="250" rows="3"></textarea>
              <span *ngIf="comments" style="color: red">{{ 250 - comments.length + " characters remaining" }}</span>
              <mat-error>Error</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="divider"></div>
        <div class="form-row d-flex justify-content-end mt-3" *ngIf="userAccessByRoleRegionLayer">
          <button
            style="margin: 4px"
            [disabled]="disabledSave"
            *ngIf="currentUser.rolePermissions.taskEditOpen"
            class="h7 primary-button"
            mat-button
            title="{{ 'CreateTask.Tooltip.SaveForLater' | translate }}"
            (click)="saveTask()"
          >
            {{ "CreateTask.Tooltip.SaveForLater" | translate }}
          </button>
          <button
            style="margin: 4px"
            [disabled]="disabledSave"
            *ngIf="currentUser.rolePermissions.taskCompleteOpen"
            class="h7 inverse-button"
            mat-stroked-button
            title="{{ 'CreateTask.Tooltip.SaveTaskCompleted' | translate }}"
            (click)="saveAndCompleteTask()"
          >
            {{ "CreateTask.Tooltip.SaveTaskCompleted" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Work Order Defaults form -->
<ng-template id="frmManageWorkOrder" #content let-modal let-d="dismiss">
  <app-heading-component [headingText]="'Select.manageworkorder.title' | translate" [variant]="'none'" [iconThree]="'cross'" (actionThree)="modal.dismiss('Cross click')"> </app-heading-component>
  <div id="createtask" style="background-color: #ffffff">
    <form class="form-container1 formbg-d2 form-manageorder" style="position: relative; width: 94%; left: 3%">
      <h4 class="mb-1" style="padding-top: 16px; font-weight: bold">{{ "Select.manageworkorder.desc" | translate }}</h4>
      <div class="divider"></div>
      <p class="my-2" style="margin-top: 6px">{{ "Select.manageworkorder.desc2" | translate }}</p>

      <div class="form-row">
        <div class="form-group col">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Select.manageworkorder.workorder" | translate }}</mat-label>
            <input matInput [(ngModel)]="defWorkOrder" type="text" name="wrkworkerid" />
            <mat-error>Error</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <app-dropdown [label]="'Select.manageworkorder.worker' | translate" [dropdownList]="clientUsersList" [val]="defWorker" [isWorkerUsername]="true" (select)="managedWorkerChanged($event)"> </app-dropdown>
        </div>
        <div class="form-group col">
          <app-dropdown [label]="'Select.manageworkorder.reason' | translate" [dropdownList]="ReasonCodes" [val]="defReason" [isManageWorkOrderReason]="true" (select)="managedReasonChanged($event)"> </app-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Select.manageworkorder.workorderdesc" | translate }}</mat-label>
            <textarea matInput [(ngModel)]="defDesc" type="text" rows="3" name="wrkdesc"></textarea>
            <mat-error>Error</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group col-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ "Select.manageworkorder.comments" | translate }}</mat-label>
            <textarea matInput [(ngModel)]="defComments" type="text" rows="3" name="comments"></textarea>
            <mat-error>Error</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div class="form-row d-flex justify-content-end mt-3">
        <button title="{{ 'Select.manageworkorder.clearbtn' | translate }}" style="margin: 4px" class="h7 primary-button" mat-button (click)="clearDef()">{{ "Select.manageworkorder.clearbtn" | translate }}</button>
        <button title="{{ 'Select.manageworkorder.savebtn' | translate }}" style="margin: 4px" mat-button class="h7 primary-button" (click)="saveWorkOrderDefaults()">
          {{ "Select.manageworkorder.savebtn" | translate }}
        </button>
        <button title="{{ 'Select.manageworkorder.cancelbtn' | translate }}" style="margin: 4px" mat-stroked-button class="h7 inverse-button" (click)="cancel()">
          {{ "Select.manageworkorder.cancelbtn" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
