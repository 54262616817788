import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customDatePipe"
})
export class CustomDatePipePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value === 0 || value === " " || value == null) {
      return null;
    }

    return new Date(value);
  }
}
