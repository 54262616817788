import { Component, OnInit } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { SystemReportsStyle } from "../system.reports.style";
import { environment } from "src/environments/environment";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-customreports-artesp",
  templateUrl: "./customreports-artesp.component.html",
  styleUrls: ["./customreports-artesp.component.scss"]
})
export class CustomreportsARTESPComponent implements OnInit {
  private currentUser: any;

  //Icons
  faDownload = faDownload;

  constructor(public systemReportsService: SystemReportsService, private authenticationService: AuthenticationService, private translateService: TranslateService, private reportStyle: SystemReportsStyle) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit(): void {
    // No line
  }
  exportCSV() {
    try {
      const Url = "?AppUrl=" + environment.customReport;
      this.systemReportsService.GetCustomReportARTESP(Url).subscribe(
        (res) => {
          this.CSVCreator(res);
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  CSVCreator(res) {
    try {
      let csv;
      const columnDelimiter = ",";
      const lineDelimiter = "\n";

      const keys = Object.keys(res.tblCustomReport[0]);

      let result = "";
      console.log(Object);

      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      let ctr = 0;

      const selfeatures = res.tblCustomReport;

      selfeatures.forEach((element) => {
        if (element) {
          keys.forEach(function (key, i) {
            result += element[key];
            result += columnDelimiter;
          });
          ctr++;
          result += lineDelimiter;
        }
      });
      if (ctr === res.tblCustomReport.length) {
        csv = result;
        if (!csv.match(/^data:text\/csv/i)) {
          csv = "data:text/csv;charset=utf-8," + csv;
        }
        const encodedUri = encodeURI(csv);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const filename = "customreports" + ".csv";
        link.setAttribute("download", filename);
        link.click();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
