<div class="container">
  <div class="row">
    <div class="col">
      <span class="h6">{{ "Systemreports.activitybyreason.desctext" | translate }}</span>
    </div>
  </div>

  <div class="row" style="margin-top: 16px">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Systemreports.activitybyreason.fromdate" | translate }}</mat-label>
        <input aria-labelledby="abRe_fDateLabelID" id="abReRepCalInput1" matInput [matDatepicker]="picker1" autocomplete="off" [(ngModel)]="startDate" [max]="endDate" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>{{ "Systemreports.activitybyreason.todate" | translate }}</mat-label>
        <input aria-labelledby="abRe_tDateLabelID" id="abReRepCalInput2" matInput [matDatepicker]="picker" autocomplete="off" [(ngModel)]="endDate" [min]="startDate" [max]="todayDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="card-footer text-right" style="background-color: white">
  <button [attr.aria-label]="'' + 'Systemreports.btn.generatereport' | translate" (click)="exportPDF()" class="report-button h7" mat-button>
    <mat-icon variant="outlined" class="material-icons-outlined" class="report-icon" svgIcon="report"></mat-icon>{{ "Systemreports.btn.generatereport" | translate }}
  </button>
</div>
