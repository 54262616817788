import { Injectable, Inject, Optional, EventEmitter } from "@angular/core";
import { environment } from "src/environments/environment";
import { AssetDetailService } from "../services/assetDetail.service";

@Injectable({
  providedIn: "root"
})
export class RetireAssetService {
  // Variables
  private paentAsset;
  private maintId;
  private oldRefParentId;
  private newRefParentId;
  private baseAsset;
  // pending Changes String
  private pendingChanges;
  // Layers
  private signLayer;
  private lightLayer;
  private documentLayer;
  private parentAssetLayer;
  private activityLayer;
  private maintenanceLayer;
  public newAssetObjectId;
  public newAssetObject;
  public oldAaaetId;
  public assessmentlyr;
  // Events
  eventReplaceCompleted: EventEmitter<any>;

  constructor(
    @Inject("FeatureLayer") private FeatureLayer: any,
    @Inject("Graphic") private Graphic: any,
    @Inject("currentUser") private currentUser: any,
    @Inject("objGlobals") private objGlobals: any,
    @Inject("objGlobals") private eventEmitersService: any,
    @Inject("httpClient") @Optional() private httpClient: any,
    public objAssetDetailService: AssetDetailService,
    @Inject("GISHttpClient") @Optional() private GISHttpClient: any,
  ) {
    this.FeatureLayer = FeatureLayer;
    this.Graphic = Graphic;
    this.currentUser = currentUser;
    this.objGlobals = objGlobals;
    this.GISHttpClient = GISHttpClient;
    try {
      this.activityLayer = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.currentUser.serviceLyrInfo.activityId);
      this.maintenanceLayer = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.currentUser.serviceLyrInfo.maintenanceId);
      this.signLayer = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.currentUser.serviceLyrInfo.signId);
      this.lightLayer = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.currentUser.serviceLyrInfo.lightId);
      this.documentLayer = new FeatureLayer(this.currentUser.webServiceURL + "FeatureServer/" + this.currentUser.serviceLyrInfo.documentId);
      this.assessmentlyr = new this.FeatureLayer(this.currentUser.webServiceURL + environment.featureServer + this.currentUser.serviceLyrInfo.assessmentId, { outFields: ["*"] });
    } catch (error) {
      console.log(error);
    }

    this.eventReplaceCompleted = new EventEmitter<any>();
  }
  updateOldReplacedAssetStatus(layer, asset, replaceTaskAttributes) {
    try {
      this.oldAaaetId = asset[0].AssetID;
      this.baseAsset = asset;
      this.paentAsset = asset[2];
      this.parentAssetLayer = layer;
      this.pendingChanges = JSON.stringify(this.baseAsset[2].attributes);
      // Set Status as Inactive for asset classes
      const attr = asset[2].attributes;
      this.oldRefParentId = attr.AssetID;
      attr.Status = "Inactive";
      attr.RetireDate = new Date(Date.now()).getTime();
      attr.LastModifyDate = new Date(Date.now()).getTime();
      attr.ModifiedBy = this.currentUser.username;
      asset[2].attributes = attr;
      layer.lyrObj = layer;
      const assetId = asset[2].attributes.AssetID;
      this.updateAsset(layer, asset[2]).then((result) => {
        this.addAsset(this.parentAssetLayer, this.paentAsset).then((result) => {
          if (result) {
            asset[2].attributes.InstallDate = new Date(Date.now()).getTime();
            const replaceYear = this.eventEmitersService.getCalculatedReplaceYear(asset[1].lyrObj.name, asset[2]);
            const objectId = result[0].objectId;
            //const prefix = this.getAssetIDFormat(asset[1].layerName);
            //Jira Id-935
            const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === asset[1].layerName)[0].attributes.LayerCode;

            attr.OBJECTID = objectId;
            attr.AssetID = prefix + "_" + objectId;
            this.newRefParentId = prefix + "_" + objectId;
            this.newAssetObject = objectId;
            attr.Status = "Active";
            attr.ReplacesID = assetId;
            attr.ReplaceYear = replaceYear;
            attr.Latitude = asset[2].attributes.Latitude;
            attr.Longitude = asset[2].attributes.Longitude;
            attr.InstallDate = new Date(Date.now()).getTime();
            attr.RetireDate = null;
            attr.Condition = "Good";
            asset[2].attributes = attr;
            //
            this.updateAsset(asset[1].lyrObj, asset[2]).then((result) => {
              this.updateParenIdForRelatedAssets();
              // *   Create New Task on newly created Asset *
              this.createNewTask(asset[2], replaceTaskAttributes);
            });
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  private updateParenIdForRelatedAssets() {
    if (this.baseAsset[1].layerName.includes("Support")) {
      this.getFilteredFeatures(this.currentUser.serviceLyrInfo.signId, "ParentID", this.oldRefParentId).subscribe((signData) => {
        console.log(signData);
        for (let index = 0; index < signData.features.length; index++) {
          const featuresUpdate = new this.Graphic();
          featuresUpdate.setAttributes({ OBJECTID: signData.features[index].attributes.OBJECTID, ParentID: this.newRefParentId ,
            LastModifyDate : new Date(Date.now()).getTime(),
            ModifiedBy : this.currentUser.username
          });
          this.updateAsset(this.signLayer, featuresUpdate);
        }
      });
      this.getFilteredFeatures(this.currentUser.serviceLyrInfo.lightId, "ParentID", this.oldRefParentId).subscribe((lightData) => {
        console.log(lightData);
        for (let index = 0; index < lightData.features.length; index++) {
          const featuresUpdate = new this.Graphic();
          featuresUpdate.setAttributes({ OBJECTID: lightData.features[index].attributes.OBJECTID, ParentID: this.newRefParentId });
          this.updateAsset(this.lightLayer, featuresUpdate);
        }
      });
    }

    // As per Jeff comments documents are not required to link with new replaced asset
    // this.getFilteredFeatures(this.currentUser.serviceLyrInfo.lightId, "ParentID", this.oldRefParentId).subscribe((documentData) => {
    //   console.log(documentData);
    //   for (let index = 0; index < documentData.features.length; index++) {
    //     const featuresUpdate = new this.Graphic();
    //     featuresUpdate.setAttributes({ OBJECTID: documentData.features[index].attributes.OBJECTID, ParentID:  this.newRefParentId });
    //     this.updateAsset(this.signLayer, featuresUpdate);
    //   }
    // });
  }
  // Create New Task
  private createNewTask(asset, replaceTaskAttributes) {
    const featurese = [];

    console.log("test here");
    console.log(replaceTaskAttributes);
    const features = new this.Graphic();
    let assetId;
    if (asset && asset.attributes.AssetID) {
      assetId = asset.attributes.AssetID;
    }
    let geomString;
    if (asset.geometry) {
      if (asset && asset.geometry != null) {
        geomString = asset.geometry.toJson();
      }
      if (geomString.paths) {
        geomString.x = geomString.paths[0][0][0];
        geomString.y = geomString.paths[0][0][1];
        delete geomString.paths;
      }
    }

    console.log(".........................test Here..............................");
    // AssetName:this.layerName

    features.setAttributes({
      Status: "Closed",
      CreatedBy: this.currentUser.username,
      CompletedBy: this.currentUser.username,
      CompletionDate: new Date(Date.now()).getTime(),
      CreationDate: new Date(Date.now()).getTime(),
      LastModifyDate : new Date(Date.now()).getTime(),
      ModifiedBy : this.currentUser.username,
      AssetName: asset._layer.name,
      Region: asset.attributes.Region1,
      AssetID: assetId,
      Comment: "Replaced " + this.oldAaaetId,
      GeomWKT: JSON.stringify(geomString),

      // need to uncomment once restore task defects are fixed .
      // PendingChanges: this.pendingChanges
      Worker: replaceTaskAttributes.Worker,
      WorkOrder: replaceTaskAttributes.WorkOrder,
      WorkOrderDesc: replaceTaskAttributes.WorkOrderDesc
    });
    featurese.push(features);

    this.addAsset(this.maintenanceLayer, features).then((newTask) => {
      this.updateTaskMaintID(newTask);
    });
  }
  private updateTaskMaintID(newTask) {
    const featuresUpdate = new this.Graphic();
    this.maintId = "MNT_" + newTask[0].objectId;
    featuresUpdate.setAttributes({ OBJECTID: newTask[0].objectId, MaintID: "MNT_" + newTask[0].objectId });

    this.updateAsset(this.maintenanceLayer, featuresUpdate).then(() => {
      this.createActivity();
    });
  }
  private createActivity() {
    const actfeatures = new this.Graphic();
    actfeatures.setAttributes({ MaintID: this.maintId, Asset: 0, Activity: "New", ModifiedBy: this.currentUser.username });
    this.addAsset(this.activityLayer, actfeatures).then(() => {
      this.updateGlobalObjects();
    });
  }
  // CRUD Oparation On Asset
  private updateAsset(layer, asset) {
    let promise;
    if (layer) {
      promise = new Promise((resolve) => {
        layer
          .applyEdits(null, [asset], null)
          .then((results) => {
            resolve(results);
          })
          .catch(function (error) {
            console.error("[ update applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      });
    }
    return promise;
  }
  private addAsset(layer, asset) {
    let promise;
    if (layer) {
      promise = new Promise((resolve) => {
        if (asset.attributes.InstallDate) {
          asset["attributes"]["ReplaceYear"] = this.eventEmitersService.getCalculatedReplaceYear(layer.name, asset);
        }

        layer
          .applyEdits([asset], null, null)
          .then((results) => {
            console.log("newww addedddd" + results);
            if (layer.name == "Sign" || layer.name == "Light") {
              this.newAssetObjectId = results[0].objectId;
              this.objGlobals.newAssetObjectId = this.newAssetObjectId;
            }
            this.newAssessment(results[0].objectId, layer.name);
            resolve(results);
          })
          .catch(function (error) {
            console.error("[ update applyEdits ] FAILURE: ", error.code, error.name, error.message);
            console.log("error = ", error);
          });
      });
    }
    return promise;
  }
  private deleteAsset(layer, asset) {
    let promise;
    if (layer) {
      promise = new Promise((resolve) => {
        layer.applyEdits(null, null, [asset]).then((results) => {
          resolve(results);
        });
      });
    }
    return promise;
  }
  // Get Asset Code
  private getAssetIDFormat(layername) {
    const assetFormatCodes = [
      { key: "Barrier", value: "BAR" },
      { key: "CrashCushion", value: "CCU" },
      { key: "EndTreatment", value: "ETM" },
      { key: "Guardrail", value: "GUR" },
      { key: "PavementMarking", value: "PVM" },
      { key: "PavementMarking_Symbol", value: "PVS" },
      { key: "Support", value: "SUP" },
      { key: "Sign", value: "SIG" },
      { key: "Light", value: "LGT" }
    ];
    return assetFormatCodes.filter((prefix) => prefix.key === layername);
  }

  // Update Global Objects
  private updateGlobalObjects() {
    if (this.objGlobals.complaintID != null && this.objGlobals.complaintID !== undefined && this.objGlobals.complaintID !== "") {
      (this.objGlobals.longitude = this.paentAsset.attributes.Longitude), (this.objGlobals.latitude = this.paentAsset.attributes.Latitude), (this.objGlobals.assetID = this.newRefParentId);
    }
    this.eventReplaceCompleted.emit([this.newAssetObject, this.newRefParentId]);
  }
  // get Data
  private getFilteredFeatures(lyrId: number, fieldName: string, value: string) {
    const urlfilterFeatures = environment.queryAllPostFixGeomFalse.replace("1=1", fieldName + "='" + value + "'");
    return this.GISHttpClient.get(environment.proxy + this.currentUser.webServiceURL + environment.featureServer + lyrId + urlfilterFeatures);
  }
  newAssessment(newAssetid, layerName) {
    console.log("assetment");
    //Jira Id-935
    const prefix = this.objAssetDetailService.assetFormatCodes.filter((res) => res.attributes.Name === layerName)[0].attributes.LayerCode;

    if (prefix) {
      const features = new this.Graphic();
      const assetAttr: any = [];
      assetAttr.AssetID = prefix + "_" + newAssetid;
      assetAttr.Condition = "Good";
      assetAttr.Inspector = this.currentUser.username;
      assetAttr.ModifiedBy = this.currentUser.userId;
      assetAttr.InspectionDate = new Date(Date.now()).getTime();
      assetAttr.AssessType = "System Generated"
      features.setAttributes(assetAttr);
      this.assessmentlyr.applyEdits(
        [features],
        null,
        null,
        (evt) => {
          const upfeatures = new this.Graphic();
          const upassetAttr: any = [];
          upassetAttr.OBJECTID = evt[0].objectId;
          upassetAttr.AssessmentID = evt[0].objectId;
          upfeatures.setAttributes(upassetAttr);
          this.assessmentlyr.applyEdits(null, [upfeatures], null);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
