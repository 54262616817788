import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const currentUser = this.authenticationService.currentUserValue;

      if (currentUser) {
        // authorised so return true
        return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(["login"]);
      return false;
    } catch (error) {
      console.error(error);
    }
  }
}
