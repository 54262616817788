export const LANG_HI_NAME = "in";

export const LANG_HI_TRANS = {
  "Login.logo.name": "प्रबंधन प्रणाली पर हस्ताक्षर करें",
  "Login.page.text1": "सुरक्षित, तेज और हल्के तरीके",
  "Login.page.text2": "दुनिया को आगे बढ़ाने के लिए",
  "Login.loginbox.heading": "लॉगइन करें",
  "Login.loginbox.text": "3M साइन मैनेजमेंट सिस्टम में लॉगिन करने के लिए नीचे अपनी साख दर्ज करें",
  "Login.loginbox.userplaceholder": "अपना यूजर आईडी डालें",
  "Login.loginbox.passwordplaceholder": "अपना पासवर्ड डालें",
  "Login.loginbox.button": "लॉग इन करें",
  "Login.loginvalidate.text1": "आपका उपयोगकर्ता आईडी और पासवर्ड मेल नहीं खाते हैं",
  "Login.loginvalidate.text2": "कृपया लॉग इन करने के लिए मान्य क्रेडेंशियल दर्ज करें",
  "Login.loginvalidate.tryagain": "पुनः प्रयास करें?",
  "Login.loginvalidate.forgotpassword": "पासवर्ड भूल गए? यहाँ रीसेट करें।",
  "Login.loginpage.legaltext": "कानूनी",
  "Login.loginpage.privacytext": "एकांत",
  "Login.loginpage.rightstext": "सभी अधिकार सुरक्षित",
  "Login.loginsuccess.welcome": "स्वागत हे!",
  "Login.loginsuccess.msgtext": "आपने सफलतापूर्वक में लॉग इन किया है",
  "Login.loginsuccess.getstartedbtn": "शुरू हो जाओ",
  "Login.afterlogin.term&condition": "नियम एवं शर्तें",
  "Login.afterlogin.acceptbtn": "स्वीकार करना",
  "Login.newlogin.changepasstxt": "पासवर्ड बदलें",
  "Login.newlogin.oldpasstxt": "पुराना पासवर्ड",
  "Login.newlogin.oldpassdesc": "कृपया पुराना पासवर्ड प्रदान करें",
  "Login.newlogin.newpasstxt": "नया पासवर्ड",
  "Login.newlogin.newpassdesc": "कृपया नया पासवर्ड प्रदान करें",
  "Login.changepwdvalidate.newandold": "नया पासवर्ड पुराने पासवर्ड से अलग होना चाहिए",
  "Login.changepwdvalidate.confrmpwd": "पासवर्ड की पुष्टि कीजिये",
  "Login.changepwdvalidate.confrmpwddesc": "कृपया पासवर्ड की पुष्टि करें",
  "Login.changepwdvalidate.confrmpwddesc2": "पासवर्ड की पुष्टि नए पासवर्ड के समान होनी चाहिए",
  "Login.changepswd.changepswdbtn": "पासवर्ड बदलें",
  "Login.alert.loginfail": "लॉगिन विफल..!!",
  "Login.alert.pswdchange": "पासवर्ड बदला गया",
  "Login.alert.reloginconfirmation": "ओके को फिर से दबाएं",
  "User.Account.Inactive": "आपका खाता निष्क्रिय है। कृपया अपनी एजेंसी के समाधान व्यवस्थापक से संपर्क करें.",
  "User.LockOut.Contact.SolutionAdmin": "आपका खाता लॉक है। इसे अनलॉक करने के लिए कृपया अपनी एजेंसी के समाधान व्यवस्थापक से संपर्क करें.",
  "User.Password.Expired": "आपका पासवर्ड समाप्त हो गया है, कृपया एक नया बनाएं.",
  "User.AttemptRemaining.1": "अमान्य लॉगिन प्रयास। ",
  "User.AttemptRemaining.2": " प्रयास बाकी हैं कुल प्रयास ",
  "User.AttemptRemaining.3": ".",
  "Login.alert.reloginmessage": "फिर से लॉग इन करने के लिए ओके पर क्लिक करें",
  "Login.alert.passwordsuccessmsg": "पासवर्ड सफलतापूर्वक बदल दिया गया है",
  "Login.alertuseradmin.InvalidPassword": "%s और %s वर्णों के बीच होना चाहिए, जिसमें कम से कम %s अपरकेस अक्षर, %s लोअरकेस अक्षर, %s संख्या और %s विशेष वर्ण शामिल हों",
  "Login.changepswd.passUsernameSame":"पासवर्ड यूजर आईडी के समान नहीं हो सकता",
  "Login.changepswd.passNotValid":"पुराना पासवर्ड मान्य नहीं है",
  "Login.changepswd.InvalidPasswordLast24":"नया पासवर्ड हाल ही में उपयोग किया गया पासवर्ड नहीं हो सकता",
  "Login.loginbox.required":"आवश्यक",
  "Login.alert.username": "कृपया उपयोगकर्ता नाम दर्ज करें",
  "Login.alert.password": "कृप्या पास्वर्ड भरो"
};
