export const LANG_ES_NAME = "es";

export const LANG_ES_TRANS = {
  "Login.logo.name": "Sistema de gestión de letreros",
  "Login.page.text1": "Formas más seguras, rápidas y ligeras",
  "Login.page.text2": "para mantener el mundo en movimiento",
  "Login.loginbox.heading": "Iniciar Sesión",
  "Login.loginbox.text": "Ingrese sus credenciales a continuación para iniciar sesión en 3M Road Asset Manager",
  "Login.loginbox.userplaceholder": "Ingrese su ID de usuario",
  "Login.loginbox.passwordplaceholder": "Ingresa tu contraseña",
  "Login.loginbox.button": "Iniciar sesión",
  "Login.loginvalidate.text1": "Su identificación de usuario y contraseña no coinciden",
  "Login.loginvalidate.text2": "Ingrese credenciales válidas para iniciar sesión",
  "Login.loginvalidate.tryagain": "¿Inténtalo de nuevo?",
  "Login.loginvalidate.forgotpassword": "Se te olvidó tu contraseña? Restablecer aquí.",
  "Login.loginpage.legaltext": "Legal",
  "Login.loginpage.privacytext": "Intimidad",
  "Login.loginpage.rightstext": "Todos los derechos reservados",
  "Login.loginsuccess.welcome": "Bienvenido!",
  "Login.loginsuccess.msgtext": "Has iniciado sesión correctamente",
  "Login.loginsuccess.getstartedbtn": "EMPEZAR",
  "Login.afterlogin.term&condition": "Términos y condiciones",
  "Login.afterlogin.acceptbtn": "Aceptar",
  "Login.newlogin.changepasstxt": "Cambia la contraseña",
  "Login.newlogin.oldpasstxt": "Contraseña anterior",
  "Login.newlogin.oldpassdesc": "Por favor proporcione la contraseña anterior",
  "Login.newlogin.newpasstxt": "Nueva contraseña",
  "Login.newlogin.newpassdesc": "Por favor proporcione Nueva contraseña",
  "Login.changepwdvalidate.newandold": "La nueva contraseña debe ser diferente de la contraseña anterior",
  "Login.changepwdvalidate.confrmpwd": "Confirmar contraseña",
  "Login.changepwdvalidate.confrmpwddesc": "Por favor proporcione Confirmar contraseña",
  "Login.changepwdvalidate.confrmpwddesc2": "Confirme que la contraseña debe ser la misma que la nueva contraseña",
  "Login.changepswd.changepswdbtn": "Cambia la contraseña",
  "Login.alert.loginfail": "Error de inicio de sesion..!!",
  "Login.alert.pswdchange": "Contraseña cambiada",
  "Login.alert.reloginconfirmation": "Presione Ok para volver a iniciar sesión",
  "User.Account.Inactive": "Tu cuenta está inactiva. Comuníquese con el administrador de soluciones de su agencia.",
  "User.LockOut.Contact.SolutionAdmin": "Tu cuenta esta bloqueada. Comuníquese con el administrador de la solución de su agencia para desbloquearlo.",
  "User.Password.Expired": "Su contraseña ha caducado, cree una nueva.",
  "User.AttemptRemaining.1": "Intento de inicio de sesión no válido. Quedan ",
  "User.AttemptRemaining.2": " de ",
  "User.AttemptRemaining.3": " intentos.",
  "Login.alert.reloginmessage": "Haga clic en Aceptar para volver a iniciar sesión",
  "Login.alert.passwordsuccessmsg": "La contraseña se ha cambiado correctamente",
  "Login.alertuseradmin.InvalidPassword": "Debe tener entre %s y %s caracteres, incluido al menos %s letra mayúscula, %s letra minúscula, %s número y %s carácter especial.",
  "Login.changepswd.passUsernameSame":"La contraseña no puede ser la misma que la ID de usuario",
  "Login.changepswd.passNotValid":"La contraseña anterior no es válida",
  "Login.changepswd.InvalidPasswordLast24":"La nueva contraseña no puede ser una contraseña usada recientemente",
  "Login.loginbox.required":"Requerido",
  "Login.alert.username": "Por favor ingrese nombre de usuario",
  "Login.alert.password": "Por favor, ingrese contraseña"
//1687
};
