<!-- //Added for Visualization layers-->
<div class="card">
    <div class="card-body admin-model-height formbg-d2">
        <div class="row">
            <h3 class="col-sm-11 text-green">Add Reference Layer</h3>
        </div>              
        <div class="row">
            <div class="col-sm-12 form-group">
                <label class="text-green">WMS URL
                    <sup style="color: red;">★</sup>:
                </label>
            </div>
            <div class="col-sm-10 form-group" style="margin-top: -15px;">
                <input class="form-control" [disabled]="wmsUrlInputDisabled" placeholder="WMS URL…" [(ngModel)]="WMSUrl" aria-label="wms url"/>
            </div>
            <div class="col-sm-1 form-group" style="margin-top: -18px; margin-left:-10px">
                <button type="button" class="btn btn-primary" (click)="callWMSAsGet()">Submit</button>
            </div>
            <div class="col-sm-1 form-group" style="margin-top: -18px; margin-left:-10px">
                <button type="button" (click)="clearUrlData()" class="btn btn-default mr-2" style="background: #e62e2d;color: #fff;">Clear</button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5">
                <div class="form-group">
                    <label class="text-green">Layer Name
                        <sup style="color: red;">★</sup>:
                    </label>
                    <input class="form-control" maxlength="30" placeholder="Layer Name… [Max. 30 characters]" [(ngModel)]="WMSLayerName" aria-label="Layer Name"/>
                    <label class="text-green">Layer List</label>
                    <div class="reference-layers" style="height: 100px; overflow: auto; background: #fff;">
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let layer of WMSLayerList;"
                                [ngClass]="{'active': layer.layerTitle == selectedWMSLayer}"
                                (click)="selectWMS(layer)">
                                {{layer.layerTitle}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-group">
                    <label for="layerIconUpload" style="cursor: pointer;">
                        <i class="point-plus"></i> {{layerLogo}}
                        <input type="file" id="layerIconUpload" style="display: none" name="image" accept="image/png" 
                        (change)="uploadIcon($event.target.files)" data-original-title="upload layerIcon">
                    </label>
                    <img  *ngIf="fileInBase64Data != null" src={{fileInBase64Data}} width="15px" height="15px" alt={{layerLogo}} />
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" (click)="addLayer()" class="btn btn-secondary point-margin point-right mr-2" aria-label="add layer to reference layer list"></button>
		        <button type="button" (click)="removeLayer()" class="btn btn-secondary point-left" aria-label="remove layer from reference layer list"></button>
            </div>
            <div class="col-sm-5">
                <div class="form-group">
                    <label class="text-green">Reference Layers Order</label>
                    <div class="reference-layers" style="height: 150px; overflow: auto; background: #fff;">
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let layer of referenceWMSLayerList;"
                                [ngClass]="{'active': layer.layerName == selectedWMSOrderLayerName}"
                                (click)="selectWMSLayerOrderList(layer)">
                                {{layer.layerName}}
                                <Label *ngIf="layer.layerNotExist == true" style="color: #e62e2d;">!</Label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" (click)="layerOrderUp()" class="btn btn-secondary point-margin point-up mr-2" aria-label="change reference layer order by up angle"></button>
		        <button type="button" (click)="layerOrderDown()" class="btn btn-secondary point-down" aria-label="change reference layer order by down angle"></button>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button type="button" (click)="addOrUpdateLayers()" class="btn btn-primary mr-2">Ok</button>
            <button type="button" (click)="loadExistingData()" class="btn btn-default mr-2" style="background: #e62e2d;color: #fff;">Cancel</button>
        </div>  
    </div>
</div>