import { Component, OnInit } from "@angular/core";
import { SystemReportsService } from "../system.reports.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { SystemReportsStyle } from "../system.reports.style";
import { AssetAttributesService } from "src/app/services/assetAttibutes.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: "app-sms-activitytotals",
  templateUrl: "./activityTotals.component.html",
  styleUrls: ["../system.reports.component.scss"]
})
export class ActivityTotalsComponent implements OnInit {
  private currentUser: any;
  private tableAliases: any;
  constructor(public systemReportsService: SystemReportsService, private authenticationService: AuthenticationService, private translateService: TranslateService, private reportStyle: SystemReportsStyle,private assetAttributesService: AssetAttributesService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit(): void {
    //console.log("init")
  }
  exportPDF() {
    try {
      this.systemReportsService.getActivityTotalReport().subscribe(
        (res) => {
          this.assetAttributesService.getTableAlias().subscribe((tblresults: any) => {
            this.tableAliases = tblresults.features;
            this.JSONToPDFConvertor(res, true);
          });
        },
        (err) => {
          Swal.fire("Please contact administrator. API is throwing an error");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  JSONToPDFConvertor(res, ShowLabel) {
    try {
      let JSONData = [];

      if (res.table.length > 0) {
        JSONData = res.table;
      } else {
        Swal.fire(this.translateService.instant("Systemreports.alertactivitybyreason.nodatafound"));
        return;
      }

      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
      const tableheader = [];
      const tablebody = [];
      let tablerow = [];
      const currentReportStyle = this.reportStyle.getReportStyle();
      const currentLayoutStyle = this.reportStyle.getReportLayout();
      const currentFooter = this.reportStyle.getReportFooter();
      const currentYear = new Date().getFullYear();

      // This condition will generate the Label/Header
      if (ShowLabel) {
        // This loop will extract the label from 1st index of on array
        tableheader.push(
          { text: this.translateService.instant("Systemreports.headeractivitytotals.activitytype"), style: "tableHeader" },
          { text: this.translateService.instant("Systemreports.headeractivitytotals.currentyear"), style: "tableHeader" },
          { text: (currentYear - 1).toString(), style: "tableHeader" },
          { text: (currentYear - 2).toString(), style: "tableHeader" },
          { text: (currentYear - 3).toString(), style: "tableHeader" },
          { text: (currentYear - 4).toString(), style: "tableHeader" },
          { text: (currentYear - 5).toString(), style: "tableHeader" },
          { text: (currentYear - 6).toString(), style: "tableHeader" }
        );
        tablebody.push(tableheader);
      }

      let assetClass = [];
      arrData.forEach((data) => {
        if (data.assetName !== "") {
          assetClass.push(data.assetName);
        }
      });

      const distinct = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      assetClass = assetClass.filter(distinct);

      // 1st loop is to extract each row
      for (let j = 0; j < assetClass.length; j++) {
        const total = [];
        const arr = arrData.filter((obj) => obj.assetName.includes(assetClass[j]));
        const tableAlias = this.tableAliases.map(a => a['attributes']).filter((lyr) => lyr.Name === assetClass[j])[0]?.Alias;

        if (j === 0) {
          tablebody.push([
            {
              colSpan: 8,
              text: tableAlias,
              style: "rowGroupHeader"
            },
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          ]);
        } else {
          tablebody.push([
            {
              colSpan: 8,
              text: tableAlias,
              style: "rowGroupHeader",
              pageBreak: "before"
            },
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          ]);
        }

        const IndexVal = ["activityType", "current"];
        IndexVal.push((currentYear - 1).toString());
        IndexVal.push((currentYear - 2).toString());
        IndexVal.push((currentYear - 3).toString());
        IndexVal.push((currentYear - 4).toString());
        IndexVal.push((currentYear - 5).toString());
        IndexVal.push((currentYear - 6).toString());
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let t4 = 0;
        let t5 = 0;
        let t6 = 0;
        let t7 = 0;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          tablerow = [];

          // 2nd loop will extract each column and push in the table row
          if (arr[i].assetSubtype !== "") {
            if (arr[i].assetSubtype !== null) {
              IndexVal.forEach((index) => {
                switch (index) {
                  case "activityType":
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case "current":
                    t1 = t1 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 1).toString():
                    t2 = t2 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 2).toString():
                    t3 = t3 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 3).toString():
                    t4 = t4 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 4).toString():
                    t5 = t5 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 5).toString():
                    t6 = t6 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                  case (currentYear - 6).toString():
                    t7 = t7 + arr[i][index];
                    tablerow.push({ text: arr[i][index], style: "tableContent" });
                    break;
                }
              });
              // add a line break after each row
              tablebody.push(tablerow);
            }
          }
        }

        total.push(
          { text: this.translateService.instant("Systemreports.headeractivitytotals.total"), style: "tableHeader" },
          { text: t1, style: "tableHeader" },
          { text: t2, style: "tableHeader" },
          { text: t3, style: "tableHeader" },
          { text: t4, style: "tableHeader" },
          { text: t5, style: "tableHeader" },
          { text: t6, style: "tableHeader" },
          { text: t7, style: "tableHeader" }
        );
        tablebody.push(total);
      }

      const dd = {
        pageSize: "A4",
        pageOrientation: "portrait",
        footer: currentFooter,
        content: [
          {
            text: this.translateService.instant("Systemreports.headeractivitytotals.headingtxt"),
            style: "header"
          },
          {
            style: "tableExample",
            alignment: "justify",
            table: {
              widths: [85, 75, 50, 50, 50, 50, 50, 50, 50],
              heights: 20,
              headerRows: 1,
              body: tablebody
            },
            pageOrientation: "landscape",
            layout: currentLayoutStyle
          }
        ],
        styles: currentReportStyle
      };
      pdfMake.createPdf(dd).open();
    } catch (error) {
      console.error(error);
    }
  }
}
